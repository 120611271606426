import * as types from "../types";

const initialState = {
  partner: null,
  partnerLoading: false,
  errorPartner: null,
};

export const partner = (state = initialState, action) => {
  switch (action.type) {
    //Pagination
    case types.GET_PARTNER_CHAT_REQUEST:
      return {
        ...state,
        partnerLoading: true,
        errorPartner: null,
      };
    case types.GET_PARTNER_CHAT_SUCCESS:
      return {
        ...state,
        partner: action.payload,
        partnerLoading: false,
        errorPartner: null,
      };
    case types.GET_PARTNER_CHAT_FAIL:
      return {
        ...state,
        partnerLoading: false,
        errorPartner: action.payload,
      };
    case types.SET_PARTNER_NULL:
      return {
        ...state,
        partner: null,
        partnerLoading: false,
        errorPartner: null,
      };

    default:
      return state;
  }
};

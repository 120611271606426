import styled from "@emotion/styled";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import parse from "html-react-parser";

// Redux
import { useSelector } from "react-redux";
import { patchLikeComment } from  "../../redux/api/commentsCourseApi";

const SubReplyCard = (props) => {
    const { subComment } = props;

    // REDUX
    const id = useSelector((state) => state?.auth?.user?.repo_id);

    // STATES
    const [isLiked, setIsLiked] = useState(subComment?.liked);
    const [likes, setLikes] = useState(subComment?.likes);

    // FUNCTIONS
    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const handleLike = async () => {
        try {
            await patchLikeComment(subComment?.id, "comment", id);

            setIsLiked(!isLiked);

            // handleSetLike(!isLiked);
            setLikes(!isLiked ? likes + 1 : likes - 1);
        } catch (error) {
            console.info("error", error);
        }
    };

    return (
        <Container>
            <Header>
                <Image>
                    <img
                        src={subComment?.partner_id?.image}
                        alt="imagen usuario"
                    />
                </Image>
                <NameContainer>
                    <h2>{subComment?.partner_id?.name}</h2>
                    <span>{subComment?.create_date}</span>
                </NameContainer>
            </Header>
            <Body>
                <Comment>
                    <p>{parse(sanitizerHtml(subComment?.comment))}</p>
                </Comment>
                <Interactions>
                    <Likes>
                        {isLiked ? (
                            <Icon
                                onClick={handleLike}
                                icon="icon-park-solid:like"
                            />
                        ) : (
                            <Icon
                                onClick={handleLike}
                                icon="icon-park-outline:like"
                            />
                        )}
                        <span>{likes}</span>
                    </Likes>
                </Interactions>
            </Body>
        </Container>
    );
};

export default SubReplyCard;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 10px;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
`;

const Image = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;

    h2 {
        color: #222222;
        font-weight: bold;
        font-size: 0.9rem;
    }

    span {
        font-size: 0.8rem;
        font-weight: bold;
        color: #a8a8a8;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

const Comment = styled.div`
    width: 100%;

    p {
        color: #616161;
        font-size: 1rem;
        text-align: start;
    }
`;

const Interactions = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
`;

const Likes = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        color: #b31d15;
        font-size: 1.3rem;
        cursor: pointer;
    }

    span {
        color: #616161;
        font-size: 0.9rem;
        font-weight: bold;
    }
`;

// const Reply = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     gap: 8px;

//     svg {
//         color: #b31d15;
//         font-size: 1.3rem;
//     }

//     span {
//         color: #616161;
//         font-size: 0.9rem;
//         font-weight: bold;
//     }
// `;

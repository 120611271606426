import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";

const TrainingExperience = (props) => {
  // REDUX
  const { user } = props;

  return (
    <Container>
      {user.academic_training !== "" && (
        <>
          <Text fontSize="1.25rem" fontWeight="700">
            Formación Académica
          </Text>
          <PaddingText fontSize="1rem">{user.academic_training}</PaddingText>
        </>
      )}
      {user.work_experience !== "" && (
        <>
          <Text fontSize="1.25rem" fontWeight="700">
            Experiencia Laboral
          </Text>
          <PaddingText fontSize="1rem">{user.work_experience}</PaddingText>
        </>
      )}
    </Container>
  );
};

export default TrainingExperience;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const PaddingText = styled(Text)`
  padding: 0 2rem;
`;

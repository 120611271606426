import * as types from "../types";

const actions = {};

// GET MICROLEARNINGS
actions.getMicrolearningsRequest = () => {
  return {
    type: types.GET_MICROLEARNINGS_REQUEST,
  };
};

actions.getMicrolearningsSuccess = (microlearnings) => {
  return {
    type: types.GET_MICROLEARNINGS_SUCCESS,
    payload: microlearnings,
  };
};

actions.getMicrolearningsFail = (error) => {
  return {
    type: types.GET_MICROLEARNINGS_FAIL,
    payload: error,
  };
};

// SET CURRENT MICROLEARNINGS
actions.loadMicrolearning = (id) => {
  return {
    type: types.LOAD_FIRST_MICROLEARNING,
    payload: id,
  };
};

actions.setMicrolearning = (microlearning) => {
  return {
    type: types.SET_CURRENT_MICROLEARNING,
    payload: microlearning,
  };
};

// Likes (Favoritos)
actions.setLikes = (payload) => {
  return {
    type: types.SET_LIKE_MICROLEARNING,
    payload: payload,
  };
};

actions.setNoLikes = (payload) => {
  return {
    type: types.SET_NOLIKE_MICROLEARNING,
    payload: payload,
  };
};

export { actions };

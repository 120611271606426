import styled from "styled-components";
import React, { useState } from "react";

const Image = (props) => {
  const { w, h, radius, src, alt } = props;

  const [isLoad, setIsLoad] = useState(false);

  return (
    <Wrapper isLoad={isLoad}>
      <Img
        src={src}
        alt={alt}
        w={w}
        h={h}
        radius={radius}
        onLoad={() => setIsLoad(true)}
      />
    </Wrapper>
  );
};

export default Image;

export const Img = styled.img`
  width: ${(p) => p.w};
  height: ${(p) => p.h};
  border-radius: ${(p) => p.radius};
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  opacity: ${(p) => (p.isLoad ? "1" : "0")};
  transition: ease-in-out 1.5s all;
`;

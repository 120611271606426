import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from '@iconify/react';
import Slider from 'react-slick'

const Section = (props) => {

    const {
        title,
        tabsArray,
        width = "100%",
        height = "480px",
        Card = null,
        array = null,
        to = "/",
        breakPoints,
        activeTab,
        setActiveTab,
        marginBottom = "0px"
    } = props;

    const [isSwiping, setIsSwiping] = useState(false);

    const getItemsToShow = (screenWidth) => breakPoints
        .sort((a, b) => b.width - a.width)
        .find(bp => screenWidth >= bp.width)?.itemsToShow || breakPoints[breakPoints.length - 1].itemsToShow;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: getItemsToShow(window.innerWidth),
        slidesToScroll: 1,
        draggable: true,
        swipeToSlide: true,
        dontAnimate: true,
        beforeChange: () => {
            setIsSwiping(true);
        },
        afterChange: () => {
            setIsSwiping(false);
        }
    }

    const carouselRef = useRef(null);

    const handleNextArrow = () => {
        carouselRef.current?.slickNext();
    };

    const handlePrevArrow = () => {
        carouselRef.current?.slickPrev();
    };

    useEffect(() => {
        carouselRef.current?.slickGoTo(0, false)
    }, [activeTab])

    return (
        <Container width={width} height={height} marginBottom={marginBottom}>
            <Title>
                {title}
            </Title>
            <SliderWrapper>
                <SliderHeader tabsArray={tabsArray === null}>
                    {
                        tabsArray !== null && <Tabs>
                            {
                                tabsArray.map((item, index) => {
                                    return (
                                        <TabButton active={activeTab === index} onClick={() => setActiveTab(index)}>
                                            {item === "RRHH" || item === "Blog ADEN" ? item : item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                        </TabButton>
                                    )
                                })
                            }
                        </Tabs>
                    }
                    <SlideButtonsWrapper display={false}>
                        <SlideButton padding={true} href={to}>
                            Ver más
                        </SlideButton>
                        <SlideButton onClick={handlePrevArrow}>
                            <Icon icon="ph:arrow-left-bold" width="20" height="20" />
                        </SlideButton>
                        <SlideButton onClick={handleNextArrow}>
                            <Icon icon="ph:arrow-right-bold" width="20" height="20" />
                        </SlideButton>
                    </SlideButtonsWrapper>
                </SliderHeader>
                {/* <FakeCarousel
                    length={arrayLength}
                    breakPoints={type === "programs" ? 0 : breakPoints}
                    ref={carouselRef}
                    itemPadding={[8]}
                >
                    {
                        array !== null ?
                            (
                                array.map((item, index) => (
                                    <Card
                                        value={item}
                                        index={index}
                                    />
                                ))
                            )
                            : array === false || array?.length === 0 ?
                                [...Array(5)].map((_, index) => (
                                    <Skeleton key={index} h="325px" w="320px" />
                                ))
                                : (
                                    [...Array(5)].map((_, index) => (
                                        <Skeleton key={index} h="325px" w="320px" />
                                    ))
                                )
                    }
                </FakeCarousel> */}
                <EditedSwiper
                    ref={carouselRef}
                    {...settings}
                >
                    {
                        array !== null ?
                            (
                                array.map((item, index) => (
                                    <Card
                                        value={item}
                                        index={index}
                                        isSwiping={isSwiping}
                                    />
                                ))
                            )
                            : array === false || array?.length === 0 ?
                                [...Array(5)].map((_, index) => (
                                    <Skeleton key={index} h="340px" w="320px" />
                                ))
                                : (
                                    [...Array(5)].map((_, index) => (
                                        <Skeleton key={index} h="340px" w="320px" />
                                    ))
                                )
                    }
                </EditedSwiper>
                <SlideButtonsWrapper display={true}>
                    <SlideButton padding={true} href={to}>
                        Ver más
                    </SlideButton>
                    <SlideButton onClick={handlePrevArrow}>
                        <Icon icon="ph:arrow-left-bold" width="20" height="20" />
                    </SlideButton>
                    <SlideButton onClick={handleNextArrow}>
                        <Icon icon="ph:arrow-right-bold" width="20" height="20" />
                    </SlideButton>
                </SlideButtonsWrapper>
            </SliderWrapper>
        </Container>
    )

}

export default Section;

const EditedSwiper = styled(Slider)`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    :active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    .slick-list {
        width: 100% !important;
        animation: none;
        transform: none !important;
    }
    
    .slick-track {
        padding-bottom: 8px;
        padding-left: 8px;
    }
`

const Container = styled.div`
    width: ${(p) => p.width};
    height:  ${(p) => p.height};
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: ${(props) => props.marginBottom};
    visibility: auto;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-one);
`

const SliderWrapper = styled.div`
    width: calc(100% - 48px);
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`

const SliderHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${(props) => props.tabsArray ? "end" : "space-between"};
`

const Tabs = styled.div`
    width: fit-content;
    display: flex;
    gap: 8px;
    @media (width < 768px) {
        overflow-x: scroll;
        scrollbar-color: transparent transparent;
        -webkit-overflow-scrolling: touch;
    }
`

const TabButton = styled.button`
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 8px 24px;
    background-color: ${(p) => p.active ? "var(--secondary-two)" : "var(--secondary-one)"};
    color: ${(p) => p.active ? "var(--primary-one)" : "var(--secondary-four)"};
    border-radius: 20px;
    box-shadow: ${(p) => p.active && "0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F"} ;
    font-size: 14px;
    font-weight: ${(p) => p.active ? "700" : "500"};
    @media (width < 768px) {
        white-space: nowrap;
    }
`

const SlideButtonsWrapper = styled.div`
    width: fit-content;
    height: 32px;
    display: ${(props) => props.display ? "none" : "flex"};
    align-items: center;
    gap: 8px;
    // display: ${(props) => props.display};
    @media (width < 768px) {
        width: 100%;
        height: 8px;
        display: ${(props) => props.display ? "flex" : "none"};
        align-items: center;
        justify-content: center;
        & > a:first-child {
            display: none;
        }
    }
`

const SlideButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;
    color: var(--primary-one);
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    padding: ${(p) => p.padding && "0 16px"};
    :hover {
        background-color: var(--primary-one);
        color: var(--secondary-one);
    }
`

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w} !important;
    margin-right: 1rem;
`;
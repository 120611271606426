import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import SimpleLoading from "./SimpleLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResultsFound from "./NoResultsFound";
import NoMoreResults from "./NoMoreResults";
import CourseFilters from "../ui/organisms/my-progress/CourseFilters";
import TryAgain from "./TryAgain";
import EmptyGridResource from "./EmptyGridResource";
import CourseCardSkeleton from "./CourseCardSkeleton";
import { useSelector } from "react-redux";

const GridMyProgress = (props) => {
  const {
    Card,
    propsValues,
    name,
    loadMore,
    hasMore,
    handleCurrentState,
    currentState,
    gridFor,
    errorList = null,
    handleTryAgain,
  } = props;

  const listLoading = useSelector((state) => state.courses.listLoading);

  // STATE
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");

  const order = { Confirmado: 1, Preinscripto: 2, Egresado: 3 };

  const emptyItems = new Array(6).fill(null);

  // EFFECTS
  useEffect(() => {
    if (propsValues !== null) {
      if (name === "nombre") {
        setValues(
          propsValues.filter((propsValue) => {
            return propsValue.nombre
              .toLowerCase()
              .includes(search.toLowerCase());
          })
        );
      } else {
        setValues(
          propsValues.filter((propsValue) => {
            return propsValue.curso
              .toLowerCase()
              .includes(search.toLowerCase());
          })
        );
      }
    }
  }, [search, propsValues]);

  useEffect(() => {
    if (propsValues !== null) {
      if (currentState === "Todos") {
        setValues(propsValues);
      } else {
        setValues(propsValues.filter((value) => value.estado === currentState));
      }
    }
  }, [currentState]);

  // FUNCTIONS
  function handleChange(e) {
    setSearch(e.target.value);
  }

  if (errorList !== null) {
    return <TryAgain padding="6rem" handleTryAgain={handleTryAgain} />;
  }

  // RETURN
  return (
    <>
      {propsValues === null || listLoading ? (
        <EmptyGridResource propsValues={emptyItems} Card={CourseCardSkeleton} />
      ) : (
        <Wrapper>
          <Filters>
            <WrapperSearch>
              <TextField
                value={search}
                onChange={handleChange}
                size="small"
                label=""
                variant="outlined"
                placeholder="Buscar..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <SplitButton handleOption={handleOption} options={options} /> */}
            </WrapperSearch>

            {gridFor === "courses" ? (
              <CourseFilters
                currentState={currentState}
                handleCurrentState={handleCurrentState}
              />
            ) : (
              ""
            )}
          </Filters>
          {values.length === 0 ? (
            <NoResultsFound />
          ) : (
            <>
              <InfiniteScrollFake
                dataLength={values.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<SimpleLoading padding="4rem" />}
                endMessage={<NoMoreResults />}
              >
                <GridValues>
                  {values
                    .sort((a, b) => order[a.estado] - order[b.estado])
                    .map((value, index) => (
                      <Card value={value} key={index} index={index} />
                    ))}
                </GridValues>
              </InfiniteScrollFake>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default GridMyProgress;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 20px;
  padding: 2rem;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }
`;

const GridValues = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  padding: 0.5rem;
  /* align-items: center; */
  overflow: hidden;
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const WrapperSearch = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfiniteScrollFake = styled(InfiniteScroll)`
  padding: 2rem;
`;

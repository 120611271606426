import React, { useState, useRef } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";

import { Button, IconButton } from "@mui/material";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import SharedResource from "../../../common/SharedResource";
import TryAgain from "../../../common/TryAgain";
import CourseCardSkeleton from "../../../common/CourseCardSkeleton";
import WorkshopCardSkeleton from "../../../common/WorkshopCardSkeleton";
import MSCardSkeleton from "../../../common/MSCardSkeleton";
import useTheme from "../../../../hooks/useTheme";
import CardOa from "../../molecules/subject/CardOa";
import CardSubject from "../../molecules/subject/CardSubject";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1400, itemsToShow: 4 },
];

const SliderCampus = (props) => {
  const {
    items,
    title,
    label_button,
    to,
    Card,
    lp_type,
    type,
    error,
    handleTryAgain,
  } = props;
  const { primary } = useTheme();

  // STATE
  const carouselRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sharedItem, setSharedItem] = useState(null);

  const emptyItems = new Array(6).fill(null);

  // FUNCTIONS
  function handleNextArrow() {
    carouselRef.current.slideNext();
  }

  function handlePrevArrow() {
    carouselRef.current.slidePrev();
  }

  function handleOpenModal(id, nombre_publicacion) {
    setSharedItem({ lp_type, type, id, nombre_publicacion });
    setModalIsOpen(true);
  }

  function handleCloseModal() {
    setModalIsOpen(false);
  }

  //  RETURN
  if (error !== null) {
    return <TryAgain padding="4rem" handleTryAgain={handleTryAgain} />;
  }

  return (
    <SliderCampusContainer>
      <SharedResource
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        sharedItem={sharedItem}
      />
      <OptionsWrapper>
        <H2>{title}</H2>
        <ArrowsWrapper>
          <FakeLink to={to}>
            <Button color="primary" size="small">
              {label_button}
            </Button>
          </FakeLink>
          <FakeIconButton
            size="small"
            color={primary}
            onClick={handlePrevArrow}
          >
            <ChevronLeftRounded />
          </FakeIconButton>
          <FakeIconButton
            size="small"
            color={primary}
            onClick={handleNextArrow}
          >
            <ChevronRightRounded />
          </FakeIconButton>
        </ArrowsWrapper>
      </OptionsWrapper>

      {items === null && title === "Tus cursos" ? (
        /* <SimpleLoading padding='4rem' /> */
        <FakeCarousel
          length={emptyItems.length}
          ref={carouselRef}
          breakPoints={breakPoints}
          itemPadding={[8]}
        >
          {emptyItems.map((item, index) => (
            <CourseCardSkeleton key={index} />
          ))}
        </FakeCarousel>
      ) : items === null && title === "Cursos abiertos" ? (
        <FakeCarousel
          length={emptyItems.length}
          ref={carouselRef}
          breakPoints={breakPoints}
          itemPadding={[8]}
        >
          {emptyItems.map((item, index) => (
            <WorkshopCardSkeleton key={index} />
          ))}
        </FakeCarousel>
      ) : items === null && title === "Biblioteca de contenidos" ? (
        <FakeCarousel
          length={emptyItems.length}
          ref={carouselRef}
          breakPoints={breakPoints}
          itemPadding={[8, 40]}
        >
          {emptyItems.map((item, index) => (
            <MSCardSkeleton key={index} />
          ))}
        </FakeCarousel>
      ) : (
        <FakeCarousel
          length={items !== null ? items.length : emptyItems.length}
          ref={carouselRef}
          breakPoints={breakPoints}
          itemPadding={[8]}
          initialActiveIndex={Math.floor(Math.random() * items.length)}
        >
          {items.map((item, index) => {
            return !!item?.type ? (
              item.type === "asignatura" ? (
                <CardSubject values={item} />
              ) : null
            ) : (
              <Card
                value={item}
                key={index}
                index={index}
                handleOpenModal={handleOpenModal}
              />
            );
          })}
        </FakeCarousel>
      )}
      <FakeLinkMobile to={to}>
        <Button color="primary" size="medium">
          {label_button}
        </Button>
      </FakeLinkMobile>
    </SliderCampusContainer>
  );
};

export default SliderCampus;

const SliderCampusContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #222222;
`;

const ArrowsWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const FakeLink = styled(Link)`
  display: inline-flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FakeLinkMobile = styled(Link)`
  display: none;

  @media (max-width: 768px) {
    display: inline-flex;

    button {
      width: 100%;
    }
  }
`;

const FakeIconButton = styled(IconButton)`
  background-color: ${(p) => p.color};
  color: #ffffff;
  border-radius: 5px;

  :hover {
    color: #ffffff;
  }
`;

const FakeCarousel = styled(Carousel)`
  /* ${(props) => {
    return props.length === 1
      ? `
            .rec.rec-item-wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;

            div {
                width: 100%;
                div {
                    width: auto;
                }
            }
            @media(max-width: 900px) {
                align-items: center;
            }
        }
		}`
      : `.rec.rec-item-wrapper{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
           
                div{
                width: 100%;
                div {
                    width: auto;
                }
            }
            
		}`;
  }} */

  .rec.rec-arrow {
    display: none;
    visibility: hidden;
  }

  .rec.rec-pagination {
    display: none;
    visibility: hidden;
  }
`;

import { Modal } from '@mui/material';
import styled from 'styled-components';
import CheckImage from "../../../../assets/media/gif/check-girl.gif"
import { Icon } from '@iconify/react';
import { Text } from '../../../../components/common/Texts';

const ModalInfo = (props) => {
    const { isOpen, handleCloseModal, handleClick } = props;

    return (
        <>
            <FakeModal
                onClose={handleCloseModal}
                open={isOpen}
            >
                <Wrapper>
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>
                    <img src={CheckImage} alt="personas" />
                    <div>
                        <Text
                            fontSize="16px"
                            fontWeight={500}
                            color="#222222"
                            textAlign="center"
                        >
                            Para completar cada módulo<br/><b>debes cumplir con todos los requisitos.</b>
                        </Text>
                        <Text
                            fontSize="16px"
                            fontWeight={500}
                            color="#b31d15"
                            textAlign="center"
                        >
                            <b>Los requisitos se encuentran al pie de cada recurso.</b>
                        </Text>
                    </div>
                    <ButtonGoTo onClick={handleClick}>
                        Entendido
                    </ButtonGoTo>
                </Wrapper>
            </FakeModal>
        </>
    );
};

export default ModalInfo;

const FakeModal = styled(Modal)`
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
        background-color: rgba(0, 0, 0, 0.3) !important;
    }
`

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    width: 445px;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 1rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 320px;
        height: auto;
        padding: 2rem 1rem;
    }

    img {
        width: 300px;
        height: 300px;
    }
`;

const ButtonGoTo = styled.button`
    cursor: pointer;
    height: 40px;
    padding: 0 24px;
    border-radius: 100px;
    background-color: #CD2118;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;

    :hover {
        background-color: #B31D15;
    }
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
`;

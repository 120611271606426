import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import usePopup from "../hooks/usePopup";
import CardPopup from "./ui/molecules/CardPopup";

const MainPopup = () => {
    const [open, setOpen] = useState(false);

    const { popupSlides } = usePopup();

    //STATE
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(
        Array.isArray(popupSlides) ? popupSlides[currentStep]?.attributes : null
    );

    // EFFECTS
    useEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        !!popupSlides && setCurrentSlide(popupSlides[currentStep]?.attributes);
    }, [popupSlides]);

    useEffect(() => {
        setCurrentSlide(
            Array.isArray(popupSlides) && popupSlides[currentStep]?.attributes
        );
    }, [currentStep]);

    // FUNCTIONS
    const handleClose = () => {
        window.localStorage.setItem("lastSeenPopup", new Date());
        setOpen(false);
    };

    const handleBackSlide = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const handleNextSlide = () => {
        if (currentStep < popupSlides.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <>
            {popupSlides?.length > 0 && (
                <ModalFake
                    open={open}
                    disableEnforceFocus
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <CardPopup
                        currentSlide={currentSlide}
                        handleBackSlide={handleBackSlide}
                        handleNextSlide={handleNextSlide}
                        handleClose={handleClose}
                        popupLength={popupSlides.length}
                        currentStep={currentStep}
                    />
                </ModalFake>
            )}
        </>
    );
};

export default MainPopup;

const ModalFake = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

// Components
import SimpleLoading from "../../../../../components/common/SimpleLoading";

// Material UI
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Redux
import { useSelector } from "react-redux";

// Context
import CourseContext from "../../../context/CourseContext"

const CardNewNote = (props) => {
    const {
        LoadingPostNote,
        errorPost,
        upDateNotes,
        createNote,
        resetUpdateNotes,
    } = props;

    const {
        currentMedia: {
            id: idResource
        },
        currentSecondVideo,
        notesContent: {
            getNotes
        }
    } = useContext(CourseContext); 

    // REDUX
    const { repo_id, foto } = useSelector((state) => state.auth.user);

    // STATES
    const [expandComment, setExpandComment] = useState(false);
    const [valueMessage, setValueMessage] = useState("");

    const { activeRol } = useSelector((state) => state.auth);

    // EFFECTS
    useEffect(() => {
        if (errorPost === false) {
            setValueMessage("");
            handleComment();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorPost]);

    useEffect(() => {
        if (upDateNotes) {
            getNotes();
            resetUpdateNotes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upDateNotes]);

    // FUNCTIONS
    const handleValueMessage = (e) => {
        if (e.target.value.length <= 530) {
            setValueMessage(e.target.value);
        }
    };

    const handleComment = () => {
        setExpandComment(!expandComment);
    };

    function secondsToHMS(d) {
        let hours = Math.floor(d / 3600);
        let minutes = Math.floor((d - hours * 3600) / 60);
        let seconds = d - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        const integerPart = Math.floor(seconds);

        return (
            hours +
            ":" +
            minutes +
            ":" +
            integerPart.toString().padStart(2, "0")
        );
    }


    const handlePostNote = () => {
        const body = {
            resource_id: idResource,
            video_time: currentSecondVideo,
            content: valueMessage,
            partner_id: repo_id,
        };
        createNote(body);
    };

    return (
        <Card expandComment={expandComment}>
            <HeaderContainer expandComment={expandComment}>
                <Image>
                    <img src={foto} alt="imagen de perfil" />
                </Image>
            </HeaderContainer>
            {expandComment && (
                <TimeContainer>
                    <Icon icon="ph:play-fill" />
                    <p>{secondsToHMS(currentSecondVideo)}</p>
                </TimeContainer>
            )}
            <BoxComment expandComment={expandComment}>
                <div className="headerBox"></div>
                <textarea
                    type="text"
                    value={valueMessage}
                    onChange={handleValueMessage}
                    placeholder="Escribe un nuevo apunte."
                    onClick={() => {
                        !expandComment && handleComment();
                    }}
                />
                <div className="footerBox">{valueMessage.length}/530</div>
            </BoxComment>
            <ButtonContainer expandComment={expandComment}>
                {
                    activeRol === "ondemand" ?
                        <Button onClick={handleComment} style={{ color: "var(--primary-one )" }}>
                            <p>Cancelar</p>
                        </Button>
                        :
                        <Button onClick={handleComment}>
                            <p>Cancelar</p>
                        </Button>
                }
                {
                    activeRol === "ondemand" ?
                        <>
                            <SaveButton

                                variant="contained"
                                onClick={handlePostNote}
                                disabled={valueMessage.length === 0 ? true : false}
                            >
                                {
                                    LoadingPostNote ? <CircularProgress size={"20px"} style={{ color: "var(--secondary-one)", padding: "0px 24px"}} /> : <p>Guardar</p>
                                }
                            </SaveButton>
                        </>
                        :
                        <>
                            {LoadingPostNote ? (
                                <SimpleLoading />
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handlePostNote}
                                    disabled={valueMessage.length === 0 ? true : false}
                                >
                                    <p>Guardar</p>
                                </Button>
                            )}
                        </>
                }

            </ButtonContainer>
            {expandComment && errorPost && (
                <ErrorContainer>
                    <p>Tu apunte no pudo ser guardado.</p>
                </ErrorContainer>
            )}
        </Card>
    );
};

export default CardNewNote;

const Card = styled.div`
    margin-top: ${(p) => (p.expandComment ? "1rem" : "0")};
    height: ${(p) => (!p.expandComment ? "calc(80px - 2rem)" : "auto")};
    width: ${(p) => (p.expandComment ? "calc(100% - 2rem)" : "100%")};
    display: flex;
    padding: ${(p) => (p.expandComment ? "1rem 1rem" : "1rem 0")};
    gap: 1rem;
    transition: all 0.3s ease-in-out;
    flex-direction: ${(p) => (p.expandComment ? "column" : "row")};
    background-color: ${(p) => (p.expandComment ? "#f1f1f1" : "transparent")};
    border: ${(p) => (p.expandComment ? "solid 1px #c4c4c4" : "none")};
    border-radius: ${(p) => (p.expandComment ? "10px" : "0")};
`;

const HeaderContainer = styled.div`
    display: ${(p) => (p.expandComment ? "none" : "block")};
    transition: all 0.3s ease-in-out;
    width: ${(p) => (p.expandComment ? "100%" : "auto")};
    justify-content: space-between;
    align-items: center;
`;

const Image = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        /* border-radius: 50%; */

        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const BoxComment = styled.div`
    width: 100%;
    height: ${(p) => (p.expandComment ? "328px" : "100%")};
    border-radius: 10px;
    border: ${(p) => (p.expandComment ? "solid 1px #c4c4c4" : "none")};

    .headerBox {
        display: ${(p) => (p.expandComment ? "block" : "none")};
        background-color: #f1f1f1;
        height: 50px;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: solid 1px #c4c4c4;
    }

    .footerBox {
        height: calc(17px - 12px);
        width: calc(100% - 20px);
        padding: 11px 10px;
        display: ${(p) => (p.expandComment ? "flex" : "none")};
        justify-content: flex-end;
        align-items: center;
        color: #a8a8a8;
        border-top: solid 1px #c4c4c4;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #fff;
    }

    textarea {
        font-size: 1rem;
        width: calc(100% - 1rem);
        padding: 0.5rem;
        height: ${(p) =>
        !p.expandComment
            ? "calc(100% - 1rem)"
            : "calc(100% - 1rem - 82px) "};
        outline: none;
        color: #222222;
        resize: none;
        border: ${(p) => (!p.expandComment ? "solid 1px #c4c4c4" : "none")};
        border-radius: ${(p) => (!p.expandComment ? "10px" : "0")};

        ::placeholder {
            color: #616161;
        }
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: ${(p) => (p.expandComment ? "flex" : "none")};
    gap: 1rem;
`;

const SaveButton = styled(Button)`
    :not(:disabled) {
        background-color: var(--primary-one);
    }
`

const TimeContainer = styled.div`
    display: flex;
    gap: 0.3rem;
    align-items: center;

    p {
        color: #616161;
        font-size: 1.1rem;
    }
    svg {
        color: #b31d15;
        font-size: 1.3rem;
    }
`;

const ErrorContainer = styled.div`
    width: 100%;
    background-color: #f8423f;
    border-radius: 10px;
    text-align: center;
    padding: 0.1rem 0;

    p {
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
    }
`;
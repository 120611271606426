import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dracmaActions } from "../../../../redux/actions";
import { Button, Modal } from "@mui/material";

import Text from "../../../common/Text";
import UserDracma from "../../../templates/dracma/UserDracma";
import CardUserPosition from "../../molecules/dracma/CardUserPosition";
import HowToGetMorePoints from "./HowToGetMorePoints";
import UsersRating from "./UsersRating";
import MoreInformation from "./MoreInformation";
import UsersRatingOndemand from "./UsersRatingOndemand";

const DracmaResume = () => {
  // REDUX
  const dispatch = useDispatch();
  const dracmas = useSelector((state) => state.dracma.dracmas);
  const ondemand = useSelector((state) => state.ondemand.ondemand);

  // EFFECTS
  useEffect(() => {
    if (!dracmas) {
      dispatch(dracmaActions.getDracmaRequest());
    }
  }, []);

  // RETURN
  if (!dracmas) {
    return null;
  }

  return (
    <Container>
      {/** Informacion del usuario */}
      <Header>
        <Text fontSize="1.25rem" fontWeight="700" color="secondary">
          Mis puntos
        </Text>
        <MoreInformation
          dracma_coin_name={ondemand ? ondemand.dracma_coin_name : undefined}
          withText={false}
        />
      </Header>
      <UserDracma
        dracma_coin_name={ondemand ? ondemand.dracma_coin_name : undefined}
        dracmas={dracmas}
      />
      {/** Posicion en el ranking del usuario */}

      <HowToGetMorePoints
        dracma_coin_name={ondemand ? ondemand.dracma_coin_name : undefined}
        dracmas={dracmas}
      />

      <Text fontSize="1.25rem" fontWeight="700" color="secondary">
        Tu posición en el ranking
      </Text>
      <CardUserPosition />
      {/** Podio */}
      {/* <Text fontSize="1.25rem" fontWeight="700" color="secondary">
                Podio
            </Text>
            {!!ondemand ? (
                <UsersRatingOndemand
                    minHeight={'min'}
                    limit={3}
                    withTitle={false}
                />
            ) : (
                <UsersRating minHeight={'min'} limit={3} withTitle={false} />
            )} */}
      <Link to="/competencia">
        <Button variant="contained" color="primary" size="small" fullWidth>
          Ver la clasificación
        </Button>
      </Link>
    </Container>
  );
};

export default DracmaResume;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: max-content;

  @media (max-width: 768px) {
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

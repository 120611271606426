import styled from "styled-components";
import React from "react";
import CardSubject from "../../molecules/subject/CardSubject";
import { Button } from "@mui/material";
// import CardOa from "../../molecules/subject/CardOa";

const GridCardSubjects = (props) => {
  const { filterValues, enrollment, tabsFake } = props;

  return (
    <>
      <GridWrapper grid={enrollment?.length === 1 ? true : false}>
        {!!filterValues ? (
          <>
            {filterValues.map((subject) => {
              if (subject.type === "asignatura")
                return <CardSubject values={subject} />;
            })}
          </>
        ) : (
          <>
            {enrollment.map((subject) => {
              if (subject.type === "asignatura")
                return <CardSubject values={subject} />;
            })}
          </>
        )}
      </GridWrapper>
      {(filterValues?.length === 0 || enrollment?.length === 0) && (
        <WrapperNotFound>
          <NotFound>
            No te has matriculado en ninguna asignatura. Por favor selecciona
            una asignatura de tu plan de estudios
          </NotFound>
          <ButtonFake
            color="primary"
            variant="contained"
            onClick={() => {
              tabsFake(1);
            }}
          >
            Mis Programas
          </ButtonFake>
        </WrapperNotFound>
      )}
    </>
  );
};

export default GridCardSubjects;

const GridWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  place-content: ${(p) => (p.grid ? "start" : "center")};
  gap: 1rem;
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const WrapperNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const NotFound = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cccccc;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

const ButtonFake = styled(Button)`
  width: fit-content;
`;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { microlearningsActions } from "../../redux/actions";

const useMicrolearning = () => {
  const dispatch = useDispatch();
  const { microlearnings, errorList } = useSelector(
    (state) => state.microlearnings
  );

  useEffect(() => {
    !microlearnings &&
      dispatch(microlearningsActions.getMicrolearningsRequest());
  }, []);

  return { microlearnings, error: errorList };
};

export default useMicrolearning;

import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";

const SearchChallenges = (props) => {
    const { searchValue, handleSearchValue } = props;

    const [onFocus, setOnFocus] = useState(false);

    const handleFocus = () => {
        setOnFocus(!onFocus);
    };

    return (
        <Wrapper>
            <CustomInput shadow={onFocus} htmlFor="searchChallenges">
                <SearchIcon />
                <input
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    id="searchChallenges"
                    type="text"
                    onChange={(e) => {
                        handleSearchValue(e.target.value);
                    }}
                    value={searchValue}
                    placeholder="Buscar..."
                />
            </CustomInput>
        </Wrapper>
    );
};

export default SearchChallenges;

const Wrapper = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const CustomInput = styled.label`
    width: 325px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #fff;
    gap: 0.5rem;
    transition: all 0.3s ease-in-out;
    box-shadow: ${(p) =>
        p.shadow
            ? `0px 8px 8px -4px rgba(24, 39, 75, 0.08),
        0px 4px 6px -4px rgba(24, 39, 75, 0.12)`
            : "none"};

    svg {
        color: #a8a8a8;
        font-size: 1.5rem;
    }

    input {
        outline: none;
        border: none;
        font-size: 1rem;
        color: #a8a8a8;
    }
`;

import React from "react";
import styled from "styled-components";
import BusinessCard from "../../../../components/templates/business/components/BusinessCard";
import { checkRoles } from "../../../../helpers/roles.helper";
import useOndemand from "../../../../hooks/common/useOndemand";
import Section from "./components/Section";
import { useSelector } from "react-redux";

const BusinessSlider = () => {
  const { competitor } = useOndemand();

  const ondemandAcademic = useSelector((state) => state.ondemand.academic);
  const ondemand = useSelector((state) => state?.ondemand?.ondemand);

  const breakPoints = [
    { width: 500, itemsToShow: 1},
    { width: 1200, itemsToShow: 3 },
    { width: 1400, itemsToShow: 3 },
    { width: 1500, itemsToShow: 3.5 },
    { width: 1600, itemsToShow: 3.8 },
    { width: 1700, itemsToShow: 4.2 },
    { width: 1800, itemsToShow: 4.5 },
    { width: 2000, itemsToShow: 5 }
  ]

  if (!competitor.aden || !competitor.business) {
    return <Skeleton h="515px" w="100%" />;
  }

  return (
    <>
      {(competitor.aden !== null || competitor.business !== null) &&
        ondemand !== null &&
        (competitor.aden.length || competitor.business.length > 0) ? (
        <>
          {checkRoles(["ondemand"]) && (
            <Section
              title="Cursos empresa"
              tabsArray={null}
              arrayLength={10}
              array={[...ondemandAcademic.business, ...ondemandAcademic.aden]}
              Card={BusinessCard}
              width="100%"
              heigth="400px"
              cardWidth={300}
              breakPoints={breakPoints}
              activeTab={0}
              setActiveTab={() => console.log(0)}
              to={`/mi-empresa/${0}`}
              marginBottom="50px"
            />

          )}
        </>
      ) : null}
    </>
  );
};

export default BusinessSlider;

const Skeleton = styled.div`
  background-color: #ededed;
  border-radius: 20px;
  height: ${(p) => p.h};
  width: ${(p) => p.w};
`;

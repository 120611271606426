import React from "react";
import styled from "styled-components";

import { TextField } from "@mui/material";
import NoResultsFound from "./NoResultsFound";

const EmptyGridResource = (props) => {
  const { Card, propsValues } = props;
  // RETURN
  return (
    <>
      <Filters>
        <TextField size="small" label="" placeholder="Buscar..." />
      </Filters>
      {propsValues.length === 0 ? (
        <NoResultsFound />
      ) : (
        <GridValues>
          {propsValues.map((value, index) => (
            <Card key={index} />
          ))}
        </GridValues>
      )}
    </>
  );
};

export default EmptyGridResource;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridValues = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;

  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`;

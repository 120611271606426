import React from "react";
import styled from "styled-components";

import Text from "../../../common/Text";

const TicketText = (props) => {
  const { title, text } = props;

  // RETURN
  return (
    <Container>
      <Text fontSize="0.9rem" fontWeight="700" color="primary" component="h2">
        {title}
      </Text>
      <Text fontSize="1rem" component="p">
        {text.charAt(0).toUpperCase() + text.slice(1)}
      </Text>
    </Container>
  );
};

export default TicketText;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

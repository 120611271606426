import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import SimpleLoading from "../../../../../components/common/SimpleLoading"

// Material UI
import { Box, Button, Modal } from "@mui/material";

// Hooks
import useDeleteNote from "./hooks/useDeleteNote";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { subjectsActions } from "../../../../../redux/actions";

// Assets
import img from "../../../../../assets/media/aden/modal-delete-note.png";

const ModalDeleteNote = (props) => {
    
    const { isConfirm, handleConfirm, id, getNotes, resetUpdateNotes } = props;

    const { deleteNote, error, loading } = useDeleteNote();

    const { activeRol } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (error === false) {
            getNotes();
            resetUpdateNotes();
            if (activeRol !== "ondemand") {
                dispatch(subjectsActions.getSubjectNotesRequest());
            }
            handleConfirm(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Modal
            open={isConfirm}
            onClose={() => handleConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <CustomBox sx={style}>
                <Image>
                    <img src={img} alt="Imagen modal" />
                </Image>
                <Body>
                    {error ? (
                        <h2>
                            No se pudo eliminar este apunte. Intente nuevamente.
                        </h2>
                    ) : (
                        <h2>¿Estás seguro que deseas eliminar este apunte?</h2>
                    )}
                    <Buttons>
                        {loading ? (
                            <SimpleLoading />
                        ) : (
                            <>
                                {
                                    activeRol === "ondemand" ?
                                        <CustomButton
                                            fullWidth
                                            style={{ backgroundColor: "var(--primary-one)" }}
                                            activeRol={activeRol}
                                            variant="contained"
                                            onClick={() => deleteNote(id)}
                                        >
                                            Eliminar apunte
                                        </CustomButton>
                                        :
                                        <CustomButton
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            onClick={() => deleteNote(id)}
                                        >
                                            Eliminar apunte
                                        </CustomButton>
                                }
                            </>
                        )}
                        {
                            activeRol === "ondemand" ?
                                <CustomButton
                                    onClick={() => handleConfirm(false)}
                                    fullWidth
                                    style={{ color: "var(--primary-one)" }}
                                >
                                    Cancelar
                                </CustomButton>
                                :
                                <CustomButton
                                    onClick={() => handleConfirm(false)}
                                    fullWidth
                                    color="primary"
                                >
                                    Cancelar
                                </CustomButton>
                        }
                    </Buttons>
                </Body>
            </CustomBox>
        </Modal>
    );
};

export default ModalDeleteNote;

const CustomBox = styled(Box)`
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 70%;
    }
`;

const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        width: auto;

        @media (max-width: 768px) {
            width: 80%;
        }
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;

    h2 {
        text-align: center;
        color: #616161;
    }
`;

const Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`;

const CustomButton = styled(Button)``;

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MainBlog from "../../components/blog/MainBlog";
import { titleHTML } from "../../helpers/title.helper";
import useLayout from "../../hooks/useLayout";

const Blog = () => {
  titleHTML("Blog acrópolis");

  const titleAcropolis =
    useSelector((state) => state?.ondemand?.ondemand?.acropolis_title) || "";

  const { setMenu } = useLayout()

  setMenu(`Blog ${titleAcropolis}`)

  return (
    <Container>
      <MainBlog />
    </Container>
  );
};

export default Blog;

const Container = styled.div`
  
`;

import React from "react";
import styled from "styled-components";

// Material UI
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";

const PageFinish = (props) => {
  const { presentation, handleNewTry } = props;

  const confetti = require("../../../../../assets/media/evaluation/confetti.gif");

  return (
    <CalificationWrapper>
      <BgGif src={confetti} />
      <LogoCheck>
        <CheckIconFake />
      </LogoCheck>
      <Title>{presentation.name}</Title>
      <Number>Tu entrega se realizó con éxito</Number>
      <Footer>
        <Button onClick={handleNewTry} variant="contained" color="primary">
          <ReloadIcon /> Volver hacia atras
        </Button>
      </Footer>
    </CalificationWrapper>
  );
};

export default PageFinish;

const CalificationWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const LogoCheck = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3bd39c;
  font-size: 2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3rem 0;
  z-index: 2;
`;

const Number = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  z-index: 2;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

const ReloadIcon = styled(ArrowBackIcon)`
  transform: scale(0.7);
  z-index: 2;
`;

const CheckIconFake = styled(CheckIcon)`
  transform: scale(2);
  z-index: 2;
`;

const BgGif = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  filter: opacity(5%);
`;

import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import FormActiveAccount from "./FormActiveAccount";
const ModalActiveAccount = (props) => {
    const { primaryColor, isOpen, handleCloseModal } = props;

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <Wrapper>
                <CloseIconFake onClick={handleCloseModal} />
                <Header>
                    <Col color={primaryColor}>
                        <h2>Activación de mi cuenta</h2>
                    </Col>
                    <p>
                        <span>Coloca el código asignado</span> y <span>completa el formulario</span> con tus datos para activar tu cuenta.
                    </p>
                </Header>
                <FormActiveAccount primaryColor={primaryColor} handleCloseModal={handleCloseModal} />
            </Wrapper>
        </Modal>
    );
};

export default ModalActiveAccount;

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 607px;
    max-width: calc(90vw - 4rem);
    height: 835px;
    max-height: calc(90vh - 4rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    padding: 2rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    align-items: center;
    gap: 2rem;
    text-align: start;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1D1D1B;
        letter-spacing: 0.0015em;
    }

    span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #1D1D1B;
        letter-spacing: 0.0015em;
    }

    @media (max-width: 768px){ 
        width: 100%;
        text-align: center;
    }

`;

const Col = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.0015em;
        color: ${(p) => p.color}
    }

    @media (max-width: 768px) {
        h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 29px;
            letter-spacing: 0.0015em;
        }
    }
`;

const CloseIconFake = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #616161;
`;

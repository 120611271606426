import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPublicInfoForEmail } from "../../redux/api/auth";
import styled from "styled-components";
import Navbar from "../../components/ui/organisms/certificate/Navbar";
import { Card } from "@mui/material";
import InfoUser from "../../components/templates/refered/InfoUser";
import FormRefered from "../../components/templates/refered/FormRefered";
import { getCountries } from "../../redux/api/utils";

const Referred = () => {
  const { id } = useParams();

  const [student, setStudent] = useState(null);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    if (!student && id !== undefined) {
      getData(id);
    }
    !countries && getCountry();
  }, [student, countries]);

  const getData = async (email) => {
    const request = await getPublicInfoForEmail(email);
    if (request.error) {
      setStudent(false);
    } else {
      setStudent(request);
    }
  };

  const getCountry = async () => {
    try {
      const request = await getCountries();
      setCountries(request.data.response_data);
    } catch (error) {}
  };

  return (
    <Page>
      <Navbar />
      <Container>
        <CardFake h="369px">
          <InfoUser student={student} id={id} getData={getData} />
        </CardFake>
        <CardFake h="557px">
          <FormRefered student={student} countries={countries} />
        </CardFake>
      </Container>
    </Page>
  );
};

export default Referred;

const Container = styled.div`
  padding: 7rem 12rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }
`;

const Page = styled.div`
  width: 100%;
  height: auto;
  /* overflow: scroll; */
`;

const CardFake = styled(Card)`
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  background-color: white;
  max-height: ${(p) => p.h};
  @media (max-width: 768px) {
    max-height: 100%;
    height: 100%;
  }
`;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgressFake variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <TypographyFake
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >{`${Math.round(props.value / 10)}`}</TypographyFake>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function TimerProgress(props) {
    const { handleNext, player, dispatchState, handleStop } = props;
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const timer = setInterval(async () => {
            handleStop();
            let isPaused = await player.getPaused();
            if (isPaused) {
                setProgress((prevProgress) =>
                    prevProgress <= 0 ? 0 : prevProgress - 10
                );
                dispatchState({ displayCard: true });
            } else {
                dispatchState({ displayCard: false });
            }
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress === 0) {
            handleNext();
        }
    }, [progress]);

    return <CircularProgressWithLabel value={progress} />;
}

const TypographyFake = styled(Typography)`
    color: white;
    font-weight: 600;
`;

const CircularProgressFake = styled(CircularProgress)`
    color: white;
`;

import { Skeleton } from '@mui/material';
import React from 'react'

const SkeletonLoading = (props) => {

  const { propsSkl } = props;

  return (
    <Skeleton
      variant={propsSkl.variant}
      animation={propsSkl.animation}
      width={propsSkl.width}
      height={propsSkl.height}
    />
  )
}


export default SkeletonLoading
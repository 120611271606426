import React, { useRef, useState } from "react";
import styled from "styled-components";

import {
    Avatar,
    ClickAwayListener,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
    ExpandMoreRounded,
    PersonRounded,
    SupervisorAccountRounded,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getRolActive } from "../../../helpers/roles.helper";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DracmaItem from "./DracmaItem";
import useTheme from "../../../hooks/useTheme";
import DirectionsBusRoundedIcon from "@mui/icons-material/DirectionsBusRounded";
import { Favorite } from "@mui/icons-material";

const MenuUser = () => {
    // REDUX
    const { user, activeRol } = useSelector((state) => state.auth);
    const ondemand = useSelector((state) => state.ondemand.ondemand);

    // STATE
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    // FUNCTIONS
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    let localStorageWTKey = "localStorageWTKey";

    // RETURN
    return (
        <div>
            <FakeButton
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                data-tut="reactour__menu_user"
            >
                <Avatar alt="user-avatar" src={user?.foto || user?.image} />
                <FakeExpandMoreRounded fontSize="small" />
            </FakeButton>
            <FakePopper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <FakeGrow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <FakeMenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                >
                                    {
                                        <NewMenuItem
                                            to={
                                                activeRol === "docente"
                                                    ? "/profesor/perfil"
                                                    : "/perfil"
                                            }
                                            icon={<PersonRounded />}
                                            label="Ver mi perfil"
                                            handleClose={handleClose}
                                        />
                                    }
                                    {getRolActive("docente") && (
                                        <NewMenuItem
                                            to={"/profesor/faculty"}
                                            icon={<AccountBalanceIcon />}
                                            label="Honorarios y liquidaciones"
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {(getRolActive("Admin") ||
                                        getRolActive("alumno")) && (
                                        <NewMenuItem
                                            to="/informacion-academica"
                                            icon={<SchoolIcon />}
                                            label="Información académica"
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {(getRolActive("Admin") ||
                                        getRolActive("alumno")) && (
                                        <NewMenuItem
                                            to="/mi-academic-advisor"
                                            icon={<SupervisorAccountRounded />}
                                            label="Mi Academic Advisor"
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {/* <NewMenuItem
                                        to="/peticiones-reclamos"
                                        icon={<ConfirmationNumberIcon />}
                                        label="Peticiones y reclamos"
                                        handleClose={handleClose}
                                    /> */}
                                    {
                                        <NewMenuItem
                                            to={"/mis-referidos"}
                                            icon={<PersonAddIcon />}
                                            label="Mis referidos"
                                            handleClose={handleClose}
                                        />
                                    }
                                    {(getRolActive("Admin") ||
                                        getRolActive("alumno") ||
                                        getRolActive("visita") ||
                                        getRolActive("ondemand") ||
                                        getRolActive("docente")) && (
                                        <NewMenuItem
                                            to="/favoritos"
                                            icon={<Favorite />}
                                            label="Mis favoritos"
                                            handleClose={handleClose}
                                        />
                                    )}
                                    {ondemand ? (
                                        ondemand.enable_dracma === true ? (
                                            <DracmaItem
                                                handleClose={handleClose}
                                                dracma_coin_name={
                                                    ondemand
                                                        ? ondemand.dracma_coin_name
                                                        : undefined
                                                }
                                            />
                                        ) : null
                                    ) : (
                                        <DracmaItem
                                            handleClose={handleClose}
                                            dracma_coin_name={
                                                ondemand
                                                    ? ondemand.dracma_coin_name
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {(getRolActive("alumno") ||
                                        getRolActive("ondemand")) && (
                                        <NewMenuItem
                                            to="/"
                                            icon={<DirectionsBusRoundedIcon />}
                                            label="Tour de Bienvenida"
                                            handleClose={handleClose}
                                            onClick={window.localStorage.setItem(
                                                localStorageWTKey,
                                                "seeTourAgain"
                                            )}
                                        />
                                    )}
                                    {/* <ChangeRol/> */}
                                    <NewMenuItem
                                        to="/logout"
                                        icon={<ExitToAppIcon />}
                                        label="Cerrar sesión"
                                        handleClose={handleClose}
                                    />
                                </FakeMenuList>
                            </ClickAwayListener>
                        </Paper>
                    </FakeGrow>
                )}
            </FakePopper>
        </div>
    );
};

export default MenuUser;

const NewMenuItem = (props) => {
    const { to, label, icon, handleClose } = props;
    const { primary } = useTheme();

    return (
        <FakeLink to={to} activeClassName="active" onClick={handleClose}>
            <FakeMenuItem color={primary}>
                <ListItemIcon>{icon}</ListItemIcon>
                <p>{label}</p>
            </FakeMenuItem>
        </FakeLink>
    );
};

const FakeLink = styled(NavLink)`
    &.active {
        svg,
        span,
        p {
            color: ${(p) => p.color};
        }
    }
`;

const FakeButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 12px;
    color: #bfbfbf;
    cursor: pointer;
    background-color: var(--secondary-one);
`;

const FakePopper = styled(Popper)`
    &.MuiPopperUnstyled-root {
        top: 1.5rem !important;
        padding-right: 1rem !important;
    }
`;

const FakeExpandMoreRounded = styled(ExpandMoreRounded)`
    position: absolute;
    background-color: var(--secondary-one);
    border-radius: 50%;
    right: 0;
    bottom: 0;
`;

const FakeGrow = styled(Grow)`
    border-radius: 20px;
    padding: 0;
    box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
`;

const FakeMenuList = styled(MenuList)`
    padding: 0;
`;

const FakeMenuItem = styled(MenuItem)`
    padding: 0.5rem 2rem;
    border-radius: 20px;
    .MuiListItemIcon-root {
        min-width: 2rem;
        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }

    .material-icons-round {
        padding: 0.2rem;
    }

    p {
        font-size: 0.9rem;
        margin: 0;
        padding: 0.9rem;

        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }

    svg {
        color: var(--secondary-four);
        width: 28px;
    }

    span,
    p {
        color: var(--secondary-four);
    }

    :hover {
        svg,
        span,
        p {
            color: ${(p) => p.color};
        }
    }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import SimpleReview from "./SimpleReview";

// Material UI
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckIcon from "@mui/icons-material/Check";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Redux
import { getDataEvaluationService } from "../../../../../redux/api/courses";
import { useSelector } from "react-redux";

// Assets
import Confetti from "../../../../../assets/media/evaluation/confetti.gif";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PageCalification = (props) => {
  const {
    qualification,
    evaluation,
    handleNewTry,
    handleReview,
    objId,
    resourceId,
    idEvaluacion,
  } = props;

  const user = useSelector((state) => state.auth.user);

  const [open, setOpen] = useState(false);
  const [submission, setSubmission] = useState(null);

  const handleClickOpen = (sub) => {
    setSubmission(sub);
    setOpen(true);
  };

  const handleClose = () => {
    setSubmission(null);
    setOpen(false);
  };

  useEffect(() => {
    if (submission !== null) {
      handleClickOpen(submission);
    }
  }, [submission]);

  const handlePromp = () => {
    const result = window.confirm(
      "Una vez vista la revisión no podrás volver a intentarlo. ¿Deseas ver la revisión?"
    );
    if (result) {
      handleReview();
    }
  };

  const handleView = () => {
    getDataPresentation({
      id: idEvaluacion,
      user: user.repo_id,
      obj: objId,
      reso: resourceId,
    });
  };

  const getDataPresentation = async (data) => {
    const response = await getDataEvaluationService(data);
    if (!response) {
      setSubmission(false);
    } else {
      setSubmission(response.submissions[response.submissions.length - 1]);
    }
  };

  return (
    <>
      <CalificationWrapper>
        <BgGif src={Confetti} />
        <LogoCheck>
          <CheckIconFake />
        </LogoCheck>
        <Title>{evaluation.name}</Title>
        <Subtitle>tu calificación</Subtitle>
        <Number>
          {qualification.nota} / {qualification.total_puntaje}
        </Number>
        <Footer>
          <WrapperUpButtons>
            <ButtonFake onClick={handleView} variant="outlined" color="primary">
              Ver mi entrega
            </ButtonFake>
            {evaluation?.mostrar_respuestas_correctas && (
              <ButtonFake
                onClick={handlePromp}
                variant="outlined"
                color="primary"
              >
                Ver respuestas correctas
              </ButtonFake>
            )}
          </WrapperUpButtons>
          <WrapperBottomButtons>
            <ButtonFake
              onClick={handleNewTry}
              disabled={evaluation.cantidad_intentos - 1 > 0 ? false : true}
              startIcon={<ReloadIcon />}
              variant="contained"
              color="primary"
            >
              Volver a intentar
            </ButtonFake>
          </WrapperBottomButtons>
        </Footer>
      </CalificationWrapper>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SimpleReview submission={submission} />
      </Dialog>
    </>
  );
};

export default PageCalification;

const CalificationWrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const LogoCheck = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3bd39c;
  font-size: 2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3rem 0;
  z-index: 2;
`;

const Subtitle = styled.h6`
  font-size: 1rem;
  font-weight: 700;
  color: #616161;
  text-transform: uppercase;
  z-index: 2;
`;

const Number = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  z-index: 2;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: auto;
  padding: 0 0.5rem;
  z-index: 2;
`;

const WrapperUpButtons = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: row;
`;
const WrapperBottomButtons = styled.div`
  display: grid;
  width: 100%;
`;

const ButtonFake = styled(Button)`
  width: 100%;
  font-size: 1rem !important;
  svg {
    font-size: 1.8rem !important;
  }
`;

const ReloadIcon = styled(ReplayIcon)`
  transform: scale(0.7);
  z-index: 2;
`;

const CheckIconFake = styled(CheckIcon)`
  transform: scale(2);
  z-index: 2;
`;

const BgGif = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  filter: opacity(5%);
`;

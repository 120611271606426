import React, { useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

// Material UI
import { Button } from "@mui/material";

const TextQuillInput = (props) => {

  const { handleSubmitText, delivery } = props;

  const [text, setText] = useState("");

  const handleChange = (e) => {
    setText(e);
  };

  return (
    <>
      <Container>
        <ReactQuill
          style={{ height: "250px" }}
          value={text}
          onChange={handleChange}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["link" /*, 'image'*/],
                [{ color: [] }],
              ],
            },
          }}
        />
      </Container>
      <CommentContainer>
        <p>
          Escribe un comentario sobre tu entrega
        </p>
        <textarea />
      </CommentContainer>
      <Footer>
        <Button
          disabled={text !== "" && !delivery ? false : true}
          variant="contained"
          color="primary"
          onClick={() => handleSubmitText(text)}
        >
          Enviar
        </Button>
      </Footer>
    </>
  );
};

export default TextQuillInput;

const Container = styled.div`
  height: 300px;
  .ql-toolbar {
    border: none;
    box-shadow: 1px 0px 39px -6px rgba(174, 174, 174, 0.42);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .ql-container {
    border: none;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top: 1px solid #f1f1f1;
  }
`;

const CommentContainer = styled.div`
  width: calc(100% - 2rem);
  height: 300px;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  textarea {
    width: calc(100% - 2rem);
    height: 90%;
    border: none;
    text-align: start;
    vertical-align: top; 
    padding: 1rem;
    resize: none;
    border-radius: 20px;

    :focus {
      outline: none;
    }
  }
`

const Footer = styled.div`
  width: calc(100% - 1rem);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`;

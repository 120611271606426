import { useEffect, useRef } from "react";

const OutsideClickHandler = ({ onOutsideClick, children }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                // Se hizo clic fuera del contenedor
                onOutsideClick();
            }
        };

        // Agrega un escuchador de eventos al documento
        document.addEventListener('click', handleOutsideClick);

        // Limpia el escuchador de eventos cuando el componente se desmonta
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onOutsideClick]);

    return <div ref={containerRef}>{children}</div>;
};

export default OutsideClickHandler;
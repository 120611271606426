import { Card } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";

const CertificateHtml = (props) => {
  const { html } = props;

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  return (
    <CardFake>
      <CardCertificate id="toPdf">{parse(sanitizerHtml(html))}</CardCertificate>
    </CardFake>
  );
};

export default CertificateHtml;

const CardCertificate = styled.div`
  width: 100%;
  border: 1px solid #ffffff7a;
  overflow-x: auto;
`;

const CardFake = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

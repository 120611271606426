import React from "react";
import styled from "styled-components";

import { SearchRounded } from "@mui/icons-material";

const NoResultsFound = (props) => {
  const {
    message = "¡Ups! No encontramos lo que buscabas, intenta una búsqueda diferente.",
    minHeight = null
  } = props;

  return (
    <NoResultsFoundContainer minHeight={minHeight}>
      <SearchRounded fontSize="large" />
      <Text>{message}</Text>
    </NoResultsFoundContainer>
  );
};

export default NoResultsFound;

const NoResultsFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  width: calc(100% - 4rem);
  padding: 2rem;
  text-align: center;
  background-color: #ffffff;
  color: #bfbfbf;
  border-radius: 20px;
  min-height: ${(props) => props.minHeight};
`;

const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

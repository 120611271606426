import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GridCard from "../../../common/GridCard";
import { Text } from "../../../common/Texts";
import CardTask from "../../molecules/progress/CardTask";
import { SearchRounded } from "@mui/icons-material";

const GridTasks = (props) => {
  const { tasks, oa } = props;

  const [filterField, setFilterField] = useState("");
  const [taskFilters, setTaskFilters] = useState(null);

  useEffect(() => {
    setTaskFilters(null);
    setFilterField("");
  }, [oa]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilterField(value);
    if (value === "") {
      setTaskFilters(null);
      return;
    }
    setTaskFilters(
      tasks.filter((program) =>
        program.activity.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <Text color="#777" fontWeight="bold" fontSize="16px">
        Mis tareas de {oa?.name || "indefinido"}
      </Text>
      <Filters>
        <TextField
          value={filterField}
          onChange={handleChange}
          variant="outlined"
          size="small"
          label="Buscar tarea"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
      </Filters>
      <GridCard
        cards={taskFilters || tasks || []}
        component={CardTask}
        width={"150px"}
      />
    </div>
  );
};

export default GridTasks;

const Filters = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { darken } from "polished";
import styled from "styled-components";
import { titleHTML } from "../../helpers/title.helper";
import Calendar from "./components/Calendar";
import { Text } from "../../components/common/Texts";
import EventsDateList from "./components/EventsDateList";
import { getOndemandEvents } from "../../redux/api/events/events";
import { getEventType } from "./hooks/useTypeEvent";

const ScheduleInCompany = () => {
  titleHTML("calendario");

  //REDUX
  const ondemandId = useSelector((state) => state?.auth?.user?.ondemand_id);
  const { primary_color } = useSelector((state) => state?.ondemand?.ondemand);

  const [events, setEvents] = useState(null);
  const [datesToEvents, setDatesToEvents] = useState([]);

  useEffect(() => {
    getCompanyEvents(ondemandId);
  }, []);

  // FUNCTIONS
  const getCompanyEvents = async (ondemandId) => {
    const response = await getOndemandEvents(ondemandId);
    if (response.error || response === "No se encontraron eventos") {
      setEvents([]);
      return;
    }
    setEvents(response);
    gestDatesToCalendar(response);
  };

  const gestDatesToCalendar = (events) => {
    const dates = events.reduce((acc, curr) => {
      acc.push({
        id: curr.id,
        date: moment(curr.date_begin).format("YYYY-MM-DD"),
        type: getEventType(curr?.type).type,
      });
      return acc;
    }, []);
    setDatesToEvents(dates);
  };

  return (
    <>
      <ScheduleContainer>
        <Header backgroundColor={primary_color}>
          <Text color="#ffffff" fontSize="24px" fontWeight="400">
            Próximos eventos
          </Text>
        </Header>
        <Body>
          <Calendar dates={datesToEvents} />
          <EventsDateList allEevents={events} />
        </Body>
      </ScheduleContainer>
    </>
  );
};

export default ScheduleInCompany;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 650px;
  max-width: 1500px;
  margin: auto;
  row-gap: 0.5rem;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -0px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  border-radius: 20px 20px 0 0;
  display: grid;
  place-items: center;
  padding: 1.3rem;
  background: ${(p) =>
    `linear-gradient(180deg, ${p.backgroundColor}, ${darken(
      0.015,
      p.backgroundColor
    )})`};

  margin: 0;
`;

const Body = styled.section`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 2rem;
  height: auto;
  padding: 1.5rem;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 3rem);
  }
`;

export async function encryptObject(obj, key) {
    const enc = new TextEncoder();
    const keyData = enc.encode(key).slice(0, 32);
    const iv = new Uint8Array(12); 

    const cryptoKey = await crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'AES-GCM' },
        false,
        ['encrypt']
    );
    
    const encodedObj = enc.encode(JSON.stringify(obj));
    const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        cryptoKey,
        encodedObj
    );
    
    const encryptedArray = new Uint8Array(encrypted);
    const ivAndEncrypted = new Uint8Array(iv.byteLength + encryptedArray.byteLength);
    ivAndEncrypted.set(iv);
    ivAndEncrypted.set(encryptedArray, iv.byteLength);

    return btoa(String.fromCharCode.apply(null, ivAndEncrypted)); // Codifica en base64
}

export async function decryptObject(encryptedString, key) {
    const enc = new TextEncoder();
    const keyData = enc.encode(key).slice(0, 32);

    const cryptoKey = await crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'AES-GCM' },
        false,
        ['decrypt']
    );

    const encryptedArray = new Uint8Array(atob(encryptedString).split('').map(char => char.charCodeAt(0)));

    const iv = encryptedArray.slice(0, 12);
    const data = encryptedArray.slice(12);

    const decrypted = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: iv },
        cryptoKey,
        data
    );

    return JSON.parse(new TextDecoder().decode(decrypted));
}
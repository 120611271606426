import React, { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import { Span, Text } from "../../../../../components/common/Texts";
import SimpleLoading from "../../../../../components/common/SimpleLoading";

// Material UI
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DefaultCardIcon from "../../../../../components/common/DefaultCardIcon";

// Redux
import { getReviewById } from "../../../../../redux/api/courses";

const SimpleReview = (props) => {
    const { submission } = props;

    const [info, setInfo] = useState(null);

    useEffect(() => {
        !info && getReview(submission);
        //eslint-disable-next-line
    }, []);

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const getReview = async (sub) => {
        const request = await getReviewById(sub.id);
        if (request) {
            setInfo(request);
        }
    };

    if (!info) {
        return (
            <LoadingWrapper>
                <SimpleLoading />
            </LoadingWrapper>
        );
    }

    return (
        <Wrapper>
            <Container>
                <Text fontSize="24px" fontWeight="bold" textAlign="Center">
                    Revisión de actividad
                </Text>
                <Text textAlign="Center" fontSize="18px">
                    {info?.activity?.name}
                </Text>
                <Information>
                    <DefaultCardIcon
                        icon={<CalendarTodayIcon />}
                        color="#5E80DB"
                        subtitle={"Fecha"}
                        title={new Date(
                            info?.create_date
                        )?.toLocaleDateString()}
                    />
                    <DefaultCardIcon
                        icon={<FormatListNumberedIcon />}
                        color="#5E80DB"
                        subtitle={"Puntaje"}
                        title={info?.grade + "/100"}
                    />
                </Information>
                <Col>
                    {info.answers.map((item, index) => {
                        return (
                            <Contain isCorrect={item.correct}>
                                <Question>
                                    <Span color="#b31d15">{index + 1}</Span>{" "}
                                    {parse(sanitizerHtml(item.question))}
                                </Question>
                                <Text>
                                    {item.answer.length > 1
                                        ? "respuestas:"
                                        : "respuesta:"}
                                </Text>
                                {item.answer.map((answer) => (
                                    <Answer key={answer.election}>
                                        <ArrowRightIcon />
                                        {answer.election}
                                        <IsCorrect
                                            isCorrect={answer.correct_election}
                                        >
                                            {answer.correct_election ? (
                                                <CheckCircleOutlineIcon />
                                            ) : (
                                                <CancelIcon />
                                            )}
                                        </IsCorrect>
                                    </Answer>
                                ))}
                            </Contain>
                        );
                    })}
                </Col>
            </Container>
        </Wrapper>
    );
};

export default SimpleReview;

const Wrapper = styled.div`
    margin-top: 70px;
    padding: 12px;
`;

const Container = styled.div`
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 15px;
    margin-top: 15px;
`;

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Contain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    position: relative;
    padding: 15px;
    position: relative;
    overflow: hidden;

    ::after {
        content: "";
        height: 70px;
        width: 18px;
        background-color: black;
        transform: rotateZ(45deg);
        position: absolute;
        bottom: -20px;
        right: 0;
        background-color: ${(p) => (p.isCorrect ? "#C3FAEB" : "#FFE6EB")};
    }
    border: solid 1px ${(p) => (p.isCorrect ? "#35D0A5" : "#F8423F")};
`;
const IsCorrect = styled.div`
    svg {
        color: ${(p) => (p.isCorrect ? "#35D0A5" : "#F8423F")};
        font-size: 1.8rem;
    }
`;
const Question = styled.div`
    display: flex;
    gap: 12px;
`;

const Information = styled.div`
    gap: 15px;
    width: 50%;
    margin: 24px auto;
    display: flex;
    justify-content: space-between;
`;

const Answer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
`;

const Col = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
`;
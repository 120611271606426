import axios from "axios";

export async function getCalifications(repo_id, idCourse) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/student_activities/${idCourse}/${repo_id}`;

  try {
    const response = await axios.get(URL);
    return response.data.response_data.courses;
  } catch (error) {
    return { error };
  }
}

import styled from "styled-components";
import AnnouncementsBlog from "./components-announcements/AnnouncementsBlog";
import SliderMicrolearning from "./components-announcements/SliderMicrolearning";
import { useSelector } from "react-redux";

const Announcements = () => {

    const { resources } = useSelector((state) => state.ondemand);

    return (
        <Container>
            <Title>
                Anuncios
            </Title>
            <Wrapper>
                <AnnouncementsBlog />
                <SliderMicrolearning resources={resources} />
            </Wrapper>
        </Container>
    )
}

export default Announcements;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    visibility: auto;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-one);
`

const Wrapper = styled.div`
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: space-between;

    @media (width < 768px) {
        height: fit-content;
        flex-direction: column;
        gap: 2rem;
    }
`
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import { Text } from "../../components/common/Texts";
import { getHomeByRol } from "../../helpers/roles.helper";
import { authActions, ondemandActions } from "../../redux/actions";

const LoadingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rol } = useParams();
  const { loadingGetUser, activeRol } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!loadingGetUser && activeRol !== rol) {
      dispatch(ondemandActions.setOndemand());
      dispatch(authActions.getDataRequest(rol));
    }
    if (!loadingGetUser && activeRol === rol) {
      history.push(getHomeByRol(rol));
    }
  }, [loadingGetUser, activeRol]);

  return (
    <Wrapper>
      <SimpleLoading />
      <Text color="#591AB6" fontWeight="bold" fontSize="16px">
        Cambiando el entorno...
      </Text>
    </Wrapper>
  );
};

export default LoadingPage;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 9999;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  background-color: white;
`;

import React, { useEffect } from "react";
import styled from "styled-components";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleLoading from "../../components/common/SimpleLoading";
import { microlearningsActions } from "../../redux/actions";
import Video from "../../components/templates/microlearnings/Video";
import ScrollMicrolearnings from "../../components/templates/microlearnings/ScrollMicrolearnings";
import useTheme from "../../hooks/useTheme";
import { titleHTML } from "../../helpers/title.helper";
import useLayout from "../../hooks/useLayout";

const Microlearnings = () => {
    titleHTML("Microlearnings");

    const { primary } = useTheme();
    const id = useParams();

    // REDUX
    const dispatch = useDispatch();
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const currentMicrolearning = useSelector(
        (state) => state.microlearnings.currentMicrolearning
    );
    const { setMenu } = useLayout()

    // ! ========= Recarga la página completa =========
    // Solución temporal, buscar otro reproductor/solución
    const reloadCounter = Number(sessionStorage.getItem("reloadCounter")) || 0;

    useEffect(() => {
        if (reloadCounter < 1) {
            sessionStorage.setItem("reloadCounter", String(reloadCounter + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem("reloadCounter");
        }
    }, []);

    // EFFECTS
    useEffect(() => {
        if (microlearnings === null) {
            dispatch(microlearningsActions.getMicrolearningsRequest());
        }
        if (microlearnings !== null) {
            dispatch(microlearningsActions.loadMicrolearning(id));
        }
    }, [microlearnings]);

    /* useEffect(() => {
        console.info("name", currentMicrolearning?.publication_name)
        if (currentMicrolearning) {
            setMenu(currentMicrolearning?.publication_name)
        }
        
    }, [currentMicrolearning]) */

    // FUNCTIONS
    function handleCurrentMicrolearning(newMicrolearning) {
        dispatch(microlearningsActions.setMicrolearning(newMicrolearning));
    }

    // RETURN
    if (currentMicrolearning === null) {
        return <SimpleLoading padding="4rem" />;
    }
    return (
        <MicrolearningsContainer>
            <Title color={primary}>
                {currentMicrolearning.publication_name}
            </Title>
            <MicrolearningsMain
                isSimulator={
                    currentMicrolearning.categoria === 45 ? true : false
                }
            >
                <FirstColumn>
                    <Video currentMicrolearning={currentMicrolearning} />
                </FirstColumn>
                {currentMicrolearning.categoria !== 45 && (
                    <ScrollMicrolearnings
                        microlearnings={microlearnings}
                        currentMicrolearningId={currentMicrolearning.repo_id}
                        handleCurrentMicrolearning={handleCurrentMicrolearning}
                    />
                )}
            </MicrolearningsMain>
        </MicrolearningsContainer>
    );
};

export default Microlearnings;

const MicrolearningsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: ${(p) => p.color};
`;

const MicrolearningsMain = styled.div`
    display: ${(p) => (p.isSimulator ? "flex" : "grid")};
    grid-template-columns: 3fr 2fr;
    flex-direction: ${(p) => (p.isSimulator ? "column" : "row")};
    grid-gap: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

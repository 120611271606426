import React from "react";
import { IconButton } from "@mui/material";
import styled from "styled-components";

const Menu = (props) => {
  const { openMenu, setOpenMenu } = props;

  return (
    <MenuButton disableRipple={true} openMenu={openMenu} onClick={() => setOpenMenu(!openMenu)}>
      <Bar openMenu={openMenu} className="upper-bar" />
      <Bar openMenu={openMenu} className="mid-bar" />
      <Bar openMenu={openMenu} className="bottom-bar" />
    </MenuButton>
  );
};

export default Menu;

const MenuButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 50%;
  padding: 12px 12px;

  & {
    transition: all 0.5s;
    transform: ${(p) => p.openMenu ? "rotate(90deg)" : "rotate(0deg)"};
  }
`;

const Bar = styled.span`
  width: 20px;
  height: 2px;
  border-radius: 25px;
  background-color: var(--primary-two);
`;

import * as encode from "nodejs-base64-encode";
import styled from "styled-components";
import React from "react";

// Components
import DefaultCard from "../../common/DefaultCard";
import { Text } from "../../common/Texts";

// Material UI
import Button from "@mui/material/Button";

// Redux
import { viewResource } from "../../../redux/api/courses";
import { useSelector } from "react-redux";

// Assets
import ErrorImg from "../../../assets/media/aden/ticket-creation-error.png"

const MainViewSurvey = (props) => {
	const { hash } = props;

	const { user } = useSelector((state) => state.auth);

	const decodeData = encode.decode(hash, "base64");
	const surveyObject = JSON.parse(decodeData);
	console.info(surveyObject)

	const handleProgress = async () => {
		if (!surveyObject.done) {
			const body = {
				user: user.repo_id,
				survey: surveyObject.id,
			};
			const request = await viewResource(body);
			if (request.error) {
				alert("Ocurrió un error al cargar el progreso");
			}
		}
	};

	const containsHttps = /https/.test(surveyObject?.link)

	return (
		<DefaultCard>
			<Header>
				<div></div>
				<Text fontWeight="bold">
					En caso de no poder finalizar la encuesta has click en el siguiente
					botón
				</Text>
				<Button
					onClick={() => window.open(surveyObject?.link)}
					color="primary"
					variant="contained"
				>
					Ir a la encuesta
				</Button>
			</Header>
			{
				containsHttps
					? <Iframe onLoad={handleProgress} src={surveyObject?.link} />
					: <ErrorContainer>
						<img src={ErrorImg} alt="Error en la encuesta" width={500}/>
						<h3>¡Ups! Hubo un error</h3>
						<label>Encuesta no encontrada</label>
					</ErrorContainer>
			}
		</DefaultCard>
	);
};

export default MainViewSurvey;

const Iframe = styled.iframe`
	border: none;
	width: 100%;
	min-height: 670px;
	height: 100%;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	align-items: center;
`;

const ErrorContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	height: 100%;
	width: 100%;

	img {
		margin-bottom: 1rem;
	}

	h3 {
		font-size: 24px;
		font-weight: 600;
		color: #b31d15;
	}

	label {
		font-size: 20px;
		font-weight: 500;
	}
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import {
  getAssignmentRelated,
  getProgramById,
  getRelatedPrograms,
  getStudyPlanFake,
} from "../../redux/api/studyPlan";
import NotFound from "../error/NotFound";
import ProgramsCourses from "../../components/templates/studyPlan/ProgramsCourses";
// import SimpleLoading from "../../components/common/SimpleLoading";
import TabsComponent from "../../components/common/TabsComponent";
import useGetStudyPlan from "../../hooks/students/useGetStudyPlan";
import DefaultNavegation from "../../components/shared/navegation/DefaultNavegation";
import { useSelector } from "react-redux";
import * as encode from "nodejs-base64-encode";
import SkeletonPlan from "./SkeletonPlan";

const StudyPlan = () => {
  const { id } = useParams();
  const history = useHistory();

  // STATES
  const [programRelated, setProgramRelated] = useState([]);
  const [data, setData] = useState(null);
  const [dataProgram, setDataProgram] = useState(null);
  const [isRelated, setIsRelated] = useState(null);

  const hash = encode.decode(id, "base64");

  const program = hash;

  const repo_id = useSelector((state) => state.auth.user.repo_id);

  const tabs = [
    { label: "Plan de estudio", id: 0 },
    // { label: "Mis Calificaciones", id: 1 },
    // { label: "Alumnos", id: 2 },
    // { label: "Cuerpo Académico", id: 3 },
  ];

  const { tab, handleTab } = useGetStudyPlan();

  // EFFECT
  useEffect(() => {
    if (dataProgram === null) {
      getProgramByIds();
    }
  }, []);

  useEffect(() => {
    if (programRelated.length > 0) {
      getDataAssignmentRelated(programRelated[0].id);
    }
  }, [programRelated]);

  useEffect(() => {
    if (dataProgram !== null) {
      setIsRelated(dataProgram.has_related_programs);
    }
  }, [dataProgram]);

  useEffect(() => {
    if (isRelated !== null) {
      if (isRelated) {
        getProgramRelated();
      } else {
        getDataStudyPlan(dataProgram.id);
      }
    }
  }, [isRelated]);

  // FUNCTIONS

  // trae la info del programa actual
  const getProgramByIds = async () => {
    const response = await getProgramById(program);
    if (!response.error) {
      setDataProgram(response);
    } else {
      history.push("../not-found");
    }
  };

  // trae los programas relacionados
  const getProgramRelated = async () => {
    const response = await getRelatedPrograms(dataProgram.id, repo_id);
    if (!response.error) {
      setProgramRelated(response);
    }
  };

  // trae las asignaturas
  const getDataStudyPlan = async (id) => {
    const response = await getStudyPlanFake(id, repo_id);
    if (!response.error) {
      setData(response);
    } else {
      history.push("../not-found");
    }
  };

  // trae las asignaturas de planes de estudios relacionados
  const getDataAssignmentRelated = async (id) => {
    const response = await getAssignmentRelated(id, repo_id);
    if (!response.error) {
      setData(response);
    } else {
      history.push("../not-found");
    }
  };

  if (data?.error) {
    history.push("../not-found");
    return <NotFound />;
  }

  if (data === null || dataProgram === null) {
    return <SkeletonPlan />;
  }

  return (
    <Wrapper>
      <DefaultNavegation title={dataProgram.name || ""} />
      <TabsWrapper>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </TabsWrapper>
      <ContentWrapper>
        <ToggleMain
          id={hash}
          data={data}
          programRelated={programRelated}
          dataProgram={dataProgram}
          tab={tab}
          getDataStudyPlan={getDataAssignmentRelated}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default StudyPlan;

const Wrapper = styled.div`
  margin-top: 3rem;
`;

const TabsWrapper = styled.div`
  width: calc(100% - 6rem - 70px);
  position: fixed;
  left: 70px;
  top: 140px;
  z-index: 10;
  border-top: 1px solid #f7f7f7;
  padding: 0 3rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    left: 0;
    width: calc(100% - 6rem);
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  /* margin-top: 30px; */
`;

function ToggleMain(props) {
  const { tab, data, programRelated, getDataStudyPlan, id, dataProgram } =
    props;

  const isDiplomat = dataProgram?.name?.includes("Diplomado");

  switch (tab) {
    case 0:
      return (
        <ProgramsCourses
          dataProgram={dataProgram}
          data={data}
          id={id}
          programRelated={programRelated}
          getDataStudyPlan={getDataStudyPlan}
          isDiplomat={isDiplomat}
        />
      );
    default:
      return null;
  }
}

import * as types from "../types";

const initialState = {
  titulation: null,
};

export const titulation = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TITULACION_STATE:
      return {
        ...state,
        titulation: action.payload,
      };
    default:
      return state;
  }
};

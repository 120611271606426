import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    getMyCertificates,
    postGenerateBadge,
    getIndividualCertificate,
} from "../redux/api/myCertificates";
import { useParams } from "react-router-dom";

const useMyCertificate = (namePublication, isGenerateBadge, codCurso) => {
    const { partner, id } = useParams();

    // STATES
    const [certificates, setCertificates] = useState(null);
    const partnerId = useSelector((state) => state.auth.user.partner_id);
    const ondemandId = useSelector((state) => state.auth.user.ondemand_id);
    const repoId = useSelector((state) => state.auth.user.repo_id);
    const [individualCertificate, setIndividualCertificate] = useState(null);
    const [search, setSearch] = useState("");
    const [certificatesFilter, setCertificateFilter] = useState(null);

    // EFFECTS
    useEffect(() => {
        certificates === null && handleMyCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        partner && id && getMyIndividualCertificate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner, id]);

    useEffect(() => {
        if (certificates) {
            setCertificates(
                certificates.filter((certificate) => {
                    return certificate.nombre
                        .toLowerCase()
                        .includes(search.toLowerCase());
                })
            );
        }

        if (search === "") {
            setCertificates(certificatesFilter);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // FUNCTIONS
    // TRAE CERTIFICADO INDIVIDUAL
    const getMyIndividualCertificate = async () => {
        try {
            const req = await getIndividualCertificate(partner, id, ondemandId);

            setIndividualCertificate(req);
        } catch (error) {
            setIndividualCertificate(false);
        }
    };

    // TRAE TODAS LAS INSIGNIAS
    const handleMyCertificates = async () => {
        try {
            const res = await getMyCertificates(partnerId);

            setCertificates(res?.insignias);
            setCertificateFilter(res?.insignias);
        } catch (error) {
            setCertificates(false);
            setCertificateFilter(false);
        }
    };

    function getDateNow() {
        let date = new Date();
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    let dateNow = getDateNow();

    // GENERA LA INSIGNIA
    const generateBadge = () => {
        const body = {
            id_insignia: parseInt(8658),
            titulo: namePublication,
            fecha: dateNow,
            partner_id: partnerId,
            repo_id: repoId,
            codcurso: codCurso
        };

        if (isGenerateBadge) {
            postGenerateBadge(body);
        }
    };

    // SETEA LA BUSQUEDA PARA FILTRAR
    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const handleChangeFilter = (e) => {
        if (e.target.value === 1) {
            setCertificates(
                certificatesFilter.sort(
                    (a, b) =>
                        new Date(a.fechaExpedicion) >
                        new Date(b.fechaExpedicion)
                )
            );
        }

        if (e.target.value === 2) {
            setCertificates(
                certificatesFilter.sort(
                    (a, b) =>
                        new Date(a.fechaExpedicion) <
                        new Date(b.fechaExpedicion)
                )
            );
        }
    };

    return {
        certificates,
        individualCertificate,
        search,
        handleChange,
        generateBadge,
        handleChangeFilter,
    };
};

export default useMyCertificate;

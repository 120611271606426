import React from "react";
import styled, { keyframes } from "styled-components";
import { checkRoles } from "../../../../helpers/roles.helper";
import useAssignamentByAlab from "../../../../hooks/useAssignamentByAlab";
import FirstSectionCard from "./components-firstsection/FirstSectionCard";
import Analytics from "./components/Analytics";
import { useSelector } from "react-redux";
import DatesInCompany from "../../../scheduleInCompany/components/DatesInCompany";

const FirstSection = () => {
  /* Hay que refactorizar useAssignamentByAlab */
  const { courses } = useAssignamentByAlab(false, false, true, "first");
  const { lastAssignment } = useAssignamentByAlab(false, true, false, "first");
  const { lastCourse } = useAssignamentByAlab(true, false, false, "first");

  const { competitor, ondemand, academic } = useSelector(
    (state) => state?.ondemand
  );
  const {
    habilita_rutas_formativas,
    enable_company_courses,
    enable_aden_courses,
    is_incompany,
  } = useSelector((state) => state?.ondemand?.ondemand);
  const enabledTabs = useSelector((state) => state?.coursesTabs?.tabs);

  const arrayCourses = () => {
    if (competitor) {
      return (competitor?.aden !== null || competitor?.business !== null) &&
        ondemand !== null &&
        (competitor?.aden.length || competitor?.business.length > 0)
        ? [...competitor?.aden, ...competitor?.business]
        : false;
    } else if (academic) {
      return (academic?.aden !== null || academic?.business !== null) &&
        ondemand !== null &&
        (academic?.aden.length || academic?.business.length > 0)
        ? [...academic?.aden, ...academic?.business]
        : false;
    }
  };

  const screenWidth = window.outerWidth;

  return (
    <>
      {checkRoles(["ondemand", "admin"]) && (
        <CardFake>
          {habilita_rutas_formativas && (
            <>
              {lastAssignment ? (
                <FirstSectionCard
                  // array={arrayRoutes()}
                  object={lastAssignment}
                  information={
                    lastAssignment?.last_open_subject?.subject_information
                  }
                  pendingExams={lastAssignment?.last_open_subject?.pending_exams
                    .filter((curso) => curso.exam_name)
                    .slice(0, 3)}
                  classification={lastAssignment?.subject_classification}
                  type="route"
                  title="Ver mis rutas formativas"
                  to="/mi-empresa/6?tab=6"
                  subtitle="Última ruta vista"
                />
              ) : screenWidth < 768 ? (
                <Skeleton h="470px" w="100%" />
              ) : (
                <Skeleton h="500px" w="50%" />
              )}
            </>
          )}

          {!!is_incompany ? (
            <DatesInCompany />
          ) : (
            (enable_company_courses || enable_aden_courses) && (
              <>
                {lastCourse ? (
                  <FirstSectionCard
                    // array={arrayCourses()}
                    object={lastCourse}
                    information={
                      lastCourse?.last_open_resource?.resource_information
                    }
                    pendingExams={lastCourse?.last_open_resource?.pending_exams
                      .filter((curso) => curso.exam_name)
                      .slice(0, 3)}
                    classification={lastCourse?.resources_classification}
                    type="course"
                    title="Ver mis cursos"
                    to={`/mi-empresa/6?tab=${
                      enabledTabs?.habilita_cursos_empresa ? "0" : "1"
                    }`}
                    subtitle="Último curso visto"
                  />
                ) : screenWidth < 768 ? (
                  <Skeleton h="470px" w="100%" />
                ) : (
                  <Skeleton h="500px" w="50%" />
                )}
              </>
            )
          )}

          {!habilita_rutas_formativas &&
            !(enable_company_courses || enable_aden_courses) && (
              <Skeleton h="500px" w="50%" />
            )}
          {!(
            habilita_rutas_formativas &&
            (enable_company_courses || enable_aden_courses)
          ) && !is_incompany && (
            <Analytics
              arrayCourses={arrayCourses()}
              enableCourses={enable_company_courses || enable_aden_courses}
              arrayRoutes={courses}
              enableTrainingRoutes={habilita_rutas_formativas}
              type="route"
              title="Estado de mi cursado"
              to=""
              subtitle="Última ruta vista"
            />
          )}
        </CardFake>
      )}
    </>
  );
};

export default FirstSection;

const CardFake = styled.div`
  width: 100%;
  height: 500px;
  box-shadow: none;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
  }
`;

const pulseColorAnimation = keyframes`
  0% {
    background-color: #ededed;
  }
  50% {
    background-color: #cfcfcf; /* Change to your desired color */
  }
  100% {
    background-color: #ededed;
  }
`;

const Skeleton = styled.div`
  background-color: #ededed;
  border-radius: 20px;
  height: ${(p) => p.h};
  width: ${(p) => p.w};
  animation: ${pulseColorAnimation} 2s infinite;
`;

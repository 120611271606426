import React from "react";
import styled from "styled-components";

import IconInfoLeft from "../../molecules/profile/IconInfoLeft";
import {
  AlternateEmailRounded,
  PhoneRounded,
  SmartphoneRounded,
} from "@mui/icons-material";

const Contact = (props) => {
  const { phone, mobile, work_email, personal_email } = props;

  return (
    <ContactContainer>
      <Title>Datos de contacto</Title>
      <IconInfoLeft icon={<PhoneRounded />} title="Teléfono" data={phone} />
      <IconInfoLeft
        icon={<SmartphoneRounded />}
        title="Celular"
        data={mobile}
      />
      <IconInfoLeft
        icon={<AlternateEmailRounded />}
        title="Email Laboral"
        data={work_email}
      />
      <IconInfoLeft
        icon={<AlternateEmailRounded />}
        title="Email Principal"
        data={personal_email}
      />
    </ContactContainer>
  );
};

export default Contact;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem;
  border-radius: 20px;
  color: #bfbfbf;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
`;

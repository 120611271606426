import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import useTheme from "../../../hooks/useTheme";

const MenuItem = (props) => {
  const {
    to,
    icon,
    title,
    closeMenu,
    target,
    data_tut,
    link = false,
    shadow = false,
  } = props;

  const { primary } = useTheme();

  if (!!link) {
    return (
      <Hiper href={to} target={"_blank"}>
        <IconFake>
          {/* {isBeta && <NewSpan>BETA</NewSpan>} */}
          {icon}
        </IconFake>
        <Title>{title}</Title>
      </Hiper>
    );
  }

  return (
    <MenuItemContainer
      exact
      to={to}
      color={primary}
      activeClassName="active"
      target={target}
      onClick={closeMenu}
      data-tut={data_tut}
      shadow={shadow}
    >
      <IconFake>
        {/* {isBeta && <NewSpan>BETA</NewSpan>} */}
        {icon}
      </IconFake>
      <Title>{title}</Title>
    </MenuItemContainer>
  );
};

export default MenuItem;

const MenuItemContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.1s ease-in-out all;
  margin: 0 7px 0 0;
  padding-left: 1.4rem !important;
  font-weight: 500;
  border-radius: 0 25px 25px 0 !important;
  justify-content: flex-start;

  svg,
  span {
    color: var(--primary-two);
  }

  &.active {
    background: var(--secondary-two);
    border-radius: 0 25px 25px 0;
    justify-content: flex-start;
    
    ${(p) =>
      !!p.shadow &&
      `box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)`};
    svg,
    span {
      color: var(--primary-one);
    }
  }

  &:hover {
    svg,
    span {
      color: var(--primary-one);
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    column-gap: 1rem;
    padding: 1rem 0rem 1rem 2rem;
    width: 100%;
  }

  @media (max-width: 1400px) {
    svg {
      font-size: 1.2rem;
    }
  }
`;

const Hiper = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.1s ease-in-out all;
  margin: 0 7px 0 0;
  border-radius: 0 25px 25px 0 !important;
  font-weight: 500;
  justify-content: flex-start;
  padding-left: 1.4rem !important;
  cursor: pointer;

  svg,
  span {
    color: var(--primary-two);
  }

  &.active {
    background: var(--secondary-two);
    margin: 0 7px 0 0;
    border-radius: 0 25px 25px 0 !important;
    justify-content: flex-start;
    padding-left: 1.5rem;
    
    svg,
    span {
      color: var(--primary-one);
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    column-gap: 1rem;
    padding: 1rem 0rem 1rem 2rem;
    width: 100%;
  }

  @media (max-width: 1400px) {
    svg {
      font-size: 1.2rem;
    }
  }
`;

const Title = styled.span`
  display: none;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    display: block;
  }
`;

const IconFake = styled.div`
  max-width: 45px;
`;

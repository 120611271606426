import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const SkeletonSubject = () => {
  return (
    <Container>
      <TabsSkeleton>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="50px"
        />
      </TabsSkeleton>
      <BannerSkeleton>
        <Skeleton
          variant="circular"
          animation="wave"
          width="200px"
          height="200px"
          sx={{ borderRadius: "50%" }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width="70%"
          height="200px"
        />
      </BannerSkeleton>
      <BodySkeleton>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="320px"
          height="25px"
          sx={{ borderRadius: "20px" }}
        />
        <CoursesList>
          <CourseFake>
            <Skeleton
              variant="circular"
              animation="wave"
              width="36px"
              height="36px"
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="100%"
              height="102px"
              sx={{ borderRadius: "20px" }}
            />
          </CourseFake>
          <CourseFake>
            <Skeleton
              variant="circular"
              animation="wave"
              width="36px"
              height="36px"
              sx={{ marginLeft: "5px" }}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="100%"
              height="102px"
              sx={{ borderRadius: "20px" }}
            />
          </CourseFake>
        </CoursesList>
      </BodySkeleton>
    </Container>
  );
};

export default SkeletonSubject;

const Container = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  margin-top: 3rem;
`;

const TabsSkeleton = styled.div`
  position: absolute;
  top: 70px;
  left: 70px;
  width: calc(100% - 70px);
  background-color: #e1e1e1;
`

const BannerSkeleton = styled.div`
  border-radius: 20px;
  height: 264px;
  width: calc(100% - 16px);
  background-color: #f9f9f9;
  border: 8px solid #cccccc;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const BodySkeleton = styled.div`
  border-radius: 5px;
  height: 400px;
  width: 100%;
  margin-top: 2rem;
`;

const CoursesList = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 80px;
`

const CourseFake = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
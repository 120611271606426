import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const IaCard = (props) => {
  const { value } = props;

  return (
    <BusinessCardContainer>
      <ImageWrapper>
        <FakeImg src={value.image_url} alt="objeto de aprendizaje" />
        <SpanType>
          {value.acropolis_author !== 0
            ? value.acropolis_author
            : "autor sin registrar"}
        </SpanType>
      </ImageWrapper>
      <Body>
        <NombrePublicacion>{value.publication_name}</NombrePublicacion>
        <FakeLink target="_blank" href={`/curso`}>
          Ver contenido
        </FakeLink>
      </Body>
    </BusinessCardContainer>
  );
};

export default IaCard;

const BusinessCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  margin: 1rem;
  max-width: 380px;
  width: 380px;
  height: 270px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const SpanType = styled.span`
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #591AB6;
  color: #ffffff;
  border-radius: 100px;
  padding: 0.3rem 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(1rem, 1rem);
`;

const FakeImg = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  height: 100%;
`;

const NombrePublicacion = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  text-align: start;
  color: #222222;

  @media screen and (max-width: 996px) {
    font-size: 1.2rem;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-top: auto;
  max-height: 150;
  height: 150px;
  overflow-y: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FakeLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  font-weight: 700;
  background-color: #591AB6;
  color: #ffffff;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  :hover {
    background-color: #616161;
    color: #ffffff;
  }
`;

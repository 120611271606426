import axios from "axios";

export function getPrograms() {
  return axios.get(`${process.env.REACT_APP_SISAPI}/v1/acropolis/academics`);
}

export const getProgramsServices = async (body) => {
  const URL = `${window.location.origin}/mock/programs.json`;

  try {
    const response = await axios.get(URL, body);
    return response.data.response_data.programs.slice(0, 3);
  } catch (error) {
    return { error };
  }
};

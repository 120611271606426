import React from "react";
import useMyCertificate from "../../hooks/useMyCertificate";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import parse from "html-react-parser";
import ErrorResponse from "../../components/common/ErrorResponse";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "@material-ui/core";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

const IndividualCertificate = () => {
    const { individualCertificate } = useMyCertificate();

    const link = window.location.href;

    // FUNCTIONS
    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const generateImage = () => {
        window.html2canvas = html2canvas;
        let content = document.getElementById("toPdf");

        const options = {
            background: "white",
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
        };

        html2canvas(content, options).then((canvas) => {
            var a = document.createElement("a");
            a.href = canvas
                .toDataURL("image/PNG")
                .replace("image/PNG", "image/octet-stream");
            a.download = "Constancia.png";
            a.click();
        });
    };

    const generatePDF = () => {
        window.html2canvas = html2canvas;

        let content = document.getElementById("toPdf");

        const options = {
            background: "white",
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
        };

        html2canvas(content, options)
            .then((canvas) => {
                const img = canvas.toDataURL("image/PNG");
                let doc = new jsPDF("l", "cm", "a4");

                const pageWidth = 29.5;
                const pageHeight = 20;

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio =
                    widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                const marginY = (pageHeight - canvasHeight) / 2;

                doc.addImage(
                    img,
                    "PNG",
                    marginX,
                    marginY,
                    canvasWidth,
                    canvasHeight,
                    undefined,
                    "FAST"
                );

                return doc;
            })
            .then((doc) => {
                doc.save("Constancia.pdf");
            });
    };

    // RETURN

    if (individualCertificate === null) {
        return <SimpleLoading />;
    }

    return (
        <Wrapper>
            {!individualCertificate ? (
                <ErrorResponse />
            ) : (
                <Grid>
                    <Container id="toPdf">
                        <div id="content">
                            {parse(sanitizerHtml(individualCertificate.html))}
                        </div>
                    </Container>

                    <Aside>
                        <Header>
                            <h2>¡Felicidades!</h2>
                            <p>
                                Te invitamos a compartir este logro con tus
                                colegas, amigos y familia.
                            </p>
                        </Header>
                        <SocialMediaContainer>
                            <FacebookShareButton onClick={() => ""} url={link}>
                                <FacebookIcon round size={48} />
                            </FacebookShareButton>
                            <TwitterShareButton onClick={() => ""} url={link}>
                                <TwitterIcon round size={48} />
                            </TwitterShareButton>
                            <LinkedinShareButton onClick={() => ""} url={link}>
                                <LinkedinIcon round size={48} />
                            </LinkedinShareButton>
                            <WhatsappShareButton onClick={() => ""} url={link}>
                                <WhatsappIcon round size={48} />
                            </WhatsappShareButton>
                        </SocialMediaContainer>

                        <Buttons>
                            <ButtonCustom
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={generateImage}
                            >
                                Descargar imagen
                            </ButtonCustom>
                            <ButtonCustom
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={generatePDF}
                            >
                                Descargar PDF
                            </ButtonCustom>
                        </Buttons>
                    </Aside>
                </Grid>
            )}
        </Wrapper>
    );
};

export default IndividualCertificate;

const Wrapper = styled.div`
    padding: 2rem;
`;

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
`;

const Container = styled.div`
    .certificadocontainer {
        min-height: 260mm;
        width: 280mm;
    }
`;

const Aside = styled.div`
    width: calc(100% - 3rem);
    background-color: #fff;
    height: fit-content;

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    h2 {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    p {
        font-size: 0.95rem;
        color: #616161;
    }
`;

const SocialMediaContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const ButtonCustom = styled(Button)`
    height: 44px;
`;

import axios from "axios";

export const getStudentsServices = async (minify) => {
  const endpoint = !minify
    ? "/v1/repo_aden/faculty/students"
    : "/v1/repo_aden/faculty/students?simplified=true";
  const URL = `${process.env.REACT_APP_REPO}${endpoint}`;

  try {
    const response = await axios.get(URL);
    // console.log(response.data.response_data.students);
    return response.data.response_data.students;
  } catch (error) {
    return { error };
  }
};

export const getStudentsCanvas = async (id) => {
  // const endpoint = !minify ? '/v1/repo_aden/faculty/students' : '/v1/repo_aden/faculty/students?simplified=true'
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/faculty/students/${id}`;

  try {
    const response = await axios.get(URL);
    // console.log(response.data.response_data.students);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getHighlight = async (repo_id, crm_id, email) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/outstanding/?repo_id=${repo_id}&crm_id=${crm_id}&email=${email}`;

  try {
    const response = await axios.get(URL);
    // console.log(response.data.response_data.students);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

// Destacar alumno
export const postHighlight = async (body) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/outstanding/save`;

  try {
    const response = await axios.post(URL, body);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    return { error };
  }
};

//Alumnos del AA
export const getStudentsAAServicesSis = async () => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/academic_advisor/students`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getStudentsAAServicesRepo = async (repositorio_partner_id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/academic_advisor/students/${repositorio_partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

// Info de un alumno en especifico de la AA
export const getGeneralInfoOneStudent = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/academic_advisor/students/data/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
};

// datos generales 360
export const getInfoOneStudentAA = async (partner_id, inscripcion_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/academic_advisor/students/info/${partner_id}?inscripcion_id=${inscripcion_id}`;
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
};

// Cursos del alumno 360
export const getCoursesStudentAA = async (sis_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/courses/${sis_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

// Documentación alumno 360
export const getDocsStudentAA = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/academic_advisor/students/docs/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
};

// Documentación alumno 360
export const getProgramsStudentAA = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/academic_advisor/students/inscriptions/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
};

//Información del alumno para el Docente _ Vista 360º
//Información profesional
export const getProfessionalInfo = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/contact/professional_info/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};
//Estudios_Formación Académica
export const getStudiesInfo = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/contact/studies/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};
//Información general
export const getGeneralInfo = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/contact/general_info/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};
//Más info
export const getMoreInfo = async (partner_id) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/contact/more_info/${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

import { useState } from "react";

export const useAlertDialog = () => {
  const [alertDialog, setAlertDialog] = useState({
    // ...alertDialog,
    titulo: "",
    mensaje: "",
    accion: "",
    open: false,
  });

  const closeAlertDialog = () => {
    setAlertDialog({ ...alertDialog, open: false });
  };
  const openAlertDialog = (titulo, mensaje, accion) => {
    setAlertDialog({ ...alertDialog, titulo, mensaje, accion, open: true });
  };

  return [alertDialog, closeAlertDialog, openAlertDialog];
};

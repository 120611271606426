import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonPlan = () => {
  return (
    <Container>
      <BannerSkeleton />
      <BodySkeleton />
    </Container>
  );
};

export default SkeletonPlan;

const twinkle = keyframes`
    0%{
        background-color: #e1e1e1;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #e1e1e1;
    }
`;

const Container = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  position: relative;
`;

const BannerSkeleton = styled.div`
  /* border-radius: 20px; */
  height: 120px;
  width: 100%;
  animation: ${twinkle} 2s linear infinite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: absolute;
  top: -26px;
  left: -26px;
`;

const BodySkeleton = styled.div`
  margin-top: 120px;
  border-radius: 20px;
  height: 650px;
  width: 100%;
  animation: ${twinkle} 2s linear infinite;
`;

const Progress = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ccc;
`;

const Titles = styled.div`
  width: 60%;
  height: 80px;
  border-radius: 3px;
  background-color: #ccc;
`;

import React, { useEffect, useState } from "react";
import Star from "@mui/icons-material/Star";
import StarHalf from "@mui/icons-material/StarHalf";
import StarBorderOutlined from "@mui/icons-material/StarBorderOutlined";
import styled from "styled-components";

const StarsResume = (props) => {
  const { percentage } = props;

  const [arrayStar, setArrayStar] = useState([]);
  const [arrayOutlineStar, setArrayOutlineStar] = useState([]);
  const [starHalfNumeber, setStarHalfNumeber] = useState(0);
  const [starHalfBool, setStarHalfBool] = useState(false);

  let decimal = (percentage + "").split(".");
  let outlinesStar = 5 - decimal[0] - starHalfNumeber;
  useEffect(() => {
    selectStar(decimal[0]);
    selectOutlineStar(outlinesStar);
  }, []);

  const selectStar = (stars) => {
    const star = {
      1: [""],
      2: ["", ""],
      3: ["", "", ""],
      4: ["", "", "", ""],
      5: ["", "", "", "", ""],
    };
    setArrayStar(star[stars]);
  };

  const selectOutlineStar = (outlinesStar) => {
    const outlineStar = {
      0: [],
      1: [""],
      2: ["", ""],
      3: ["", "", ""],
      4: ["", "", "", ""],
      5: ["", "", "", "", ""],
    };
    setArrayOutlineStar(outlineStar[outlinesStar]);
  };

  if (starHalfNumeber === 0) {
    if (decimal[1]) {
      let decimalFix = decimal[1].substr(0, 2);
      if (decimalFix > 0 && decimalFix <= 99) {
        setStarHalfBool(true);
        setStarHalfNumeber(1);
      }
    }
  }

  return (
    <Stars>
      {arrayStar.map(() => {
        return <Star />;
      })}
      {starHalfBool ? <StarHalf /> : null}
      {arrayOutlineStar.map(() => {
        return <StarBorderOutlined />;
      })}
    </Stars>
  );
};

export default StarsResume;

const Stars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #f7bb56;
    font-size: 1.5rem;
  }
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { careersActions } from "../../../redux/actions";

const useCareer = () => {
  const dispatch = useDispatch();
  const allCareers = useSelector((state) => state.careers.careers);

  const [career, setCareer] = useState(null);
  const [products, setProducts] = useState(null);
  const [currentCareers, setCurrentCareers] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredCareers, setFilteredCareers] = useState(null);

  useEffect(() => {
    allCareers === null && dispatch(careersActions.getCareersRequest());
    if (!products && allCareers) {
      getCareerProduct();
    }
  }, [allCareers, products]);

  // ORDENAMOS Y SETEAMOS LOS DATOS DEL ENDPOINT A RENDERIZAR
  const getCareerProduct = async () => {
    const data = allCareers.map((item, i) => ({
      label: item.category_name,
      id: i,
      disabled: false,
      data: item.category_products,
    }));

    handleDisabled(data);
    setProducts(allCareers);
    setCareer(data);
    handleProduct(handleViewTab(data), allCareers);
  };

  // SETEA EL CONTEIDO A RENDERIZAR
  const handleProduct = (idTab, request) => {
    if (products) {
      setCurrentCareers(products[idTab]?.category_products);
    } else {
      setCurrentCareers(request[idTab]?.category_products);
    }
    setSearch("");
    setFilteredCareers(null);
  };

  // // TRAE EL ID DEL PRIMER OBJETO QUE NO TRAIGA UN ARRAY VACÍO
  // TRAE EL CONTENIDO DEL TAB DIPLOMADO (TEMPORAL)
  const handleViewTab = (data) => {
    // const tabView = data.find(item => item.data.length !== 0)
    const tabView = data.find((item) => item.label === "Diplomados");
    return tabView.id;
  };

  // DESHABILITA TABS QUE NO TRAIGAN CONTENIDO
  const handleDisabled = (data) => {
    data.forEach((item) => {
      if (item.data.length === 0) {
        item.disabled = true;
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    filterCareers(search);
  };

  // FILTRA POR INPUT DE TEXTO
  const filterCareers = (input) => {
    const filteredCareers = currentCareers.filter((career) =>
      career.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredCareers(filteredCareers);
  };

  return {
    career,
    search,
    products,
    handleProduct,
    currentCareers,
    handleChange,
    filteredCareers,
  };
};

export default useCareer;

import { Card } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import TabsComponent from "../../../common/TabsComponent";
import AboutProduct from "../../molecules/ecommerce/AboutProduct";
import ProgramProduct from "../../molecules/ecommerce/ProgramProduct";
import QuestionsProduct from "../../molecules/ecommerce/QuestionsProduct";
import ReviewProduct from "../../molecules/ecommerce/ReviewProduct";

const OPTIONS = [
  { id: 0, label: "Acerca de" },
  // { id: 1, label: "Programa" },
  // { id: 2, label: 'Reseñas' },
  { id: 3, label: "Preguntas frecuentes" },
];

export default function BodyLanding(props) {
  const { about, program, review, frequent_questions } = props;

  const [currentTab, setCurrentTab] = useState(0);

  function changeTab(e, value) {
    setCurrentTab(value);
  }

  const renderCurrentTab = () => {
    switch (currentTab) {
      case 0:
        return <AboutProduct about={about} />;
      case 1:
        return <ProgramProduct program={program} />;
      case 2:
        return <ReviewProduct review={review} />;
      case 3:
        return <QuestionsProduct frequent_questions={frequent_questions} />;
      default:
        return <AboutProduct about={about} />;
    }
  };

  return (
    <BodyLandingWrapper>
      <Content>
        <TabsComponent
          tab={currentTab}
          handleTab={changeTab}
          tabs={OPTIONS}
          variant={"scrollable"}
        />
        <TabContainer>{renderCurrentTab()}</TabContainer>
      </Content>
    </BodyLandingWrapper>
  );
}

const BodyLandingWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 2rem 10rem; */
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1170px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 20px;
`;

const TabContainer = styled.div`
  padding: 1rem;
`;

import React, { useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import {
  interestsActions,
  motivationsActions,
} from "../../../../redux/actions";
import SimpleLoading from "../../../common/SimpleLoading";
import Text from "../../../common/Text";
import DefaultSelect from "../../../common/DefaultSelect";

const OptionFour = (props) => {
  const { values, setFieldValue } = props;
  // REDUX
  const dispatch = useDispatch();
  const motivations = useSelector((state) => state.motivations.motivations);
  const interests = useSelector((state) => state.interests.interests);

  // EFFECTS
  useEffect(() => {
    if (motivations === null) {
      dispatch(motivationsActions.getMotivationsRequest());
    }
    if (interests === null) {
      dispatch(interestsActions.getInterestsRequest());
    }
  }, []);

  // FUNCTIONS
  function removeMotivation(id) {
    let newValues = values.motivaciones.filter((x) => x !== id);
    setFieldValue("motivaciones", newValues);
  }

  function addMotivation(e) {
    setFieldValue("motivaciones", [...values.motivaciones, e.target.value]);
  }

  function removeInterest(id) {
    let newvalues = values.intereses.filter((x) => x !== id);
    setFieldValue("intereses", newvalues);
  }

  function addInterest(id) {
    setFieldValue("intereses", [...values.intereses, id]);
  }

  // RETURN
  if (!motivations || !interests) {
    return <SimpleLoading padding="8rem" />;
  }

  return (
    <OverflowWrappper>
      <Container>
        <InputWrapper>
          {/** Select Motivaciones */}
          <Text fontSize="1rem" fontWeight="700" component="h1">
            Motivaciones
          </Text>
          <DefaultSelect
            name="motivaciones"
            label="Motivaciones"
            options={motivations.filter(
              (item) => !values.motivaciones.some((x) => x === item.id)
            )}
            onChange={addMotivation}
          />
          {/** Motivaciones del usuario */}
          <MotivationsWrapper>
            {motivations.map(
              (item) =>
                values.motivaciones.some((x) => x === item.id) && (
                  <FakeChip
                    label={item.name}
                    key={item.id}
                    onDelete={() => removeMotivation(item.id)}
                    size="small"
                  />
                )
            )}
          </MotivationsWrapper>
          {/** Intereses */}
          <Text fontSize="1rem" fontWeight="700" component="h1">
            Intereses
          </Text>
          {/** Intereses del usuario */}
          <InterestsWrapper>
            {interests.map((interest) =>
              values.intereses.some((x) => x === interest.id) ? (
                <Interest
                  key={interest.id}
                  selected
                  onClick={() => removeInterest(interest.id)}
                >
                  <img src={`data:image/svg+xml;base64, ${interest.icon}`} />
                  <Text fontSize="0.8rem" component="span">
                    {interest.name}
                  </Text>
                </Interest>
              ) : (
                <Interest
                  key={interest.id}
                  onClick={() => addInterest(interest.id)}
                >
                  <img src={`data:image/svg+xml;base64, ${interest.icon}`} />
                  <Text fontSize="0.8rem" component="span">
                    {interest.name}
                  </Text>
                </Interest>
              )
            )}
          </InterestsWrapper>
        </InputWrapper>
      </Container>
    </OverflowWrappper>
  );
};

export default OptionFour;

const OverflowWrappper = styled.div`
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem 1rem 2rem 0;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 0;
  }
`;

const MotivationsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  flex-wrap: wrap;
`;

const FakeChip = styled(Chip)`
  font-weight: 700;
  padding: 0.5rem;
  background-color: #ffd7d7;
  color: #591AB6;

  svg {
    background-color: #ffd7d7;
    color: #591AB6;
  }
`;

const InterestsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.5rem;
`;

const Interest = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  height: 5rem;
  border-radius: 20px;
  color: #616161;
  background-color: ${(props) => (props.selected ? "#ffd7d7" : "#fafafa")};
  /* border: ${(props) => (props.selected ? "1px solid #591AB6" : "none")}; */
  cursor: pointer;
`;

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import DefaultField from "../../../common/DefaultField";
import DefaultPhone from "../../../common/DefaultPhone";

const OptionThree = (props) => {
  const { values, setFieldValue } = props;
  // REDUX
  const { phone } = useSelector((state) => state.auth.user);

  // RETURN
  return (
    <OverflowWrappper>
      <Container>
        <InputWrapper>
          {/** Email principal */}
          <DefaultField
            name="personal_email"
            type="email"
            label="Email principal"
            disabled
          />
          {/** Celular */}
          <div>
            <DefaultPhone
              name="phone"
              label="Celular"
              value={values.phone}
              setFieldValue={setFieldValue}
            />
          </div>
          {/** Tel Secundario */}
          <div>
            <DefaultPhone
              name="mobile"
              label="Teléfono secundario"
              value={values.mobile}
              setFieldValue={setFieldValue}
            />
          </div>
          {/** Whatsapp */}
          {/* <div>
						<DefaultPhone
							name="whatsapp"
							label="Whatsapp"
							value={values.whatsapp}
							setFieldValue={setFieldValue}
						/>
					</div> */}
          {/** Facebook */}
          <DefaultField name="facebook" type="text" label="Facebook" />
          {/** Instagram */}
          <DefaultField name="instagram" type="text" label="Instagram" />
          {/** Twitter */}
          <DefaultField name="twitter" type="text" label="Twitter" />
          {/** Skype */}
          <DefaultField name="skype" type="text" label="Skype" />
          {/** LinkedIn */}
          <DefaultField name="linkedin" type="text" label="LinkedIn" />
        </InputWrapper>
      </Container>
    </OverflowWrappper>
  );
};

export default OptionThree;

const OverflowWrappper = styled.div`
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  padding: 2rem 1rem 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 100%;
  height: max-content;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 0;
  }
`;

import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import ModalICEvent from "./ModalICEvent";
import { Text } from "../../../components/common/Texts";
import { AccessTime, FmdGood } from "@mui/icons-material";
import { getEventType } from "../hooks/useTypeEvent";
import { useSelector } from "react-redux";

const DateCardSchedule = (props) => {
  const { event } = props;
  const { primary_color } = useSelector((state) => state?.ondemand?.ondemand);

  const [openModal, setOpenModal] = useState(false);
  const [hours, setHours] = useState(false);
  const [location, setLocation] = useState(false);

  useEffect(() => {
    extractEventTimes(event);
    concatLocation(event);
  }, []);

  const handleOpenModal = () => {
    console.log("open :>> ");
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const extractEventTimes = (event) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    const startTime = event?.date_begin
      ? new Date(event.date_begin).toLocaleTimeString([], options)
      : null;
    const endTime = event?.date_end
      ? new Date(event.date_end).toLocaleTimeString([], options)
      : null;
    const concat = `${startTime} - ${endTime}`;
    setHours(concat);
  };

  const concatLocation = (event) => {
    const parts = [];
    if (event?.address) parts.push(event.address);
    if (event?.city) parts.push(event.city);
    if (event?.country) parts.push(event.country);
    const location = parts.join(", ");
    const locationFull = location.length > 0 ? `${location}` : false;
    setLocation(locationFull);
  };

  return (
    <>
      <EventWrapper key={event?.id} onClick={handleOpenModal}>
        <DateRounded type={getEventType(event?.type).color}>
          <Text color="#ffffff" fontSize="16px" fontWeight="400">
            {moment(event?.date_begin).format("DD")}
          </Text>
        </DateRounded>
        <EventInfo>
          <Text color="#222222" fontSize="1rem" fontWeight="700">
            <b>{event?.name}</b>
          </Text>
          <Text
            color="#1D1D1B"
            fontSize="0.8rem"
            fontWeight="400"
            textTransform="uppercase"
          >
            <b>{getEventType(event?.type).type}</b>
          </Text>
          <LocationInfoWrapper>
            <Section>
              <AccessTime />
              <TimeWrapper>
                <Text color="#a8a8a8" fontSize="12px" fontWeight="500">
                  {event?.date_begin}
                </Text>
              </TimeWrapper>
            </Section>
            {/* locación solo para presencial e hibrido  */}
            {!event?.type.includes("Virtual") && (
              <Section>
                <FmdGood />
                <LocationTextWrapper>
                  <Text color="#a8a8a8" fontSize="12px" fontWeight="500">
                    {location}
                  </Text>
                </LocationTextWrapper>
              </Section>
            )}
          </LocationInfoWrapper>
        </EventInfo>
      </EventWrapper>
      {openModal && (
        <ModalICEvent
          key={event.id}
          openModal={openModal}
          event={event}
          hours={hours}
          location={location}
          handleCloseModal={handleCloseModal}
          primary_color={primary_color}

        />
      )}
    </>
  );
};

export default DateCardSchedule;

const EventWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  &::after {
    position: absolute;
    content: " ";
    width: calc(100% - 3.6rem);
    height: 1px;
    margin: auto;
    right: 0;
    bottom: -11px;
    background-color: #a8a8a8;
  }
`;

const DateRounded = styled.div`
  min-width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: ${(p) => p.type};
  color: #fafafa;
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.45rem;
`;

const LocationInfoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;

  svg {
    width: 18px;
    height: 18px;
    color: #a8a8a8;
  }
`;

const TimeWrapper = styled.div`
  min-width: 75px;
`;

const Section = styled.section`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  width: auto;
`;

const LocationTextWrapper = styled.div`
  width: 350px;
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastAssignmentOrCourse } from "../redux/api/ondemand";
import { ondemandActions } from "../redux/actions";

const useAssignamentByAlab = (course, route, allCourses = true) => {

    const [lastAssignment, setLastAssignment] = useState(null);
    const [lastCourse, setLastCourse] = useState(null);
    const { ondemand_id, repo_id } = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    const courses = useSelector((state) => state?.ondemand?.academic?.assignments);

    useEffect(() => {
        if (allCourses) {
            getCourses(ondemand_id);
        }
        if (course && !lastCourse) {
            getLastCourse(ondemand_id, "course")
        }
        if (route && !lastAssignment) (
            getLastAssignment(ondemand_id, "assignment")
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCourses = async (id) => {
        try {
            dispatch(ondemandActions.getAssignmentsRequest({ondemandId: id, repoId: repo_id}));
        } catch (error) {
            console.log(error)
        }
    };

    const getLastAssignment = async (id, type) => {
        try {
            const req = await getLastAssignmentOrCourse(id, repo_id, type);
            setLastAssignment(req);
        } catch (error) {
            setLastAssignment(false);
        }
    }

    const getLastCourse = async (id, type) => {
        try {
            const req = await getLastAssignmentOrCourse(id, repo_id, type);
            setLastCourse(req);
        } catch (error) {
            console.log(error)
        }
    }

    return { courses, lastAssignment, lastCourse };
};

export default useAssignamentByAlab;

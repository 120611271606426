import React from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";

const SkeletonLoading = (props) => {
  const {
    variant = "rectangular",
    animation = "wave",
    width = "100%",
    height = 120,
    radius = "20px",
    position = "relative",
    inset = "0px 0px 0px 0px",
    margin = "auto",
  } = props;

  return (
    <FakeSkeleton
      variant={variant}
      animation={animation}
      width={width}
      height={height}
      radius={radius}
      position={position}
      inset={inset}
      margin={margin}
    />
  );
};

export default SkeletonLoading;

const FakeSkeleton = styled(Skeleton)`
  position: ${(p) => p.position};
  width: ${(p) => p.width};
  border-radius: ${(p) => p.radius};
  inset: ${(p) => p.inset};
  margin: ${(p) => p.margin};
`;

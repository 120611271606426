import React, { useState } from "react";
import styled from "styled-components";

// Components
import { Img } from "../../../../components/common/Image";
import { Text } from "../../../../components/common/Texts";
import FormContact from "../../../../components/ui/organisms/educativeOffer/FormContact";
import ModalUpselling from "../../../../components/ui/organisms/subject/ModalUpselling";
import UpsellingSelect from "../../../../components/ui/organisms/subject/UpsellingSelect";

// Material UI
import { Button, Dialog } from "@mui/material";

// Hooks
import useEcommerce from "../../../../hooks/useEcommerce";
import { useQueryParams } from "../../../../hooks/useQueryParams";

// Helpers
import { decodeData } from "../../../../helpers/encrypt.helper";

const Upselling = (props) => {
  const { img, setOpenTest, openTest } = props;

  // PRUEBA MODAL DESPUÉS MODIFICAR
  const [productState, setProduct] = useState(null);

  const handleCloseTest = () => setOpenTest(false);
  const handleOpenTest = () => setOpenTest(true);

  // STATE
  const params = useQueryParams();
  // const [type, setType] = useState("diplomado");
  const [open, setOpen] = useState(false);

  const idPro = decodeData(params.get("program") || "") || "";
  const { product } = useEcommerce(idPro);
  // const url = idPro
  //     ? window.location.origin + "/producto/" + idPro
  //     : window.location.origin + "/oferta-educativa/";

  const handleSelected = (type) => {
    // setType(type);
    // console.log("type", type);
  };

  // const handleClick = () => {
  //     if (type === "diplomado") {
  //         setOpen(false);
  //         window.open(url);
  //     } else {
  //         setOpen(true);
  //     }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModalOpport = (idProduct) => {
    setProduct(idProduct);
    setOpen(true);
  };

  return (
    <>
      <CardWrapper>
        <Image>
          <Img h="200px" w="100%" src={img} />
        </Image>
        <Body>
          <Text fontSize="36px" fontWeight="700" color="#591AB6">
            ¡Avanza en tu ruta formativa!
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#444">
            ¿Sabías que completando este curso estás completando una parte de
            cada uno de los programas que tiene ADEN para ofrecerte?
          </Text>
          <Text fontSize="18px" fontWeight="500" color="#222">
            ¡Conoce tu avance en cada uno y aprovecha esta oportunidad!
          </Text>
          {!!idPro && <UpsellingSelect handleSelected={handleSelected} />}
          {/* <Button
                        onClick={handleClick}
                        variant="contained"
                        color="primary"
                    >
                        Conocer mi avance
                    </Button> */}
          {/* PRUEBA MODAL DESPUES MODIFICAR */}
          <Button onClick={handleOpenTest} variant="contained" color="primary">
            Conocer mi avance
          </Button>
        </Body>
        <Dialog open={open} onClose={handleClose}>
          <FormContact productSelect={productState} handleClose={handleClose} />
        </Dialog>
      </CardWrapper>
      <ModalUpselling
        open={openTest}
        handleClose={handleCloseTest}
        handleOpenModalOpport={handleOpenModalOpport}
        upselling={product}
        type="rutaFormat"
      />
    </>
  );
};

export default Upselling;

const CardWrapper = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  padding: 24px;
  height: 560px;
  min-width: 350px;
  /* display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 16px; */
`;

const Image = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0;
  top: 0;
  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Body = styled.div`
  margin-top: 200px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: spac-around;
`;

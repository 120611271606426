import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  Button,
  Modal,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { inconvenientesActions } from "../../redux/actions/index";
import SimpleLoading from "./SimpleLoading";

const ModalProblem = (props) => {
  const { isOpen, handleCloseModal, idCurrentMedia } = props;
  // REDUX
  const dispatch = useDispatch();
  const tipos_inconvenientes = useSelector(
    (state) => state.inconvenientes.tipos_inconvenientes
  );
  const loading = useSelector((state) => state.inconvenientes.loading);

  // STATE
  const [problem, setProblem] = useState({
    recurso_id: idCurrentMedia,
    tipo_inconveniente_id: "",
    detalle: "",
  });

  // EFFECTS
  useEffect(() => {
    if (!tipos_inconvenientes) {
      dispatch(inconvenientesActions.tipoInconvenienteRequest());
    }
  }, []);

  // FUNCTIONS
  function handleChange(e) {
    setProblem({ ...problem, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(inconvenientesActions.reportInconvenienteRequest(problem));
    handleCloseModal();
  }

  // RETURN
  if (tipos_inconvenientes === null || loading) {
    return <></>;
  }

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <ModalProblemContainer>
        {/** Header */}
        <Header>
          <ModalTitle>Reportar un problema</ModalTitle>
          <FakeIconButton onClick={handleCloseModal} size="small">
            <CloseRounded fontSize="small" />
          </FakeIconButton>
        </Header>
        {/** Body */}
        <Body onSubmit={handleSubmit}>
          <FormControl>
            <InputLabel id="select-type">Tipo de problema *</InputLabel>
            <Select
              id="problem-select"
              name="tipo_inconveniente_id"
              color="primary"
              labelId="select-type"
              value={problem.tipo_inconveniente_id}
              onChange={handleChange}
            >
              {tipos_inconvenientes.map((tipo) => (
                <MenuItem value={tipo.id} key={tipo.id}>
                  {tipo.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="detalle"
            label="Tu mensaje *"
            multiline
            rows={5}
            value={problem.detalle}
            onChange={handleChange}
          />
          <ButtonsWrapper>
            <FakeButton
              variant="outlined"
              color="primary"
              onClick={handleCloseModal}
            >
              Cancelar
            </FakeButton>
            <FakeButton variant="contained" color="primary" type="submit">
              Enviar
            </FakeButton>
          </ButtonsWrapper>
        </Body>
      </ModalProblemContainer>
    </Modal>
  );
};

export default ModalProblem;

const ModalProblemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem;
  min-width: 400px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    min-width: 90vw;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #b7b7b7;
`;

const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
`;

const FakeIconButton = styled(IconButton)`
  border-radius: "10px";
  margin: 0;
  background-color: "#f3f6f9";
`;

const Body = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
`;

const FakeButton = styled(Button)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

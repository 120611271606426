import { useSelector } from "react-redux";
import useTicketForm from "../hooks/useTicketForm";
import { useState } from "react";
import useTheme from "../../../../hooks/useTheme";
import styled from "styled-components";
import { Form, Formik } from "formik";
import ImgSuccess from '../../../../assets/media/aden/ticket-success.png'
import ImgError from '../../../../assets/media/aden/ticket-creation-error.png'
import { Icon } from "@iconify/react";
import { validationSchema } from "../../../../utils/schemas";
import DefaultField from "./DefaultField";
import RichTextEditor from "./RichTextEditor";

const TicketForm = (props) => {
    const { open, setOpen, proyectId, title, subtitle } = props;

    const [error, setError] = useState(false);

    const user = useSelector((state) => state.auth.user);

    const [inputArea, setInputArea] = useState("");

    const { primary } = useTheme();

    const initialValues = {
        externalClientName: user.name,
        externalClientEmail: user.personal_email,
        ticketName: "",
        description: inputArea,
        attachments: null,
        projectId: proyectId,
        dateDeadline: null,
    };

    const {
        handleSubmit,
        openModal,
        setOpenModal,
        openModalError,
        setOpenModalError,
        ticketNumber,
        loading,
    } = useTicketForm(initialValues, inputArea);

    const handleClickSubmitCheckDescription = async () => {
        if (inputArea === "") {
            setError(true);
            return;
        }
        setError(false);
    };

    if (openModal) {
        return (
            <Modal open={open} color={primary}>
                <ModalImg src={ImgSuccess} />
                <h3>
                    ¡Tu solicitud número <b>#{ticketNumber}</b>
                    <br /> se ha generado exitosamente!
                </h3>
                <p>
                    Revisa tu correo electrónico para hacer <br /> el
                    seguimiento de la misma.
                </p>
                <button
                    onClick={() => {
                        setOpen(false);
                        setOpenModal(false);
                        setInputArea("");
                    }}
                >
                    Entendido
                </button>
            </Modal>
        );
    }

    if (openModalError) {
        return (
            <Modal open={open} color={primary}>
                <ModalImg src={ImgError} />
                <h3>¡Ups! No pudimos generar la solicitud</h3>
                <p>Por favor intenta nuevamente.</p>
                <button onClick={() => setOpenModalError(false)}>
                    Entendido
                </button>
            </Modal>
        );
    }

    return (
        <FormWrapper open={open}>
            <CloseButton onClick={() => setOpen(false)}>
                <Icon icon="ic:round-close" width="1.2em" height="1.2em" />
            </CloseButton>
            <Header color={primary}>
                <h2>{title}</h2>
                <h3>¿Cómo podemos ayudarte?</h3>
                <span>{subtitle}</span>
            </Header>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <FormContainer>
                    <DefaultField
                        name="ticketName"
                        type="text"
                        label="Asunto"
                        required
                    />
                    <RichTextEditor
                        inputArea={inputArea}
                        setInputArea={setInputArea}
                        error={error}
                        required
                    />
                    <Footer>
                        <CustomButton
                            color={primary}
                            onClick={() => setOpen(false)}
                            mod="w"
                        >
                            Cancelar
                        </CustomButton>
                        <CustomButton
                            type="submit"
                            onClick={handleClickSubmitCheckDescription}
                            mod="r"
                            color={primary}
                        >
                            {loading ? "Cargando..." : "Enviar consulta"}
                        </CustomButton>
                    </Footer>
                </FormContainer>
            </Formik>
        </FormWrapper>
    );
};

export default TicketForm;

const FormWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    color: #000;
    right: 12px;
    top: 12px;
    font-size: 24px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    h3 {
        color: ${(props) => props.color};
        font-size: 1.8rem;
    }
    h2 {
        color: #616161;
        font-size: 1.3rem;
    }
    span {
        color: #616161;
        font-size: 1rem;
        width: 400px;
    }

    @media (width < 768px) {
        span {
            color: #616161;
            font-size: 1rem;
            width: 90%;
        }
    }

    @media (width <= 1520px) {
        gap: 0.1rem;
    }
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    box-sizing: border-box;

    @media (width <= 1520px) {
        gap: .5rem;
    }
`;

const Footer = styled.div`
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (width < 768px) {
        margin-top: 7rem;
    }
`;

const CustomButton = styled.button`
    width: auto;
    cursor: pointer;
    padding: 10px 30px;
    background-color: ${(p) => (p.mod === "w" ? "#fff" : p.color)};
    border-radius: 10px;
    border: ${(p) =>
        p.mod === "w" ? "solid 1px " + p.color : "solid 0px transparent"};
    color: ${(p) => (p.mod === "w" ? p.color : "#fff")};
    font-weight: bold;
`;

const Modal = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    h3 {
        font-size: 20px;
        font-weight: 800;
        color: #616161;
        text-align: center;
    }
    
    b {
        font-size: 20px;
        font-weight: 900;
        color: ${(props) => props.color};
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: #616161;
        text-align: center;
    }
    button {
        background-color: ${(props) => props.color};
        width: 280px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        border: 0px;
        :hover {
            background-color: ${(props) => props.color};
        }
    }
`;

const ModalImg = styled.img`
    width: 190px;
    height: 190px;
`;

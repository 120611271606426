import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import ProgramsModal from "../../../templates/my-progress/ProgramsModal";
import GenericChips from "../../../common/GenericChip";
import { useHistory } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const ProgramCard = (props) => {
  const { program } = props;
  const history = useHistory();

  // STATE
  const [isOpen, setIsOpen] = useState(false);
  const [report, setReport] = useState(false);
  // FUNCTIONS
  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  const handleRedirect = () => {
    // CUANDO ESTÉ BIEN EL ENDPOINT ESTO DEBERÍA FUNCIONAR
    const hash = encode.encode(String(program.id), "base64");
    if (program.from === "repo") {
      if (!!program.has_study_plan) {
        history.push(`../mi-progreso/${hash}`);
      }
    }
    if (program.from === "sis") {
      setReport(true);
      handleOpenModal();
    }
  };

  return (
    <>
      <ProgramCardContainer
        from={program.from}
        studyPlan={program?.has_study_plan}
        title={program.program}
        onClick={handleRedirect}
      >
        <PhotoWrapper>
          <ImgFake
            src={program.image_url || "/assets/aden/logo-aden-simple-red.png"}
          />
        </PhotoWrapper>
        <Body>
          <Label>
            {program.from === "repo" && (
              <GenericChips
                title="Programa"
                color="#5E80DB"
                textColor="#fff"
                fontSize=".75rem"
              />
            )}

            {!!program.academic_phase && (
              <GenericChips
                title={program.academic_phase || program.state}
                color="#591AB6"
                textColor="#fff"
                fontSize=".75rem"
              />
            )}
          </Label>
          <Plan>{program.program.toLowerCase()}</Plan>
          <ProgressWrapper>
            <LinearProgressWrapper>
              <LinearProgress
                porcentaje={
                  program.total_progress > 100 ? 100 : program.total_progress
                }
              />
            </LinearProgressWrapper>
            <Percentage>
              {program.total_progress > 100 ? 100 : program.total_progress}%
            </Percentage>
          </ProgressWrapper>
          <CoursesWrapper>
            {program.from === "sis" && !!program.subjects_qty && (
              <CourseWrapper>
                <Quantity>{program.subjects_qty}</Quantity>
                <CourseText>asignaturas</CourseText>
              </CourseWrapper>
            )}

            <CourseWrapper>
              <Quantity>{program.in_progress}</Quantity>
              <CourseText>en progreso</CourseText>
            </CourseWrapper>
            <CourseWrapper>
              <Quantity>{program.finished}</Quantity>
              <CourseText>terminados</CourseText>
            </CourseWrapper>
            <CourseWrapper>
              <Quantity>{program.pending}</Quantity>
              <CourseText>pendientes</CourseText>
            </CourseWrapper>
          </CoursesWrapper>
          {!!program.discharge_date && (
            <DateWrapper>
              <p>
                Fecha de alta: <span>{program.discharge_date}</span>
              </p>
            </DateWrapper>
          )}
        </Body>
      </ProgramCardContainer>
      {report && (
        <ProgramsModal
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          program={program}
        />
      )}
    </>
  );
};

export default ProgramCard;

const ProgramCardContainer = styled(Card)`
  display: ${(p) =>
    p.from === "sis" ? "flex" : p.studyPlan ? "flex" : "none"};
  justify-content: space-between;
  height: 250px;
  gap: 1rem;
  position: relative;
  border-radius: 20px;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  cursor: ${(p) =>
    p.from === "sis" ? "pointer" : p.studyPlan ? "pointer" : "default"};

  :before {
    content: "";
    height: 100%;
    width: 100%;
    background: #5e80db80;
    opacity: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    :before {
      opacity: 1;
    }
  }
`;

const DateWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  display: flex;
  align-items: center;
  right: 0.8rem;
  top: 1.3rem;
  z-index: 4;
  padding: 0.2rem;
  background-color: #3bd39c;
  border-radius: 5px;
  width: fit-content;

  p {
    color: #fff;
    font-size: 0.7rem;
  }
`;

const PhotoWrapper = styled.div`
  width: 90px;
`;

const ImgFake = styled.img`
  border-bottom-right-radius: 20px;
  position: absolute;
  width: 115px;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  object-position: center;
`;

const Body = styled.div`
  display: flex;
  background-color: white;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  z-index: 3;
  width: 500px;
  border-top-left-radius: 20px;
  :before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: -50px;
    bottom: 0px;
    box-shadow: 22px 0 0px 0 white;
    border-bottom-right-radius: 25px;
  }
`;

const Label = styled.span`
  width: fit-content;
  display: flex;
  gap: 4px;
`;

const Plan = styled.h1`
  font-size: 1rem;
  color: #222222;
  height: 100%;
  font-weight: 700;
  width: 260px;
  text-transform: capitalize;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #3bd39c;
`;

const Percentage = styled.span`
  font-size: 0.9rem;
  color: #3bd39c;
  flex-wrap: wrap;
`;

const CoursesWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  column-gap: 0.5rem;
`;

const CourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const Quantity = styled.span`
  font-size: 0.9rem;
  text-align: center;
  border-radius: 5px 5px 0 0;
  padding: 0.25rem;
  color: #616161;
  background-color: #eaeaea;
`;

const CourseText = styled.span`
  font-size: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0 0 5px 5px;
  padding: 0.25rem;
  color: #ffffff;
  background-color: #ababab;
`;

import { authActions } from "./actions";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken, token },
      } = store.getState();
      if (authToken || token) {
        config.headers.Authorization = `Bearer ${authToken || token}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (res) => {
      if (res.data.token) {
        //console.log("RES: ", res);
        store.dispatch(authActions.setRefreshToken(res.data.token));
      }
      return res;
    },
    (error) => {
      const { response } = error;
      if (response) {
        console.log("ERROR: ", error);
        if (
          response.data?.code === "expired_token" ||
          response.data?.detail === "Token vencido"
        ) {
          store.dispatch(authActions.logoutUser());
        }
      }

      return Promise.reject(error);
    }
  );
}

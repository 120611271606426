import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";

const UpsellingSelect = (props) => {
  const { handleSelected } = props;

  const dataFake = [
    {
      value: 0,
      label: "DIPLOMADO",
    },
    {
      value: 1,
      label: "ESPECIALIZACIÓN",
    },
    {
      value: 2,
      label: "MAESTRÍA",
    },
  ];

  const [checked, setChecked] = useState(0);

  const handleChange = (value) => {
    setChecked(value);
    handleSelected(value === 0 ? "diplomado" : "oportunidad");
  };

  return (
    <Container>
      <Dotted />
      <RadioForm>
        {dataFake.map((item) => (
          <RadioGroupContent>
            {item.value === checked && <PersonPinCircleFake />}
            <FormControlLabelFake
              value={item.value}
              checked={item.value === checked ? true : false}
              control={<RadioFake id="aca" size="small" />}
              label={item.label}
              labelPlacement="bottom"
              onClick={() => handleChange(item.value)}
            />
          </RadioGroupContent>
        ))}
      </RadioForm>
    </Container>
  );
};

export default UpsellingSelect;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const RadioForm = styled(RadioGroup)`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  position: relative;
  /* align-items: center; */
`;

const FormControlLabelFake = styled(FormControlLabel)`
  span {
    color: ${(p) => (p.checked ? "#222" : "#aaa")};
    font-size: 10px;
  }
`;

const Dotted = styled.div`
  width: 100%;
  height: 0px;
  margin-bottom: 14px;
  border-top: 3px #c9c9c9 dotted;
  position: absolute;
`;

const RadioFake = styled(Radio)`
  background-color: white;
  div {
    color: #3bd39c;
  }
  :hover {
    background-color: white;
  }
`;

const RadioGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PersonPinCircleFake = styled(PersonPinCircleIcon)`
  color: #3bd39c;
  font-size: 24px;
  position: absolute;
  top: -25px;
`;

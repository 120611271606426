import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";

import CardRating from "./CardRating";

const OverflowRanking = (props) => {
  const { ranking, limit } = props;

  const podio = ranking.slice(0, `${limit}`);

  // RETURN
  return (
    <Overflow>
      {ranking.length === 0 ? (
        <NotFoundWrapper>
          <Text fontSize="1rem" fontWeight="700" color="primary" component="h2">
            No hay usuarios registrados aún...
          </Text>
        </NotFoundWrapper>
      ) : (
        podio.map((user, index) => (
          <CardRating user={user} key={index} index={index} />
        ))
      )}
    </Overflow>
  );
};

export default OverflowRanking;

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: clip;
`;

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

import { useEffect, useState } from "react";
import styled from "styled-components";
import SkeletonLoading from "../../../components/common/SkeletonLoading";
import { getMonthName } from "../../../utils/dates.utils";
import { Text } from "../../../components/common/Texts";
import { removeDuplicateObjects } from "../../../utils/array.utils";
import DateCardSchedule from "./DateCardSchedule";

const EventsDateList = (props) => {
  const { allEevents } = props;
  const [events, setEvents] = useState(null);

  useEffect(() => {
    !!allEevents && handleListEvents(allEevents);
  }, [allEevents]);

  const filterDatesByMonth = (events, month) => {
    const filteredEvents = events.filter(
      (event) => getMonthName(event.date_begin) === month
    );

    return filteredEvents;
  };

  const handleListEvents = (events) => {
    const formattedEvents = events.map((event) => {
      return {
        month: getMonthName(event.date_begin),
        events: filterDatesByMonth([...events], getMonthName(event.date_begin)),
      };
    });
    setEvents(removeDuplicateObjects(formattedEvents, "month"));
  };

  if (!events) {
    return <SkeletonLoading height={420} width="550px" />;
  }
  return (
    <Wrapper>
      <Body>
        {events?.map(
          (event) =>
            event?.month && (
              <MonthContainer key={event.month}>
                <MonthWrapper key={event.month}>
                  <Text color="#616161" fontSize="16px" fontWeight="400">
                    <b>{event?.month}</b>
                  </Text>
                </MonthWrapper>
                <EventsWrapper>
                  {event?.events?.map((event) => (
                    <DateCardSchedule key={event.id} event={event} />
                  ))}
                </EventsWrapper>
              </MonthContainer>
            )
        )}
      </Body>
    </Wrapper>
  );
};

export default EventsDateList;

const Wrapper = styled.div`
  box-sizing: border-box;
  max-height: 60vh;
  overflow-y: auto;
  width: 80%;
  padding-bottom: 1rem;
  @media screen and (max-width: 950px) {
    max-height: 300px;
  }
`;

const Body = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
  box-sizing: border-box;
`;

const MonthContainer = styled.div`
  height: 100%;
  padding-bottom: 1rem;
`;

const MonthWrapper = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #616161;
    bottom: -0.5rem;
  }
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  gap: 2rem;
`;

import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import styled from "styled-components";
import FormEventModal from "./FormEventModal";
import { getCountries } from "../../../../redux/api/utils";
import InfoRegisterModal from "./InfoRegisterModal";

const CardEventModal = (props) => {
  const {
    crm_id,
    handleModal,
    getDynamicEvent,
    tabsFake,
    event,
    is_registered,
  } = props;
  // STATES
  const [countries, setCountries] = useState(null);
  const [isRegisterFake, setIsRegisterFake] = useState(false);

  useEffect(() => {
    !countries && getCountry();
  }, [countries]);

  // FUNCTIONS
  const getCountry = async () => {
    try {
      const request = await getCountries();
      setCountries(request.data.response_data);
    } catch (error) {}
  };

  return (
    <CardFake>
      {!!is_registered ? (
        <InfoRegisterModal event={event} />
      ) : isRegisterFake === true ? (
        <InfoRegisterModal event={event} />
      ) : (
        <FormEventModal
          tabsFake={tabsFake}
          getDynamicEvent={getDynamicEvent}
          handleModal={handleModal}
          crm_id={crm_id}
          countries={countries}
          setIsRegisterFake={setIsRegisterFake}
        />
      )}
    </CardFake>
  );
};

export default CardEventModal;

const CardFake = styled(Card)`
  height: 100%;
  width: 100%;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
`;

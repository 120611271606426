import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "@mui/material";
import Text from "../../../common/Text";
import InfoEventModal from "../../molecules/events/InfoEventModal";
import CardEventModal from "../../molecules/events/CardEventModal";
import CancelIcon from "@mui/icons-material/Close";

const RegisterEventModal = (props) => {
  const { handleClick, event, getDynamicEvent, tabsFake, setFlagForEvents } =
    props;

  const { crm_id, is_registered } = event;

  // STATE
  const [isOpen, setIsOpen] = useState(true);
  const [viewForm, setViewForm] = useState(false);

  // FUNCTIONS
  function handleModal() {
    handleClick();
    // setFlagForEvents(true);
    setIsOpen(false);
  }

  const handleForm = () => {
    setViewForm(!viewForm);
  };

  // RETURN
  return (
    <>
      <ModalFake onClose={handleModal} open={isOpen}>
        <Container>
          <InfoEventModal event={event} />
          <WrapperCard viewForm={viewForm}>
            <CardEventModal
              tabsFake={tabsFake}
              handleModal={handleModal}
              getDynamicEvent={getDynamicEvent}
              crm_id={crm_id}
              event={event}
              is_registered={is_registered}
            />
          </WrapperCard>
          <CloseModal onClick={handleModal}>
            <CancelIcon />
          </CloseModal>
          <RegisterButton
            variant="contained"
            color="primary"
            onClick={handleForm}
          >
            {!!is_registered ? "Código de asistencia" : "Registrarme"}
          </RegisterButton>
        </Container>
      </ModalFake>
    </>
  );
};

export default RegisterEventModal;

const ModalFake = styled(Modal)`
  /* position: relative; */
`;

const Container = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  border-radius: 20px;
  width: 70%;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: hidden;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0rem;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const WrapperCard = styled.div`
  min-height: 80%;
  max-height: 100%;
  width: 400px;
  position: absolute;
  bottom: 40px;
  right: 40px;
  transition: all 0.5s ease-in-out;

  @media (max-width: 1350px) {
    top: ${(p) => (p.viewForm ? "100px" : "100%")};
    bottom: ${(p) => (p.viewForm ? "15px" : "100%")};

    right: 0;
    left: 0;
    display: block;
    margin: 0 auto;
    overflow: auto;
  }
`;

const CloseModal = styled(Button)`
  position: absolute;
  top: 15px;
  right: 15px;
  svg {
    background-color: #591AB6;
    padding: 0.6rem;
    border-radius: 100%;
    color: #fff;
    font-size: 1.6rem;
  }
`;

const RegisterButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: none;

  @media (max-width: 1350px) {
    display: block;
  }
`;

import axios from "axios";

export async function postEvets(data) {
    try {
        const URL = `${process.env.REACT_APP_SISAPI}/v2/acropolis/events/register-event`;
        const response = await axios.post(URL, data);
        return response.data;
    } catch (error) {
        return { error };
    }
}

export async function getInfoEvents(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/events/${id}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function getDynamicEvents(
    modality,
    limit,
    timelapse,
    ondemand_id
) {
    let URL = `${process.env.REACT_APP_SISAPI}/v2/acropolis/events?modality=${modality}&timelapse=${timelapse}`;

    if (ondemand_id) {
        URL += `&id_acropolis_lab=${ondemand_id}`;
    }

    if (limit !== "all") {
        URL += `&limit=${limit}`;
    }

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function getOndemandEvents(ondemandId) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/events/ondemand/${ondemandId}`
    try {
        const request = await axios.get(URL)
        return request.data.response
    } catch (error) {
        return error
    }
}

import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

const NotReferred = () => {
  return (
    <Container>
      <img src="./assets/sharing-Ideas.svg" />
      <h2>Parece que aún no refieres a nadie</h2>
      <p>
        Añade un nuevo referido y una vez que se inscriba en un programa ADEN
        podrás reclamar beneficios exclusivos.
      </p>
      <Button color="primary" variant="contained">
        Referir amigo
      </Button>
    </Container>
  );
};

export default NotReferred;

const Container = styled.div`
  width: calc(100% - 4rem);
  padding: 2rem;
  background-color: #fafafa;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;

  img {
    width: 300px;
  }

  h2 {
    color: #000;
    font-size: 1.3rem;
  }

  p {
    color: #000;
    font-size: 1rem;
  }
`;

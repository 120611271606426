import axios from "axios";

export async function getMyReferred() {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/referidos`;

  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

import { createSlice } from "@reduxjs/toolkit";

const initialChatState = {
  channel: "",
  participants: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    channelSelect: (state, action) => {
      const channel = action.payload;
      return {
        ...state,
        channel: channel,
      };
    },
    setParticipants: (state, action) => {
      const participants = action.payload;
      return {
        ...state,
        participants,
      };
    },
  },
});

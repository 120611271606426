import React from "react";
import styled from "styled-components";

import { Button } from "@mui/material";

const LoadMore = (props) => {
  const { handleLoadMore } = props;

  return (
    <LoadMoreContainer>
      <Button variant="contained" color="primary" onClick={handleLoadMore}>
        Ver más
      </Button>
    </LoadMoreContainer>
  );
};

export default LoadMore;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

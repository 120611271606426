/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ondemandActions,
  coursesTabsActions,
} from "../../redux/actions";
import { useParams } from "react-router-dom";
import { registerUser } from "../../redux/api/business/registration";
import { getMainThematicService } from "../../redux/api/courses";
import { useHistory } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

const useBusiness = (type) => {
  const { id } = useParams();

  const history = useHistory();

  // REDUX
  const dispatch = useDispatch();
  const { ondemand_id, repo_id, personal_email } = useSelector((state) => state?.auth?.user);
  const { ondemand, academic } = useSelector((state) => state?.ondemand);
  const ondemandAcademic = useSelector((state) => state?.ondemand?.academic);
  const enabledTabs = useSelector((state) => state?.coursesTabs?.tabs);
  const programs = useSelector((state) => state?.programs?.programs);
  const user = useSelector((state) => state?.auth?.user);

  const myCourseTabs = [
    {
      id: 6,
      label: `Rutas formativas`,
      disabled: !enabledTabs?.habilita_rutas_formativas,
    },
    {
      id: 0,
      label: "Cursos empresa",
      disabled: !enabledTabs?.habilita_cursos_empresa,
    },
    {
      id: 1,
      label: "Cursos ADEN",
      disabled: !enabledTabs?.habilita_cursos_aden,
    },
    {
      id: 2,
      label: "Cursos adicionales",
      disabled: !enabledTabs?.habilita_cursos_adicionales,
    },
    /* { id: 3, label: "Test de habilidades" }, */
  ];

  // CUSTOM HOOKS
  //const trainingRoutes = []

  const [tab, setTab] = useState(null);
  const [tabs, setTabs] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [thematic, setThematic] = useState(null);
  const [allThematic, setAllThematic] = useState(null);
  const [title, setTitle] = useState("Mi empresa");
  const [loadingtabs, setLoadingtabs] = useState(false);

  useEffect(() => {
    if (type !== "card") {
      dispatch(ondemandActions.getAddiotionalCoursesRequest(ondemand_id));
      dispatch(
        ondemandActions.getOndemandByCompetitorRequest({
          id: ondemand_id,
          email: personal_email,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (type !== "card") {
      !enabledTabs &&
        dispatch(coursesTabsActions.getCoursesTabsRequest(ondemand_id));
      !!enabledTabs &&
        setTab(
          enabledTabs?.habilita_rutas_formativas
            ? parseInt(id)
            : enabledTabs?.habilita_cursos_aden
              ? 0
              : enabledTabs?.habilita_cursos_empresa
                ? 1
                : enabledTabs?.habilita_cursos_adicionales
                  ? 2
                  : 3
        );
    }
  }, [enabledTabs]);

  useEffect(() => {
    handleDisplayedTabs(myCourseTabs);
    setTitle(ondemand?.acropolis_title || "Mi empresa");
  }, [academic, ondemand]);

  useEffect(() => {
    if (type !== "card") {
      if (thematic === null) {
        getMainThematic();
        getAllThematic();
      }
    }
  }, []);

  // FUNCTIONS
  const handleTab = (e, value) => {
    setTab(value);
  };

  const getMainThematic = useCallback(async () => {
    const response = await getMainThematicService(user?.od_participant_id?.id);
    if (!response.error) {
      setThematic(response);
    }
  }, [user?.od_participant_id?.id]);

  const getAllThematic = useCallback(async () => {
    const response = await getMainThematicService(0);
    if (!response?.error) {
      setAllThematic(response);
    }
  }, []);

  // ========== VALIDACIÓN MATRICULACIÓN OA ==========

  // MATRICULA AL ALUMNO EN UN OA Y REDIRECCIONA AL CURSO
  const registrateUser = async (ondemandId, repoId, objId, type) => {
    const hash = encode.encode(objId + "", "base64");
    const res = await registerUser(ondemandId, repoId, objId, type);

    if (res.error) {
      console.log("Register error", res.error);
    } else {
      if (type === "subject") {
        history.push(
          `/asignatura/${hash}`
        );
      } else {
        history.push(
          `/curso/${hash}?origin=${!!ondemand.enable_correction_activities ? "plan" : "alab"}`
        );
      }
    }
  };

  const handleOpenModal = (objApId, data) => {
    const hash = encode.encode(objApId + "", "base64");
    const registeredCourses = academic?.aden.find(
      (item) => item.id === objApId
    );

    if (registeredCourses !== undefined) {
      return history.push(
        `/curso/${hash}?origin=${!!ondemand.enable_correction_activities ? "plan" : "alab"
        }`
      );
    }

    setOpenModal(registeredCourses === undefined);
    setCourseData(data);
  };

  // MUESTRA TABS DINAMICAMENTE DEPENDIENDO SI SE TRAE CONTENIDO
  const handleDisplayedTabs = (tabs) => {
    setLoadingtabs(true);
    const reducedTabs = tabs?.reduce((acc, curr) => {
      if (!curr?.disabled) {
        acc.push(curr);
      }
      return acc;
    }, []);

    setTabs(reducedTabs);
    setLoadingtabs(false);
  };

  return {
    handleTab,
    programs,
    title,
    allThematic,
    user,
    tabs,
    tab,
    thematic,
    ondemandAcademic,
    openModal,
    setOpenModal,
    repo_id,
    ondemand_id,
    courseData,
    registrateUser,
    handleOpenModal,
    enabledTabs,
    loadingtabs,
    myCourseTabs
  };
};

export default useBusiness;
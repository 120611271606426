import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import styled from "styled-components";
import {
  AssignmentTurnedInRounded,
  CheckCircleRounded,
  SchoolRounded,
  WatchLaterRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "CURSO",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "ESTADO CURSO",
  },
  { id: "note", numeric: true, disablePadding: true, label: "NOTA" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <SpanHidden>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </SpanHidden>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProgramTable(props) {
  const { cursos } = props;

  // STATE
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("note");
  const [page, setPage] = useState(0);
  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = 5 - Math.min(5, cursos.length - page * 5);

  return (
    <div>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={cursos.length}
          />
          <TableBody>
            {stableSort(cursos, getComparator(order, orderBy))
              // .slice(page * 5, page * 5 + 5)
              .map((curso, index) => {
                return (
                  <TableRowFake
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      history.push("./mi-progreso?tab=mis-asignaturas");
                    }}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      {curso.name}
                    </TableCell>
                    <TableCell>
                      {curso.state === "Egresado" ? (
                        <FakeChip color="#1e8065">
                          <SpanChip>{curso.state}</SpanChip>
                          <SchoolRounded fontSize="small" />
                        </FakeChip>
                      ) : curso.state === "Confirmado" ? (
                        <FakeChip color="#5e80db">
                          <SpanChip>{curso.state}</SpanChip>
                          <CheckCircleRounded fontSize="small" />
                        </FakeChip>
                      ) : curso.state === "Preinscripto" ? (
                        <FakeChip color="#c29f43">
                          <SpanChip>{curso.state}</SpanChip>
                          <AssignmentTurnedInRounded fontSize="small" />
                        </FakeChip>
                      ) : (
                        <FakeChip color="#b75e26">
                          <SpanChip>{curso.state}</SpanChip>
                          <WatchLaterRounded fontSize="small" />
                        </FakeChip>
                      )}
                    </TableCell>
                    <TableCell align="right">{curso.note}</TableCell>
                  </TableRowFake>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
                component="div"
                count={cursos.length}
                rowsPerPage={5}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[]}
            /> */}
    </div>
  );
}

const TableRowFake = styled(TableRow)`
  cursor: pointer;
`;

const SpanHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

const FakeChip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  background-color: ${(props) => props.color};
  color: #ffffff;
  max-width: 130px;
`;

const SpanChip = styled.span`
  font-size: 0.8rem;
`;

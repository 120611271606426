import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { options } from "../utils/snackStyle";

const useActiveAccount = (handleCloseModal, isPorvenir) => {
    const cases = [
        "porvenir",
        "coopecaja",
        "cr.acropolislab",
        "alabgt",
        "dm.acropolislab",
        "pmrd",
    ];
    const origin = {
        porvenir: 162,
        coopecaja: 185,
        pmrd: 337,
        alabgt: 282,
        "cr.acropolislab": 251,
        "dm.acropolislab": 8,
    };
    // STATES
    const [courses, setCourses] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState({
        isOpen: false,
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [originId, setOriginId] = useState(null);

    // EFFECTS
    useEffect(() => {
        courses === null && getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (originId === null) {
            handleOrigin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originId]);

    const [openSnackbar] = useSnackbar(options);

    // FUNCTIONS
    const handleOrigin = () => {
        cases.forEach((e) => {
            if (window.origin.includes(e)) {
                setterOrigin(e);
            }
        });
    };

    const setterOrigin = (originCases) => {
        setOriginId(origin[originCases]);
    };

    const postData = async (data) => {
        setIsLoading(true);

        const body = {
            firstname: data.name,
            lastname: data.lastName,
            email: data.email,
            password: data.password,
            courses: courses.map((item) => item.id),
            code: data.cod,
            incompany_id: originId,
        };

        if (isPorvenir) {
            body.company_name = data?.company_name;
            if (data?.nit !== 0) {
                body.nit = data?.nit;
            }
        }
        const httpConfig = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
        try {
            const request = await fetch(
                `https://sisapi.aden.org/api/v1/acropolis/ondemand/register/acropolislab`,
                httpConfig
            );
            const json = await request.json();

            setShowSnackbar(true);
            setIsLoading(false);
            if (!!json?.error) {
                openSnackbar(json?.error);
            } else {
                openSnackbar("¡Cuenta activada correctamente!");
            }
            handleCloseModal();

            return json;
        } catch (error) {
            setIsLoading(false);

            return { error };
        }
    };

    const getCourses = async () => {
        try {
            const request = await fetch(
                `https://repositorio-api.aden.org/api/v1/repo_aden/ondemand/courses/own_by_ondemand/162`
            );
            const json = await request.json();
            return setCourses(json.response_data);
        } catch (error) {
            return { error };
        }
    };

    return { postData, showSnackbar, setShowSnackbar, isLoading };
};

export default useActiveAccount;

import styled from "styled-components";

export const Text = styled.div`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color};
  text-align: ${(p) => p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing};
  text-transform:${(p) => p.textTransform};
`;

export const Span = styled.span`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color};
  text-align: ${(p) => p.textAlign};
`;

export const Href = styled.a`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color};
  text-align: ${(p) => p.textAlign};
  text-decoration: none;
  :hover {
    filter: brightness(0.8);
  }
`;

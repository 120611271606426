import React from "react";
import styled from "styled-components";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CardCertificate = (props) => {
    const { certificate } = props;
    const history = useHistory();

    const colorPrimary = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    const partnerId = useSelector((state) => state.auth.user.partner_id);

    const handleRedirect = () => {
        if (certificate?.url_repo_alab) {
            window.open(certificate?.url_repo_alab);
        } else {
            history.push(
                `../mis-certificados/${partnerId}/${certificate.id_partner_insignia}`
            );
        }
    };

    return (
        <Wrapper onClick={handleRedirect} colorPrimary={colorPrimary}>
            <WrapperIcon colorPrimary={colorPrimary}>
                <WorkspacePremiumIcon />
            </WrapperIcon>
            <Type colorPrimary={colorPrimary}>
                {certificate.descripcionInsignia || "certificado"}
            </Type>
            <Title>{certificate.nombre}</Title>
            <Description>{certificate.descripcion}</Description>
            <Date>Obtenido el {certificate.fechaExpedicion}</Date>
        </Wrapper>
    );
};

export default CardCertificate;

const Wrapper = styled.div`
    display: flex;
    width: calc(100% - 3rem);
    border: 1px solid #f1f1f1;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    padding: 1.5rem;
    cursor: pointer;

    :hover {
        ${(p) => `
    background: linear-gradient(180deg, #ffffff 0%, ${p.colorPrimary}50 99.99%);

`}
    }
`;

const WrapperIcon = styled.div`
    svg {
        color: ${(p) => p.colorPrimary};
        font-size: 1.5rem;
    }
    display: flex;
    width: 100%;
    text-align: start;
`;

const Type = styled.p`
    color: ${(p) => p.colorPrimary};
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: start;
`;

const Title = styled.h2`
    color: #222222;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 22px;
`;

const Description = styled.p`
    color: #616161;
    font-size: 0.9rem;
    text-align: start;
`;

const Date = styled.span`
    color: #a8a8a8;
    font-size: 0.8rem;
`;

import React from "react";
import { ErrorMessage, Field } from "formik";
import styled from "@emotion/styled";

const DefaultField = (props) => {
    const { name, type, label, smallWidth, show = true, required = false } = props;

    // RETURN
    return (
        <Container smallWidth={smallWidth}>
            <Label>{label}{required && " *"}</Label>
            <CustomField
                name={name}
                type={type}
                variant="outlined"
                color="primary"
                fullWidth
                show={show}
                {...props}
            />
            <ErrorMessage name={name} component={CustomError} />
        </Container>
    );
};

export default DefaultField;

const Container = styled.div`
    /* width: ${(p) => (p.smallWidth ? "fit-content" : "100%")}; */
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
`;
const Label = styled.div`
    color: #616161;
    font-size: 1rem;
    font-weight: normal;
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #f8423f;
    position: absolute;
    right: 0;
`;

const CustomField = styled(Field)`
    /* display: ${(props) => !props.show && "none"}; */
    border-radius: 10px;
    padding: 0.3rem;
    border: solid 1.82px #c4c4c4;
    height: 24px;
`;
import React from "react";
import styled from "styled-components";
import MainFavs from "../../components/templates/favorites/MainFavs";
import { titleHTML } from "../../helpers/title.helper";
import useTheme from "../../hooks/useTheme";

const MyFavs = () => {
  titleHTML("Mis Favoritos");

  const { primary } = useTheme();

  return (
    <FavsWrapper>
      <Title color={primary}>Mis favoritos</Title>
      <MainFavs />
    </FavsWrapper>
  );
};

export default MyFavs;

const FavsWrapper = styled.div`
  display: grid;
  row-gap: 10px;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${(p) => p.color};
`;

export const LOGIN_TOKEN_REQUEST = "LOGIN_TOKEN_REQUEST";
export const LOGIN_TOKEN_FAIL = "LOGIN_TOKEN_FAIL";
export const LOGIN_TOKEN_SUCCESS = "LOGIN_TOKEN_SUCCESS";

export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";

export const SET_CHALLENGE_DRACMA = "SET_CHALLENGE_DRACMA";

export const SET_TOKEN_AUTH = "SET_TOKEN_AUTH";

import React from "react";
import styled from "styled-components";

const GridCard = (props) => {
    const { cards = [], component: Card, width = "300px" } = props;

    return (
        <GridCardWrapper width={width}>
            {cards?.map((card, i) => (
                <Card key={i} values={card} />
            ))}
        </GridCardWrapper>
    );
};

export default GridCard;

const GridCardWrapper = styled.div`
    padding: 2rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${(p) => p.width}, 1fr));
    gap: 24px;
    justify-items: center;
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "@mui/material";
import Text from "../../../common/Text";
import { postAssignmentPrograms } from "../../../../redux/api/studyPlan";
import { useSnackbar } from "react-simple-snackbar";
import { options } from "../../../../utils/snackStyle";

const ModalAssignment = (props) => {
  const {
    handleClick,
    data,
    repo_id,
    setDataAssigment,
    dataAssigment,
    setIsChange,
    idProgram,
    setIsFlag,
    isFlag,
  } = props;
  // REDUX
  const [openSnackbar] = useSnackbar(options);

  // STATE
  const [isOpen, setIsOpen] = useState(true);

  // FUNCTIONS
  function handleModal() {
    handleClick();
    setIsOpen(false);
  }

  const handlePost = () => {
    postAssignment("assignment", data.id);
  };

  const postAssignment = async (type, id) => {
    const body = {
      type: type,
      assignment: id,
      program_id: idProgram,
    };

    const response = await postAssignmentPrograms(repo_id, body);

    if (!response.error) {
      const result = dataAssigment;

      result.forEach((element) => {
        if (element.id === data.id) {
          element.state = "confirmado";
          setIsChange(true);
          setIsFlag(!isFlag);
        }
      });

      setTimeout(() => {
        setIsChange(false);
      }, 300);

      setDataAssigment(result);
      openSnackbar("¡Matriculación correcta!");
    } else {
      openSnackbar("¡No se pudo matricular a la asignatura!");
    }
  };

  // RETURN
  return (
    <>
      <Modal onClose={handleModal} open={isOpen}>
        <Container>
          <TextWrapper>
            <Text fontSize="1.5rem" fontWeight="700" color="primary">
              Estás a punto de matricularte en {data.name || data.nombre_publicacion}
            </Text>
            <TextFake fontSize="1.2rem" fontWeight="400">
              ¡Confirma tu elección!
            </TextFake>
          </TextWrapper>
          {/* Botones */}
          <WrapperButtons>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                handleModal();
              }}
            >
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={handlePost}>
              Confirmar
            </Button>
          </WrapperButtons>
        </Container>
      </Modal>
    </>
  );
};

export default ModalAssignment;

const Container = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem;
  border-radius: 20px;
  width: 500px;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0rem;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 1.5rem;
  width: 70%;
`;
const TextFake = styled(Text)`
  color: #616161;
  margin-top: 1rem;
  span {
    font-weight: bold;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 468px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

import React from "react";
import styled from "styled-components";
import ErrorResponse from "../../../common/ErrorResponse";
import SimpleLoading from "../../../common/SimpleLoading";
import { Text } from "../../../common/Texts";

const OAList = (props) => {
  const { assignment, assignmentSelected, handleClick } = props;

  if (assignment === "error") {
    return (
      <ListWrapper>
        <ErrorResponse message="No se encontro ninguna tarea" />
      </ListWrapper>
    );
  }

  if (!assignment) {
    return (
      <ListWrapper>
        <SimpleLoading />
      </ListWrapper>
    );
  }

  return (
    <Container>
      <Text color="#777" fontWeight="bold" fontSize="16px">
        Asignaturas
      </Text>
      <ListWrapper>
        {assignment.map((assignment) => (
          <Item
            active={assignmentSelected?.id === assignment?.id}
            onClick={() => handleClick(assignment?.id)}
          >
            {assignment?.name || "Asinatura sin nombre"}
          </Item>
        ))}
      </ListWrapper>
    </Container>
  );
};

export default OAList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.div`
  width: calc(100% - 16px - 5px);
  height: 50px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  border-left: 5px solid #591AB6;
  background-color: white;
  padding: 0 8px;
  display: flex;
  font-weight: 600;
  align-items: center;
  ${(p) =>
    p.active && "background: linear-gradient(270deg,#ffe2e1 0%,#fff8f7 100%);"};
  :hover {
    transition: ease-in-out 0.3s all;
    background: linear-gradient(270deg, #ffe2e1 0%, #fff8f7 100%);
    cursor: pointer;
  }
`;

import React from "react";
import styled from "styled-components";
import { titleHTML } from "../../helpers/title.helper";

const Iframe = () => {
  titleHTML("Consultas sincrónicas");

  return (
    <Container>
      <CustomIframe
        src="https://app.spatial.chat/s/adenbs?room=smvdqPoykiWXdfRKG26Y"
        frameborder="0"
        seamless="seamless"
        scrolling="yes"
        allow="camera *;microphone *;autoplay"
      />
    </Container>
  );
};

export default Iframe;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 2rem);
`;

const CustomIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
`;

import React, { useState } from "react";
import styled from "styled-components";

import {
  EventAvailable,
  EventBusy,
  CalendarToday,
  Stars,
} from "@mui/icons-material";
import Moment from "react-moment";
import moment from "moment";
import { Avatar, Button, Card } from "@mui/material";
import { AvatarGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import UsersModal from "../../organisms/my-progress/UsersModal";
import { authActions, coursesActions } from "../../../../redux/actions";
import ConfirmDialog from "./ConfirmDialog";
import * as encode from "nodejs-base64-encode";
import { useHistory } from "react-router-dom";

const CardCourse = (props) => {
  const { value, index } = props;
  const fecha_inicio = value.curso_fecha_inicio;

  const fecha_vencimiento = value.curso_fecha_fin;
  const daysDateEnd = value.curso_duracion_semanas * 7;

  function addDaysToDate(date, days) {
    var res = new Date(date);
    res.setDate(res.getDate() + days);
    return res;
  }

  const userStatus = useSelector((state) => state.userStatus);
  const { push } = useHistory();

  const isBlock = !!userStatus?.blockedStatus?.blocked;

  // REDUX
  const dispatch = useDispatch();

  const baseURL = window.location.origin;

  const hashCourseId = encode.encode(value?.repo_course_id + "", "base64");

  // STATE
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setuser] = useState(null);
  const { canvas_login } = useSelector((state) => state.auth);

  // FUNCTIONS
  function handleOpenModalProfesor() {
    setIsOpen(true);
    setuser("Profesores");
  }

  function handleOpenModalAlumno() {
    setIsOpen(true);
    setuser("Alumnos");
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  function handleEnrollClick(curso_id, es_nivelatoria, repo_course_id) {
    let body = { curso_id, es_nivelatoria };
    body = !!repo_course_id ? { ...body, repo_course_id } : { ...body };

    dispatch(coursesActions.enrollCourseRequest(body));
  }

  const handleOpenConfirm = () => {
    setOpen(true);
  };

  const handleConfirm = (value, selectedCourse) => {
    setOpen(false);
    if (value) {
      handleEnrollClick(
        selectedCourse.curso_id,
        selectedCourse.es_nivelatoria,
        selectedCourse.repo_course_id
      );
    }
  };

  const handleOpenCourse = () => {
    if (value.repo_enrollment_id && value.repo_course_id) {
      // SE ABRE EN ACROPOLIS CON PUSH
      push(`../asignatura/${hashCourseId}?origin=alab`);
    }
  };

  // RETURN
  return (
    <>
      <CourseCardContainer>
        <ImgWrapper>
          <FakeImg
            src={`/assets/transient/courses/${index}.jpg`}
            alt="course"
          />
        </ImgWrapper>
        <Body>
          <Estado estado={value.estado}>
            {value.estado == "Confirmado" ? "En curso" : value.estado}
          </Estado>
          {value.es_nivelatoria && (
            <StatusNivelation>Nivelatorio opcional</StatusNivelation>
          )}
          {value.estado !== "Preinscripto" && (
            <DateGroup>
              <>
                {!!fecha_inicio && (
                  <ItemWrapper>
                    <CalendarToday style={{ fontSize: 30 }} />
                    <DateWrapper>
                      <TextSpan>FECHA INICIO</TextSpan>
                      <DateSpan>
                        <Moment format="DD/MM/YYYY">{fecha_inicio}</Moment>
                      </DateSpan>
                    </DateWrapper>
                  </ItemWrapper>
                )}

                {!!value.curso_fecha_fin && (
                  <ItemWrapper>
                    <EventAvailable style={{ fontSize: 30 }} />
                    <DateWrapper>
                      <TextSpan>FECHA FIN</TextSpan>
                      <DateSpan>
                        <Moment format="DD/MM/YYYY">
                          {addDaysToDate(fecha_inicio, daysDateEnd)}
                        </Moment>
                      </DateSpan>
                    </DateWrapper>
                  </ItemWrapper>
                )}
              </>
            </DateGroup>
          )}
          <Title>{value.curso.toTitleCase()}</Title>

          {/* COMENTADO HASTA QUE FUNCIONE BIEN EL PROGRESO */}
          {/* <ProgressWrapper>
            <LinearProgressWrapper>
              <LinearProgress porcentaje={value.progreso} />
            </LinearProgressWrapper>
            <Percentage>{parseInt(value.progreso)}%</Percentage>
          </ProgressWrapper> */}
        </Body>
        <Footer>
          <UsersWrapper>
            <AvatarsWrapper>
              <UsersTitle>Alumnos</UsersTitle>
              <FakeAvatarGroup max={10} onClick={handleOpenModalAlumno}>
                {value.alumnos.map((alumno, index) => (
                  <FakeAvatar key={index} src={alumno} />
                ))}
              </FakeAvatarGroup>
            </AvatarsWrapper>
            <AvatarsWrapper>
              <UsersTitle>Profesores</UsersTitle>
              <FakeAvatarGroup onClick={handleOpenModalProfesor}>
                <Avatar src={value.profesor} />
                <Avatar src={value.academic} />
              </FakeAvatarGroup>
            </AvatarsWrapper>
          </UsersWrapper>
          {value.estado == "Confirmado" ? (
            <BottomFooterWrapper>
              {value.estado == "Egresado" ? (
                <NoteWrapper>
                  <NoteSpan>Tu nota:</NoteSpan>
                  <NoteText>
                    {value.nota % 1 === 0 ? value.nota : value.nota.toFixed(2)}
                  </NoteText>
                </NoteWrapper>
              ) : (
                value.es_aulaunica && (
                  <ItemWrapper>
                    <EventBusy style={{ fontSize: 30 }} />
                    <DateWrapper>
                      <TextSpan>VENCIMIENTO</TextSpan>
                      <DateSpan>
                        <Moment format="DD/MM/YYYY">{fecha_vencimiento}</Moment>
                      </DateSpan>
                    </DateWrapper>
                  </ItemWrapper>
                )
              )}
              {value.repo_enrollment_id && value.repo_course_id ? (
                <FakeButton
                  state={value.estado}
                  variant="contained"
                  onClick={handleOpenCourse}
                  rel="noopener noreferrer"
                  target={!!value.repo_enrollment_id ? "_self" : "_blank"}
                  disabled={isBlock}
                >
                  {!!value.repo_enrollment_id ? "Cursar" : "Ir a cursar"}
                </FakeButton>
              ) : (
                <FakeButton
                  state={value.estado}
                  variant="contained"
                  href="https://aden.instructure.com/login/openid_connect"
                  rel="noopener noreferrer"
                  target={!!value.repo_enrollment_id ? "_self" : "_blank"}
                  disabled={isBlock}
                >
                  {!!value.repo_enrollment_id ? "Cursar" : "Ir a cursar"}
                </FakeButton>
              )}
            </BottomFooterWrapper>
          ) : value.estado == "Preinscripto" ? (
            <BottomFooterWrapper>
              <NoteWrapper>
                <NoteSpan>¡Confirma para iniciar!</NoteSpan>
              </NoteWrapper>
              <FakeButton
                variant="contained"
                state={value.estado}
                disabled={isBlock}
                onClick={handleOpenConfirm}
              >
                Iniciar curso
              </FakeButton>
              <ConfirmDialog
                selectedCourse={value}
                open={open}
                onClose={handleConfirm}
              ></ConfirmDialog>
            </BottomFooterWrapper>
          ) : value.estado == "Egresado" ? (
            <BottomFooterWrapper>
              <NoteWrapper>
                <Stars />
                <NoteText>
                  {value.nota % 1 === 0 ? value.nota : value.nota.toFixed(2)}
                </NoteText>
              </NoteWrapper>
              <FakeButton
                variant="contained"
                state={value.estado}
                href={
                  !!value.repo_enrollment_id && !!value.repo_course_id
                    ? `${baseURL}/asignatura/${hashCourseId}?origin=alab`
                    : canvas_login
                    ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                    : "https://aden.instructure.com/login/openid_connect"
                  // : "https://aden.instructure.com"
                }
                rel="noopener noreferrer"
                target={!!value.repo_enrollment_id ? "_self" : "_blank"}
                disabled={isBlock}
              >
                Ver curso
              </FakeButton>
            </BottomFooterWrapper>
          ) : (
            <BottomFooterWrapper>
              <NoteWrapper>
                <NoteSpan>Tu nota:</NoteSpan>
                <NoteText>{value.nota}</NoteText>
              </NoteWrapper>
              <FakeButton
                variant="contained"
                state={value.estado}
                href={
                  !!value.repo_enrollment_id && !!value.repo_course_id
                    ? `${baseURL}/asignatura/${hashCourseId}?origin=alab`
                    : canvas_login
                    ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                    : "https://aden.instructure.com/login/openid_connect"
                  // : "https://aden.instructure.com"
                }
                rel="noopener noreferrer"
                target={!!value.repo_enrollment_id ? "_self" : "_blank"}
                disabled={isBlock}
              >
                {!!value.repo_enrollment_id ? "Cursar" : "Ir a cursar"}
              </FakeButton>
            </BottomFooterWrapper>
          )}
        </Footer>
      </CourseCardContainer>
      <UsersModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        title={user}
        id={value.curso_id}
      />
    </>
  );
};

export default CardCourse;

const CourseCardContainer = styled(Card)`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  min-height: 380px;
  height: 442px;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
`;

const Body = styled.div`
  height: 160px;
  background-color: white;
  position: relative;
  bottom: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
  :before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: -50px;
    left: 0;
    box-shadow: -30px 0 0px 0 white;
    border-bottom-left-radius: 20px;
  }
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  height: 50%;
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 160px;
  position: relative;
  border-bottom-left-radius: 20px;
`;

const FakeImg = styled.img`
  width: 100%;
  height: 130px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
  object-position: center;
`;

const Estado = styled.span`
  font-size: 0.6rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.estado === "Confirmado"
      ? "#5e80db"
      : props.estado === "Preinscripto"
      ? "#C29F43"
      : props.estado === "Egresado"
      ? "#1E8065"
      : ""};
  color: #ffffff;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  position: absolute;
  top: -15px;
  left: 25px;
  margin: 0 auto;
`;

const StatusNivelation = styled.div`
  font-size: 0.6rem;
  text-transform: uppercase;
  background-color: #a8a8a8;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  position: absolute;
  top: -15px;
  right: 25px;
  margin: 0 auto;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  column-gap: 0.5rem;
  color: #a8a8a8;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1rem;
  color: #a8a8a8;
`;

const DateGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  color: #a8a8a8;

  svg {
    font-size: 0.9rem;
  }
`;

const TextSpan = styled.span`
  font-size: 0.5rem;
  font-weight: 400;
`;

const DateSpan = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  flex-grow: 1;
  text-align: start;
`;

// COMENTADO HASTA QUE FUNCIONE BIEN EL PROGRESO
// const ProgressWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   column-gap: 1rem;
//   margin-top: auto;
// `;

// const LinearProgressWrapper = styled.div`
//   border-radius: 50px;
//   width: 100%;
//   height: 7px;
//   background-color: #eaeaea;
// `;

// const LinearProgress = styled.div`
//   border-radius: 50px;
//   width: ${(props) => `${props.porcentaje}%`};
//   height: 7px;
//   background-color: #5e80db;
// `;

// const Percentage = styled.span`
//   font-size: 0.9rem;
//   color: #5e80db;
//   flex-wrap: wrap;
// `;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  margin-top: auto;
  border-top: 1px solid #e8e8e8;
`;

const UsersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiAvatarGroup-avatar {
    width: 35px;
    height: 35px;
    background-color: #ececec;
    color: #b7b7b7;
    font-size: 10px;
    font-weight: 600;
  }
`;

const AvatarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const UsersTitle = styled.h2`
  font-size: 0.75rem;
  font-weight: 700;
  color: #b7b7b7;
`;

const FakeAvatarGroup = styled(AvatarGroup)`
  cursor: pointer;
`;

const FakeAvatar = styled(Avatar)`
  width: 35px;
  height: 35px;
`;

const BottomFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: #a3a3a3;
`;

const NoteSpan = styled.span`
  font-size: 0.75rem;
`;

const NoteText = styled.span`
  font-size: 1.2rem;
  color: #222222;
  font-weight: bold;
`;

const FakeButton = styled(Button)`
  background-color: ${(p) =>
    p.state === "Preinscripto"
      ? "#c29f43"
      : p.state === "Confirmado"
      ? "#5e80db"
      : "#1e8065"};
  color: #ffffff;

  :hover {
    background-color: ${(p) =>
      p.state === "Preinscripto"
        ? "#dcb54d"
        : p.state === "Confirmado"
        ? "#7e9df0"
        : "#35ae8d"};
  }
`;

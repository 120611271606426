import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";

const ADEN_URL = "https://www.aden.org/programas/";

const CardModalUpselling = (props) => {
  const {
    id,
    name,
    image,
    number,
    progress = 0,
    type = "ESPECIALIZACIóN",
    buttonTitle = "Entrar",
    handleOpenModalOpport,
  } = props;

  const items = [
    // { item: "3 semanas" },
    { item: "Certificación Acrópolis LAB " },
    { item: "Cursado 100% online" },
    { item: "Asesoramiento académico" },
    { item: "Networking" },
  ];

  const { push } = useHistory();

  const handleInformation = () => {
    if (type === "DIPLOMADO") {
      push("../producto/" + id);
    } else {
      handleOpenModalOpport(id, name);
    }
  };

  const handlePage = () => {
    if (type === "DIPLOMADO") {
      push("../oferta-educativa");
    } else {
      window.open(ADEN_URL);
    }
  };

  return (
    <CardFake>
      <Step>{number}</Step>
      <Header>
        <img
          src={!!image ? image : "../assets/upselling/image-upselling-card.png"}
        />
      </Header>
      <Body>
        <Tag>{type}</Tag>
        <Title>{name}</Title>
        <Container>
          <p>¡Ya completaste el {Math.round(progress)}%!</p>
          <ProgressWrapper>
            <LinearProgressWrapper>
              <LinearProgress porcentaje={progress} />
            </LinearProgressWrapper>
          </ProgressWrapper>
        </Container>
        <ItemsWrapper>
          {items.map((item) => (
            <div>
              <CheckIcon /> <p>{item.item}</p>
            </div>
          ))}
        </ItemsWrapper>
        <WrapperButtons>
          <Button
            onClick={handleInformation}
            fullWidth
            variant="contained"
            color="primary"
          >
            {buttonTitle}
          </Button>
          <Button onClick={handlePage} color="primary">
            Saber más
          </Button>
        </WrapperButtons>
      </Body>
    </CardFake>
  );
};

export default CardModalUpselling;

const CardFake = styled.div`
  background: #ffffff;
  position: relative;
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  gap: 1.5rem;

  @media (max-width: 1400px) {
    gap: 1rem;
    min-width: 281px;
    max-width: 281px;
  }
`;

const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  background-color: #591AB6;
  color: #fff;
  padding: 5px;
  width: calc(35px - 10px);
  height: calc(35px - 10px);
  font-weight: bold;
  font-size: 1.2rem;
  top: -10px;
  left: -10px;
`;

const Header = styled.div`
  height: 86px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 1400px) {
    height: 76px;
  }

  @media (max-height: 768px) {
    height: 0px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: calc(100% - 3rem);
  padding: 0 1.5rem;
  h2 {
    color: #000;
    font-size: 1.2rem;
  }

  @media (max-width: 1400px) {
    gap: 0.8rem;

    h2 {
      font-size: 1.1rem;
    }
  }
  @media (max-height: 768px) {
    height: 100%;
    overflow: auto;
    padding: 0 1.4rem;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

const Tag = styled.p`
  background: #591AB6;
  border-radius: 5px;
  width: fit-content;
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.3rem 0.4rem;

  @media (max-width: 1400px) {
    font-size: 0.7rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    font-size: 1rem;
    color: #3bd39c;
  }

  @media (max-width: 1400px) {
    p {
      font-size: 0.8rem;
    }
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
  margin-top: auto;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #3bd39c;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;

  div {
    display: flex;
    gap: 0.3rem;
    align-items: center;

    p {
      color: #616161;
      font-size: 1.05rem;
    }

    svg {
      color: #a8a8a8;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 1400px) {
    gap: 0.8rem;

    div {
      p {
        font-size: 0.9rem;
      }

      svg {
        color: #a8a8a8;
        font-size: 1.1rem;
      }
    }
  }

  @media (max-height: 768px) {
    gap: 0.3rem;

    div {
      p {
        font-size: 0.9rem;
      }

      svg {
        font-size: 0.9rem;
      }
    }
  }
`;

const WrapperButtons = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 1400px) {
    margin-top: 0;
    gap: 0.3rem;
  }

  @media (max-height: 768px) {
    button {
      font-size: 0.8rem;
    }
  }
`;

const Title = styled.div`
  height: 50px;
  overflow-y: auto;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;

  ::-webkit-scrollbar {
    width: 1px;
  }

  @media (max-height: 768px) {
    font-size: 0.9rem;
  }
`;

import React from "react";
import styled from "styled-components";

import { Button, IconButton, Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import ProgramsModalMain from "../../ui/organisms/my-progress/ProgramsModalMain";

const ProgramsModal = (props) => {
  const { isOpen, handleCloseModal, program } = props;

  // RETURN
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <ProgramsModalContainer>
        <Header>
          <TitleHeader>{program.program.toLowerCase()}</TitleHeader>
          <IconButton onClick={handleCloseModal} size="large">
            <CloseRounded color="secondary" fontSize="small" />
          </IconButton>
        </Header>
        <Body>
          <ProgramsModalMain program={program} />
          <ButtonWrapper>
            {/*<ExportButton variant="contained" color="primary">
                            Exportar a PDF
    </ExportButton>*/}
            <CloseButton
              variant="outlined"
              color="primary"
              onClick={handleCloseModal}
            >
              Cerrar
            </CloseButton>
          </ButtonWrapper>
        </Body>
      </ProgramsModalContainer>
    </Modal>
  );
};

export default ProgramsModal;

const ProgramsModalContainer = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: max-content;
  width: 80vw;
  height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: #b7b7b7;
  border-bottom: 1px solid #eeeeee;

  @media (max-width: 768px) {
    background-color: #591AB6;
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;

const TitleHeader = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: capitalize;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  height: 100%;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 0.5rem;
`;

/*const ExportButton = styled(Button)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;*/

const CloseButton = styled(Button)`
  @media (max-width: 768px) {
    display: none;
  }
`;

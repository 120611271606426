import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import GridMyProgress from "../../common/GridMyProgress";
import { authActions, coursesActions } from "../../../redux/actions";
import { checkRoles } from "../../../helpers/roles.helper";
import AlertDialog from "../../common/AlertDialog";
import { useAlertDialog } from "../../../hooks/useAlertDialog";
import CardCourse from "../../ui/molecules/campus/CardCourse";
import TabsComponent from "../../common/TabsComponent";
import { Card } from "@mui/material";
import Registrations from "../subject/Registrations";

const Courses = (props) => {
  const { tabsFake } = props;
  // REDUX
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses.courses);
  const listLoading = useSelector((state) => state.courses.listLoading);
  const errorList = useSelector((state) => state.courses.errorList);
  const loadMoreLoading = useSelector((state) => state.courses.loadMoreLoading);
  const hasMore = useSelector((state) => state.courses.hasMore);
  const errorEnroll = useSelector((state) => state.courses.errorEnroll);
  const faActionUrl =
    `${process.env.REACT_APP_FA_ACTION}` ||
    "https://fa.aden.org/oauth2/authorize";
  const userStore = useSelector((state) => state.auth.user);
  const { canvas_login } = useSelector((state) => state.auth);

  const tabs = [
    { label: "Asignaturas Canvas", id: 0 },
    // { label: "Asignaturas Acrópolis", id: 1 },
  ];

  //STATE
  const [currentState, setCurrentState] = useState("Todos");

  const [alertDialog, closeAlertDialog, openAlertDialog] = useAlertDialog();
  const [tab, setTab] = useState(0);

  // EFFECTS
  // useEffect(() => {
  //   loginFirstInsructure();
  // }, []);
  useEffect(() => {
    if (courses === null && !listLoading) {
      dispatch(
        coursesActions.getCoursesRequest({
          size: "12",
          offset: "0",
        })
      );
    }
    if (errorEnroll === "flag") {
      dispatch(
        coursesActions.getCoursesRequest({
          size: "12",
          offset: "0",
        })
      );
    }

    if (errorEnroll !== null && errorEnroll !== "flag") {
      if (errorEnroll.message.includes("418")) {
        openAlertDialog(
          "¡Ups!",
          "Has alcanzado el límite permitido de cursos en progreso. Por favor, toma contacto con tu Asesor Académico.",
          "Ok"
        );
      } else if (errorEnroll.message.includes("400")) {
        openAlertDialog(
          "¡Ups!",
          "No has entregado la documentación correspondiente. Por favor, toma contacto con tu Asesor Académico.",
          "Ok"
        );
      } else {
        openAlertDialog(
          "¡Ups!",
          "Ocurrió un error al inscribirte en este curso. Por favor intenta nuevamente más tarde o refresca la página.",
          "Ok"
        );
      }
    }
  }, [errorEnroll]);

  // FUNCTIONS
  const renderCase = (type) => {
    const caseType = {
      0: (
        <GridMyProgress
          propsValues={courses}
          Card={CardCourse}
          loadMore={loadMore}
          hasMore={hasMore}
          currentState={currentState}
          handleCurrentState={handleCurrentState}
          gridFor="courses"
          errorList={errorList}
          handleTryAgain={handleTryAgain}
        />
      ),
      1: <Registrations tabsFake={tabsFake} />,
    };

    return caseType[type];
  };

  const handleTab = (_, value) => {
    setTab(value);
  };

  function loadMore() {
    if (loadMoreLoading === false && courses !== null) {
      dispatch(
        coursesActions.getMoreCoursesRequest({
          size: "12",
          offset: courses.length,
        })
      );
    }
  }

  function handleCurrentState(newValue) {
    setCurrentState(newValue);
  }

  function handleTryAgain() {
    dispatch(
      coursesActions.getCoursesRequest({
        size: "12",
        offset: "0",
      })
    );
  }

  function loginFirstInsructure() {
    const form = document.getElementById("formlogin");

    if (!canvas_login && !!form) {
      dispatch(authActions.canvasLoginSuccess());
      setTimeout(() => {
        form.submit();
      }, 2000);
    }
  }

  if (hasMore) {
    loadMore();
  }

  // RETURN
  if (checkRoles("visita")) {
    return (
      <VisitaWrapper>
        <TextVisita
          target="_blank"
          href="https://www.aden.org/nuestros-programas/"
          rel="noopener noreferrer"
        >
          Ir a Oferta educativa
        </TextVisita>
      </VisitaWrapper>
    );
  }

  return (
    <CoursesContainer>
      <Container>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
        <TabCase>{renderCase(tab)}</TabCase>
      </Container>

      <AlertDialog
        titulo={alertDialog.titulo}
        mensaje={alertDialog.mensaje}
        accion={alertDialog.accion}
        open={alertDialog.open}
        onClose={closeAlertDialog}
      ></AlertDialog>
      {/* <form id="formlogin" onSubmit={""} action={faActionUrl} method="post">
        <input
          type="hidden"
          name="client_id"
          value="2c54fc21-43b7-4c96-ba29-a4a66fd54342"
        />
        <input
          type="hidden"
          name="redirect_uri"
          value={`${window.location.origin}/mi-progreso`}
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="state"
          value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI"
        />
        <input
          type="hidden"
          name="timezone"
          value="America/Argentina/Buenos_Aires"
        />
        <input type="hidden" name="loginId" value={userStore.personal_email} />
        <input type="hidden" name="password" value={userStore.password} />
      </form> */}
    </CoursesContainer>
  );
};

export default Courses;

const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
`;

const VisitaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6rem 0;
`;

const TextVisita = styled.a`
  color: #591AB6;
  text-decoration: underline;
  font-size: 1.5rem;
  font-weight: 700;
`;

const Container = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const TabCase = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

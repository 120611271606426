import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LaptopIcon from "@mui/icons-material/Laptop";
import PriceIcon from "@mui/icons-material/AttachMoney";
import EventIcon from "@mui/icons-material/Event";
import ScheduleInCompany from "@mui/icons-material/Schedule";
import { getInfoEvents } from "../../../../redux/api/events/events";
import SimpleLoading from "../../../common/SimpleLoading";
import parse from "html-react-parser";

const InfoEventModal = (props) => {
  const { event } = props;

  const {
    publication_name,
    hour_begin,
    crm_id,
    day,
    month,
    type,
    description,
  } = event;

  // STATE
  const [infoEvent, setInfoEvent] = useState(null);
  const [speakers, setSpeakers] = useState(null);

  useEffect(() => {
    if (infoEvent === null) {
      getInfoEvent();
    }
  }, []);

  useEffect(() => {
    if (infoEvent !== null) {
      if (!!infoEvent.speakers_json) {
        if (speakers === null) {
          setSpeakers(JSON.parse(infoEvent.speakers_json));
        }
      }
    }
  }, [infoEvent]);

  //FUNCTIONS
  const getInfoEvent = async () => {
    const response = await getInfoEvents(crm_id);
    if (response.error) {
      // alert("hay un error en la api");
      setInfoEvent([]);
    } else {
      setInfoEvent(response);
    }
  };

  const getHourNumber = (hour) => {
    return parseInt(hour.substring(0, 2));
  };

  const getMinuteNumber = (hour) => {
    return hour.substring(3, 5);
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  return (
    <Container>
      <Header img_url={event.image_url}>
        <WrapperChip>
          <Chips>
            <LaptopIcon />
            {type === "PRESENCIAL" ? "Taller Presencial" : "Foro Virtual"}
          </Chips>
          <Chips>
            <PriceIcon />
            Sin cargo
          </Chips>
        </WrapperChip>
      </Header>
      <Body>
        {infoEvent === null ? (
          <WrapperBody>
            <SimpleLoading />
          </WrapperBody>
        ) : (
          <WrapperBody>
            <Title>{publication_name}</Title>
            <WrapperDate>
              <div>
                <EventIcon />
                <div>
                  <span>{day}</span>
                  {month}
                </div>
              </div>
              <div>
                <ScheduleInCompany />
                <div>
                  <span>hora</span>
                  {hour_begin}
                  {" (AR) |"}{" "}
                  {getHourNumber(hour_begin) -
                    2 +
                    ":" +
                    getMinuteNumber(hour_begin)}{" "}
                  {" (PA) |"}{" "}
                  {getHourNumber(hour_begin) -
                    3 +
                    ":" +
                    getMinuteNumber(hour_begin)}{" "}
                  {" (CR) "}
                </div>
              </div>
            </WrapperDate>
            {infoEvent.length !== 0 ? (
              <>
                <DescriptionWrapper>
                  <p>{infoEvent.description}</p>
                </DescriptionWrapper>

                <Footer>
                  {speakers && (
                    <>
                      <h3>Disertantes</h3>
                      {speakers.map((speaker) => (
                        <p>
                          {speaker.name}, {speaker.description}
                        </p>
                      ))}
                    </>
                  )}
                </Footer>
              </>
            ) : description !== "" ? (
              <DescriptionWrapper>
                <p>{parse(sanitizerHtml(description))}</p>
              </DescriptionWrapper>
            ) : (
              <h2>No hay información del evento</h2>
            )}
          </WrapperBody>
        )}
      </Body>
    </Container>
  );
};

export default InfoEventModal;

const Container = styled.div`
  height: 90vh;
  max-height: 680px;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 220px;
  background-image: url("./assets/aden/aden-live.png");
  background-image: url(${(p) => p.img_url});
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 20px;
`;

const WrapperChip = styled.div`
  position: absolute;
  display: flex;
  gap: 0.8rem;
  bottom: 20px;
  left: 20px;
`;

const Chips = styled.div`
  color: white;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  background-color: white;
  color: #591AB6;
  border-radius: 20px;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;

  svg {
    font-size: 1.2rem;
  }
`;

const Body = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
`;

const WrapperBody = styled.div`
  width: 50%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(100% - 245px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1350px) {
    width: calc(100% - 3rem);
  }
`;

const Title = styled.h3`
  width: 100%;
  color: #222222;
  font-size: 1.4rem;
  font-weight: bold;
`;

const WrapperDate = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
  div {
    display: flex;
    gap: 0.5rem;
    color: #a8a8a8;
    font-size: 0.9rem;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      align-items: flex-start;
      color: #616161;

      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.7rem;
      }
    }
    svg {
      font-size: 1.6rem;
    }
  }
`;

const DescriptionWrapper = styled.div`
  p {
    font-size: 1rem;
    line-height: 30px;
  }
`;

const Footer = styled.div`
  h3 {
    color: #591AB6;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 30px;
  }
`;

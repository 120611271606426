import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Material UI
import {
	/* ReportProblemRounded, */
	ThumbDownRounded,
	ThumbUpRounded,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { utilsActions } from "../../../../redux/actions";

// Context
import CourseContext from "../../context/CourseContext"

const NavegationBottom = (props) => {
	/* const {
		handleOpenModal
	} = props; */

	const {
		currentMedia: {
			id,
			formato: format,
			id_recurso,
			visto = false
		},
		currentPosition,
		menuItems,
		nextItem,
		backItem,
		blockResocurce
	} = useContext(CourseContext)

	const idCurrentMedia = format === "evaluacion" || format === "presentacion" ? id_recurso : id

	const dispatch = useDispatch();
	const likes = useSelector((state) => state.utils.likes);
	const dislikes = useSelector((state) => state.utils.dislikes);
	const likeType = useSelector((state) => state.utils.likeType);
	const calificacion = useSelector((state) => state.utils.calificacion);

	// STATE
	const [likeDislike, setLikeDislike] = useState(null);
	const [current, setCurrent] = useState(0);

	// EFFECTS
	useEffect(() => {
		dispatch(
			utilsActions.getCalificacionRequest({
				type: "recurso",
				id: idCurrentMedia,
			})
		);
		dispatch(
			utilsActions.getLikesRequest({
				type: "recurso",
				id: idCurrentMedia,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idCurrentMedia]);

	useEffect(() => {
		if (calificacion === "10") {
			setLikeDislike(true);
		} else if (calificacion === "1") {
			setLikeDislike(false);
		} else {
			setLikeDislike(null);
		}
	}, [calificacion, idCurrentMedia]);

	useEffect(() => {
		function beforeUnloadFn() {
			if (likeType !== null) {
				dispatch(
					utilsActions.resourceRequestLikes({
						id: idCurrentMedia,
						type: likeType,
					})
				);
			}
		}
		window.addEventListener("beforeunload", beforeUnloadFn);
		return () => {
			beforeUnloadFn();
			window.removeEventListener("beforeunload", beforeUnloadFn);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getPosition(currentPosition, menuItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPosition]);

	// FUNCTIONS
	function handleLike() {
		setLikeDislike(true);
		dispatch(utilsActions.setLike("like"));
	}

	function handleDislike() {
		setLikeDislike(false);
		dispatch(utilsActions.setDislike("dislike"));
	}

	const getPosition = (current, array) => {
		let result = 0;
		array.forEach((item, i) => {
			if (item.nombre_publicacion === current.nombre_publicacion) {
				result = i;
			}
		});
		setCurrent(result);
	};

	return (
		<NavegationBottomContainer format={format}>
			<ButtonsWrapper>
				<ButtonOption
					disabled={current === 0 || blockResocurce === true}
					onClick={backItem}
					startIcon={<ArrowBackIcon />}
				>
					Anterior
				</ButtonOption>
			</ButtonsWrapper>
			<ButtonAndRating>
				{calificacion && (
					<RatingWrapper>
						<FakeButton
							onClick={handleLike}
							disabled={likeDislike === true}
							selected={likeDislike === true}
						>
							<ThumbUpRounded color="secondary" />
							<span>{likes}</span>
						</FakeButton>
						<FakeButton
							onClick={handleDislike}
							disabled={likeDislike === false}
							selected={likeDislike === false}
						>
							<ThumbDownRounded color="secondary" />
							<span>{dislikes}</span>
						</FakeButton>
					</RatingWrapper>
				)}{/* 
				<FakeButton
					variant="outlined"
					style={{ color: "var(--primary-one)" }}
					onClick={handleOpenModal}
				>
					<ReportProblemRounded />
					<span>Reportar</span>
				</FakeButton> */}
			</ButtonAndRating>
			<ButtonsWrapper>
				<ButtonOption
					disabled={current === menuItems.length - 1 || blockResocurce === true || !visto}
					onClick={nextItem}
					endIcon={<ArrowForwardIcon />}
				>
					Siguiente
				</ButtonOption>
			</ButtonsWrapper>
		</NavegationBottomContainer>
	);
};

export default NavegationBottom;

const NavegationBottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	height: 64px;
	background-color: #ffffff;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	width: calc(100% - 2rem);
	border-radius: 20px;

	@media (max-width: 768px) {
		flex-direction: row;
		
	}
`;

const ButtonAndRating = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 2rem;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const RatingWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

const FakeButton = styled.button`
	border: none;
	outline: none;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.1rem;
	cursor: pointer;

	span {
		font-size: 0.75rem;
	}

	svg,
	span {
		color: ${(props) => (props.selected ? "var(--primary-one)" : "#bfbfbf")};
	}

	:hover {
		svg {
		color: var(--primary-one);
		}
		span {
		color: var(--primary-one);
		}
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;

	@media (max-width: 768px) {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const ButtonOption = styled(Button)`
	color: var(--secondary-four);
	border-radius: 10px;

	:hover { 
		background-color: var(--secondary-two);
		color: var(--primary-one);
		span > svg {
		color: var(--primary-one);
		}
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Button, CircularProgress } from "@mui/material";
import FirstForm from "./forms/FirstForm";
import SecondForm from "./forms/SecondForm";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/actions";

const ModalForm = (props) => {
  const { closeModal, motivations, userMotivations, interests, userInterests } =
    props;

  // REDUX
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  // STATE
  const [currentStep, setCurrentStep] = useState(1);

  // STATE INICIAL
  const initialValues = {
    motivaciones: userMotivations,
    intereses: userInterests,
  };

  function handleNextStep() {
    setCurrentStep(2);
  }

  function handleBackStep() {
    setCurrentStep(1);
  }
  function handleSubmit(values) {
    dispatch(authActions.updateUserRequest(values));
  }

  // RETURN
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Container>
            {/* Indicador */}
            <Indicator>
              <span>{currentStep}</span>
            </Indicator>
            {/** Formularios */}
            {currentStep === 1 ? (
              <FirstForm
                motivations={motivations}
                values={values}
                setFieldValue={setFieldValue}
              />
            ) : (
              <SecondForm
                interests={interests}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
            {currentStep === 1 ? (
              <WrapperButtons>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={closeModal}
                >
                  Lo haré más tarde
                </Button>
                <ButtonFake
                  color="primary"
                  variant="contained"
                  onClick={handleNextStep}
                  type="button"
                  disabled={values.motivaciones.length > 0 ? false : true}
                  value="Siguiente"
                />
              </WrapperButtons>
            ) : (
              <WrapperButtons>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleBackStep}
                >
                  Volver
                </Button>
                {loading ? (
                  <WrapperLoading>
                    <CircularProgress color="primary" size={32} />
                  </WrapperLoading>
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={values.intereses.length > 0 ? false : true}
                  >
                    Guardar mi elección
                  </Button>
                )}
              </WrapperButtons>
            )}
          </Container>
        )}
      </Formik>
    </>
  );
};

export default ModalForm;

const Container = styled(Form)`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem;
  border-radius: 20px;
  width: 800px;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0rem;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const Indicator = styled.div`
  width: 48px;
  height: 48px;
  background-color: #591AB6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  span {
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 468px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

const ButtonFake = styled.input`
  border: none;
  height: 40px;
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  width: fit-content;
  justify-content: center;
  background-color: #591AB6;
  align-items: center;
  transition: all 0.2s ease;
  &:hover {
    background-color: #890700;
  }
  :disabled {
    background-color: #aaaaaa;
    cursor: default;
  }
`;

const WrapperLoading = styled(CircularProgress)`
  padding: 0rem 4.5rem;
`;

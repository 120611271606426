import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import NoResultsFound from "./NoResultsFound";
import SimpleLoading from "./SimpleLoading";

//Initial State "Order"
const orderFilter = ["date", "az", "za"];
export const randomOrder = () => {
  const number = Math.floor(Math.random() * orderFilter.length);
  return orderFilter[number];
};

const GridResource = (props) => {
  const {
    Card,
    propsValues,
    handleOpenModal,
    type = false,
    gtc,
    handleRemoveItem,
    idCourse,
    loading,
    currentTab,
    setFlagForEvents,
  } = props;

  // STATE
  const [values, setValues] = useState(propsValues);
  const [valuesFake, setValuesFake] = useState(values);
  const [search, setSearch] = useState("");
  const [changeState, setChangeState] = useState(false);
  const [order, setOrder] = useState(
    type === "subject" ? "" : randomOrder(orderFilter)
  );

  useEffect(() => {
    if (type === "subject") {
      handleOnStudy();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setChangeState(false);
    }, 200);
  }, [changeState]);

  // EFFECTS
  useEffect(() => {
    if (type === "micro" || type === "workshop" || type === "subject") {
      switch (order) {
        case "date":
          sortByDate();
          break;

        case "az":
          sortByNameAZ();
          break;

        case "za":
          sortByNameZA();
          break;

        default:
          sortByDate();
          break;
      }
    }
  }, [order]);

  // FUNCTIONS
  function handleChange(e) {
    setSearch(e.target.value);
    setValuesFake(
      values.filter((propsValue) => {
        let verify = propsValue.publication_name
          ? propsValue.publication_name
          : "";
        // propsValue.publication_name === undefined
        //     ? propsValue.published_name
        //     : propsValue.publication_name;
        let verifyName = propsValue.name ? propsValue.name : "";
        let verifyTags = propsValue.tags ? propsValue.tags.join() : "";
        return (
          verify.toLowerCase().includes(e.target.value.toLowerCase()) ||
          verifyTags.toLowerCase().includes(e.target.value.toLowerCase()) ||
          verifyName.toLowerCase().includes(e.target.value.toLowerCase())
        );
      })
    );
  }

  // ORDER BY DATE

  function sortByDate() {
    setValuesFake(
      values.sort((a, b) => new Date(b.fecha_desde) - new Date(a.fecha_desde))
    );
    setChangeState(true);
  }

  function sortByNameAZ() {
    setValuesFake(
      values.sort((a, b) =>
        a.publication_name
          .toLowerCase()
          .localeCompare(b.publication_name.toLowerCase())
      )
    );
    setChangeState(true);
  }

  function sortByNameZA() {
    setValuesFake(
      values.sort((a, b) =>
        b.publication_name
          .toLowerCase()
          .localeCompare(a.publication_name.toLowerCase())
      )
    );
    setChangeState(true);
  }

  const handleOnStudy = () => {
    let fakeValue = valuesFake;

    for (let index = 0; index < fakeValue.length; index++) {
      if (index === 0) {
        fakeValue[index].onStudy = true;
      } else {
        if (fakeValue[index - 1].progress >= 100) {
          fakeValue[index].onStudy = true;
        } else {
          fakeValue[index].onStudy = false;
        }
      }
    }
    setValues(fakeValue);
    setValuesFake(fakeValue);
  };

  // RETURN

  if (!!loading) {
    return <SimpleLoading />;
  }

  return (
    <>
      <Filters>
        <SearchAndOrder>
          <TextField
            value={search}
            onChange={handleChange}
            size="small"
            label=""
            placeholder="Buscar..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
          />
        </SearchAndOrder>
      </Filters>
      {values.length === 0 ? (
        <NoResultsFound />
      ) : (
        <>
          {changeState ? (
            <SimpleLoadingContainer>
              <SimpleLoading />
            </SimpleLoadingContainer>
          ) : (
            <>
              <GridValues gtc={gtc}>
                {type === "subject" ? (
                  <>
                    {valuesFake
                      .sort((a, b) => a.order - b.order)
                      .map((value, index) => (
                        <Card
                          value={value}
                          contact={value}
                          key={index}
                          handleOpenModal={handleOpenModal}
                          handleRemoveItem={handleRemoveItem}
                          idCourse={idCourse}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {valuesFake.map((value, index) => (
                      <Card
                        value={value}
                        contact={value}
                        setFlagForEvents={setFlagForEvents}
                        key={index}
                        currentTab={currentTab}
                        handleOpenModal={handleOpenModal}
                        handleRemoveItem={handleRemoveItem}
                      />
                    ))}
                  </>
                )}
              </GridValues>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GridResource;

const Filters = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  .MuiSelect-select.MuiSelect-select {
    padding: 0 5rem 0.5rem 0;
    vertical-align: top;
  }
`;

const SimpleLoadingContainer = styled.div`
  height: 42vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GridValues = styled.div`
  min-height: 42vh;
  margin-top: 2rem;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: ${(p) =>
    p.gtc ? p.gtc : "repeat(auto-fill, minmax(300px, 1fr))"};
  grid-gap: 2rem;

  @media screen and (max-width: 1400px) {
    grid-template-columns: ${(p) =>
    p.gtc ? p.gtc : "repeat(auto-fill, minmax(300px, 1fr))"};
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`;
const SearchAndOrder = styled.div`
  display: flex;
  justify-content: space-between;
`;

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import { titleHTML } from "../../helpers/title.helper";
import * as encode from "nodejs-base64-encode";
import { useParams } from "react-router-dom";

const Challege = () => {
  titleHTML("Challenge");

  const { id } = useParams();

  const urlDecode = encode.decode(id + "", "base64");

  const ondemand = useSelector((state) => state.ondemand.ondemand);
  const urlChallengeGroup = useSelector(
    (state) => state?.ondemand?.url_desafio
  );

  if (ondemand === null) {
    return <SimpleLoading />;
  }

  return (
    <Container>
      <CustomIframe
        src={id ? urlDecode : urlChallengeGroup || ondemand?.challenge_url}
        frameborder="0"
        allow="camera; microphone"
      />
    </Container>
  );
};

export default Challege;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 2rem);
`;

const CustomIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
`;

import axios from "axios";

export function getPaginate(payload) {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/directories/paginate`;
  const pagination = axios.post(URL, payload);

  return pagination;
}
export function getDirectories(payload) {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/directories?page_offset=${payload.page_offset}&page_size=${payload.page_size}`;
  const directories = axios.post(URL, payload);

  return directories;
}

export function getPaginateAlab(payload) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/paginate/${payload.id}`;
  const pagination = axios.post(URL, payload.query);

  return pagination;
}

export function getDirectoriesAlab(payload) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/directories/${payload.id}?offset=${payload.query.page_offset}&limit=${payload.query.page_size}`;
  const directories = axios.post(URL, payload.query);

  return directories;
}

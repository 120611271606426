import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";

function CircularProgressWithLabel(props) {
  return (
    <>
      {props.variant === "linear" ? (
        <WrapperLinear scale={props.scale}>
          <Linear
            value={props.value}
            color={props.value >= 70 ? "#79d49a" : "#a64937"}
          ></Linear>
          <Typography
            variant="body2"
            color={props.value >= 70 ? "#79d49a" : "#a64937"}
          >{`${Math.round(props.value)}%`}</Typography>
        </WrapperLinear>
      ) : (
        <Box position="relative" display="inline-flex">
          <CircularProgressFake
            size={props.size ? props.size : 80}
            variantColor={props.variantColor}
            {...props}
          />
          <Box
            top={-20}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TypographyFake
              variant="caption"
              component="div"
              color={props.variantColor}
              fontSize={"64px"}
            >{Math.round(props.value)}<b>%</b></TypographyFake>
          </Box>
          <Box
            top={70}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TypographyFake
              variant="caption"
              component="div"
              color={"var(--primary-three)"}
              fontSize={"14px"}
              fontWeight={"600"}
            >Mi progreso</TypographyFake>
          </Box>
        </Box>
      )}
    </>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function ProgressBanner(props) {
  const { progress, size, fontSize, variant, scale, variantColor } = props;

  return (
    <CircularProgressWithLabel
      value={progress}
      size={size}
      fontSize={fontSize}
      variant={variant}
      scale={scale}
      variantColor={variantColor}
    />
  );
}

const TypographyFake = styled(Typography)`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "1.2rem")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "bold")};
  color: ${(p) => p.color};
  text-align: center;
  vertical-align: middle;

  b {
    font-size: 40px;
    font-weight: 500;
  }
`;

const CircularProgressFake = styled(CircularProgress)`
  color: ${(props) => props.variantColor};
  background-color: #f5f5f5;
  border-radius: 50%;
`;

const WrapperLinear = styled.div`
  width: calc(100% -2rem);
  padding: 1rem;
  transform: ${(p) => (p.scale ? `scale(${p.scale}%)` : "scale(100%)")};
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: 4fr 1fr;
`;

const Linear = styled.div`
  width: 100%;
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: all 0.2s ease;
  position: relative;

  :before {
    transition: all 0.2s ease;
    position: absolute;
    content: "";
    top: 0;
    border-radius: 4px;
    left: 0;
    height: 100%;
    width: ${(p) => `${p.value}%`};
    background-color: ${(p) => p.color};
  }
`;

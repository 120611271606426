import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BannerReferred from "../../components/ui/molecules/referred/BannerReferred";
import MyCardReferred from "../../components/ui/molecules/referred/MyCardReferred";
import PanelReferred from "../../components/ui/molecules/referred/PanelReferred";
import { getMyReferred } from "../../redux/api/referred";

const MyReferrals = () => {
  const user = useSelector((state) => state.auth.user);
  const [myReferred, setMyReferred] = useState(null);

  useEffect(() => {
    myReferred === null && handleReferred();
  }, []);

  // FUNCTIONS
  const handleReferred = async () => {
    const response = await getMyReferred();

    !response.error ? setMyReferred(response) : setMyReferred([]);
  };

  return (
    <Container>
      <MyCardReferred user={user} infoReferred={myReferred} />
      <BannerReferred />
      <PanelReferred myReferred={myReferred} />
    </Container>
  );
};

export default MyReferrals;

const Container = styled.div`
  display: grid;
  grid-template-areas:
    "userCard  banner banner"
    "panel panel panel"
    "panel panel panel"
    "panel panel panel";
  gap: 2rem;
  width: 100%;

  @media (max-width: 1160px) {
    grid-template-areas:
      "banner"
      "userCard"
      "panel"
      "panel";
  }
`;

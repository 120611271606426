import React from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircle from "@mui/icons-material/PlayCircleFilled";
import MenuBook from "@mui/icons-material/MenuBook";
import LiveHelp from "@mui/icons-material/LiveHelp";
import ContentVideos from "./program/ContentVideos";
import ContentReadings from "./program/ContentReadings";
// import ContentExams from './program/ContentExams';

const ProgramProduct = (props) => {
  const { program } = props;
  return (
    <Container>
      {program.map((programs) => {
        return (
          <AccordionContainer>
            <AccordionSumm
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls={`panel${programs.unit_id}a-content`}
              id={`panel${programs.unit_id}a-header`}
            >
              <BodyAccordionSummary>
                <Unit>Unidad {programs.unit_id}</Unit>
                <Title>{programs.unit_name}</Title>
                <Description>
                  {programs.videos.length} videos, {programs.readings.length}{" "}
                  lecturas, {programs?.exams?.length} examen
                </Description>
              </BodyAccordionSummary>
            </AccordionSumm>
            {/* <AccordionDetails>
                            <Typography>
                                <div>
                                    <BoxTitle>
                                        <PlayCircle color="primary"></PlayCircle>
                                        <TitleContent color="primary">
                                            Videos
                                        </TitleContent>
                                    </BoxTitle>
                                    <ContentVideos videos={programs.videos} />
                                </div>
                                <div>
                                    <BoxTitle>
                                        <MenuBook color="primary"></MenuBook>
                                        <TitleContent color="primary">
                                            Lecturas
                                        </TitleContent>
                                    </BoxTitle>
                                    <ContentReadings
                                        readings={programs.readings}
                                    />
                                </div>
                                <div>
                                    <BoxTitle>
                                        <LiveHelp color="primary"></LiveHelp>
                                        <TitleContent color="primary">
                                            exámenes
                                        </TitleContent>
                                    </BoxTitle>
                                    <ContentExams exams={programs?.exams} />
                                </div>
                            </Typography>
                        </AccordionDetails> */}
          </AccordionContainer>
        );
      })}
    </Container>
  );
};

export default ProgramProduct;
const Container = styled.div`
  margin: 1rem;
`;

const Title = styled(Typography)`
  color: #222222;
  font-weight: bold;
`;
const AccordionContainer = styled(Accordion)`
  margin-bottom: 1rem;
  align-items: center;
  box-shadow: none;
  &::before {
    content: "";
    width: 0px;
    height: 0px;
  }
`;
const AccordionSumm = styled(AccordionSummary)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: none;
  border-radius: 6px;
`;
const BodyAccordionSummary = styled.div`
  width: 100%;
`;
const Unit = styled(Typography)`
  color: #591AB6;
  text-transform: uppercase;
  font-size: 0.8rem;
`;
const Description = styled(Typography)`
  font-size: 0.7rem;
`;
const TitleContent = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
`;
const BoxTitle = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  align-content: center;
`;

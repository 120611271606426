import React from "react";
import styled from "styled-components";

// Components
import QuestionReview from "./questions/QuestionReview";

// Material UI
import { Button } from "@mui/material";

const PageReview = (props) => {
  const { studentRes, evaluation, qualification, handleLastPage } = props;

  const getResponseQuestion = (question) => {
    let result = false;

    studentRes.respuestas.forEach((sQuestion) => {
      // eslint-disable-next-line eqeqeq
      if (question.pregunta_id == sQuestion.pregunta_id) {
        result = sQuestion;
      }
    });

    return result;
  };

  return (
    <WrapperExam>
      <NavExam>
        <QuestionsCount></QuestionsCount>
        <Time>
          {qualification.nota} / {qualification.total_puntaje} puntos
        </Time>
      </NavExam>
      <WrapperQuestions>
        {evaluation.preguntas.map((q, index) => {
          return (
            <QuestionReview
              type={q.tipo}
              question={q}
              resQuestion={getResponseQuestion(q)}
              number={index + 1}
            />
          );
        })}
        <ContainerButton>
          <Button onClick={handleLastPage} variant="contained" color="primary">
            Volver atras
          </Button>
        </ContainerButton>
      </WrapperQuestions>
    </WrapperExam>
  );
};

export default PageReview;

const WrapperExam = styled.div`
  width: 100%;
  height: auto;
`;

const NavExam = styled.nav`
  padding: 0 18px;
  width: calc(100% - 36px);
  height: 80px;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionsCount = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: center;
`;

const Time = styled.div`
  width: 10%;
  height: 100%;
  padding: 0 1rem;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #616161;
`;

const WrapperQuestions = styled.div`
  width: calc(100% - 30px);
  min-height: 450px;
  max-height: 600px;
  overflow-y: auto;
  padding: 15px;
`;

const ContainerButton = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Text } from "../../common/Texts";
import useTheme from "../../../hooks/useTheme";
import { useHistory } from "react-router-dom";

const DefaultNavegation = (props) => {
  const { title = "Título indefinido", course } = props;

  const { primary } = useTheme();
  const history = useHistory();

  const handleGoBack = () => history.goBack();

  return (
    <NavegationTeacherWrapper>
      <NavAction onClick={handleGoBack} color={primary}>
        <ArrowBackIcon />
        <Text fontSize="1.2rem" fontWeight="700">
          {course === true && (
            <Text fontSize="10px" fontWeight="400">
              Asignatura
              <br />
            </Text>
          )}
          {title}
        </Text>
      </NavAction>
    </NavegationTeacherWrapper>
  );
};

export default DefaultNavegation;

const NavegationTeacherWrapper = styled.div`
  position: fixed;
  padding: 0 35px;
  top: 70px;
  left: 70px;
  height: 70px;
  width: calc(100% - 140px);
  display: flex;
  align-items: center;
  z-index: 9;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(209, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(209, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(209, 209, 209, 0.75);

  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
`;

const NavAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  color: ${(p) => p.color};
  transition: all 0.3s ease-in-out;
  svg {
    font-size: 20px;
  }
  :hover {
    filter: opacity(0.8);
  }
`;

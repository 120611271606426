import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

const ModalTermsActiveAccount = (props) => {
    const { isOpen, handleCloseModalTerms } = props;
    return (
        <Modal open={isOpen} onClose={handleCloseModalTerms}>
            <Wrapper>
                <Header>
                    <h2>
                        AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Ley
                        1581 de 2012 / Decreto 1074 de 2015
                    </h2>
                    <CloseIconFake onClick={handleCloseModalTerms} />
                </Header>
                <Body>
                    <p>
                        En adición y complemento de las autorizaciones
                        previamente otorgadas, autorizo de manera expresa y
                        previa sin lugar a pagos ni retribuciones a la Sociedad
                        Administradora de Fondos de Pensiones y Cesantías
                        Porvenir S.A. (“PORVENIR”), a sus sucesores, cesionarios
                        a cualquier título o a quien represente los derechos,
                        para que efectúe el Tratamiento mis Datos Personales de
                        la manera y para las finalidades que se señalan a
                        continuación. Para efectos de la presente autorización,
                        se entiende por “Datos Personales” la información
                        personal que suministre por cualquier medio, incluyendo,
                        pero sin limitarse a, aquella de carácter financiero,
                        crediticio, comercial, profesional, sensible (tales como
                        mis huellas, imagen, voz, entre otros), técnico y
                        administrativo, privada, semiprivada o de cualquier
                        naturaleza, pasada, presente o futura, contenida en
                        cualquier medio físico, digital o electrónico, entre
                        otros y sin limitarse a documentos, fotos, memorias USB,
                        grabaciones, datos biométricos, correos electrónicos, y
                        videograbaciones...
                    </p>
                </Body>
            </Wrapper>
        </Modal>
    );
};

export default ModalTermsActiveAccount;

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    width: 615px;
    max-width: calc(90vw - 4rem);
    height: 60vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    background: #f9f9f9;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    padding: 2rem;
`;

const CloseIconFake = styled(CloseIcon)`
    cursor: pointer;
    color: #616161;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;

    h2 {
        color: #222;
        font-size: 1rem;
    }
`;

const Body = styled.div`
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    height: 100%;
    p {
        color: #616161;
        font-size: 1rem;
    }
`;

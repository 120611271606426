import { useEffect, useState } from "react";
import { getRecommendedCoursesByThematicAxis } from "../redux/api/courses";

const useRecommendedCourses = (id) => {
    // STATES

    // FUNCTIONS
    const getRecommendedCoursesByCategoryName = async (name) => {
        const req = await getRecommendedCoursesByThematicAxis(name);
        if (req.error) {
            return console.info("req.error", req.error);
        }
        return req;
    }

    return { getRecommendedCoursesByCategoryName };
};

export default useRecommendedCourses;

import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { searchReferedSchema } from "../../../utils/schemas";
import DefaultField from "../../common/DefaultField";
import { Img } from "../../common/Image";
import SimpleLoading from "../../common/SimpleLoading";
import { Text } from "../../common/Texts";

const InfoUser = (props) => {
  const { student, id, getData } = props;

  //STATES
  const [loading, setLoading] = useState(false);
  const [fakeId, setFakeId] = useState(id);
  const [fakeStudent, setFakeStudent] = useState(student);

  useEffect(() => {
    if (fakeStudent === null && student !== null) {
      setFakeStudent(student);
    }
  }, [student]);

  // FORMIK
  const initialValues = {
    name: "",
    lastName: "",
    country: 10,
    state: "",
    programs: "MBAeo1",
    email: "",
    phone: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    getData(values.email);
  };

  if (fakeStudent === null && fakeId !== undefined) {
    return (
      <CardWrapper>
        <SimpleLoading />
      </CardWrapper>
    );
  }

  return (
    <>
      {fakeId === undefined ? (
        fakeStudent === null ? (
          <CardWrapper>
            <Title>¡Ingresá tu Email para ser identificado!</Title>

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={searchReferedSchema}
            >
              {({ values, setFieldValue }) => (
                <FormContainer>
                  <DefaultField
                    name="email"
                    type="email"
                    variant="outlined"
                    label="Correo electrónico"
                    required
                  />

                  <ButtonWrapper>
                    {!loading ? (
                      <Button
                        type="submit"
                        color="primary"
                        fullWidth
                        variant="contained"
                      >
                        Buscar
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled
                        fullWidth
                      >
                        Cargando...
                      </Button>
                    )}
                  </ButtonWrapper>
                </FormContainer>
              )}
            </Formik>
          </CardWrapper>
        ) : (
          <CardWrapper>
            <ImageWrapper>
              <Img
                h="142px"
                src={
                  student === false
                    ? "/assets/no-photo.png"
                    : process.env.REACT_APP_CRM_PHOTO + student.partner_id
                }
              />
            </ImageWrapper>
            <Text color="#222222" fontWeight="900" fontSize="24px">
              {student.name || "Usuario no identificado"}
            </Text>
            <Text color="#616161" fontWeight="700" fontSize="14px">
              {student.personal_email}
            </Text>
            <Text color="#A8A8A8" fontWeight="400" fontSize="14px">
              LEGAJO: {student.partner_id || "No definido"}
            </Text>
            <ButtonWrapper>
              <Button color="primary" disabled variant="outlined">
                Gestionar mis referidos
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setFakeId(undefined);
                  setFakeStudent(null);
                  setLoading(false);
                }}
              >
                Volver a buscar
              </Button>
            </ButtonWrapper>
          </CardWrapper>
        )
      ) : (
        <CardWrapper>
          <ImageWrapper>
            <Img
              h="142px"
              src={
                student === false
                  ? "/assets/no-photo.png"
                  : process.env.REACT_APP_CRM_PHOTO + student.partner_id
              }
            />
          </ImageWrapper>
          <Text color="#222222" fontWeight="900" fontSize="24px">
            {student.name || "Usuario no identificado"}
          </Text>
          <Text color="#616161" fontWeight="700" fontSize="14px">
            {student.personal_email}
          </Text>
          <Text color="#A8A8A8" fontWeight="400" fontSize="14px">
            LEGAJO: {student.partner_id || "No definido"}
          </Text>
          <ButtonWrapper>
            <Button color="primary" disabled variant="outlined">
              Gestionar mis referidos
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setFakeId(undefined);
                setFakeStudent(null);
              }}
            >
              Volver a buscar
            </Button>
          </ButtonWrapper>
        </CardWrapper>
      )}
    </>
  );
};

export default InfoUser;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 369px;
  max-height: 369px;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  object-fit: cover;
  img {
    border-radius: 50%;
  }
`;

const Title = styled.h3`
  color: #222222;
  font-size: 1.4rem;
  text-align: center;
  width: 80%;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 25px;
  width: 80%;
`;

const ButtonWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

import * as types from "../types";

const initialState = {
  microlearnings: null,
  listLoading: false,
  errorList: null,
  currentMicrolearning: null,
};

export const microlearnings = (state = initialState, action) => {
  switch (action.type) {
    // GET MICROLEARNINGS
    case types.GET_MICROLEARNINGS_REQUEST:
      return {
        ...state,
        listLoading: true,
        errorList: null,
      };
    case types.GET_MICROLEARNINGS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        microlearnings: action.payload,
        errorList: null,
      };
    case types.GET_MICROLEARNINGS_FAIL:
      return {
        ...state,
        listLoading: false,
        errorList: action.payload,
      };
    // SET CURRENT MICROLEARNING
    case types.SET_CURRENT_MICROLEARNING:
      return {
        ...state,
        currentMicrolearning: action.payload,
      };
    case types.LOAD_FIRST_MICROLEARNING:
      return {
        ...state,
        currentMicrolearning: state.microlearnings.find(
          (x) => x.repo_id == action.payload.id
        ),
      };
    case types.SET_LIKE_MICROLEARNING:
      const microLike = state.microlearnings.map((microlearning) =>
        microlearning.repo_id !== action.payload
          ? microlearning
          : { ...microlearning, favorito: true }
      );
      return {
        ...state,
        microlearnings: microLike,
      };
    case types.SET_NOLIKE_MICROLEARNING:
      const microNoLike = state.microlearnings.map((microlearning) =>
        microlearning.repo_id !== action.payload
          ? microlearning
          : { ...microlearning, favorito: false }
      );
      return {
        ...state,
        microlearnings: microNoLike,
      };
    default:
      return state;
  }
};

import React from "react";
import styled from "styled-components";

import { Skeleton } from "@mui/material";

const SubjectsSkeleton = () => {
  const array = [0, 0, 0, 0];

  return (
    <Container>
      {array.map(() => (
        <CardSkeleton animation="wave" variant="rect">
          <Image />
          <Title />
          <Bar />
          <Body />
          <Button />
        </CardSkeleton>
      ))}
    </Container>
  );
};

export default SubjectsSkeleton;

const Container = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 530px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CardSkeleton = styled(Skeleton)`
  position: relative;
  padding: 1rem;
  width: calc(100% - 2rem);
  height: 450px;
`;

const Image = styled(Skeleton)`
  width: 100%;
  height: 150px;
  filter: opacity(2);
  position: absolute;
  top: -35px;
  left: 0;
`;

const Title = styled(Skeleton)`
  width: calc(100% - 2rem);
  height: 2rem;
  color: black;
  position: absolute;
  top: 100px;
`;

const Bar = styled(Skeleton)`
  width: calc(100% - 2rem);
  height: 1rem;
  color: green;
  position: absolute;
  top: 140px;
`;

const Body = styled(Skeleton)`
  width: calc(100% - 2rem);
  height: 200px;
  color: green;
  position: absolute;
  top: 120px;
`;

const Button = styled(Skeleton)`
  width: 120px;
  height: 60px;
  color: green;
  position: absolute;
  top: 310px;
  right: 1rem;
`;

import React, { useState/* , useMemo */ } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import ErrorBoundaryAlert from "../../common/ErrorBoundaryAlert";
import NavegationOndemand from "./NavegationOndemand";
import TicketPopUp from "../chat-layout/TicketPopUp";
import { BlockedAlert } from "../finantial-status/BlockedAlert";
/* import BannerServices from "../../common/BannerServices"; */
/* import Anniversary from "../../ui/shared/Anniversary"; */

// Hooks
import useTheme from "../../../hooks/useTheme";
/* import { useServices } from "../../../hooks/useServices"; */

// Utils
import ErrorBoundary from "../../utils/complete-profile/ErrorBoundary";

// Assets
import BackgroundHome from '../../../assets/media/aden/background-home.jpeg'
import BackgroundHomeMobile from '../../../assets/media/aden/background-course.png'
import BackgroundCourse from '../../../assets/media/aden/background-course.png'

const LayoutOndemand = (props) => {
  const { finantial_status } = props;

  const { primary } = useTheme();

  const history = useHistory();

  const location = history?.location.pathname.match(/\/([^/]*)\/?/);

  const appLocation = location ? location[1] : null;

  // Ancho de la pantalla
  const screenWidth = window.screen.width;

  const locationNone = ["asignatura", "biblioteca", "mi-empresa", "competencia", "favoritos", "mis-referidos", "perfil", "view"]

  const BackgroundSelected = () => {
    if (locationNone.includes(appLocation)) {
      return "none";
    } else if (appLocation === "curso") {
      return BackgroundCourse;
    } else {
      return screenWidth > 768 ? BackgroundHome : BackgroundHomeMobile;
    }
  };
  
  // STATE
  const [menu, setMenu] = useState(false);
  /* const [services] = useServices(); */

  // FUNCTIONS
  function toggleMenu() {
    setMenu(!menu);
  }

  function closeMenu() {
    setMenu(false);
  }

  // RETURN
  return (
    <Wrapper
    backgroundImage={BackgroundSelected}
    value={appLocation}
    screenWidth={screenWidth}
    >
      {/* {!!services && services.length > 0 && (
        <BannerServices
          absolut={false}
          title="¡Algunos de nuestros servicios están en mantenimiento! Puede que presente algun error el sitio."
        />
      )} */}
      <BlockedAlert
        show={
          finantial_status?.blocked === true ? finantial_status?.alert : false
        }
        blocked={finantial_status?.blocked}
      />
      <NavCointainer services={/* !!services && services.length ? true :  */false}>
        <NavegationOndemand
          menu={menu}
          closeMenu={closeMenu}
          toggleMenu={toggleMenu}
        />
      </NavCointainer>

      <Overlay menu={menu}></Overlay>
      <Footer>
        <Main menu={menu} bgColor={primary}>
          <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
            {props.children}
          </ErrorBoundary>
        </Main>
      </Footer>

      <TicketPopUp />

      {/* Aniversario 30 años */}
      {/* <Anniversary /> */}
    </Wrapper>
  );
};

export default LayoutOndemand;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-image: url(${(p) => p.backgroundImage});
  background-position: ${(props) => props.value === "curso" || (props.screenWidth < 768) ? "top center" : "50% 170%"};
  background-repeat: no-repeat;
  background-size: ${(props) => props.value === "curso" || (props.screenWidth < 768) ? "cover" : "100% 93%"};
`;

const NavCointainer = styled.div`
  position: fixed;
  z-index: 999;
  height: ${(props) => (props.bannerExists ? "calc(100vh - 70px)" : "100vh")};
  top: ${(props) => (props.bannerExists ? "70px" : "0")};
  height: "100vh";
  top: "0";

  @media screen and (max-width: 768px) {
    height: ${(props) =>
      props.bannerExists ? "calc(100vh - 110px)" : "100vh"};
    top: ${(props) => (props.bannerExists ? "110px" : "0")};
  }
`;

const Main = styled.main`
  padding: ${(props) =>
    props.bannerExists
      ? "calc(2rem + 140px) 2rem 2rem calc(2rem + 70px)"
      : "calc(2rem + 70px) 2rem 2rem calc(2rem + 70px)"};
  overflow: ${(props) => props.menu && "hidden"};
  height: 100%;
  @media (max-width: 1440px) {
    padding: ${(props) =>
      props.bannerExists
        ? "calc(2rem + 140px) 1rem 2rem calc(1rem + 70px)"
        : "calc(2rem + 70px) 4rem 2rem calc(4rem + 70px)"};
  }

  @media (max-width: 768px) {
    padding: ${(props) =>
      props.bannerExists
        ? "calc(2rem + 180px) 1rem 2rem 1rem"
        : "calc(2rem + 70px) 1rem 2rem 1rem"};
  }
`;

const Overlay = styled.div`
  display: ${(props) => (props.menu ? "flex" : "none")};
  visibility: ${(props) => (props.menu ? "visible" : "hidden")};
  opacity: ${(props) => (props.menu ? 1 : 0)};
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #00000050;
  z-index: 9;
`;

const Footer = styled.div`
  /* height: 100%; */
`;
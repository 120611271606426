import { useState } from "react";

const useCountryName = () => {
  // STATES

  // FUNCTIONS
  const findCountry = (dataCountry, country_fake, country_crm_id) => {
    const result = dataCountry;

    country_fake = result.find(function (e) {
      return e.id == country_crm_id;
    });
    return country_fake?.name;
  };

  return {
    findCountry,
  };
};

export default useCountryName;

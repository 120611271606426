import React from "react";
import styled from "styled-components";
import DefaultCard from "../../../common/DefaultCard";
import { Img } from "../../../common/Image";
import { Text } from "../../../common/Texts";
import contactSvg from "../../../../assets/media/aden/contact.svg";
import { Formik, Form } from "formik";
import DefaultField from "../../../common/DefaultField";
import DefaultPhone from "../../../common/DefaultPhone";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { decodeData } from "../../../../helpers/encrypt.helper";
import useEcommerce from "../../../../hooks/useEcommerce";
import DefaultSelect from "../../../common/DefaultSelect";
import { upsellingScheme } from "../../../../utils/schemas";
import { useState } from "react";
import { createOpportunity } from "../../../../redux/api/studyPlan";
import { options } from "../../../../utils/snackStyle";
import { useSnackbar } from "react-simple-snackbar";

const FormContact = (props) => {
  const { handleClose, productSelect } = props;

  const { partner_id, personal_email, phone } = useSelector(
    (state) => state.auth.user
  );
  const query = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar(options);

  const hashProgram = query.get("program") || "";
  const id = decodeData(hashProgram);

  const { product } = useEcommerce(id);

  const initialValues = {
    phone: phone,
    email: personal_email,
    product: productSelect,
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const body = {
        program_name: product?.upselling.find(
          (item) => item.id === values.product
        ).name,
        opp_id_prod_mkt: values.product,
        partner_id: partner_id,
        phone: values.phone,
      };
      await createOpportunity(body);
      openSnackbar("¡La consulta ha sido enviada con éxito!");
    } catch (error) {
      console.error(error);
      openSnackbar("¡Ocurrió un error!");
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleClose();
      }, 1500);
    }
  };

  return (
    <DefaultCard>
      <Header>
        <Img w="180px" src={contactSvg} alt="Asesor Aden" />
        <Text fontSize="18px" fontWeight="bold" color="#591AB6">
          ¡Un asesor se pondrá en contacto contigo!
        </Text>
      </Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={upsellingScheme}
      >
        {({ values, setFieldValue }) => (
          <FormContainer>
            {/* Correo */}
            <DefaultField
              name="email"
              disabled
              type="email"
              label="Email"
              variant="outlined"
              required
            />
            {/* Telefono */}
            <DefaultPhone
              name="phone"
              label="Teléfono"
              value={values.phone}
              setFieldValue={setFieldValue}
            />
            {/* Programa */}
            {product ? (
              <DefaultSelect
                name="product"
                label="Productos"
                variant="outlined"
                disabled
                options={product?.upselling || []}
              />
            ) : (
              <TextField disabled />
            )}
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              {loading ? "Cargando" : "Enviar"}
            </Button>
          </FormContainer>
        )}
      </Formik>
    </DefaultCard>
  );
};

export default FormContact;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled(Form)`
  margin: 16px 0;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

import React from "react";
import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import useTheme from "../../hooks/useTheme";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const TabsComponent = (props) => {
    const { tab, handleTab, tabs, variant } = props;

    const tabVariant = variant !== undefined ? variant : "scrollable";
    const { activeRol } = useSelector((state) => state.auth)
    const { primary } = useTheme();

    // RETURN
    return (
        <FakeAppBar position="static" activeRol={activeRol}>
            <TabsFake
                value={tab}
                onChange={handleTab}
                indicatorColor="var(--primary-one)"
                variant={tabVariant}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                color={primary}
            >
                {tabs?.map((item) => {
                    return (
                    <TabButton
                        label={item.label}
                        key={item.id}
                        {...a11yProps(item.id)}
                        value={item.id}
                        disabled={item.disabled}
                    />
                )})}
            </TabsFake>
        </FakeAppBar>
    );
};

export default TabsComponent;

const FakeAppBar = styled(AppBar)`
    background-color: transparent;
    box-shadow: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;

    &.Mui-selected {
        color: 'var(--primary-one)'
    }
`;

const TabButton = styled(Tab)`
    position: relative;
    width: 200px;
    height: 50px;
    color: var(--secondary-four);
`

const TabsFake = styled(Tabs)``;

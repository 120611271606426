import React, { useEffect } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IconButton } from "@mui/material";

const FilterNotification = (props) => {
  const { currentState, handleCurrentState, categories, setCategories } = props;

  useEffect(() => {
    if (categories[0].id !== 0) {
      setCategories([{ id: 0, name: "Todos" }, ...categories]);
    }
  }, [categories]);

  const leftScroll = () => {
    const scroll = document.getElementById("scroll-tags");
    scroll.scrollLeft = scroll.scrollLeft + -200;
  };

  const rightScroll = () => {
    const scroll = document.getElementById("scroll-tags");
    scroll.scrollLeft = scroll.scrollLeft + 200;
  };

  // RETURN
  return (
    <FilterWrapper>
      <Arrow>
        <IconButton color="primary" onClick={leftScroll} size="large">
          <ArrowBackIcon />
        </IconButton>
      </Arrow>
      <NotificationFiltersContainer id="scroll-tags">
        {categories.map((category) => (
          <SpanWrapper
            key={category.id}
            currentState={currentState === category.name}
            onClick={() => handleCurrentState(category.name)}
          >
            {category.name}
            <Span currentState={currentState === category.name}></Span>
          </SpanWrapper>
        ))}
      </NotificationFiltersContainer>
      <Arrow>
        <IconButton color="primary" onClick={rightScroll} size="large">
          <ArrowForwardIcon />
        </IconButton>
      </Arrow>
    </FilterWrapper>
  );
};

export default FilterNotification;

const FilterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
`;
const Arrow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NotificationFiltersContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  max-width: 100%;
  overflow-x: hidden;
  align-items: center;
  scroll-behavior: smooth;
`;

const SpanWrapper = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: ${(props) => (props.currentState ? "#591AB6" : "#bfbfbf")};
  background-color: ${(props) =>
    props.currentState ? "#ffffff" : "transparent"};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.currentState
      ? "0px 3px 5px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.06)"
      : "none"};

  :hover {
    color: #591AB6;
    background-color: ${(props) => (props.currentState ? "#fff" : "#FFF7F6")};
    font-weight: 700;
  }
`;

const Span = styled.span`
  font-size: 1rem;
  font-weight: ${(props) => (props.currentState ? "700" : "400")};
  text-transform: uppercase;
`;

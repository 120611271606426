import * as types from "../types";

const actions = {};

// GET Finances
actions.getFinancesRequest = () => {
  return {
    type: types.GET_FINANCES_REQUEST,
  };
};

actions.getFinancesSuccess = (documents) => {
  return {
    type: types.GET_FINANCES_SUCCESS,
    payload: documents,
  };
};

actions.getFinancesFail = (error) => {
  return {
    type: types.GET_FINANCES_FAIL,
    payload: error,
  };
};

export { actions };

import axios from "axios";

export async function getResourceById(id, type) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/${id}?type=${type}`
    );
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

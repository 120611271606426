import { Button, Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import activity from "../../../../assets/media/aden/scorm-act.svg";
import { Img } from "../../../common/Image";
import CloseIcon from "@mui/icons-material/Close";
import { encodeData } from "../../../../helpers/encrypt.helper";
import { useHistory } from "react-router-dom";

const DialogActivity = (props) => {
  const { course, subject } = props;
  const firstCourse = course;
  const { id } = firstCourse;

  const [open, setOpen] = useState(true);
  const { push } = useHistory();
  const hashId = encodeData(String(id));

  const onClose = () => setOpen(false);
  const handleOpenCourse = () => push(`../curso/${hashId}?origin=demo`);

  return (
    <Dialog onClose={onClose} open={open}>
      <Content>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Img w="230px" src={activity} />
        <Text fontSize="24px" fontWeight="bold">
          ¡Completa tu curso!
        </Text>
        <Text textAlign="center" color="#616161" fontSize="14px">
          A través de este programa, pudiste completar uno de los módulos del{" "}
          {subject?.name}. Completa la actividad para obtener tu certificado y
          un descuento exclusivo para continuar tu formación en ADEN
        </Text>
        <Button
          onClick={handleOpenCourse}
          fullWidth
          color="primary"
          variant="contained"
        >
          Ir a la actividad
        </Button>
      </Content>
    </Dialog>
  );
};

export default DialogActivity;

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

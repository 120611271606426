import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useChallenges from "../../hooks/challengs/useChallenges";
import SimpleLoading from "../../components/common/SimpleLoading";
import CardChallenges from "../../components/ui/organisms/challenges/CardChallenges";
import CategoriesChallenges from "../../components/ui/organisms/challenges/CategoriesChallenges";
import SearchChallenges from "../../components/ui/organisms/challenges/SearchChallenges";
import { ReactComponent as NotFoundSvg } from "../../assets/media/svg/404-Error-pana.svg";

const Challenges = () => {
    // HOOKS
    const { categories, allChallenges } = useChallenges();

    // STATES
    const [currentCategory, setCurrentCategory] = useState("Todos");
    const [currentChallenges, setCurrentChallenges] = useState(allChallenges);
    const [searchValue, setSearchValue] = useState("");

    // EFFECTS
    useEffect(() => {
        if (allChallenges !== null) {
            setCurrentChallenges(allChallenges);
        }
    }, [allChallenges]);

    useEffect(() => {
        if (currentCategory === "Todos") {
            setCurrentChallenges(allChallenges);
        } else {
            setCurrentChallenges(
                allChallenges.filter(
                    (values) => values.categoria === currentCategory
                )
            );
        }
    }, [currentCategory]);

    useEffect(() => {
        if (allChallenges !== null) {
            setCurrentChallenges(
                allChallenges.filter((values) =>
                    values.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                )
            );
        }
    }, [searchValue]);

    // FUNCTIONS
    const handleCategory = (value) => {
        setCurrentCategory(value);
    };

    const handleSearchValue = (e) => {
        setSearchValue(e);
    };

    if (currentChallenges === null || categories === null) {
        return (
            <Wrapper>
                <SimpleLoading />
            </Wrapper>
        );
    }

    return (
        <>
            <Wrapper>
                <SearchChallenges
                    searchValue={searchValue}
                    handleSearchValue={handleSearchValue}
                />
                {categories && (
                    <CategoriesChallenges
                        currentCategory={currentCategory}
                        categories={categories}
                        handleCategory={handleCategory}
                    />
                )}
                {!currentChallenges ? (
                    <MessageErrorRequest>
                        <NotFoundSvg width="50%" />
                        No se pudieron cargar los desafíos
                    </MessageErrorRequest>
                ) : currentChallenges?.length > 0 ? (
                    <Grid>
                        {currentChallenges.map((values, index) => (
                            <CardChallenges values={values} key={index} />
                        ))}
                    </Grid>
                ) : (
                    <MessageErrorRequest>
                        <NotFoundSvg width="50%" />
                        No se encontraron desafíos
                    </MessageErrorRequest>
                )}
            </Wrapper>
        </>
    );
};

export default Challenges;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;

const Filters = styled.div`
    width: 100%;
    height: 48px;
    background-color: #d9d9d9;
`;

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 1rem;
`;

const MessageErrorRequest = styled.div`
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    color: #591ab6;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;
`;

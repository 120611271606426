import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ShareOutlined, Favorite } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import useTheme from "../../../../hooks/useTheme";
import moment from "moment";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { postFav, postTakeOutFav } from "../../../../redux/api/utils";
import { useDispatch, useSelector } from "react-redux";
import { workshopsActions } from "../../../../redux/actions";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CheckIcon from "@mui/icons-material/Check";
import { getWorkshopById } from "../../../../redux/api/workshops";

const WorkshopCard = (props) => {
  const { value, handleOpenModal } = props;

  const { primary } = useTheme();
  let location = useLocation();

  const hash = encode.encode(value.repo_id + "", "base64");

  const now = moment();
  const fecha_desde = moment(value.fecha_desde);
  const isNew = now.isoWeek() === fecha_desde.isoWeek();
  const [fav, setFav] = useState(false);
  const [onCertificate, setOnCertificate] = useState(false);
  const [workshop, setWorkshop] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onCertificate) {
      getWorkshopByIdRequest(value.repo_id);
      window.open(
        `https://constancias.aden.org/?idcod=OA-${value.repo_id}-${user.repo_id}-ADEN2022/`,
        "_blank"
      );
    }
    setTimeout(() => {
      setOnCertificate(false);
    }, 2000);
  }, [onCertificate]);

  useEffect(() => {
    if (workshop !== null) {
      if (!!workshop.certificado && value.progreso >= 100) {
        window.open(`/certificate/${value.repo_id}/${user.repo_id}`, "_blank");
      }
    }
  }, [workshop]);

  useEffect(() => {
    setFav(value.favorito);
  }, [value.favorito]);

  const handleFav = () => (!fav ? setFavorite() : setTakeOut());

  const setFavorite = async () => {
    const req = await postFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "workshop",
    });
    if (!req.error) {
      setFav(true);
      handleStateRedux(value.repo_id, "like");
    }
  };

  const setTakeOut = async () => {
    const req = await postTakeOutFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "workshop",
    });
    if (!req.error) {
      setFav(false);
      handleStateRedux(value.repo_id, "no-like");
    }
  };

  const handleStateRedux = (id, type) => {
    type === "like"
      ? dispatch(workshopsActions.setLikes(id))
      : workshopsActions.setNoLikes(id);
  };

  const getWorkshopByIdRequest = async (id) => {
    try {
      const response = await getWorkshopById(id);
      setWorkshop(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** RETURN ----------------------------------------------------------------------- */
  return (
    <WorkshopCardContainer>
      <WrapperImage>
        <CustomImg src={value.image_url} alt="workshop" />
        {isNew && (
          <BadgeNew>
            <NewReleasesRoundedIcon style={{ fontSize: "1.2rem " }} />
            NUEVO
          </BadgeNew>
        )}
        <WrapperTags>
          {value.tags.slice(0, 3).map((tag, index) => (
            <>
              <Tag>{tag}</Tag>
              {index !== 2 ? <Tag>|</Tag> : null}
            </>
          ))}
        </WrapperTags>
        {value.progreso >= 100 && (
          <Label>
            <CheckIcon /> <p>Finalizado</p>
          </Label>
        )}
      </WrapperImage>
      <Body>
        <ProgressWrapper>
          <LinearProgressWrapper>
            <LinearProgress
              porcentaje={value.progreso > 100 ? 100 : value.progreso}
            />
          </LinearProgressWrapper>
          <Percentage>
            {parseInt(value.progreso > 100 ? 100 : value.progreso)}%
          </Percentage>
        </ProgressWrapper>
        <NombrePublicacion>{value.publication_name}</NombrePublicacion>
        {location.pathname === "/workshops" && (
          <Description>
            {value.description
              ? value.description
              : "Descripción del workshop vacía"}
          </Description>
        )}
      </Body>
      <Footer>
        <IconWrapper>
          <IconButton
            onClick={() =>
              handleOpenModal(value.repo_id, value.publication_name)
            }
            size="large"
          >
            <ShareOutlinedFake />
          </IconButton>
          <IconButtonFav
            isFav={fav}
            title="Marcar como favorito"
            onClick={handleFav}
          >
            <Favorite color="primary" fontSize="small" />
          </IconButtonFav>
          {value.progreso >= 100 && (
            <IconButton
              onClick={() => {
                setOnCertificate(true);
              }}
              size="large"
            >
              <EmojiEventsIconFake />
            </IconButton>
          )}
        </IconWrapper>
        <Link to={`/curso/${hash}?origin=workshop`}>
          <CustomButton variant="contained" color="primary">
            Cursar
          </CustomButton>
        </Link>
      </Footer>
    </WorkshopCardContainer>
  );
};

export default WorkshopCard;

const WorkshopCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  /* min-height: 520px; */
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 20px;

  @media screen and (max-width: 1221px) {
    min-height: 400px;
  }

  @media screen and (max-width: 768px) {
    min-height: 320px;
  }
`;
const WrapperImage = styled.div`
  position: relative;
`;

const Label = styled.div`
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  border-radius: 5px;
  padding: 0.1rem;
  background-color: #35d0a5;
  color: white;
  left: 10px;

  svg {
    font-size: 1.1rem;
    margin: 0.3rem 0 0.3rem 0.3rem;
  }
  p {
    margin: 0.3rem 0.3rem 0.3rem 0;
  }
`;

const WrapperTags = styled.div`
  position: absolute;
  display: flex;
  background-color: #fff;
  border-top-left-radius: 10px;
  padding: 0.3rem;
  bottom: 0;
  right: 0;
`;

const Tag = styled.p`
  color: #a8a8a8;
  text-transform: uppercase;
  font-size: 10px;
  margin: 0 2px;
`;

const CustomImg = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  @media screen and (max-width: 1221px) {
    height: 120px;
  }

  @media screen and (max-width: 768px) {
    height: 100px;
  }
`;

const BadgeNew = styled.div`
  position: absolute;
  background-color: #03a9f4;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: -5px;
  right: -5px;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: 900;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.06));
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
`;

const NombrePublicacion = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  text-align: start;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: auto;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #35d0a5;
`;

const Percentage = styled.span`
  font-size: 0.9rem;
  color: #35d0a5;
  flex-wrap: wrap;
`;

const Description = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  text-align: start;
  color: #949494;
  height: 7rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #fafafa;
  background-color: #fafafa;
  margin-top: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomButton = styled(Button)`
  font-weight: 700;
  flex-basis: calc(60% - 0rem);
`;

const IconButtonFav = styled(IconButton)`
  svg {
    color: ${(p) => !p.isFav && "#cccccc"};
    transform: scale(1.2);
  }
`;

const EmojiEventsIconFake = styled(EmojiEventsIcon)`
  color: #ded52c;
`;

const ShareOutlinedFake = styled(ShareOutlined)`
  color: #cccccc;
`;

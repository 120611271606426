import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Text } from "../../../../components/common/Texts";

const Greeting = () => {

  const { user } = useSelector((state) => state.auth);
  const primaryColor = useSelector((state) => state?.ondemand?.ondemand?.primary_color);
  
  return (
    <Greetings>
      <Text fontSize="28px" fontWeight="700" color={primaryColor}>
        Hola, {user.name}
      </Text>
      <Subtitle>
        <Text fontSize="1rem" fontWeight="500" color="#222222">
          <b>¡Que alegría verte de nuevo por aquí! </b>
          Échale un vistazo a tus pendientes
          y ten un excelente día.
        </Text>
      </Subtitle>

      
    </Greetings>
  );
};

export default Greeting;

const Greetings = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const Subtitle = styled.div`
  display: flex;
  gap: 4px;
  div {
    font-style: italic;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import parse from "html-react-parser";
import DefaultCardIcon from "../../../common/DefaultCardIcon";
import { Text } from "../../../common/Texts";
import SimpleLoading from "../../../common/SimpleLoading";
import { getDataEvaluationService } from "../../../../redux/api/courses";

const RenderActivity = (props) => {
  const { evaluationId, repoId, idResource, obj } = props;

  const [dataActivity, setDataActivity] = useState(null);

  useEffect(() => {
    if (dataActivity === null) {
      getDataPresentation();
    }
  }, [dataActivity]);

  // FUNCTION
  const getDataPresentation = async () => {
    let body = {
      id: evaluationId,
      user: repoId,
      obj: obj,
      reso: idResource,
    };
    const response = await getDataEvaluationService(body);
    if (!response) {
      setDataActivity(false);
    } else {
      setDataActivity(response);
    }
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  return (
    <>
      {dataActivity === null ? (
        <SimpleLoading />
      ) : (
        <WrapperInitial>
          <Name>{dataActivity.name}</Name>
          <Header>
            <LeftContent></LeftContent>
            <RightContent>
              <DefaultCardIcon
                icon={<ContactSupportIcon />}
                color="#5E80DB"
                subtitle={"Preguntas"}
                title={dataActivity.preguntas.length}
              />
              <DefaultCardIcon
                icon={<RestorePageIcon />}
                color="#5E80DB"
                subtitle={"Intentos"}
                title={dataActivity?.cantidad_intentos}
              />
              <DefaultCardIcon
                icon={<AccessAlarmIcon />}
                color="#5E80DB"
                subtitle={"Tiempo"}
                title={
                  !dataActivity?.time
                    ? "Sin límite"
                    : `${dataActivity?.time} min`
                }
              />
            </RightContent>
          </Header>
          <ContainerInitial>
            <Text color="#B31D15" fontSize="18px" fontWeight="700">
              Instrucciones
            </Text>

            <DescriptionInitial>
              <ParseHtml>
                {parse(sanitizerHtml(dataActivity.descripcion))}
              </ParseHtml>
            </DescriptionInitial>

            {/* <Text color="#B31D15" fontSize="18px" fontWeight="700">
                    Tus intentos
                </Text> */}

            {/* <AttemptsWrapper>
                    {!delivery ? (
                        <TextFake fontSize="16px" color="#A8A8A8">
                            Sin intentos registrados
                        </TextFake>
                    ) : (
                        <Attempts submissions={submissions} note={note} />
                    )}
                </AttemptsWrapper> */}
            {/* <Start>
                    <Text color="#616161" fontSize="16px">
                        Tienes{" "}
                        <Span fontWeight="bold">
                            {attempts === 100
                                ? "ilimitado"
                                : attempts < 0
                                ? 0
                                : attempts}
                        </Span>{" "}
                        intentos restantes
                    </Text>
                    {!!review && (
                        <Text color="#616161" fontSize="16px">
                            ¡No puedes volver a realizar el examen ya que has
                            visto la revisión!
                        </Text>
                    )}
                    <Button
                        disabled={
                            attempts <= 0 || !!review || !!isReview
                                ? true
                                : false
                        }
                        handleClick={handleStartExam}
                        label="Realizar actividad"
                    />
                </Start> */}
          </ContainerInitial>
        </WrapperInitial>
      )}
    </>
  );
};

export default RenderActivity;

const WrapperInitial = styled.div`
  width: 100%;
  height: 100%;
`;

const ContainerInitial = styled.div`
  width: calc(100% - 40px);
  height: 100%;
  padding: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Header = styled.div`
  height: auto;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const DescriptionInitial = styled.span`
  min-height: 200px;
  max-height: 380px;
  overflow-y: auto;
  height: auto;
`;

const AttemptsWrapper = styled.div`
  width: 100%;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  min-height: 70px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextFake = styled(Text)`
  font-style: italic;
`;

const Start = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

const ParseHtml = styled.div`
  width: 100%;
  max-width: auto;
  /* margin: 1rem; */
  /* Estilos html string */
`;

const LeftContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
`;

const RightContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
`;

const Name = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
`;

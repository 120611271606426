import axios from "axios";

export async function getAllChallenges(id, categories = "") {
    const URL = `${
        process.env.REACT_APP_REPO
    }/v1/repo_aden/ondemand/get_desafios/${id}${categories && categories}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
}

import React, { useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import OptionPhoto from "./OptionPhoto";
import DefaultField from "../../../common/DefaultField";
import DefaultSelect from "../../../common/DefaultSelect";
import SimpleLoading from "../../../common/SimpleLoading";
import { utilsActions } from "../../../../redux/actions";
import DefaultComplete from "../../../common/DefaultComplete";
import DefaultRadio from "../../../common/DefaultRadio";
import { FormControl, FormControlLabel, FormLabel } from "@mui/material";
import DefaultSwitch from "../../../common/DefaultSwitch";

const RADIOOPTIONS = [
  { value: "F", label: "Femenino" },
  { value: "M", label: "Masculino" },
  { value: "O", label: "Otro" },
];

const OptionOne = (props) => {
  const { values, setFieldValue } = props;

  // REDUX
  const dispatch = useDispatch();

  const docTypes = useSelector((state) => state.utils.docTypes);
  const nationalities = useSelector((state) => state.utils.nationalities);
  const countries = useSelector((state) => state.utils.countries);

  // EFFECTS
  useEffect(() => {
    if (docTypes === null) {
      dispatch(utilsActions.getDocTypesRequest());
    }
    if (nationalities === null) {
      dispatch(utilsActions.getNationalitiesRequest());
    }
    if (countries === null) {
      dispatch(utilsActions.getCountriesRequest());
    }
  }, []);

  // RETURN
  if (!docTypes || !nationalities || !countries) {
    return <SimpleLoading padding="8rem" />;
  }

  return (
    <OverflowWrappper>
      <Container>
        {/** Photo */}
        <OptionPhoto />
        {/** User Information */}
        <InputWrapper>
          {/** Nombre completo */}
          <FullField name="name" type="text" label="Nombre completo" />
          {/** Biografia */}
          <FullField
            name="biography"
            type="text"
            label="Acerca de mí"
            multiline
            rows={4}
          />
          {/** Tipo de documento */}
          <DefaultSelect
            name="document_type"
            label="Tipo de documento"
            options={docTypes}
          />
          {/** Numero de documento */}
          <DefaultField
            name="document_number"
            type="text"
            label="Nro. Documento"
          />
          {/** Cumpleaños */}
          <DefaultField
            name="birthday"
            type="date"
            label="Cumpleaños"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/** Direccion */}
          <DefaultField name="street" type="text" label="Dirección" />
          {/** Codigo Postal */}
          <DefaultField name="zip" type="number" label="Código Postal" />
          {/** Nacionalidad */}
          <DefaultComplete
            name="nationality"
            label="Nacionalidad"
            value={values.nationality}
            setFieldValue={setFieldValue}
            options={nationalities}
          />
          {/** Pais de residencia */}
          <DefaultComplete
            name="country"
            label="País de residencia"
            value={values.country}
            setFieldValue={setFieldValue}
            options={countries}
          />
          {/** Ciudad de residencia */}
          <DefaultField name="city" type="text" label="Ciudad de residencia" />
          {/** Genero */}
          <CustomFormControl component="fieldset" size="small" color="primary">
            <FormLabel component="legend" color="primary">
              Género
            </FormLabel>
            <DefaultRadio name="gender" row={true} options={RADIOOPTIONS} />
          </CustomFormControl>
          {/** Perfil Público/Privado */}
          <CustomFormControl size="small" color="primary">
            <FormControlLabel
              control={
                <DefaultSwitch
                  name="profile_is_public"
                  value={values.profile_is_public}
                />
              }
              label={
                values.profile_is_public
                  ? "El perfil está público"
                  : "El perfil está privado"
              }
            />
          </CustomFormControl>
        </InputWrapper>
      </Container>
    </OverflowWrappper>
  );
};

export default OptionOne;

const OverflowWrappper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  grid-gap: 2rem;
  padding: 2rem 1rem 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 0;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 100%;
  height: max-content;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
`;

const FullField = styled(DefaultField)`
  grid-column: span 2;
`;

const CustomFormControl = styled(FormControl)`
  grid-column: span 2;
`;

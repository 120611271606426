import React from "react";
import ErrorResponse from "../../../common/ErrorResponse";

const RenderPresentation = (props) => {
  const { resource } = props;

  return (
    <ErrorResponse message="Las actividades de presentacion aún no se pueden visualizar en esta página" />
    // <Presentation
    //     submitViewResource={submitViewResource}
    //     handleNewTry={handleNewTry}
    //     presentation={presentation}
    //     resourceId={id_recurso}
    //     objId={objId}
    //     idCourse={idCourse}
    //     setChangeStatus={setChangeStatus}
    //     viewDelivery={viewDelivery}
    // />
  );
};

export default RenderPresentation;

import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import { Text } from "../../../common/Texts";
import { ScheduleRounded } from "@mui/icons-material";
import moment from "moment";
import RegisterEventModal from "./RegisterEventModal";
import { useSelector } from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const EventsCard = (props) => {
  const { event, countryName, getDynamicEvent, tabsFake, currentTab } = props;

  const colorPrimary = useSelector((state) => state?.ondemand?.ondemand?.primary_color);

  // STATES
  const [openModal, setOpenModal] = useState(false);

  moment.updateLocale("es", {
    months:"Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
    monthsShort:"Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

  const currentDate = new Date();
  const formatCurrentDate = moment(currentDate).locale("es").format("LL");

  const getLocalUTC = () => {
    const hours = new Date().getTimezoneOffset();
    if (hours > 0) {
      return `-${hours / 60}`;
    } else {
      return `+${(hours * -1) / 60}`;
    }
  };

  const utc = getLocalUTC();

  const eventDateBegin = new Date(event?.date_begin.replaceAll("-", "/") + `${utc} UTC`);

  const monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const formatEventDateBegin = moment(eventDateBegin).locale("es").format("LL");

  const getHourNumber = (hour) => {
    return parseInt(hour.substring(0, 2));
  };

  const getMinuteNumber = (hour) => {
    return hour.substring(3, 5);
  };

  const handleClick = () => {
    if (currentTab === 0) {
      window.open(event?.registration_url);
      return;
    }
    setOpenModal(!openModal);
  };
  
  return (
    <>
      {openModal && (
        <RegisterEventModal
          event={event}
          handleClick={handleClick}
          getDynamicEvent={getDynamicEvent}
          tabsFake={tabsFake}
        />
      )}
      <CardFake
        isRegistered={event?.is_registered}
        colorPrimary={colorPrimary}
        type={event?.type}
        onClick={handleClick}
      >
        <Body 
        isRegistered={event?.is_registered}
        color="#fff"
        >
          {event?.type === "ONLINE" ? (
            (eventDateBegin === currentDate || formatEventDateBegin === formatCurrentDate) && event?.is_registered ? (
              <Footer>
                <DateWrapper isRegistered={event?.is_registered}>
                  <p>{monthNames[eventDateBegin.getMonth()]}</p>
                  <p>{eventDateBegin.getFullYear()}</p>
                </DateWrapper>
                <WrapperText>
                  <CircleLive />
                  <Text
                    fontSize="14px"
                    color="var(--primary-one)"
                    fontWeight="bold"
                    component="span"
                  >
                    En vivo hoy
                  </Text>
                </WrapperText>
              </Footer>
            ) : event?.is_registered ? (
              <Footer>
                <DateWrapper isRegistered={event?.is_registered}>
                  <p>{monthNames[eventDateBegin.getMonth()]}</p>
                  <p>{eventDateBegin.getFullYear()}</p>
                </DateWrapper>
                <WrapperInscription colorPrimary={"#35D0A5"} type={true}>
                  <CheckCircleIcon /> 
                  Inscripto
                </WrapperInscription>
              </Footer>
            ) : (
              <Footer>
                <DateWrapper isRegistered={event?.is_registered}>
                  <p>{monthNames[eventDateBegin.getMonth()]}</p>
                  <p>{eventDateBegin.getFullYear()}</p>
                </DateWrapper>
                <WrapperInscription colorPrimary={"var(--primary-one)"} type={false}>
                  <AddCircleIcon />
                  Inscribirme
                </WrapperInscription>
              </Footer>
            )
          ) : (
            <Footer>
              <DateWrapper isRegistered={event?.is_registered}>
                <p>{monthNames[eventDateBegin.getMonth()]}</p>
                <p>{eventDateBegin.getFullYear()}</p>
              </DateWrapper>
              {
                event?.is_registered ? (
                  <WrapperInscription colorPrimary={"#35D0A5"} type={false}>
                    <CheckCircleIcon /> 
                    Inscripto
                  </WrapperInscription>
                ) : (
                  <WrapperInscription colorPrimary={"var(--primary-one)"} type={false}>
                    <AddCircleIcon />
                    Inscribirme
                  </WrapperInscription>
                )
              }
            </Footer>
          )}
          <TextFakeWrapper>
            <TextFake
              id="title"
              fontSize="16px"
              fontWeight="700"
              color="#222222"
            >
              {event?.publication_name ?? event?.name}
            </TextFake>
          </TextFakeWrapper>
          <WrapperText>
            <ScheduleRounded />
            <Text fontSize="11px" color="#616161" component="span">
              {event.hour_begin ?? event.date_begin}
              {" (AR) |"}{" "}
              {getHourNumber(event.hour_begin ?? event.date_begin) -
                2 +
                ":" +
                getMinuteNumber(event.hour_begin ?? event.date_begin)}{" "}
              {" (PA) |"}{" "}
              {getHourNumber(event.hour_begin ?? event.date_begin) -
                3 +
                ":" +
                getMinuteNumber(event.hour_begin ?? event.date_begin)}{" "}
              {" (CR) "}
            </Text>
          </WrapperText>
          {event.type === "PRESENCIAL" && (
            <>
              <WrapperText>
                <LocationOnIcon />
                <Text
                  fontSize="11px"
                  color={"#616161"}
                  component="span"
                >
                  {event?.location?.street ?? "Indefinido 999"},{" "}
                  {event?.location?.city ?? "Indefinido"},{" "}
                  {countryName !== "" ? countryName : "Indefinido"}
                </Text>
              </WrapperText>
            </>
          )}
        </Body>
      </CardFake>
    </>
  );
};

export default EventsCard;

const CardFake = styled(Card)`
  display: inline-flex;
  position: relative;
  min-height: ${(p) => (p.type === "ONLINE" ? "150px" : "150px")};
  cursor: pointer;
  width: calc(100% - 10px);
  border-radius: 10px;
  border-top: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  border-left: 8px solid #DCDCDC;
  scale: ${(p) => p.scale};
  :hover {
    border-left: 8px solid ${(p) => p.isRegistered ? "#35D0A5" : "var(--primary-one)"};
    box-shadow: 0px 8px 8px -4px #18274B14, 0px 4px 4px 0px #00000040;
  }
  transition: all 0.3s ease-in-out;

`;

const TextFakeWrapper = styled.div`
  height: auto;
  width: calc(100% - 45px);
`;

const TextFake = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 0.3rem;
  position: relative;
  width: calc(100% - 25px);
  max-width: calc(100% - 25px);
  background: white;
  align-items: ${(p) => (p.type === "default" ? "center" : "flex-start")};
  transition: ease-in-out 0.3s all;

  :hover {
    background: linear-gradient(270deg, ${(p) => p.isRegistered ? "#EAFAF4" : "var(--secondary-two)"} 0%, ${(p) => p.isRegistered ? "#fff" : "#fff8f7"} 100%);
  }
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    font-size: 16px;
    color: #a8a8a8;
  }
`;

const WrapperInscription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => (p.colorPrimary)};
  font-size: 14px;
  font-weight: 700;
  padding: 0.2rem;
  svg {
    font-size: 1.1rem;
  }
`;

const CircleLive = styled.div`
  min-width: 12px;
  min-height: 12px;
  background-color: var(--primary-one);
  border-radius: 50%;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

const DateWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  padding: 4px 8px;
  background-color: ${(p) => p.isRegistered ? "#35D0A5" : "var(--primary-one)"};
  border-radius: 100px;
  p {
    color: #fff;
    font-weight: 500;
    font-size: 14;
  }
`;

import { Route, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import SimpleLoading from "../components/common/SimpleLoading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux/actions";

// Hooks
import { useQueryParams } from "../hooks/useQueryParams";

// Utils
import { decryptObject, /* encryptObject */ } from "../utils/encryptAndDecryptObject";
import { getHomeByRol } from "../helpers/roles.helper";

const AutoLoginRoute = (props) => {

	const [flag, setFlag] = useState(false);

	const dispatch = useDispatch();

	const { authToken, loadingGetUser, activeRol, user, error } = useSelector((state) => state?.auth);
	const ondemand = useSelector((state) => state.ondemand.ondemand);

	const params = useQueryParams();

	const redirecturi = params.get("redirecturi");
	const rol = params.get("rol");
	const userParam = params.get("user");
	const token = params.get("token");

	const DEFAULT_ROL = !!rol ? rol : "visita";

	const { push } = useHistory();

	const handleLogin = () => {
		// Funcion para encriptar un objeto
		/* encryptObject({ user: 'usuario.muestra@acropolislab.com', password: 'aden12345' }, 'clave-alab-auto1').then(encryptedString => {
			console.info("encriptado: ",encryptedString);
		}); */
		if (!loadingGetUser && activeRol !== DEFAULT_ROL) {
			dispatch(authActions.getDataRequest(DEFAULT_ROL));
		}
		if (!loadingGetUser && activeRol === DEFAULT_ROL) {
			push(getHomeByRol(DEFAULT_ROL));
		}
		// Funcion para desencriptar un objeto 
		if (!!userParam) {
			const encryptedString = userParam.replace(/ /g, "+"); // Se usa expresion regular para poner un + en lugar de un espacio porque cuando llegan por params los + se convierten en espacios (tener cuidado con los = tambien)
			decryptObject(encryptedString, 'clave-alab-auto1').then(decryptedObj => {
				if (DEFAULT_ROL === "ondemand") {
					dispatch(authActions.loginUserRequest(decryptedObj));
				}
			});
		}
	};

	useEffect(() => {
		if (!!authToken && !!user) {
			push(`../${redirecturi}`);
		}
		if (!authToken && !user) {
			dispatch(authActions.setTokenAuth(token));
		}

		if (!!authToken && !user) {
			if (!flag) {
				handleLogin();
				setFlag(true);
			} else if (!!error) {
				dispatch(authActions.setTokenAuth(null));
				push(`../auth?redirect=${redirecturi}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingGetUser, authToken]);

	// EFFECTS
	useEffect(() => {
		if (!!ondemand) {
			push(`../${redirecturi}`);
		}
		if (!user) {
			handleLogin();
		} else {
			dispatch(authActions.getDataRequest(DEFAULT_ROL));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<>
			<Route
				{...props}
				render={() => {
					return (
						<Container>
							<SimpleLoading />
						</Container>
					);
				}}
			/>
		</>
	);
};

export default AutoLoginRoute;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: #fff;
`
import * as types from "../types/my_course_routes";

const initialState = {
  tabs: null,
  loading: false,
  error: false,
  errorMessage: false,
};

export const coursesTabs = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MY_COURSE_ROUTES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_MY_COURSE_ROUTES_SUCCESS:
      return {
        ...state,
        tabs: action.payload,
        loading: false,
        error: false,
        errorMessage: false,
      };
    case types.GET_MY_COURSE_ROUTES_FAIL:
      return {
        ...state,
        tabs: false,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

import { useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from '@iconify/react';
import Text from "../../../../components/common/Text";
import { useSelector } from "react-redux";

const NewsForYou = () => {

    const primaryColor = useSelector((state) => state?.ondemand?.ondemand?.primary_color);

    const mock = ["", "", ""]

    const [isDown, setIsDown] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [open, setOpen] = useState(0)

    const containerRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDown(true)
        setStartX(e.pageX - containerRef.current.offsetLeft)
        setScrollLeft(containerRef.current.scrollLeft)
    }

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault()
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 1.5;
        containerRef.current.scrollLeft = scrollLeft - walk;
    }

    return (
        <>
            <Text fontSize="20px" fontWeight="700" color="var(--primary-one)">Novedades para ti</Text>
            <Container
                onMouseDown={(e) => handleMouseDown(e)}
                onMouseLeave={() => setIsDown(false)}
                onMouseUp={() => setIsDown(false)}
                onMouseMove={(e) => handleMouseMove(e)}
                isDown={isDown}
                ref={containerRef}
            >
                <CarouselHorizontal>
                    {
                        mock.map((item, i) => {

                            return (
                                <Card open={open} index={i + 1}>
                                    <InsideCard>
                                        <PrincipalText color={primaryColor}>
                                            Descubre el nivel de tus habilidades
                                        </PrincipalText>
                                        <OpenButton
                                            open={open} index={i + 1}
                                            onClick={() => {
                                                open === 0 ? setOpen(i + 1) : setOpen(0)
                                            }}>
                                            <Icon icon="ep:right" width="30" height="30" />
                                        </OpenButton>
                                    </InsideCard>
                                    <OutsideCard open={open} index={i + 1} backgroundColor={primaryColor}>
                                        <RightOpen>
                                            <CardText>
                                                <i>Explora, mejora tus aptitudes y conviértete<br /> <b>en tu mejor versión.</b></i>
                                            </CardText>
                                            <TestButton backgroundColor={primaryColor}>
                                                ¡Realiza ahora el test de habilidades!
                                            </TestButton>
                                        </RightOpen>
                                    </OutsideCard>

                                </Card>
                            )
                        })
                    }
                </CarouselHorizontal>
            </Container>
        </>
    )
}

export default NewsForYou;

const Container = styled.div`
    width: 102%;
    height: 440px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: -12px;
    padding-bottom: 8px;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
        height: 0;
    }
`

const CarouselHorizontal = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 24px;

    overflow-x: auto;
    cursor: grab;
    &:active {
        cursor: -webkit-grabbing;
    }
`

const PrincipalText = styled.h2`
    position: absolute;
    z-index: 9999;
    font-size: 32px;
    font-weight: 800;
    color: ${(props) => props.color};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    background-color: #fff;
    padding: 0 4px;
`

const Card = styled.div` 
    width: ${(p) => p.open === p.index ? "1470px" : "1050px"};
    transition: width 1.5s ease;
    height: 440px;
    display: flex;
    justify-content: start;
    align-items: center;
`

const InsideCard = styled.div`
    width: 1028px;
    height: 440px;
    background-color: var();
    background-image: url("/assets/aden/novedades-img.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: -70px;
    transition: none;
    position: relative;
`

const OutsideCard = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    height: 350px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 20px;
    width:${(p) => p.open === p.index ? "490px" : "70px"};
    padding-right: ${(p) => p.open === p.index ? "0" : "20px"};
    transition: width 1.5s ease;
`

const OpenButton = styled.button`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 60px;
    height: 150px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 999;
    color: white;
    svg {
        transform: ${(p) => p.open === p.index && "rotate(180deg)"};
        transition: transform 0.3s ease-in-out;
    }
`

const RightOpen = styled.div`
    width: 490px;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
`

const CardText = styled.div`
    width: 370px;
    color: #FFF;
    font-size: 24px;
    font-weight: 400;
    padding-left: 60px;
`

const TestButton = styled.button`
    height: 40px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    color: ${(props) => props.backgroundColor};
    border-radius: 48px;
`
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

// Components
import SkeletonSubject from "./SkeletonSubject";
import SimpleLoading from "../../components/common/SimpleLoading";
import MainSubject from "../subject/MainSubject";

// Hooks
import { useSelector } from "react-redux";
import { useQueryParams } from "../../hooks/useQueryParams";

// Redux
import { getSubjectWithCourses, updateLastEntry } from "../../redux/api/courses";

// Helpers
import { titleHTML } from "../../helpers/title.helper";

export default function CourseSubject() {
  titleHTML("Asignatura");

  const { id } = useParams();
  const {repo_id, od_participant_id} = useSelector((state) => state.auth.user);
  const params = useQueryParams();
  const origin = params.get("origin") || "";
  const program = params.get("program") || "";

  const hash = encode.decode(id, "base64");
  const programId = encode.decode(program, "base64");

  const [subject, setSubject] = useState(null)

  useEffect(() => {
    updateLastEntry(od_participant_id, hash);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    !subject && getSubject(hash)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject])

  const getSubject = async (id) => {
    try {
      const response = await getSubjectWithCourses(repo_id, id)
      setSubject(response);
    } catch (error) {
      console.log(error);
    }
  };

  if (subject?.courses?.length === 0) {
    return <SkeletonSubject />;
  }

  return (
    <CourseSubjectWrapper>
      {!!subject ? (
        <MainSubject
          subjectSelected={subject}
          idCourse={id}
          origin={origin}
          programId={programId}
        />
      ) : (
        <SimpleLoading />
      )}
    </CourseSubjectWrapper>
  );
}

const CourseSubjectWrapper = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

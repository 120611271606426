import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { postNotification } from "../../../../redux/api/notifications";

const CardNotifications = (props) => {
  const { title, descriptions, date, read, allRead, launch_url, id } = props;

  // Function
  const handleRedirect = async () => {
    const req = await postNotification(id);

    if (req.error) {
      console.error(req.error);
    }
    if (launch_url) {
      window.location.href = launch_url;
    }
  };

  return (
    <CardFake
      onClick={handleRedirect}
      allRead={allRead}
      launch_url={launch_url}
      read={read}
    >
      <Header>
        <Title allRead={allRead} read={read}>
          {title}
        </Title>
        <Date>{date}</Date>
      </Header>
      <Body>
        <p>{descriptions}</p>
      </Body>
    </CardFake>
  );
};

export default CardNotifications;

const CardFake = styled(Card)`
  box-shadow: ${(p) =>
    p.allRead
      ? "none"
      : p.read
      ? "none"
      : "0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)"};
  border-radius: 20px;
  background-color: ${(p) =>
    p.allRead ? "#F9F9F9" : p.read ? "#F9F9F9" : "#5E80DB25"};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #f1f1f1;
  width: calc(100% - 2rem);
  min-height: 96px;
  height: auto;
  cursor: ${(p) => (p.launch_url ? "pointer" : "default")};
  position: relative;
  ::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 100%;
    width: 9px;
    overflow: hidden;
    background-color: ${(p) => (p.read ? "#d9d9d9" : "#5E80DB")};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.h3`
  width: 100%;
  color: ${(p) => (p.allRead ? "#616161" : p.read ? "#616161" : "#5E80DB")};
  font-weight: bold;
  font-size: 1rem;
  text-align: start;
  text-transform: uppercase;
`;

const Date = styled.p`
  color: #a8a8a8;
  font-size: 1rem;
  width: 100%;
  text-align: end;
`;

const Body = styled.div`
  width: 100%;
  p {
    color: #616161;
    font-size: 1.1rem;
  }
`;

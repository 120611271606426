import React from "react";
import styled from "styled-components";

import { WhatsApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const WhatsappChat = () => {
  const URL = `https://api.whatsapp.com/send/?phone=5492617504810`;

  const handleRedirect = () => {
    window.open(URL);
  };

  return (
    <WhatsappIconWrapper onClick={handleRedirect}>
      <WhatsApp />
    </WhatsappIconWrapper>
  );
};

export default WhatsappChat;

const WhatsappIconWrapper = styled(IconButton)`
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: #07a004;
  right: 1.5rem;
  bottom: 2rem;
  z-index: 15;

  svg {
    fill: #ffffff;
    width: 35px;
    height: 35px;
  }

  &:hover {
    background-color: #0bb205;
  }
`;

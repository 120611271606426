import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

// Components
import ModalDeleteNote from "./ModalDeleteNote";

// Material UI
import DeleteIcon from "@mui/icons-material/Delete";

// Context
import CourseContext from "../../../context/CourseContext"

const CardNoteCourse = (props) => {
    const {
        notes,
        resetUpdateNotes = () => null
    } = props;

    const {
        handleIsChangeMinute,
        handleCurrentMinute,
        notesContent: {
            getNotes
        },
        showAside
    } = useContext(CourseContext); 

    // STATES
    const [isConfirm, setIsconfirm] = useState(false);
    const [seeMore, setSeeMore] = useState(false)

    // FUNCTIONS
    const handleClick = () => {
        handleCurrentMinute(notes?.video_time);
        handleIsChangeMinute(true);
    };

    const handleConfirm = (value) => {
        setIsconfirm(value);
    };

    function secondsToHMS(d) {
        let hours = Math.floor(d / 3600);
        let minutes = Math.floor((d - hours * 3600) / 60);
        let seconds = d - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        const integerPart = Math.floor(seconds);
        if (!hours || !minutes) {
            return notes.video_time;
        } else {
            return (
                hours +
                ":" +
                minutes +
                ":" +
                integerPart.toString().padStart(2, "0")
            );
        }
    }

    if (!showAside) {
        return (
            <>
                <ModalDeleteNote
                    isConfirm={isConfirm}
                    handleConfirm={handleConfirm}
                    id={notes.id}
                    resetUpdateNotes={resetUpdateNotes}
                    getNotes={getNotes}
                />
                <NoteCard seeMore={seeMore}>
                    <NoteHeader>
                        <Icon icon="mingcute:file-new-fill" width="20px" height="20px" style={{ color: "var(--secondary-four)" }} />
                        <NoteContent seeMore={seeMore}>
                            <p>{notes.content}</p>
                            <button onClick={() => setSeeMore(!seeMore)}>
                                {
                                    !seeMore ? "Ver más" : "Ver menos"
                                }
                            </button>
                        </NoteContent>
                    </NoteHeader>
                    <NoteFooter>
                        <VideoInfo>
                            <button onClick={() => handleClick()}>
                                <Icon icon="ph:play-fill" width="20px" height="20px" />
                            </button>
                            <NoteTime>{secondsToHMS(notes.video_time)}</NoteTime>
                        </VideoInfo>
                        <NoteActions>
                            <button onClick={() => handleConfirm(true)}>
                                <Icon icon="ic:baseline-delete" width="20px" height="20px" />
                            </button>
                        </NoteActions>
                    </NoteFooter>
                </NoteCard>
            </>
        )
    }

    return (
        <>
            <ModalDeleteNote
                isConfirm={isConfirm}
                handleConfirm={handleConfirm}
                id={notes.id}
                resetUpdateNotes={resetUpdateNotes}
                getNotes={getNotes}
            />
            <Card>
                <Header />
                <BoxContainer>
                    <p>{notes?.content}</p>
                </BoxContainer>
                <Footer>
                    <TimeContainer onClick={handleClick}>
                        <Icon icon="ph:play-fill" />
                        <p>{secondsToHMS(notes?.video_time)}</p>
                    </TimeContainer>
                    <DeleteButton onClick={() => handleConfirm(true)}>
                        <DeleteIcon />
                    </DeleteButton>
                </Footer>
            </Card>
        </>
    );
};

export default CardNoteCourse;

const NoteCard = styled.div`
    height: ${(props) => props.seeMore ? "fit-content" : "100px"};
    width: 160px;
    background-color: var(--secondary-one);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;

    section {
        color: var(--secondary-four);
        button {
            cursor: pointer;
            color: var(--secondary-four);
        }
    }

    :hover {
        box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;

        button {
            color: var(--primary-one);
        }
        section {
            background-color: var(--primary-one); 
            div {
                color: var(--secondary-one);
                svg {
                    color: var(--secondary-one);
                }
            }
            button {
                color: var(--secondary-one);
            }
        }
    }
`

const NoteHeader = styled.div`
    height: 60%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 8px;
`

const NoteFooter = styled.section`
    height: 40px;
    background-color: #e5e5e5;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`

const NoteContent = styled.div`

    p {
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        color: var(--secondary-three);
    }

    button {
        cursor: pointer;
        font-size: 10px;
        font-weight: 700;
        color: var(--secondary-three);
    }

    p {
        overflow: hidden;
        white-space: ${(props) => !props.seeMore && "nowrap"};
        text-overflow: ellipsis;
    }
`

const VideoInfo = styled.section`
    display: flex;
    align-items: center;
    gap: 8px;
`

const NoteTime = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary-four);
`

const NoteActions = styled.div`
    display: flex;
    gap: 4px;
`

const Card = styled.div`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08),
        0px 6px 8px -6px rgba(24, 39, 75, 0.12);
    background-color: var(--secondary-one);
`;

const Header = styled.div`
    height: 12px;
    background-color: #f1f1f1;
    width: 100%;
    border-radius: 10px 10px 0 0;
`;

const BoxContainer = styled.div`
    width: calc(100% - 2rem);
    height: auto;
    padding: 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    p {
        text-align: start;
        color: #222222;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
`;

const Footer = styled.div`
    width: calc(100% - 3rem);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    background-color: #f1f1f1;
    border-radius: 0 0 10px 10px;
`;

const TimeContainer = styled.div`
    display: flex;
    gap: 0.3rem;
    align-items: center;
    cursor: pointer;

    p {
        color: #616161;
        font-size: 1.1rem;
    }
    svg {
        color: #b31d15;
        font-size: 1.3rem;
    }
`;

const DeleteButton = styled.button`
    width: auto;
    cursor: pointer;

    svg {
        color: #b31d15;
    }
`;
import React, { useRef, useState } from "react";
import styled from "styled-components";
import VideoJS from "../../../common/VideoJS";

const RenderVideo = (props) => {
  const { resource } = props;

  const [errorVideo, setErrorVideo] = useState(null);

  const playerRef = useRef(null);
  const videoRef = useRef(null);

  console.log("resource", resource);

  const videoResolution = [
    {
      src: resource.direct_link_vimeo_720 || resource.video_url,
      type: "video/mp4",
      label: "720p",
      res: 720,
    },
    {
      src: resource.direct_link_vimeo_1080 || resource.video_url,
      type: "video/mp4",
      label: "1080p",
      res: 1080,
    },
  ];

  const onProgress = () => {
    return;
  };

  const onError = (error) => {
    setErrorVideo(error);
  };

  return (
    <div>
      {!errorVideo ? (
        <VideoJS
          videos={videoResolution}
          playerRef={playerRef}
          videoRef={videoRef}
          onProgress={onProgress}
          onError={onError}
        />
      ) : (
        <Iframe src={resource.video_url} />
      )}
    </div>
  );
};

export default RenderVideo;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  min-height: 400px;
  height: 100%;
`;

import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getDocuments, saveDocument, updateDocument } from "../api/documents";
import { documentsActions } from "../actions";

function* documentsRequest() {
  try {
    const res = yield call(getDocuments);
    yield put(documentsActions.getDocumentsSuccess(res.data.titulaciones));
  } catch (error) {
    yield put(documentsActions.getDocumentsFail(error));
  }
}

function* documentRequest(action) {
  try {
    const res = yield call(saveDocument, action.payload);
    yield put(documentsActions.postDocumentSuccess(res.data));
  } catch (error) {
    yield put(documentsActions.postDocumentFail(error));
  }
}

function* documentUpdateRequest(action) {
  try {
    const res = yield call(updateDocument, action.payload);
    yield put(documentsActions.putDocumentSuccess(res.data));
  } catch (error) {
    yield put(documentsActions.putDocumentFail(error));
  }
}

function* documentsWatcher() {
  yield takeEvery(types.GET_DOCUMENTS_REQUEST, documentsRequest);
  yield takeEvery(types.POST_DOCUMENT_REQUEST, documentRequest);
  yield takeEvery(types.PUT_DOCUMENT_REQUEST, documentUpdateRequest);
}

export { documentsWatcher };

import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getMicrolearnings } from "../api/microlearnings";
import { microlearningsActions } from "../actions";

function* microlearningsRequest() {
  try {
    const res = yield call(getMicrolearnings);
    yield put(
      microlearningsActions.getMicrolearningsSuccess(res.data.response_data)
    );
  } catch (error) {
    yield put(microlearningsActions.getMicrolearningsFail(error));
  }
}

function* microlearningsWatcher() {
  yield takeEvery(types.GET_MICROLEARNINGS_REQUEST, microlearningsRequest);
}

export { microlearningsWatcher };

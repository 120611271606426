import { useState } from "react";

const useGetStudyPlan = () => {
  // STATES
  const [tab, setTab] = useState(0);

  // FUNCTIONS
  function handleTab(e, value) {
    setTab(value);
  }

  return {
    tab,
    handleTab,
  };
};

export default useGetStudyPlan;

import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../../../../components/common/Texts";
import NoResultsFound from "../../../../../components/common/NoResultsFound";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Icon } from '@iconify/react';
import * as encode from "nodejs-base64-encode";
import LazyImg from "../../../../../modules/acropolisCommon/components/LazyImg"
import useStatus from "../../../../../hooks/useStatus";
import useBusiness from "../../../../../hooks/business/useBusiness";
import RegistrationModal from "../../../../../components/templates/business/components/RegistrationModal";

const FirstSectionCard = (props) => {

    const {
        object,
        information: {
            id = 0,
            name_publication,
            image,
            progress
        },
        pendingExams,
        classification: {
            pending,
            in_progress,
            finished
        },
        type,
        title,
        to,
        subtitle,
    } = props;

    // Valores por defecto en caso de que no esté matriculado en ruta formativa o curso
    const defaultValues = type === "course" ? {
        id: 1794,
        img: "https://repositorio.aden.org/web/image?model=repo.aden.objeto_aprendizaje&id=1794&field=imagen&unique=14112023150857",
        nombre_publicacion: "Liderazgo en acción"
    }
        : {
            id: 1632,
            img: "https://repositorio.aden.org/web/image?model=repo.aden.asignatura&id=1632&field=imagen&unique=17042024104726",
            nombre_publicacion: "Inteligencia Artificial"
        }

    const [openModal, setOpenModal] = useState(false)

    // REDUX
    const { enable_correction_activities, primary_color } = useSelector((state) => state?.ondemand?.ondemand);

    // HOOKS
    const { push } = useHistory();

    const { setLightStateColor, setStrongStateColor } = useStatus();

    const { registrateUser, repo_id, ondemand_id } = useBusiness("card");

    // FUNCTIONS

    const hash = (id) => encode.encode(id + "", "base64");

    const handleClick = (id) => {
        if (progress === undefined) {
            setOpenModal(true)
        } else {
            if (type === "course") {
                push(`/curso/${hash(id)}?origin=${!!enable_correction_activities ? "plan" : "alab"}`)
            } else {
                push(`/asignatura/${hash(id)}`)
            }
        }
    };

    if (object === false) {
        return (
            <NoResultsFound
                message={"No hay resultados en: " + title.toUpperCase()}
            />
        );
    }

    return (
        <CoursesContainer backgroundColor={primary_color}>
            <RegistrationModal
                handleClick={registrateUser}
                data={defaultValues}
                openModal={openModal}
                setOpenModal={setOpenModal}
                repoId={repo_id}
                ondemandId={ondemand_id}
                type={type}
            />
            {/* Header */}
            <OptionsWrapper>
                <ButtonTitle href={to} primaryColor={primary_color}>
                    {/* Extraer este Icon, pasarlo por props */}
                    <Icon icon="ion:book" width="25" height="20" />
                    {title}
                </ButtonTitle>
                <Line />
            </OptionsWrapper>
            {/* Body */}
            <Container>
                <LastCourse>
                    {
                        progress === undefined &&
                        <Text fontSize="14px" fontWeight="600" textAlign={"center"} style={{ width: "100%" }}>
                            ¡Ups! Aún no iniciaste {type === "route" ? "ninguna ruta" : "ningún curso"}.
                        </Text>
                    }
                    <SubtitleText>
                        {
                            progress === undefined
                                ? <Text fontSize="14px" fontWeight="600" textAlign={"center"} style={{ width: "100%" }}>Te recomendamos {type === "route" ? "la" : "el"} siguiente: <b>"{defaultValues?.nombre_publicacion}"</b></Text>
                                : <Text fontSize="14px" fontWeight="600">{subtitle}: <b>"{name_publication ?? defaultValues?.nombre_publicacion}"</b></Text>
                        }
                    </SubtitleText>
                    <CourseContainer maxHeight={progress === undefined ? "290px" : "330px"}>
                        <ImageContainer>
                            <LazyImg
                                src={image ?? defaultValues?.img}
                                height="100%"
                                width="100%"
                                borderRadius="10px 10px 0 0"
                            />
                        </ImageContainer>

                        <CourseInfo>
                            <ProgressContainer>
                                <Text fontSize="12px" fontWeight="600">
                                    {
                                        progress < 34 ? "Buen inicio de cursado, ¡muchos éxitos!"
                                            : (progress >= 34 && progress <= 66) ? "Excelente progreso, ¡mantén ese ritmo!"
                                                : progress > 66 ? "Sigue así, ¡ya casi terminas!"
                                                    : "¡Comienza ahora!"
                                    }
                                </Text>
                                <Progress>
                                    <ProgressBar>
                                        <ProgressColorBar progress={progress} />
                                    </ProgressBar>
                                    <Text fontSize="12px" fontWeight="600">
                                        {parseInt(progress ?? 0) + "%"}
                                    </Text>
                                </Progress>
                            </ProgressContainer>
                            <ContinueButton onClick={() => handleClick(id)}>
                                {
                                    (type === "course" && progress === 100) ? "Ver cursado" :
                                        (type === "course" && progress === 0) ? "Empezar cursado" :
                                            (type === "course" && progress > 0 && progress < 100) && "Continuar curso"
                                }
                                {
                                    (type === "route" && progress === 100) ? "Ver ruta" :
                                        (type === "route" && progress === 0) ? "Empezar ruta" :
                                            (type === "route" && progress > 0 && progress < 100) && "Continuar ruta"
                                }
                                {
                                    progress === undefined && "¡Comienza ahora!"
                                }
                            </ContinueButton>
                        </CourseInfo>
                    </CourseContainer>
                </LastCourse>
                <AsideContainer>
                    <AsideBox backgroundWhite={progress === undefined}>
                        <BoxTitle>
                            <Text fontSize="14px" fontWeight="700" color="var(--primary-one)">
                                Mi cursado
                            </Text>
                        </BoxTitle>
                        <ButtonsContainer>
                            {
                                progress === undefined
                                    ? <>
                                        <p><b>Aún no tienes <br /> {type === "course" ? "cursos iniciados" : "rutas iniciadas"}</b><br />No te preocupes <br /> ¡puedes comenzar ahora!</p>
                                    </>
                                    : <>
                                        <InfoButton lightColor={setLightStateColor("sin_entrega")} strongColor={setStrongStateColor("sin_entrega")}>
                                            <b>{pending}</b> por iniciar
                                        </InfoButton>
                                        <InfoButton lightColor={setLightStateColor("pendiente")} strongColor={setStrongStateColor("pendiente")}>
                                            <b>{in_progress}</b> en curso
                                        </InfoButton>
                                        <InfoButton lightColor={setLightStateColor("corregido")} strongColor={setStrongStateColor("corregido")}>
                                            <b>{finished}</b> finalizados
                                        </InfoButton>
                                    </>
                            }
                        </ButtonsContainer>
                    </AsideBox>
                    <AsideBox backgroundWhite={pendingExams.length === 0}>
                        <BoxTitle>
                            <Text fontSize="14px" fontWeight="700" color="var(--primary-one)" textAlign="center">
                                Mis actividades pendientes
                            </Text>
                        </BoxTitle>
                        <ButtonsContainer>
                            {
                                pendingExams.length > 0
                                    ?
                                    <>
                                        {
                                            pendingExams.map((item) => (
                                                <InfoButton lightColor={setLightStateColor("sin_entrega")} strongColor={setStrongStateColor("sin_entrega")}>
                                                    <p>{item.exam_name}</p>
                                                </InfoButton>
                                            ))
                                        }
                                    </>
                                    :
                                    <p><b>No tienes <br /> actividades pendientes.</b><br />¡Enhorabuena!</p>
                            }
                        </ButtonsContainer>
                    </AsideBox>
                </AsideContainer>
            </Container>
        </CoursesContainer>
    );
};

export default FirstSectionCard;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 480px;
    border-radius: 20px;
    box-shadow: 0px 8px 8px -4px #18274B14, 0px 4px 6px -4px #18274B1F;
    background-image: ${(props) => `linear-gradient(180deg, ${props.backgroundColor} , #fff)`};
    background-size: 100% 300%;

    @media (max-width: 768px) {
        min-height: 470px;
        height: 430px;
        width: 100%;
    }
`;

const OptionsWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    padding: 24px;
    color: #fff;
`;

const ButtonTitle = styled.a`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 24px 0px 16px;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    white-space: nowrap;
    background-color: ${(props) => props.primaryColor};
    border: 1px solid var(--secondary-one);
    cursor: pointer;

    :hover {
        box-shadow: 0px 4px 4px 0px #00000040;
    }
`;

const Line = styled.div`
    width:100%;
    height: 1.5px;
    background-color: #fff;
    margin-bottom: 2px;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 48px);
    height: 100%;
    min-height: 392px;
    padding: 0 24px 20px 24px;
    gap: 16px;
    border-radius: 20px;
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        padding: 0;
        margin: 0 auto;
        height: 340px;
        min-height: 370px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(128.08deg, #FFFFFF 2.08%, rgba(255, 255, 255, 0) 103.51%);
        box-shadow: 0px 4px 4px 0px #00000040;
    }
`

const LastCourse = styled.div`
    width: 70%;
    max-width: 520px;
    height: calc(100% - 32px);
    border-radius: 20px;
    background: linear-gradient(128.08deg, #FFFFFF 2.08%, rgba(255, 255, 255, 0) 103.51%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
    padding: 16px;
    
    @media (width <= 1400px ) {
        max-width: 335px;
    }

    @media (width <= 1250px) {
        max-width: 280px;
    }

    @media (max-width: 768px) {
        height: 43%;
        width: calc(100% - 7rem);
        background: #FFFFFF;
        padding: 2rem 2rem 0rem 2rem;
        margin-top: 1rem;
        gap: 4px;
    }
`

const SubtitleText = styled.div`
    width: 100%;
    max-width: 500px;
    div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @media (width <= 1600px) {
        max-width: 400px;
    }

    @media (max-width: 768px) {
        div {
            white-space: normal;
            text-overflow: normal;
        }
        
    }
`

const CourseContainer = styled.div`
    height: 100%;
    max-height: ${(props) => props.maxHeight};
    width: 100%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
`

const ImageContainer = styled.div`
    height: 70%;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: var(--secondary-two);
    @media (max-width: 768px) {
        display: none;
    }
`

const CourseInfo = styled.div`
    width: calc(100% - 32px);
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    @media (max-width: 768px) {
        width: 100%;
        height: 80%;
        flex-direction: column;
        align-items: start;
        padding: 0;
    }
`

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 12px;
    width: 50%;
    height: 100%;
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        & > div:first-child {
            display: none;
        }
    }
`

const Progress = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
    @media (max-width: 768px) {
        height: 40px;
    }
`

const ProgressBar = styled.div`
    width: 80%;
    height: 16px;
    background-color: #c4c4c4;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 3px;
    @media (max-width: 768px) {
        height: 20px;
    }
`

const ProgressColorBar = styled.div`
    width: ${(p) => p.progress + "%"};
    height: 12px;
    background-color: #35D0A5;
    border-radius: 50px;
    @media (max-width: 768px) {
        height: 16px;
    }
`

const ContinueButton = styled.button`
    cursor: pointer;
    background-color: var(--primary-one);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 50%;
    max-width: 178px;
    height: 40px;
    padding: 0 16px;
    border-radius: 48px;
    font-size: 14px;
    z-index: 1;
    :hover {
        box-shadow: 0px 4px 4px 0px #00000040;
    }
    :disabled {
        box-shadow: none;
        cursor: not-allowed;
        background-color: var(--secondary-four);
    }
`

const AsideContainer = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:16px;
    @media (max-width: 768px) {
        flex-direction: row;
        width: calc(100% - 2.5rem);
        height: 45%;
        margin: 0 1rem 1rem 1rem;
    }
`

const AsideBox = styled.div`
    width: 100%;
    height: 50%;
    max-height: 170px;
    border-radius: 20px;
    background: ${(props) => props.backgroundWhite ? "#fff" : "linear-gradient(128.08deg, #FFFFFF 2.08%, rgba(255, 255, 255, 0) 103.51%)"};
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 50%;
        height: 100%;
        max-height: 160px;
        max-width: 50%;
        width: 145px;
        box-shadow: 0px 4px 4px 0px #00000040;
    }
`

const BoxTitle = styled.div`
    width: 100%;
    height: 20%;
    background-color: #f1f1f1;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonsContainer = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    p {
        text-align: center;
    }
`

const InfoButton = styled.button`
    height: 30px;
    min-width: 100px;
    max-width: 90%;
    padding: 4px 12px;
    border-radius: 100px;
    border: 1px solid ${(p) => p.strongColor};
    background-color: ${(p) => p.lightColor};
    font-size: 10px;
    font-weight: 600px;
    color: ${(p) => p.strongColor};

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    :hover{
        box-shadow: 0px 4px 4px 0px #00000040;
    }
`
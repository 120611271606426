import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "@mui/material";
import Text from "../../common/Text";
import { motivationsActions, interestsActions } from "../../../redux/actions";
import SaveSnackbar from "../../templates/profile/SaveSnackbar";
import ModalForm from "./ModalForm";
import { checkRoles } from "../../../helpers/roles.helper";

const CompleteProfile = () => {
  // REDUX
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // const utils = useSelector((state) => state);
  const motivations = useSelector((state) => state.motivations.motivations);
  const userMotivations = useSelector((state) => state.auth.user.motivaciones);

  const interests = useSelector((state) => state.interests.interests);
  const userInterests = useSelector((state) => state.auth.user.intereses);

  // STATE
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // EFFECTS
  useEffect(() => {
    if (motivations === null) {
      dispatch(motivationsActions.getMotivationsRequest());
    }
    if (interests === null) {
      dispatch(interestsActions.getInterestsRequest());
    }
  }, []);

  useEffect(() => {
    if (checkRoles(["alumno", "visita"])) {
      if (user.intereses.length === 0 && user.motivaciones.length === 0) {
        setTimeout(() => {
          openModal();
        }, 3000);
      }
    }
  }, [user.intereses, user.motivaciones]);

  // FUNCTIONS
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function handleModal() {
    setModalOpen(true);
  }

  // RETURN
  return (
    <>
      <Modal open={isOpen}>
        {!modalOpen ? (
          <Container>
            {/** Imagen */}
            <CustomImage
              loading="lazy"
              src="/assets/thinking-pana.svg"
              alt=""
            />
            {/** Texto */}
            <TextWrapper>
              <Text fontSize="1.5rem" fontWeight="700" color="primary">
                ¡Sácale provecho a tu experiencia en Acrópolis!
              </Text>
              <TextFake fontSize="1.2rem" fontWeight="400">
                ¿Sabías que Acrópolis se basa en{" "}
                <span>tus intereses y motivaciones</span> para ofrecerte
                contenido exclusivo para tí?
              </TextFake>
              <TextFake fontSize="1.2rem" fontWeight="400">
                ¡Tómate unos segundos para completarlos!
              </TextFake>
            </TextWrapper>
            {/* Botones */}
            <WrapperButtons>
              <Button color="secondary" variant="outlined" onClick={closeModal}>
                Lo haré más tarde
              </Button>
              <Button color="primary" variant="contained" onClick={handleModal}>
                ¡Personalizar mi experiencia ahora!
              </Button>
            </WrapperButtons>
          </Container>
        ) : (
          <ModalForm
            closeModal={closeModal}
            motivations={motivations}
            userMotivations={userMotivations}
            interests={interests}
            userInterests={userInterests}
          />
        )}
      </Modal>
      <SaveSnackbar />
    </>
  );
};

export default CompleteProfile;

const Container = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem;
  border-radius: 20px;
  width: 800px;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0rem;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const CustomImage = styled.img`
  width: 270px;
  height: 270px;
  @media (max-width: 768px) {
    width: 250px;
    margin-top: 2rem;
  }
  @media (max-width: 425px) {
    width: 200px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 1.5rem;
  width: 70%;
`;
const TextFake = styled(Text)`
  color: #616161;
  margin-top: 1rem;
  span {
    font-weight: bold;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 468px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

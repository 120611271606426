import React from "react";
import PropTypes from "prop-types";
import { Button as MatBtn } from "@mui/material";

const Button = (props) => {
  const {
    label,
    variant,
    handleClick,
    color,
    children,
    type,
    id,
    disabled,
    size,
    ...others
  } = props;
  return (
    <MatBtn
      onSubmitCapture={
        () => {}
        //console.log('on submit')
      }
      id={id}
      type={type}
      variant={variant}
      onClick={handleClick}
      color={color}
      disabled={disabled}
      size={size}
      {...others}
    >
      {children || label}
    </MatBtn>
  );
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  disabled: false,
  // handleClick: () => console.log('default'),
  type: "button",
  size: "medium",
};
Button.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Button;

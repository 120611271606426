import React from 'react'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useTheme from '../hooks/useTheme';
import { Text } from './Texts';
import useLayout from '../../../hooks/useLayout';

const GoBack = (props) => {

    const { title = ' ', course } = props;

    const { primary } = useTheme();
    const history = useHistory();
    const { menu } = useLayout()

    const handleGoBack = () => history.goBack();

    if (!title) {
        return <div/>
    }

    return (
        <NavAction onClick={handleGoBack} color={primary}>
            {/* <ArrowBackIcon /> */}
            <Text fontSize="1.2rem" fontWeight="700">
                {course === true && (
                    <Text fontSize="10px" fontWeight="400">
                        Asignatura
                        <br />
                    </Text>
                )}
                {menu || title}
            </Text>
        </NavAction>
    )
}

export default GoBack

const NavAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: start;
    gap: 15px;
    color: ${(p) => p.color};
    transition: all 0.3s ease-in-out;
    height: 70px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    svg {
        font-size: 20px;
    }
    :hover {
        filter: opacity(0.8);
    }
`;

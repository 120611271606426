import React, { useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";
import { Button, Dialog, InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import ReferredFilter from "./ReferredFilter";
import NotReferred from "./NotReferred";
import CardReferred from "./CardReferred";
import SimpleLoading from "../../../common/SimpleLoading";
import FormRefered from "../../../templates/refered/FormRefered";
import { getCountries } from "../../../../redux/api/utils";
import { useDispatch, useSelector } from "react-redux";
import GridCard from "../../../common/GridCard";
import { assignDracma } from "../../../../redux/api/dracma.api";
import { dracmaActions } from "../../../../redux/actions";

const DashboardReferred = (props) => {
  const { myReferred } = props;

  // STATES
  const [filterField, setFilterField] = useState("");
  const [currentState, setCurrentState] = useState("Todos");
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    !countries && getCountry();
  }, []);

  // FUNCTIONS
  const getCountry = async () => {
    try {
      const request = await getCountries();
      setCountries(request.data.response_data);
    } catch (error) {}
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const handleRefered = (values) => {
    setFiltered(values);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFilterField(value);
    handleRefered(
      myReferred.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleDragma = async () => {
    const body = {
      description: "Amigo referido",
      category: "Referidos",
      repositorio_partner_id: user.repo_id,
      points: 75,
      must_be_unique: false,
      // date: new Date(),
      is_acropolis_lab: false,
      acropolis_lab_id: "",
    };
    const request = await assignDracma(body);
    if (!request.error) {
      dispatch(dracmaActions.getDracmaRequest());
    }
  };

  function handleCurrentState(newValue) {
    setCurrentState(newValue);
    if (newValue === "Todos") handleRefered(myReferred);
    else handleRefered(myReferred.filter((user) => user.stage === newValue));
  }

  return (
    <Wrapper>
      <Header>
        <TextField
          value={filterField}
          onChange={handleChange}
          variant="outlined"
          size="small"
          label="Buscar"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleModal}
        >
          Añadir referido
        </Button>
      </Header>
      {/* INPUT DE BUSQUEDA */}
      <FilterWrapper>
        <ReferredFilter
          currentState={currentState}
          handleCurrentState={handleCurrentState}
        />
      </FilterWrapper>

      {myReferred === null ? (
        <SimpleLoading />
      ) : myReferred.lenght === 0 ? (
        <NotReferred />
      ) : (
        <GridCard cards={filtered || myReferred} component={CardReferred} />
      )}
      <Dialog open={open} onClose={handleModal}>
        <FormRefered
          countries={countries}
          student={user}
          handleDragma={handleDragma}
        />
      </Dialog>
    </Wrapper>
  );
};

export default DashboardReferred;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FilterWrapper = styled.div`
  width: 100%;
`;

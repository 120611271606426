import * as types from "../types";

const actions = {};

actions.tipoInconvenienteRequest = () => {
  return {
    type: types.TIPO_INCONVENIENTE_REQUEST,
  };
};

actions.tipoInconvenienteSuccess = (tipos) => {
  return {
    type: types.TIPO_INCONVENIENTE_SUCCESS,
    payload: tipos,
  };
};

actions.tipoInconvenienteFail = (error) => {
  return {
    type: types.TIPO_INCONVENIENTE_FAIL,
    payload: error,
  };
};

actions.reportInconvenienteRequest = (payload) => {
  return {
    type: types.REPORT_INCONVENIENTE_REQUEST,
    payload: payload,
  };
};

actions.reportInconvenienteSuccess = (payload) => {
  return {
    type: types.REPORT_INCONVENIENTE_SUCCESS,
    payload: payload,
  };
};

actions.reportInconvenienteFail = (error) => {
  return {
    type: types.REPORT_INCONVENIENTE_FAIL,
    payload: error,
  };
};

export { actions };

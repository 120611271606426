import React from "react";
import styled from "styled-components";
import CardAbout from "./about/CardAbout";
import InstructorsAbout from "./about/InstructorsAbout";
import SkillsAbout from "./about/SkillsAbout";

const AboutProduct = (props) => {
  const { about } = props;

  return (
    <Container>
      <InfoWrapper>
        <p>{about.info}</p>
      </InfoWrapper>
      <CardAbout
        duration={about.duration}
        modality={about.modality}
        certificate={about.certificate}
        networking={about.networking}
      />
      <SkillsAbout skills={about.skill} />
      <InstructorsAbout instructors={about.instructors} />
    </Container>
  );
};

export default AboutProduct;
const Container = styled.div`
  margin: 1rem;
`;

const InfoWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  p {
    font-size: 1rem;
    line-height: 140%;
  }
`;

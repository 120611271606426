import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import Button from "../../../../../components/ui/atoms/Button";
import { Text, Span } from "../../../../../components/common/Texts";
import DefaultCardIcon from "../../../../../components/common/DefaultCardIcon";
import Attempts from "./Attempts";

// Material UI
import AccessTime from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SchoolIcon from "@mui/icons-material/School";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import { Icon } from "@iconify/react/dist/iconify.js";

const PageInitial = (props) => {
	const {
		description,
		time,
		questions,
		attempts,
		handleStartExam,
		delivery,
		note,
		isReview,
		submissions,
	} = props;

	const dateAttempt = !!delivery[0]?.create_date
		? new Date(delivery[0]?.create_date).toLocaleDateString()
		: "-";

	const sanitizerHtml = (html) => {
		const string = 'src="/';
		const regex = new RegExp(string, "g");
		return html.replace(regex, 'src="');
	};

	return (
		<WrapperInitial>
			<Header>
				<LeftContent>
					<DefaultCardIcon
						icon={
							submissions.length === 0 ? (
								<AccessTime />
							) : (
								<CheckCircleOutlineIcon />
							)
						}
						color={submissions.length === 0 ? "#CEB269" : "#3BD39C"}
						title={submissions.length === 0 ? "Pendiente" : "Entregado"}
						subtitle="Estado entrega"
					/>
					{submissions.length > 0 && (
						<DefaultCardIcon
							icon={<SchoolIcon />}
							color="#3BD39C"
							title={`${submissions.sort((a, b) => {
								return b.grade - a.grade;
							})[0]?.grade
								}/${note}`}
							subtitle="Nota entrega"
						/>
					)}
				</LeftContent>
				<RightContent>
					<DefaultCardIcon
						icon={<CalendarTodayIcon />}
						color={delivery.length === 0 ? "#CEB269" : "#5E80DB"}
						subtitle={"Fecha de entrega"}
						title={dateAttempt}
					/>
					<DefaultCardIcon
						icon={<ContactSupportIcon />}
						color="#5E80DB"
						subtitle={"Preguntas"}
						title={questions}
					/>
					<DefaultCardIcon
						icon={<RestorePageIcon />}
						color="#5E80DB"
						subtitle={"Intentos"}
						title={attempts === 100 ? "Ilimitado" : attempts < 0 ? 0 : attempts}
					/>
					<DefaultCardIcon
						icon={<AccessAlarmIcon />}
						color="#5E80DB"
						subtitle={"Tiempo"}
						title={!time ? "Sin límite" : `${time} min`}
					/>
				</RightContent>
			</Header>
			<ContainerInitial>
				<Text color="#B31D15" fontSize="18px" fontWeight="700">
					Instrucciones
				</Text>

				<DescriptionInitial>
					<ParseHtml>{parse(sanitizerHtml(description))}</ParseHtml>
				</DescriptionInitial>

				<Text color="#B31D15" fontSize="18px" fontWeight="700">
					Tus intentos
				</Text>

				<AttemptsWrapper>
					{delivery.length === 0 ? (
						<TextFake fontSize="16px" color="#A8A8A8">
							Sin intentos registrados
						</TextFake>
					) : (
						<Attempts submissions={submissions} note={note} />
					)}
				</AttemptsWrapper>
				<Start>
					<Text color="#616161" fontSize="16px">
						Tienes{" "}
						<Span fontWeight="bold">
							{attempts === 100 ? "ilimitado" : attempts < 0 ? 0 : attempts}
						</Span>{" "}
						intentos restantes
					</Text>
					{delivery.length > 0 && !!delivery[0]?.review && (
						<Text color="#616161" fontSize="16px">
							¡No puedes volver a realizar el examen ya que has visto la
							revisión!
						</Text>
					)}
					<Button
						disabled={
							attempts <= 0 ||
								(delivery.length > 0 && !!delivery[0]?.review) ||
								!!isReview
								? true
								: false
						}
						handleClick={handleStartExam}
						style={{ backgroundColor: "var(--primary-one)" }}
						label="Realizar actividad"
					/>
				</Start>
			</ContainerInitial>
			<InfoContainer>
				<Icon icon="octicon:info-24" width="24px" height="24px" />
				<label>
					Ten en cuenta que para que la plataforma registre este examen como visto, <b>deberás realizarlo.</b>
				</label>
			</InfoContainer>
		</WrapperInitial>
	);
};

export default PageInitial;

const WrapperInitial = styled.div`
	width: 100%;
	height: 100%;
`;

const ContainerInitial = styled.div`
  width: calc(100% - 40px);
  height: 100%;
  padding: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Header = styled.div`
  height: auto;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const DescriptionInitial = styled.span`
  min-height: 200px;
  max-height: 380px;
  overflow-y: auto;
  height: auto;
`;

const AttemptsWrapper = styled.div`
  width: 100%;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  min-height: 70px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextFake = styled(Text)`
  font-style: italic;
`;

const Start = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

const ParseHtml = styled.div`
  width: 100%;
  max-width: auto;
  /* margin: 1rem; */
  /* Estilos html string */
`;

const LeftContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
`;

const RightContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;
	margin: 1rem;

    svg {
        color: #C29F43;
    }
`
import * as types from "../types/subject_types";

const initialState = {
  //SUBJECTS
  enrollment: null,
  tags: null,
  error: false,
  loading: false,
  //PAGINATION
  currentPage: 0,
  pagination: null,
  paginationLoading: false,
  errorPagination: null,
  //DIRECTORIES
  directories: null,
  listLoading: false,
  errorList: null,
};

export const subjects = (state = initialState, action) => {
  switch (action.type) {
    // GET SUBJECTS
    case types.GET_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        enrollment: action.payload.enrollments,
        tags: action.payload.general_tags,
        error: false,
        loading: false,
      };
    case types.GET_SUBJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    //Pagination
    case types.GET_SUBJECT_PAGINATION_REQUEST:
      return {
        ...state,
        paginationLoading: true,
        errorPagination: null,
      };
    case types.GET_SUBJECT_PAGINATION_SUCCESS:
      const data = action.payload;
      const currentPage = data.contactos > 0 ? 1 : 0;
      const response = {
        totalPages: data.total_paginas,
        totalContacts: data.contactos,
        pages: data.paginas,
      };
      return {
        ...state,
        currentPage: currentPage,
        paginationLoading: false,
        pagination: response,
        errorPagination: null,
      };
    case types.GET_SUBJECT_PAGINATION_FAIL:
      return {
        ...state,
        paginationLoading: false,
        errorPagination: action.payload,
      };

    //CurrentPage
    case types.NEXT_SUBJECT_PAGE:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case types.PREVIOUS_SUBJECT_PAGE:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case types.CHANGE_SUBJECT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    //Directories
    case types.GET_SUBJECT_DIRECTORIES_REQUEST:
      return {
        ...state,
        directories: [],
        listLoading: true,
        errorList: null,
      };
    case types.GET_SUBJECT_DIRECTORIES_SUCCESS:
      const directoryData = action.payload;
      return {
        ...state,
        listLoading: false,
        directories: directoryData,
        errorList: null,
      };
    case types.GET_SUBJECT_DIRECTORIES_FAIL:
      return {
        ...state,
        listLoading: false,
        errorList: action.payload,
      };
    default:
      return state;
  }
};

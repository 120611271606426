import * as types from "../types";

const initialState = {
  advisor: null,
  listLoading: false,
  errorList: null,
};

export const advisor = (state = initialState, action) => {
  switch (action.type) {
    // GET FINANCES
    case types.GET_ACADEMIC_ADVISOR_REQUEST:
      return {
        ...state,
        listLoading: true,
        errorList: null,
      };
    case types.GET_ACADEMIC_ADVISOR_SUCCESS:
      return {
        ...state,
        listLoading: false,
        advisor: action.payload,
        errorList: null,
      };
    case types.GET_ACADEMIC_ADVISOR_FAIL:
      return {
        ...state,
        listLoading: false,
        errorList: action.payload,
      };
    default:
      return state;
  }
};

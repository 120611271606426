import React, { useContext } from "react";
import styled from "styled-components";

// Components
import MainCourse from "./components/mainCourse/MainCourse";
import AsideCourse from "./components/asideCourse/AsideCourse";
import CourseSkeleton from "./components/CourseSkeleton";
import DialogCertificate from "../../components/ui/molecules/studyPlan/DialogCertificate";

// Material UI
import { MenuRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

// Assets
import { ReactComponent as NotFoundSvg } from "../../assets/media/svg/404-Error-pana.svg";

// Context
import CourseContext from "./context/CourseContext"

const CourseTemplate = () => {

    const {
        currentMedia,
        certificateEnabled,
        showAside,
        openAside,
        workshop,
        toggleAside,
        progress
    } = useContext(CourseContext)

    /** RETURN ----------------------------------------------------------------------- */
    if (currentMedia === null) {
        return <CourseSkeleton />;
    }

    if (currentMedia === false) {
        return (
            <DataEmpty>
                <Container>
                    <NotFoundSvg width="50%" />
                    <Title>{workshop.nombre_publicacion}</Title>
                    <Description>
                        No hay recursos en el objeto de aprendizaje
                    </Description>
                </Container>
            </DataEmpty>
        );
    }

    return (
        <>
            <CoursePageContainer>
                <MainCourseWrapper openAside={openAside} showAside={showAside}>
                    <MainCourse>
                        <IconButton onClick={toggleAside} size="large">
                            <MenuRounded htmlColor="#ffffff" />
                        </IconButton>
                    </MainCourse>
                </MainCourseWrapper>
                <AsideWrapper showAside={showAside}>
                    <AsideCourse />
                </AsideWrapper>
                {workshop.certificado === true &&
                    progress === 100 &&
                    !!certificateEnabled && (
                        <DialogCertificate
                            setOpenTest={false}
                            course={workshop}
                            type={"workshop"}
                        />
                    )}
            </CoursePageContainer>
        </>
    );
};

export default CourseTemplate;

const CoursePageContainer = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const MainCourseWrapper = styled.div`
    flex-basis: ${(props) => props.showAside ? "75%" : "calc(75% + 200px)"};
    transition: ${(props) => !props.showAside && "flex-basis .5s ease"};

    @media (max-width: 1400px) {
        flex-basis: 70%;
    }

    @media (max-width: 900px) {
        flex-basis: 100%;
    }
`;

const AsideWrapper = styled.div`
    flex-basis: ${(props) => props.openAside ? "25%" : "200px"};
    transition: ${(props) => !props.openAside && "flex-basis .5s ease"};

    @media (max-width: 1400px) {
        flex-basis: 30%;
    }

    @media (max-width: 900px) {
        flex-basis: 100%;
    }
`;

const DataEmpty = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled.div`
    font-size: 1.2rem;
    color: #b31d15;
    font-weight: 600;
`;

const Description = styled.div`
    font-size: 1rem;
    color: #555555;
    font-weight: 600;
`;
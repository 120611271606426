import { useState } from "react";
import { postDeleteNote } from "../redux/api/notesCourseApi";

const useDeleteNote = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const deleteNote = async (id) => {
        setError(null);
        setLoading(true);
        try {
            await postDeleteNote(id);

            setError(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    return { deleteNote, error, loading };
};

export default useDeleteNote;

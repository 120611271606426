import * as types from "../types";

const initialState = {
  // Courses
  courses: null,
  listLoading: false,
  errorList: null,
  loadMoreLoading: false,
  errorLoadMore: null,
  hasMore: true,
  // Students
  students: null,
  studentsLoading: false,
  errorStudents: null,
  // Teachers
  teachers: null,
  teachersLoading: false,
  errorTeachers: null,
  // Enrrolment
  enrollLoading: false,
  errorEnroll: null,
  snack: {
    snackMessage: "",
    snackShow: false,
    snackType: "",
  },
};

export const courses = (state = initialState, action) => {
  switch (action.type) {
    // GET COURSES
    case types.GET_COURSES_REQUEST:
      return {
        ...state,
        listLoading: true,
        errorList: null,
      };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        listLoading: false,
        courses: action.payload,
      };
    case types.GET_COURSES_FAIL:
      return {
        ...state,
        listLoading: true,
        errorList: action.payload,
      };
    // GET MORE COURSES
    case types.GET_MORECOURSES_REQUEST:
      return {
        ...state,
        loadMoreLoading: true,
        errorLoadMore: null,
      };
    case types.GET_MORECOURSES_SUCCESS:
      if (action.payload.length === 0) {
        return {
          ...state,
          loadMoreLoading: false,
          hasMore: false,
        };
      } else {
        return {
          ...state,
          loadMoreLoading: false,
          courses: [...state.courses, ...action.payload],
        };
      }
    case types.GET_MORECOURSES_FAIL:
      return {
        ...state,
        loadMoreLoading: false,
        errorLoadMore: action.payload,
      };
    // GET STUDENTS
    case types.GET_STUDENTS_REQUEST:
      return {
        ...state,
        studentsLoading: true,
        errorStudents: null,
      };
    case types.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        studentsLoading: false,
        students: action.payload,
      };
    case types.GET_STUDENTS_FAIL:
      return {
        ...state,
        studentsLoading: false,
        errorStudents: action.payload,
      };
    // GET TEACHERS
    case types.GET_TEACHERS_REQUEST:
      return {
        ...state,
        teachersLoading: true,
        errorTeachers: null,
      };
    case types.GET_TEACHERS_SUCCESS:
      return {
        ...state,
        teachersLoading: false,
        teachers: action.payload,
      };
    case types.GET_TEACHERS_FAIL:
      return {
        ...state,
        teachersLoading: false,
        errorTeachers: action.payload,
      };
    // ENROLL COURSE
    case types.ENROLL_COURSE_REQUEST:
      return {
        ...state,
        enrollLoading: true,
        errorEnroll: null,
      };
    case types.ENROLL_COURSE_SUCCESS:
      const newCourses = state.courses.map((course) => {
        if (course.curso_id === action.payload) {
          const newCourse = { ...course, estado: "Confirmado" };
          return newCourse;
        } else {
          return course;
        }
      });
      return {
        ...state,
        enrollLoading: false,
        courses: newCourses,
        errorEnroll: "flag",
      };
    case types.ENROLL_COURSE_FAIL:
      return {
        ...state,
        enrollLoading: false,
        errorEnroll: action.payload,
      };
    case types.FETCH_STATE:
      return {
        ...state,
        enrollLoading: false,
        errorEnroll: null,
        errorList: null,
      };
    default:
      return state;
  }
};

import { Card } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import TabsComponent from "../../common/TabsComponent";
// import InfoCard from '../../ui/organisms/educativeOffer/InfoCard';
// import Products from '../../ui/organisms/educativeOffer/Products';
import VideosSupport from "../../ui/organisms/support/VideosSupport";

const MainSupport = () => {
  const tabs = [
    { label: "Tutoriales", id: 2 },
    // { label: `Carreras`, id: 0, disabled: true },
    // { label: `Cursos`, id: 1, disabled: true },
    // { label: `Especializaciones`, id: 3, disabled: true },
    // { label: `Másters`, id: 4, disabled: true },
  ];

  const [tab, setTab] = useState(2);

  // FUNCTIONS
  const handleTab = (e, value) => {
    setTab(value);
  };

  return (
    <Container>
      <TabsWrapper>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </TabsWrapper>
      <ContentWrapper>
        {/* <CardWrapper>
                    <InfoCard/>
                </CardWrapper> */}
        <CardWrapper>
          {/* <Products/> */}
          <VideosSupport />
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default MainSupport;

const Container = styled.div`
  position: relative;
`;
const TabsWrapper = styled.div`
  width: calc(100% - 6rem - 70px);
  position: fixed;
  left: 70px;
  top: 70px;
  z-index: 3;
  border-top: 1px solid #f7f7f7;
  padding: 0 3rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    left: 0;
    width: calc(100% - 6rem);
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
`;

const CardWrapper = styled(Card)`
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
`;

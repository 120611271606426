import Button from "@mui/material/Button";
import React, { useState } from "react";
import styled from "styled-components";
import ModalActiveAccount from "./ModalActiveAccount";

const ActivateAccount = (props) => {

    const { primaryColor } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ModalActiveAccount
                primaryColor={primaryColor}
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
            />
            <Wrapper
            color={primaryColor}
            >
                <Legend>
                    <p>¿Aún no tienes tu cuenta activa?</p>
                </Legend>
                <ButtonFake
                    onClick={handleOpenModal}
                    variant="contained"
                    color="primary"
                >
                    Activar mi cuenta
                </ButtonFake>
            </Wrapper>
        </>
    );
};

export default ActivateAccount;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    align-items: center;
    color: ${(p) => p.color};
    border-color: ${(p) => p.color};
    z-index: 10;

    @media (max-width: 1200px) {
        width: 70%;
    }

    @media (max-width: 768px) {
        width: 80%;
    }
`;

const Legend = styled.div`
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;

    p {
        text-align: center;
        color: #000;
        font-weight: 600;

        @media (max-width: 768px) {
            font-size: 14px;
            font-weight: 700;
        }   
    }
`;

const ButtonFake = styled(Button)`
    border-radius: 100px;
    width: 50%;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #fff !important;
    color: inherit;
    border: 1px solid currentColor;
    box-shadow: none;
    :hover {
        box-shadow: 0px 7px 10px -5px rgba(0,0,0,0.5);
        -webkit-box-shadow: 0px 7px 10px -5px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 7px 10px -5px rgba(0,0,0,0.5);
    }
    @media (max-width: 768px) {
        width: 60%;
        font-size: 12px;
        font-weight: 600;
    }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useReducer, useContext } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import * as encode from "nodejs-base64-encode";
import { Icon } from "@iconify/react";

// Components
import NavegationBottom from "./NavegationBottom";
import MultipleChoice from "./MultipleChoice";
import FooterResource from "./FooterResource";
import Presentation from "./Presentation";
import MeetingComponent from "./MeetingComponent";
import ModalProblem from "../../../../components/common/ModalProblem";
import VimeoPlayer from "../../../../components/common/VimeoPlayer"
import SimpleLoading from "../../../../modules/acropolisCommon/components/SimpleLoading";

// Hooks
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { useVideo } from "../../../../hooks/useVideo";
import useTheme from "../../../../modules/acropolisCommon/hooks/useTheme";

// Redux
import { useSelector } from "react-redux";
import {
    getDataEvaluationService,
    viewResource,
} from "../../../../redux/api/courses";

// Helpers
import {
    concatUrl,
    formatUrlFrame,
} from "../../../../helpers/pdfGoogleFormated.helper";
import { userAction } from "../../../../helpers/userActions.helper";

// Assets
import { ReactComponent as NotFoundSvg } from "../../../../assets/media/svg/404-Error-pana.svg";

// Context
import CourseContext from "../../context/CourseContext"
import RiseScorm from "./resources/RiseScorm";

const MainCourse = () => {

    const {
        currentMedia: {
            id,
            nombre_publicacion,
            formato,
            link_video,
            link_storage,
            id_recurso,
            id_evaluacion,
            orden,
            url_padlet,
            subtipo,
            direct_link_vimeo_720,
            direct_link_vimeo_1080,
            link_interactivo,
            descripcion_html,
            youtube_id,
        },
        objId,
        handleCheckResource,
        showAside,
        setShowAside,
        setTabClose,
        handleCurrentSecond,
        onFinish,
        handleNext,
        valuesNotes,
        nextResource
    } = useContext(CourseContext)

    const CASES_TO_EXCLUDE = ["NetSelfie", "NetChat"];

    const params = useQueryParams();
    const assignmentId = params.get("id");
    const decodedId = encode.decode(assignmentId ? assignmentId : "", "base64");
    const { activeRol } = useSelector((state) => state.auth);

    // STATE
    const [state, dispatchState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            isOpen: false,
            tutorIsOpen: false,
            evaluation: null,
            presentation: null,
            displayCard: true,
            isViewVideo: false,
        }
    );

    // Reproductor de video
    const playerRef = useRef(null);
    const watchedHalf = useRef(false);

    // Video
    const {
        nextVideo,
        setNextVideo,
        setStopVideo,
    } = useVideo();

    // Tema
    const { primary } = useTheme();

    // REDUX
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatchState({
            evaluation: null,
            presentation: null,
            isViewVideo: false,
        });

        setNextVideo(false);
        setStopVideo(false);
        // Actividad
        userAction({
            object_id: id + "",
            object: nombre_publicacion,
            name: nombre_publicacion,
            activity: "Visualizar recurso",
            medio: "Ver recurso",
        });

        if (formato === "audiovisual") blockDownloadVideo();
    }, [nombre_publicacion, id, orden]);

    useEffect(() => {
        if (state?.evaluation === null && formato === "evaluacion") {
            getDataEvaluation({
                id: id_evaluacion,
                user: user.repo_id,
                obj: objId,
                reso: id_recurso,
                assignmentId: decodedId,
            });
        }
    }, [formato, id_evaluacion, state?.evaluation]);

    useEffect(() => {
        if (state?.presentation === null && formato === "presentacion") {
            getDataPresentation({
                id: id_evaluacion,
                user: user.repo_id,
                obj: objId,
                reso: id_recurso,
                assignmentId: decodedId,
            });
        }
    }, [formato, id_evaluacion, state?.presentation]);

    useEffect(() => {
        if (
            state?.isViewVideo ||
            formato === "material_plano" ||
            subtipo === "Youtube"
        ) {
            formato !== "presentacion" &&
                formato !== "evaluacion" &&
                submitViewResource();
        }
    }, [state?.isViewVideo, formato]);

    // YOUTUBE
    const opts = {
        height: "630px",
        width: "100%",
        playerVars: {
            autoplay: 1,
        },
    };

    // PARA YOUTUBE
    const onReady = (event) => {
        playerRef.current = event.target;
    };

    const onStateChange = () => {
        const player = playerRef.current;
        const duration = player.getDuration();
        const currentTime = player.getCurrentTime();

        if (!watchedHalf.current && currentTime >= duration / 2) {
            watchedHalf.current = true;
        }
    };

    // OTRAS FUNCIONES
    /* function handleOpenModal() {
        dispatchState({ isViewVideo: true });
    } */

    function handleCloseModal() {
        dispatchState({ isViewVideo: false });
    }

    const getDataEvaluation = async (data) => {
        const response = await getDataEvaluationService(data);
        if (!response) {
            dispatchState({ evaluation: false });
        } else {
            dispatchState({ evaluation: { ...response, tiempo: false } });
        }
    };

    const getDataPresentation = async (data) => {
        const response = await getDataEvaluationService(data);
        if (!response) {
            dispatchState({ presentation: false });
        } else {
            dispatchState({ presentation: { ...response, tiempo: false } });
        }
    };

    const handleNewTry = () => {
        dispatchState({ evaluation: null });
        dispatchState({ presentation: null });
    };

    const blockDownloadVideo = () => {
        const player = document.getElementById("video-react");
        if (player !== null) player.oncontextmenu = () => false;
    };

    const videoResolution = [
        {
            src: direct_link_vimeo_720,
            type: "video/mp4",
            label: "720p",
            res: 720,
        },
        {
            src: direct_link_vimeo_1080,
            type: "video/mp4",
            label: "1080p",
            res: 1080,
        },
    ];

    // Renderizado de material
    const renderTypeResources = () => {
        if (formato === "evaluacion") {
            return (
                <EvaluationWrapper>
                    {state?.evaluation === null ? (
                        <h1>Cargando...</h1>
                    ) : state?.evaluation === false ? (
                        <MessageErrorRequest>
                            <NotFoundSvg width="50%" />
                            No se pudo cargar la evaluación
                        </MessageErrorRequest>
                    ) : (
                        <>
                            <HeaderRender bg={primary} textColor="#fff">
                                {nombre_publicacion}
                            </HeaderRender>
                            <MultipleChoice
                                evaluationData={state?.evaluation}
                                handleNewTry={handleNewTry}
                                submitViewResource={submitViewResource}
                            />
                        </>
                    )}
                </EvaluationWrapper>
            );
        } else if (formato === "audiovisual" && subtipo === "Meeting") {
            return (
                <MeetingComponent />
            );
        } else if (formato === "audiovisual" && subtipo === "h5p") {
            return (
                <IframeWrapper>
                    <Iframe
                        onLoad={handleOnLoadMaterial}
                        src={link_interactivo}
                        frameBorder="0"
                        allowFullScreen
                    />
                </IframeWrapper>
            );
        } else if (formato === "audiovisual") {
            if (
                (videoResolution[0].src !== "" ||
                    videoResolution[1].src !== "") &&
                subtipo !== "Youtube"
            ) {
                return (
                    <VideoWrapper>
                        <HeaderRender bg="#f1f1f1">
                            {nombre_publicacion}
                            {
                                activeRol === "ondemand" &&
                                <ShowAsideButton onClick={() => {
                                    setShowAside(!showAside)
                                    setTabClose(4)
                                }}>
                                    {
                                        showAside ? <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)" }} />
                                            : <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)", transform: "rotate(180deg)" }} />
                                    }
                                </ShowAsideButton>
                            }
                        </HeaderRender>
                        <VimeoPlayer
                            videoLink={link_video}
                            valuesNotes={valuesNotes}
                            nextResource={nextResource}
                            onProgress={handleFinishVideo}
                            handleCurrentSecond={handleCurrentSecond}
                            onFinish={onFinish}
                            handleNext={handleNext}
                            nextVideo={nextVideo}
                            CASES_TO_EXCLUDE={CASES_TO_EXCLUDE}
                            dispatchState={dispatchState}
                        />
                        <InfoContainer>
                            <Icon
                                icon="octicon:info-24"
                                width="24px"
                                height="24px"
                            />
                            <label>
                                Ten en cuenta que para que la plataforma
                                registre este recurso como visto,{' '}
                                <b>deberás verlo completo.</b>
                            </label>
                        </InfoContainer>
                    </VideoWrapper>
                );
            } else if (subtipo === "Youtube") {
                return (
                    <YouTube
                        videoId={youtube_id}
                        onReady={onReady}
                        opts={opts}
                        onStateChange={onStateChange}
                    />
                );
            } else {
                return (
                    <MessageErrorRequest>
                        <NotFoundSvg width="50%" />
                        No se puede visualizar el video
                    </MessageErrorRequest>
                );
            }
        } else if (
            formato === "material_plano" &&
            subtipo === "Gamification-tools" &&
            link_storage.toLowerCase().includes("repo_id_aden")
        ) {
            return (
                <IframeWrapper>
                    {link_storage !== "" ? (
                        <Iframe
                            onLoad={handleOnLoadMaterial}
                            src={link_storage.replace(
                                "repo_id_aden",
                                `repo_id=${user.repo_id}`
                            )}
                            frameBorder="0"
                            allowFullScreen
                        />
                    ) : (
                        <MessageErrorRequest>
                            <NotFoundSvg width="300px" />
                            No se puede visualizar el material
                        </MessageErrorRequest>
                    )}
                </IframeWrapper>
            );
        } else if (formato === "material_plano" && subtipo === "Página Web") {
            return (
                <>
                    <HeaderRender bg="#f1f1f1">
                        {nombre_publicacion}
                        <ShowAsideButton onClick={() => {
                            setShowAside(!showAside)
                            setTabClose(4)
                        }}>
                            {
                                showAside ? <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)" }} />
                                    : <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)", transform: "rotate(180deg)" }} />
                            }
                        </ShowAsideButton>
                    </HeaderRender>
                    <IframeWrapper>
                        {link_storage !== "" ? (
                            <Iframe
                                onLoad={handleOnLoadMaterial}
                                src={
                                    subtipo !== "h5p"
                                        ? formatUrlFrame(link_storage) +
                                        `${concatUrl(link_storage)}id=${user.repo_id
                                        }&object=${objId}&resource=${id}`
                                        : link_storage
                                }
                                frameBorder="0"
                                allowFullScreen
                            />
                        ) : descripcion_html !== "" ? (
                            <HtmlContainer
                                onLoad={handleOnLoadMaterial}
                                dangerouslySetInnerHTML={{
                                    __html: descripcion_html,
                                }}
                            />
                        ) : (
                            <MessageErrorRequest>
                                <NotFoundSvg width="300px" />
                                No se puede visualizar el material
                            </MessageErrorRequest>
                        )}
                    </IframeWrapper>
                </>

            );
        } else if (formato === "material_plano") {
            if (subtipo === "Rise - Scorm con retorno") {
                return <RiseScorm
                    title={nombre_publicacion}
                    onLoad={handleOnLoadMaterial}
                    url={link_storage + `${concatUrl(link_storage)}id=${user.repo_id}&object=${objId}&resource=${id}`} />
            }
            return (
                <>
                    <HeaderRender bg="#f1f1f1">
                        {nombre_publicacion}
                        <ShowAsideButton onClick={() => {
                            setShowAside(!showAside)
                            setTabClose(4)
                        }}>
                            {
                                showAside ? <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)" }} />
                                    : <Icon icon="fluent:arrow-export-20-filled" width="36px" height="36px" style={{ color: "var(--primary-one)", transform: "rotate(180deg)" }} />
                            }
                        </ShowAsideButton>
                    </HeaderRender>
                    <IframeWrapper>
                        {link_storage !== "" ? (
                            <Iframe
                                onLoad={handleOnLoadMaterial}
                                src={
                                    subtipo !== "h5p"
                                        ? formatUrlFrame(link_storage) +
                                        `${concatUrl(link_storage)}id=${user.repo_id
                                        }&object=${objId}&resource=${id}`
                                        : link_storage
                                }
                                frameBorder="0"
                                allowFullScreen
                            />
                        ) : descripcion_html !== "" ? (
                            <HtmlContainer
                                onLoad={handleOnLoadMaterial}
                                dangerouslySetInnerHTML={{
                                    __html: descripcion_html,
                                }}
                            />
                        ) : (
                            <MessageErrorRequest>
                                <NotFoundSvg width="300px" />
                                No se puede visualizar el material
                            </MessageErrorRequest>
                        )}
                    </IframeWrapper>
                    <InfoContainer>
                        <Icon
                            icon="octicon:info-24"
                            width="24px"
                            height="24px"
                        />
                        <label>
                            Ten en cuenta que para que la plataforma
                            registre este recurso como visto,{' '}
                            <b>deberás verlo.</b>
                        </label>
                    </InfoContainer>
                </>
            );
        } else if (formato === "presentacion") {
            return (
                <EvaluationWrapper>
                    {state?.presentation === null ? (
                        <SimpleLoading />
                    ) : state?.presentation === false ? (
                        <MessageErrorRequest>
                            <NotFoundSvg width="50%" />
                            No se pudo cargar la evaluación
                        </MessageErrorRequest>
                    ) : (
                        <Presentation
                            presentation={state?.presentation}
                            handleNewTry={handleNewTry}
                            submitViewResource={submitViewResource}
                        />
                    )}
                </EvaluationWrapper>
            );
        } else {
            return <h4>Tipo de recurso desconocido</h4>;
        }
    };

    // Manejar avance del alumno
    const handleOnLoadMaterial = () => {
        submitViewResource();
    };

    const handleFinishVideo = (currentSecond, totalTime) => {
        let progress = 0;
        if (totalTime > 0) {
            progress = Math.round((currentSecond * 100) / totalTime);
            // Marcar actividad como vista
            if (progress > 50) {
                dispatchState({ isViewVideo: true });
            }
            // Levantar cartel de proximo recurso
            if (Math.round(totalTime) - 10 - Math.round(currentSecond) <= 0) {
                setNextVideo(true);
            }
        }
    };

    const submitViewResource = async () => {
        const request = await viewResource({
            user: user.repo_id,
            resource: id || id_recurso,
            obj: objId,
        });
        if (request.error) return;
        handleCheckResource(nombre_publicacion);
    };

    /** RETURN ------------------------------------------------ */
    return (
        <MainCourseContainer>
            <ModalProblem
                isOpen={state?.isOpen}
                handleCloseModal={handleCloseModal}
                idCurrentMedia={id}
            />
            <ContentWrapper format={formato}>
                {/* Renderiza tipos de recursos */}
                <NavegationWrapper>
                    {renderTypeResources()}
                    <NavegationBottom
                    /* handleOpenModal={handleOpenModal} */
                    />
                </NavegationWrapper>
                {
                    activeRol === "ondemand" ?
                        url_padlet && <FooterResource padlet={url_padlet} />
                        :
                        <FooterResource padlet={url_padlet} />
                }
            </ContentWrapper>
        </MainCourseContainer>
    );
};

export default MainCourse;

const MainCourseContainer = styled.div`
    height: 100%;
`;

const ContentWrapper = styled.div`
    padding: 2rem 2.5rem 0 2.5rem;
    margin-bottom: 4rem;
    display: grid;
    gap: 15px;
    @media (max-width: 768px) {
        padding: 1.5rem;
    }
`;

const NavegationWrapper = styled.div`
    /* min-height: 120%; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 0;
`;

const EvaluationWrapper = styled.div`
    position: relative;
    /* height: 100%; */
    margin-bottom: 15px;
`;

const IframeWrapper = styled.div`
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25;
    height: 0;
`;

const HtmlContainer = styled.div`
    /* @import url("https://cdns-varios.pages.dev/styles/common-04107f0c72.css");
    @import url("https://cdns-varios.pages.dev/styles/new_user_tutorials-2950c871dd.css");
    @import url("https://cdns-varios.pages.dev/styles/adenuniversity-and-bootstrap-ns-2022.css"); */
    /* width: fit-content; */
    /* scale: 0.99; */
    /* height: fit-content !important; */
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const MessageErrorRequest = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #b31d15;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;
`;

const HeaderRender = styled.div`
    position: relative;
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 40px;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => p.bg};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: ${(p) => (p.textColor ? p.textColor : "#000")};
`;

const ShowAsideButton = styled.div`
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 10px;
    border-radius: 40px;
    background-color: var(--secondary-two);
    :hover {
        background-color: #e5e5e5;
    }
`

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;

    svg {
        color: #c29f43;
    }
`;
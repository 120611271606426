import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const MotivationsCard = (props) => {
  const { id, name, checked, setFieldValue, values } = props;

  // Function
  const handleCheck = (id) => {
    if (checked) {
      let newvalues = values.motivaciones.filter((x) => x !== id);
      setFieldValue("motivaciones", newvalues);
    } else {
      setFieldValue("motivaciones", [...values.motivaciones, id]);
    }
  };

  return (
    <CardFake checked={checked} onClick={() => handleCheck(id)}>
      <Header>
        <CustomImg src={`/assets/motivaciones/${id}.svg`} />
      </Header>
      <TextFake fontSize="1.2rem" fontWeight="700" textColor="#222222">
        {name}
      </TextFake>
      <CheckCircleIconFake checked={checked} />
    </CardFake>
  );
};

export default MotivationsCard;

const CardFake = styled(Card)`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  transition: all ease 0.5s;
  background-color: ${(p) => (p.checked ? "#F7FDFB" : "#FFFFFF")};
  &:hover {
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
      0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    cursor: pointer;
    margin-top: -0.3rem;
  }
`;

const Header = styled.div`
  margin: 1rem auto;
  text-align: center;
`;

const CustomImg = styled.img`
  width: 80%;
  height: 100px;
  margin: 0 auto;
`;

const TextFake = styled(Text)`
  margin: 1rem;
  text-align: center;
`;

const CheckCircleIconFake = styled(CheckCircleIcon)`
  position: absolute;
  color: #3bd39c;
  font-size: 1.2rem;
  top: 15px;
  right: 15px;
  transition: all ease 0.3s;
  opacity: ${(p) => (p.checked ? "100%" : "0%")};
`;

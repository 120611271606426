import React from "react";
import styled from "styled-components";

import { ErrorMessage, Field } from "formik";
import { TextField } from "@mui/material";

const DefaultField = (props) => {
  const { name, type, label, placeholder, variant = "standard" } = props;

  // RETURN
  return (
    <Field
      as={TextField}
      name={name}
      helperText={<ErrorMessage name={name} component={CustomError} />}
      type={type}
      label={label}
      variant={variant}
      placeholder={placeholder}
      size="small"
      fullWidth
      {...props}
    />
  );
};

export default DefaultField;

const CustomError = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: #591AB6;
`;

import React from "react";
import styled from "styled-components";

import { Modal } from "@mui/material";
import HeaderModalEditProfile from "../../ui/molecules/profile/HeaderModalEditProfile";
import BodyModalEditProfile from "../../ui/molecules/profile/BodyModalEditProfile";

const ModalEditProfile = (props) => {
  const { isOpen, closeModal } = props;

  // RETURN
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Container>
        {/** Header */}
        <HeaderModalEditProfile closeModal={closeModal} />
        {/** Body */}
        <BodyModalEditProfile />
      </Container>
    </Modal>
  );
};

export default ModalEditProfile;

const Container = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 1200px;
  max-width: 90vw;
  height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

import React from "react";
import styled from "styled-components";

import { Formik, Form } from "formik";
import { Button, CircularProgress } from "@mui/material";
import DefaultField from "../../../common/DefaultField";
import NumberTitle from "../../atoms/tickets/NumberTitle";
import { checkTicketSchema } from "../../../../utils/schemas";

const ControlTicket = (props) => {
  const { handleSubmit, loading } = props;

  // STATE
  const initialValues = {
    ticket: "",
  };

  // RETURN
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={checkTicketSchema}
    >
      {() => (
        <Container>
          <NumberTitle number="1" title="Ingresa el número de ticket" />
          <PaddingWrapper>
            {/** Numero de ticket */}
            <DefaultField name="ticket" type="text" label="Nro. de ticket" />
            {/** Boton */}
            {loading ? (
              <CircularProgress color="primary" size={32} />
            ) : (
              <CustomButton variant="contained" color="primary" type="submit">
                Siguiente
              </CustomButton>
            )}
          </PaddingWrapper>
        </Container>
      )}
    </Formik>
  );
};

export default ControlTicket;

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: max-content;
`;

const PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: max-content;
  padding-left: calc(30px + 1rem);

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const CustomButton = styled(Button)`
  width: max-content;
  padding-left: 2rem;
  padding-right: 2rem;
`;

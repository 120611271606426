import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import DefaultCardIcon from "../../../../../components/common/DefaultCardIcon";

// Material UI
import AttachFileIcon from "@mui/icons-material/AttachFile";

const BlockSubmission = (props) => {
  const { submission } = props;

  const handleDonwload = () => {
    window.open(submission.file.file);
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
    // const string2 = 'website/static/'
    // const regex2 = new RegExp(string2, 'g');
    // return result.replace(regex2, 'https://repositorio-test.aden.org/website/static/');
  };

  if (!!submission.response) {
    return <div>{parse(sanitizerHtml(submission.response))}</div>;
  }

  return (
    <BlockSubmissionWrapper>
      <Click onClick={handleDonwload}>
        <DefaultCardIcon
          icon={<AttachFileIcon />}
          color={"#5E80DB"}
          subtitle={new Date(submission.create_date).toLocaleDateString()}
          title={"Mi tarea"}
        />
      </Click>
    </BlockSubmissionWrapper>
  );
};

export default BlockSubmission;

const BlockSubmissionWrapper = styled.div`
  border-radius: 20px;
`;

const Click = styled.div`
  cursor: pointer;
  max-width: 230px;
  transition: all 0.3s ease-in-out;
  :hover {
    filter: brightness(0.9);
  }
`;

import React from "react";
import styled from "styled-components";
import { FormControl, Skeleton } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import GridPaginationBox from "../common/GridPaginationBox";

const GridBusinessSkeleton = (props) => {
    const {
        width = 325,
        height = 340,
        cardWidth = "310px",
        cards = 4
    } = props;

    const data = Array.from({ length: cards }, (_, index) => index + 1);

    // RETURN
    return (
        <BigContainer>
            <Container>
                <Wrapper>
                    <Filters>
                        <SearchFilter>
                            <TextField
                                type="text"
                                placeholder="Buscar..."
                            />
                            <SearchIcon />
                        </SearchFilter>
                        <FormControlFake>
                            <ButtonFilter>
                                <TuneIcon />
                                Filtrar
                            </ButtonFilter>
                        </FormControlFake>
                    </Filters>
                </Wrapper>
                <Grid cardWidth={cardWidth}>
                    {
                        data.map((numero, index) => (
                            <SkeletonCard
                                variant="rounded"
                                animation="wave"
                                width={width}
                                height={height}
                            />
                        ))
                    }
                </Grid>
            </Container>
            <GridPaginationBox />
        </BigContainer>
    );
};

export default GridBusinessSkeleton;

const BigContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 470px;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  height: auto;
  background-color: var(--secondary-one);
  border-radius: 20px;
  min-height: 650px;
  @media screen and (max-width: 1121px) {
    grid-template-columns: ${(p) => (p.enableFilter ? "1fr 2fr" : "repeat(3)")};
    /* grid-template-columns: repeat(3) */
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 768px) {
    width: 60vw;
    position: fixed;
    z-index: 10;
    padding-top: 60px;
    height: 95%;
    left: ${(p) => (p.openAside ? "0" : "-110%")};
    gap: 50px;
    top: 8%;
    background: #fff;
    transition: all ease-in-out 0.3s;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 1rem);
  border-radius: 20px 20px 0 0;
  padding: 1rem 2rem;
`;

const SearchFilter = styled.div`
  position: relative;
`

const SearchIcon = styled(SearchRounded)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-four);
`

const TextField = styled.input` 
  background: #F9F9F9;
  color: #000;
  margin-bottom: 0;
  width: 309px;
  border-radius: 8px;
  height: 32px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: start;
  padding: 12px 16px 12px 48px;
  font-size: 16px;

  ::placeholder {
    color:  var(--secondary-four);
    opacity: 1; /* Firefox */
  }
`

const FormControlFake = styled(FormControl)`
  width: 100%;
  height: 10vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  position: relative;
  margin-bottom: auto;
  .MuiFormControl-root {
    width: 90%;
    height: auto;
    align-content: space-between;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInputLabel-formControl {
    display: contents;
    align-content: center;
    color: ${(props) => props.color};
    width: 100%;
    label {
      margin: 0 1.5rem;
    }
    text-align: center;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.color};
  }
`;

const ButtonFilter = styled.button`
  height: 48px;
  width: fit-content;
  padding: 0 24px 0 16px;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-one);
  color: var(--secondary-one);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax( ${(props) => props.cardWidth}, 1fr));
  justify-content: space-between;
  align-self: center;
  width: calc(100% - 8rem);
  padding: 4rem;
  height: auto;
  row-gap: 3rem;
  column-gap: 1rem;
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`

const SkeletonCard = styled(Skeleton)`
  align-items: center;
  justify-self: center;
  border-radius: 20px;
`
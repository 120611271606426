import * as types from "../types/status_types";

const initialState = {
  // BLOCKED STATUS
  blockedStatus: null,
  error: null,
};

export const userStatus = (state = initialState, action) => {
  switch (action.type) {
    // GET BLOCKED STATUS
    case types.GET_BLOCKED_STATUS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.GET_BLOCKED_STATUS_SUCCESS:
      return {
        ...state,
        blockedStatus: action.payload,
      };
    case types.GET_BLOCKED_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

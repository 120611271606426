import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import SimpleLoading from "../../components/common/SimpleLoading";
import MainPage from "../../components/templates/certificate/MainPage";
import { titleHTML } from "../../helpers/title.helper";

const Certificate = () => {
  titleHTML("Certificados");

  let { obj, user } = useParams();
  const history = useHistory();

  const [certificate, setCertificate] = useState(null);

  if (!obj && !user) {
    history.push("/auth/login");
  }

  useEffect(() => {
    getCertificate();
  });

  const getCertificate = async () => {
    const response = await getCertificateService(obj, user);
    if (response.error) {
      alert(response.error);
    } else {
      setCertificate(response.html);
    }
  };

  const getCertificateService = async (obj, user) => {
    const URL = `${process.env.REACT_APP_REPO}/repo_aden_objeto_aprendizaje/certificado/${obj}/${user}`;
    try {
      const request = await axios.get(URL);
      return request.data.response_data;
    } catch (error) {
      return {
        error,
      };
    }
  };

  //RETURN
  if (certificate === null) {
    return <SimpleLoading />;
  }
  return <MainPage html={certificate} />;
};

export default Certificate;

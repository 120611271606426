import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardArticles from "../../../../components/blog/CardArticles";
import useBlogArticle from "../../../../hooks/blog/useBlogArticle";
import Section from "./components/Section";
import AdenEvents from "../../../../components/templates/campus/AdenEvents";
import { useSelector } from "react-redux";

const BlogSlider = () => {

    const breakPoints = [
        { width: 500, itemsToShow: 1},
        { width: 1200, itemsToShow: 3.5},
        { width: 1400, itemsToShow: 3.5},
        { width: 1500, itemsToShow: 3.8 },
        { width: 1600, itemsToShow: 4.5 },
        { width: 1700, itemsToShow: 4.5 },
        { width: 1800, itemsToShow: 4.5 },
        { width: 2000, itemsToShow: 4.5 }
    ]

    const breakPointsWithEvents = [
        { width: 500, itemsToShow: 1},
        { width: 1200, itemsToShow: 1.7},
        { width: 1400, itemsToShow: 2 },
        { width: 1500, itemsToShow: 2.5 },
        { width: 1600, itemsToShow: 2.8 },
        { width: 1700, itemsToShow: 3 },
        { width: 1800, itemsToShow: 3.5 },
        { width: 2000, itemsToShow: 4.5 }
    ]

    const [activeTab, setActiveTab] = useState(0)

    const [activeArray, setActiveArray] = useState(null)

    const { allArticles, allCompanyBlogs } = useBlogArticle();

    const enable_event = useSelector((state) => state?.ondemand?.ondemand?.enable_event);

    const showEvents = enable_event;

    useEffect(() => {
        if (activeTab === 0) {
            setActiveArray(allArticles)
        } else if (activeTab === 1) {
            setActiveArray(allCompanyBlogs)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    if (!allArticles) {
        return <Skeleton h="485px" w="100%" />;
    }

    const mockTabsArray = ["Blog ADEN", "Noticias empresa"]

    return (
        <>
            {allArticles.length === 0 ? null : (
                <Container>
                    <Section
                        title={"Noticias y eventos"}
                        tabsArray={mockTabsArray}
                        arrayLength={allArticles.length}
                        array={activeArray || allArticles}
                        Card={CardArticles}
                        width={showEvents && (window.outerWidth > 768) ? "calc(100% - 490px)" : "100%"}
                        height={"475px"}
                        cardWidth={311}
                        breakPoints={showEvents ? breakPointsWithEvents : breakPoints}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        to="/blog"
                    >
                        {
                            allArticles.map((item) => (
                                <CardArticles
                                    value={item}
                                />
                            ))
                        }
                    </Section>
                    {
                        showEvents &&
                        <AdenEvents
                            firstSection={false}
                        />
                    }
                </Container>
            )}
        </>
    );
};

export default BlogSlider;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 32px;
    visibility: auto;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 32px;
    }
`

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

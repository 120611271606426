import React, { useState } from "react";
import styled from "styled-components";
import useSimulator from "../hooks/useSimulator";
import EmptyGridResource from "../../../components/common/EmptyGridResource";
import GridResource from "../../../components/common/GridResource";
import MicrolearningsCard from "../../../components/ui/molecules/microlearnings/MicrolearningsCard";
import MicrocontentCardSkeleton from "../../../components/common/MicrocontentCardSkeleton";
import SharedResource from "../../../components/common/SharedResource";

const MainSimulator = () => {
    const { simulators } = useSimulator();

    // STATES
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    const emptyItems = new Array(6).fill(null);

    // FUNCTIONS
    function handleOpenModal(id, nombre_publicacion) {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: "microcontent",
            type: "recurso",
            id,
            nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    return (
        <Wrapper>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            {simulators === null ? (
                <EmptyGridResource
                    propsValues={emptyItems}
                    Card={MicrocontentCardSkeleton}
                />
            ) : (
                <GridResource
                    propsValues={simulators}
                    type={"micro"}
                    Card={MicrolearningsCard}
                    handleOpenModal={handleOpenModal}
                />
            )}
        </Wrapper>
    );
};

export default MainSimulator;

const Wrapper = styled.div``;

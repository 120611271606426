import * as types from "../types";

const initialState = {
  likes: 0,
  dislikes: 0,
  likeType: null,
  calificacion: null,
  loadingPost: false,
  loadingGet: false,
  loading: false,
  // Countries
  countries: null,
  loadingCountries: false,
  error: null,
  // Nationalities
  nationalities: null,
  loadingNationalities: false,
  errorNationalities: null,
  // Document types
  docTypes: null,
  loadingDocTypes: false,
  errorDocTypes: null,
  // Professions
  professions: null,
  loadingProfessions: false,
  errorProfessions: null,
  // Programs
  programsOptions: null,
  loadingProgramsOptions: false,
  errorProgramsOptions: null,
  // Jobs
  jobsOptions: null,
  loadingJobsOptions: false,
  errorJobsOptions: null,
  // Areas
  areasOptions: null,
  loadingAreasOptions: false,
  errorAreasOptions: null,
};

export const utils = (state = initialState, action) => {
  switch (action.type) {
    // POST LIKE OR DISLIKE
    case types.SET_LIKE_REQUEST:
      return {
        ...state,
        loadingPost: true,
        error: null,
      };
    case types.SET_LIKE_SUCCESS:
      return {
        ...state,
        loadingPost: false,
        error: null,
        likeType: null,
      };
    case types.SET_LIKE_FAIL:
      return {
        ...state,
        loadingPost: false,
        error: action.payload,
      };
    // GET LIKES
    case types.GET_LIKES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_LIKES_SUCCESS:
      return {
        ...state,
        loading: false,
        likes: action.payload.likes,
        dislikes: action.payload.dislikes,
        error: null,
      };
    case types.GET_LIKES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        likes: null,
        dislikes: null,
      };
    // GET MY CALIFICATION
    case types.GET_CALIFICACION_REQUEST:
      return {
        ...state,
        loadingGet: true,
        error: null,
      };
    case types.GET_CALIFICACION_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        calificacion: action.payload,
        error: null,
      };
    case types.GET_CALIFICACION_FAIL:
      return {
        ...state,
        loadingGet: false,
        error: action.payload,
        calificacion: null,
      };
    // LIKE TYPE
    case types.SET_LIKE:
      return {
        ...state,
        likes: state.likes + 1,
        dislikes: state.dislikes > 0 ? state.dislikes - 1 : state.dislikes,
        likeType: action.payload,
      };
    case types.SET_DISLIKE:
      return {
        ...state,
        likes: state.likes > 0 ? state.likes - 1 : state.likes,
        dislikes: state.dislikes + 1,
        likeType: action.payload,
      };

    // GET COUNTRIES
    case types.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        loadingCountries: true,
        error: null,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loadingCountries: false,
        countries: action.payload,
        error: null,
      };
    case types.GET_COUNTRIES_FAIL:
      return {
        ...state,
        loadingCountries: false,
        error: action.payload,
      };
    // GET NATIONALITIES
    case types.GET_NATIONALITIES_REQUEST:
      return {
        ...state,
        loadingNationalities: true,
        errorNationalities: null,
      };
    case types.GET_NATIONALITIES_SUCCESS:
      return {
        ...state,
        loadingNationalities: false,
        nationalities: action.payload,
      };
    case types.GET_NATIONALITIES_FAIL:
      return {
        ...state,
        loadingNationalities: false,
        errorNationalities: action.payload,
      };
    // GET DOCTYPES
    case types.GET_DOCTYPES_REQUEST:
      return {
        ...state,
        loadingDocTypes: true,
        errorDocTypes: null,
      };
    case types.GET_DOCTYPES_SUCCESS:
      return {
        ...state,
        loadingDocTypes: false,
        docTypes: action.payload,
      };
    case types.GET_DOCTYPES_FAIL:
      return {
        ...state,
        loadingDocTypes: false,
        errorDocTypes: action.payload,
      };
    // GET PROFESSIONS
    case types.GET_PROFESSIONS_REQUEST:
      return {
        ...state,
        loadingProfessions: true,
        errorProfessions: null,
      };
    case types.GET_PROFESSIONS_SUCCESS:
      return {
        ...state,
        loadingProfessions: false,
        professions: action.payload,
      };
    case types.GET_PROFESSIONS_FAIL:
      return {
        ...state,
        loadingProfessions: false,
        errorProfessions: action.payload,
      };

    // GET PROGRAMS
    case types.GET_PROGRAMS_OPTIONS_REQUEST:
      return {
        ...state,
        loadingProgramsOptions: true,
        errorProgramsOptions: null,
      };
    case types.GET_PROGRAMS_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingProgramsOptions: false,
        programsOptions: action.payload,
      };
    case types.GET_PROGRAMS_OPTIONS_FAIL:
      return {
        ...state,
        loadingProgramsOptions: false,
        errorProgramsOptions: action.payload,
      };

    // GET JOBS
    case types.GET_JOBS_OPTIONS_REQUEST:
      return {
        ...state,
        loadingJobsOptions: true,
        errorJobsOptions: null,
      };
    case types.GET_JOBS_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingJobsOptions: false,
        jobsOptions: action.payload,
      };
    case types.GET_JOBS_OPTIONS_FAIL:
      return {
        ...state,
        loadingJobsOptions: false,
        errorJobsOptions: action.payload,
      };

    // GET AREAS
    case types.GET_AREAS_OPTIONS_REQUEST:
      return {
        ...state,
        loadingAreasOptions: true,
        errorAreasOptions: null,
      };
    case types.GET_AREAS_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingAreasOptions: false,
        areasOptions: action.payload,
      };
    case types.GET_AREAS_OPTIONS_FAIL:
      return {
        ...state,
        loadingAreasOptions: false,
        errorAreasOptions: action.payload,
      };
    default:
      return state;
  }
};

import React from "react";
import styled from "styled-components";

import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import Text from "../../components/common/Text";
// import EventsMain from '../../components/templates/events/EventsMain';
import ForumMain from "../../components/templates/forum/ForumMain";
import { titleHTML } from "../../helpers/title.helper";

const ForumSynchronous = () => {
  titleHTML("Foros especializados sincrónicos");

  return (
    <Container>
      <CustomBreadcrumbs />
      <Text fontSize="2rem" fontWeight="700" color="primary">
        Foros especializados sincrónicos
      </Text>
      <ForumMain />
    </Container>
  );
};

export default ForumSynchronous;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

import { CLEAR_MENU, SET_MENU } from '../types/layout_types';

const actions = {
    setMenu: (payload) => {
        return {
            type: SET_MENU,
            payload: payload
        }
    },
    clearMenu: () => {
        return {
            type: CLEAR_MENU,
        }
    }
};


export { actions };

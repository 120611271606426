import React/* , { useEffect } */ from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
/* import styled from 'styled-components' */
import { connect } from "react-redux";

// Components
import LayoutOndemand from "../components/shared/ondemand-layout/LayoutOndemand";
/* import Copyright from '../components/common/Copyright' */

// Hooks
import useHotjar from "../hooks/useHotjar";
/* import { useHelpChat } from "../hooks/useHelpChat"; */
/* import useLayout from "../hooks/useLayout"; */

const PrivateRoute = (props) => {
  const {
    layout = "common",
    isAuthenticated,
    blockedRoles,
    permissionRoles,
    user,
    blockedStatus,
    /* title = false, */
    component: Component,
    ...rest
  } = props;

  // Hooks
  /* const { setMenu, clearMenu } = useLayout() */
  // useHelpChat(user, isAuthenticated, "private");
  useHotjar();

/*   useEffect(() => {
    if (!!title) {
        setMenu(title)
    }
    else {
        clearMenu()
    }
  }, [title]) */

  const { pathname } = useLocation();
  const urlRedirect = pathname.slice(1);

  // RETURN
  return (
    <>
      <Route
        {...rest}
        component={(props) =>
          isAuthenticated ? (
            <LayoutOndemand minPadding={false} layout={layout}>
              <Component {...props} />
            </LayoutOndemand>
          ) : (
            <Redirect
              to={
                urlRedirect === "not-found" || urlRedirect === "logout"
                  ? "/auth"
                  : `/auth?redirect=${urlRedirect}`
              }
            />
          )
        }
      />
      {/* <FooterWrapper>
        <Copyright />
      </FooterWrapper> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.user,
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrivateRoute);

/* const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`; */
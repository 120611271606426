import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import SimpleLoading from "../../../common/SimpleLoading";
import {
  interestsActions,
  motivationsActions,
} from "../../../../redux/actions";
import { Chip } from "@mui/material";

const InterestsMotivations = () => {
  // REDUX
  const dispatch = useDispatch();
  const motivations = useSelector((state) => state.motivations.motivations);
  const listLoadingMotivations = useSelector(
    (state) => state.motivations.listLoading
  );
  const interests = useSelector((state) => state.interests.interests);
  const listLoadingInterests = useSelector(
    (state) => state.interests.listLoading
  );
  const userMotivations = useSelector((state) => state.auth.user.motivaciones);
  const userInterests = useSelector((state) => state.auth.user.intereses);

  // STATE
  const [motivationsShow, setMotivationsShow] = useState(null);
  const [interestsShow, setInterestsShow] = useState(null);

  // EFFECTS
  useEffect(() => {
    if (motivations === null) {
      dispatch(motivationsActions.getMotivationsRequest());
    }
    if (interests === null) {
      dispatch(interestsActions.getInterestsRequest());
    }
  }, []);

  useEffect(() => {
    if (motivations !== null) {
      setMotivationsShow(
        motivations.filter((x1) => userMotivations.some((x2) => x1.id === x2))
      );
    }
  }, [userMotivations, motivations]);

  useEffect(() => {
    if (interests !== null) {
      setInterestsShow(
        interests.filter((x1) => userInterests.some((x2) => x1.id === x2))
      );
    }
  }, [userInterests, interests]);

  // RETURN
  if (
    motivations === null ||
    listLoadingMotivations ||
    motivationsShow === null ||
    interests === null ||
    listLoadingInterests ||
    interestsShow === null
  ) {
    return <SimpleLoading padding="4rem" />;
  }

  return (
    <InterestsMotivationsContainer>
      <Title>Motivaciones</Title>
      <MotivationsWrapper>
        {motivationsShow.map((motivationShow) => (
          <FakeChip
            label={motivationShow.name}
            key={motivationShow.id}
            color="primary"
            size="small"
          />
        ))}
      </MotivationsWrapper>
      <Title>Intereses</Title>
      <InterestsGrid>
        {interestsShow.map((interestShow) => (
          <InterestWrapper key={interestShow.id}>
            <InterestImage
              src={`data:image/svg+xml;base64, ${interestShow.icon}`}
            />
            <InterestName>{interestShow.name}</InterestName>
          </InterestWrapper>
        ))}
      </InterestsGrid>
    </InterestsMotivationsContainer>
  );
};

export default InterestsMotivations;

const InterestsMotivationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: #222222;
`;

const MotivationsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  flex-wrap: wrap;
`;

const FakeChip = styled(Chip)`
  padding: 1rem 0.5rem;
  background-color: #591AB6;
  color: #ffffff;
`;

const InterestsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.5rem;
`;

const InterestWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  color: #616161;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #ffd7d7;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InterestImage = styled.img``;

const InterestName = styled.span`
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
`;

import axios from "axios";

export const updateLastEntry = async (id, hash) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/update_latest_entry_alab/${id}?curso_id=${hash}`;
    try {
        const res = await axios.post(URL);
        // eslint-disable-next-line no-unused-vars
        const data = res.data;
    } catch (error) {
        return {
            error,
        };
    }
}
import axios from "axios";

export async function listTutorials(rol) {
  const URL = `${process.env.REACT_APP_CMS_ADENAPI}/tutoriales?populate=*&filters[rol]=${rol}`;
  try {
    const res = await fetch(URL);
    return await res.json();
  } catch (error) {
    return { error };
  }
}

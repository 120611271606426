import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import { Span, Text } from "../../../../../components/common/Texts";
import GenericChips from "../../../../../components/common/GenericChip";
import Attachments from "./Attachments";

// Material UI
import GradeIcon from "@mui/icons-material/Grade";
import { Avatar } from "@mui/material";

// Hooks
import useStatus from "../../../../../hooks/useStatus";

const Correction = (props) => {
  const { values } = props;

  const date = new Date(values.create_date);

  const parseString = (message) => {
    const string = "\\n";
    const regex = new RegExp(string, "g");
    return message.replace(regex, "<br/>");
  };

  const { setLightStateColor, setStrongStateColor } = useStatus();

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const state = values?.redo ? "rehacer" : values?.state;

  return (
    <CorrectionWrapper color={setLightStateColor(state)}>
      <Submission>
        <GenericChips
          textColor={setStrongStateColor(state)}
          bgColor={setLightStateColor(state)}
          title={`${values.submission_number || 1}° Entrega`}
          fontSize="12px"
          padding="10px 12px"
          fontWeight="900"
        />
        <Text fontWeight="400" fontSize="12px" color="#A8A8A8">
          Entregado el {date.toLocaleDateString()}
        </Text>
      </Submission>
      <Submission>
        <State color={setStrongStateColor(state)}>
          Estado: <b>{capitalizeFirstLetter(state)}</b>
        </State>
      </Submission>
      <Submission>
        <IconWrapper>
          <GradeIcon />
        </IconWrapper>
        <Text fontWeight="900" fontSize="24px">
          {values.grade}{" "}
          <Span fontWeight="700" color="#A8A8A8">
            / 100
          </Span>
        </Text>
      </Submission>
      <label>
        Comentarios del profesor
      </label>
      <ContentMessage>
        {parse(parseString(values.professor_comment || "Sin comentario"))}
      </ContentMessage>
      <Submission>
        <Avatar>P</Avatar>
        <Text fontWeight="700" fontSize="16px" color="#222222">
          {values?.professor_name}
        </Text>
      </Submission>
      <Text fontSize="13px" fontWeight="600" color="#616161">
        Adjuntos del profesor
      </Text>
      <Submission>
        {
          values?.file_correction || values?.audio_correction || values?.video_correction ?
            <Attachments
              values={values}
            />
            :
            <CommentBox>
              <Text fontSize="14px" fontWeight="400" color="#616161">
                <b>No se encontraron adjuntos</b>
              </Text>
            </CommentBox>
        }
      </Submission>
    </CorrectionWrapper>
  );
};

export default Correction;

const CorrectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 20px;
  border-left: 10px solid ${(props) => props.color};
  border-radius: 10px; 
  box-shadow: rgba(24, 39, 75, 0.08) 0px 8px 24px -4px, rgba(24, 39, 75, 0.2) 0px 6px 12px -4px;
`;

const Submission = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

const State = styled.label`
  font-size: 13px;
  font-weight: 600;
  b {
    color: ${(props) => props.color};
  }
`

const IconWrapper = styled.div`
  svg {
    color: #a8a8a8;
    font-size: 32px;
  }
`;

const ContentMessage = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 150px;
  padding: 1rem;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 400;
  color: #616161;
`;

const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
  border-radius: 10px;
  background-color: #f9f9f9;
`;
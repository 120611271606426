import * as types from "../types/tickets_types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getOptions, getTicket } from "../api/tickets.api";
import { ticketsActions } from "../actions";

function* getOptionsRequest() {
  try {
    const res = yield call(getOptions);
    // console.log(res);
    yield put(ticketsActions.getOptionsSuccess(res.data.response_data));
  } catch (error) {
    yield put(ticketsActions.getOptionsFail(error));
  }
}

function* getTicketRequest(action) {
  try {
    const res = yield call(getTicket, action.payload);
    // console.log(res);
    yield put(ticketsActions.getTicketSuccess(res.data.response_data));
  } catch (error) {
    yield put(ticketsActions.getTicketFail(error));
  }
}

function* ticketsWatcher() {
  yield takeEvery(types.GET_OPTIONS_REQUEST, getOptionsRequest);
  yield takeEvery(types.GET_TICKET_REQUEST, getTicketRequest);
}

export { ticketsWatcher };

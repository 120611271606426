import styled, { keyframes } from "styled-components";
import useStatus from "../../../../../hooks/useStatus";

const AnalyticBarSkeleton = () => {

    // HOOK

    const { setStrongStateColor } = useStatus();

    // Tipos de barras
    const analytics = [
        {
            label: "Por iniciar",
            height: 0,
            percentage: 0,
            color: setStrongStateColor("sin_entrega")
        },
        {
            label: "En curso",
            height: 0,
            percentage: 0,
            color: setStrongStateColor("pendiente")
        },
        {
            label: "Finalizados",
            height: 0,
            percentage: 0,
            color: setStrongStateColor("corregido")
        }
    ]

    return analytics.map((item) => {
        return (
            <Analytic height={item.height + "%"} key={item.label}>
                <Percentage color={item.color}>
                    {item.percentage || 0}%
                </Percentage>
                <PercentageBarContainer >
                    <PercentageBar height={item.height + "px"} color={item.color} />
                </PercentageBarContainer>
                <PercentageType>
                    {item.label}
                </PercentageType>
            </Analytic>
        )
    })
}


export default AnalyticBarSkeleton;

const barUp = (height) => keyframes`
    0% {
        height: 20px;
    }
    100% {
        height: ${height};
    }
`

const colorText = (color) => keyframes`
    0% {
        color: var(--primary-two);
    }
    99% {
        color: var(--primary-two);
    }
    100% {
        color: ${color};
    }
`

const Analytic = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 12px;
`

const Percentage = styled.div`
    font-size: 20px;
    font-weight: 500;
    animation: ${(p) => colorText(p.color)} 3000ms ease-in-out forwards;
`

const PercentageBarContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: end;
`

const PercentageBar = styled.div`
    min-height: 20px;
    width: 70px;
    border-radius: 20px 20px 5px 5px;
    background-color: ${(p) => p.color};
    animation: ${(props) => barUp(props.height)} 3000ms ease-in-out forwards;
`

const PercentageType = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-two);
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { coursesActions } from "../../redux/actions";

const AlertDialog = (props) => {
  const { onClose, titulo, mensaje, accion, open, w = "xs", ...rest } = props;

  //REDUX
  const dispatch = useDispatch();
  const errorEnroll = useSelector((state) => state.courses.errorEnroll);

  const handleClose = () => {
    if (errorEnroll !== null) {
      dispatch(coursesActions.fetchState());
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={w}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <CustomDialogTitle id="alert-dialog-title">{titulo}</CustomDialogTitle>
      <Divider />
      <DialogContent>
        <CustomDialogContentText id="alert-dialog-description">
          {mensaje}
        </CustomDialogContentText>
      </DialogContent>
      <CustomDialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
        >
          {accion}
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

export default AlertDialog;

const CustomDialogActions = styled(DialogActions)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const CustomDialogTitle = styled(DialogTitle)`
  text-align: center;
  font-weight: 700;
`;

const CustomDialogContentText = styled(DialogContentText)`
  text-align: center;
  margin-bottom: 0px;
`;

import React, { useState, useEffect } from "react"

// Hooks
import { useQueryParams } from "../../../modules/acropolisCommon/hooks/useQueryParams";
import useCourse from "../hooks/useCourse";
import useGetNotesCourse from "../hooks/useGetNotesCourse";

const Context = React.createContext();

export function CourseContextProvider ({ children, hash }) {

    const params = useQueryParams();
    const idCourse = params.get("id");
    let idResource = parseInt(params.get("resource"));

    // STATES
    const [isChangeMinute, setIsChangeMinute] = useState(false);
    const [currentMinute, setCurrentMinute] = useState(0);
    const [changeStatus, setChangeStatus] = useState(false);
    const [viewDelivery, setViewDelivery] = useState(null);
    const [currentSecondVideo, setCurrentSecondVideo] = useState(0);
    const [showAside, setShowAside] = useState(true)
    const [tabClose, setTabClose] = useState(4)

    //CUSTOM-HOOKS
    const {
        workshop,
        grade,
        progress,
        lastResourceOfCourse,
        currentMedia,
        currentPosition,
        unitsEnabled,
        openAside,
        blockResocurce,
        menuItems,
        currentUnit,
        nextResource,
        certificateEnabled,
        blockResourceChange,
        changeCurrentMedia,
        setProgress,
        setCurrentMedia,
        setCurrentPosition,
        setMenuItems,
        handleCheckResource,
        handleNext,
        handleCloseAside,
        toggleAside,
        unitCurrentNow,
        backItem,
        nextItem,
    } = useCourse(hash);

    const { valuesNotes, errorGetNotes, LoadingGetNotes, getNotes } = useGetNotesCourse(currentMedia?.id);

    const notesContent = {
        valuesNotes,
        errorGetNotes,
        LoadingGetNotes,
        getNotes,
    };

    // FUNCTIONS
    const handleCurrentSecond = (second) => {
        setCurrentSecondVideo(second);
    };

    const handleCurrentMinute = (minute) => {
        setCurrentMinute(minute);
    };

    const handleIsChangeMinute = (value) => {
        setIsChangeMinute(value);
    };

    // EFFECT
    useEffect(() => {
        if (currentMedia === null && workshop !== null) {
            // Selecciona la actividad no vista
            let status = true;
            if (idResource) {
                if (workshop !== null) {
                    workshop.unidades.forEach((unidad) => {
                        unidad.clases.forEach((clase) => {
                            if (clase?.id_evaluacion === idResource) {
                                setCurrentMedia(clase || false);
                                setCurrentPosition(clase || false);
                                unitCurrentNow(clase || null);
                                // setMenuItems((state) => [...state, clase]);
                                status = false;
                            }
                        });
                    });
                }
            }
            workshop.unidades.forEach((unidad, u) => {
                if (status === true) {
                    unidad.clases.forEach((clase, i) => {
                        if (!clase.visto && status === true) {
                            setCurrentMedia(clase || false);
                            setCurrentPosition(clase || false);
                            unitCurrentNow(clase || null);
                            status = false;
                        }
                    });
                }
            });
            if (status === true) {
                setCurrentMedia(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : false
                );
                setCurrentPosition(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : false
                );
                unitCurrentNow(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : null
                );
            }
        }
        // Selecciona los items
        if (workshop !== null && menuItems.length === 0) {
            workshop.unidades.forEach((unidad) => {
                unidad.clases.forEach((clase) => {
                    setMenuItems((state) => [...state, clase]);
                });
            });
        }
        // Cálculo del progreso según "visto"
        let allView = 0;
        if (workshop !== null && menuItems.length !== 0) {
            workshop.unidades.forEach((unidad) => {
                unidad.clases.forEach((clase) => {
                    if (clase.visto) {
                        ++allView;
                    }
                    setProgress(
                        (allView * 100) /
                        (menuItems.length === 0 ? 1 : menuItems.length)
                    );
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshop, currentMedia]);

    useEffect(() => {
        const id = localStorage.getItem("src_id");
        const type = localStorage.getItem("src_type");
        if (id || type) {
            localStorage.removeItem("src_id");
            localStorage.removeItem("src_type");
        }
    }, []);

    const contextValues = {
        blockResocurce: blockResocurce,
        currentPosition: currentPosition,
        currentMedia: currentMedia,
        currentMinute: currentMinute,
        certificado: workshop?.certificado_alab,
        currentSecondVideo: currentSecondVideo,
        currentUnit: currentUnit,
        changeStatus: changeStatus,
        certificateEnabled: certificateEnabled,
        grade: grade,
        idCourse: idCourse,
        isWs: !!workshop?.es_workshop,
        isChangeMinute: isChangeMinute,
        menuItems: menuItems,
        lastResourceOfCourse: lastResourceOfCourse,
        notesContent: notesContent,
        nextResource: nextResource,
        nombre: workshop?.nombre_publicacion,
        progress: progress,
        showAside: showAside,
        openAside: openAside,
        objId: hash,
        tabClose: tabClose,
        unitsEnabled: unitsEnabled,
        unidades: workshop?.unidades,
        viewDelivery: viewDelivery,
        workshop: workshop,
        backItem: backItem,
        blockResourceChange: blockResourceChange,
        changeCurrentMedia: changeCurrentMedia,
        handleCurrentSecond: handleCurrentSecond,
        handleCheckResource: handleCheckResource,
        handleNext: handleNext,
        handleIsChangeMinute: handleIsChangeMinute,
        handleCloseAside: handleCloseAside,
        handleCurrentMinute: handleCurrentMinute,
        nextItem: nextItem,
        setChangeStatus: setChangeStatus,
        setShowAside: setShowAside,
        setTabClose: setTabClose,
        setViewDelivery: setViewDelivery,
        toggleAside: toggleAside,
    }

    return (
        <Context.Provider value={contextValues}>
            {children}
        </Context.Provider>
    )
}

export default Context;
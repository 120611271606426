import axios from "axios";

export async function getMyCertificates(repoId) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/ondemand/get_insignias/${repoId}`;

    try {
        const request = await axios.get(URL);
        return await request.data;
    } catch (error) {
        throw new Error(error);
    }
}

export async function postGenerateBadge(body) {
    const URL = `${process.env.REACT_APP_SISAPI}/insignia/nueva`;

    try {
        const req = await axios.post(URL, body);

        return req;
    } catch (error) {
        throw new Error(error);
    }
}

export async function getIndividualCertificate(partnerId, badgeId, ondemandId) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/ondemand/certificado_by_partner/${partnerId}/${badgeId}?ondemand_id=${ondemandId}`;

    try {
        const request = await axios.get(URL);
        return await request.data;
    } catch (error) {
        throw new Error(error);
    }
}

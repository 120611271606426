import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useResource from "../../../hooks/students/useResource";
import DefaultCard from "../../common/DefaultCard";
import ErrorResponse from "../../common/ErrorResponse";
import GenericChips from "../../common/GenericChip";
import SimpleLoading from "../../common/SimpleLoading";
import { Text } from "../../common/Texts";
import RenderActivity from "../../ui/organisms/resources/RenderActivity";
import RenderPlaneMaterial from "../../ui/organisms/resources/RenderPlaneMaterial";
import RenderPresentation from "../../ui/organisms/resources/RenderPresentation";
import RenderVideo from "../../ui/organisms/resources/RenderVideo";

const MainViewResource = (props) => {
  // PROPS
  const { type, id } = props;

  const params = useParams();
  const idResource = params.id;
  const repoId = useSelector((state) => state?.auth?.user?.repo_id);

  // HOOKS
  const { resource } = useResource(id, type);

  // FUNCTIONS
  const renderType = (resource) => {
    const cases = {
      audiovisual: <RenderVideo resource={resource} />,
      material_plano: <RenderPlaneMaterial resource={resource} />,
      evaluacion: (
        <RenderActivity
          repoId={repoId}
          idResource={idResource}
          evaluationId={resource.id_evaluacion}
          obj={100001}
        />
      ),
      presentacion: <RenderPresentation resource={resource} />,
      error: <ErrorResponse message="Ocurrió un error al obtener el recurso" />,
    };
    return cases[resource.format] || cases["error"];
  };

  if (!resource) {
    return (
      <DefaultCard>
        <SimpleLoading />
      </DefaultCard>
    );
  }

  if (resource === "error") {
    return (
      <DefaultCard>
        <ErrorResponse message="Ocurrió un error al obtener el recurso" />
      </DefaultCard>
    );
  }

  return (
    <MainWrapper>
      <ResourceWrapper>
        {/* Informacion del recurso */}
        <DefaultCard>
          <Container>
            <Text color="#591AB6" fontSize="18px" fontWeight="bold">
              {resource?.publication_name ||
                "El recurso no posee un nombre cargado."}
            </Text>
            <Description>
              {resource?.description ||
                "El recurso seleccionado no posee una descripción."}
            </Description>
            {resource?.tags && (
              <ChipsWrapper>
                {resource?.tags.map((item) => (
                  <GenericChips
                    padding="5px 10px"
                    radius="20px"
                    textColor="white"
                    color="#591AB6"
                    title={item.toLowerCase()}
                  />
                ))}
              </ChipsWrapper>
            )}
          </Container>
        </DefaultCard>
        {/* Recurso */}
        <DefaultCard>
          {!resource.can_be_published && (
            <Description>
              ¡El recurso no esta apto para publicar en Acropolis!
            </Description>
          )}
          <Container>{renderType(resource)}</Container>
        </DefaultCard>
      </ResourceWrapper>
      {/* Padlet */}
      {resource.url_padlet && (
        <DefaultCard>
          <Iframe src={resource.url_padlet} />
        </DefaultCard>
      )}
    </MainWrapper>
  );
};

export default MainViewResource;

const MainWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const ResourceWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 16px;
`;

const Description = styled.div`
  padding: 1rem;
  background-color: #f7f7f7;
  border-radius: 20px;
  color: #858585;
  font-weight: 600;
  margin-bottom: 1.2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChipsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* align-items: center; */
  gap: 8px;
  width: 100%;
  height: 80px;
  max-height: 80px;
  overflow-y: auto;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  min-height: 600px;
  height: 100%;
`;

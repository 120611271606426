import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMicrolearning from "../../../../hooks/common/useMicrolearning";
import MicrolearningsCard from "../../../../components/ui/molecules/microlearnings/MicrolearningsCard";
import Section from "./components/Section"
import Analytics from "./components/Analytics";
import useAssignamentByAlab from "../../../../hooks/useAssignamentByAlab";
import { useSelector } from "react-redux";
import useSimulator from "../../../../modules/simulatorModule/hooks/useSimulator";

const Microlearning = () => {

    const [activeTab, setActiveTab] = useState(0)

    const [activeArray, setActiveArray ] = useState(null)

    const breakPoints = [
        { width: 500, itemsToShow: 1},
        { width: 1200, itemsToShow: 3 },
        { width: 1400, itemsToShow: 3.3 },
        { width: 1500, itemsToShow: 3.8 },
        { width: 1600, itemsToShow: 4.5 },
        { width: 1800, itemsToShow: 4.5 },
        { width: 2000, itemsToShow: 4.5 }
    ]

    const breakPointsWithAnalytics = [
        { width: 500, itemsToShow: 1},
        { width: 1200, itemsToShow: 1.5 },
        { width: 1400, itemsToShow: 1.7 },
        { width: 1500, itemsToShow: 2.3 },
        { width: 1600, itemsToShow: 2.5 },
        { width: 1700, itemsToShow: 2.8 },
        { width: 1800, itemsToShow: 2.9 },
        { width: 2000, itemsToShow: 3 }
    ]

    const { /* microlearnings, */ error } = useMicrolearning();

    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );

    const competitor = useSelector((state) => state?.ondemand?.academic);
    const ondemand = useSelector((state) => state?.ondemand?.ondemand);
    const enable_training_routes = useSelector((state) => state?.ondemand?.ondemand?.enable_training_routes);
    const enable_company_courses = useSelector((state) => state?.ondemand?.ondemand?.enable_company_courses);
    const enable_aden_courses = useSelector((state) => state?.ondemand?.ondemand?.enable_aden_courses);
    
    const { simulators } = useSimulator();

    const { courses } = useAssignamentByAlab(false, false, true, "microlearning")
    
    const { enable_analytics = true, enable_event } = ondemand;

    const showAnalytics = (enable_training_routes && (enable_company_courses || enable_aden_courses)) && enable_event && enable_analytics;

    const arrayCourses = () => {
        return (competitor.aden !== null || competitor.business !== null) &&
        ondemand !== null &&
        (competitor.aden.length || competitor.business.length > 0)
        ? [...competitor.aden, ...competitor.business]
        : false
    }

    useEffect(() => {
        if (activeTab === 0) {
            setActiveArray(microlearnings)
        } else if (activeTab === 1) {
            setActiveArray(simulators)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    if (!microlearnings && error === null) {
        return <Skeleton h="500px" w="100%" />;
    }

    const mockTabsArray = ["Microlearning", "Simulador 360"]

    const mockArray = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];

    return (
        <>
            {!error && (
                <Container>
                    <Section 
                    title={"Biblioteca de contenido"} 
                    tabsArray={mockTabsArray} 
                    arrayLength={mockArray.length}
                    array={activeArray || microlearnings}
                    Card={MicrolearningsCard}
                    width={showAnalytics && (window.outerWidth > 768) ? "calc(100% - 550px)" : "100%"}
                    cardWidth={320}
                    breakPoints={showAnalytics ? breakPointsWithAnalytics : breakPoints}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    >
                        
                    </Section>
                    {
                        showAnalytics &&
                        <Analytics
                            arrayCourses={arrayCourses()}
                            arrayRoutes={courses}
                            title="Estado de mi cursado"
                            to=""
                            subtitle="Última ruta vista"
                            width="550px"
                            height="510px"
                            label="Tu analítica"
                            first={false}
                        />
                    }
                    
                </Container>
                
            )}
        </>
    );
};

export default Microlearning;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 2rem;
    visibility: auto;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 12rem;
        margin-top: 3rem;
    }
`

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../../../components/common/Texts";
import AnalyticBar from "./AnalyticBar";
import { useSelector } from "react-redux";
import AnalyticBarSkeleton from "./AnalyticBarSkeleton";


const Analytics = (props) => {

  const {
    arrayCourses,
    arrayRoutes,
    title,
    height = "500px",
    width = "calc(50% - 32px)",
    label = false,
    first = true
  } = props;

  // STATES

  const [tabSelected, setTabSelected] = useState()

  const heightBar = first ? 210 : 170;

  // EFFECTS

  useEffect(() => {
    enable_training_routes ? setTabSelected(true) : setTabSelected(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // HOOKS
  const primaryColor = useSelector((state) => state?.ondemand?.ondemand?.primary_color);
  const enable_training_routes = useSelector((state) => state?.ondemand?.ondemand?.enable_training_routes);
  const enable_company_courses = useSelector((state) => state?.ondemand?.ondemand?.enable_company_courses);
  const enable_aden_courses = useSelector((state) => state?.ondemand?.ondemand?.enable_aden_courses);

  // const handleClick = (id) => push(`/curso/${hash(id)}?origin=${!!enable_correction_activities ? "plan" : "alab"}`);

  return (
    <Wrapper width={width} height={height}>
      {
        label && <Label>{label}</Label>
      }
      <CoursesContainer height={height} width={width} backgroundColor={primaryColor}>
        {
          (arrayCourses === false && arrayRoutes === false) ?
            <NoResultsFoundWrapper>
              <Text fontSize="20px" fontWeight="500" color="var(--secondary-one)">No se encontraron analíticas</Text>
            </NoResultsFoundWrapper>
            :
            <>
              {/* Header */}
              <OptionsWrapper>
                <Text fontSize="20px" fontWeight="700">
                  {title}
                </Text>
                <Line />
              </OptionsWrapper>
              {/* Buttons */}
              <ButtonsWrapper>
                {
                  enable_training_routes &&
                  <TabButton onlyOne={!(enable_company_courses || enable_aden_courses)} selected={tabSelected} onClick={() => !tabSelected && setTabSelected(!tabSelected)}>
                    Rutas formativas
                  </TabButton>
                }
                {
                  (enable_company_courses || enable_aden_courses) &&
                  <TabButton onlyOne={!enable_training_routes} selected={!tabSelected} onClick={() => tabSelected && setTabSelected(!tabSelected)}>
                    Cursos
                  </TabButton>
                }

              </ButtonsWrapper>
              {/* Body */}
              <Container>
                <AnalyticsContainer>
                  {
                    (tabSelected) &&
                    <>
                      {
                        arrayRoutes
                          ? <AnalyticBar heightBar={heightBar} array={arrayRoutes} />
                          : <AnalyticBarSkeleton />
                      }
                    </>
                  }
                  {
                    (!tabSelected) &&
                    <>
                      {
                        arrayCourses
                          ? <AnalyticBar heightBar={heightBar} array={arrayCourses} />
                          : <AnalyticBarSkeleton />
                      }
                    </>
                  }
                </AnalyticsContainer>
              </Container>
            </>
        }

      </CoursesContainer>
    </Wrapper>

  );
};

export default Analytics;

const Wrapper = styled.div`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Label = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-one);
`

const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 32px);
  height: ${(p) => p.height === "500px" ? "500px" : "455px"};
  border-radius: 20px;
  box-shadow: 0px 8px 8px -4px #18274B14, 0px 4px 6px -4px #18274B1F;
  background-image: ${(props) => `linear-gradient(180deg, ${props.backgroundColor} , #fff)`};
  background-size: 100% 300%;
  padding: 0 16px;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 20px;
  color: #fff;
  white-space: nowrap;
  margin-top: 24px;
`;


const Line = styled.div`
  width:100%;
  height: 1.5px;
  background-color: #fff;
  margin-bottom: 4px;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TabButton = styled.button`
    width: ${(p) => p.onlyOne ? "100%" : "50%"};
    height: 35px;
    border-radius: 20px; 
    background-color:${(p) => p.selected ? "var(--secondary-one)" : "transparent"};
    color:${(p) => p.selected ? "var(--primary-one)" : "var(--secondary-one)"};
    font-size: 14px;
    font-weight:${(p) => p.selected ? "700" : "400"};
    cursor: pointer;
`

const Container = styled.div`
    width: 100%;
    max-width: calc(100% - 32px);
    height: calc(100% - 32px);
    border-radius: 20px;
    background: linear-gradient(128.08deg, #FFFFFF 2.08%, rgba(255, 255, 255, 0) 103.51%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
    padding: 16px;
    margin-bottom: 16px;
`

const AnalyticsContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 64px;
    padding: 16px 0;
    @media (max-width: 768px) {
      gap: 1rem;
    }
`

const NoResultsFoundWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
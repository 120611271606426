import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import SimpleLoading from '../../components/common/SimpleLoading'
import { Text } from '../../components/common/Texts'

const Cookies = () => {

    const [state, setState] = useState("Reiniciando")
    const { push } = useHistory()

    useEffect(() => {
        handleCookies()
        handleStorage()
        logout()
    }, [])

    const handleCookies = () => {
        setTimeout(() => {
            setState("Borrando cookies de Acropolis")
            const cookies = document.cookie.split("; ")
            cookies.forEach(cookie => deleteCookie(cookie))
        }, 2000);
    }

    function deleteCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    const handleStorage = () => {
        setTimeout(() => {
            setState("Borrando caché de Acropolis")
            localStorage.clear()
        }, 4000);
    }

    const logout = () => {
        setTimeout(() => setState("Cerrando sesión"), 6000);
        setTimeout(() => push('./logout'), 8000)
    }


    return <ContentWrapper>
        <SimpleLoading />
        <Text color='#591AB6' fontWeigth="bold" fontSize="18px">{state}</Text>
    </ContentWrapper>
}

export default Cookies

const ContentWrapper = styled.div`
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubProgramCard from "../../ui/molecules/studyPlan/SubProgramCard";
import ArrowIcon from "@mui/icons-material/ArrowForwardIos";
import { Card } from "@mui/material";
import GridStudyPlan from "../../common/GridStudyPlan";
import TabsComponent from "../../common/TabsComponent";
import { handleMouseScrollX } from "../../../helpers/eventMouse.helper";

const ProgramsCourses = (props) => {
  const {
    id,
    data,
    programRelated,
    getDataStudyPlan,
    dataProgram,
    isDiplomat,
  } = props;

  const tabs = [
    { label: "Asignaturas", id: 0 },
    // { label: "Mis Calificaciones", id: 1 },
    // { label: "Alumnos", id: 2 },
    // { label: "Cuerpo Académico", id: 3 },
  ];

  // STATES
  const [programActive, setProgramActive] = useState(programRelated[0]?.id);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tab, setTab] = useState(0);
  const [isFlag, setIsFlag] = useState(false);
  const [dataAssigment, setDataAssigment] = useState(data);
  const [isChange, setIsChange] = useState(false);
  const [onInscription, setOnInscription] = useState(false);

  useEffect(() => {
    setDataAssigment(data);
    setTimeout(() => {
      setIsChange(false);
    }, 300);
    handleOnInscription(data);

    // setIsFlag(false);
  }, [data, isFlag]);

  // FUNCTIONS
  const renderCase = (type) => {
    const caseType = {
      0: (
        <GridStudyPlan
          id={id}
          course={dataAssigment}
          setDataAssigment={setDataAssigment}
          currentIndex={currentIndex}
          dataAssigment={dataAssigment}
          setIsChange={setIsChange}
          isChange={isChange}
          dataProgram={dataProgram}
          onInscription={onInscription}
          isDiplomat={isDiplomat}
          setIsFlag={setIsFlag}
          isFlag={isFlag}
        />
      ),
    };

    return caseType[type];
  };

  const handleOnInscription = (data) => {
    let courseConfirmed = data.filter((value) => value.state === "confirmado");

    if (courseConfirmed.length >= 2) {
      setOnInscription(false);
    } else {
      setOnInscription(true);
    }
  };

  const handleTab = (_, value) => {
    setTab(value);
  };

  return (
    <Container>
      <ProgramsCoursesWrapper
        id="subprograms"
        onClick={() => handleMouseScrollX("subprograms")}
      >
        {programRelated.map((program, index) => {
          return (
            <>
              <SubProgramCard
                key={index}
                data={program}
                index={index}
                setCurrentIndex={setCurrentIndex}
                programActive={programActive}
                setProgramActive={setProgramActive}
                setIsChange={setIsChange}
                getDataStudyPlan={getDataStudyPlan}
              />
              <ArrowIconFake />
            </>
          );
        })}
      </ProgramsCoursesWrapper>
      <CardFake>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
        <CardsWrapper>
          <TabCase>{renderCase(tab)}</TabCase>
        </CardsWrapper>
      </CardFake>
    </Container>
  );
};

export default ProgramsCourses;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
`;

const CardFake = styled(Card)`
  border-radius: 20px;
`;

const CardsWrapper = styled.div`
  padding: 2rem;
`;

const ProgramsCoursesWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  user-select: none;
  overflow-x: auto;
  padding: 0.1rem;
  :active {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArrowIconFake = styled(ArrowIcon)`
  color: #a8a8a8;
`;

const TabCase = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const createChannel = (fromPartnerId, toPartnerId) => {
  if (!!fromPartnerId && !!toPartnerId) {
    let hashChannel;
    if (fromPartnerId <= toPartnerId) {
      hashChannel = toPartnerId + "" + fromPartnerId;
    } else {
      hashChannel = fromPartnerId + "" + toPartnerId;
    }
    return hashChannel;
  } else {
    alert("Ocurrió un error el emisor y/o receptor");
  }

  return;
};

import React, { useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { Button, Link, Menu, MenuItem } from "@mui/material";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

const AddEventToCalendar = (props) => {
  const { oneEvent } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    publication_name,
    category,
    description,
    date_begin,
    date_end,
    webinar_url,
  } = oneEvent;

  const utc = getLocalUTC();
  const date_begin_local = new Date(
    moment(date_begin.replaceAll("-", "/") + `${utc} UTC`).format()
  );
  const date_end_local = new Date(
    moment(date_end.replaceAll("-", "/") + `${utc} UTC`).format()
  );

  moment.locale("es", {
    months:
      "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
        "_"
      ),
    monthsShort:
      "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

  const calendarEvent = {
    title: publication_name,
    description: webinar_url,
    start: moment(date_begin_local).format(),
    end: moment(date_end_local).format(),
    location: category.name,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // RETURN
  return (
    <>
      <CustomButton
        variant="contained"
        color="primary"
        aria-controls="calendars"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Añadir a mi calendario
      </CustomButton>

      <Menu
        id="calendars"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItemFake onClick={handleClose}>
          <Link href={google(calendarEvent)} target="_blank">
            {" "}
            Google Calendar{" "}
          </Link>
        </MenuItemFake>
        <MenuItemFake onClick={handleClose}>
          <Link href={outlook(calendarEvent)} target="_blank">
            {" "}
            Outlook{" "}
          </Link>
        </MenuItemFake>
        <MenuItemFake onClick={handleClose}>
          <Link href={office365(calendarEvent)} target="_blank">
            {" "}
            Office365{" "}
          </Link>
        </MenuItemFake>
        <MenuItemFake onClick={handleClose}>
          <Link href={yahoo(calendarEvent)} target="_blank">
            {" "}
            Yahoo{" "}
          </Link>
        </MenuItemFake>
        <MenuItemFake onClick={handleClose}>
          <Link href={ics(calendarEvent)} target="_blank">
            {" "}
            Ics{" "}
          </Link>
        </MenuItemFake>
      </Menu>
    </>
  );
};

export default AddEventToCalendar;

const getLocalUTC = () => {
  const hours = new Date().getTimezoneOffset();
  if (hours > 0) {
    return `-${hours / 60}`;
  } else {
    return `+${(hours * -1) / 60}`;
  }
};

const CustomButton = styled(Button)`
  width: max-content;
  font-size: 0.7rem;
  span {
    color: #ffffff;
  }
  :hover {
    color: #ffffff;
  }
`;

const MenuItemFake = styled(MenuItem)`
  .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
    color: #616161;
  }
  .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary:hover {
    color: #591AB6;
  }
  .MuiLink-underlineHover:hover {
    text-decoration: none;
  }
`;

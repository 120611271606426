import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../components/common/Texts";

// Material UI
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CalificationPercentage = (props) => {
  const { values } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    values && getData(values);
  }, [values]);

  const getData = (courses) => {
    const percentage = {
      10: {
        activities: [],
        prom: 0,
      },
      20: {
        activities: [],
        prom: 0,
      },
      70: {
        activities: [],
        prom: 0,
      },
    };
    let first = 0;
    let second = 0;
    let third = 0;
    courses.forEach((item) => {
      item.activities.forEach((act) => {
        if (act?.percentage_grade === "10") {
          const [max] =
            act.submissions
              .sort((a, b) => a.grade - b.grade)
              .filter((item) => item.state === "corregido" && !item.redo) || 0;
          percentage["10"].activities.push({ ...act, grade: max });
          first += max?.grade || 0;
        }
        if (act?.percentage_grade === "20") {
          const [max] =
            act.submissions
              .sort((a, b) => a.grade - b.grade)
              .filter((item) => item.state === "corregido" && !item.redo) || 0;
          percentage["20"].activities.push({ ...act, grade: max });
          second += max?.grade || 0;
        }
        if (act?.percentage_grade === "70") {
          const [max] =
            act.submissions
              .sort((a, b) => a.grade - b.grade)
              .filter((item) => item.state === "corregido" && !item.redo) || 0;
          percentage["70"].activities.push({ ...act, grade: max });
          third += max?.grade || 0;
        }
        percentage["10"].prom = (
          ((first / percentage["10"].activities.length) * 10) /
          100
        ).toFixed(1);
        percentage["20"].prom = (
          ((second / percentage["20"].activities.length) * 20) /
          100
        ).toFixed(1);
        percentage["70"].prom = (
          ((third / percentage["70"].activities.length) * 70) /
          100
        ).toFixed(1);
      });
    });
    console.log("percentage", percentage);
    setTotal(
      parseFloat(
        percentage["10"].prom + percentage["20"].prom + percentage["70"].prom
      ).toFixed(1)
    );
    setData(percentage);
  };

  const handleOpen = (value) => {
    setIsOpen(value === isOpen ? false : value);
  };

  return (
    <Wrapper>
      <Head>
        <Text color="#777" fontSize="18px">
          Notas de la asignatura
        </Text>
        <Text fontSize="18px">Promedio general</Text>
      </Head>
      <br />
      {data &&
        Object.keys(data).map((item) => {
          return (
            <>
              <Header open={isOpen === item} onClick={() => handleOpen(item)}>
                {isOpen !== item ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
                <Text fontSize="22px">
                  {data[item].prom}/<b>{item}%</b>
                </Text>
              </Header>
              <Body open={isOpen === item}>
                <Activity>
                  <Text fontWeight="bold" color="#555">
                    Actividad
                  </Text>
                  <Text fontWeight="bold" color="#555">
                    Estado
                  </Text>
                  <Text textAlign="center" fontWeight="bold" color="#555">
                    Nota obtenida
                  </Text>
                </Activity>
                {data[item].activities.map((act) => (
                  <Activity>
                    <Text color="#555">{act.name}</Text>
                    <Text fontWeight="bold" color="#777">
                      {(act.grade?.state || "pendiente").toUpperCase()}
                    </Text>
                    <Text textAlign="center" fontWeight="bold" color="#555">
                      {act.grade?.grade || "-"}/100
                    </Text>
                  </Activity>
                ))}
              </Body>
            </>
          );
        })}
      <Footer>
        <Text color="#777" fontSize="18px">
          Promedio total de la asignatura
        </Text>
        <Text fontSize="22px">
          {total}/<b>{100}%</b>
        </Text>
      </Footer>
    </Wrapper>
  );
};

export default CalificationPercentage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out 0.3s all;
  :hover {
    background-color: #ddd;
  }
`;

const Body = styled.div`
  border: ${(p) => (p.open ? "1px solid #eee" : "0px solid #eee")};
  transition: ease-in-out 0.3s all;
  height: ${(p) => (p.open ? "auto" : "0")};
  div {
    display: ${(p) => (p.open ? "grid" : "none")};
  }
`;

const Activity = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 0.5fr;
  border-bottom: 1px solid #eee;
  padding: 1rem;
`;

const Footer = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #eee;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Head = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #eee;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";
import InterestsChips from "../molecules/InterestsChips";

const SecondForm = (props) => {
  const { interests, values, setFieldValue } = props;

  return (
    <Wrapper>
      <TextFake fontSize="1.8rem" fontWeight="700" textColor="#222222">
        ¿Cuáles son tus intereses?
      </TextFake>
      <TextFake fontSize="1.3rem" fontWeight="400" textColor="#616161">
        ¡Elige todos los que quieras y te identifiquen! Recuerda que usaremos tu
        selección para personalizar el contenido que encuentras en Acrópolis.
      </TextFake>
      <WrapperChips>
        {interests.map((interest) =>
          values.intereses.some((x) => x === interest.id) ? (
            <InterestsChips
              key={interest.id}
              id={interest.id}
              name={interest.name}
              checked={true}
              setFieldValue={setFieldValue}
              values={values}
            />
          ) : (
            <InterestsChips
              key={interest.id}
              id={interest.id}
              name={interest.name}
              checked={false}
              setFieldValue={setFieldValue}
              values={values}
            />
          )
        )}
      </WrapperChips>
    </Wrapper>
  );
};

export default SecondForm;

const Wrapper = styled.div`
  text-align: center;
`;

const TextFake = styled(Text)`
  margin-top: 2rem;
`;

const WrapperChips = styled.div`
  display: flex;
  margin: 2rem auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";

const MicrocontentCardSkeleton = (props) => {
  return (
    <MicrocontentCardContainer>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={150}
      />
      <Body>
        <Skeleton animation="wave" height={30} />
        <Skeleton animation="wave" height={8} width="50%" />
      </Body>

      <Footer>
        <IconWrapper>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        </IconWrapper>
        <FakeButton animation="wave" />
      </Footer>
    </MicrocontentCardContainer>
  );
};

export default MicrocontentCardSkeleton;

const MicrocontentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  min-height: 200px;
  border: 1px solid #f2f2f2;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #fafafa;
  margin-top: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FakeButton = styled(Skeleton)`
  width: 100px;
  height: 50px;
`;

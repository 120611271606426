import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  BlockOutlined,
  ReportProblemOutlined,
  KeyboardArrowRightOutlined,
  CloseRounded,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import { ReactComponent as BlockedAccountSvg } from "../../../assets/media/svg/blocked-account.svg";
import { ReactComponent as AlertAccountSvg } from "../../../assets/media/svg/alert-account.svg";

export const BlockedAlert = ({ blocked = false, show = false }) => {
  const [showMessage, setShowMessage] = useState(true);
  const [heightDimension, setHeightDimension] = useState(0);
  const msgRef = useRef();
  useLayoutEffect(() => {
    if (msgRef.current) {
      setHeightDimension(msgRef.current.offsetHeight);
    }
  }, []);
  if (!show) {
    return null;
  }

  const handleClick = () => {
    setShowMessage(!showMessage);
  };
  return (
    <FakeContainer showMessage={showMessage} translateY={heightDimension}>
      <FakeStatusTitle blocked={blocked} onClick={handleClick}>
        {blocked ? <BlockOutlined /> : <ReportProblemOutlined />}{" "}
        {blocked ? "Cuenta bloqueada" : "Aviso de bloqueo"}
      </FakeStatusTitle>
      <FakeContentMessage blocked={blocked} ref={msgRef}>
        <FakeIconButton
          onClick={handleClick}
          blocked={blocked}
          showMessage={showMessage}
        >
          <CloseRounded />
        </FakeIconButton>
        {!!blocked ? (
          <BlockedAccountSvg width="50%" style={{ minWidth: "150px" }} />
        ) : (
          <AlertAccountSvg width="50%" style={{ minWidth: "150px" }} />
        )}
        <h5>
          {!!blocked
            ? "¡Tu cuenta ha sido bloqueada!"
            : "¡Tu cuenta será bloqueada proximamente!"}
        </h5>
        <span>
          {!blocked &&
            "Presentas demora en el pago de cuotas, por favor, ponte al día para evitar el bloqueo."}
        </span>
        {!blocked && (
          <FakeButton to="/informacion-academica?tab=finantial-state">
            <span>Ir a Estado Financiero</span> <KeyboardArrowRightOutlined />
          </FakeButton>
        )}
        <h6>
          Si crees que esto es un error, comunícate con Servicio al Cliente a
          través del formulario de{" "}
          <FakeLink to="/peticiones-reclamos">Peticiones y Reclamos</FakeLink>
        </h6>
        {!!blocked && (
          <FakeRestrictionsContainer>
            <p>Mientras tu cuenta se encuentre bloqueada no podrás:</p>
            <ul>
              <li>Acceder a tus cursos en Canvas</li>
            </ul>
          </FakeRestrictionsContainer>
        )}
      </FakeContentMessage>
    </FakeContainer>
  );
};

const FakeContainer = styled.div`
  transition: bottom 0.2s ease-in;
  bottom: ${(props) => (props.showMessage ? 0 : `-${props.translateY}px`)};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: fixed;
  z-index: 10;
  transform: translateX(-50%);
  min-width: 350px;
  box-shadow: 0px 0px 13px -1px rgba(102, 102, 102, 0.75);
  -webkit-box-shadow: 0px 0px 13px -1px rgba(102, 102, 102, 0.75);
  -moz-box-shadow: 0px 0px 13px -1px rgba(102, 102, 102, 0.75);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FakeContentMessage = styled.div`
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  max-width: 550px;
  padding: 30px 33px;
  padding-bottom: 50px;
  row-gap: 1rem;

  h5 {
    color: ${(props) => (!!props.blocked ? "#f8423f" : "#FFB800")};
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  h6 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  h6 > a {
    color: ${(props) => (!!props.blocked ? "#f8423f" : "#FFB800")};
  }
`;

const FakeStatusTitle = styled.span`
  align-items: center;
  background-color: ${(props) => (!!props.blocked ? "#f8423f" : "#FFB800")};
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: white;
  column-gap: 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  text-transform: uppercase;
  width: 250px;
  height: 50px;
  position: absolute;
  top: -50px;
  z-index: 99999;
  @media (max-width: 768px) {
    top: -50px;
  }
`;

const FakeRestrictionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 700;
  }
  ul {
    padding-top: 19px;
  }
  li {
    font-size: 16px;
    font-weight: 400;
  }
`;
const FakeButton = styled(Link)`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  color: white;
  background-color: #222222;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;

  :hover {
    color: white;
    filter: brightness(1.5);
  }
`;

const FakeLink = styled(Link)`
  :hover {
    filter: brightness(1.5);
  }
`;

const FakeIconButton = styled.div`
  margin: 0;
  background-color: ${(props) => (!!props.blocked ? "#f8423f" : "#FFB800")};
  position: fixed;
  color: #ffffff;
  top: 10px;
  right: 10px;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  padding: auto;

  :hover {
    filter: brightness(1.5);
  }

  svg {
    font-size: 1.2rem;
  }
  @media (max-width: 768px) {
    top: 10px;
  }
`;

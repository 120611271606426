import axios from "axios";

export async function getUserRegistrations(ondemandId, repoId) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/obj_ap/partner/${ondemandId}?partner_id=${repoId}`;
  try {
    const req = await axios.get(URL);
    return req.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function registerUser(ondemandId, repoId, objApId, type = "learning_object") {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/enroll_alab/${ondemandId}?partner_id=${repoId}&object_id=${objApId}&type=${type}`;
  try {
    const req = await axios.post(URL);
    return req.data.response_data;
  } catch (error) {
    return { error };
  }
}

import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getInterests } from "../api/interests";
import { interestsActions } from "../actions";

function* interestsRequest() {
  try {
    const res = yield call(getInterests);
    yield put(interestsActions.getInterestsSuccess(res.data.response_data));
  } catch (error) {
    yield put(interestsActions.getInterestsFail(error));
  }
}

function* interestsWatcher() {
  yield takeEvery(types.GET_INTERESTS_REQUEST, interestsRequest);
}

export { interestsWatcher };

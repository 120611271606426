import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TabsComponent from "../../components/common/TabsComponent";
import Achievements from "../../components/templates/my-progress/Achievements";
import Courses from "../../components/templates/progress/Courses";
import MyTasks from "../../components/templates/progress/MyTasks";
import Programs from "../../components/ui/organisms/profile/Programs";
import { useQueryParams } from "../../hooks/useQueryParams";
import { programsActions } from "../../redux/actions";

const Progress = () => {
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.programs.programs);

  const queryTab = useQueryParams();
  const initialTab = queryTab.get("tab") === "mis-asignaturas" ? 1 : 0;

  // EFFECTS
  useEffect(() => {
    if (programs === null) {
      dispatch(programsActions.getProgramsRequest());
    }
  }, [programs]);

  const tabs = [
    { label: "Mis programas", id: 0 },
    { label: "Mis asignaturas", id: 1 },
    { label: "Mis tareas", id: 3 },
    { label: "Mis logros", id: 2 },
  ];
  // STATE
  const [tab, setTab] = useState(initialTab);

  // FUNCTIONS
  function handleTab(e, value) {
    setTab(value);
  }

  // RETURN
  return (
    <ProgressContainer>
      <TabsWrapper>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </TabsWrapper>
      <ContentWrapper>
        <ToggleMain programs={programs} setTab={setTab} tab={tab} />
      </ContentWrapper>
    </ProgressContainer>
  );
};

export default Progress;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const TabsWrapper = styled.div`
  width: calc(100% - 6rem - 70px);
  position: fixed;
  left: 70px;
  top: 70px;
  z-index: 10;
  border-top: 1px solid #f7f7f7;
  padding: 0 3rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    left: 0;
    width: calc(100% - 6rem);
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

function ToggleMain(props) {
  const { tab, programs, setTab } = props;
  switch (tab) {
    case 1:
      return <Courses tabsFake={setTab} />;
    case 0:
      return <Programs programs={programs} report={true} />;
    case 2:
      return <Achievements />;
    case 3:
      return <MyTasks />;
    default:
      return null;
  }
}

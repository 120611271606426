import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import CardSubjectModule from "../../../../components/ui/organisms/subject/CardSubjectModule";
import SimpleLoading from "../../../../components/common/SimpleLoading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { subjectsActions } from "../../../../redux/actions";

export const SubjectModules = (props) => {
  const {
    mod,
    idCourse,
    subject,
    origin,
    color
  } = props;

  // Redux
  const { user } = useSelector((state) => state.auth);
  const { tags } = useSelector((state) => state.enrollment);
  const dispatch = useDispatch();

  const { type } = subject;

  // Effects
  useEffect(() => {
    if (tags === null) {
      dispatch(subjectsActions.getSubjectsRequest(user.repo_id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);


  if (!mod) return <SimpleLoading />;

  const nextSurveyValues = mod.map((item, index) => {
    return item.survey;
  })

    return (
      <SubjectForModules>
        <h2>Cursos que componen esta ruta:</h2>
        <WrapperCards>
          {mod
            .sort((a, b) => a.order - b.order)
            .map((module, index) => (
              <CardSubjectModule
                type={type}
                key={index}
                module={module}
                idCourse={idCourse}
                origin={subject.type === "DEMO" ? "demo" : origin || "alab"}
                last={mod.length === index + 1}
                nextSurvey={nextSurveyValues[index + 1]}
                color={color}
              />
            ))}
        </WrapperCards>
      </SubjectForModules>
    );

  };

  const SubjectForModules = styled.div`
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h2 {
    font-size: 20px;
    color: #535353;
    font-weight: 500;
  }

  @media (width < 768px) {
    gap: 6rem;
    width: 95vw;
  }
`;

  const WrapperCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 80px;
  max-width: 100%;

  @media (width < 768px) {
    gap: 70px;
    align-items: start;
  }
`;

import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { getResourceById } from "../../redux/api/resource";
import { options } from "../../utils/snackStyle";

const useResource = (id, type) => {
  const [resource, setResource] = useState(null);
  const [openSnackbar] = useSnackbar(options);

  useEffect(() => {
    fetchData();
  }, [id, type]);

  const fetchData = async () => {
    const request = await getResourceById(id, type);
    if (request.error) {
      openSnackbar("¡No se pudo obtener la información del recurso!");
      setResource("error");
    } else {
      setResource(request);
    }
  };

  return { resource };
};

export default useResource;

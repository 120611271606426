import axios from "axios";

export async function getStudyPlan(id) {
  const URL = `${window.location.origin}/mock/studyPlan/infoPrograma.json`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function getCalificationActivity() {
  const URL = `${window.location.origin}/mock/studyPlan/activityCalification.json`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function getStudyPlanFake(id, repo_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/assignments_by_program/${id}?repo_id=${repo_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data.assignments;
  } catch (error) {
    return { error };
  }
}

export async function getAssignmentRelated(id, repo_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/study_plan/${id}?repo_id=${repo_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data.assignments;
  } catch (error) {
    return { error };
  }
}

export async function getRelatedPrograms(id, repo_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/program/related_study_plans/${id}?repo_id=${repo_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function postAssignmentPrograms(repo_id, body) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/enroll/${repo_id}`;
  try {
    const response = await axios.post(URL, body);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function getProgramById(program_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/program/${program_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function getSubmissionByUser(repo_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/submissions_by_partner/${repo_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function createOpportunity(body) {
  console.log("body", body);
  // const URL = `https://sisapi-test.aden.org/api/v1/crm/lead`;

  const URL = `${process.env.REACT_APP_SISAPI}/v1/crm/lead`;
  try {
    const response = await axios.post(URL, {
      ...body,
      utm_source: 465,
      utm_medium: 612,
      utm_campaign: "Proyecto100",
    });
console.log('response', response)
    return response.data;
  } catch (error) {
    throw Error(error);
  }
}

import React from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const QuestionsProduct = (props) => {
  const { frequent_questions } = props;
  return (
    <Container>
      {frequent_questions.map((question) => {
        return (
          <AccordionContainer key={question.questrion_title}>
            <AccordionSumm
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Title>{question.question_title}</Title>
            </AccordionSumm>
            <AccordionDetails>
              <Typography>{question.answer}</Typography>
            </AccordionDetails>
          </AccordionContainer>
        );
      })}
    </Container>
  );
};

export default QuestionsProduct;
const Container = styled.div`
  margin: 1rem;
`;

const Title = styled(Typography)`
  color: #222222;
  font-weight: bold;
`;
const AccordionContainer = styled(Accordion)`
  margin-bottom: 1rem;
  align-items: center;
  box-shadow: none;
  &::before {
    content: "";
    width: 0px;
    height: 0px;
  }
`;
const AccordionSumm = styled(AccordionSummary)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: none;
  border-radius: 6px;
`;

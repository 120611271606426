import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import TabsComponent from "../../components/common/TabsComponent";
import EventsMain from "../../components/templates/events/EventsMain";
import ForumMain from "../../components/templates/forum/ForumMain";
import { titleHTML } from "../../helpers/title.helper";

const Events = () => {
    titleHTML("Talleres presenciales");

    const nameCompany = useSelector(
        (state) => state?.ondemand?.ondemand?.acropolis_title
    );
    const OndemandOPTIONS = [
        { id: 0, label: `${nameCompany}` },
        { id: 1, label: "Eventos ADEN" },
    ];

    const { activeRol } = useSelector((state) => state.auth);

    //STATES
    const [currentTab, setCurrentTab] = useState(0);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    const ActiveTabContent = () => {
        if (currentTab === 0 && activeRol === "ondemand") {
            return <EventsMain />;
        }

        if (currentTab === 1) {
            return <ForumMain />;
        }
        return <div></div>;
    };

    return (
        <>
            <TabsWrapper>
                <TabsComponent
                    tab={currentTab}
                    handleTab={changeTab}
                    tabs={OndemandOPTIONS}
                />
            </TabsWrapper>
            <Container>
                <ActiveTabContent />
            </Container>
        </>
    );
};

export default Events;

const Container = styled.div`
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 30px;
`;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 10;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;
    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Material UI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DescriptionIcon from "@mui/icons-material/Description";

// Hooks
import useTheme from "../../../../../hooks/useTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDescription = (props) => {
  const { open, setOpen, description } = props;
  const { primary } = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<DescriptionIcon />}
      >
        CONSIGNAS
      </Button>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitleFake color={primary}>{"Descripción"}</DialogTitleFake>
        <DialogContent>
          <DescriptionInitial>
            <ParseHtml>{parse(sanitizerHtml(description))}</ParseHtml>
          </DescriptionInitial>
        </DialogContent>
        <DialogActions>
          <ButtonFake color="primary" variant="outlined" onClick={handleClose}>
            Cerrar
          </ButtonFake>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDescription;

const DialogTitleFake = styled(DialogTitle)`
  color: ${(p) => p.color};
  text-align: center;
  position: relative;
  text-transform: uppercase;
`;

const DescriptionInitial = styled.span`
  font-size: 0.7rem;
  color: #8a8a8a;
  font-weight: 700;
  margin: 0.8rem;
`;
const ParseHtml = styled.div`
  width: 70%;
  max-width: auto;
  margin: 0 auto;
  * {
    transform: scale(1.3);
  }
`;

const ButtonFake = styled(Button)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

import React, { useState } from "react";
import styled from "styled-components";

import {
  // CalendarTodayRounded,
  ScheduleRounded,
  ShareOutlined,
} from "@mui/icons-material";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import { Button, IconButton } from "@mui/material";
// import EventInfo from '../../../templates/events/EventInfo';
import Text from "../../../common/Text";
import moment from "moment";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import AddEventToCalendar from "../../shared/AddEventToCalendar";
import RegisterEventModal from "../campus/RegisterEventModal";
import { getDynamicEvents } from "../../../../redux/api/events/events";
// import SharedResource from '../../../common/SharedResource';

const EventCard = (props) => {
  const { value, handleOpenModal, currentTab, setFlagForEvents } = props;

  // STATES
  const [openModal, setOpenModal] = useState(false);

  moment.updateLocale("es", {
    months:
      "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
        "_"
      ),
    monthsShort:
      "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

  // FECHAS
  function parseMonth(month) {
    const FINAL_MONTH = {
      ENE: "Enero",
      FEB: "Febrero",
      MAR: "Marzo",
      ABI: "Abril",
      MAY: "Mayo",
      JUN: "Junio",
      JUL: "Julio",
      AGO: "Agosto",
      SEP: "Septiembre",
      OCT: "Octubre",
      NOV: "Noviembre",
      DIC: "Diciembre",
    };
    return FINAL_MONTH[month] || month;
  }

  const currentDate = new Date();
  const formatCurrentDate = moment(currentDate).locale("es").format("LL");

  const getLocalUTC = () => {
    const hours = new Date().getTimezoneOffset();
    if (hours > 0) {
      return `-${hours / 60}`;
    } else {
      return `+${(hours * -1) / 60}`;
    }
  };

  const utc = getLocalUTC();

  const eventDateBegin = new Date(
    value.date_begin.replaceAll("-", "/") + `${utc} UTC`
  );
  const eventDateEnd = new Date(
    value.date_end.replaceAll("-", "/") + `${utc} UTC`
  );

  const formatEventDateBegin = moment(eventDateBegin).locale("es").format("LL");
  const formatEventDateEnd = moment(eventDateEnd).locale("es").format("LL");

  const getHourNumber = (hour) => {
    return parseInt(hour.substring(0, 2));
  };

  const getMinuteNumber = (hour) => {
    return hour.substring(3, 5);
  };

  const GetValidDate = () => {
    if (isNaN(eventDateBegin.getTime())) {
      return value.day + " de " + parseMonth(value.month);
    } else {
      return (
        <span>
          {formatEventDateBegin}{" "}
          {formatEventDateEnd != formatEventDateBegin
            ? " - " + formatEventDateEnd
            : null}
        </span>
      );
    }
  };

  // FUNCIONES PARA ABRIR MODAL DE REGISTRO
  const handleRegister = () => {
    handleClick();
  };

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  const getDynamicEvent = async (modality, limit, timelapse) => {
    const response = await getDynamicEvents(modality, limit, timelapse);
  };

  return (
    <>
      {openModal && (
        <RegisterEventModal
          event={value}
          handleClick={handleClick}
          getDynamicEvent={getDynamicEvent}
          tabsFake={currentTab}
          setFlagForEvents={setFlagForEvents}
        />
      )}
      <Container>
        <Header>
          <CustomImg
            src={
              value.image_url ? value.image_url : "/assets/event-default.jpg"
            }
            alt="event"
          />
          {value.is_registered && (
            <ChipRegistered>
              <DoneRoundedIcon
                style={{ color: "#E6FFC9", fontSize: "0.9rem" }}
              />
              INSCRIPTO
            </ChipRegistered>
          )}
          {value.category.name && (
            <ChipTypeEvent>{value.category.name}</ChipTypeEvent>
          )}
        </Header>

        <Body>
          <Text fontSize="1rem" fontWeight="700" component="h1">
            {value.publication_name}
          </Text>
          <DatesContainer>
            <DateWrapper>
              <EventRoundedIcon htmlColor="#616161" fontSize="small" />
              <Text fontSize="0.9rem" textColor="#616161" component="span">
                <GetValidDate></GetValidDate>
              </Text>
            </DateWrapper>

            {(eventDateEnd >= currentDate ||
              formatEventDateBegin === formatCurrentDate ||
              formatEventDateEnd === formatCurrentDate) && (
              <DateWrapper>
                <ScheduleRounded htmlColor="#616161" fontSize="small" />
                <Text fontSize="0.9rem" textColor="#616161" component="span">
                  {value.hour_begin}
                  {" (AR) |"}{" "}
                  {getHourNumber(value.hour_begin) -
                    2 +
                    ":" +
                    getMinuteNumber(value.hour_begin)}{" "}
                  {" (PA) |"}{" "}
                  {getHourNumber(value.hour_begin) -
                    3 +
                    ":" +
                    getMinuteNumber(value.hour_begin)}{" "}
                  {" (CR) "}
                </Text>
              </DateWrapper>
            )}
          </DatesContainer>
        </Body>
        <Footer>
          {!!value.webinar_url && (
            <>
              {eventDateBegin < currentDate ? (
                <IconButton
                  onClick={() =>
                    handleOpenModal(
                      value.webinar_url,
                      value.crm_id,
                      value.publication_name,
                      "evento-pasado"
                    )
                  }
                  size="large"
                >
                  <ShareOutlined color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() =>
                    handleOpenModal(
                      value.webinar_url,
                      value.crm_id,
                      value.publication_name,
                      "evento-actual"
                    )
                  }
                  size="large"
                >
                  <ShareOutlined color="primary" />
                </IconButton>
              )}
            </>
          )}

          {!value.is_registered && eventDateEnd > currentDate && (
            <Button
              onClick={handleRegister}
              color="primary"
              variant="contained"
            >
              Inscribirme
            </Button>
          )}
          {!!value.is_registered &&
          (eventDateBegin >= currentDate ||
            formatEventDateBegin === formatCurrentDate) ? (
            <DivAddEventToCalendar>
              <CustomButton
                variant="contained"
                color="primary"
                component="a"
                href={value.webinar_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ingresar al evento
              </CustomButton>
              <AddEventToCalendar oneEvent={value} />
            </DivAddEventToCalendar>
          ) : !!value.is_registered &&
            value.registration_url === "" &&
            value.webinar_url != "" &&
            (eventDateBegin >= currentDate ||
              formatEventDateBegin === formatCurrentDate) &&
            !value.is_registered ? (
            <DivAddEventToCalendar>
              <CustomButton
                variant="contained"
                color="primary"
                component="a"
                href={value.webinar_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ingresar al evento
              </CustomButton>
              <AddEventToCalendar oneEvent={value} />
            </DivAddEventToCalendar>
          ) : value.webinar_url != "" &&
            ((eventDateBegin < currentDate &&
              //isForoSinc === false &&
              eventDateEnd < currentDate) ||
              formatEventDateBegin === formatCurrentDate) ? (
            value.webinar_url.toLowerCase().includes("www.aden.org") && (
              <CustomButton
                variant="contained"
                color="primary"
                component="a"
                href={value.webinar_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Volver a ver
              </CustomButton>
            )
          ) : value.webinar_url != "" &&
            ((eventDateBegin < currentDate && eventDateEnd >= currentDate) ||
              formatEventDateBegin === formatCurrentDate) ? (
            <DivAddEventToCalendar>
              <CustomButton
                variant="contained"
                color="primary"
                component="a"
                href={value.webinar_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ingresar al evento
              </CustomButton>
              <AddEventToCalendar oneEvent={value} />
            </DivAddEventToCalendar>
          ) : null}

          {/** Mas informacion */}
          {/* {true ? (
                <CustomButton
                    variant="contained"
                    color="primary"
                    component="a"
                    href={value.webinar_url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Ingresar al evento
                </CustomButton>
            ) : (
                <EventInfo event={value} />
            )} */}
        </Footer>
      </Container>
    </>
  );
};

export default EventCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  min-height: 300px;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 768px) {
    min-height: max-content;
  }
`;

const Header = styled.div`
  position: relative;
`;

const ChipTypeEvent = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 5px;
  background-color: #ffffff;
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #a8a8a8;
  padding: 0.2rem 0.5rem;
`;

const ChipRegistered = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  gap: 0.2rem;
  border-radius: 50px;
  background-color: #4caf50;
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #e6ffc9;
  padding: 0.2rem 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const CustomImg = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;

  @media screen and (max-width: 1221px) {
    height: 120px;
  }

  @media screen and (max-width: 768px) {
    height: 100px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
`;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  row-gap: 0.5rem;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: #616161;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #fafafa;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const CustomButton = styled(Button)`
  width: max-content;
  span {
    font-size: 0.7rem;
    color: #ffffff;
  }
  :hover {
    color: #ffffff;
  }
`;

const DivAddEventToCalendar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
`;

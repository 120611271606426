import { Icon } from "@iconify/react";
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";
import { useHistory } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import useTheme from "../../../../hooks/useTheme";

const CardSubjectSurvey = (props) => {

  const { module, openCard, last, color, releaseSequence } = props;

  const history = useHistory();

  const { primary } = useTheme()

  const survey = {
    link: module?.survey?.link || false,
    id: module?.survey?.id || false,
    done: module?.survey?.done || false,
  };

  const hashSurvey = encode.encode(JSON.stringify(survey), "base64");

  const handleClickSurvey = () => {
    if (!(releaseSequence ? false : !module.onStudy)) {
      history.push(`/view/survey/${hashSurvey}`);
    }
  };

  return (
    <Wrapper onStudy={module.onStudy} title={module.description} color={primary}>
      <Header>
        <Icon icon="solar:danger-circle-bold" width="24px" height="24px" style={{ color: color, marginRight: "-8px" }} />
        <Text color="#535353" fontSize="14px" fontWeight="500">
          Recuerda completar la encuesta para continuar con tu cursado.
        </Text>
      </Header>
      <Body>
        <ImgWrapper>
          <LazyImg
            src="/assets/aden/survey-img.png"
            width="280px"
            height="150px"
          />
        </ImgWrapper>
        <TextWrapper>
          <Text fontSize="22px" fontWeight="700" color="var(--primary-three)">
            Tu opinión es muy importante para seguir mejorando.
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#535353">
            <i>¿Nos ayudas completando una breve encuesta?</i>
          </Text>
        </TextWrapper>
        <ButtonWrapper color={color}>
          <Button disabled={releaseSequence ? false : !module.onStudy} onClick={handleClickSurvey} color={color}>
            Responder encuesta
          </Button>
        </ButtonWrapper>
        <Check check={module.progress >= 100 || module?.survey?.done} survey={true} color={primary}>
          <Icon icon="heroicons-outline:check" width="32px" height="32px" style={{ color: "var(--secondary-one)" }} />
          <Line openCard={openCard} last={last} survey={true} color={primary} />
        </Check>
      </Body>
      <SuggestionInfo>
        <IconContainer>
          <Icon icon="fa6-regular:lightbulb" width="24px" height="24px" />
        </IconContainer>
        <label>
          Te sugerimos <b>realizar la encuesta</b> cuando ya tengas una <b>visión integral</b> sobre el <b>profesor tutor y el contenido de la asignatura.</b>
        </label>
      </SuggestionInfo>
    </Wrapper>
  )
}

export default CardSubjectSurvey;

const Wrapper = styled.div`
  width: calc(100% - 4rem);
  height: 300px;
  border-radius: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 2px solid ${(props) => props.color};
  box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
  background-color: var(--secondary-two);
  background-image: url('/assets/aden/background-card-subject.png');
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center center;
  transition: height .5s ease;
  
  @media (width < 768px) {
    width: 95%;
    height: 600px;
  }
`;

const Header = styled.div`
  position: absolute;
  right: 0;
  top: -40px;
  height: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: calc(100% - 4rem);
  padding: 1rem 2rem;

  @media (width < 768px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    padding-bottom: 6rem;
  }
`;

const ImgWrapper = styled.div`
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (width < 768px) {
        width: 100%;
    }
`

const TextWrapper = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;

  & > div:first-child {
    width: 350px;
  }

  @media (width < 768px) {
    width: 100%;
    gap: 16px;

    & > div:first-child {
      width: 330px;
    }
  }
`
const ButtonWrapper = styled.div`
  width: 33%;
  height: 150px;
  display: flex;
  align-items: end;
  padding-left: 20px;

  @media (width < 768px) {
    width: 100%;
    padding-left: 0;
  }
`

const Button = styled.button`
  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 48px;
  padding: 0 8px;
  font-size: 14px;
  color: var(--secondary-one);
  :disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
    :hover {
      box-shadow: none;
    }
  }
  :hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`

const Check = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left:${(props) => props.survey ? "-58px" : "-90px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.color};
  border-radius: 40px;
  background-color: ${(props) => props.check ? props.color : "var(--secondary-one)"};
`

const Line = styled.div`
  width: 2px;
  height: ${(props) => props.last ? "0px" : props.openCard ? "250px" : props.nextSurvey ? "200px" : props.survey ? "230px" : "130px"};
  position: absolute;
  bottom: ${(props) => props.openCard ? "-260px" : props.nextSurvey ? "-210px" : props.survey ? "-240px" : "-140px"};
  background-color: ${(props) => props.color};
  transition: all .5s ease;
`

const SuggestionInfo = styled.div`
    width: 100%;
    position: absolute;
    bottom: 1rem;
    left: 10rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    @media (width < 1600px) {
        left: 3rem;
    }

    @media (width < 768px) {
        left: .5rem;
    }
`


const IconContainer = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #F9F5EC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #C29F43;
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EventItem from "../campus/EventItem";
import { useDispatch, useSelector } from "react-redux";
import { utilsActions } from "../../../../redux/actions";
import { getDynamicEvents, getOndemandEvents } from "../../../../redux/api/events/events";
import AdenEventsSkt from "../../../skeletons/AdenEventsSkt";
import NoResultsFound from "../../../common/NoResultsFound";
import { checkRoles } from "../../../../helpers/roles.helper";

const OptionWrapper = (props) => {
    const { currentTab, firstSection } = props;

    // REDUX
    const dispatch = useDispatch();
    const countries = useSelector((state) => state.utils.countries);
    const ondemandId = useSelector((state) => state?.auth?.user?.ondemand_id);

    //EFFECTS
    const [event, setEvent] = useState(null);
    const [eventFake, setEventFake] = useState([]);

    useEffect(() => {
        setEvent(null);
        if (currentTab === 0) {
            getCompanyEvents(ondemandId)
        }
        if (currentTab === 1) {
            getDynamicEvent("all", 10, "next");
        }
    }, [currentTab]);

    // comentado hasta que suban el back a prod

    useEffect(() => {
        if (event !== null) {
            if (event.length > 0) {
                if (!checkRoles("alumno")) {
                    setEventFake(
                        event.filter((value) => !value.only_for_students)
                    );
                } else {
                    setEventFake(event);
                }
            }
        }
    }, [event]);

    // FUNCTIONS
    const getDynamicEvent = async (modality, limit, timelapse, ondemand_id) => {
        const response = await getDynamicEvents(
            modality,
            limit,
            timelapse,
            ondemand_id
        );
        if (response.error) {
            setEvent([]);
        } else {
            setEvent(response);
        }
    };

    const getCompanyEvents = async (ondemandId) => {
        const response = await getOndemandEvents(ondemandId)
        if (response.error || response === "No se encontraron eventos") {
            setEvent([])
            return;
        }
        setEvent(response);
    } 

    // EFFECTS traer countries
    useEffect(() => {
        if (countries === null) {
            dispatch(utilsActions.getCountriesRequest());
        }
    }, []);

    // RETURN
    if (event === null) {
        return <AdenEventsSkt firstSection={firstSection}/>;
    }

    return (
        <OverflowWrapper firstSection={firstSection}>
            <Overflow>
                {event.length > 0 ? (
                    eventFake.length > 0 ? (
                        <>
                            {event.map((item) => (
                                <EventItem
                                    tabsFake={currentTab}
                                    getDynamicEvent={getDynamicEvent}
                                    countries={countries}
                                    event={item}
                                    key={item?.ondemand_id}
                                    currentTab={currentTab}
                                />
                            ))}
                        </>
                    ) : (
                        <NoResultsFound message="¡No se encontraron eventos próximos!" />
                    )
                ) : (
                    <NoResultsFound message="¡No se encontraron eventos próximos!" />
                )} 
            </Overflow>
        </OverflowWrapper>
    );
};

export default OptionWrapper;

const OverflowWrapper = styled.div`
    position: relative;
    margin-bottom: auto;
    height: ${(p) => p.firstSection ? "340px" : "250px" };
`;

const Overflow = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    row-gap: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: transparent transparent;
    padding-bottom: 8px;
    border-radius: 10px;

    ::-webkit-scrollbar {
        width: 0px;
    }
`;

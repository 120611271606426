import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getFavsRep } from "../redux/api/utils";

const useFavorites = () => {
  // STATES
  const [favs, setFavs] = useState(null);

  const { user } = useSelector((state) => state.auth);

  // EFFECT
  useEffect(() => {
    getFavs();
  }, []);

  // FUNCTION
  const getFavs = async () => {
    const request = await getFavsRep(user.repo_id);
    setFavs(request);
  };

  return {
    favs,
  };
};

export default useFavorites;

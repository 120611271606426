import { useEffect, useState } from "react";
import { getAllChallenges } from "../../redux/api/challenges/challenges";
import { useSelector } from "react-redux";

const useChallenges = () => {
    // REDUX
    const repoId = useSelector((state) => state?.auth?.user?.ondemand_id);

    // STATES
    const [allChallenges, setAllChallenges] = useState(null);
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(false);

    // EFFECTS
    useEffect(() => {
        allChallenges === null && getChallenges();
        categories === null && getCategories();
    }, []);

    // FUNCTIONS
    const getChallenges = async () => {
        setLoading(true);

        try {
            const res = await getAllChallenges(repoId);
            setAllChallenges(res);
            setLoading(false);
        } catch (error) {
            setAllChallenges(false);
            setLoading(false);
        }
    };

    const getCategories = async () => {
        setLoading(true);

        try {
            const res = await getAllChallenges(repoId, `?categoria=true`);
            setCategories(res);
            setLoading(false);
        } catch (error) {
            setCategories(false);
            setLoading(false);
        }
    };

    return { categories, allChallenges, loading };
};

export default useChallenges;

import React from "react";
import Text from "../../../common/Text";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import { loginSchema } from "../../../../utils/schemas";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";
import { useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import { options } from "../../../../utils/snackStyle";
import { useSnackbar } from "react-simple-snackbar";

const LoginForm = (props) => {
    const { primaryColor } = props;

    // STATE
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar] = useSnackbar(options);
    // FORMIK
    const initialValues = {
        user: "",
        password: "",
    };

    // REDUX
    const dispatch = useDispatch();
    const { loadingLogin, errorRoles, roles, error, loadingGetUser, token } =
        useSelector((state) => state.auth);

    useEffect(() => {
        if (!!errorRoles) {
            openSnackbar("Usuario y/o contraseña incorrectos");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorRoles, dispatch]);

    useEffect(() => {
        if (error) {
            openSnackbar("¡Ocurrió un error al acceder con este usuario!");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    // FUNCTIONS
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (values) => {
        dispatch(authActions.loginTokenRequest(values));
    };

    useEffect(() => {
        !!roles && handleDirectLogin(roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    const handleDirectLogin = (roles) => {
        if (!roles.includes("ondemand") && !roles.includes("alumno")) {
            openSnackbar("¡El usuario no tiene los permisos de empresa!");
        } else {
            if (roles.includes("ondemand")) {
                dispatch(authActions.getDataRequest("ondemand"));
            } else {
                window.open(
                    `${process.env.REACT_APP_ACROPOLIS}/autologin?rol=alumno&token=${token}&redirecturi=/campus`,
                    "_self"
                );
            }
        }
    };

    return (
        <Wrapper>
            <TextFake color={"#000"}>Iniciar sesión</TextFake>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {({ values, handleSubmit, handleChange: formikHandleChange }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        {/* USER */}
                        <FormField
                            color={primaryColor}
                        >
                            <FieldFake
                                name="user"
                                type="email"
                                value={values.user}
                                placeholder="Ingresa tu correo electrónico"
                                fullWidth
                                color={primaryColor}
                            />
                            {
                                values.user !== "" && <FieldLabel>Correo electrónico</FieldLabel>
                            }
                            <ErrorMessage name="user" component={CustomError} />
                        </FormField>

                        {/* PASSWORD */}
                        <PassWrapper
                            color={primaryColor}
                        >
                            <FieldFake
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={values.password}
                                placeholder="Contraseña"
                                fullWidth
                                color={primaryColor}
                            />
                            {
                                values.password !== "" && <FieldLabel>Contraseña</FieldLabel>
                            }
                            <InputAdornmentFake position="end">
                                <IconButton
                                    size="small"
                                    onClick={toggleShowPassword}
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            </InputAdornmentFake>
                            <ErrorMessage
                                name="password"
                                component={CustomError}
                            />
                        </PassWrapper>

                        <ButtonWrapper
                            color={primaryColor}
                        >
                            {!loadingLogin && !loadingGetUser && (
                                <ButtonFake
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                    Ingresar al campus
                                </ButtonFake>
                            )}
                            {loadingLogin && !loadingGetUser && (
                                <Center>
                                    <CircularProgress
                                        size="1rem"
                                        color="secondary"
                                    />{" "}
                                    <Text
                                        fontSize="0.7rem"
                                        fontWeight="600"
                                        textColor="#bfbfbf"
                                    >
                                        Ingresando
                                    </Text>
                                </Center>
                            )}
                            {loadingGetUser && loadingLogin && (
                                <Center>
                                    <CircularProgress
                                        size="1rem"
                                        color="secondary"
                                    />{" "}
                                    <Text
                                        fontSize="0.7rem"
                                        fontWeight="600"
                                        textColor="#bfbfbf"
                                    >
                                        Obteniendo información
                                    </Text>
                                </Center>
                            )}
                            <ButtonForgot
                                onClick={() =>
                                    window.open(
                                        "https://fa.aden.org/password/forgot"
                                    )
                                }
                            >
                                Olvidé mi contraseña
                            </ButtonForgot>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </Wrapper>
    );
};

export default LoginForm;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    height: 100%;

    @media (max-width: 1200px) {
        width: 70%;
    }

    @media (max-width: 768px) {
        width: 80%;
    }
`;

const CustomError = styled.span`
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    color: inherit;
    margin-left: 1rem;
    margin-top: 0.2rem;
`;
const PassWrapper = styled.div`
    position: relative;
    color: ${(p) => p.color};
`;
const FieldFake = styled(Field)`
    font-size: 12px;
    outline: none;
    border: 1px solid ${(p) => p.color};
    padding: 0.5rem 3.5rem 0.5rem 1.5rem;
    height: 20px;
    width: calc(100% - 5rem);
    border-radius: 100px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
        box-shadow: 0 0 0px 1000px #f5f5f5 inset;
        -webkit-text-fill-color: #000;
    }
`;

const FormField = styled.div`
    position: relative;
    color: ${(p) => p.color};
`;

const FieldLabel = styled.label`
    position: absolute;
    top: -6px;
    left: 20px;
    background-color: #f5f5f5;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 10px;
    @media (max-width: 768px) {
        left: 15px;
        font-size: 10px;
    }
`

const InputAdornmentFake = styled(InputAdornment)`
    position: absolute;
    top: 50%;
    right: calc(-10px + 2rem);
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: ${(p) => p.color};
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    /* padding: 0 15rem; */
    width: 100%;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const ButtonFake = styled(Button)`
    border-radius: 48px;
    font-size: 0.7rem;
    font-weight: 500;
    width: 50%;

    @media (max-width: 1200px) {
        font-size: 12px;
        width: 60%;
    }
`;

const ButtonForgot = styled(Button)`
    font-size: 0.7rem;
    font-weight: 500;
    color: inherit;

    :hover {
        font-weight: 600;
    }

    @media (max-width: 1200px) {
        font-size: 12px;
        width: 60%;
        font-weight: 600;

        :hover {
            font-weight: 700;
        }
    }

    &&.MuiButtonBase-root.MuiButton-root
        .css-16uy7nq-MuiButtonBase-root-MuiButton-root:hover {
        background-color: #ffffff 8% !important;
    }
`;

const TextFake = styled.h3`
    color: ${(p) => p.color};
    font-size: 20px;
    font-weight: 700;
    text-align: center;
`;

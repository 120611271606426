import React from "react";
import styled from "styled-components";
import BodyPopup from "./BodyPopup";
import ControlsSteps from "./ControlsSteps";

const CardPopup = (props) => {
    const {
        currentSlide,
        handleBackSlide,
        handleNextSlide,
        handleClose,
        currentStep,
        popupLength,
    } = props;

    const URL = process.env.REACT_APP_CMS_ADEN || "https://cms.aden.org";

    const image = currentSlide?.image?.data?.attributes?.url
        ? URL + currentSlide?.image?.data?.attributes?.url
        : currentSlide?.image_url;

    return (
        <Card>
            <ImageWrapper>
                <img src={image} />
            </ImageWrapper>
            <BodyWrapper>
                <BodyPopup
                    currentSlide={currentSlide}
                    handleClose={handleClose}
                />
                <ControlsSteps
                    handleBackSlide={handleBackSlide}
                    handleNextSlide={handleNextSlide}
                    popupLength={popupLength}
                    currentStep={currentStep}
                />
            </BodyWrapper>
        </Card>
    );
};

export default CardPopup;

const Card = styled.div`
    width: 800px;
    background-color: white;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    height: auto;
    overflow: hidden;
    outline: none;

    @media (max-width: 768px) {
        width: 85%;
    }
`;

const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const BodyWrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

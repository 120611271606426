import React from "react";
import styled from "styled-components";
// import { Href, Text } from '../common/Texts';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Card } from "@mui/material";
// import { Card } from '../common/Card';
// import Product from './Product';
import { Text } from "../../common/Texts";
import Product from "../../ui/organisms/ecommerce/ProductLanding";
import { useHistory } from "react-router-dom";

const Banner = (props) => {
  const { name, categories, description, price, currency, about } = props;

  const { goBack } = useHistory();

  return (
    <BannerContent>
      <InfoBanner>
        <NavigatioCampus>
          <ButtonFake onClick={() => goBack()}>
            <ArrowBackIcon /> Ir al campus
          </ButtonFake>
        </NavigatioCampus>
        <Absolute>
          <Premium>premium</Premium>
        </Absolute>
        <Text fontSize="2rem" fontWeight="900" color="white">
          {name}
        </Text>
        <Category>
          {categories.map((category) => (
            <Text fontSize=".7rem" fontWeight="600" color="white">
              {category.toUpperCase()}
            </Text>
          ))}
        </Category>
        <Text fontSize="1rem" color="white">
          {description}
        </Text>
        <InstructorData></InstructorData>
      </InfoBanner>
      <CardProduct>
        <Product about={about} price={price} currency={currency} />
      </CardProduct>
    </BannerContent>
  );
};

export default Banner;

const BannerContent = styled.div`
  width: calc(100% - 20rem);
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(122, 116, 102, 0) 100%
  );
  padding: 0rem 10rem;
  @media (max-width: 1180px) {
    grid-template-columns: 0.8fr 1fr;
  }

  @media (max-width: 1170px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 10rem);
    padding: 0rem 5rem;
  }
`;

const InfoBanner = styled.div`
  width: 100%;
  height: 100%;
`;

const Category = styled.div`
  display: flex;
  padding: 0.3rem 0 1rem 0;
  gap: 0.5rem;
`;

const NavigatioCampus = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
`;

const ButtonFake = styled(Button)`
  color: white;
  height: 50px;
  svg {
    font-size: 1.3rem;
  }
`;

const Absolute = styled.div`
  position: relative;
  height: 50px;
`;

const Premium = styled.div`
  width: auto;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f3cc2f;
  color: white;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  position: absolute;
  font-size: 0.7rem;
  font-weight: 600;
`;

const InstructorData = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
`;

const CardProduct = styled.div`
  position: fixed;
  width: 400px;
  right: 10rem;
  top: 10rem;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 20px;
  @media (max-width: 1600px) {
    width: 330px;
  }
  @media (max-width: 1280px) {
    width: 280px;
  }
  @media (max-width: 1175px) {
    position: fixed;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    z-index: 10;
  }
`;

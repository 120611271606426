import React from "react";
import styled from "styled-components";

const NoMoreResults = () => {
  return (
    <NoMoreResultsContainer>
      <Text>Lo has visto todo!</Text>
    </NoMoreResultsContainer>
  );
};

export default NoMoreResults;

const NoMoreResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  margin-top: 2rem;
  text-align: center;
  background-color: #ffffff;
  color: #bfbfbf;
`;

const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const formatUrlFrame = (url) => {
  const newU = url.replace("/view", "/preview");
  return newU;
};

export const concatUrl = (url) => {
  if (url.search(/\?/i) != -1) {
    return "&";
  } else {
    return "?";
  }
};

import * as types from "../types";

const actions = {};

actions.fetchState = () => {
  return { type: types.FETCH_STATE };
};

// GET COURSES
actions.getCoursesRequest = (payload) => {
  return {
    type: types.GET_COURSES_REQUEST,
    payload: payload,
  };
};

actions.getCoursesSuccess = (courses) => {
  return {
    type: types.GET_COURSES_SUCCESS,
    payload: courses,
  };
};

actions.getCoursesFail = (error) => {
  return {
    type: types.GET_COURSES_FAIL,
    payload: error,
  };
};

// GET MORE COURSES
actions.getMoreCoursesRequest = (payload) => {
  return {
    type: types.GET_MORECOURSES_REQUEST,
    payload: payload,
  };
};

actions.getMoreCoursesSuccess = (courses) => {
  return {
    type: types.GET_MORECOURSES_SUCCESS,
    payload: courses,
  };
};

actions.getMoreCoursesFail = (error) => {
  return {
    type: types.GET_MORECOURSES_FAIL,
    payload: error,
  };
};

// GET STUDENTS
actions.getStudentsRequest = (payload) => {
  return {
    type: types.GET_STUDENTS_REQUEST,
    payload: payload,
  };
};

actions.getStudentsSuccess = (students) => {
  return {
    type: types.GET_STUDENTS_SUCCESS,
    payload: students,
  };
};

actions.getStudentsFail = (error) => {
  return {
    type: types.GET_STUDENTS_FAIL,
    payload: error,
  };
};

// GET TEACHERS
actions.getTeachersRequest = (payload) => {
  return {
    type: types.GET_TEACHERS_REQUEST,
    payload: payload,
  };
};

actions.getTeachersSuccess = (teachers) => {
  return {
    type: types.GET_TEACHERS_SUCCESS,
    payload: teachers,
  };
};

actions.getTeachersFail = (error) => {
  return {
    type: types.GET_TEACHERS_FAIL,
    payload: error,
  };
};

// ENROLL COURSE
actions.enrollCourseRequest = (payload) => {
  return {
    type: types.ENROLL_COURSE_REQUEST,
    payload: payload,
  };
};
actions.enrollCourseSuccess = (course_id) => {
  return {
    type: types.ENROLL_COURSE_SUCCESS,
    payload: course_id,
  };
};
actions.enrollCourseFail = (error) => {
  return {
    type: types.ENROLL_COURSE_FAIL,
    payload: error,
  };
};

export { actions };

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ArrowBackRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { useSelector } from "react-redux";

const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#591AB6",
  },
  tooltip: {
    backgroundColor: "#591AB6",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: "0.2em 0.5em",
  },
}))(Tooltip);

const ChatLayout = (props) => {
  const { activeRol } = useSelector((state) => state.auth);

  return (
    <>
      <MenuWrapper>
        <Link
          to={
            activeRol === "docente"
              ? "/profesor/dashboard"
              : activeRol === "academico"
              ? "/academico"
              : "/campus"
          }
        >
          <FakeImg src="/assets/acropolislab-logo-fullcolor.png" alt="logo" />
        </Link>
        <Link
          to={
            activeRol === "docente"
              ? "/profesor/dashboard"
              : activeRol === "academico"
              ? "/academico"
              : "/campus"
          }
        >
          <RedTooltip title="Volver" color="primary" arrow placement="right">
            <ArrowBackRounded color="primary" fontSize="large" />
          </RedTooltip>
        </Link>
      </MenuWrapper>
      <Main>{props.children}</Main>
    </>
  );
};

export default ChatLayout;

const Main = styled.main`
  padding: 1rem 1rem 1rem calc(3rem + 70px);
  background-color: #f9f9f9 !important;

  @media (max-width: 768px) {
    padding: calc(1rem + 70px) 1rem 1rem 1rem;
  }
`;

const MenuWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  background-color: #ffffff;
  box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.06);
  z-index: 99;

  @media (max-width: 768px) {
    flex-direction: row;
    right: 0;
    height: 70px;
    width: 100%;
  }
`;

const FakeImg = styled.img`
  max-height: 46px;
`;

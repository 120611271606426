import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ondemandActions } from "../../redux/actions";

const useOndemand = () => {
  const dispatch = useDispatch();
  const ondemand = useSelector((state) => state.ondemand.ondemand);
  const competitor = useSelector((state) => state.ondemand.academic);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user.ondemand_id !== null) {
      if (competitor.aden === null && competitor.business === null) {
        dispatch(
          ondemandActions.getOndemandByCompetitorRequest({
            id: user.ondemand_id,
            email: user.personal_email,
          })
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitor]);

  return { ondemand, competitor };
};

export default useOndemand;

import React from "react";
import styled from "styled-components";

import { ErrorMessage, Field } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

const DefaultComplete = (props) => {
  const {
    name,
    label,
    value,
    setFieldValue,
    options = [],
    variant = "standard",
  } = props;

  // RETURN
  return (
    <Field
      as={Autocomplete}
      name={name}
      value={value}
      onChange={(e, newValue) => {
        if (newValue !== null) {
          if (name === "case") {
            setFieldValue("category", { crm_id: 0, name: "" });
          }
          setFieldValue(name, newValue);
        }
      }}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          helperText={<ErrorMessage name={name} component={CustomError} />}
        />
      )}
      {...props}
    />
  );
};

export default DefaultComplete;

const CustomError = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: #591AB6;
`;

import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import useTheme from "../../../hooks/useTheme";
import TicketForm from "../ticket/components/TicketForm";
import { useSelector } from "react-redux";

const TicketPopUp = () => {
    const [open, setOpen] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const { primary } = useTheme();
    const is_incompany = useSelector((state) => state?.ondemand?.ondemand?.is_incompany);

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const threshold = documentHeight - windowHeight - 60;

        if (window.scrollY > threshold) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    return (
        <>
            <IconWrapper backgroundColor={isFixed ? "#fff" : primary} color={!isFixed ? "#fff" : primary} isFixed={isFixed} onClick={() => setOpen(true)}>
                <Icon icon="icon-park-outline:message-emoji" width="1.6em" height="1.6em" />
            </IconWrapper>
            <ModalWrapper open={open} isFixed={isFixed}>
                <TicketForm
                    open={open}
                    setOpen={setOpen}
                    proyectId={!!is_incompany ? 81 : 63}
                    title="Centro de ayuda ADEN"
                    subtitle="Completa el siguiente formulario y te responderemos a la brevedad"
                />
            </ModalWrapper>
        </>
    );
};

export default TicketPopUp;

const IconWrapper = styled(IconButton)`
    position: fixed;
    width: 80px;
    height: 80px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    right: 1.5rem;
    bottom: 1.5rem;
    z-index: 15;
    transition: all .3s ease;
    &:hover {
        background-color: ${(props) => props.backgroundColor};
        box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
        transform: scale(1.2);
        transition: all .3s ease-in-out;
    }

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
`;

const ModalWrapper = styled.div`
    height: 80vh;
    width: calc(500px - 2rem);
    background-color: #fff;
    position: fixed;
    right: ${(props) => props.open ? "1.5rem" : "-40rem"};
    bottom: ${props => props.open || props.isFixed ? "2rem" : "auto"};
    z-index: 999;
    transition: all .5s ease;
    padding: 2rem 1rem 1rem 1rem;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    overflow-y: auto;

    @media (width <= 1536px) {
        height: 85vh;
        padding: 0.5rem 1rem 1rem 1rem;
    }

    @media (width < 768px) {
        width: calc(100vw - 6rem);
        height: fit-content;
        right: ${(props) => props.open ? "1.5rem" : "-50rem"};
        overflow-x: hidden;
    }
`

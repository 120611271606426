import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MainViewResource from "../../components/templates/viewResource/MainViewResource";
import MainViewSurvey from "../../components/templates/viewResource/MainViewSurvey";
import useLayout from "../../hooks/useLayout";

const ViewResource = () => {
  const { type, id } = useParams();

  const cases = {
    survey: "encuesta",
    resource: "recurso",
  };

  const { setMenu } = useLayout()

  setMenu(`Visor de ${cases[type] || "contenido"}`)

  return (
    <ResourceWrapper>
      {cases[type] === "recurso" && (
        <MainViewResource type={cases[type] || "contenido"} id={id} />
      )}
      {cases[type] === "encuesta" && <MainViewSurvey hash={id} />}
    </ResourceWrapper>
  );
};

export default ViewResource;

const ResourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

import * as types from "../types";

const actions = {};

actions.getProgramsRequest = () => {
  return {
    type: types.GET_PROGRAMS_REQUEST,
  };
};

actions.getProgramsSuccess = (programs) => {
  return {
    type: types.GET_PROGRAMS_SUCCESS,
    payload: programs,
  };
};

actions.getProgramsFail = (error) => {
  return {
    type: types.GET_PROGRAMS_FAIL,
    payload: error,
  };
};

export { actions };

import React, { useState } from "react";
import { porvenirScheme, coopecajaScheme } from "../../../utils/schemas";
import { Form, Formik } from "formik";
import DefaultField from "../../common/DefaultField";
import { IconButton, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";
import useActiveAccount from "../../../hooks/useActiveAccount";
import SimpleLoading from "../../common/SimpleLoading";
import ModalTermsActiveAccount from "./ModalTermsActiveAccount";

const FormActiveAccount = (props) => {
    const { primaryColor, handleCloseModal } = props;

    // FORMIK
    const initialValues = {
        cod: "",
        name: "",
        lastName: "",
        email: "",
        password: "",
        changepassword: "",
        terms: true,
    };

    // FORMIK
    const initialValuesPorvenir = {
        cod: "",
        name: "",
        company_name: "",
        nit: 0,
        lastName: "",
        email: "",
        password: "",
        changepassword: "",
        terms: true,
    };

    const isPorvenir = window.origin.includes("porvenir");

    // HOOK
    const { postData, isLoading } = useActiveAccount(
        handleCloseModal,
        isPorvenir
    );

    //STATE
    const [showPassword, setShowPassword] = useState(false);
    const [showRecoPassword, setShowRecoPassword] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTION
    const handleSubmit = (values) => {
        postData(values);
    };

    // FUNCTIONS
    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    function toggleShowRecoPassword() {
        setShowRecoPassword(!showRecoPassword);
    }

    const handleCloseModalTerms = () => {
        setIsOpen(false);
    };
    return (
        <>
            <ModalTermsActiveAccount
                handleCloseModalTerms={handleCloseModalTerms}
                isOpen={isOpen}
            />
            <Formik
                initialValues={isPorvenir ? initialValuesPorvenir : initialValues}
                validateOnChange
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
                validationSchema={
                    isPorvenir ? porvenirScheme : coopecajaScheme
                }
            >
                {({ values, setFieldValue, isValid }) => (
                    <FormContainer>
                        <Content color={primaryColor}>
                            {/* CODE */}
                            <FormField>
                                <DefaultFieldFake
                                    name="cod"
                                    type="text"
                                    placeholder="Ingresa el código asignado"
                                    required
                                    variant="outlined"
                                    values={values.cod}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                />
                                {
                                    values.cod !== "" && <FieldLabel>Codigo</FieldLabel>
                                }
                            </FormField>


                            {/* NAME */}
                            <FormField>
                                <DefaultFieldFake
                                    name="name"
                                    type="text"
                                    placeholder="Ingresa tu nombre"
                                    required
                                    variant="outlined"
                                    values={values.name}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                />
                                {
                                    values.name !== "" && <FieldLabel>Nombre</FieldLabel>
                                }
                            </FormField>
                            {/* LASTNAME */}
                            <FormField>
                                <DefaultFieldFake
                                    name="lastName"
                                    type="text"
                                    variant="outlined"
                                    placeholder="Ingresa tu apellido"
                                    required
                                    values={values.lastName}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                />
                                {
                                    values.lastName !== "" && <FieldLabel>Apellido</FieldLabel>
                                }
                            </FormField>
                            {/* EMAIL */}
                            <FormField>
                                <DefaultFieldFake
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    placeholder="Ingresa tu correo electrónico"
                                    required
                                    values={values.email}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                />
                                {
                                    values.email !== "" && <FieldLabel>Correo electrónico</FieldLabel>
                                }
                            </FormField>
                            {/* PASSWORD */}
                            <FormField>
                                <DefaultFieldFake
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Ingresa tu contraseña"
                                    required
                                    variant="outlined"
                                    values={values.password}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    size="large"
                                                >
                                                    {showPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    values.password !== "" && <FieldLabel>Contraseña</FieldLabel>
                                }
                            </FormField>
                            {/* REPEAT PASSWORD */}
                            <FormField>
                                <DefaultFieldFake
                                    name="changepassword"
                                    type={showRecoPassword ? "text" : "password"}
                                    placeholder="Ingresa nuevamente tu contraseña"
                                    required
                                    variant="outlined"
                                    values={values.changepassword}
                                    setFieldValue={setFieldValue}
                                    primaryColor={primaryColor}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleShowRecoPassword}
                                                    size="large"
                                                >
                                                    {showRecoPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    values.changepassword !== "" && <FieldLabel>Repetir contraseña</FieldLabel>
                                }
                            </FormField>
                            {isPorvenir && (
                                <>
                                    <FormField>
                                        <DefaultFieldFake
                                            name="company_name"
                                            type="text"
                                            placeholder="Ingresa el nombre de la compañía"
                                            required
                                            variant="outlined"
                                            values={values?.company_name}
                                            setFieldValue={setFieldValue}
                                            primaryColor={primaryColor}
                                        />
                                        {
                                            values?.company_name !== "" && <FieldLabel>Nombre de la compañia</FieldLabel>
                                        }
                                    </FormField>
                                    <FormField>
                                        <DefaultFieldFake
                                            name="nit"
                                            type="number"
                                            placeholder="Ingresa el NIT"
                                            variant="outlined"
                                            values={values?.nit}
                                            setFieldValue={setFieldValue}
                                            primaryColor={primaryColor}
                                        />
                                        {
                                            <FieldLabel>NIT</FieldLabel>
                                        }
                                    </FormField>
                                </>
                            )}
                        </Content>
                        {!isLoading ? (
                            <ButtonFake
                                variant="contained"
                                type="submit"
                                primary={primaryColor}
                                secondary="#ffffff"
                                fullWidth
                                disabled={!isValid}
                            >
                                Activar mi cuenta
                            </ButtonFake>
                        ) : (
                            <SimpleLoading />
                        )}
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default FormActiveAccount;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
    width: 70%;
    margin-top: 2rem;
    overflow: auto;
    padding: 1rem 3px 1rem 0rem;

    @media (max-width: 768px){ 
        width: 100%;
    }

    ::-webkit-scrollbar {
        width: 3px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    height: auto;
    color: ${(p) => p.color};
`;

const FormField = styled.div`
    color: inherit;
    position: relative;
`

const DefaultFieldFake = styled(DefaultField)`
    color: inherit;
    div {
        padding: 10px 0;
        outline: none;
        border: 1px solid ${(p) => p.primaryColor};
        border-radius: 100px;
        font-size: 12px;
        input {
            padding: 0rem 1.5rem 0rem 1.5rem;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset;
                box-shadow: 0 0 0px 1000px #f9f9f9 inset;
                -webkit-text-fill-color: #000;
            }
        }
        fieldset {
            display: none;
        }
        div {
            border: none;
        }
    }
    p {
        color: inherit;
        span {
            color: inherit;
        }
    }

    @media (max-width: 768px) {
        div input {
            padding: 0rem 1.5rem 0rem 2.5rem;
        }
    }
    
`;

const FieldLabel = styled.div`
    position: absolute;
    top: -5px;
    margin-left: 20px;
    background-color: #f9f9f9;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 10px;
    @media (max-width: 768px) {
        margin-left: 12px;
        left: 15px;
        font-size: 10px;
    }
`

const ButtonFake = styled(Button)`
    border-radius: 48px;
    font-size: 0.7rem;
    font-weight: 500;
    width: 50%;
    background-color: ${(p) => p.primary};

    :hover {
        background-color: ${(p) => p.primary};
    }

    @media (max-width: 1200px) {
        font-size: 12px;
        width: 60%;
    }
`;
import React from "react";

import { Switch } from "@mui/material";
import { Field } from "formik";

const DefaultSwitch = (props) => {
  const { name, value } = props;

  // RETURN
  return <Field as={Switch} name={name} checked={value} color="primary" />;
};

export default DefaultSwitch;

import styled from "styled-components";
import React, { useState } from "react";

// Material UI
import { Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const InputFile = (props) => {
  const { handleSubmitFile, delivery } = props;

  const [file, setFile] = useState(null);
  const [infoFile, setInfoFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [sizeFile, setSizeFile] = useState(false);

  const handleChange = (e) => {
    setFileError(false);
    setSizeFile(false);
    const type = e.target.files[0].type.split("/")[1];
    if (true) {
      const size = (e.target.files[0].size / 1048576).toFixed(2);
      if (size <= 15) {
        setFile(e.target.files[0]);
        setInfoFile({
          name: e.target.files[0].name,
          size: size + " MB",
          type: type,
        });
      } else {
        setSizeFile(true);
      }
    } else {
      setFileError(true);
      setFile(null);
    }
  };

  return (
    <>
      <Container>
        <Label for="fileInput">
          <CloudDownloadIcon />
          {!fileError ? (
            !sizeFile ? (
              file === null ? (
                <Gap>
                  <Span>Arrastre y suelte el archivo aquí</Span>
                  <ButtonFile>Seleccione un archivo</ButtonFile>
                </Gap>
              ) : (
                <InfoFileDiv>
                  <TitleFile>{infoFile.name}</TitleFile>
                  <Size>{infoFile.size}</Size>
                </InfoFileDiv>
              )
            ) : (
              <InfoFileDiv>
                <TitleFile>Peso máximo permitido</TitleFile>
                <Size>15MB</Size>
              </InfoFileDiv>
            )
          ) : (
            <InfoFileDiv>
              <TitleFile>Solo se permiten los siguentes formatos</TitleFile>
              <Size>pdf, doc, docx, rft, txt, zip, rar</Size>
            </InfoFileDiv>
          )}
        </Label>

        <Input
          multiple={false}
          onChange={handleChange}
          id="fileInput"
          type="file"
        />
      </Container>
      <Footer>
        <Button
          disabled={file !== null && !delivery ? false : true}
          variant="contained"
          color="primary"
          onClick={() => handleSubmitFile(file)}
        >
          Enviar
        </Button>
      </Footer>
    </>
  );
};

export default InputFile;

const Container = styled.div`
  height: auto;
`;

const Footer = styled.div`
  width: calc(100% - 1rem);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.label`
  width: 100%;
  height: 189px;
  border: 2px dotted #999999;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f1f1f1;
  /* gap: 12px; */
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  svg {
    color: #a8a8a8;
    font-size: 4rem;
  }
  :hover {
    background-color: #f0f0f0;
  }
`;

const Input = styled.input`
  display: none;
`;

const Span = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-top: 1rem;
  color: #616161;
`;

const ButtonFile = styled.div`
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  color: white;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 150px;
  text-align: center;
  background-color: #B31D15;
  font-weight: 600;
  text-transform: uppercase;
`;

const InfoFileDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleFile = styled.div`
  text-transform: uppercase;
  color: #B31D15;
  font-weight: 600;
  font-size: 0.8rem;
`;

const Size = styled.div`
  text-transform: uppercase;
  color: #555555;
  font-size: 0.7rem;
`;

const Gap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

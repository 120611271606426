import React from "react";
import styled from "styled-components";
import DefaultCard from "../common/DefaultCard";

function Card() {
  return <CardContainer />;
}

const ProgramsSkt = () => {
  return (
    <DefaultCard>
      <Header>
        <InputFake />
        <FiltersFake>
          <Filter />
          <Filter />
          <Filter />
        </FiltersFake>
      </Header>
      <Container>
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </Container>
    </DefaultCard>
  );
};

export default ProgramsSkt;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  height: auto;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
  border-radius: 20px;
  height: 200px;
  background-color: #f1f1f1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const Header = styled.div`
  height: 120px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const InputFake = styled.div`
  width: 240px;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 4px;
`;

const FiltersFake = styled.div`
  display: flex;
  gap: 12px;
`;

const Filter = styled.div`
  height: 32px;
  width: 75px;
  background-color: #f1f1f1;
`;

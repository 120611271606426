import { useState, useEffect } from "react";
import { getContribution } from "../redux/api/commentsCourseApi";

const useGetContributions = (idResource) => {
    // STATES
    // GET CONTRIBUTIONS
    const [LoadingGetCont, setLoadingGetCont] = useState(false);
    const [errorGetCont, setErrorGetCont] = useState(null);
    const [valuesContributions, setValuesContributions] = useState(null);

    // EFFECTS
    useEffect(() => {
        valuesContributions === null && getContributions();
        // eslint-disable-next-line
    }, [valuesContributions]);

    // FUNCTIONS
    // TRAER CONTRIBUCIONES
    const getContributions = async () => {
        setLoadingGetCont(true);
        setErrorGetCont(null);

        try {
            const res = await getContribution(idResource);

            setValuesContributions(res);
            setLoadingGetCont(false);
            setErrorGetCont(false);
        } catch (error) {
            setValuesContributions(false);
            setErrorGetCont(true);
            setLoadingGetCont(false);
        }
    };

    return {
        valuesContributions,
        errorGetCont,
        LoadingGetCont,
        getContributions,
    };
};

export default useGetContributions;

import * as types from "../types/dracma_types";

const initialState = {
  // DRACMAS
  dracmas: null,
  error: null,
  // MOVEMENTS
  movements: null,
  errorMovements: null,
  // RANKING
  ranking: null,
  errorRanking: null,
  // BENEFITS
  benefits: null,
  errorBenefits: null,
  // RANKING ONDEMAND
  rankingOndemand: null,
  errorRankingOndemand: null,
};

export const dracma = (state = initialState, action) => {
  switch (action.type) {
    // GET DRACMA
    case types.GET_DRACMA_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.GET_DRACMA_SUCCESS:
      return {
        ...state,
        dracmas: action.payload,
      };
    case types.GET_DRACMA_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // GET MOVEMENTS
    case types.GET_MOVEMENTS_REQUEST:
      return {
        ...state,
        errorMovements: null,
      };
    case types.GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        movements: action.payload,
      };

    case types.GET_MOVEMENTS_FAIL:
      return {
        ...state,
        errorMovements: action.payload,
      };
    // GET RANKING
    case types.GET_RANKING_REQUEST:
      return {
        ...state,
        errorRanking: null,
      };
    case types.GET_RANKING_SUCCESS:
      return {
        ...state,
        ranking: action.payload,
      };
    case types.GET_RANKING_FAIL:
      return {
        ...state,
        errorRanking: action.payload,
      };
    // GET RANKING ONDEMAND
    case types.GET_RANKINGONDEMAND_REQUEST:
      return {
        ...state,
        errorRankingOndemand: null,
      };
    case types.GET_RANKINGONDEMAND_SUCCESS:
      return {
        ...state,
        rankingOndemand: action.payload,
      };
    case types.GET_RANKINGONDEMAND_FAIL:
      return {
        ...state,
        errorRankingOndemand: action.payload,
      };
    // BENEFITS
    case types.GET_BENEFITS_REQUEST:
      return {
        ...state,
        errorBenefits: null,
      };
    case types.GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: action.payload,
      };
    case types.GET_BENEFITS_FAIL:
      return {
        ...state,
        errorBenefits: action.payload,
      };
    default:
      return state;
  }
};

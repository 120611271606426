import axios from "axios";

export async function getProductById(id) {
  const URL = `${window.location.origin}/mock/ecommerce.json`;
  try {
    const response = await axios.get(URL);
    const result = response.data.response_data.products.find(
      (prod) => prod.id === id
    );
    return result;
  } catch (error) {
    return { error };
  }
}

export async function getProducts() {
  const URL = `${window.location.origin}/mock/ecommerce.json`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data.products;
  } catch (error) {
    return { error };
  }
}

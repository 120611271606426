import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { Prompt, useParams } from "react-router-dom";
import axios from "axios";
import * as encode from "nodejs-base64-encode";
import * as encrypt from "nodejs-base64-encode";

// Components
import PageInitial from "./evaluation/PageInitial";
import PageExam from "./evaluation/PageExam";
import PageCalification from "./evaluation/PageCalification";
import PageReview from "./evaluation/PageReview";

// Hooks
import { useQueryParams } from "../../../../hooks/useQueryParams";

// Redux
import { useSelector } from "react-redux";
import { postGradeAlab } from "../../../../redux/api/courses";

// Context
import CourseContext from "../../context/CourseContext"

const MultipleChoice = (props) => {
  // PROPS
  const {
    evaluationData,
    handleNewTry,
    submitViewResource,
  } = props;

  const {
    currentMedia: {
        id_recurso: resourceId,
        id_evaluacion: idEvaluacion,
    },
    blockResourceChange,
    blockResocurce,
    objId
} = useContext(CourseContext)

  // ID ASIGNATURA
  const params = useQueryParams();
  const hash = params.get("id");
  const idOrigin = params.get("origin");
  const ObjAId = useParams().id;

  const idOA = encode.decode(ObjAId + "", "base64");

  // COMPONENT STATE
  const [evaluation, setEvaluation] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [page, setpage] = useState(0);
  const [exam, setExam] = useState([]);
  const [review, setReview] = useState(null);
  const [id, setId] = useState(false);
  // REF
  const examRef = useRef();

  // HOOKS
  const user = useSelector((state) => state.auth.user);

  const { activeRol } = useSelector((state) => state.auth);

  const { repo_id, ondemand_id } = user;

  const gradeForOndemand =
    useSelector((state) => state?.ondemand?.ondemand?.grade_for_certificate) ||
    null;

  // EFFECTS
  useEffect(() => {
    if (evaluationData !== false) {
      setEvaluation(evaluationData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  useEffect(() => {
    if (hash !== null) {
      setId(parseInt(encrypt.decode(hash, "base64")));
    }

    return () => {
      if (page === 1) {
        blockResourceChange(false);
        handleSendExam();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ref
  examRef.current = {
    respuestas: exam,
    alumno: user.repo_id,
    evaluacion: evaluationData.id,
    recurso: parseInt(resourceId, 10),
    objeto_aprendizaje: parseInt(objId, 10),
    tipo: "auto_corregible",
    asignatura: id,
  };

  // FUNCTIONS
  const handleStartExam = () => {
    setpage(1);
    blockResourceChange(true);
  };

  const handleDataQuestion = (data) => {
    let flag = true;
    let examInstance = exam;
    if (exam.length > 0) {
      exam.forEach((question, index) => {
        if (data.pregunta_id === question.pregunta_id) {
          examInstance[index] = data;
          setExam(examInstance);
          flag = false;
        }
      });
      if (flag === true) {
        setExam([...exam, data]);
      }
    } else {
      setExam([data]);
    }
  };

  const handlePostGradeAlab = async (grade) => {
    await postGradeAlab(repo_id, idOA, ondemand_id, grade);
  };

  const handleSendExam = async () => {
    const response = await postExamService(examRef.current);
    setQualification(response);
    if (activeRol === "ondemand" && gradeForOndemand && idOrigin === "alab") {
      handlePostGradeAlab(response.nota);
    }
    blockResourceChange(false);
    submitViewResource();
    setpage(2);
  };

  const handleWatchResult = async () => {
    const body = {
      alumno: user.repo_id,
      evaluacion: evaluationData.id,
      recurso: parseInt(resourceId, 10),
      objeto_aprendizaje: parseInt(objId, 10),
      tipo: "",
      entrega_alumno: "",
      sub_tipo: "",
      asignatura: id,
    };
    const response = await postWatchReview(body);
    setReview(response);
  };

  const handleReview = () => {
    blockResourceChange(false);
    handleWatchResult();
    setpage(3);
  };

  const handleLastPage = () => {
    setpage(page - 1);
  };

  // RETURNS
  if (evaluation === null) {
    return <h4>No hay ninguna evaluacion</h4>;
  }

  if (evaluation === null && evaluationData === false) {
    return <h4>Cargando</h4>;
  }

  return (
    <MultipleChoiceWrapper>
      {page === 0 && (
        <PageInitial
          note={evaluation.puntaje_evaluacion}
          delivery={evaluation.submissions}
          name={evaluation.name}
          description={evaluation.descripcion}
          time={evaluation.tiempo}
          questions={evaluation.preguntas.length}
          attempts={evaluation.cantidad_intentos}
          isReview={review}
          submissions={evaluation.submissions}
          handleStartExam={handleStartExam}
        />
      )}
      {page === 1 && (
        <PageExam
          handleDataQuestion={handleDataQuestion}
          handleSendExam={handleSendExam}
          evaluation={evaluation}
        />
      )}
      {page === 2 && (
        <PageCalification
          qualification={qualification}
          evaluation={evaluation}
          objId={objId}
          resourceId={resourceId}
          idEvaluacion={idEvaluacion}
          handleNewTry={handleNewTry}
          handleReview={handleReview}
        />
      )}
      {page === 3 && (
        <PageReview
          handleLastPage={handleLastPage}
          studentRes={examRef.current}
          evaluation={evaluation}
          qualification={qualification}
        />
      )}
      <Prompt
        when={blockResocurce}
        message={(location) =>
          `Se enviará la actividad como se encuantra actualmente!`
        }
      />
    </MultipleChoiceWrapper>
  );
};

export default MultipleChoice;

const postExamService = async (exam) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/corregir_test`;

  try {
    const response = await axios.post(URL, exam);

    return response.data;
  } catch (error) {
    return error;
  }
};

const postWatchReview = async (reviewData) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/watch_results`;

  try {
    const response = await axios.post(URL, reviewData);
    return response.data;
  } catch (error) {
    return error;
  }
};

const MultipleChoiceWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 0px 0px 20px 20px;
`;

import React, { useRef, useState } from "react";
import styled from "styled-components";
import tutorial from "../../../../assets/media/aden/tutorial.png";
import { Text } from "../../../common/Texts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Dialog } from "@mui/material";
import VideoJS from "../../../common/VideoJS";

const CardTutorial = (props) => {
  const { values } = props;

  const [open, setOpen] = useState(false);
  const playerRef = useRef(null);
  const videoRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onProgress = () => {
    return;
  };

  const onError = (error) => {
    // setErrorVideo(error)
  };

  const videoResolution = [
    {
      src: values.url,
      type: "video/mp4",
      label: "720p",
      res: 720,
    },
  ];

  return (
    <>
      <CardWrapper onClick={handleClickOpen}>
        <ImageBanner logo={tutorial}>
          <Gradient id="banner">
            <Play id="play">
              <PlayArrowIcon />
            </Play>
          </Gradient>
        </ImageBanner>
        <Footer>
          <Text fontSize="16px" fontWeight="bold" color="#591AB6">
            {values.title}
          </Text>
        </Footer>
      </CardWrapper>
      <Dialog open={open} onClose={handleClose}>
        <DialogBody>
          <VideoJS
            autoplay={true}
            videos={videoResolution}
            playerRef={playerRef}
            videoRef={videoRef}
            onError={onError}
            onProgress={onProgress}
          />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default CardTutorial;

const CardWrapper = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 20px;
  border: 0.5px solid #fafafa;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  cursor: pointer;
  transition: ease-in-out 0.3s all;
  :hover {
    transition: ease-in-out 0.3s all;
    #banner {
      background: linear-gradient(
        180deg,
        rgba(34, 174, 195, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      transition: ease-in-out 0.3s all;
      #play {
        transition: ease-in-out 0.3s all;
        display: flex;
      }
    }
  }
`;

const ImageBanner = styled.div`
  transition: ease-in-out 0.3s all;
  height: 200px;
  width: 100%;
  background-image: url(${(p) => p.logo});
  background-size: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Gradient = styled.div`
  transition: ease-in-out 0.3s all;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  transition: ease-in-out 0.3s all;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Play = styled.div`
  transition: ease-in-out 0.3s all;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #591AB6aa;
  display: none;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 42px;
    color: white;
  }
`;

const DialogBody = styled.div`
  width: 600px;
  height: auto;
  background-color: white;
`;

import * as types from "../types";

const actions = {};

// PARTNER
actions.getPartnerDataRequest = (partner_id) => {
  return {
    type: types.GET_PARTNER_CHAT_REQUEST,
    payload: partner_id,
  };
};

actions.getPartnerDataSuccess = (partner_data) => {
  return {
    type: types.GET_PARTNER_CHAT_SUCCESS,
    payload: partner_data,
  };
};

actions.getPartnerDataFail = (error) => {
  return {
    type: types.GET_PARTNER_CHAT_FAIL,
    payload: error,
  };
};

actions.setPartnerNull = (partner_data) => {
  return {
    type: types.SET_PARTNER_NULL,
    payload: null,
  };
};

export { actions };

import { useEffect, useState } from "react";
import { getProductById } from "../redux/api/ecommerce";

const useEcommerce = (id = false) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (!product) {
      getProduct();
    }
  }, []);

  const getProduct = async () => {
    const request = await getProductById(parseInt(id, 10));
    if (request.error) {
      return;
    }
    setProduct(request);
  };

  return { product };
};

export default useEcommerce;

import * as types from '../types';

const actions = {};

// GET CAREERS
actions.getCareersRequest = () => {
    return {
        type: types.GET_CAREERS_REQUEST,
    };
};

actions.getCareersSuccess = (careers) => {
    return {
        type: types.GET_CAREERS_SUCCESS,
        payload: careers,
    };
};

actions.getCareersFail = (error) => {
    return {
        type: types.GET_CAREERS_FAIL,
        payload: error,
    };
};

export { actions };
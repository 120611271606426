import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import { useHistory } from "react-router-dom";

// Components
import AsideListItem from "./AsideListItem";
import MainNotes from "./notesCourse/MainNotes";
import MainComments from "./commentsCourse/MainComments";
import ModalTutor from "./ModalTutor";
import CertificateButtonProgress from "./CertificateButtonProgress";
import TabsComponent from "../../../../components/common/TabsComponent";
import BlockTabCourse from "../../../../components/common/BlockTabCourse";

// Material
import { List } from "@mui/material";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

// Hooks
import useTheme from "../../../../modules/acropolisCommon/hooks/useTheme";
import { useQueryParams } from "../../../../modules/acropolisCommon/hooks/useQueryParams";

// Redux
import { useSelector } from "react-redux";
import { postProgressOA } from "../../../../redux/api/courses";

// Helpers
import { userAction } from "../../../../helpers/userActions.helper";
import { handleMouseScrollX } from "../../../../helpers/eventMouse.helper";

// Context
import CourseContext from "../../context/CourseContext"

const AsideCourse = () => {

    const {
        workshop,
        unidades,
        currentMedia,
        unitsEnabled,
        showAside,
        tabClose,
        setTabClose
    } = useContext(CourseContext);

    const history = useHistory();

    const [officeHour, setOfficeHour] = useState(false);
    const [tab, setTab] = useState(0);
    const [progress, setProgress] = useState(0)

    const params = useQueryParams();
    const userOrigin = params.get("origin");

    const { activeRol } = useSelector((state) => state.auth);
    const { repo_id, ondemand_id } = useSelector((state) => state.auth.user);

    let { id } = useParams();

    const idAssigmentHash = params.get("id");

    const idAssignment = idAssigmentHash
        ? encode.decode(idAssigmentHash + "", "base64")
        : false;

    const idOA = encode.decode(id + "", "base64");

    // Tema
    const { primary } = useTheme();

    useEffect(() => {
        getOfficeHour(unidades);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOfficeHour = (unidades) => {
        const officeHour = unidades.filter(
            (unidad) => unidad.nombre === "Office Hour"
        );
        setOfficeHour(officeHour);
    };

    const handleSetProgress = async (progress) => {
        const roundedProgress = Math.round(progress);
        const body = {
            partner_id: repo_id,
            oa_id: parseInt(idOA),
            assignment_id: !!idAssignment ? parseInt(idAssignment) : false,
            progress: roundedProgress,
        };
        await postProgressOA(body);
    };

    const handleProgress = (progress) => {
        if (progress === 100) {
            userAction({
                object_id: workshop.id,
                object: workshop.nombre_publicacion,
                name: workshop.nombre_publicacion,
                activity: !!workshop.es_workshop
                    ? "Finalizar Workshop"
                    : "Finalizar OA",
                medio: !!workshop.es_workshop
                    ? "Finalizar Workshop"
                    : "Finalizar OA",
            });
        }
        handleSetProgress(progress);
    };

    const getProgress = () => {
        let lengthClass = 0;
        let allView = 0;
        workshop.unidades.forEach((unit) => {
            unit.clases.forEach((clase) => {
                ++lengthClass;
                if (clase.visto) {
                    ++allView;
                }
            });
        });
        setProgress((allView * 100) / lengthClass);
        handleProgress((allView * 100) / lengthClass);
    };
    
    const handleClickGoBack = () => {
        if (!!idAssigmentHash) {
            history.push(`/asignatura/${idAssigmentHash}`);
        } else {
            history.push(`/mi-empresa/${ondemand_id}`);
        }
    }

    useEffect(() => {
        getProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshop, currentMedia]);

    const tabs = [
        { label: "Módulos", id: 0 },
        { label: "Comentarios", id: 1 },
        { label: "Apuntes", id: 2 },
    ];

    function handleTab(e, value) {
        setTab(value);
    }
    
    const handleChip = (id) => {
        setTab(id);
    };


    /** RETURN ----------------------------------------------------------------------- */
    if (!showAside) {
        return (
            <FakeDrawer activeRol={activeRol} showAside={showAside}>
                <TabsCloseWrapper>
                    {
                        tabs.map((item, index) => {
                            return (
                                <TabClose isOpen={tabClose === item.id}>
                                    <HeadTab onClick={() => tabClose === item.id ? setTabClose(4) : setTabClose(item.id)}>
                                        {item.label}
                                        {
                                            tabClose !== item.id ? <ExpandMoreRoundedIcon fontSize="large" /> : <ExpandLessRoundedIcon fontSize="large" />
                                        }
                                    </HeadTab>
                                    {
                                        (tabClose === 0 && tabClose === item.id) && <>
                                            {
                                                unidades.map((unidad) => {
                                                    const isEnabled = !!unitsEnabled.find((item) => item === unidad.id)
                                                    return (
                                                        <Unity isEnabled={isEnabled}>
                                                            <UnityIndex isEnabled={isEnabled}>
                                                                {unidad.id}
                                                                {
                                                                    unidad.id !== 1 && <Line isEnabled={isEnabled} />
                                                                }
                                                            </UnityIndex>
                                                            <UnityName isEnabled={isEnabled}>
                                                                {unidad.nombre}
                                                            </UnityName>
                                                        </Unity>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                    {
                                        (tabClose === 1 && tabClose === item.id) && <>
                                            {
                                                <>
                                                    <SubLabel>
                                                        Últimos comentarios
                                                    </SubLabel>
                                                    <MainComments />
                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        (tabClose === 2 && tabClose === item.id) && <>
                                            {
                                                <>
                                                    <SubLabel>
                                                        Últimos apuntes
                                                    </SubLabel>
                                                    <MainNotes />
                                                </>
                                            }
                                        </>
                                    }

                                </TabClose>
                            )
                        })
                    }
                    <ButtonGoBackSubject onClick={handleClickGoBack} bgColor={primary}>
                        Volver
                    </ButtonGoBackSubject>
                </TabsCloseWrapper>
            </FakeDrawer>
        )
    }
    return (
        <FakeDrawer activeRol={activeRol === "ondemand"} showAside={showAside}>
            <SliderTabs id="tabs" onClick={() => handleMouseScrollX("tabs")}>
                {tabs.map((element, index) => (
                    <ChipTab
                        key={index}
                        onClick={() => handleChip(element.id)}
                        currentId={element.id}
                        tabSelected={tab}
                        color={primary}
                    >
                        {element.label}
                    </ChipTab>
                ))}
            </SliderTabs>
            <TabsWrapper>
                <TabsComponent
                    tab={tab}
                    handleTab={handleTab}
                    tabs={tabs}
                    notBorder
                    center
                />
            </TabsWrapper>
            {tab === 0 && (
                <CustomList activeRol={activeRol === "ondemand"}>
                    {unidades.map((unidad, index) => {
                        if (unidad.nombre !== "Office Hour") {
                            return (
                                <AsideListItem
                                    unidad={unidad}
                                    id={index}
                                    index={index + 1}
                                    isEnabled={
                                        !!unitsEnabled.find(
                                            (item) => item === unidad.id
                                        )
                                    }
                                    key={index}
                                />
                            );
                        }
                        return false;
                    })}
                    <CertificateButtonProgress
                        userOrigin={userOrigin}
                        disabled={progress !== 100 ? true : false}
                    />
                    <ButtonGoBackSubject onClick={handleClickGoBack} bgColor={primary}>
                        Volver
                    </ButtonGoBackSubject>
                </CustomList>
            )}
            {tab === 1 && (
                <>
                    {currentMedia?.formato === "audiovisual" ? (
                        <MainComments />
                    ) : (
                        <BlockTabCourse
                            msg="Lamentablemente, no es posible dejar comentarios en esta sección."
                        />
                    )}
                </>
            )}
            {tab === 2 && (
                <>
                    {currentMedia?.formato === "audiovisual" ? (
                        <MainNotes />
                    ) : (
                        <BlockTabCourse
                            msg="Lamentablemente, no es posible agregar apuntes en esta sección."
                        />
                    )}
                </>
            )}
            {officeHour && officeHour.length > 0 && (
                <GridModal>
                    <ModalTutor officeHour={officeHour} />
                </GridModal>
            )}
        </FakeDrawer>
    );
};

export default AsideCourse;

const FakeDrawer = styled.div`
    display: flex;
    flex-direction: column;
    position: ${(props) => props.activeRol ? "" : "fixed"};
    top: 70px;
    right: 0;
    bottom: 0;
    width: ${(props) => props.activeRol ? !props.showAside ? "200px" : "100%" : "25%"};
    min-height: 500px;
    height: ${(props) => props.activeRol ? "100vh" : "calc(100vh - 70px)"};
    background-color: ${(props) => props.activeRol ? "transparent" : "#fff"};
    z-index: 1;

    @media (max-width: 1400px) {
        width: ${(props) => props.activeRol ? "100%" : "30%"};
    }

    @media (max-width: 900px) {
        width: calc(100% - 4rem);
        position: relative;
        padding: 0 2rem;
        top: 0px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 1.6rem);
        height: fit-content;
        padding: 0 0.8rem;
    }
`;

const GridModal = styled.div`
    width: 100%;
    display: flex;
    align-self: flex-end;
    flex-grow: 1;
    box-sizing: border-box;
    padding: ${(props) => props.activeRol === "ondemand" ? ".5rem" : "1.5rem"};
    justify-content: center;
    @media (max-width: 768px) {
        flex-grow: 0;
        align-self: flex-start;
    }
`;

const CustomList = styled(List)`
    height: ${(props) => props.activeRol === "ondemand" ? "110%" : "90vh"};
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        height: 60vh;
    }
    @media (max-width: 361px) {
        height: 48vh;
    }
    @media (max-width: 320px) {
        height: 35vh;
    }
`;

const TabsWrapper = styled.div`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    margin-top: 2rem;

    @media (max-width: 900px) {
        display: none;
    }
`;

const TabsCloseWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 24px;
    margin-top: 2rem;
`

const TabClose = styled.div`
    position: relative;
    width: 200px;
    height: ${(props) => props.isOpen ? "fit-content" : "72px"};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    color: var(--primary-one);
    font-weight: 700;
    padding-bottom: ${(props) => props.isOpen && "24px"};
`

const HeadTab = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 72px;
`

const Unity = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;  
    gap: 24px;
    :hover {
        div {
            background-color: ${(props) => props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
        }
        label {
            color: ${(props) => props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
            font-weight: 600;
        }
    }
`

const UnityIndex = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: ${(props) => props.isEnabled ? "#CD2118" : "#C4C4C4"};
    color: var(--secondary-one);
`

const UnityName = styled.label`
    width: 100px;
    font-weight: 500;
    color: ${(props) => props.isEnabled ? "#CD2118" : "#C4C4C4"};
`

const Line = styled.div`
    position: absolute;
    top: -24px;
    width: 2px;
    height: 25px;
    background-color: ${(props) => props.isEnabled ? "#CD2118" : "#C4C4C4"};
    :hover {
        background-color: ${(props) => props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
    }
`

const SubLabel = styled.label`
    color: #222222;
    font-size: 11px;
    font-weight: 500;
    position: absolute;
    top: 60px;
    left: 1rem;
`



const SliderTabs = styled.div`
    display: none;
    gap: 1rem;
    align-items: center;
    user-select: none;
    overflow-x: auto;
    padding: 0.1rem;

    :active {
        cursor: pointer;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 900px) {
        display: flex;
    }
`;

const ChipTab = styled.div`
    width: 100%;
    text-align: center;
    border-radius: 15px;
    padding: 13px 27px;
    box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08),
        0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    background-color: ${(p) =>
        p.tabSelected === p.currentId ? p.color : "#dcdcdc"};
`;

const ButtonGoBackSubject = styled.div`
    cursor: pointer;
    width: 160px;
    height: 40px;
    background-color: ${(props) => props.bgColor};
    border-radius: 48px;
    padding: 0 8px;
    font-size: 16px;
    color: var(--secondary-one);
    margin: .5rem auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    :disabled {
        background-color: #c4c4c4;
        cursor: not-allowed;
        :hover {
            box-shadow: none;
        }
    }

    :hover {
        box-shadow: 0px 4px 4px 0px #00000040;
    }
`
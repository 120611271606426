import * as types from "../types";

const actions = {};

//Pagination
actions.getPaginateRequest = (payload) => {
  return {
    type: types.GET_PAGINATION_REQUEST,
    payload: payload,
  };
};

actions.getPaginateSuccess = (pagination) => {
  return {
    type: types.GET_PAGINATION_SUCCESS,
    payload: pagination,
  };
};

actions.getPaginateFail = (error) => {
  return {
    type: types.GET_PAGINATION_FAIL,
    payload: error,
  };
};

actions.nextPage = () => {
  return {
    type: types.NEXT_PAGE,
  };
};
actions.previousPage = () => {
  return {
    type: types.PREVIOUS_PAGE,
  };
};
actions.changePage = (page) => {
  return {
    type: types.CHANGE_PAGE,
    payload: page,
  };
};

//Direcotory
actions.getDirectoriesRequest = (query) => {
  return {
    type: types.GET_DIRECTORIES_REQUEST,
    payload: query,
  };
};

actions.getDirectoriesSuccess = (directories) => {
  return {
    type: types.GET_DIRECTORIES_SUCCESS,
    payload: directories,
  };
};

actions.getDirectoriesFail = (error) => {
  return {
    type: types.GET_DIRECTORIES_FAIL,
    payload: error,
  };
};

// Directorio Alab
actions.getDirectoriesAlabRequest = (payload) => {
  return {
    type: types.GET_DIRECTORIESALAB_REQUEST,
    payload: payload,
  };
};

actions.getDirectoriesAlabSuccess = (directories) => {
  return {
    type: types.GET_DIRECTORIESALAB_SUCCESS,
    payload: directories,
  };
};

actions.getDirectoriesAlabFail = (error) => {
  return {
    type: types.GET_DIRECTORIESALAB_FAIL,
    payload: error,
  };
};

// Paginate Alab
actions.getPaginateAlabRequest = (payload) => {
  return {
    type: types.GET_PAGINATIONALAB_REQUEST,
    payload: payload,
  };
};

actions.getPaginateAlabSuccess = (pagination) => {
  return {
    type: types.GET_PAGINATIONALAB_SUCCESS,
    payload: pagination,
  };
};

actions.getPaginateAlabFail = (error) => {
  return {
    type: types.GET_PAGINATIONALAB_FAIL,
    payload: error,
  };
};

export { actions };

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useTheme from "../../../../hooks/useTheme";
import BarProgress from "../../../common/BarProgress";
import SimpleLoading from "../../../common/SimpleLoading";
import { Button } from "@mui/material";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";
import { Icon } from "@iconify/react";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useHistory } from "react-router-dom";
import { encodeData } from "../../../../helpers/encrypt.helper";
import useBusiness from "../../../../hooks/business/useBusiness";
import RegistrationModal from "./RegistrationModal";

const BusinessCard = (props) => {
  const { value, progress = 0, finalNote = 0, type = "course", progressActive = true, additional = false } = props;

  const { primary } = useTheme();

  const { registrateUser, repo_id, ondemand_id } = useBusiness("card");

  // STATES
  const [openActions, setOpenActions] = useState(false);
  const [openModal, setOpenModal] = useState(false)

  // REFS
  const divRef = useRef(null);
  const botonRef = useRef(null);

  // EFFECTS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        !botonRef.current.contains(event.target)
      ) {
        setOpenActions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const history = useHistory();



  const handleOpen = (id) => {

    if (!progressActive) {
      setOpenModal(true)
      return;
    }

    history.push(
      `../${type === "route" ? "asignatura" : "curso"}/${encodeData(
        String(id)
      )}${type !== "route" ? "?origin=alab" : ""}`
    );
  }


  //RETURN
  if (!value) {
    return <SimpleLoading />;
  }

  return (
    <BusinessCardContainer>
      <RegistrationModal
        handleClick={registrateUser}
        data={value}
        openModal={openModal}
        setOpenModal={setOpenModal}
        repoId={repo_id}
        ondemandId={ondemand_id}
      />
      <ImageWrapper>
        <Img
          width="100%"
          height="100%"
          borderRadius="20px 20px 0 0"
          src={
            !value.imagen
              ? value.img
              : value.imagen !== ""
                ? value.imagen
                : "https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg"
          }
        />
        {
          value?.rework_activities &&
          <ReworkActivities>
            <Icon icon="mingcute:alert-line" width="16px" height="16px" />
            Actividades por rehacer
          </ReworkActivities>
        }
        {
          progressActive &&
          <State value={progress}>
            {progress === 0
              ? "Iniciar curso"
              : progress === 100
                ? "Finalizado"
                : "En curso"}
          </State>
        }

        {type === "route" ? (
          <NumberOfCourses>
            <p>
              <span>
                <b>{value?.courses_amount ?? 0}</b>
              </span>{" "}
              cursos
            </p>
          </NumberOfCourses>
        ) : (
          <>
            {progress === 100 && (
              <Note>
                <p>
                  Nota:{" "}
                  <span>
                    <b>{finalNote}</b>
                  </span>{" "}
                  / 100
                </p>
              </Note>
            )}
          </>
        )}
        {progress === 100 && (
          <Note>
            <p>
              Nota:{" "}
              <span>
                <b>{finalNote}</b>
              </span>{" "}
              / 100
            </p>
          </Note>
        )}
      </ImageWrapper>
      <Body>
        {
          progressActive &&
          <BarProgress
            /* value={value.progreso > 100 ? 100 : value.progreso} */
            value={progress}
          />
        }

        <PublicationName>{value.nombre_publicacion ?? value.name}</PublicationName>
        {
          additional &&
          <Description>
            {value?.descripcion}
          </Description>
        }
      </Body>
      <Footer type={type}>
        
        {/* {toggleNotes(value)} */}
        {/* Si en un futuro se quiere usar las acciones de los tres puntitos, descomentar lo de abajo :P */}
        {/* {type !== "route" && (
          <ActionsButton ref={botonRef} onClick={setOpenActions}>
            <Icon icon="pepicons-pop:dots-y" width="24" height="24" />
          </ActionsButton>
        )} */}
        <FakeButton buttonColor={primary} onClick={() => handleOpen(value.id)}>
          {progress === 0
            ? `Iniciar ${type === "route" ? "ruta" : "curso"}`
            : progress === 100
              ? `Volver a ver`
              : progressActive
                ? `Continuar ${type === "route" ? "ruta" : "curso"}`
                : `Iniciar ${type === "route" ? "ruta" : "curso"}`
          }
        </FakeButton>
        {openActions && (
          <ActionsContainer ref={divRef}>
            <Action>
              <Icon icon="ic:baseline-info" width="24" height="24" />
              Información del curso
            </Action>
            <Action>
              <Icon icon="material-symbols:favorite" width="24" height="24" />
              Agregar a favoritos
            </Action>
            <Action>
              <WorkspacePremiumIcon />
              Ver certificado
            </Action>
          </ActionsContainer>
        )}
      </Footer>
    </BusinessCardContainer>
  );
};

export default BusinessCard;

const BusinessCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  max-width: 325px;
  width: 100%;
  min-height: 325px;
  border-radius: 20px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  img {
    scale: 1;
    transition: scale 0.5s ease-in-out;
  }
  :hover img {
    scale: 1.3;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 136px;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
`;

const Img = styled(LazyImg)`
  object-fit: cover;
  object-position: center;
`;

const State = styled.span`
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-1rem, 1rem);
  border-radius: 8px;
  color: ${(p) =>
    p.value === 0
      ? "var(--tertiary-two)"
      : p.value === 100
        ? "#1E8065"
        : "var(--tertiary-four)"};
  background-color: ${(p) =>
    p.value === 0
      ? "var(--tertiary-three)"
      : p.value === 100
        ? "#EAFAF6"
        : "var(--tertiary-five)"};
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
`;

const NumberOfCourses = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-radius: 0 10px 0 0;
  background-color: var(--secondary-one);
  font-size: 11px;
  font-weight: 500;
  color: #616161;
  padding: 4px 16px;
`;

const Note = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-radius: 0 10px 0 0;
  background-color: var(--secondary-one);
  font-size: 11px;
  font-weight: 500;
  color: #616161;
  padding: 4px 16px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  height: 80px;
  max-height: 117px;
  padding: 1rem 1rem;
`;

const PublicationName = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  color: #222222;
`;

const Description = styled.div`
  height: 65%;
  overflow-y: scroll;
  scroll-behavior: smooth;
`

const Footer = styled.div`
  position: relative;
  height: calc(72px - 16px);
  display: flex;
  justify-content: ${(props) =>
    props.type === "route" ? "end" : "end"};
  align-items: center;
  border-top: 1px solid #fafafa;
  margin-top: auto;
  padding: 16px;
  background-color: var(--secondary-two);
  border-radius: 0 0 20px 20px;
`;

const ReworkActivities = styled.div`  
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  background-color: #F9F5EC;
  color: #C29F43;
  padding: .5rem;
  border-radius: 100px;
  border: 1px solid #C29F43;
`;

const ActionsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 228px;
  height: 160px;
  border-radius: 20px;
  padding: 8px 0;
  background-color: var(--secondary-one);
  box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
`;

const Action = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-three);
`;

const FakeButton = styled(Button)`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--primary-one);
  color: #ffffff;

  :hover {
    background-color: #616161;
    color: #ffffff;
  }
`;
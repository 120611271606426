import { Button } from "@mui/material";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Text } from "../../../components/common/Texts";
import LazyImg from "../../acropolisCommon/components/LazyImg";

const Card = (props) => {
  const { values } = props;

  return (
    <CardWrapper title={values.nombre}>
      <LazyImg
        src={values.image || values.imagen}
        height="102px"
        width="100%"
        borderRadius="20px 20px 0 0"
        backgroundColor="#f1f1f1"
      />
      <Body>
        <Title fontSize="18px" fontWeight="700">
          {values.name || values.nombre}
        </Title>
        <BodyDescription>
          <Text color="#949494">{values?.description}</Text>
        </BodyDescription>
      </Body>

      <Footer>
        <Button
          onClick={() => window.open(values?.nueva_url || "#")}
          color="primary"
        >
          Ver más
        </Button>
        {/* <Button variant='contained' color='primary'>Solicitar info</Button> */}
      </Footer>
    </CardWrapper>
  );
};

export default Card;

const CardWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-rows: 102px auto 78px;
  width: 323px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
`;

const HeaderWrapper = styled.div`
  height: 102px;
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #f9f9f9;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;

const Header = styled.img`
  height: 102px;
  width: 100%;
  object-fit: cover;
  animation: ${fadeIn} 0.5s ease-in;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Body = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const BodyDescription = styled.div`
  max-height: 143px;
  overflow-y: auto;
`;

const Footer = styled.div`
  height: 78px;
  background-color: #fafafa;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
`;

const Title = styled(Text)`
  width: 280px;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  overflow: hidden;
`;

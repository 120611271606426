import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import MenuItem from "../menu/MenuItem";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useSelector } from "react-redux";
import Menu from '../../ui/atoms/Menu'
import { Icon } from '@iconify/react';
import LazyImg from "../../../modules/acropolisCommon/components/LazyImg";

const MenuOndemand = (props) => {
	const { menu, closeMenu } = props;

	// STATE
	// const [isViewEvent, setIsViewEvent] = useState(true);
	const wrapperRef = useRef();
	const { ondemand } = useSelector((state) => state.ondemand);
	/* const { personal_email, name, foto, partner_id } = useSelector(
		(state) => state.auth.user
	); */
	// const url = `https://go.crisp.chat/chat/embed/?website_id=${getCrisp()}&user_email=${personal_email}&user_avatar=${foto}&id=${partner_id}&user_nickname=${name}`;

	const [openMenu, setOpenMenu] = useState(false);
	const [hoverMenu, setHoverMenu] = useState(false)

	const [subdomain] = window.location.host.split(".") || [false];

	/* const menuEducational = [
		// {
		//   title: "Cursos abiertos",
		//   to: "/workshops",
		//   icon: <MenuBookRounded />,
		//   active: ondemand?.enable_workshop ?? true,
		// },
		{
			title: "Biblioteca de contenidos",
			to: "/biblioteca",
			icon: <PlayArrowRounded />,
			active: ondemand?.enable_workshop ?? true,
		},
		{
			title: "Simulador 360",
			to: "/biblioteca?tab=simulador",
			icon: <PlayArrowRounded />,
			active: ondemand?.enable_workshop ?? true,
		},
	]; */

	/* const menuEvents = [
		// {
		//     title: "Talleres presenciales",
		//     to: "/eventos?tab=physical-events",
		//     icon: <SchoolRounded />,
		//     active: ondemand?.enable_event ?? true,
		// },
		{
			title: "Eventos virtuales",
			to: "/evento?tab=online-events",
			icon: <ComputerIcon />,
			active: ondemand?.enable_event ?? true,
		},
		// {
		//     title: "Consultas sincrónicas",
		//     to: "/chatear",
		//     icon: <FreeBreakfastIcon />,
		//     active: ondemand?.enable_networking ?? true,
		// },
	]; */

	/* const menuAcropolisLab = [
		{
			title: "Mis cursos",
			to: `/mi-empresa/6`,
			icon: <MenuBookRounded />,
			active: true,
		},

		{
			title: "Desafíos",
			to: "/desafios",
			icon: <StarsIcon />,
			active:
				(!!ondemand?.challenge_url && !!ondemand?.enable_challenge) ?? true,
		},
	]; */

	// EFFECTS
	// useEffect(() => {
	//     if (checkRoles("ondemand")) {
	//         setIsViewEvent(false);
	//     }
	//     if (checkRoles("ondemand") && checkRoles("alumno")) {
	//         setIsViewEvent(true);
	//     }
	// }, []);

	useEffect(() => {
		if (menu) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

	// FUNCTIONS

	function handleClickOutside(e) {
		if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
			return;
		}
		closeMenu();
	}

	// RETURN
	return (
		<Aside
			menu={menu}
			ref={wrapperRef}
			openMenu={openMenu}
			hoverMenu={hoverMenu}
		>
			<ButtonMenu id="buttons" openMenu={openMenu}>
				<Menu openMenu={openMenu} setOpenMenu={setOpenMenu} />
				<section onMouseEnter={() => setHoverMenu(true)}>
					{(subdomain !== "porvenir" && subdomain !== "analdexsena") && (
						<LazyImg
							width="95px"
							height="auto"
							src="/assets/acropolis-lab-rojo_logo.png"
							backgroundColor="transparent"
						/>
					)}
					<LazyImg
						width="auto"
						height="50%"
						src={`${ondemand?.logo_url}`}
						backgroundColor="transparent"
					/>
				</section>
			</ButtonMenu>
			<MenuIconsWrapper
				openMenu={openMenu}
				hoverMenu={hoverMenu}
				onMouseEnter={() => setHoverMenu(true)}
				onMouseLeave={() => setHoverMenu(false)}>
				<AsideTop id="overflowY">
					<ListMenu id="menu">
						{/* Home */}
						<MenuItem
							title="Inicio"
							to="/campus"
							icon={<Icon icon="teenyicons:home-solid" />}
							closeMenu={closeMenu}
							data_tut="reactour__inicio"
						/>
						<MenuItem
							title="Mis cursos"
							to="/mi-empresa/6"
							icon={<Icon icon="ion:book" />}
							closeMenu={closeMenu}
							data_tut="reactour__inicio"
						/>
						<MenuItem
							title="Biblioteca"
							to="/biblioteca"
							icon={<Icon icon="material-symbols:smart-display-sharp" />}
							closeMenu={closeMenu}
							data_tut="reactour__inicio"
						/>
						{/* <MenuItem
              title="Networking"
              to=""
              icon={<Icon icon="dashicons:networking" />}
              closeMenu={closeMenu}
              data_tut="reactour__inicio"
            /> */}
						{!!ondemand?.enable_educative_offer && (
							<MenuItem
								title="Oferta educativa"
								to="/oferta-educativa"
								icon={<LocalMallIcon color="secondary" />}
								closeMenu={closeMenu}
								data_tut="reactour__oferta_educativa"
							/>
						)}
					</ListMenu>
				</AsideTop>

				<AsideBottom>
					{/* <MenuItem
						title="Ayuda"
						to="/support"
						data_tut="reactour__tutoriales_ondemand"
						icon={<Icon icon="material-symbols:help" />}
						closeMenu={closeMenu}
					/> */}
					<Divider className="divider-aside" />
					{ondemand?.is_admin && (
						<MenuItem
							title="Administrador"
							to="https://admin.acropolislab.com/"
							link={true}
							icon={<Icon icon="eos-icons:admin" />}
							closeMenu={closeMenu}
						/>
					)}
				</AsideBottom>
			</MenuIconsWrapper>
		</Aside>
	);
};

export default MenuOndemand;

const Aside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70px;
  height: 100%;
  background-color: var(--secondary-one);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.5s;
  z-index: 999999999;

  #overflowY {
    overflow-y: auto;
  }
  .text-aside {
    visibility: hidden;
  }

  #buttons {
    padding-top: 12px;
    ${(p) =>
		(p.openMenu || p.hoverMenu)
			? `
            padding-left: 0;
          `
			: `
            padding-left: 12px;
          `
	}
  }

  #buttons section {
    
    height: 100%;
    display: flex;
    align-items: center;
    & > div > img {
      height: 100%;
    }
    ${(p) =>
		(p.openMenu || p.hoverMenu)
			? `position: absolute;
          width: 200px;
          left: 70px;
          display: flex;
          gap: 12px;
          transition: all 5s;
          div, img {
            transition: all 0.5s ease-in-out;
          }
          & > div:first-child {
            padding-right: 4px;
            border-right: 1px solid var(--primary-two);
          }
          `
			: `transition: all 5s;
          width: 0px;
          div, img {
            width: 0px;
            height: 0px;
          }
      `}
  }

  @media (max-width: 768px) {
    visibility: ${(props) => (props.menu ? "visible" : "hidden")};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;
    position: fixed;
    top: 0;
    left: ${(props) => (props.menu ? "0" : "-100%")};
    bottom: 0;
    z-index: 9999;
    width: 70vw;
    overflow: hidden;
    height: 100%;
  }
`;

const MenuIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: all 0.5s;
  background-color: var(--secondary-one);

  a {
    position: relative;
    padding: 1rem;
    transition: all 0.5s;
    padding-left: 17px;
    max-height: 55px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
    }
    svg {
      font-size: 22px;
    }
  }
  a:hover {
    transition: all 0.5s;
  }

  .EventFake,
  .IconsHearder {
    display: none;
  }

  .accordionFake,
  .SubAccordionFake {
    padding-left: 25px;
  }
  .PFake {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    p {
      margin-left: 1rem;
    }
  }

  ${(p) =>
		(p.openMenu || p.hoverMenu)
			? `
    transition: all 0.5s;
    width: 266px;
    #menu-accordion {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      position: relative;
      transition: all 0.5s;
      max-height: 55px;
      #title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
      }
    }

    .EventFake,
    .IconsHearder {
      display: block;
    }
    .accordionFake {
      justify-content: space-between;
    }
    .SubAccordionFake {
      gap: 1rem;
    }
    .divider-aside {
      transition: ease-in-out 0.3s all;
    }
    .text-aside {
      padding-left: 24px;
      visibility: visible;
      transition: ease-in-out 0.3s all;
      width: 130px;
    }

    .sc-eSYpDc {
      position: absolute;
      left: 70px;
      display: flex;
      transition: all 5s;:
    }
    
    a {
      gap: 1rem;
      padding-left: 17px;
      font-size: 16px;
      span {
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
      }
      transition: ease-in-out all 1s;
    }
    a:hover {
      transition: all 0.5s;
      border-radius: 25px;
    }
    `
			: `:hover {
    transition: all 0.5s;
    width: 300px;
    #menu-accordion {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      position: relative;
      transition: all 0.5s;
      max-height: 55px;
      #title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
      }
    }

    .EventFake,
    .IconsHearder {
      display: block;
    }
    .accordionFake {
      justify-content: space-between;
    }
    .SubAccordionFake {
      gap: 1rem;
    }
    .divider-aside {
      transition: ease-in-out 0.3s all;
      width: calc(100% - 24px);
    }
    .text-aside {
      padding-left: 24px;
      visibility: visible;
      transition: ease-in-out 0.3s all;
      width: 130px;
    }

    a {
      gap: 1rem;
      padding-left: 17px;
      font-size: 0.9rem;
      span {
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
      }
      transition: ease-in-out all 1s;
    }
    a:hover {
      transition: all 0.5s;
      border-radius: 25px;
    }
  }`}
`;

/* const Header = styled.div`
  display: none;
  visibility: ${(props) => (props.menu ? "visible" : "hidden")};
  opacity: ${(props) => (props.menu ? 1 : 0)};
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
`; */

const AsideTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 500px;
  overflow-y: auto;
  #menu {
    overflow-y: auto;
  }
`;

const AsideBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonMenu = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
`;

const ListMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: calc(8rem - 8px);
  gap: 10px;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
  overflow-y: auto;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 16px 0;
  z-index: 10;
`;

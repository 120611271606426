import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ProfileSummary from "../../organisms/profile-summary/ProfileSummary";
import { useShow } from "../../../../hooks/useShow";
import { EmojiEventsRounded } from "@mui/icons-material";
import Text from "../../../common/Text";

const CardRating = (props) => {
  const { user, index } = props;

  const [data, setData] = useState([]);

  // Hooks
  const [show, handleShow, handleClose] = useShow(false);

  //FUNCTIONS
  const getData = async () => {
    const URL =
      process.env.REACT_APP_SISAPI +
      `/v1/acropolis/users/profile-summary/${user?.crm_partner?.id || 0}`;
    try {
      const res = await axios.get(URL);
      const data = res.data;
      const postData = data.response_data;
      setData(postData);
    } catch (error) {
      return {
        error,
      };
    }
  };

  useEffect(() => {
    // getData();
  }, []);

  const { sis_id } = data;
  const rol = sis_id ? "Alumno" : "Invitado";

  // RETURN
  return (
    <Container>
      <CenterWrapper>
        {index <= 2 ? (
          <EmojiEventsRounded
            htmlColor={
              index === 0 ? "#fee101" : index === 1 ? "#a7a7ad" : "#a77044"
            }
          />
        ) : (
          <Text fontSize="1.1rem" fontWeight="700" color="secondary">
            {index + 1}°
          </Text>
        )}
      </CenterWrapper>
      <ClickeableArea>
        <CenterWrapper>
          <UserPhoto src={user?.repositorio_partner?.foto || ""} alt="user" />
        </CenterWrapper>
        <LeftWrapper>
          <Text fontSize="1rem" fontWeight="400">
            {user?.repositorio_partner?.name?.toTitleCase() || "sin dato"}
          </Text>
        </LeftWrapper>
        <CoinsWrapper>
          <PointsText>{user?.amount || "sin dato"} </PointsText>
          <PointsTextStrong>DP</PointsTextStrong>
        </CoinsWrapper>
      </ClickeableArea>
      {/* SUMMARY*/}
      <ProfileSummary
        open={show}
        handleClose={handleClose}
        userRol={rol}
        contact={data}
        maxWidth="md"
        showChatButton={true}
      />
    </Container>
  );
};

export default CardRating;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 6fr 2fr;
  column-gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
`;

const ClickeableArea = styled.div`
  /* cursor: pointer; */
  display: contents;
  flex-direction: row;
  align-items: center;
`;

const UserPhoto = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftWrapper = styled.div`
  display: contents;
  justify-content: flex-start;
  align-items: center;
`;

const CoinsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.1rem;
`;

const PointsText = styled.span`
  font-size: 1rem;
  color: #616161;
  font-weight: 400;

  b {
    font-size: 1rem;
    color: #616161;
    font-weight: 700;
  }
`;

const PointsTextStrong = styled(PointsText)`
  font-weight: 700;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Navegation from "../navegation/Navegation";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { eventsActions } from "../../../redux/actions";
import BannerLayout from "../banners/BannerLayout";
import Copyright from "../../common/Copyright";
import SurveyFeedback from "../../ui/organisms/surveys/SurveyFeedback";
import WhatsappChat from '../chat-layout/WhatsappChat'

const ECLayout = (props) => {
  // STATE
  const [menu, setMenu] = useState(false);

  let bannerExists = false;

  // REDUX
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.events);
  let eventToShow = null;
  const errorList = useSelector((state) => state.events.errorList);

  useEffect(() => {
    if (events === null) {
      dispatch(eventsActions.getEventsRequest());
    }
  }, [events]);
  if (events === null || events === undefined || events.length === 0) {
    bannerExists = false;
  } else {
    bannerExists = true;
    eventToShow = events.find((event) => event.publish_in_acropolis);
    if (!eventToShow) {
      bannerExists = false;
    }
  }

  // FUNCTIONS
  function toggleMenu() {
    setMenu(!menu);
  }

  function closeMenu() {
    setMenu(false);
  }

  // RETURN
  return (
    <>
      <Main menu={menu} bannerExists={false}>
        {props.children}
        {/* <Copyright></Copyright> */}
      </Main>
      <WhatsappChat />
    </>
  );
};

export default ECLayout;

const Main = styled.main`
  padding: 0;
`;

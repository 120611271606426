import React from "react";
import styled from "styled-components";

import IconInfoLeft from "../../molecules/profile/IconInfoLeft";
import {
  BusinessRounded,
  CakeRounded,
  SchoolRounded,
  WorkRounded,
  CategoryRounded,
} from "@mui/icons-material";

const AboutMe = (props) => {
  const { birthday, profession, current_job, company, job_area } = props;

  return (
    <AboutMeContainer>
      <Title>Sobre mí</Title>
      <IconInfoLeft icon={<CakeRounded />} title="Cumpleaños" data={birthday} />
      <IconInfoLeft
        icon={<SchoolRounded />}
        title="Profesión"
        data={profession}
      />
      <IconInfoLeft icon={<WorkRounded />} title="Cargo" data={current_job} />
      <IconInfoLeft icon={<CategoryRounded />} title="Área" data={job_area} />
      <IconInfoLeft icon={<BusinessRounded />} title="Empresa" data={company} />
    </AboutMeContainer>
  );
};

export default AboutMe;

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem;
  border-radius: 20px;
  color: #bfbfbf;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
`;

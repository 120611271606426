import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import EmailIcon from "@mui/icons-material/Email";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { Text } from "./Texts";
import { userAction } from "../../helpers/userActions.helper";
import parse from "html-react-parser";
import { sendEmail } from "../../redux/api/utils";
import { useSelector } from "react-redux";
import * as encrypt from "nodejs-base64-encode";
import { options } from "../../utils/snackStyle";
import { useSnackbar } from "react-simple-snackbar";

export default function DialogEmail(props) {
  const { button: ButtonProp, sharedItem } = props;

  const { user } = useSelector((state) => state.auth);
  const [openSnackbar] = useSnackbar(options);

  const subject = `${user.name} compartió este contenido desde Aden International Business School`;
  let url = "https://app.aden.org/biblioteca/" + sharedItem.id;
  let image =
    "https://oerp-edu.aden.org/web/image?model=repo.aden.recurso&field=imagen&id=" +
    sharedItem.id;
  if (sharedItem.lp_type !== "microcontent") {
    const hash = encrypt.encode(sharedItem.id + "", "base64");
    url = "https://app.aden.org/curso/" + hash;
    image =
      "https://oerp-edu.aden.org/web/image?model=repo.aden.objeto_aprendizaje&field=imagen&id=" +
      sharedItem.id;
  }

  const html = `<div style="display: flex; justify-content: center; margin: 25px;">
    <div style="width: 300px; border: .5px solid #cccccc; border-radius: 5px;">
        <img style="width: 100%; border-top-left-radius: 5px; border-top-right-radius: 5px;" src="${image}" alt="">
        <div style="width: 100%; padding: 5px; height: 100px;">
            <h4 style="color: #591AB6; font-family: Arial, Helvetica, sans-serif;">${sharedItem.nombre_publicacion}</h4>
            <br/>
            <div style="text-align: end; margin-right: 15px;">
                <a href="${url}" style="padding: 5px 10px;font-weight: 600;  background-color: #591AB6; color: #fff; font-family: Arial, Helvetica, sans-serif; text-decoration: none; font-size: 12px; border-radius: 5px;">Ir a ver el contenido</a>
            </div>
        </div>
    </div>
</div>`;

  const [open, setOpen] = useState(false);
  const [to, setTo] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setTo(e.target.value);
  };

  const handleSubmit = async () => {
    const request = await sendEmail({
      from_email: process.env.REACT_APP_EMAIL,
      to_emails: [to],
      subject: subject,
      html_content: html,
    });
    if (request.error) {
      openSnackbar("¡El correo no pudo ser enviado!");
    } else {
      openSnackbar("¡Has compartido el contenido correctamente!");
      userAction({
        object_id: sharedItem.id + "",
        object: sharedItem.nombre_publicacion,
        name: sharedItem.nombre_publicacion
          ? sharedItem.nombre_publicacion
          : sharedItem.nombre,
        activity: "Compartir recurso",
        medio: "email",
      });
      handleClose();
    }
  };

  return (
    <div>
      <ButtonProp
        style={{
          backgroundColor: "#ad5951",
          width: "46.5px",
          height: "46.5px",
        }}
        onClick={handleClickOpen}
      >
        <EmailIconFake />
      </ButtonProp>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <Content>
          <Text color="#591AB6" fontSize="1.4rem" fontWeight="bold">
            Compartir contenido vía E-Mail
          </Text>
          <br />
          <TextField
            type="email"
            onChange={handleChange}
            fullWidth
            label="Para:"
            variant="outlined"
            value={to}
          />
          <TextField
            fullWidth
            label="Asunto"
            disabled
            variant="outlined"
            value={"Comparte este contenido desde Aden"}
          />
          <Message>
            <div>
              {parse(html)}
              <Filter />
            </div>
          </Message>
        </Content>
        <Footer>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cerrar
          </Button>
          <Button
            disabled={!to}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            Enviar e-mail
          </Button>
        </Footer>
      </Dialog>
    </div>
  );
}

const EmailIconFake = styled(EmailIcon)`
  color: white;
`;

const Content = styled.div`
  padding: 1rem;
  display: grid;
  row-gap: 25px;
`;

const Message = styled.div`
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 350px;
  cursor: text;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-y: auto;
`;

const Footer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 15px;
`;

const Filter = styled.div`
  background-color: #0000000a;
  position: absolute;
  width: 100%;
  height: 160%;
  top: 0;
  left: 0;
  z-index: 9;
`;

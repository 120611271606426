import React from "react";
import styled from "styled-components";

// Components
import Banner from "./components/Banner";
import SimpleLoading from "../../components/common/SimpleLoading";
import SubjectTabs from "./components/subjectTabs/SubjectTabs";
import useLayout from "../../hooks/useLayout";

export default function MainSubject(props) {
  const { subjectSelected, idCourse, origin, programId } = props;

  const { setMenu } = useLayout()

  setMenu(subjectSelected?.name !== "" ? subjectSelected?.name : "Nombre Asignatura")

  const COLOR = "var(--primary-one)"

  if (subjectSelected === undefined || subjectSelected === null) {
    return <SimpleLoading />;
  }

  if (subjectSelected?.progress === null) {
    return <SimpleLoading />;
  }

  return (
    <MainSubjectWrapper>
      <Banner
        subjectProgress={subjectSelected?.progress}
        subject={subjectSelected}
        color={COLOR} // Se puede hacer que venga de redux o algo así
      />
      <SubjectTabs
        subject={subjectSelected}
        idCourse={idCourse}
        origin={origin}
        programId={programId}
        color={COLOR}
      />
    </MainSubjectWrapper>
  );
}

const MainSubjectWrapper = styled.div`
  display: grid;
  position: relative;
  margin-top: -2rem;
  width: 100%;

  @media (width < 768px) {
    display: flex;
    flex-direction: column;
    gap: 12rem;
  }
`;

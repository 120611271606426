import React from "react";
import styled from "styled-components";

import { IconButton, Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import GridCardsModal from "./GridCardsModal";

const UsersModal = (props) => {
  const { isOpen, handleCloseModal, title, id } = props;

  // RETURN
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <UsersModalContainer>
        <Header>
          <TitleHeader>{title}</TitleHeader>
          <IconButton onClick={handleCloseModal} size="large">
            <CloseRounded color="secondary" fontSize="small" />
          </IconButton>
        </Header>
        <Body>
          <OverflowWrapper>
            <GridCardsModal id={id} title={title} isOpen={isOpen} />
          </OverflowWrapper>
        </Body>
      </UsersModalContainer>
    </Modal>
  );
};

export default UsersModal;

const UsersModalContainer = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  max-width: 80vw;
  height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #b7b7b7;
  border-bottom: 1px solid #eeeeee;

  @media (max-width: 768px) {
    background-color: #591AB6;
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;

const TitleHeader = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
`;

const Body = styled.div`
  height: 100%;
  padding: 1.5rem;
`;

const OverflowWrapper = styled.div`
  position: relative;
  height: 100%;
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import NoResultsFound from "../../../components/common/NoResultsFound";
import Filter from "../../../components/common/Filter";
import GridValues from "../../../components/common/GridValues";
import GridPaginationBox from "../../../components/common/GridPaginationBox";
import GridFilterTags from "../../../components/common/GridFilterTags";

// Material
import { FormControl } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import SimpleLoading from "../../../components/common/SimpleLoading";

const GridBusiness = (props) => {
  const {
    Card,
    menuFilter,
    allCourses,
    enableFilter = true,
    cardWidth = "310px",
    type = "course",
    filterByStateActivated = true,
    filterByCategoryActivated = true,
    progressActive,
    additional
  } = props;

  const [values, setValues] = useState([]);
  const [searchedValues, setSearchedValues] = useState(values)
  const [search, setSearch] = useState(null);
  const [openFilter, setOpenFilter] = useState(false)
  const [checkedState, setCheckedState] = useState(new Array(menuFilter?.length).fill(false));
  const [checkedStateAll, setCheckedStateAll] = useState(true)
  const [currentFilters, setCurrentFilters] = useState([])
  const [selectedOption, setSelectedOption] = useState("todos")
  const [selectedOptionActive, setSelectedOptionActive] = useState("todos")
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)

  const MAX_CARDS_PER_PAGE = window.innerWidth < 1750 ? 12 : 15;

  // EFFECTS

  // Set "allCourses" on "values"
  useEffect(() => {
    setValues(allCourses);
    setSearchedValues(allCourses)
  }, [allCourses]);

  // Filter with search
  useEffect(() => {
    if (search !== null) {
      if (search === "") {
        setSearchedValues(values);
      } else {
        setSearchedValues(
          values.filter((course) => {
            return course.nombre_publicacion
              .toLowerCase()
              .includes(search.toLowerCase()); //setea "values" desde "allCourses" filtrando por SEARCH "course.nombre_publicacion"
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, allCourses]);

  // Manage "totalPages" depending of "values"
  useEffect(() => {
    setSearchedValues(values)
    if (values.length > MAX_CARDS_PER_PAGE) {
      setTotalPages(Math.ceil(values.length / MAX_CARDS_PER_PAGE))
      page > Math.ceil(values.length / MAX_CARDS_PER_PAGE) && setPage(Math.ceil(values.length / MAX_CARDS_PER_PAGE))
    } else {
      setTotalPages(1);
      setPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  // Manage "totalPages" depending of "searchedValues"
  useEffect(() => {
    if (searchedValues.length > MAX_CARDS_PER_PAGE) {
      setTotalPages(Math.ceil(searchedValues.length / MAX_CARDS_PER_PAGE))
      page > Math.ceil(searchedValues.length / MAX_CARDS_PER_PAGE) && setPage(Math.ceil(searchedValues.length / MAX_CARDS_PER_PAGE))
    } else {
      setTotalPages(1);
      setPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues])

  useEffect(() => {
    handleApplyFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionActive])

  useEffect(() => {

  }, [menuFilter])

  //FUNCTIONS

  function handleApplyFilters() {

    const filtrosActivos = menuFilter?.filter((filtro, index) => checkedState[index]);

    if (!filterByCategoryActivated) {
      const arrayFilterByState = filterByState(allCourses);
      setValues(arrayFilterByState)
      return;
    } else if (!filterByCategoryActivated) {
      setCurrentFilters(filtrosActivos)
      const arrayFiltrado = filterByCategory(allCourses, filtrosActivos);
      setValues(arrayFiltrado);
      return;
    } else {
      setCurrentFilters(filtrosActivos)
      if (filtrosActivos?.length === 0 && checkedStateAll) {
        const arrayFilterByState = filterByState(allCourses)
        setValues(arrayFilterByState);
      } else {
        const arrayFiltrado = filterByCategory(allCourses, filtrosActivos);
        const arrayFilterByState = filterByState(arrayFiltrado);
        setValues(arrayFilterByState);
      }
      setSelectedOptionActive(selectedOption)
      return;
    }
  }

  function handleClearFilters() {
    setValues(allCourses)
    setCheckedState(new Array(menuFilter.length).fill(false));
    setCheckedStateAll(true)
    setCurrentFilters([])
    setSelectedOption("todos")
    setSelectedOptionActive("todos")
  }

  function filterByState(arrayToFilter) {
    if (type === "route") {
      return arrayToFilter.filter(object => {
        switch (selectedOption) {
          case "todos":
            return true;
          case "en curso":
            return object.progress.progress > 0 && object.progress.progress < 100;
          case "por iniciar":
            return object.progress.progress === 0;
          case "finalizado":
            return object.progress.progress === 100;
          default:
            return false;
        }
      });
    } else {
      return arrayToFilter.filter(object => {
        switch (selectedOption) {
          case "todos":
            return true;
          case "en curso":
            return object.progreso > 0 && object.progreso < 100;
          case "por iniciar":
            return object.progreso === 0;
          case "finalizado":
            return object.progreso === 100;
          default:
            return false;
        }
      });
    }

  }

  function filterByCategory(arrayToFilter, arrayOfCategories) {
    return arrayToFilter.filter(objeto => objeto.eje_tematico_ids?.some(categoria => arrayOfCategories?.some(filtro => filtro.name === categoria.nombre || filtro.name === categoria.name)));
  }

  function handleOnChangeChecks(position) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    const thereIsSomeTrue = updatedCheckedState.some(elemento => elemento === true);
    thereIsSomeTrue ? setCheckedStateAll(false) : setCheckedStateAll(true)
  };

  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  function handleDeleteTagCategory(name) {
    const index = menuFilter.findIndex(filtro => filtro.name === name);
    if (index !== -1) {
      setCheckedState(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
    const filtrosActivos = currentFilters.filter(filtro => filtro.name !== name);
    setCurrentFilters(filtrosActivos)
    if (filtrosActivos.length === 0) {
      const arrayFilterByState = filterByState(allCourses)
      setValues(arrayFilterByState)
      setCheckedStateAll(true)
    } else {
      const arrayFiltrado = filterByCategory(allCourses, filtrosActivos)
      const arrayFilterByState = filterByState(arrayFiltrado);
      setValues(arrayFilterByState);
    }
  };

  function handleDeleteTagState() {
    setSelectedOption("todos")
    setSelectedOptionActive("todos")
  }

  const sonIguales = values.length === searchedValues.length && values.every((obj, index) => {
    return obj.id === searchedValues[index].id;
  })

  // RETURN
  return (
    <BigContainer>
      <Container enableFilter={enableFilter}>
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          menuFilter={menuFilter}
          handleOnChangeChecks={handleOnChangeChecks}
          checkedState={checkedState}
          handleApplyFilters={handleApplyFilters}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          checkedStateAll={checkedStateAll}
          handleClearFilters={handleClearFilters}
          filterByStateActivated={filterByStateActivated}
          filterByCategoryActivated={filterByCategoryActivated}
        />
        {enableFilter && (
          <Wrapper>
            <Filters>
              <SearchFilter>
                <TextField
                  type="text"
                  value={search}
                  onChange={handleChangeSearch}
                  placeholder="Buscar..."
                />
                <SearchIcon />
              </SearchFilter>
              {menuFilter?.length > 0 && (
                <FormControlFake>
                  <ButtonFilter onClick={() => {
                    setOpenFilter(!openFilter)
                  }}>
                    <TuneIcon />
                    Filtrar
                  </ButtonFilter>
                </FormControlFake>
              )}
            </Filters>
          </Wrapper>
        )}
        <GridFilterTags
          currentFilters={currentFilters}
          selectedOptionActive={selectedOptionActive}
          handleDeleteTagState={handleDeleteTagState}
          handleDeleteTagCategory={handleDeleteTagCategory}
        />
        <>
          {
            sonIguales ? <>{
              values.length === 0 ? (
                <NoResultsFound minHeight={"450px"} />
              ) : (
                <GridValues
                  values={values}
                  cardWidth={cardWidth}
                  MAX_CARDS_PER_PAGE={MAX_CARDS_PER_PAGE}
                  page={page}
                  Card={Card}
                  type={type}
                  progressActive={progressActive}
                  additional={additional}
                />
              )}</> : <>{
                searchedValues.length === 0 ? (
                  <NoResultsFound minHeight={"450px"} />
                ) : (
                  <GridValues
                    values={searchedValues}
                    cardWidth={cardWidth}
                    MAX_CARDS_PER_PAGE={MAX_CARDS_PER_PAGE}
                    page={page}
                    Card={Card}
                    type={type}
                    progressActive={progressActive}
                    additional={additional}
                  />
                )}</>
          }
        </>
      </Container>
      <GridPaginationBox
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </BigContainer>
  );
};

export default GridBusiness;

const BigContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 470px;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  height: auto;
  background-color: var(--secondary-one);
  border-radius: 20px;
  min-height: 650px;
  @media screen and (max-width: 1121px) {
    grid-template-columns: ${(p) => (p.enableFilter ? "1fr 2fr" : "repeat(3)")};
    /* grid-template-columns: repeat(3) */
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 768px) {
    width: 60vw;
    position: fixed;
    z-index: 10;
    padding-top: 60px;
    height: 95%;
    left: ${(p) => (p.openAside ? "0" : "-110%")};
    gap: 50px;
    top: 8%;
    background: #fff;
    transition: all ease-in-out 0.3s;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 1rem);
  border-radius: 20px 20px 0 0;
  padding: 1rem 2rem;
`;

const SearchFilter = styled.div`
  position: relative;
`

const SearchIcon = styled(SearchRounded)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-four);
`

const TextField = styled.input` 
  background: #F9F9F9;
  color: #000;
  margin-bottom: 0;
  width: 309px;
  border-radius: 8px;
  height: 32px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: start;
  padding: 12px 16px 12px 48px;
  font-size: 16px;

  ::placeholder {
    color:  var(--secondary-four);
    opacity: 1; /* Firefox */
  }
`

const FormControlFake = styled(FormControl)`
  width: 100%;
  height: 10vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  position: relative;
  margin-bottom: auto;
  .MuiFormControl-root {
    width: 90%;
    height: auto;
    align-content: space-between;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInputLabel-formControl {
    display: contents;
    align-content: center;
    color: ${(props) => props.color};
    width: 100%;
    label {
      margin: 0 1.5rem;
    }
    text-align: center;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.color};
  }
`;

const ButtonFilter = styled.button`
  height: 48px;
  width: fit-content;
  padding: 0 24px 0 16px;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-one);
  color: var(--secondary-one);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`
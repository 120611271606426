import React from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../components/common/Texts"

// Material UI
import AccessTime from "@mui/icons-material/AccessTime";

const Pending = () => {
  return (
    <PendingWrapper>
      <AccessTime />
      <Text fontWeight="700" fontSize="24px" color="#A8A8A8">
        Entrega pendiente de calificación
      </Text>
    </PendingWrapper>
  );
};

export default Pending;

const PendingWrapper = styled.div`
  border-radius: 20px;
  height: 238px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  svg {
    font-size: 40px;
    color: #a8a8a8;
  }
`;

import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

const ControlsSteps = (props) => {
    const { handleBackSlide, handleNextSlide, currentStep, popupLength } =
        props;

    return (
        <Wrapper>
            <StepsNumbers>
                <p>
                    <span>{currentStep + 1}</span>/{popupLength}
                </p>
            </StepsNumbers>
            <ArrowsButtons>
                <IconButton
                    disabled={currentStep === 0}
                    color="primary"
                    onClick={handleBackSlide}
                >
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    disabled={currentStep === popupLength - 1}
                    color="primary"
                    onClick={handleNextSlide}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </ArrowsButtons>
        </Wrapper>
    );
};

export default ControlsSteps;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StepsNumbers = styled.div`
    p {
        font-size: 1.1rem;
        letter-spacing: 0.3rem;
        line-height: 140%;
        color: #a8a8a8;

        span {
            color: #222222;
            font-weight: bold;
        }
    }
`;

const ArrowsButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

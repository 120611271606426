import React from "react";
import styled from "styled-components";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import useTheme from "../../../../hooks/useTheme";

const Socials = (props) => {
  const { primary } = useTheme();
  const { facebook, instagram, linkedin, twitter, whatsapp } = props;

  return (
    <SocialsContainer>
      <Title>Redes sociales</Title>
      <SocialsWrapper>
        <FakeIconButton
          red={facebook}
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          color={primary}
        >
          <FacebookIcon></FacebookIcon>
        </FakeIconButton>
        <FakeIconButton
          red={instagram}
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
          color={primary}
        >
          <InstagramIcon></InstagramIcon>
        </FakeIconButton>
        {/* <FakeIconButton
					red={skype}
					href={skype}
					target="_blank"
					rel="noopener noreferrer"
                    color={primary}
				>
					<i className="fab fa-skype"></i>
				</FakeIconButton> */}
        <FakeIconButton
          red={linkedin}
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
          color={primary}
        >
          <LinkedInIcon></LinkedInIcon>
        </FakeIconButton>
        <FakeIconButton
          red={twitter}
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
          color={primary}
        >
          <TwitterIcon></TwitterIcon>
        </FakeIconButton>
        <FakeIconButton
          red={whatsapp}
          href={`https://wa.me/?phone=${whatsapp}`}
          target="_blank"
          rel="noopener noreferrer"
          color={primary}
        >
          <WhatsappIcon></WhatsappIcon>
        </FakeIconButton>
      </SocialsWrapper>
    </SocialsContainer>
  );
};

export default Socials;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem;
  border-radius: 20px;
  color: #bfbfbf;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
`;

const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 0px;
  }
`;

const FakeIconButton = styled.a`
  font-size: 2.5rem;
  color: ${(props) => (props.red !== "" ? `${props.color}` : "#cbcbcb")};
`;

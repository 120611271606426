import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import Upselling from "./Upselling";
import MyDeliveries from "./MyDeliveries";
import { SubjectModules } from "./SubjectModules";
import SubjectDescriptions from "./SubjectDescriptions";
import SubjectCalifications from "./SubjectCalifications";
import DialogCertificate from "../../../../components/ui/molecules/studyPlan/DialogCertificate";
import DialogActivity from "../../../../components/ui/molecules/studyPlan/DialogActivity";
import TabsComponent from "../../../../components/common/TabsComponent";
import SimpleLoading from "../../../../components/common/SimpleLoading";

const SubjectTabs = (props) => {
  const {
    subject,
    idCourse,
    origin,
    color
  } = props;

  const subjectCourses = subject.courses;
  const descr = subject.information;
  const enrolled = subject.total_enrolled;
  const { type } = subject;
  const FREE_TYPE = "DEMO";

  const tabs = [
    { label: `Tablero`, id: 5 },
    {
      label:
        type === FREE_TYPE ? "Calificaciones (bloqueado)" : "Calificaciones",
      id: 3,
      disabled: type === FREE_TYPE ? true : false,
    },
  ];

  const [tab, setTab] = useState(5);
  const [valuesFake, setValuesFake] = useState([]);
  const [openTest, setOpenTest] = useState(false);

  useEffect(() => {
    handleOnStudy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS
  const handleTab = (e, value) => {
    setTab(value);
  };

  const handleOnStudy = () => {

    let arrayCourses = subjectCourses.sort((a, b) => a.order - b.order);

    for (let index = 0; index < arrayCourses.length; index++) {
      if (index === 0) {
        arrayCourses[index].onStudy = true;
      } else {
        if (
          arrayCourses[index - 1].progress >= 100 ||
          !!arrayCourses[index - 1]?.survey.done
        ) {
          arrayCourses[index].onStudy = true;
        } else {
          arrayCourses[index].onStudy = false;
        }
      }
    }
    setValuesFake(arrayCourses);
  };

  function ToggleMain(props) {
    const { tab } = props;

    const cases = {
      0: (
        <>
          {valuesFake.length > 0 && (
            <SubjectModules
              mod={valuesFake}
              idCourse={idCourse}
              subject={subject}
              handleOnStudy={handleOnStudy}
              origin={origin}
            />
          )}
        </>
      ),
      1: <MyDeliveries />,
      2: (
        <SubjectDescriptions
          descr={descr}
          subject={subject}
          totalStudents={enrolled}
        />
      ),
      3: <SubjectCalifications idCourse={idCourse} />,
      5: (
        <Gap isDemo={subject.type === "DEMO" ? true : false}>
          <Modules>
            <SubjectModules
              mod={valuesFake}
              idCourse={idCourse}
              subject={subject}
              handleOnStudy={handleOnStudy}
              origin={origin}
              color={color}
            />
          </Modules>
          {subject.type === FREE_TYPE && (
            <Upselling
              img={subject.img}
              setOpenTest={setOpenTest}
              openTest={openTest}
            />
          )}
        </Gap>
      ),
    };

    return cases[String(tab)] || <SubjectModules />;
  }

  //RETURN
  if (subject.length === 0) {
    return (
      <CardFake>
        <SimpleLoading />
      </CardFake>
    );
  }

  const firstCourse =
    subject?.courses?.find((course) => course.is_free === true) ||
    subject?.courses[0];

  return (
    <>
      <TabPosition>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </TabPosition>
      <CardFake>
        <BodyWrapper>
          <ToggleMain tab={tab} />
        </BodyWrapper>
      </CardFake>
      {subject.type === FREE_TYPE && firstCourse?.progress >= 100 && (
        <DialogCertificate
          setOpenTest={setOpenTest}
          course={firstCourse}
          type={"demo"}
        />
      )}
      {subject.type === FREE_TYPE && firstCourse?.progress < 100 && (
        <DialogActivity subject={subject} course={firstCourse} />
      )}
    </>
  );
};
export default SubjectTabs;

const CardFake = styled.div`
  margin-top: 24rem;

  @media (width < 768px) {
    margin-top: 48rem;
  }
`;

const BodyWrapper = styled.div`
  @media (width < 768px) {
    width: 100%;
  }
`;

const TabPosition = styled.div`
  position: absolute;
  width: calc(100% + 1.4rem);
  padding-left: 2.5rem;
  left: -2rem;
  background-color: white;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
`;

const Gap = styled.div`
  display: grid;
  grid-template-columns: ${(p) => (p.isDemo ? "3fr 1fr" : "1fr")};
  gap: 16px;
`;

const Modules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

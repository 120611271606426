import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as encrypt from "nodejs-base64-encode";

// Components
import CalificationPercentage from "./subjectCalification.js/CalificationPercentage";
import ActivityCalification from "./subjectCalification.js/ActivityCalification";
import NoResultsFound from "../../../../components/common/NoResultsFound";
import DefaultCard from "../../../../components/common/DefaultCard";
import { Text } from "../../../../components/common/Texts";

// Material UI
import { Button } from "@mui/material";

// Redux
import { useSelector } from "react-redux";
import { getCalifications } from "../../../../redux/api/califications/califications";

const SubjectCalifications = (props) => {
  const { idCourse } = props;

  const repo_id = useSelector((state) => state.auth.user.repo_id);
  const [tab, setTab] = useState(0);
  const decode = encrypt.decode(idCourse, "base64");

  // STATES
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) getActivitiesCalifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS

  // trae las todas las actividades con su nota
  const getActivitiesCalifications = async () => {
    const response = await getCalifications(repo_id, decode);
    if (!response.error) {
      setData(response);
    } else {
      setData([]);
    }
  };

  const handleTab = () => {
    setTab(tab === 0 ? 1 : 0);
  };

  return (
    <DefaultCard>
      <Text color="#555" fontSize="22px" fontWeight="bold">
        Mis calificaciones
      </Text>
      <br />
      <Menu>
        <Button
          onClick={handleTab}
          variant={tab === 0 ? "outlined" : "default"}
          disabled={tab === 1 ? false : true}
        >
          Notas por módulo
        </Button>
        <Button
          onClick={handleTab}
          variant={tab === 1 ? "outlined" : "default"}
          disabled={tab === 0 ? false : true}
        >
          Promedio asignatura
        </Button>
      </Menu>
      {/* Calificacion por OA */}
      {tab === 0 && (
        <Container>
          {data.length === 0 ? (
            <>
              <NoResultsFound message="No se encontraron calificaciones." />
            </>
          ) : (
            <>
              {data
                .sort((a, b) => a.order - b.order)
                .map((activity, index) => {
                  // CICLADO DE CADA ACTIVIDAD
                  return <ActivityCalification key={index} data={activity} />;
                })}
            </>
          )}
        </Container>
      )}
      {tab === 1 && (
        <Container>
          <CalificationPercentage values={data} />
        </Container>
      )}
    </DefaultCard>
  );
};

export default SubjectCalifications;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  overflow: hidden;
`;

const Menu = styled.div`
  display: flex;
  gap: 16px;
`;

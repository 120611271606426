import { chatSlice } from "./chatSlice";

const { actions } = chatSlice;

export const changeChannel = (channel) => (dispach) => {
  dispach(actions.channelSelect(channel));
};

export const setParticipants = (participants) => (dispach) => {
  dispach(actions.setParticipants(participants));
};

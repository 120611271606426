import * as types from "../types/subject_types";

const actions = {};

actions.getSubjectsRequest = (id) => {
  return {
    type: types.GET_SUBJECTS_REQUEST,
    payload: id,
  };
};

actions.getSubjectsSuccess = (payload) => {
  return {
    type: types.GET_SUBJECTS_SUCCESS,
    payload: payload,
  };
};

actions.getSubjectsFail = (error) => {
  return {
    type: types.GET_SUBJECTS_FAIL,
    payload: error,
  };
};

//Pagination
actions.getSubjectPaginateRequest = (payload) => {
  return {
    type: types.GET_SUBJECT_PAGINATION_REQUEST,
    payload: payload,
  };
};

actions.getSubjectPaginateSuccess = (pagination) => {
  return {
    type: types.GET_SUBJECT_PAGINATION_SUCCESS,
    payload: pagination,
  };
};

actions.getSubjectPaginateFail = (error) => {
  return {
    type: types.GET_SUBJECT_PAGINATION_FAIL,
    payload: error,
  };
};

actions.nextSubjectPage = () => {
  return {
    type: types.NEXT_SUBJECT_PAGE,
  };
};
actions.previousSubjectPage = () => {
  return {
    type: types.PREVIOUS_SUBJECT_PAGE,
  };
};
actions.changeSubjectPage = (page) => {
  return {
    type: types.CHANGE_SUBJECT_PAGE,
    payload: page,
  };
};

//Directory
actions.getSubjectDirectoriesRequest = (query) => {
  return {
    type: types.GET_SUBJECT_DIRECTORIES_REQUEST,
    payload: query,
  };
};

actions.getSubjectDirectoriesSuccess = (directories) => {
  return {
    type: types.GET_SUBJECT_DIRECTORIES_SUCCESS,
    payload: directories,
  };
};

actions.getSubjectDirectoriesFail = (error) => {
  return {
    type: types.GET_SUBJECT_DIRECTORIES_FAIL,
    payload: error,
  };
};

export { actions };

import axios from "axios";

export function getWorkshops() {
  return axios.get(`${process.env.REACT_APP_REPO}/v1/repo_aden/workshops`);
}

export function getWorkshopById(payload, origin) {
  return axios.get(
    `${
      process.env.REACT_APP_REPO
    }/v1/repo_aden/objeto_aprendizaje/${payload}?origin=${origin || "alab"}`
  );
}

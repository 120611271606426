import axios from "axios";

export function getOndemandById(id, repoId) {
  return axios.get(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/${id}${
      !!repoId && `?partner_id=${repoId}`
    }`
  );
}

export function getOndemandByCompetitor(data) {
  return axios.get(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/obtener_participante/${data.id}?email_participante=${data.email}`
  );
  }

export function getAdditionalCourses(id) {
  return axios.get(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/obj_aprendizaje_all/${id}`
  );
}

export function getAnalytics(payload) {
  return axios.get(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/analytics/students/${payload.id}/progress?participant_id=${payload.participant_id}`
  );
};

export async function getBySubDomain(domain) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/landing/${domain}`;
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
}

export async function getAssignamentByIC(id, partner_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/assignments/partner/${id}?partner_id=${partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

export async function getLastAssignmentOrCourse(id, partner_id, type){
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/last_open_assignment_or_course/${id}?partner_id=${partner_id}&${type === "assignment" ? "asignatura=true" : "curso=true"}` 
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
}

// ENVIAR MAIL CUANDO CONSULTE CERTIFICADO
export async function sendEmailCertificate(oaId, repo_id, ondemand_id) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/certificate/${ondemand_id}?oa_id=${oaId}&partner_id=${repo_id}`;
  try {
    const response = await axios.post(URL);
    return response.data;
  } catch (error) {
    return { error };
  }
}

export async function getDemoData(hash, ondemandId) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/objeto_aprendizaje/${hash}?origin=alab&acropolislab_id=${ondemandId}`;
  try {
    const res = await axios.get(URL);
    return res.data.response_data;
  } catch (error) {
    return {
      error,
    };
  }
}

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

// Components
import CourseTemplate from "./CourseTemplate";

// Hooks
import useLayout from "../../hooks/useLayout";

// Redux
import { useSelector } from "react-redux";

// Helpers
import { titleHTML } from "../../helpers/title.helper";
import { updateLastEntry } from "./redux/api/updateLastEntry";
import { CourseContextProvider } from "./context/CourseContext";

export default function Course() {
  titleHTML("Curso");

  const { id } = useParams();

  const { clearMenu } = useLayout()

  const { od_participant_id } = useSelector((state) => state.auth.user);

  clearMenu()

  const hash = encode.decode(id + "", "base64");

  useEffect(() => {
    updateLastEntry(od_participant_id?.id, hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CourseContextProvider hash={hash}>
      <CourseTemplate />
    </CourseContextProvider>
  );
}

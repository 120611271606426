import styled from "styled-components";
import { encodeData } from "../../helpers/encrypt.helper";


const GridValues = (props) => {

    const { values, cardWidth, MAX_CARDS_PER_PAGE, page, Card, type, progressActive, additional } = props;

    

    return (
        <GridValuesContainer cardWidth={cardWidth}>
            {values
                .sort(function (a) {
                    if (a.id === 6619) {
                        return -1;
                    }
                })
                .slice(MAX_CARDS_PER_PAGE * (page - 1), values.length > (MAX_CARDS_PER_PAGE * page) ? (MAX_CARDS_PER_PAGE * page) : values.length)
                .map((value, i) => {
                    return (
                        <Card
                            value={value}
                            key={i}
                            courseId={value?.id}
                            progress={value?.progreso ?? value?.progress?.progress ?? 0}
                            finalNote={value?.nota_final ?? value?.courses?.length ?? 0}
                            type={type}
                            progressActive={progressActive}
                            additional={additional}
                        />
                    )
                })}
        </GridValuesContainer>
    )
}

export default GridValues;

const GridValuesContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(auto, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(${(props) => props.cardWidth}, 1fr));
  justify-content: space-between;
  align-self: center;
  width: calc(100% - 8rem);
  padding: 4rem;
  height: auto;
  row-gap: 3rem;
  column-gap: 1rem;
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`;
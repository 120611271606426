import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import GridResource from "../../common/GridResource";
import EventCard from "../../ui/organisms/events/EventCard";
import TabsComponent from "../../common/TabsComponent";
import EventIcon from "@mui/icons-material/Event";
import SharedResource from "../../common/SharedResource";
import EmptyGridResource from "../../common/EmptyGridResource";
import MicrocontentCardSkeleton from "../../common/MicrocontentCardSkeleton";
import { getDynamicEvents } from "../../../redux/api/events/events";

const OPTIONS = [
    { id: 0, label: "Próximos" },
    { id: 1, label: "Pasados" },
];

const EventsMain = () => {
    // REDUX
    const ondemandId = useSelector((state) => state?.auth?.user?.ondemand_id);

    // STATE
    const [currentTab, setCurrentTab] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedLink, setSharedLink] = useState(null);
    const [sharedItem, setSharedItem] = useState(null);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const emptyItems = new Array(6).fill(null);
    const [flagForEvents, setFlagForEvents] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (flagForEvents === true) {
            if (currentTab === 0) {
                getDynamicEvent("all", "all", "next", ondemandId);
            }
            if (currentTab === 1) {
                getDynamicEvent("all", "all", "past", ondemandId);
            }
            setFlagForEvents(false);
        }
    }, [flagForEvents]);

    useEffect(() => {
        if (event === null) {
            if (currentTab === 0) {
                getDynamicEvent("all", "all", "next", ondemandId);
            }
        }
    }, []);

    useEffect(() => {
        if (currentTab === 0) {
            getDynamicEvent("all", "all", "next", ondemandId);
        }
        if (currentTab === 1) {
            getDynamicEvent("all", "all", "past", ondemandId);
        }
    }, [currentTab]);

    // FUNCTIONS
    const getDynamicEvent = async (modality, limit, timelapse, ondemandId) => {
        setLoading(true);
        const response = await getDynamicEvents(
            modality,
            limit,
            timelapse,
            ondemandId
        );
        setLoading(false);
        if (response.error) {
            setEvent([]);
        } else {
            setEvent(response);
        }
    };

    function changeTab(e, value) {
        setCurrentTab(value);
    }

    function handleOpenModal(sharedLink, id, nombre, lp_type) {
        setSharedLink(sharedLink);
        setSharedItem({ lp_type, type: "evento", id, nombre });
        setModalIsOpen(true);
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    // RETURN
    if (!event) {
        return (
            <GridMain>
                <EmptyGridResource
                    propsValues={emptyItems}
                    Card={MicrocontentCardSkeleton}
                />
            </GridMain>
        );
    }

    // EVENTS DATES

    const getLocalUTC = () => {
        const hours = new Date().getTimezoneOffset();
        if (hours > 0) {
            return `-${hours / 60}`;
        } else {
            return `+${(hours * -1) / 60}`;
        }
    };

    const ActiveTabContent = () => {
        if (currentTab === 0) {
            return (
                <MainWrapper>
                    {event.length === 0 || event === null ? (
                        <NotFoundWrapper>
                            <EventIcon htmlColor="#a8a8a8" fontSize="large" />
                            <h1>Aún no hay eventos programados</h1>
                        </NotFoundWrapper>
                    ) : (
                        <GridResource
                            propsValues={event}
                            currentTab={currentTab}
                            type="event"
                            Card={EventCard}
                            loading={loading}
                            setFlagForEvents={setFlagForEvents}
                            handleOpenModal={handleOpenModal}
                        />
                    )}
                </MainWrapper>
            );
        }

        if (currentTab === 1) {
            return (
                <MainWrapper>
                    {event.length === 0 || event === null ? (
                        <NotFoundWrapper>
                            <EventIcon htmlColor="#a8a8a8" fontSize="large" />
                            <h1>No encontramos eventos pasados</h1>
                        </NotFoundWrapper>
                    ) : (
                        <GridResource
                            type={"event"}
                            propsValues={event}
                            Card={EventCard}
                            loading={loading}
                            handleOpenModal={handleOpenModal}
                        />
                    )}
                </MainWrapper>
            );
        }

        return <div></div>;
    };

    return (
        <>
            <Container>
                <TabsComponent
                    tab={currentTab}
                    handleTab={changeTab}
                    tabs={OPTIONS}
                />

                <ActiveTabContent></ActiveTabContent>
            </Container>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedLink={sharedLink}
                sharedItem={sharedItem}
            />
        </>
    );
};

export default EventsMain;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    border-radius: 20px;
    padding: 2rem;
`;
const NotFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    text-align: center;
    padding: 0 2rem;
    background-color: #fafafa;
    gap: 1rem;
    row-gap: 1rem;

    h1 {
        font-size: 1rem;
        color: #a8a8a8;
        font-weight: 700;
    }
`;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;

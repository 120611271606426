import React, { useState } from "react";
import styled from "styled-components";
import OptionWrapper from "../../ui/organisms/events/OptionWrapper";
import { Text } from "../../common/Texts";
import { useSelector } from "react-redux";

const AdenEvents = (props) => {

    const { firstSection = true } = props;
    // REDUX
    const nameCompany = useSelector(
        (state) => state?.ondemand?.ondemand?.acropolis_title
    );

    // STATE
    const [currentTab, setCurrentTab] = useState(1);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    return (
        <Container data-tut="reactour__proximos_eventos" firstSection={firstSection}>
            {/** Titulo */}
            <TitleWrapper>
                <Text fontWeight="700" fontSize="18px">
                    Próximos eventos
                </Text>
            </TitleWrapper>
            {/** Menu de opciones */}
            <MenuWrapper>
                <ButtonFake
                    colorPrimary={"var(--primary-one)"}
                    onClick={() => changeTab(null, 1)}
                    active={currentTab === 1 ? true : false}
                >
                    Agenda ADEN
                </ButtonFake>
                <ButtonFake
                    colorPrimary={"var(--primary-one)"}
                    onClick={() => changeTab(null, 0)}
                    active={currentTab === 0 ? true : false}
                >
                    Agenda {nameCompany}
                </ButtonFake>
            </MenuWrapper>
            {/** Opcion */}
            <NextWrapper>
                <OptionWrapper firstSection={firstSection} currentTab={currentTab} />
            </NextWrapper>
        </Container>
    );
};

export default AdenEvents;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 8px 8px -4px #18274B14, 0px 4px 6px -4px #18274B1F;
    min-height: ${(p) => p.firstSection && "500px"};
    height: ${(p) => !p.firstSection ? "416px" : "100%"};
    margin-top: ${(p) => !p.firstSection && "55px"};
    width: 50%;
    width: ${(p) => !p.firstSection && "460px"};
    border: 1px solid #DCDCDC;
    @media (max-width: 768px) {
        padding: 1rem;
        width: calc(100% - 2rem);
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    padding: 1.5rem;
`;

const NextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
    height: 100%;
`;

const MenuWrapper = styled.div`
    display: grid;
    gap: 12px;
    height: 50px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 18px;
`;

const ButtonFake = styled.div`
    width: 100%;
    border-radius: 20px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.colorPrimary || "#591AB6"};
    border: ${(p) => p.active && "1px solid #F1F1F1"};
    background-color: ${(p) => p.active && "#F9F9F9"};
    font-weight: 700;
    font-size: 14px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    cursor: pointer;
    transition: all 0.3s ease;
    ${(p) =>
        !p.active &&
        `
            color: #A8A8A8;
            box-shadow: none;
        `}

    :hover {
        box-shadow:${(p) => p.active && "0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)"};
    }
`;

import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import useStatus from "../../../../../hooks/useStatus";

const AnalyticBar = (props) => {

    const { array, heightBar } = props;

    // STATES
    // Manejan el número de porcentaje
    const [percentageToStart, setPercentageToStart] = useState(0)
    const [percentageInProgress, setPercentageInProgress] = useState(0)
    const [percentageCompleted, setPercentageCompleted] = useState(0)

    // Manejan el número de altura, el número más alto va a ser el 100% y los otros dos van a depender del más alto
    const [heightpercentageToStart, setHeightPercentageToStart] = useState(0)
    const [heightpercentageInProgress, setHeightPercentageInProgress] = useState(0)
    const [heightpercentageCompleted, setHeightPercentageCompleted] = useState(0)

    /* ---------- Si se cambia de TAB antes de los 6000ms se rompe - revisar ---------- */

    useEffect(() => {

        setPercentageToStart(0)
        setPercentageInProgress(0)
        setPercentageCompleted(0)

        setHeightPercentageToStart(0)
        setHeightPercentageInProgress(0)
        setHeightPercentageCompleted(0)

        const { start, inProgress, completed, maxPercentage } = searchCount(array);

        const porcentaje = (setModificado, estado, setAltura) => {
            setAltura((estado / maxPercentage) * heightBar)

            const id1 = setInterval(() => {
                setModificado((oldCount) => oldCount < estado ? oldCount + 1 : estado)
            }, 3000 / estado);


            setTimeout(function () {
                clearInterval(id1);
            }, 10000);
        }

        porcentaje(setPercentageToStart, start, setHeightPercentageToStart)
        porcentaje(setPercentageInProgress, inProgress, setHeightPercentageInProgress)
        porcentaje(setPercentageCompleted, completed, setHeightPercentageCompleted)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Devuelve el porcentaje de cursos iniciados, en progreso, completados y también cual es el porcentaje más alto de todos
    const searchCount = (array) => {
        
        let toStartCount = 0;
        let inProgressCount = 0;
        let completedCount = 0;
        
        if (!array) return {
            start: 0,
            inProgress: 0,
            completed: 0,
            maxPercentage: 0
        };

        // Recorre el array y cuenta cuantos hay de cada uno
        // eslint-disable-next-line array-callback-return
        array.map((course) => {
            if (course?.progreso === 0 || course?.progress?.progress === 0) {
                toStartCount++;
            } else if (course.progreso === 100 || course?.progress?.progress === 100) {
                completedCount++;
            } else {
                inProgressCount++;
            }
        });

        let total = toStartCount + inProgressCount + completedCount;

        let startPercentage = Math.round((toStartCount * 100) / total);
        let inProgressPercentage = Math.round((inProgressCount * 100) / total);
        let completedPercentage = Math.round((completedCount * 100) / total);

        let maxPercentage = Math.max(startPercentage, inProgressPercentage, completedPercentage);

        return {
            start: startPercentage,
            inProgress: inProgressPercentage,
            completed: completedPercentage,
            maxPercentage
        }
    };

    // HOOK

    const { setStrongStateColor } = useStatus();

    // Tipos de barras
    const analytics = [
        {
            label: "Por iniciar",
            height: heightpercentageToStart,
            percentage: percentageToStart,
            color: setStrongStateColor("sin_entrega")
        },
        {
            label: "En curso",
            height: heightpercentageInProgress,
            percentage: percentageInProgress,
            color: setStrongStateColor("pendiente")
        },
        {
            label: "Finalizados",
            height: heightpercentageCompleted,
            percentage: percentageCompleted,
            color: setStrongStateColor("corregido")
        }
    ]

    return analytics.map((item) => {
        return (
            <Analytic height={item.height + "%"} key={item.label}>
                <Percentage color={item.color}>
                    {item.percentage || 0}%
                </Percentage>
                <PercentageBarContainer >
                    <PercentageBar height={item.height + "px"} color={item.color} />
                </PercentageBarContainer>
                <PercentageType>
                    {item.label}
                </PercentageType>
            </Analytic>
        )
    })
}


export default AnalyticBar;

const barUp = (height) => keyframes`
    0% {
        height: 20px;
    }
    100% {
        height: ${height};
    }
`

const colorText = (color) => keyframes`
    0% {
        color: var(--primary-two);
    }
    99% {
        color: var(--primary-two);
    }
    100% {
        color: ${color};
    }
`

const Analytic = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 12px;
`

const Percentage = styled.div`
    font-size: 20px;
    font-weight: 500;
    animation: ${(p) => colorText(p.color)} 3000ms ease-in-out forwards;
`

const PercentageBarContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: end;
`

const PercentageBar = styled.div`
    min-height: 20px;
    width: 70px;
    border-radius: 20px 20px 5px 5px;
    background-color: ${(p) => p.color};
    animation: ${(props) => barUp(props.height)} 3000ms ease-in-out forwards;
`

const PercentageType = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-two);
`
import React from 'react';
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import OutlinedButtonWithIcon from './OutlinedButtonWithIcon';


const LinkToEvent = (props) => {
    const {link, primary_color}= props; 

    const handleClick = () => {
        window.open(link, "_blank");
      };

  return (
    <OutlinedButtonWithIcon
    icon={<LinkRoundedIcon />}
    text="Enlace del evento"
    color={primary_color ? primary_color : "#b31d15"}
    action={handleClick}
  />
  )
}

export default LinkToEvent
import styled from "styled-components";
import React, { useState } from "react";

// Components
import TabsComponent from "../../../../components/common/TabsComponent";

const FooterResource = (props) => {
  const { padlet } = props;
  let OPTIONS = [{ id: 0, label: "Descripción" }];
  if (!!padlet) {
    OPTIONS = [
      { id: 0, label: "Padlet" },
      { id: 1, label: "Descripción" },
    ];
  }
  // STATE
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (_, value) => {
    setCurrentTab(value);
  };

  return (
    <Container>
      <TabsComponent
        id="aca"
        tab={currentTab}
        handleTab={changeTab}
        tabs={OPTIONS}
        variant={"fullWidth"}
      />
      <ContentTabs>
        {currentTab === 0 && padlet && (
          <IframeWrapper>
            <Iframe src={padlet} frameBorder="0" allowFullScreen />
          </IframeWrapper>
        )}
      </ContentTabs>
    </Container>
  );
};

export default FooterResource;

const ContentTabs = styled.div`
  /* padding: 1rem; */
  min-height: 60px;
  background-color: #fff;
`;

const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

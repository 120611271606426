import React from "react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Close";
import { Button } from "@material-ui/core";
import { Modal } from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { Text } from "../../../components/common/Texts";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LinkToEvent from "./LinkToEvent";

const ModalICEvent = (props) => {
  const { event, handleCloseModal, openModal, hours, location, primary_color } =
    props;

  return (
    <Modal onClose={handleCloseModal} open={openModal}>
      <Container>
        <Header img_url={event?.cover_image}>
          <CloseModal color={primary_color} onClick={handleCloseModal}>
            <CancelIcon />
          </CloseModal>
        </Header>
        <Body>
          {event?.registration_url !== "" && event.type !== "Presencial" ? (
            <RowTitles>
              <Title>{event?.name}</Title>
              <LinkToEvent
                link={event?.registration_url}
                primary_color={primary_color}
              />
            </RowTitles>
          ) : (
            <Title>{event?.name}</Title>
          )}

          <Subtitle>{event?.type}</Subtitle>

          <Row color={primary_color}>
            <AccessTimeRoundedIcon />
            <Text
              fontSize="13px"
              fontWeight="700"
              color="#fffff"
              textTransform="capitalize"
            >
              Hora:{" "}
            </Text>
            <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
              {hours}
            </Text>
          </Row>

          {event.type !== "Virtual" && !!location && (
            <Row color={primary_color}>
              <LocationOnOutlinedIcon />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Ubicación:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
                {location}
              </Text>
            </Row>
          )}

          {event.program && (
            <Row color={primary_color}>
              <CollectionsBookmarkOutlinedIcon />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Programa:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
                {event.program
                  ? event.program
                  : "Programa no detallado en el evento"}
              </Text>
            </Row>
          )}

          {event.assigment && (
            <Row color={primary_color}>
              <BookmarkBorderOutlinedIcon />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Asignatura:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
                {event.assigment
                  ? event.assigment
                  : "Asignatura no detallada en el evento"}
              </Text>
            </Row>
          )}

          {event.module && (
            <Row color={primary_color}>
              <CheckCircleOutlinedIcon />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Módulo:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
                {event.module
                  ? event.module
                  : "Módulo no detallado en el evento"}
              </Text>
            </Row>
          )}

          {event.teacher && (
            <Row color={primary_color}>
              <PermIdentityOutlinedIcon />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Profesor:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#1D1D1B">
                {event.teacher
                  ? event.teacher
                  : "Profesor no detallado en el evento"}
              </Text>
            </Row>
          )}

          {event.description && (
            <RowDescription color={primary_color}>
              <Text
                // fontSize="13px" fontWeight="400" color="#1D1D1B"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </RowDescription>
          )}
        </Body>
      </Container>
    </Modal>
  );
};

export default ModalICEvent;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  overflow-x: hidden;
  min-height: max-content;
  height: max-content;
  max-height: 90vh;
  border: none;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5rem;
  border-radius: 20px;

  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: hidden;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const CloseModal = styled(Button)`
  position: absolute;
  width: 50px;
  top: 10px;
  right: -90% !important;
  svg {
    background-color: ${(p) => p.color ?? "#b31d15"};
    padding: 0.6rem;
    border-radius: 100%;
    color: #fff;
    font-size: 1.6rem;
  }
  @media (max-width: 600px) {
    right: -70% !important;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 220px;
  background-image: url(${(p) => p.img_url});
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  position: relative;
`;

const Body = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 0.5rem;
  overflow: scroll;
`;

const Title = styled.h3`
  width: 100%;
  color: #222222;
  font-size: 1.4rem;
  font-weight: bold;
`;

const Subtitle = styled.h4`
  width: 100%;
  text-transform: uppercase;
  color: #222222;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.5;
  width: 95%;
  svg {
    width: 1.25rem;
    color: ${(p) => p.color ?? "#b31d15"};
  }
`;

const RowDescription = styled.div`
  width: 95%;
  p {
    font-size: 1rem;
    line-height: 1.5;
    width: 80%;
  }
  svg {
    font-size: 0.5rem;
    width: 10px;
    border-radius: 100%;
    background-color:  ${(p) => p.color ?? "#b31d15"};
  }
`;

const RowTitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  padding-right: 2rem;
  button {
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

import React from "react";
import styled from "styled-components";

const OutlinedButtonWithIcon = (props) => {
  const { icon, color, text, action } = props;

  return (
    <LinkWrapper color={color} onClick={action}>
      {icon}
      <p>{text}</p>
    </LinkWrapper>
  );
};

export default OutlinedButtonWithIcon;

const LinkWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 160px;
  max-height:40px;
  gap: 0.75rem;
  border: ${(p) => p.color && `solid 1px ${p.color}`};
  border-radius: 100px;
  padding: 0.5rem 1rem;
  svg,
  p {
    color: ${(p) => p.color};
    font-weight: 700;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    min-width: min-content;
  }
  :hover {
    box-shadow: 0px 8px 8px -4px #18274b14, 0px 4px 4px 0px #00000040;
  }
`;

import React, { useContext } from "react";
import styled from "styled-components";

// Components
import { Img } from "../../../../components/common/Image";
import { Href, Text, Span } from "../../../../components/common/Texts";

// Material UI
import { Button } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

// Assets
import examen from "../../../../assets/media/svg/exam.png";

// Context
import CourseContext from "../../context/CourseContext"

const MeetingComponent = () => {

  const {
    currentMedia: {
        nombre_publicacion: title,
        meeting_vencido: defeatedMeet,
        fecha_meeting: dateMeet,
        link_meeting: linkMeeting,
        link_calendar: calendar
    }
} = useContext(CourseContext)

  const date = new Date(dateMeet).toLocaleDateString();
  const hour = new Date(dateMeet).toLocaleTimeString();

  return (
    <MeetingWrapper>
      <Img w="260px" src={examen} />
      <Text fontSize="1.2rem" fontWeight="700" color="#555555">
        {title}
      </Text>
      <div>
        <Text fontSize="1rem" fontWeight="600">
          <Span fontWeight="700" color="#555555">
            Fecha:
          </Span>{" "}
          {date}
        </Text>
        <Text fontSize="1rem" fontWeight="600">
          <Span fontWeight="700" color="#555555">
            Hora:
          </Span>{" "}
          {hour}
        </Text>
      </div>
      {!defeatedMeet ? (
        <Href
          fontSize="1rem"
          fontWeight="700"
          target="_blank"
          href={linkMeeting}
        >
          IR A LA MEETING
        </Href>
      ) : (
        <Text fontSize="1rem" fontWeight="700">
          ¡El meeting ha caducado!
        </Text>
      )}
      <ButtonFake
        disabled={defeatedMeet}
        onClick={() => window.open(calendar)}
        endIcon={<EventAvailableIcon />}
        variant="contained"
        color="primary"
      >
        Anadir al calendario
      </ButtonFake>
    </MeetingWrapper>
  );
};

export default MeetingComponent;

const MeetingWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 20px;
`;

const ButtonFake = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

import { SearchRounded } from "@mui/icons-material";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const HeaderFilters = (props) => {
    const { search, handleChange, handleChangeFilter } = props;

    const primaryColor = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    return (
        <Wrapper>
            <Search>
                <TextFieldFake
                    value={search}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    label=""
                    placeholder="Buscar..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                />
            </Search>

            <Filters>
                <OrderWrapper>
                    <p>Ordenar por</p>
                    <SelectFake
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={0}
                        primaryColor={primaryColor}
                        onChange={handleChangeFilter}
                    >
                        <MenuItem value={0}>Por defecto</MenuItem>
                        <MenuItem value={1}>Más antiguo</MenuItem>
                        <MenuItem value={2}>Más reciente</MenuItem>
                    </SelectFake>
                </OrderWrapper>
            </Filters>
        </Wrapper>
    );
};

export default HeaderFilters;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;

const Search = styled.div`
    width: 100%;
`;

const TextFieldFake = styled(TextField)`
    background: #f9f9f9;
    border-radius: 8px;

    fieldset {
        border: none;
    }
`;

const Filters = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;

const OrderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: max-content;
    p {
        color: #616161;
        font-weight: bold;
        font-size: 0.9rem;
    }
`;

const SelectFake = styled(Select)`
    color: ${(p) => p.primaryColor};
    font-weight: bold;
    font-size: 0.9rem;
    svg {
        color: ${(p) => p.primaryColor};
    }

    fieldset {
        border: none;
    }
`;

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";

const MSCardSkeleton = (props) => {
  return (
    <MsCardContainer>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={150}
      />
      <Body>
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" width="60%" height={15} />
      </Body>
    </MsCardContainer>
  );
};

export default MSCardSkeleton;

const MsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  width: 100%;
  border: 1px solid #f2f2f2;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
`;

import React, { useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import Text from "../../common/Text";
import CardHistory from "../../ui/molecules/dracma/CardHistory";
import { dracmaActions } from "../../../redux/actions";

const DracmaHistory = () => {
  // REDUX
  const dispatch = useDispatch();
  const movements = useSelector((state) => state.dracma.movements);

  // EFFECTS
  useEffect(() => {
    if (!movements) {
      dispatch(dracmaActions.getMovementsRequest());
    }
  }, []);

  // RETURN
  if (!movements) {
    return null;
  }
  return (
    <Container>
      <Text
        fontSize="1.25rem"
        fontWeight="700"
        color="secondary"
        component="h2"
      >
        Historial
      </Text>
      <ListWrapper>
        <Overflow>
          {movements.length === 0 ? (
            <NotFoundWrapper>
              <Text
                fontSize="1rem"
                fontWeight="700"
                color="primary"
                component="h2"
              >
                No hay movimientos registrados...
              </Text>
            </NotFoundWrapper>
          ) : (
            movements.map((item, index) => (
              <CardHistory activity={item} key={index} />
            ))
          )}
        </Overflow>
      </ListWrapper>
    </Container>
  );
};

export default DracmaHistory;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    width: calc(100% - 4rem);
  }
`;

const ListWrapper = styled.div`
  position: relative;
  height: 400px;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const Overflow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

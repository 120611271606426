import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { directoriesActions } from "../actions";
import {
  getDirectories,
  getDirectoriesAlab,
  getPaginate,
  getPaginateAlab,
} from "../api/directory";

function* paginationRequest(action) {
  try {
    const res = yield call(getPaginate, action.payload);
    yield put(directoriesActions.getPaginateSuccess(res.data.response_data));
  } catch (error) {
    yield put(directoriesActions.getPaginateFail(error));
  }
}
function* directoriesRequest(action) {
  try {
    const res = yield call(getDirectories, action.payload);
    yield put(directoriesActions.getDirectoriesSuccess(res.data.response_data));
  } catch (error) {
    yield put(directoriesActions.getDirectoriesFail(error));
  }
}

function* paginationAlabRequest(action) {
  try {
    const res = yield call(getPaginateAlab, action.payload);
    yield put(directoriesActions.getPaginateSuccess(res.data.response_data));
  } catch (error) {
    yield put(directoriesActions.getPaginateFail(error));
  }
}
function* directoriesAlabRequest(action) {
  try {
    const res = yield call(getDirectoriesAlab, action.payload);
    yield put(
      directoriesActions.getDirectoriesAlabSuccess(res.data.response_data)
    );
  } catch (error) {
    yield put(directoriesActions.getDirectoriesAlabFail(error));
  }
}

function* directoriesWatcher() {
  yield takeEvery(types.GET_DIRECTORIES_REQUEST, directoriesRequest);
  yield takeEvery(types.GET_DIRECTORIESALAB_REQUEST, directoriesAlabRequest);
  yield takeEvery(types.GET_PAGINATION_REQUEST, paginationRequest);
  yield takeEvery(types.GET_PAGINATIONALAB_REQUEST, paginationAlabRequest);
}

export { directoriesWatcher };

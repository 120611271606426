import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import EmptyGridResource from "../../../components/common/EmptyGridResource";
import CourseCardSkeleton from "../../../components/common/CourseCardSkeleton";
import BusinessCard from "../../../components/templates/business/components/BusinessCard";
import GridBusiness from "./GridBusiness";

// Hooks
import useTheme from "../../../hooks/useTheme";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ondemandActions } from "../../../redux/actions";

const CoursesBusiness = (props) => {
  const { thematic, handleOpenModal } = props;
  const { primary } = useTheme();
  // REDUX
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const ondemandAcademic = useSelector((state) => state.ondemand.academic);

  const CARDS_ON_SKELETON = window.innerWidth < 1750 ? (window.innerWidth < 1600 ? 3 : 4) : 5;

  const emptyItems = new Array(CARDS_ON_SKELETON).fill(null);

  // EFFECTS
  useEffect(() => {
    if (user.ondemand_id !== null) {
      if (ondemandAcademic.business === null) {
        dispatch(
          ondemandActions.getOndemandByCompetitorRequest({
            id: user.ondemand_id,
            email: user.personal_email,
          })
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ondemandAcademic]);

  return (
    <CoursesContainer>
      {ondemandAcademic.business === null ? (
        <EmptyGridResource propsValues={emptyItems} Card={CourseCardSkeleton} />
      ) : ondemandAcademic.business.length > 0 ? (
        <GridBusiness
          menuFilter={thematic}
          propsValues={ondemandAcademic.business}
          allCourses={ondemandAcademic.business}
          Card={BusinessCard}
          enableFilter={true}
          handleOpenModal={handleOpenModal}
        />
      ) : (
        <MessageEmpty color={primary}>
          No tienen cursos asignados por Aden
        </MessageEmpty>
      )}
      {/* <AlertDialog
        titulo={alertDialog.titulo}
        mensaje={alertDialog.mensaje}
        accion={alertDialog.accion}
        open={alertDialog.open}
        onClose={closeAlertDialog}
      ></AlertDialog> */}
    </CoursesContainer>
  );
};

export default CoursesBusiness;

const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const MessageEmpty = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.color};
  font-size: 1.2rem;
  font-weight: 600;
  width: calc(100% - 4rem);
  padding: 2rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 20px;
`;

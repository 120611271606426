import axios from "axios";

export function loginSisApi(payload) {
  return axios.post(`${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/login`, {
    user: payload.user,
    password: payload.password,
  });
}

export function getUserByToken(payload) {
  const config = {
    headers: {
      Authorization: `Bearer ${payload}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/profile`,
    { ...config }
  );
}

export async function getPublicInfoContact(partnerId) {
  const URL = `${process.env.REACT_APP_SISAPI}`;
  try {
    const res = await axios.get(
      URL + `/v1/acropolis/users/public-contact/${partnerId}`
    );
    return res.data.response_data;
  } catch (error) {
    return { error };
  }
}
export async function getPublicInfoForEmail(email) {
  const URL = `${process.env.REACT_APP_SISAPI}`;
  try {
    const res = await axios.get(
      URL + `/v1/acropolis/users/public-email/${email}`
    );
    return res.data.response_data;
  } catch (error) {
    return { error };
  }
}

export function updateUserByToken(payload) {
  return axios.put(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/profile`,
    payload
  );
}

export function updateUserPhoto(photo) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/avatar`,
    photo
  );
}

export function registerSisApi(user) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/register`,
    user
  );
}

export function registerOndemandSisApi(data) {
  // console.log("api: ", data);
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/ondemand/register/${data.ondemand_id}`,
    data
  );
}

export function registerStudent(user) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/change-password`,
    user
  );
}

export const loginRoles = (form) => {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v2/acropolis/auth/login`,
    form
  );
};

export const userData = (rol) => {
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v2/acropolis/auth/get_user_data/${
      rol || "alumno"
    }`
  );
};

import React from "react";
import styled from "styled-components";

// Material UI
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

const AssignmentAccordion = (props) => {
  const {
    SummaryComponent,
    DetailsComponent,
    data = [],
    handleClick = () => "",
    size,
    isCompletedBg,
    description,
    decorator,
  } = props;

  return (
    <FakeAccordion isCompletedBg={isCompletedBg}>
      <FakeAccordionSummary
        isCompletedBg={isCompletedBg}
        size={size}
        expandIcon={<FakeExpandMore visibility={description} />}
      >
        {!!SummaryComponent && (
          <SummaryComponent
            data={data}
            description={description}
            handleClick={handleClick}
            {...props}
          />
        )}
      </FakeAccordionSummary>
      {!!description && (
        <FakeAccordionDetails isCompletedBg={isCompletedBg}>
          {!!DetailsComponent && (
            <DetailsComponent
              handleClick={handleClick}
              description={description}
              {...props}
            />
          )}
        </FakeAccordionDetails>
      )}
      {decorator && <OrnamentLine />}
    </FakeAccordion>
  );
};

export default AssignmentAccordion;

const FakeAccordion = styled(Accordion)`
  position: relative;
  border: none;
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),
    0px 6px 12px -4px rgba(24, 39, 75, 0.2);
  outline: none;
  background-color: ${(p) => (p.isCompletedBg === 100 ? "#f1f1f1" : "#FFFFFF")};
  width: 100%;
  border-radius: 20px !important;
  z-index: 5;

  &.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-radius: 20px;
  }
  &.MuiAccordion-root:before {
    display: none;
  }
`;

const FakeAccordionSummary = styled(AccordionSummary)`
  position: relative;
  border: none;
  outline: none;
  background-color: ${(p) => (p.isCompletedBg === 100 ? "#f1f1f1" : "#FFFFFF")};
  height: ${(p) => (!!p.size ? "250px" : "120px")};
  border-radius: 20px;
  padding-right: 3rem;
`;

const FakeExpandMore = styled(ExpandMore)`
  width: 36px;
  height: 36px;
  color: #000000;
  visibility: ${(p) => (p.visibility ? "visible" : "hidden")};
`;

const FakeAccordionDetails = styled(AccordionDetails)`
  border: none;
  outline: none;
  background-color: ${(p) => (p.isCompletedBg === 100 ? "#f1f1f1" : "#FFFFFF")};
  height: auto;
  border-radius: 20px;
  padding: 1rem 0 0 2.3rem;
`;

const OrnamentLine = styled.span`
  min-width: 2px;
  position: absolute;
  min-height: calc(100% - 9.7rem);
  margin: auto;
  left: 2.95rem;
  top: 50%;
  visibility: visible;
  transform: translate(-50%, -50%);
  background-color: #b31d15;
`;

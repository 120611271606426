import React, { useState } from "react";
import styled from "styled-components";
import { Span, Text } from "../../../common/Texts";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Dialog, IconButton } from "@mui/material";
import TaskRender from "../../../templates/teacher/task/TaskRender";
import CloseIcon from "@mui/icons-material/Close";

const CardTask = (props) => {
  const { values } = props;

  const [open, setOpen] = useState(false);

  console.log("values", values);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CardWrapper>
      <Qubo
        state={!!values.redo ? "rehacer" : values.state}
        onClick={handleClickOpen}
      >
        <State state={!!values.redo ? "rehacer" : values.state}>
          {!!values.redo ? "rehacer" : values.state}
        </State>
        {!!values.file ? <FileCopyIcon /> : <FormatListBulletedIcon />}
      </Qubo>
      <div>
        <Text color="#aaa" fontSize="12px" textAlign="center">
          {values.oa.name}
        </Text>
        <Text fontWeight="bold" textAlign="center">
          {values.activity.name.split("-")[0] || values.activity.name}
        </Text>
        <Text color="#aaa" fontSize="12px" textAlign="center">
          {new Date(values.create_date).toLocaleDateString()}
        </Text>
      </div>
      <Dialog fullScreen onClose={handleClose} open={open}>
        <IconButtonFake onClick={handleClose}>
          <CloseIcon />
        </IconButtonFake>
        <InfoTask>
          <Info>
            <Text textWeight="bold" color="#591AB6" fontSize="18px">
              {values.oa.name}
            </Text>
            <Text color="#aaa" fontSize="12px">
              {values.assignment.name}
            </Text>
            <div>
              <Text>Recurso:</Text>
              <Text>{values.resource.name}</Text>
            </div>
            <Dates>
              <Text fontSize="12px">
                Entrega: <Span color="#591AB6">{values.create_date}</Span>
              </Text>
              <Text fontSize="12px">
                Corrección:{" "}
                <Span color="#591AB6">
                  {values.date_of_correction || "Pendiente"}
                </Span>
              </Text>
            </Dates>
            {values.date_of_correction && (
              <div>
                <Text color="#aaa" fontSize="14px">
                  Corrección
                </Text>
                <Grade>
                  {values.redo
                    ? "La tarea debe rehacerce"
                    : values.grade + "/100"}
                </Grade>
              </div>
            )}
          </Info>
          <TaskRender
            isText={!!values.response}
            isFile={!!values.file}
            name={values?.resource?.name}
            text={values.response}
            file={values.file || null}
          />
        </InfoTask>
      </Dialog>
    </CardWrapper>
  );
};

export default CardTask;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Qubo = styled.div`
  cursor: pointer;
  border-radius: 20px;
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ state }) =>
    state === "pendiente"
      ? "#f0e6ce"
      : state === "corregido"
      ? "#d0f5ea"
      : "#ffe4e3"};
  svg {
    color: ${({ state }) =>
      state === "pendiente"
        ? "#bfab7aaa"
        : state === "corregido"
        ? "#70cfb2aa"
        : "#e06965aa"};
    font-size: 42px;
  }
`;

const State = styled.div`
  position: absolute;
  background-color: ${({ state }) =>
    state === "pendiente"
      ? "#bfab7a"
      : state === "corregido"
      ? "#70cfb2"
      : "#e06965"};
  top: 5px;
  color: white;
  right: 10px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 15px;
`;

const InfoTask = styled.div`
  padding: 2.5vh 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 95vh;
  grid-template-areas: "info task";
  gap: 18px;
`;

const Info = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const IconButtonFake = styled(IconButton)`
  background-color: #591AB699;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5px;
  top: 5px;
  svg {
    color: #fff;
  }
  :hover {
    background-color: #591AB6;
  }
`;

const Dates = styled.div`
  width: 100%;
  display: flex;
  column-gap: 12px;
`;

const Grade = styled.div`
  width: 100%;
  height: 70px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  color: #591AB6;
  font-size: 20px;
  font-weight: bold;
  display: grid;
  place-content: center;
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { ShareOutlined } from "@mui/icons-material";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import { useHistory } from "react-router-dom";
import useTheme from "../../../../hooks/useTheme";
import moment from "moment";
import { useState } from "react";
import { postFav, postTakeOutFav } from "../../../../redux/api/utils";
import { useSelector, useDispatch } from "react-redux";
import { microlearningsActions } from "../../../../redux/actions";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";
import { Icon } from '@iconify/react';

const MicrolearningsCard = (props) => {
  // const { value, handleOpenModal } = props;
  const { value } = props;
  const { primary } = useTheme();

  const history = useHistory();
  const now = moment();
  const fecha_desde = moment(value?.fecha_desde) || "";
  const isNew = now.isoWeek() === fecha_desde.isoWeek();
  const [fav, setFav] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setFav(value.favorito);
  }, [value.favorito]);

  const handleFav = () => (!fav ? setFavorite() : setTakeOut());

  const setFavorite = async () => {
    const req = await postFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "recurso",
    });
    if (!req.error) {
      setFav(true);
      handleStateRedux(value.repo_id, "like");
    }
  };

  const setTakeOut = async () => {
    const req = await postTakeOutFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "recurso",
    });
    if (!req.error) {
      setFav(false);
      handleStateRedux(value.repo_id, "no-like");
    }
  };

  const handleStateRedux = (id, type) => {
    dispatch(
      type === "like"
        ? microlearningsActions.setLikes(id)
        : microlearningsActions.setNoLikes(id)
    );
  };

  const handleClick = () => {
    history.push(`./biblioteca/${value.repo_id}`);
  };

  return (
    <MicrolearningsCardContainer>
      <ImageWrapper view={value.viewed} new={isNew}>
        <Img
          width="100%"
          height="100%"
          borderRadius="20px 20px 20px 20px"
          src={value.image_url}
        />
        {value.viewed ? (
          <>
            <BadgeView>VISTO</BadgeView>
          </>
        ) : null}
        <WrapperRec onClick={handleClick}>
          <PlayIcon />
        </WrapperRec>
      </ImageWrapper>
      <Body>
        {value.tags.length > 0 && (
          <TagsGrid>
            {value.tags.slice(0, 3).map((tag, index) => (
              <Tag key={index}># {tag.toLowerCase()}</Tag>
            ))}
          </TagsGrid>
        )}
        <NombrePublicacion>{value.publication_name}</NombrePublicacion>

        <AuthorWrapper>
          <Author color={primary}>Por: <b>{value.acropolis_author}</b></Author>
        </AuthorWrapper>
      </Body>
      <Footer>
        <IconWrapper>
          <IconButtonFav
            isFav={fav}
            title="Marcar como favorito"
            onClick={handleFav}
          >
            <Icon icon="material-symbols:favorite" width="24" height="24" />
          </IconButtonFav>
          <IconButton
            /* onClick={() =>
              handleOpenModal(value.repo_id, value.publication_name)
            }  */
            size="large"
          >
            <Icon icon="material-symbols:share" width="24" height="24" />
          </IconButton>
        </IconWrapper>
        <Time>
          <Icon icon="mingcute:time-line" width="24" height="24" />
          {value.duration}
        </Time>
      </Footer>
    </MicrolearningsCardContainer>
  );
};

export default MicrolearningsCard;

const MicrolearningsCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    width: 320px;
    height: 325px;
    border-radius: 20px;
    background-color: #fff;
    transition: all .5s ease-in-out;

    img {
        scale: 1;
        transition: scale .5s ease-in-out;
    }
    :hover img {
        scale: 1.3;
        
    }
`;

const BadgeNew = styled.div`
  position: absolute;
  background-color: #591AB6;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: 900;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.06));
`;

const BadgeView = styled.div`
  position: absolute;
  background-color: #a8a8a8;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: 900;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.06));
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 136px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px 20px 20px 20px;

  :hover {
    div:nth-child(0n + 2) {
      opacity: ${(p) => (p.view ? "0" : p.new ? "0" : "1")};
    }
  }
`;

const Img = styled(LazyImg)`
    object-fit: cover;
    object-position: center;
`

const WrapperRec = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  border-radius: 20px 20px 0 0;
  transition: 0.4s all ease-in-out;
  svg {
    color: #fff;
    font-size: 3.5rem;
  }

  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1rem;
  padding: 1rem;
  height: 117px;
  width: 250px;
`;

const NombrePublicacion = styled.h1`
  font-size: 16px;
  font-weight: 700;
  text-align: start;
  color: var(--primary-three);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 996px) {
    font-size: 1.2rem;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Author = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-two);
  width: 100%;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  b {
    color: var(--primary-one);
    font-weight: 800;
  }  
`;

const Footer = styled.div`
    position: relative;
    height: calc(60px - 16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    padding: 16px;
    background-color: var(--secondary-one);
    border-radius: 0 0 20px 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Tag = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-four);
  padding: 4px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 100px;
`;

const IconButtonFav = styled(IconButton)`
  width: 48px;
  height: 48px;
  svg {
    color: ${(p) => !p.isFav ? "var(--primary-two)" : "var(--primary-one)"};
    transform: scale(1.2);
  }
`;

const TagsGrid = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  gap: 0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
import React from "react";
import styled from "styled-components";
import HeaderFilters from "../../components/templates/my-certificates/HeaderFilters";
import CardCertificate from "./CardCertificate";
import useMyCertificate from "../../hooks/useMyCertificate";
import SimpleLoading from "../../components/common/SimpleLoading";

const MyCertificates = () => {
    const { certificates, search, handleChange, handleChangeFilter } =
        useMyCertificate();

    return (
        <>
            <Wrapper>
                <HeaderFilters
                    handleChangeFilter={handleChangeFilter}
                    search={search}
                    handleChange={handleChange}
                />
                <WrapperCards>
                    {certificates === null ? (
                        <SimpleLoading />
                    ) : certificates?.length > 0 ? (
                        <>
                            {certificates.map((certificate, i) => (
                                <CardCertificate
                                    key={i}
                                    certificate={certificate}
                                />
                            ))}
                        </>
                    ) : (
                        "no se encontraron cursos"
                    )}
                </WrapperCards>
            </Wrapper>
        </>
    );
};

export default MyCertificates;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const WrapperCards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(321.75px, 1fr));
    grid-gap: 2rem;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Material UI
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const QuestionReview = (props) => {
  const { type, question, resQuestion, number } = props;

  // States
  const [value, setValue] = useState(null);

  // Effects
  useEffect(() => {
    if (resQuestion && value === null && type !== "multi_opcion") {
      setValue(resQuestion.respuestas_usuario[0]);
    }
    if (resQuestion && value === null && type === "multi_opcion") {
      setValue(resQuestion.respuestas_usuario);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Functions
  const getCheckedStudent = (id) => {
    let result = false;
    resQuestion.respuestas_usuario.forEach((question_id) => {
      if (question_id === id) {
        result = true;
      }
    });
    return result;
  };

  const getScoreStudent = (question) => {
    let result = false;
    resQuestion.respuestas_usuario.forEach((response) => {
      question.opciones.forEach((questionRes) => {
        if (
          questionRes.opcion_id === response &&
          questionRes.es_opcion_correcta === true
        ) {
          result = true;
        }
      });
    });
    return result;
  };

  // Functions material hover

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueContent, setValueContent] = useState("");

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setValueContent(event.currentTarget.textContent);
    //console.log('chau', valueContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  const open = Boolean(anchorEl);

  return (
    <Container>
      <Header>
        <Title>
          <BookmarkBorderIcon /> Pregunta {number}
        </Title>
        <Value>
          {getScoreStudent(question) === true ? question.puntaje_pregunta : 0} /{" "}
          {question.puntaje_pregunta}
          {question.puntaje_pregunta > 1 ? " pts" : " pt"}
        </Value>
      </Header>
      <Body>
        <Question>{parse(sanitizerHtml(question.pregunta))}</Question>
        <Answers>
          {type === "unica_opcion" && (
            <RadioGroup aria-label="gender" name="gender1" value={value}>
              {question.opciones.map((opt) => {
                return (
                  <div>
                    <TypographyFake
                      isCorrect={!!opt.es_opcion_correcta}
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                    >
                      <Response>
                        <FakeLabelRadio
                          onClick={handlePopoverOpen}
                          disabled
                          value={opt.opcion_id}
                          control={<FakeRadio color={"primary"} />}
                          htmlFor={opt.opcion}
                          label={opt.opcion}
                        />
                      </Response>
                      <Result>
                        {!!opt.es_opcion_correcta ? (
                          <CheckIconFake>
                            <CheckIcon />
                            Correcto
                          </CheckIconFake>
                        ) : (
                          <ClearIconFake>
                            <ClearIcon />
                            Incorrecto
                          </ClearIconFake>
                        )}
                      </Result>
                    </TypographyFake>
                  </div>
                );
              })}
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <TypographyHover sx={{ p: 1 }}>{valueContent}</TypographyHover>
              </Popover>
            </RadioGroup>
          )}
          {type === "verdadero_falso" && (
            <RadioGroup aria-label="gender" name="gender1" value={value}>
              {question.opciones.map((opt) => {
                return (
                  <>
                    <div>
                      <TypographyFake
                        aria-owns={open ? "mouse-over-popover" : undefined}
                        aria-haspopup="false"
                        onClick={handlePopoverOpen}
                      >
                        <Response>
                          <FakeLabelRadio
                            disabled
                            value={opt.opcion_id}
                            control={<FakeRadio color={"primary"} />}
                            label={opt.opcion}
                          />
                          {/* <Result>
                                                        {opt.es_opcion_correcta ===
                                                            true ? (
                                                            <CheckIconFake />
                                                        ) : (
                                                            <ClearIconFake />
                                                        )}
                                                    </Result> */}
                        </Response>
                      </TypographyFake>
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <TypographyHover
                          colors={opt.es_opcion_correcta}
                          sx={{ p: 1 }}
                        >
                          {opt.opcion}
                        </TypographyHover>
                      </Popover>
                    </div>
                  </>
                );
              })}
            </RadioGroup>
          )}
          {type === "multi_opcion" && (
            <CheckContainer>
              {question.opciones.map((opt) => {
                return (
                  <div>
                    <TypographyFake
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="false"
                      onClick={handlePopoverOpen}
                    >
                      <Response>
                        <FakeLabelCheck
                          checked={getCheckedStudent(opt.opcion_id)}
                          disabled
                          control={
                            <FakeCheckbox
                              name={opt.opcion_id}
                              value={opt.opcion_id}
                              color={"primary"}
                            />
                          }
                          label={opt.opcion}
                        />
                        {/* <Result>
                                                    {opt.es_opcion_correcta ===
                                                        true ? (
                                                        <CheckIconFake />
                                                    ) : (
                                                        <ClearIconFake />
                                                    )}
                                                </Result> */}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <TypographyHover
                            colors={opt.es_opcion_correcta}
                            sx={{ p: 1 }}
                          >
                            {opt.opcion}
                          </TypographyHover>
                        </Popover>
                      </Response>
                    </TypographyFake>
                  </div>
                );
              })}
            </CheckContainer>
          )}
        </Answers>
      </Body>
    </Container>
  );
};

export default QuestionReview;

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  margin: 1rem 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  padding: 0 15px;
  height: 50px;
  background-color: #f1f1f1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Title = styled.div`
  font-size: 1.2rem;
  color: #6b6b6b;
  font-weight: 700;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Value = styled.div`
  padding: 0 0.5rem;
  font-size: 1.2rem;
  color: #6b6b6b;
  font-weight: 700;
`;

const TypographyFake = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 0 10px;
  background-color: ${(p) => (p.isCorrect ? "#F7FDFB" : "#FFF5F7")};
  border: 1px solid ${(p) => (p.isCorrect ? "#35D0A5" : "#FC2958")};
`;

const TypographyHover = styled(Typography)`
  background-color: #222222;
  color: #fff;
  padding: 0.5rem;
  font-size: 1rem;
`;
const Question = styled.h4`
  /* color: black;
    font-size: 0.9rem; */
  margin-bottom: 15px;
`;

const Answers = styled.div`
  width: 100%;
`;

const Body = styled.div`
  margin: 0 0 1rem 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 2rem;
  padding-bottom: 0;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
`;

const FakeRadio = styled(Radio)`
  transform: scale(0.7);
`;

const FakeLabelRadio = styled(FormControlLabel)`
  span {
    font-size: 0.8rem;
    cursor: pointer;
  }
`;

const FakeLabelCheck = styled(FormControlLabel)`
  span {
    font-size: 0.8rem;
    cursor: pointer;
  }
`;

const FakeCheckbox = styled(Checkbox)`
  transform: scale(0.7);
`;

const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Response = styled.div`
  width: 100%;
  display: flex;
`;

const Result = styled.div``;

const CheckIconFake = styled.div`
  background-color: #35d0a5;
  border-radius: 5px;
  width: 85px;
  height: 28px;
  color: white;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  svg {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const ClearIconFake = styled.div`
  background-color: #fc2958;
  border-radius: 5px;
  width: 93px;
  height: 28px;
  color: white;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  svg {
    font-size: 1rem;
    font-weight: 700;
  }
`;

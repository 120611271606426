import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

const CardChallenges = (props) => {
    const { values } = props;

    const history = useHistory();

    const primaryColor = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

     const url = encode.encode(values?.url_desafio + "", "base64");

    //FUNCTIONS
    const handleClick = () => {
        if (!values?.abrir_ventana_nueva) {
            history.push(`../desafio/${url}`);
        } else {
            window.open(values?.url_desafio);
        }
    };

    return (
        <Card>
            <Chip primaryColor={primaryColor}>{values?.categoria}</Chip>
            <Header>
                <img src={values?.foto} alt="imagen desafío" />
            </Header>
            <Body>
                <Info>
                    <Title>{values?.name}</Title>
                    <Description>{values?.descripcion}</Description>
                </Info>
                <Button onClick={handleClick} primaryColor={primaryColor}>
                    Ver desafío
                </Button>
            </Body>
        </Card>
    );
};

export default CardChallenges;

const Card = styled.div`
    position: relative;
    width: 100%;
    max-width: 330px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 8px 8px -4px rgba(24, 39, 75, 0.08),
        0px 4px 6px -4px rgba(24, 39, 75, 0.12);
`;

const Chip = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: fit-content;
    background-color: #1e1e1e;
    color: #fff;
    font-size: 0.78rem;
    padding: 0.4rem;
    border-radius: 5px;
    z-index: 2;
    transition: all 0.3s ease-in-out;

    :hover {
        background-color: ${(p) => p.primaryColor};
    }
`;

const Header = styled.div`
    width: 100%;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const Body = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
`;

const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Title = styled.h2`
    width: 97.5%;
    font-size: 1rem;
    color: #222222;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    min-height: 2.5rem;
`;

const Description = styled.p`
    width: 97.5%;
    min-height: 2.5rem;
    font-size: 0.88rem;
    color: #616161;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
`;

const Button = styled.button`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    font-size: 0.88rem;
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
    background-color: ${(p) => p.primaryColor};
    cursor: pointer;
    border-radius: 48px;
`;

import * as types from "../types/my_course_routes";
import { call, put, takeEvery } from "redux-saga/effects";
import { coursesTabsActions } from "../actions";
import { getEnabledCourses } from "../api/courses.tabs";

function* coursesTabsRequest(action) {
  try {
    const response = yield call(getEnabledCourses, action.payload);
    yield put(coursesTabsActions.getCoursesTabsSuccess(response.data.response_data));
  } catch (error) {
    yield put(coursesTabsActions.getCoursesTabsFail(error));
  }
}

function* coursesTabsWatcher() {
  yield takeEvery(types.GET_MY_COURSE_ROUTES_REQUEST, coursesTabsRequest);
}

export { coursesTabsWatcher };

import { useState, useEffect } from "react";
import { getCategoriesThemes, getClassificationThematicAxes } from "../redux/api/courses";
import { useHistory } from "react-router-dom";

const useCategoryThemes = () => {
  const history = useHistory();

  const [categoryThemes, setCategoryThemes] = useState(null);
  const [thematicAxes, setThematicAxes] = useState(null)

  useEffect(() => {
    if (!categoryThemes) {
      getCategoryThemes();
    }
    if (!thematicAxes) {
      getThematicAxes();
    }
  }, [categoryThemes]);

  const getCategoryThemes = async () => {
    const req = await getCategoriesThemes();
    if (req.error) {
      return console.log("req.error", req.error);
    }
    setCategoryThemes(req);
  };

  const getThematicAxes = async () => {
    const req = await getClassificationThematicAxes();
    if (req.error) {
      return console.log("req.error", req.error);
    }
    setThematicAxes(req)
  }

  const handleThemeRedirection = (categoryTheme) => {
    if (categoryTheme?.objetos_aprendizaje?.length > 0) {
      history.push(
        `/mi-empresa/2?categoria=${categoryTheme?.name
          ?.replaceAll(/\s/g, "-")
          ?.toLowerCase()}`
      );
    }
  };

  return { categoryThemes, thematicAxes, handleThemeRedirection };
};

export default useCategoryThemes;

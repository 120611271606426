import { useEffect } from "react";
import styled from "styled-components";
import React from "react";

const GridCard = (props) => {
  const { cards = [], component: Card, width = "300px", propsSkl } = props;

  return (
    <GridCardWrapper width={width}>
      {cards.map((card, i) => (
        <Card
          key={i}
          values={card}
          propsSkl={propsSkl}
        />
      ))}
    </GridCardWrapper>
  );
};

export default GridCard;

const GridCardWrapper = styled.div`
  padding: 1rem 2.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${(p) => p.width}, 1fr));
  gap: 24px;
  justify-items: center;
`;

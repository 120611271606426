import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Text } from "../../../common/Texts";
import { useSelector } from "react-redux";

const Certification = (props) => {
  const { id, avaliable, type } = props;

  const { user } = useSelector((state) => state.auth);

  const handleOpen = () => {
    if (!!avaliable) {
      window.open(
        `https://constancias.aden.org/?idcod=${type}-${id}-${user.repo_id}-ADEN2022/`,
        "_blank"
      );
    }
  };

  return (
    <ContainerWrapper onClick={handleOpen} avaliable={avaliable}>
      <IconWrapper avaliable={avaliable}>
        <EmojiEventsIcon />
      </IconWrapper>
      <DataWrapper>
        <span>
          <Text fontWeight="bold" color={!avaliable ? "#aaa" : "#222"}>
            Constancia de finalización
          </Text>
          <Text
            fontSize="12px"
            fontWeight="bold"
            color={!avaliable ? "#aaa" : "#999"}
          >
            {avaliable ? "Haz click para ver" : "Bloqueado"}
          </Text>
        </span>
      </DataWrapper>
    </ContainerWrapper>
  );
};

export default Certification;

Certification.propTypes = {
  avaliable: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["OA", "PROGRAM"].isRequired),
};

const ContainerWrapper = styled.div`
  height: 80px;
  border-radius: 20px;
  width: 280px;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  cursor: ${(p) => (p.avaliable ? "pointer" : "block")};
  display: grid;
  grid-template-columns: 1fr 2.5fr;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 32px;
    color: ${(p) => (p.avaliable ? "#c7c17b" : "#aaa")};
  }
`;

const DataWrapper = styled.div`
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  width: 100%;
  height: 100%;
  span {
    width: 100%;
  }
`;

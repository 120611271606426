import * as types from "../types";

const actions = {};

actions.getMotivationsRequest = () => {
  return {
    type: types.GET_MOTIVATIONS_REQUEST,
  };
};

actions.getMotivationsSuccess = (motivations) => {
  return {
    type: types.GET_MOTIVATIONS_SUCCESS,
    payload: motivations,
  };
};

actions.getMotivationsFail = (error) => {
  return {
    type: types.GET_MOTIVATIONS_FAIL,
    payload: error,
  };
};

export { actions };

import React, { useState } from "react";
import styled from "styled-components";
import { checkRoles } from "../../../helpers/roles.helper";

import TabsComponent from "../../common/TabsComponent";
import InterestsMotivationsPublic from "../../ui/organisms/profile/InterestsMotivationsPublic";
import TrainingExperience from "../../ui/organisms/profile/TrainingExperience";

const tabs = [
  { label: "Intereses y motivaciones", id: 0 },
  { label: "Formación y Experiencia", id: 3 },
];

const tabsVisita = [
  { label: "Intereses y motivaciones", id: 0 },
  { label: "Formación y Experiencia", id: 3 },
];

const MainProfilePublic = (props) => {
  const { motivations, interests, user } = props;
  // STATE
  const [tab, setTab] = useState(0);

  // FUNCTIONS
  function handleTab(e, value) {
    setTab(value);
  }

  // RETURN
  return (
    <MainProfilePublicContainer>
      {!checkRoles("visita") ? (
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      ) : (
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabsVisita} />
      )}
      <BodyWrapper>
        <ToggleMain
          tab={tab}
          interests={interests}
          motivations={motivations}
          user={user}
        />
      </BodyWrapper>
    </MainProfilePublicContainer>
  );
};

export default MainProfilePublic;

const MainProfilePublicContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const BodyWrapper = styled.div`
  padding: 2rem;
`;

function ToggleMain(props) {
  const { tab, interests, motivations, user } = props;
  switch (tab) {
    case 0:
      return (
        <InterestsMotivationsPublic
          interests={interests}
          motivations={motivations}
        />
      );
    case 3:
      return <TrainingExperience user={user} />;
    default:
      return null;
  }
}

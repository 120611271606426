import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import ScrollToTop from "./utils/ScrollToTop";
import { LayoutSplashScreen } from "./components/common/SplashScreen";

import { StyledEngineProvider } from "@mui/material";
import { GlobalStyles } from "./providers/GlobalStyles";
import Routes from "./Routes";
import SnackbarProvider from "react-simple-snackbar";
import MaterialThemeProvider from "./providers/MaterialThemeProvider";
import ThemeProvider from "./modules/acropolisCommon/provider/ThemeProvider";

export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <SnackbarProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              <ScrollToTop />
              {/* Tema por In Company */}
              <StyledEngineProvider injectFirst>
                <ThemeProvider>
                  {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                  <MaterialThemeProvider>
                    {/* Render routes with provided `Layout`. */}
                    <GlobalStyles />
                    <Routes />
                  </MaterialThemeProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}

import store from "../redux/store";

export function checkRoles(roles) {
  let rolesUser = store.getState().auth.user;
  if (rolesUser) {
    rolesUser = store.getState().auth.user.roles
      ? store.getState().auth.user.roles
      : ["visita"];
  } else {
    rolesUser = ["visita"];
  }
  if (Array.isArray(roles) === true) {
    for (let i = 0; i < roles.length; i++) {
      if (rolesUser.includes(roles[i]) === true) {
        return rolesUser.includes(roles[i]);
      }
    }
  } else {
    return rolesUser.includes(roles);
  }
}

export const checkRolesLogin = (roles) => {
  const rolesFiltered = roles.filter((rol) => rol !== "visita");
  if (rolesFiltered.length === 0 || rolesFiltered.length === 1) {
    return false;
  }
  return rolesFiltered;
};

export const getRolActive = (rol) => {
  const rolState = store.getState().auth.activeRol;
  return rol === rolState ? true : false;
};

export const getHomeByRol = (rol) => {
  const cases = {
    ondemand: "../campus",
    docente: "../profesor/dashboard",
    alumno: "../campus",
    academico: "../academico",
    default: "../not-found",
  };

  return cases[rol] || cases["default"];
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { Button, CircularProgress } from "@mui/material";
import DefaultComplete from "../../../common/DefaultComplete";
import DefaultField from "../../../common/DefaultField";
import { ticketsActions } from "../../../../redux/actions";
import { ticketSchema } from "../../../../utils/schemas";
import TicketSuccess from "../../organisms/tickets/TicketSuccess";
import { createTicket } from "../../../../redux/api/tickets.api";
import DefaultSnackbar from "../../../common/DefaultSnackbar";

const TicketForm = () => {
  //REDUX
  const dispatch = useDispatch();
  const options = useSelector((state) => state.tickets.options);

  // STATE
  const initialValues = {
    area: { crm_id: "", name: "" },
    case: { categories: null, crm_id: 0, name: "" },
    category: { crm_id: 0, name: "" },
    issue: "",
    description: "",
  };
  const [nroTicket, setNroTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // EFFECTS
  useEffect(() => {
    if (!options) {
      dispatch(ticketsActions.getOptionsRequest());
    }
  }, []);

  // FUNCTIONS
  function validate(value) {
    let error;
    if (!value) {
      error = "Campo requerido";
    } else if (value.name === "") {
      error = "Campo requerido";
    }
    return error;
  }

  async function handleSubmit(values) {
    setLoading(true);
    const res = await createTicket({
      area: values.area,
      case: { crm_id: values.case.crm_id, name: values.case.name },
      category: values.category,
      issue: values.issue,
      description: values.description,
    });
    if (res.status) {
      setNroTicket(res.data);
    } else {
      setError(res.data);
    }
    setLoading(false);
  }

  // RETURN
  if (!options) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ticketSchema}
    >
      {({ values, setFieldValue, handleReset }) => (
        <Container>
          <RowWrapper>
            {/** Area responsable */}
            <DefaultComplete
              name="area"
              label="Área responsable"
              value={values.area}
              setFieldValue={setFieldValue}
              options={options.responsable_areas}
              validate={validate}
              fullWidth
            />
            {/** Tipo de caso */}
            <DefaultComplete
              name="case"
              label="Tipo de caso"
              value={values.case}
              setFieldValue={setFieldValue}
              options={options.kind_of_cases}
              validate={validate}
              fullWidth
            />
            {/** Categoria */}
            {values.case.categories && (
              <DefaultComplete
                name="category"
                label="Categoría"
                value={values.category}
                setFieldValue={setFieldValue}
                options={values.case.categories}
                validate={validate}
                fullWidth
              />
            )}
          </RowWrapper>
          {/** Asunto */}
          <DefaultField name="issue" type="text" label="Asunto" required />
          {/** Descripcion */}
          <DefaultField
            name="description"
            type="text"
            label="Descripción"
            multiline
            rows={5}
            required
          />
          {/** Botones */}
          <ButtonsWrapper>
            {loading ? (
              <CircularProgress color="primary" size={32} />
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleReset}
                >
                  Resetear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Generar ticket
                </Button>
              </>
            )}
          </ButtonsWrapper>
          {/** Modal exito */}
          <TicketSuccess nroTicket={nroTicket} handleReset={handleReset} />
          {/** Snackbar error */}
          <DefaultSnackbar error={error} />
        </Container>
      )}
    </Formik>
  );
};

export default TicketForm;

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem 4rem;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 1rem;
  }
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem 2rem;
  padding: 0 30%;

  @media (max-width: 1024px) {
    padding: 0 20%;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

import React from "react";
import styled from "styled-components";

import Text from "../../../common/Text";
import CardUserPosition from "../../molecules/dracma/CardUserPosition";

const UserPosition = () => {
  // RETURN
  return (
    <Container>
      <Text fontSize="1rem" fontWeight="700">
        Tu posición
      </Text>
      <CardUserPosition />
      {/*<Text fontSize="1rem" fontWeight="700">
				Acciones rápidas para ganar más
    </Text>*/}
    </Container>
  );
};

export default UserPosition;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

import React from "react";
import styled from "styled-components";
import Text from "../../components/common/Text";
import TicketsMain from "../../components/templates/tickets/TicketsMain";
import { titleHTML } from "../../helpers/title.helper";

const Tickets = () => {
  titleHTML("Peticiones y Reclamos");

  return (
    <Container>
      <Text fontSize="2rem" fontWeight="700" color="primary" component="h1">
        Peticiones y Reclamos
      </Text>
      <TicketsMain />
    </Container>
  );
};

export default Tickets;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

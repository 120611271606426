import React from "react";
import styled from "styled-components";
import { Img } from "../../common/Image";
import AuthComponent from "../../ui/organisms/auth/AuthComponent";
import ActivateAccount from "./ActivateAccount";
import LogoAlab from '../../../assets/media/aden/acropolis-lab-rojo_logo.png'
import BackgroundLogin from "../../../assets/media/aden/background_login.png"

const AuthFormLab = (props) => {
    const { primaryColor, secondaryColor, logoAlab, /* logoAlabColor */ } = props;

    const allowedOrigins = [
        "porvenir",
        "coopecaja",
        "cr.acropolislab",
        "alabgt",
        "localhost",
        "dm.acropolislab",
        "pmrd"
    ];

    const [subdomain] = window.location.host.split(".") || [false];

    return (
        <AuthFormWrapper 
        color={secondaryColor}
        backgroundImage={BackgroundLogin}
        className="background"
        >
            {
                logoAlab ? <ImgFake src={logoAlab} /> : <ImgFake src={LogoAlab} />
            }
            <AuthComponent isLab={true} primaryColor={primaryColor} />
            {allowedOrigins.some((origin) =>
                window.origin.includes(origin)
            ) && <ActivateAccount primaryColor={primaryColor} />}
            {
                logoAlab && subdomain !== "analdexsena" ? <ImgFakeDown src={LogoAlab} /> : <BlankImgFakeDown />
            }
        </AuthFormWrapper>
    );
};

export default AuthFormLab;

const AuthFormWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    background-image: url(${(p) => p.backgroundImage});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
    @media (max-width: 768px) {
        width: calc(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
`;

const ImgFake = styled(Img)`
    height: 150px;
    z-index: 10;
    @media (max-width: 768px) {
        height: 100px;
    }
`;

const ImgFakeDown = styled(Img)`
    height: 80px;
    z-index: 10;
    @media (max-width: 768px) {
        width: 200px;
    }
`;

const BlankImgFakeDown = styled.div`
    height: 80px;
    z-index: 10;
    @media (max-width: 768px) {
        width: 200px;
    }
`;

import React from "react";
import EventIcon from "@mui/icons-material/Event";
import DoneIcon from "@mui/icons-material/Done";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import { Card } from "@mui/material";

const CardReferred = (props) => {
  const { values } = props;

  const referred = values;

  return (
    <CardFake>
      <Chip type={referred?.stage || "Abiertas"}>
        {referred?.stage === "Ganadas" ? (
          <DoneIcon />
        ) : referred?.stage === "Perdidas" ? (
          <ClearIcon />
        ) : (
          <AutorenewIcon />
        )}
        {referred?.stage === "Ganadas"
          ? "Confirmado"
          : referred?.stage === "Perdidas"
          ? "Rechazado"
          : "Referido"}
      </Chip>
      <Info>
        <h3>{referred?.name || "Sebastian Maidana"}</h3>
        <span>{referred?.email || "sebamaidana@gmail.com"}</span>
      </Info>
      <InfoDate>
        <EventIconFake />
        <WrapperDate>
          <span>Fecha referido</span>
          <p>{new Date(referred?.create_date).toLocaleDateString() || "-"}</p>
        </WrapperDate>
      </InfoDate>
    </CardFake>
  );
};

export default CardReferred;

const CardFake = styled(Card)`
  width: calc(100% - 3rem);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 20px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;
const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: fit-content;
  padding: 0.3rem;
  border-radius: 5px;
  color: #fff;
  font-size: 0.85rem;
  /* font-weight: bold; */
  background-color: ${(p) =>
    p.type === "perdida"
      ? "#FC2958"
      : p.type === "Ganadas"
      ? "#35D0A5"
      : "#C29F43"};

  svg {
    font-size: 1.2rem;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;

  h3 {
    color: #000;
    font-size: 1.3rem;
    text-transform: capitalize;
  }

  span {
    color: #616161;
    font-size: 1rem;
  }
`;

const InfoDate = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
`;

const EventIconFake = styled(EventIcon)`
  color: #a8a8a8;
  font-size: 1.8rem;
`;

const WrapperDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  span {
    color: #a8a8a8;
    font-size: 0.7rem;
  }
  p {
    color: #222222;
    font-size: 1rem;
    font-weight: bold;
  }
`;

import React from "react";
import styled from "styled-components";
import useMyTasks from "../../../hooks/students/useMyTasks";
import DefaultCard from "../../common/DefaultCard";
import GridTasks from "../../ui/organisms/progress/GridTasks";
import OAList from "../../ui/organisms/progress/OAList";

const MyTasks = () => {
  const { assignment, submissionsByOa, assignmentSelected, handleSelectA } =
    useMyTasks();

  return (
    <TasksWrapper>
      <DefaultCard>
        <OAList
          assignment={assignment}
          assignmentSelected={assignmentSelected}
          handleClick={handleSelectA}
        />
      </DefaultCard>
      <DefaultCard>
        <GridTasks oa={assignmentSelected} tasks={submissionsByOa} />
      </DefaultCard>
    </TasksWrapper>
  );
};

export default MyTasks;

const TasksWrapper = styled.div`
  display: grid;
  /* width: ; */
  grid-template-columns: 1fr 3fr;
  gap: 16px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import styled from "styled-components";

// Components
import DefaultField from "../../../../../components/common/DefaultField";
import DefaultSnackbar from "../../../../../components/common/DefaultSnackbar";
import DefaultComplete from "../../../../../components/common/DefaultComplete";
import TicketSuccess from "../../../../../components/ui/organisms/tickets/TicketSuccess";

// Material UI
import { Button, CircularProgress } from "@mui/material";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ticketsActions } from "../../../../../redux/actions";
import { createTicket } from "../../../../../redux/api/tickets.api";

// Utils
import { ticketSchema } from "../../../../../utils/schemas";

const FormTicketSubject = (props) => {
  const { handleClose } = props;
  //REDUX
  const dispatch = useDispatch();
  const options = useSelector((state) => state.tickets.options);

  // STATE
  const initialValues = {
    area: { crm_id: "", name: "" },
    case: { categories: null, crm_id: 0, name: "" },
    category: { crm_id: 0, name: "" },
    issue: "",
    description: "",
  };
  const [loading, setLoading] = useState(false);
  const [nroTicket, setNroTicket] = useState(null);
  const [error, setError] = useState(null);

  // EFFECTS
  useEffect(() => {
    if (!options) {
      dispatch(ticketsActions.getOptionsRequest());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS
  function validate(value) {
    let error;
    if (!value) {
      error = "Campo requerido";
    } else if (value.name === "") {
      error = "Campo requerido";
    }
    return error;
  }

  async function handleSubmit(values) {
    setLoading(true);
    const res = await createTicket({
      area: values.area,
      case: { crm_id: values.case.crm_id, name: values.case.name },
      category: values.category,
      issue: values.issue,
      description: values.description,
    });
    if (res.status) {
      setNroTicket(res.data);
      handleClose();
    } else {
      setError(res.data);
    }
    setLoading(false);
  }

  // RETURN
  if (!options) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ticketSchema}
    >
      {({ values, setFieldValue, handleReset }) => (
        <Form>
          <Container>
            <WrapperField>
              <Title>¡Selecciona el tipo de problema!</Title>
              {/** Tipo de caso */}
              <DefaultComplete
                name="case"
                label="Tipo de problema"
                value={values.case}
                setFieldValue={setFieldValue}
                options={options.kind_of_cases}
                validate={validate}
                fullWidth
              />
            </WrapperField>
            <WrapperField>
              <Title>Área responsable</Title>
              {/** Area responsable */}
              <DefaultComplete
                name="area"
                label="Área responsable"
                value={values.area}
                setFieldValue={setFieldValue}
                options={options.responsable_areas}
                validate={validate}
                fullWidth
              />
            </WrapperField>
            {/** Categoria */}
            {values.case.categories && (
              <WrapperField>
                <Title>Selecciona una categoría</Title>
                <DefaultComplete
                  name="category"
                  label="Categoría"
                  value={values.category}
                  setFieldValue={setFieldValue}
                  options={values.case.categories}
                  validate={validate}
                  fullWidth
                />
              </WrapperField>
            )}
            <WrapperField>
              <Title>Asunto</Title>
              {/** Asunto */}
              <DefaultField name="issue" type="text" label="Asunto" required />
            </WrapperField>
            <WrapperField>
              <Title>¡Cuéntanos el detalle de lo ocurrido!</Title>
              <SubTitle>
                Ej: En la clase de introducción el video se queda cargando y no
                lo puedo ver
              </SubTitle>
              {/** Descripcion */}
              <DefaultField
                name="description"
                type="text"
                label="Descripción"
                multiline
                rows={3}
                required
              />
            </WrapperField>

            {/** Botones */}
            {/* <ButtonsWrapper>
                        {loading ? (
                            <CircularProgress color="primary" size={32} />
                        ) : (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleReset}
                                >
                                    Resetear
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                >
                                    Generar ticket
                                </Button>
                            </>
                        )}
                    </ButtonsWrapper> */}
            {/** Modal exito */}
            <TicketSuccess nroTicket={nroTicket} handleReset={handleReset} />
            {/** Snackbar error */}
            <DefaultSnackbar error={error} />
          </Container>

          <Footer>
            {loading ? (
              <CircularProgress color="primary" size={32} />
            ) : (
              <>
                <Button
                  onClick={() => handleClose()}
                  color="primary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Enviar
                </Button>
              </>
            )}
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

export default FormTicketSubject;

const Container = styled.div`
  width: calc(100% - 4rem);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  height: calc(100% - 205px);
`;

const WrapperField = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;
const Title = styled.h3`
  color: #616161;

  font-size: 1rem;
`;
const SubTitle = styled.span`
  color: rgba(34, 34, 34, 0.5);
`;
const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: #fff;
  height: calc(70px - 2rem);
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-right-radius: 20px;
  box-shadow: 0px -4px 6px -4px rgba(24, 39, 75, 0.12),
    0px -8px 8px -4px rgba(24, 39, 75, 0.08);
`;

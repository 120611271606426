import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    getArticles,
    getAllCompanyBlogs,
    getCompanyBlog,
} from "../../redux/api/blog/blogApi";

const useBlogArticle = (articleId) => {
    const { activeRol } = useSelector((state) => state.auth);
    const ondemand_id = useSelector((state) => state?.auth?.user?.ondemand_id);
    const { ondemand } = useSelector((state) => state.ondemand);
    const { enable_company_blog, enable_blog_aden } = ondemand;

    const company = activeRol === "ondemand" && ondemand_id;

    // CUSTOM-HOOKS
    const history = useHistory();

    // STATES
    const [valueArticles, setValueArticles] = useState(null);
    const [allArticles, setAllArticles] = useState(null);
    const [allCompanyBlogs, setAllCompanyBlogs] = useState(null);
    const [article, setArticle] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (valueArticles === null && enable_blog_aden) {
            handleArticles();
        }
        if (allCompanyBlogs === null && enable_company_blog) {
            getCompanyBlogs();
        }
        if (valueArticles !== null) {
            handleFormattedBlog(valueArticles);
        }

        if (allCompanyBlogs !== null) {
            handleFormattedBlog(valueArticles);
        }
        if (articleId && article === null) {
            getSingleCompanyBlog(articleId);
        }
    }, [valueArticles, allCompanyBlogs, articleId, article]);

    //FUNCTIONS
    const handleArticles = async () => {
        const response = await getArticles();
        if (!!response.error) {
            setValueArticles([]);
        } else {
            setValueArticles(response);
        }
    };

    const getCompanyBlogs = async () => {
        const response = await getAllCompanyBlogs(company);
        if (response.error) {
            setAllCompanyBlogs([]);
        } else {
            setAllCompanyBlogs(response.data);
        }
    };

    const getSingleCompanyBlog = async (id) => {
        const response = await getCompanyBlog(id);
        if (response.error) {
            setArticle([]);
            history.push("/not-found");
        } else {
            setArticle(response.data);
        }
    };

    const handleFormattedBlog = (blogs) => {
        if (blogs !== null) {
            const formattedBlogs = blogs.map((item) => ({
                id: null,
                attributes: {
                    ...item,
                },
            }));
            handleMergeBlog(allCompanyBlogs, formattedBlogs);
        } else {
            handleMergeBlog(allCompanyBlogs, null);
        }
    };

    const handleMergeBlog = (articlesCompany, articlesAden) => {
        if (articlesCompany && articlesAden) {
            const mergeBlogs = [...articlesAden, ...articlesCompany];
            setAllArticles(mergeBlogs);
        }
        if (articlesCompany === null && articlesAden) {
            const mergeBlogs = [...articlesAden];
            setAllArticles(mergeBlogs);
        }
        if (articlesCompany && articlesAden === null) {
            const mergeBlogs = [...articlesCompany];
            setAllArticles(mergeBlogs);
        }
    };

    return {
        valueArticles,
        allCompanyBlogs,
        allArticles,
        article,
    };
};

export default useBlogArticle;

export const GET_SUBJECTS_REQUEST = "GET_SUBJECTS_REQUEST";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";

// Directories
export const GET_SUBJECT_DIRECTORIES_REQUEST =
  "GET_SUBJECT_DIRECTORIES_REQUEST";
export const GET_SUBJECT_DIRECTORIES_SUCCESS =
  "GET_SUBJECT_DIRECTORIES_SUCCESS";
export const GET_SUBJECT_DIRECTORIES_FAIL = "GET_SUBJECT_DIRECTORIES_FAIL";

export const GET_SUBJECT_PAGINATION_REQUEST = "GET_SUBJECT_PAGINATION_REQUEST";
export const GET_SUBJECT_PAGINATION_SUCCESS = "GET_SUBJECT_PAGINATION_SUCCESS";
export const GET_SUBJECT_PAGINATION_FAIL = "GET_SUBJECT_PAGINATION_FAIL";

export const NEXT_SUBJECT_PAGE = "NEXT_SUBJECT_PAGE";
export const PREVIOUS_SUBJECT_PAGE = "PREVIOUS_SUBJECT_PAGE";
export const CHANGE_SUBJECT_PAGE = "CHANGE_SUBJECT_PAGE";

import React, { useState } from 'react';
import styled from 'styled-components';

// Material UI
import { IconButton, Modal } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import VideocamIcon from '@mui/icons-material/Videocam';

const ModalTutor = (props) => {
    const { officeHour } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    
    // FUNCTION
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // RETURN
    return <>
        <FakeIconButton onClick={openModal}>
            <ImgOHour>
                <VideoIcon />
            </ImgOHour>
            <DataOHour>
                <SubTitle>VIDEO CONSULTA</SubTitle>
                <TitleHour>¡Despeja todas tus dudas!</TitleHour>
            </DataOHour>
        </FakeIconButton>
        <Modal open={isOpen} onClose={closeModal}>
            <ModalContainer>
                <Header>
                    <TitleHeader>
                        ¡Contactate con nuestro tutor!
                    </TitleHeader>
                    <IconButton onClick={closeModal} size="large">
                        <CloseRounded color="secondary" fontSize="small" />
                    </IconButton>
                </Header>
                <IframeWrapper>
                    <Iframe
                        src={officeHour[0].clases[0].link_storage}
                        frameBorder="0"
                        allowFullScreen
                    />
                </IframeWrapper>
            </ModalContainer>
        </Modal>
    </>;
};

export default ModalTutor;

const FakeIconButton = styled.button`
    padding: 0;
    width: 100%;
    height: 70px;
    background-color: #b31d15;
    bottom: 1rem;
    left: 1rem;
    border-radius: 20px;
    transition: 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg{
        font-size: 32px;
    }
    :hover {
        background-color: #9c5854;
        transition: 0.3s ease;
    }

    @media (max-width: 768px) {
        width: 80%;
    }
`;

const ModalContainer = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: max-content;
    min-width: 1000px;
    height: 70vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 768px) {
        min-width: 90vw;
        width: 90vw;
        height: auto;
        top: 25%;
        left: 5%;
        transform: translate(-25%, -5%);
        transform: none;
    }
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const IframeWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25;
    height: 0;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: #b7b7b7;
    border-bottom: 1px solid #eeeeee;
`;

const TitleHeader = styled.div`
    font-size: 1.25rem;
    font-weight: 700;
    color: black;
`;

const VideoIcon = styled(VideocamIcon)`
    color: white;
`;

const DataOHour = styled.div`
    width: 70%;
    text-align: start;
`;

const ImgOHour = styled.div`
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SubTitle = styled.div`
    font-size: 0.7rem;
    color: white;
`;

const TitleHour = styled.div`
    font-size: 0.8rem;
    color: white;
`;

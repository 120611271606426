import styled from "styled-components";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import FilterStatusSubject from "../../molecules/subject/FilterStatusSubject";
import FilterTags from "../../molecules/subject/FilterTags";

const Filters = (props) => {
  const { handleFilter, enrollment } = props;

  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    const courses = enrollment.filter((course) => {
      if (course.type === "oa")
        return (
          course.detail.publication_name
            .toLowerCase()
            .indexOf(search.toLowerCase()) > -1
        );
      if (course.type === "asignatura" && !!course.detail.title)
        return (
          course.detail.title.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      return false;
    });
    value.length === 0 ? handleFilter(enrollment) : handleFilter(courses);
  };

  const handleStateFilter = (value) => {
    const courses = enrollment.filter((course) => course.state === value);
    if (value === "todos") handleFilter(enrollment);
    else handleFilter(courses);
  };

  const handleTags = (courses) => {
    if (!!courses) handleFilter(courses);
    else handleFilter(enrollment);
  };

  return (
    <FiltersWrapper>
      <Column>
        <TextField
          value={search}
          label="Buscar..."
          InputProps={{
            endAdornment: <Search />,
          }}
          onChange={handleSearch}
        />
        <StatusCourse>
          <FilterStatusSubject handleStateFilter={handleStateFilter} />
        </StatusCourse>
      </Column>
      {/* <Tags> */}
      {/* Crear componente */}
      {/* <FilterTags courses={enrollment} handleTags={handleTags} /> */}
      {/* </Tags> */}
    </FiltersWrapper>
  );
};

export default Filters;

const FiltersWrapper = styled.div`
  width: 100%;
`;

const Tags = styled.div`
  width: 100%;
  height: 100px;
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StatusCourse = styled.div`
  height: 60px;
  @media (max-width: 350px) {
    margin: 1.5rem 0;
  }
`;

import styled from "styled-components";

// Component
import BusinessCard from "../../../components/templates/business/components/BusinessCard";
import GridBusiness from "./GridBusiness";

// Hooks
import useTheme from "../../../hooks/useTheme";

// Redux
import { useSelector } from "react-redux";
import GridBusinessSkeleton from "../../../components/skeletons/GridBusinessSkeleton";

const CoursesAden = (props) => {
    const { thematic, handleOpenModal } = props;
    const { primary } = useTheme();

    const CARDS_ON_SKELETON = window.innerWidth < 1750 ? (window.innerWidth < 1600 ? 3 : 4) : 5;

    const ondemandAcademic = useSelector((state) => state.ondemand.academic);

    return (
        <CoursesContainer>
            {ondemandAcademic.aden === null ? (
                <Wrapper>
                    <GridBusinessSkeleton
                        cardWidth="310px"
                        width={310}
                        height={325}
                        cards={CARDS_ON_SKELETON}
                    />
                </Wrapper>
            ) : ondemandAcademic.aden.length > 0 ? (
                <GridBusiness
                    menuFilter={thematic}
                    allCourses={ondemandAcademic.aden}
                    Card={BusinessCard}
                    enableFilter={true}
                    handleOpenModal={handleOpenModal}
                />
            ) : (
                <MessageEmpty color={primary}>
                    No tienen cursos asignados por Aden{" "}
                </MessageEmpty>
            )}
        </CoursesContainer>
    );
};

export default CoursesAden;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const MessageEmpty = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.color};
    font-size: 1.2rem;
    font-weight: 600;
`;

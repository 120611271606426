import styled from "styled-components";
import React from "react";
import { Img } from "../../../common/Image";
import { Text } from "../../../common/Texts";
import * as encode from "nodejs-base64-encode";
import BarProgress from "../../../common/BarProgress";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const CardOa = (props) => {
  const { values } = props;

  const history = useHistory();
  const hash = encode.encode(values.detail.id + "", "base64");

  return (
    <CardOaWrapper>
      <Banner>
        <Status state={values.state}>{values.state}</Status>
        <Img w="100%" h="100%" src={values.detail.image_url} />
        <Type>MODULO</Type>
      </Banner>
      <Body>
        <Title color="#333333" fontSize="1rem" fontWeight="bold">
          {values.detail.publication_name || "La asignatura no posee nombre"}
        </Title>
        <BarProgress
          value={values.detail.progress > 100 ? 100 : values.detail.progress}
        />
        <Description>
          <Text color="#aaaaaa" fontSize=".9rem">
            {values.detail.description || "La asignatura no posee descripción"}
          </Text>
        </Description>
        <Content></Content>
        <Footer>
          <div />
          <Button
            onClick={() => history.push(`/curso/${hash}`)}
            color="primary"
            variant="contained"
          >
            Ir a cursar
          </Button>
        </Footer>
      </Body>
    </CardOaWrapper>
  );
};

export default CardOa;

const CardOaWrapper = styled.div`
  width: 100%;
  max-width: 418px;
  height: 418px;
  box-shadow: 0px 0px 38px 0px rgba(225, 225, 225, 0.75);
  -webkit-box-shadow: 0px 0px 38px 0px rgba(225, 225, 225, 0.75);
  -moz-box-shadow: 0px 0px 38px 0px rgba(225, 225, 225, 0.75);
`;

const Banner = styled.div`
  position: relative;
  img {
    height: 120px;
    object-fit: cover;
    object-position: center center;
  }
`;

const Body = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  display: grid;
  row-gap: 0.5rem;
`;

const Description = styled.div`
  height: 100px;
  overflow-y: auto;
`;

const Content = styled.div`
  display: grid;
  height: 52px;
  row-gap: 0.5rem;
`;

const Status = styled.span`
  font-size: 0.75rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.state === "confirmado"
      ? "#5e80db"
      : props.state === "preinscripto"
      ? "#C29F43"
      : props.state === "egresado"
      ? "#1E8065"
      : ""};
  color: #ffffff;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(1rem, 1rem);
`;

const Footer = styled.div`
  border-top: 1px solid #cccccc;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;

const Type = styled.div`
  background-color: #eeeeee;
  border-top-left-radius: 5px;
  color: #999999;
  position: absolute;
  right: 0;
  bottom: 3px;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.3rem 0.8rem;
`;

const Title = styled(Text)`
  height: 44px;
`;

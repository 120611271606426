import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";

const SubProgramCard = (props) => {
  const {
    data,
    programActive,
    setProgramActive,
    index,
    setCurrentIndex,
    getDataStudyPlan,
    setIsChange,
  } = props;

  // FUNCTION
  const handleActive = () => {
    setProgramActive(data.id);
    setCurrentIndex(index);
    getDataStudyPlan(data.id);
    setIsChange(true);
  };

  return (
    <CardFake onClick={handleActive}>
      <Header>
        <SpanFake programActive={programActive} id={data.id} className="span">
          {data.initials}
        </SpanFake>
      </Header>
      <Body>
        <TextFake
          className="text"
          fontSize="1rem"
          fontWeight="700"
          textColor={programActive === data.id ? "#222222" : "#616161"}
        >
          {data.name}
        </TextFake>
        <ProgressWrapper>
          <LinearProgressWrapper>
            <LinearProgress porcentaje={data.progress} />
          </LinearProgressWrapper>
          <Percentage
            programActive={programActive}
            id={data.id}
            className="percentage"
          >
            {parseInt(data.progress)}%
          </Percentage>
        </ProgressWrapper>
      </Body>
    </CardFake>
  );
};

export default SubProgramCard;

const CardFake = styled(Card)`
  display: flex;
  gap: 0.5rem;
  min-width: 310px;
  width: 310px;
  padding: 0.5rem;
  height: 82px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  :hover {
    .span {
      background-color: #591AB6;
    }
    .percentage {
      color: #222222;
    }
    .text {
      color: #222222;
    }
  }
`;

const Header = styled.div`
  width: fit-content;
`;

const SpanFake = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  border-radius: 50%;
  position: relative;

  background-color: ${(p) =>
    p.programActive === p.id ? "#591AB6" : "#C4C4C4"};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TextFake = styled(Text)`
  height: 40px;
  width: 235px;
  font-size: 0.75rem;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow-y: hidden;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.7rem;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #3bd39c;
`;

const Percentage = styled.span`
  font-size: 0.9rem;
  color: ${(p) => (p.programActive === p.id ? "#222222" : "#616161")};
  font-weight: bold;
  flex-wrap: wrap;
`;

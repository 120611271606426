import React, { useState } from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../components/common/Texts";
import GenericChips from "../../../../../components/common/GenericChip";
import SimpleReview from "./SimpleReview";

// Material UI
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Attempts = (props) => {
  const { note, submissions } = props;

  const [open, setOpen] = useState(false);
  const [submission, setSubmission] = useState(null);

  const handleClickOpen = (sub) => {
    setSubmission(sub);
    setOpen(true);
  };

  const handleClose = () => {
    setSubmission(null);
    setOpen(false);
  };

  return (
    <>
      <AttemptsWrapper>
        <Header>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            INTENTO
          </Col>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            FECHA DE ENTREGA
          </Col>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            PUNTOS OBTENIDOS
          </Col>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            CALIFICACIÓN
          </Col>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            ESTADO
          </Col>
          <Col color="#616161" fontWeight="700" fontSize="14px">
            REVISIÓN
          </Col>
        </Header>
        <Body>
          {submissions
            .sort((a, b) => {
              return b.submission_number - a.submission_number;
            })
            .map((sub, index) => {
              const newDate = new Date(sub.create_date);
              return (
                <>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    {sub.submission_number}
                  </Col>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    {newDate.toLocaleString()}
                  </Col>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    {sub.grade}/{note}
                  </Col>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    {((sub.grade * 100) / note).toFixed(2)}%
                  </Col>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    {
                      <GenericChips
                        bgColor="#5E80DB"
                        textColor="#EAFAF6"
                        fontSize="12px"
                        title="Entregado"
                        textTransform="none"
                      />
                    }
                  </Col>
                  <Col color="#616161" fontWeight="700" fontSize="14px">
                    <IconButton
                      onClick={() => handleClickOpen(sub)}
                      size="small"
                    >
                      <VisibilityIconFake />
                    </IconButton>
                  </Col>
                </>
              );
            })}
        </Body>
      </AttemptsWrapper>
      {/* Revision */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SimpleReview submission={submission} />
      </Dialog>
    </>
  );
};

export default Attempts;

const AttemptsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Body = styled.div`
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 8px 0;

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

const Col = styled(Text)`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VisibilityIconFake = styled(VisibilityIcon)`
  font-size: 16px;
  margin: 2px;
`;

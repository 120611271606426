import moment from "moment";
import styled from "styled-components";
import ReactCalendar from "react-calendar";
import SkeletonLoading from "../../../components/common/SkeletonLoading";

const Calendar = (props) => {
  const { dates } = props;

  const displayEventDates = ({ activeStartDate, date, view }) => {
    const arrayClases = ["online", "presencial", "hibrido", "evaluacion"];
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const event = dates.find((event) => event.date === formattedDate);
    if (event !== undefined) {
      const eventClass = arrayClases.find((c) => c === event.type);
      return eventClass || null;
    }
    return null;
  };

  if (!dates) {
    return <SkeletonLoading height={420} width="720px" />;
  }
  return (
    <CustomCalendar
      currentDate={dates?.[0]?.date}
      showNeighboringMonth={false}
      prev2Label={null}
      next2Label={null}
      tileClassName={displayEventDates}
      view="month"
      locale="es-AR"
    />
  );
};

export default Calendar;

const CustomCalendar = styled(ReactCalendar)`
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 900px;
  padding: 2rem;
  border-radius: 20px !important;
  border: 1px solid #616161 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 950px) {
    max-width: 70% !important;
    margin: auto;
  }

  &.react-calendar {
    height: 100%;
    padding: 2rem;

    .react-calendar__month-view__days {
      min-height: 150px !important;
      position: relative;
      justify-content: flex-start;

      .react-calendar__month-view__days__day {
        width: 50px !important;
        height: 50px !important;
        display: grid;
        place-items: center;
        border-radius: 50%;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    .react-calendar__tile {
      box-sizing: border-box;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }

    .react-calendar__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #cac4d0;
      margin-bottom: 1rem;

      .react-calendar__navigation__next-button,
      .react-calendar__navigation__prev-button {
        font-size: 1.5rem;
        padding: 0.5rem;
      }
      .react-calendar__navigation__label__labelText {
        font-size: 2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    //class rounded event day
    .online {
      background-color: var(--tertiary-two);
      min-width: 50px !important;
      max-width: 50px !important;
      min-height: 50px !important;
      max-height: 50px !important;
      display: grid;
      place-items: center;
      border-radius: 50%;
      color: #fafafa;
    }

    .presencial {
      background-color: var(--tertiary-four);
      min-width: 50px !important;
      max-width: 50px !important;
      min-height: 50px !important;
      max-height: 50px !important;
      display: grid;
      place-items: center;
      border-radius: 50%;
      color: #fafafa;
    }

    .hibrido {
      background-color: #28a986;
      min-width: 50px !important;
      max-width: 50px !important;
      min-height: 50px !important;
      max-height: 50px !important;
      display: grid;
      place-items: center;
      border-radius: 50%;
      color: #fafafa;
    }
  }
`;

import styled from "styled-components";
import img from "../../../../../assets/media/svg/graduation-animate.svg";

import { Text } from "../../../../../components/common/Texts";
import Button from "../../../../../components/ui/atoms/Button";
import { Img } from "../../../../../components/common/Image";

const RiseScorm = (props) => {
  const { url, title, onLoad } = props;
  console.log("🚀 ~ RiseScorm ~ url:", url);

  const handleOpenScorm = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = screenWidth * 0.6; // 90% del ancho de la pantalla
    const height = screenHeight * 0.6; // 90% de la altura de la pantalla
    const left = (screenWidth - width) / 2; // Centrado horizontalmente
    const top = (screenHeight - height) / 2; // Centrado verticalmente
    window.open(
      url,
      "_blank",
      `width=${width},height=${height},left=${left},top=${top},resizable=no,toolbar=no,menubar=no,location=no,status=no`
    );
    onLoad();
  };

  return (
    <Content>
      <Text fontSize="1.7rem" color="#b31d15" fontWeight="bold">
        {title}
      </Text>
      <Img src={img} w="250px" />
      <Description>
        ¡Hola! Para comenzar la actividad, por favor haz clic en el siguiente
        botón. ¡Esperamos que disfrutes la experiencia y aprendas mucho! 😊
      </Description>
      <Button onClick={handleOpenScorm}>Click para ver la actividad </Button>
      <Text fontSize=".7rem" color="#333" fontWeight="bold">
        NOTA: Al terminar la actividad cerrar la ventana modal y seguir con el
        cursado
      </Text>
    </Content>
  );
};

export default RiseScorm;

const Content = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 1.5rem;
  margin-bottom: 80px;
`;

const Description = styled.div`
  width: 50%;
  text-align: center;
`;

import axios from "axios";

export const postContribution = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/contributions`;

    try {
        const result = await axios.post(URL, body);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postComment = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/contributions_comment`;

    try {
        const result = await axios.post(URL, body);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getContribution = async (idResource) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/contributions/${idResource}`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const patchLikeComment = async (id, type, partnerId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/like/${id}?type=${type}&partner_id=${partnerId}`;

    try {
        const result = await axios.patch(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import GridBusiness from "./GridBusiness";
import AlertDialog from "../../../components/common/AlertDialog";
import BusinessCard from "../../../components/templates/business/components/BusinessCard";
import GridBusinessSkeleton from "../../../components/skeletons/GridBusinessSkeleton";

// Hooks
import useTheme from "../../../hooks/useTheme";
import { useAlertDialog } from "../../../hooks/useAlertDialog";

const CoursesAddiotional = (props) => {
  const { thematic, ondemandAcademic, handleOpenModal } = props;
  const { primary } = useTheme();
  // REDUX

  const [alertDialog, closeAlertDialog] = useAlertDialog();
  const [courses, setCourses] = useState(null);
  const [sliceArray, setsliceArray] = useState(0);
  const [moreCourseSelected, setMoreCourseSelected] = useState(false);

  useEffect(() => {
    if (ondemandAcademic.additional !== null) {
      cutArray(ondemandAcademic.additional);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ondemandAcademic]);

  const cutArray = (array) => {
    const result = array.slice(sliceArray, sliceArray + 9);
    if (courses !== null) {
      setCourses([...courses, ...result]);
    } else {
      setCourses(result);
    }
    setsliceArray(sliceArray + 9);
  };

  const CARDS_ON_SKELETON = window.innerWidth < 1750 ? (window.innerWidth < 1600 ? 3 : 4) : 5;

  return (
    <CoursesContainer>
      {courses === null ? (
        <Wrapper>
          <GridBusinessSkeleton
          cards={CARDS_ON_SKELETON}
          />
        </Wrapper>
      ) : ondemandAcademic.additional.length > 0 ? (
        // themeState === null ? (
        <>
          <GridBusiness
            menuFilter={thematic}
            propsValues={courses}
            Card={BusinessCard}
            allCourses={ondemandAcademic.additional}
            moreCourseSelected={moreCourseSelected}
            setMoreCourseSelected={setMoreCourseSelected}
            handleOpenModal={handleOpenModal}
            filterByStateActivated={false}
            progressActive={false}
            additional={true}
          />
        </>
      ) : (
        <MessageEmpty color={primary}>No hay cursos adicionales</MessageEmpty>
      )}

      <AlertDialog
        titulo={alertDialog.titulo}
        mensaje={alertDialog.mensaje}
        accion={alertDialog.accion}
        open={alertDialog.open}
        onClose={closeAlertDialog}
      ></AlertDialog>
    </CoursesContainer>
  );
};

export default CoursesAddiotional;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const MessageEmpty = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.color};
  font-size: 1.2rem;
  font-weight: 600;
`;
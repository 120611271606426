import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Text from "../../../common/Text";
import { Tooltip, Zoom } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const CardBenefit = (props) => {
  const { benefit, customImg, alt } = props;
  const GrayTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#ffffff",
      color: "gray",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: "0.2em 0.5em",
      border: "1px solid #f2f2f2",
    },
  }))(Tooltip);

  // RETURN
  return (
    <Container>
      <GrayTooltip title={benefit.description} TransitionComponent={Zoom}>
        <span>
          <Text fontSize="1rem" component="h2">
            <FakeLink
              to={
                benefit.name === "Completa tu perfil"
                  ? "/perfil"
                  : benefit.name === "Completa tu cursado a tiempo"
                  ? "/mi-progreso"
                  : benefit.name === "Participa en talleres TNT"
                  ? "/eventos"
                  : benefit.name === "Participa en talleres TSV"
                  ? "/eventos"
                  : benefit.name === "Compartir contenido"
                  ? "/biblioteca"
                  : "/"
              }
            >
              {benefit.name}
            </FakeLink>
          </Text>
        </span>
      </GrayTooltip>
      <CoinsWrapper>
        <Text
          fontSize="1.1rem"
          fontWeight="700"
          color="secondary"
          component="span"
        >
          +{benefit.amount}
        </Text>
        <CustomImg src={`${customImg}`} alt={`${alt}`} />
      </CoinsWrapper>
    </Container>
  );
};

export default CardBenefit;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
`;

const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const CustomImg = styled.img`
  width: 20px;
  height: auto;
`;
const FakeLink = styled(Link)`
  color: black;
`;

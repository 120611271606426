export const GET_DRACMA_REQUEST = "GET_DRACMA_REQUEST";
export const GET_DRACMA_SUCCESS = "GET_DRACMA_SUCCESS";
export const GET_DRACMA_FAIL = "GET_DRACMA_FAIL";

export const GET_MOVEMENTS_REQUEST = "GET_MOVEMENTS_REQUEST";
export const GET_MOVEMENTS_SUCCESS = "GET_MOVEMENTS_SUCCESS";
export const GET_MOVEMENTS_FAIL = "GET_MOVEMENTS_FAIL";

export const GET_RANKING_REQUEST = "GET_RANKING_REQUEST";
export const GET_RANKING_SUCCESS = "GET_RANKING_SUCCESS";
export const GET_RANKING_FAIL = "GET_RANKING_FAIL";

export const GET_RANKINGONDEMAND_REQUEST = "GET_RANKINGONDEMAND_REQUEST";
export const GET_RANKINGONDEMAND_SUCCESS = "GET_RANKINGONDEMAND_SUCCESS";
export const GET_RANKINGONDEMAND_FAIL = "GET_RANKINGONDEMAND_FAIL";

export const GET_BENEFITS_REQUEST = "GET_BENEFITS_REQUEST";
export const GET_BENEFITS_SUCCESS = "GET_BENEFITS_SUCCESS";
export const GET_BENEFITS_FAIL = "GET_BENEFITS_FAIL";

import persistReducer from "redux-persist/es/persistReducer";
import * as types from "../types";
import storage from "redux-persist/lib/storage";

const initialState = {
  ondemand: null,
  academic: {
    assignments: null,
    aden: null,
    business: null,
    additional: null,
    analytics: null,
  },
  url_desafio: null,
  resources: null,
  listLoading: false,
  errorList: null,
};

export const ondemand = persistReducer(
  {
    storage,
    key: "campus-incompany",
    whitelist: ["ondemand", "url_desafio"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      // GET ONDEMNAD
      case types.GET_ONDEMAND_REQUEST:
        return {
          ...state,
          listLoading: true,
          errorList: null,
        };
      case types.GET_ONDEMAND_SUCCESS:
        return {
          ...state,
          listLoading: false,
          ondemand: action.payload,
          errorList: null,
        };
      case types.SET_ONDEMAND:
        return initialState;
      case types.GET_ONDEMAND_FAIL:
        // console.log(action.payload.response.data.error)
        return {
          ...state,
          listLoading: false,
          errorList: { ...state.errorList, ondemand: action.payload },
        };

      // COMPETITOR
      case types.GET_ONDEMANDCOMPETITOR_REQUEST:
        return {
          ...state,
          listLoading: true,
          errorList: null,
        };
      case types.GET_ONDEMANDCOMPETITOR_SUCCESS:
        let aden = action.payload.objetos_aprendizaje.filter(
          (obj) => obj.oa_personalizado !== true && obj.id
        );
        let business = action.payload.objetos_aprendizaje.filter(
          (obj) => obj.oa_personalizado === true && obj.id
        );

        // let coursesInfo = action.payload
        //   const oa_0 = {
        //     id: 6619,
        //     nombre_publicacion: "Modelo Pericles de Transferencia",
        //     imagen:
        //       "https://repositorio.aden.org/web/image?model=repo.aden.objeto_aprendizaje&id=6619&field=imagen&unique=26012022160120",
        //     descripcion: "Modelo Pericles de transferencia..",
        //     obligatorio: true,
        //     eje_tematico_ids: [],
        //     progreso: 0,
        //   };

        return {
          ...state,
          listLoading: false,
          academic: {
            ...state.academic,
            aden: [...aden],
            business,
          },
          url_desafio: action.payload.url_desafio,
          resources:
            action.payload.recursos.length > 0 ? action.payload.recursos : null,
          errorList: null,
        };
      case types.GET_ONDEMANDCOMPETITOR_FAIL:
        // console.log(action.payload.response.data.error)
        return {
          ...state,
          academic: {
            ...state.academic,
            aden: false,
          },
          listLoading: false,
          errorList: { ...state.errorList, competitor: action.payload },
        };

      // COURSES ADDITIONAL
      case types.GET_COURSES_ADDITIONAL_REQUEST:
        return {
          ...state,
          listLoading: true,
          errorList: null,
        };
      case types.GET_COURSES_ADDITIONAL_SUCCESS:
        return {
          ...state,
          listLoading: false,
          academic: {
            ...state.academic,
            additional: action.payload,
          },
          errorList: null,
        };
      case types.GET_COURSES_ADDITIONAL_FAIL:
        // console.log(action.payload.response.data.error)
        return {
          ...state,
          listLoading: false,
          errorList: { ...state.errorList, competitor: action.payload },
        };
      // ASSIGNMENTS
      case types.GET_ASSIGNMENTS_REQUEST:
        return {
          ...state,
          listLoading: true,
          errorList: null,
        };
      case types.GET_ASSIGNMENTS_SUCCESS:
        return {
          ...state,
          listLoading: false,
          academic: {
            ...state.academic,
            assignments: action.payload,
          },
          errorList: null,
        };
      case types.GET_ASSIGNMENTS_FAIL:
        return {
          ...state,
          listLoading: false,
          errorList: { ...state.errorList, competitor: action.payload },
        };
      // ANALYTICS
      case types.GET_ANALYTICS_REQUEST:
        return {
          ...state,
          listLoading: true,
          errorList: null,
        };
      case types.GET_ANALYTICS_SUCCESS:
        return {
          ...state,
          listLoading: false,
          academic: {
            ...state.academic,
            analytics: action.payload
          },
          errorList: null,
        };
      case types.GET_ANALYTICS_FAIL: 
        return {
          ...state,
          listLoading: false,
          errorList: { ...state.errorList, competitor: action.payload },
        }

      default:
        return state;
    }
  }
);

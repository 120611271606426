import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

// Material UI
import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CreateIcon from "@mui/icons-material/Create";
import VideocamIcon from "@mui/icons-material/Videocam";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LockIcon from '@mui/icons-material/Lock';

// Hooks
import useTheme from "../../../../hooks/useTheme";

// Context
import CourseContext from "../../context/CourseContext"
import { Icon } from "@iconify/react/dist/iconify.js";
import ModalInfo from "./ModalInfo";

const AsideListItem = (props) => {

	const {
		unidad,
		id,
		index,
		isEnabled,
	} = props;

	const {
		currentMedia,
		changeCurrentMedia,
		currentUnit,
		changeStatus,
		setChangeStatus,
		setViewDelivery
	} = useContext(CourseContext);

	//STATES
	const [isOpen, setIsOpen] = useState(false);
	const [claseFake, setClaseFake] = useState(unidad?.clases);
	const [openModuleInfo, setOpenModuleInfo] = useState(false);

	// Tema
	const { primary } = useTheme();

	useEffect(() => {
		if (id === 0) {
			setIsOpen(true);
		}
	}, [id]);

	useEffect(() => {
		if (currentUnit === unidad.nombre) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMedia]);

	useEffect(() => {
		if (!!changeStatus) {
			handleStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [changeStatus]);

	function handleSetIsOpen() {
		setIsOpen(!isOpen);
	}

	const handleStatus = () => {
		let clase = claseFake;

		for (let index = 0; index < clase.length; index++) {
			if (clase[index].nombre_publicacion === currentMedia.nombre_publicacion) {
				clase[index].state = "pendiente_correccion";
			}
		}
		setClaseFake(clase);
		setChangeStatus(false);
	};

	const getState = (status) => {
		const cases = {
			pendiente_correccion: {
				name: "PENDIENTE DE CORRECCION",
				color: "#b8a349",
			},
			corregido: {
				name: "CORREGIDO",
				color: "#3bd39c",
			},
			entregado: {
				name: "ENTREGADO",
				color: "#3bd39c",
			},
			rehacer: {
				name: "REHACER",
				color: "#FC2958",
			},
		};

		return cases[status] || false;
	};

	const renderIconFormat = (type, seen, active) => {
		if (type === "evaluacion" && !seen) {
			return active ? (
				<ListAltIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			) : (
				<ListAltIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			);
		}
		if (type === "presentacion" && !seen) {
			return active ? (
				<CreateIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			) : (
				<CreateIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			);
		}
		if (type === "material_plano" && !seen) {
			return active ? (
				<DescriptionIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			) : (
				<DescriptionIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			);
		}
		if (type === "audiovisual" && !seen) {
			return active ? (
				<VideocamIcon style={{ color: "var(--primary-one)", backgroundColor: "var(--secondary-one)", borderRadius: "20px", padding: 3 }} />
			) : (
				<VideocamIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-three)", borderRadius: "20px", padding: 3 }} />
			);
		}
		if (seen) {
			return <CheckCircleRoundedIcon style={{ color: "#3BD39C", padding: -1, width: "34px", height: "34px" }} />;
		}
	};

	/** RETURN ----------------------------------------------------------------------- */
	return (
		<>
			<FakeListItem
				button
				open={isOpen}
				// disabled={!!isOpen ? false : !isEnabled}
				onClick={handleSetIsOpen}
				isEnabled={isEnabled}
				color={primary}
			>
				<ListItemIcon >
					<ItemIndex isOpen={!!isOpen} isEnabled={isEnabled}>{index}.</ItemIndex>
				</ListItemIcon>
				<ListItemArrow>
					{
						isOpen ? <ExpandMoreRoundedIcon fontSize="large" /> : <ExpandLessRoundedIcon fontSize="large" />
					}
				</ListItemArrow>
				<FakeListItemText primary={unidad.nombre} />
			</FakeListItem>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List>
					{unidad.clases &&
						claseFake.map((clase, index) => {
							if (
								clase.nombre_publicacion === currentMedia.nombre_publicacion
							) {
								setViewDelivery(clase.visto);
							}

							return (
								<FakeListItemTwo
									view={clase.visto ? true : false}
									button
									key={index}
									disabled={!isEnabled}
									onClick={() => changeCurrentMedia(clase)}
									onPointerEnterCapture={clase.orden === currentMedia.orden}
									open={
										clase.nombre_publicacion === currentMedia.nombre_publicacion
									}
									type={clase.formato}
									seen={clase.visto}
								>
									{
										isEnabled ?
											<FakeItemIcon>
												{renderIconFormat(
													clase.formato,
													clase.visto,
													clase.nombre_publicacion ===
													currentMedia.nombre_publicacion
												)}
											</FakeItemIcon>
											:
											<LockIcon style={{ color: "var(--secondary-one)", backgroundColor: "var(--secondary-four)", borderRadius: "20px", padding: 3, width: "20px", height: "20px" }} />
									}
									<ListItemTextFake>
										<Name
											view={clase.visto ? true : false}
											open={
												clase.nombre_publicacion ===
												currentMedia.nombre_publicacion
											}
											seen={clase.visto}
											isEnabled={isEnabled}
										>
											{clase.nombre_publicacion.replace(
												/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
												""
											)}
										</Name>
										{!!clase.state ? (
											!!changeStatus ? (
												<State color="#3bd39c">ENTREGADO</State>
											) : (
												<State color={getState(clase.state)?.color}>
													{getState(clase.state)?.name}
												</State>
											)
										) : null}
									</ListItemTextFake>
								</FakeListItemTwo>
							);
						})}
				</List>
			</Collapse>
			{!isEnabled && (
				<DisabledInfo onClick={() => setOpenModuleInfo(true)}>
					<Icon icon="octicon:info-24" width="24px" height="24px" />
					<label>Recuerda que para habilitar este módulo debes <b>completar el módulo anterior.</b></label>
				</DisabledInfo>
			)}
			<ModalInfo
				isOpen={openModuleInfo}
				handleCloseModal={() => setOpenModuleInfo(false)}
				handleClick={() => setOpenModuleInfo(false)}
			/>
		</>
	);
};

export default AsideListItem;

const FakeListItem = styled(ListItem)`
  overflow: hidden;
  color:  ${(props) => (props.open && props.isEnabled) ? "var(--secondary-one)" : "#616161"};
  background-color: ${(props) => (props.open && props.isEnabled) ? props.color : "var(--secondary-one)"};
  padding: 1rem 4rem;
  font-size: 18px;
  border-radius: 20px;
  margin: 25px 10px;
  width: calc(100% - 20px);
  height: 60px;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);

  &:hover {
    background-color: ${(props) => (!props.open || !props.isEnabled) ? "#f1f1f1" : props.color};
    color: ${(props) => (!props.open && props.isEnabled) && "var(--primary-one)"};
    span {
      font-weight: ${(props) => !props.open && "600"};;
    }
  }

  span {
    font-weight: 500;
  }
  .MuiListItemIcon-root {
    max-width: 36px;
    min-width: 36px;
  }

  &:hover {
      section {
        background: ${(props) => !props.isEnabled && "#C4C4C4"};
      }
  }
  section {
    background: ${(props) => props.isEnabled ? props.color : "var(--secondary-two)"};
    
  }

  @media (max-width: 1400px) {
    padding: 0rem 4rem;
    svg {
      font-size: 1rem;
    }
  }
`;

const FakeListItemTwo = styled(ListItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  padding: 0.25rem .5rem;
  background-color: ${(props) => (props.open && props.type === "audiovisual" && !props.seen) ? "var(--primary-one)" : "transparent"};
  &:hover {
    background-color: ${(props) => (props.open && props.type === "audiovisual" && !props.seen) ? "var(--primary-one)" : "#F1F1F1"};
  }
  list-style: none;
  border-radius: 20px;
  width: calc(100% - 20px);
  margin: 3px 10px;
  svg {
    color: var(--secondary-one);
  }
`;

const FakeListItemText = styled(ListItemText)`
  font-size: 1.25rem;
  font-weight: 400;
  @media (max-width: 1400px) {
    font-size: 0.6rem;
  }
`;

const Name = styled.span`
  font-size: 0.9rem;
  font-weight: ${(props) => (props.open ? "500" : "400")};
  color: ${(props) => (props.open && !props.seen && props.isEnabled) ? "var(--secondary-one)" : "#616161"} ;
  @media (max-width: 1400px) {
    font-size: 0.7rem;
  }
`;

const ListItemTextFake = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FakeItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(0.8);
  }
`;

const ItemIndex = styled.section`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  width: 96px;
  height: 96px;
  /* background: ${(props) => props.isEnabled ? "var(--primary-one)" : "var(--secondary-two)"}; */
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  /* &:hover {
    background: ${(props) => !props.isEnabled && "#C4C4C4"};
  } */
`;

const ListItemArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
`

const State = styled.div`
  font-size: 10px;
  background-color: ${(p) => p.color};
  color: white;
  padding: 0 4px;
  border-radius: 4px;
  width: fit-content;
`;

const DisabledInfo = styled.button`
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin-left: 10px;

    svg {
        color: #c29f43;
    }

    label {
        color: #616161;
        cursor: pointer;
        text-align: start;
    }

    :hover {
        label {
            text-decoration: underline;
        }
    }
`;
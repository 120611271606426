import React from "react";
import { Icon } from "@iconify/react";
import styled from "@emotion/styled";

// Redux
import { useSelector } from "react-redux";

const OrderComments = (props) => {

    const {currentFilter, handleFilter} = props;

    const { activeRol } = useSelector((state) => state.auth);
    
    return (
        <Container activeRol={activeRol}>
            <p>Ordenar por:</p>
            <ButtonsContainer>
                <CustomButton
                    onClick={() => {
                        handleFilter("new");
                    }}
                    filter="new"
                    currentFilter={currentFilter}
                    activeRol={activeRol === "ondemand"}
                >
                    <CheckIcon
                        filter="new"
                        currentFilter={currentFilter}
                        icon="iconamoon:check-bold"
                    />
                    Nuevos
                </CustomButton>
                <CustomButton
                    onClick={() => {
                        handleFilter("votes");
                    }}
                    filter="votes"
                    currentFilter={currentFilter}
                    activeRol={activeRol === "ondemand"}
                >
                    <CheckIcon
                        filter="votes"
                        currentFilter={currentFilter}
                        icon="iconamoon:check-bold"
                    />
                    Más votados
                </CustomButton>
            </ButtonsContainer>
        </Container>
    );
};

export default OrderComments;

const Container = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;

    p {
        font-size: ${(props) => props.activeRol ? "14px" : "1rem"};
    }

    @media (max-width: 1550px) {
        p {
            font-size: 0.9rem;
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const CustomButton = styled.button`
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: ${(p) =>
        p.currentFilter === p.filter ? "none" : "solid 1px #a8a8a8"};
    border-radius: 8px;
    color: ${(p) => (p.currentFilter === p.filter ? "#222222" : "#616161")};
    width: fit-content;
    background-color: ${(p) =>
        p.currentFilter === p.filter ? "#DCDCDC" : "transparent"};
    padding: 6px 16px;
    display: flex;
    font-size: ${(props) => props.activeRol ? "14px" : "1rem"};
    gap: 0.3rem;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: ${(props) => (props.activeRol && props.currentFilter !== props.filter) ? "#f1f1f1" : "#dcdcdc"};
    }

    @media (max-width: 1550px) {
        
            font-size: 0.9rem;
    }
`;

const CheckIcon = styled(Icon)`
    font-size: 1.2rem;
    display: ${(p) => (p.currentFilter === p.filter ? "block" : "none")};

    @media (max-width: 1550px) {
        
        font-size: 1.1rem;
}
`;
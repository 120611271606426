import axios from "axios";

export const createRefered = async (body) => {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/referidos`;

  try {
    const response = await axios.post(URL, body);
    // console.log(response.data.response_data.students);
    return response.data;
  } catch (error) {
    return { error };
  }
};

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Announcements from "./components-maincampus/Announcements";
import BlogSlider from "./components-maincampus/BlogSlider";
import BusinessSlider from "./components-maincampus/BusinessSlider";
import FirstSection from "./components-maincampus/FirstSection";
import Greeting from "./components-maincampus/Greeting";
import Microlearning from "./components-maincampus/Microlearning";
import NewsForYou from "./components-maincampus/NewsForYou";
import RecommendedCourses from "./components-maincampus/RecommendedCourses";

const MainCampus = () => {

    const { ondemand } = useSelector((state) => state.ondemand);
    const { activeRol } = useSelector((state) => state.auth);
    
    const enable_workshop = !!ondemand ? ondemand?.enable_workshop : true;

    const { enable_company_blog, enable_blog_aden, enable_additional_course, enable_news_for_you = false } = ondemand;

    return (
        <GridWrapp>
            {/* Cumbre - Banner */}
            {/* <CumbreBranner /> */}
            {/* Saludos */}
            <Greeting />
            {/* Primera sección - Sólo ondemand */}
            {activeRol === "ondemand" && <FirstSection />}
            {/* Anuncios */}
            {<Announcements />}
            {/* Cursos empresa */}
            {activeRol === "ondemand" && <BusinessSlider />}
            {/* Cursos recomendados */}
            {enable_additional_course && <RecommendedCourses />}
            {/* Microcontenidos */}
            {enable_workshop && <Microlearning />}            
            {/* Blog */}
            {(enable_blog_aden || enable_company_blog) && <BlogSlider />}
            {/* Novedades para tí */}
            {enable_news_for_you && <NewsForYou />}
        </GridWrapp>
    );
};

export default MainCampus;

const GridWrapp = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

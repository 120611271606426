import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from 'styled-components';

const GridFilterTags = (props) => {

    const { currentFilters, selectedOptionActive, handleDeleteTagState, handleDeleteTagCategory } = props;

    return (
        <>
            {
                (currentFilters?.length !== 0 || selectedOptionActive !== "todos") && (
                    <FilterTagsContainer>
                        {
                            selectedOptionActive !== "todos" && (
                                <FilterTag onClick={() => {
                                    handleDeleteTagState()
                                }}>
                                    #{selectedOptionActive}
                                    <CloseRoundedIcon sx={{ fontSize: 16, color: "var(--secondary-four)" }} />
                                </FilterTag>
                            )
                        }
                        {
                            currentFilters?.map((tag) => (
                                <FilterTag onClick={() => {
                                    handleDeleteTagCategory(tag.name)
                                }}>
                                    #{tag.name.toLowerCase()}
                                    <CloseRoundedIcon sx={{ fontSize: 16, color: "var(--secondary-four)" }} />
                                </FilterTag>
                            ))
                        }
                    </FilterTagsContainer>
                )
            }
        </>
    )
}

export default GridFilterTags;

const FilterTagsContainer = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 0 2.5rem;
`

const FilterTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #a8a8a8;
  background-color: var(--secondary-two);
  font-size: 14px;
  color: var(--secondary-three);
  padding: 4px 8px;
  cursor: pointer;
`
const typeStyle = {
  onlineColor: "var(--tertiary-two)",
  presencialColor: "var(--tertiary-four)",
  hibridoColor: "#28A986",
  evaluacionColor: "#CAC4D0",
  desconocidoColor: "#000",
};

export const getEventType = (eventType) => {
  switch (true) {
    case eventType?.includes("Virtual"):
      return {
        type: "online",
        color: typeStyle.onlineColor,
      };
    case eventType?.includes("Presencial"):
      return {
        type: "presencial",
        color: typeStyle.presencialColor,
      };
    // case eventType?.includes("Evaluacion"):
    //   return {
    //     type: "evaluacion",
    //     color: typeStyle.evaluacionColor,
    //   };

    case eventType?.includes("Híbrido"):
      return {
        type: "hibrido",
        color: typeStyle.hibridoColor,
      };
    default:
      return {
        type: "DESCONOCIDO",
        color: typeStyle.desconocidoColor,
      };
  }
};

import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import CalendarToday from "@mui/icons-material/CalendarToday";
import StarsIcon from "@mui/icons-material/Stars";
import ReportIcon from "@mui/icons-material/Report";
import EventBusy from "@mui/icons-material/EventBusy";
import GenericChips from "../../../common/GenericChip";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalAssignment from "./ModalAssignment";
import DialogGenericFG from "../../../common/DialogGenericFG";
import * as encode from "nodejs-base64-encode";

const SubjectsCard = (props) => {
  const {
    data,
    setDataAssigment,
    dataAssigment,
    setIsChange,
    onInscription,
    dataProgramName,
    isDiplomat,
    setIsFlag,
    isFlag,
  } = props;

  const history = useHistory();
  const repo_id = useSelector((state) => state.auth.user.repo_id);

  const { id } = useParams();
  const idProgram = encode.decode(id, "base64");

  // STATES
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = React.useState(false);

  const hash = encode.encode(data.id + "", "base64");

  const isDiplomado = dataProgramName.toLowerCase().includes("diplomado");

  // FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (
      data.state === "egresado" ||
      data.state === "confirmado" ||
      data.state === "DEMO"
    ) {
      history.push(
        `../asignatura/${hash}?program=${id}&origin=${
          isDiplomat ? "diplomado" : "alab"
        }`
      );
    }
    if (data.state === "pendiente") {
      if (!!onInscription) {
        setOpenModal(!openModal);
      } else {
        handleClickOpen();
      }
    }
  };

  if (!!open) {
    return (
      <DialogGenericFG
        message={`No puede cursar más de dos materias en simultáneo`}
        open={open}
        title="Error al Inscribirse"
        handleClose={handleClose}
        customColor="#591AB6"
        titleColor="#591AB6"
        transitionType="fade"
      />
    );
  }

  return (
    <>
      <SubjectCardContainer
        notAvailable={data.assignment_without_information}
        title={data.name}
        onClick={handleClick}
        estado={data.state}
      >
        {openModal && (
          <ModalAssignment
            repo_id={repo_id}
            handleClick={handleClick}
            data={data}
            idProgram={idProgram}
            setDataAssigment={setDataAssigment}
            dataAssigment={dataAssigment}
            setIsChange={setIsChange}
            setIsFlag={setIsFlag}
            isFlag={isFlag}
          />
        )}

        <PhotoWrapper>
          <ImgFake
            src={
              !data.assignment_without_information
                ? data.img
                : "../../assets/aden/NotImageFound.png"
            }
          />
        </PhotoWrapper>
        <Body>
          {data.assignment_without_information && (
            <WrapperNotAssignment>
              <ReportIcon />
            </WrapperNotAssignment>
          )}
          <Label>
            <GenericChips
              title={
                !data.assignment_without_information
                  ? data.state
                  : "No disponible"
              }
              color={
                data.state === "egresado"
                  ? "#1E8065"
                  : data.state === "confirmado"
                  ? "#5E80DB"
                  : data.state === "pendiente"
                  ? "#B75E26"
                  : "#FC2958"
              }
              textColor="#fff"
              fontSize=".75rem"
            />
          </Label>
          <Plan notAvailable={data.assignment_without_information}>
            {data.name.toTitleCase() || "sin nombre"}
          </Plan>
          {(!data.assignment_without_information &&
            data.state === "egresado") ||
          data.state === "confirmado" ? (
            <ProgressWrapper>
              <LinearProgressWrapper>
                <LinearProgress
                  porcentaje={
                    data?.progress?.progress > 100
                      ? 100
                      : data?.progress?.progress
                  }
                />
              </LinearProgressWrapper>
              <Percentage>
                {data?.progress?.progress > 100
                  ? 100
                  : data?.progress?.progress}
                %
              </Percentage>
            </ProgressWrapper>
          ) : null}
          {data?.estado !== "egresado" ? (
            <DetailsWrapper>
              <CourseWrapper>
                <SubTitle>
                  <CalendarToday />
                  <Content>
                    <p>Inicio</p>
                    {data.state === "confirmado" ||
                    data.state === "egresado" ? (
                      <span>{data.start_date}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </Content>
                </SubTitle>
              </CourseWrapper>
              {/* <CourseWrapper>
                <SubTitle>
                  <EventAvailable />
                  <Content>
                    <p>Fin teórico</p>
                    {data.estado === "confirmado" ? (
                      <span>{data.fecha_fin}</span>
                    ) : (
                      <span>-</span>
                    )}0
                    <span>-</span>
                  </Content>
                </SubTitle>
              </CourseWrapper> */}
              {!isDiplomado && (
                <CourseWrapper>
                  <SubTitle>
                    <EventBusy />
                    <Content>
                      <p>Vencimiento</p>
                      {data.state === "confirmado" ||
                      data.state === "egresado" ? (
                        <span>{data.end_date}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </Content>
                  </SubTitle>
                </CourseWrapper>
              )}
            </DetailsWrapper>
          ) : (
            <DetailsWrapper>
              <SubTitle>
                <EventBusy />
                <Content>
                  <p>Finalizado en</p>
                  <span>21/12/2021</span>
                </Content>
              </SubTitle>
              <Note>
                <StarsIconFake />
                <p>{data.nota}</p>
              </Note>
            </DetailsWrapper>
          )}
        </Body>
      </SubjectCardContainer>
    </>
  );
};

export default SubjectsCard;

const SubjectCardContainer = styled(Card)`
  display: flex;
  height: 220px;
  gap: 1rem;
  position: relative;
  border-radius: 20px;
  background-color: #ffffff;
  transition: 0.3s all ease;
  cursor: ${(p) => (p.notAvailable ? "default" : "pointer")};

  :before {
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0;

    background: ${(p) =>
      p.estado === "confirmado"
        ? "#5e80db80"
        : p.estado === "egresado"
        ? "#1E806580"
        : "#B75E2680"};
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    right: 0;
  }

  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    :before {
      opacity: 1;
    }
  }
`;

const WrapperNotAssignment = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;

  svg {
    font-size: 2rem;
    color: #fc2958;
  }
`;

const PhotoWrapper = styled.div`
  width: 30%;
`;

const ImgFake = styled.img`
  border-bottom-right-radius: 20px;
  position: absolute;
  width: 30%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  object-position: center;
`;

const Body = styled.div`
  display: flex;
  background-color: white;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  z-index: 3;
  width: 100%;
  border-top-left-radius: 20px;
  :before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: -50px;
    bottom: 0px;
    box-shadow: 22px 0 0px 0 white;
    border-bottom-right-radius: 25px;
  }
`;

const Label = styled.span`
  width: fit-content;
`;

const Plan = styled.h2`
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: capitalize;
  color: #222222;
  color: ${(p) => (p.notAvailable ? "#a8a8a8" : "#222222")};
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #3bd39c;
`;

const Percentage = styled.span`
  font-size: 0.9rem;
  color: #3bd39c;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  width: 285px;
`;

const CourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: max-content;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  svg {
    color: #a8a8a8;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  p {
    color: #a8a8a8;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.6rem;
  }
  span {
    color: #616161;
    font-size: 0.7rem;
    font-weight: bold;
  }
`;

const Note = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  p {
    color: #222222;
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

const StarsIconFake = styled(StarsIcon)`
  color: #a8a8a8;
  font-size: 1.4rem;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import ProgramCard from "../../molecules/profile/ProgramCard";
import ProgramsSkt from "../../../skeletons/ProgramsSkt";
import { Card, TextField, InputAdornment } from "@mui/material";
import ProgramFilter from "./ProgramFilter";
import NoResultsFound from "../../../common/NoResultsFound";
import { SearchRounded } from "@mui/icons-material";

const Programs = (props) => {
  const { report, programs } = props;

  // REDUX
  const loading = useSelector((state) => state.programs.listLoading);

  // STATES
  const [values, setValues] = useState(programs);
  const [currentState, setCurrentState] = useState("Todos");
  const [search, setSearch] = useState("");

  // EFFECT
  useEffect(() => {
    if (programs !== null) {
      setValues(
        programs.filter((program) => {
          return program.program.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [search, programs]);

  useEffect(() => {
    if (currentState === "Todos") {
      setValues(programs);
    }
    if (currentState === "En Curso") {
      setValues(
        programs.filter((value) =>
          value.from === "sis"
            ? value.academic_phase === "Cursando"
            : value.total_progress > 0 && value.total_progress < 100
        )
      );
    }
    if (currentState === "Egresado") {
      setValues(
        programs.filter((value) =>
          value.from === "sis"
            ? value.academic_phase === "Egresado"
            : value.total_progress === 100
        )
      );
    }
  }, [currentState]);

  // FUNCTION
  function handleCurrentState(newValue) {
    setCurrentState(newValue);
  }
  function handleChange(e) {
    setSearch(e.target.value);
  }

  // RETURN
  if (programs === null || loading) {
    return <ProgramsSkt />;
  }

  return (
    <Wrapper>
      {/* BUSCADOR */}
      <TextFieldFake
        value={search}
        onChange={handleChange}
        size="small"
        label=""
        variant="outlined"
        placeholder="Buscar programa..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
      <ProgramFilter
        currentState={currentState}
        handleCurrentState={handleCurrentState}
      />
      {/* TARJETAS DE PROGRAMAS */}
      <ProgramsContainer values={values?.length} report={report}>
        {values?.length === 0 || values === null ? (
          <NoResultWrapper>
            <NoResultsFound />
          </NoResultWrapper>
        ) : values.length === 1 && values[0].has_study_plan === false ? (
          <NoResultWrapper>
            <NoResultsFound />
          </NoResultWrapper>
        ) : (
          values.map((program, index) => (
            <ProgramCard program={program} key={index} report={report} />
          ))
        )}
      </ProgramsContainer>
    </Wrapper>
  );
};

export default Programs;

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const ProgramsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${(p) => (p.values === 0 ? "100%" : p.report ? "350px" : "280px")},
      1fr
    )
  );
  grid-gap: 2rem;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextFieldFake = styled(TextField)`
  width: fit-content;
`;

const NoResultWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

import * as types from "../types";

const initialState = {
  achievements: null,
  listLoading: false,
  errorList: null,
};

export const achievements = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        listLoading: true,
        errorList: null,
      };
    case types.GET_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        achievements: action.payload,
      };
    case types.GET_ACHIEVEMENTS_FAIL:
      return {
        ...state,
        listLoading: false,
        errorList: action.payload,
      };
    default:
      return state;
  }
};

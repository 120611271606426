import React from "react";
import { Box, Button, Modal } from "@mui/material";
import styled from "styled-components";
import CardModalUpselling from "../../molecules/upselling/CardModalUpselling";
import { useHistory } from "react-router-dom";

const ModalUpselling = (props) => {
  const { open, handleClose, upselling, handleOpenModalOpport, type } = props;

// para el type = "rutaFormat" se imprime el primer programa de la lista como DIPLOMADO 
// y el formulario de contacto (opp crm) se maneja con un selector de programas segun 
// el listado de id del archivo (public\mock\ecommerce.json)


// type = "oa" se define otro título y se imprimen unicamente los programas que trae vinculados el Objeto de Aprendizaje consultado. 
// El form de contacto (opp de crm) debe reunir un id de producto de MKT que venga desde el EP, 
// que asocie el crm_id del producto programa con el id de mkt, hasta entonces se envia el crm_id del programa 


  const { push } = useHistory();

  const openEducationalOffer = () => push("../oferta-educativa/");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxFake /*sx={style} */>
        <Wrapper>
          <Header>
            <HeaderChildren>
              <Image>
                <img src="../assets/upselling/image-training-route.svg" />
              </Image>
              { type === "rutaFormat" 
                ? <h2>¡Avanza en tu ruta formativa!</h2>
                : <h2>¡Avanza en tu aprendizaje!</h2>
              }
            </HeaderChildren>
            <HeaderChildren>
              <p>
                Al completar este curso, ya marcaste tu huella en estos
                programas ADEN. Chequea tu avance en cada uno y eleva tu
                potencial.
              </p>
              <ButtonFake
                fullWidth
                variant="contained"
                color="primary"
                onClick={openEducationalOffer}
              >
                Ver oferta completa
              </ButtonFake>
            </HeaderChildren>
          </Header>
          <CardContainer>

            {/* ruta formativa _ uppselling desde el detalle de producto (public\mock\ecommerce.json) */}
            { type === "rutaFormt" && 
                <CardModalUpselling
                  id={upselling?.id || ""}
                  name={upselling?.name || upselling?.oa?.name || ''}
                  image={upselling?.image || ""}
                  number={1}
                  progress={50}
                  type="DIPLOMADO"
                  buttonTitle="Comprar"
                  handleOpenModalOpport={handleOpenModalOpport}
                />
            }
            { type === "rutaFormat" && 
              upselling?.upselling && 
              upselling?.upselling.length > 0 && (
              <>
                {upselling?.upselling.map((values, index) => (
                  <CardModalUpselling
                    id={values?.id}
                    name={values.name}
                    number={index + 2}
                    progress={50 / (index + 2)}
                    buttonTitle="Solicitar información"
                    handleOpenModalOpport={handleOpenModalOpport}
                  />
                ))}
              </>
            )}

            {/* objetos de aprendizaje con upselling */}
            { type === "oa" &&  upselling?.programs && 
              upselling?.programs.length > 0 && (
                <>
                  {upselling?.programs.map((values, index) => (
                    <CardModalUpselling
                      id={values?.crm_id}
                      name={values.name}
                      number={index + 1}
                      progress={50 / (index + 2)}
                      buttonTitle="Solicitar información"
                      handleOpenModalOpport={handleOpenModalOpport}
                    />
                    ))
                  }
                </>
            )}

          </CardContainer>
        </Wrapper>
      </BoxFake>
    </Modal>
  );
};

export default ModalUpselling;

const BoxFake = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #f9f9f9;
  width: 80vw;
  height: 80vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  flex-basis: calc(20% - 1rem);
  background-color: #fff;
  display: flex;
  overflow: visible;
  height: calc(100% - 7rem);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
  padding: 3.5rem 2rem;
  flex-direction: column;
  width: calc(20% - 4rem);
  gap: 2rem;
  justify-content: center;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);

  h2 {
    color: #591AB6;
    font-weight: 900;
    font-size: 38px;
    line-height: 46px;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
  }

  @media (max-width: 1400px) {
    gap: 1rem;

    h2 {
      font-size: 28px;
      line-height: 36px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media (max-width: 1000px) {
    height: 150px;
    flex-direction: row;
    width: calc(100% - 4rem);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }

  @media (max-height: 768px) {
    padding: 2rem 1rem;
    height: calc(100% - 4rem);
    width: calc(20% - 2rem);

    h2 {
      font-size: 18px;
      line-height: 26px;
    }
    p {
      font-size: 0.8rem;
    }
    button {
      margin-top: 0.3rem;
      font-size: 0.8rem;
    }
  }
`;

const HeaderChildren = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (max-width: 1000px) {
    display: flex;
    gap: 1rem;
    width: 100%;

    h2 {
      text-align: center;
      font-size: 1.5rem;
    }
    div {
      img {
        width: 120px;
      }
    }
  }
`;
const Image = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const ButtonFake = styled(Button)`
  font-weight: 700;
`;

const CardContainer = styled.div`
  width: calc(100% - 20% - 8rem);
  height: calc(100% - 8rem);
  padding: 4rem 1rem 3.7rem 1rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  @media (max-width: 1400px) {
    height: calc(100% - 4rem);
    padding: 2rem 1rem 1.7rem 1rem;
  }

  @media (max-width: 1000px) {
    padding: 1rem;
    width: calc(100% - 3rem);
    height: 59%;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContactsFavs from "../../molecules/favorites/ContactsFavs";
import ResourcesFavs from "../../molecules/favorites/ResourcesFavs";
import WorkshopsFavs from "../../molecules/favorites/WorkshopsFavs";

const MainTabs = (props) => {
  const { tab, favs } = props;

  return (
    <MainWrapper>
      {tab === 0 && <ResourcesFavs favs={favs.recurso} />}
      {tab === 1 && <WorkshopsFavs workshops={favs.workshop} />}
      {tab === 2 && <ContactsFavs contacts={favs.contacto} />}
    </MainWrapper>
  );
};

export default MainTabs;

const MainWrapper = styled.div`
  padding: 1rem;
`;

import React from "react";
import styled from "styled-components";
import useTheme from "../../../../hooks/useTheme";

const ListCard = (props) => {
  const { primary } = useTheme();

  const { microlearning, currentMicrolearningId, handleCurrentMicrolearning } =
    props;
  return (
    <ListCardContainer
      onClick={() => handleCurrentMicrolearning(microlearning)}
      selected={microlearning.id === currentMicrolearningId}
    >
      <ImageWrapper>
        <FakeImg src={microlearning.image_url} />
      </ImageWrapper>
      <InfoWrapper>
        <NombrePublicacion color={primary}>
          {microlearning.publication_name}
        </NombrePublicacion>
        <AuthorWrapper>
          {
            typeof (microlearning?.acropolis_author) === "string" && (
              <>
                <Por>Por</Por>
                <Author color={primary}>
                  {
                    microlearning?.acropolis_author?.substring(
                      0,
                      microlearning?.acropolis_author?.indexOf(",")
                    )
                  }
                </Author>
              </>
            )
          }
        </AuthorWrapper>
      </InfoWrapper>
    </ListCardContainer>
  );
};

export default ListCard;

const ListCardContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#f3f6f9" : "transparent")};
  cursor: pointer;

  :hover {
    background-color: #f3f6f9;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
`;

const FakeImg = styled.img`
  width: 175px;
  height: 100px;
  object-fit: cover;
  object-position: center;

  @media (max-width: 425px) {
    width: 120px;
    height: 65px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const NombrePublicacion = styled.h1`
  font-size: 0.75rem;
  font-weight: 700;
  text-align: start;
  color: ${(p) => p.color};
`;

const AuthorWrapper = styled.div`
  display: flex;
  column-gap: 0.25rem;
  margin-top: auto;
`;

const Por = styled.span`
  font-size: 0.7rem;
`;

const Author = styled.span`
  font-size: 0.7rem;
  color: ${(p) => p.color};
`;

import React, { useState } from "react";
import styled from "styled-components";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { IconButton, Modal, Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { userAction } from "../../../../helpers/userActions.helper";

const SharedProfile = (props) => {
  const { modalIsOpen, handleCloseModal, link } = props;

  // STATE
  const [open, setOpen] = useState(false);

  // FUNCTIONS
  const handleTooltipOpen = () => {
    setOpen(true);
    handleSharedProfile("Link");
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleSharedProfile = (type) => {
    userAction({
      object_id: 0,
      object: "perfil",
      name: "Compartió su perfil",
      activity: "Compartir perfil",
      medio: type,
    });
  };

  return (
    <Modal open={modalIsOpen} onClose={handleCloseModal}>
      <SharedProfileContainer>
        {/** Close */}
        <CloseButtonWrapper>
          <IconButton
            onClick={handleCloseModal}
            style={{
              borderRadius: "10px",
              margin: 0,
              backgroundColor: "#f3f6f9",
            }}
            size="small"
          >
            <CloseRounded />
          </IconButton>
        </CloseButtonWrapper>
        {/** Socials */}
        <SocialsWrapper>
          <SocialsGrid>
            <FacebookShareButton
              url={link}
              onClick={() => handleSharedProfile("Facebook")}
            >
              <FacebookIcon round size={48} />
            </FacebookShareButton>
            <TwitterShareButton
              url={link}
              onClick={() => handleSharedProfile("Twitter")}
            >
              <TwitterIcon round size={48} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={link}
              onClick={() => handleSharedProfile("Linkedin")}
            >
              <LinkedinIcon round size={48} />
            </LinkedinShareButton>
            <WhatsappShareButton
              url={link}
              onClick={() => handleSharedProfile("Whatsapp")}
            >
              <WhatsappIcon round size={48} />
            </WhatsappShareButton>
          </SocialsGrid>
        </SocialsWrapper>
        {/** Link */}
        <LinkWrapper>
          <span>o copia el link</span>
          <LinkDiv>
            <input disabled value={link} />
            <CopyToClipboard text={link} onCopy={handleTooltipOpen}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado"
                placement="top"
              >
                <button>Copiar</button>
              </Tooltip>
            </CopyToClipboard>
          </LinkDiv>
        </LinkWrapper>
      </SharedProfileContainer>
      {/** Image */}
    </Modal>
  );
};

export default SharedProfile;

const SharedProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 4rem;
  width: max-content;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 5%;
  right: 5%;
`;

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: center;
`;

const SocialsText = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  color: #222222;

  span {
    font-weight: 400;
    font-size: 1.2rem;
    color: #616161;
  }
`;

const SocialsGrid = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
`;

const LinkDiv = styled.div`
  display: flex;
  background-color: #f3f6f9;
  padding: 0.5rem 1rem;
  border-radius: 8px;

  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: #9e9ea7;
    width: 100%;
  }

  button {
    background-color: transparent;
    color: #d1170d;
    width: max-content;
    padding-left: 1rem;
  }
`;

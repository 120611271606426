import React from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import HeaderInfo from "../../components/templates/profile/HeaderInfo";
import AboutMe from "../../components/ui/organisms/profile/AboutMe";
import Contact from "../../components/ui/organisms/profile/Contact";
import Socials from "../../components/ui/organisms/profile/Socials";
import MainProfile from "../../components/templates/profile/MainProfile";
import { titleHTML } from "../../helpers/title.helper";

const Profile = () => {
  titleHTML("Mi perfil");

  // REDUX
  const user = useSelector((state) => state.auth.user);

  return (
    <ProfileContainer>
      <HeaderInfo
        foto={user.foto}
        name={user.name}
        city={user.city}
        country={user.country.name}
        biography={user.biography}
        street={user.street === null ? false : user.street}
        zip={user.zip === null ? false : user.zip}
      />
      <Body>
        <BodyInfo>
          <AboutMe
            birthday={user.birthday}
            profession={user.profession.name}
            current_job={user.current_job.name}
            job_area={user.job_area.name}
            company={user.company}
          />
          <Contact
            phone={user.phone}
            mobile={user.mobile}
            work_email={user.work_email}
            personal_email={user.personal_email}
          />
          <Socials
            facebook={user.facebook}
            instagram={user.instagram}
            skype={user.skype}
            linkedin={user.linkedin}
            twitter={user.twitter}
            whatsapp={user.whatsapp}
          />
        </BodyInfo>
        <MainProfile />
      </Body>
    </ProfileContainer>
  );
};

export default Profile;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 3fr minmax(0, 8fr);
  grid-gap: 1.5rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

const BodyInfo = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Material UI
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const QuestionGeneric = (props) => {
  const { type, question, number, handleValueQuestion, handleDataQuestion } =
    props;

  const [value, setValue] = useState(false);
  const [valueCheck, setValueCheck] = useState([]);
  const [stateQuestion, setstateQuestion] = useState(false);

  useEffect(() => {
    if (value !== false || valueCheck.length !== 0) {
      handleValueQuestion(number);
      handleDataQuestion(stateQuestion);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, valueCheck]);

  const handleChangeRadio = (event) => {
    setValue(parseInt(event.target.value, 10));
    setstateQuestion({
      pregunta_id: question.pregunta_id,
      respuestas_usuario: [parseInt(event.target.value, 10)],
    });
  };

  const handleChangeCheck = (event) => {
    if (event.target.checked === true) {
      setValueCheck([...valueCheck, parseInt(event.target.value, 10)]);
      setstateQuestion({
        pregunta_id: question.pregunta_id,
        respuestas_usuario: [...valueCheck, parseInt(event.target.value, 10)],
      });
    } else {
      let result = valueCheck.filter((el) => {
        return el !== parseInt(event.target.value, 10);
      });
      setValueCheck(result);
      setstateQuestion({
        pregunta_id: question.pregunta_id,
        respuestas_usuario: result,
      });
    }
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };

  return (
    <Container id={`${number}element`}>
      <Header>
        <Title>
          <BookmarkBorderIcon /> Pregunta {number}
        </Title>
        <Value>
          {question.puntaje_pregunta}
          {question.puntaje_pregunta > 1 ? " pts" : " pt"}
        </Value>
      </Header>
      <Body>
        <Question>{parse(sanitizerHtml(question.pregunta))}</Question>
        <Answers>
          {type === "unica_opcion" && (
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChangeRadio}
            >
              {question.opciones.map((opt) => {
                return (
                  <FakeLabelRadio
                    value={opt.opcion_id}
                    control={<FakeRadio color={"primary"} />}
                    label={opt.opcion}
                  />
                );
              })}
            </RadioGroup>
          )}
          {type === "verdadero_falso" && (
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChangeRadio}
            >
              {question.opciones.map((opt) => {
                return (
                  <FakeLabelRadio
                    value={opt.opcion_id}
                    control={<FakeRadio color={"primary"} />}
                    label={opt.opcion}
                  />
                );
              })}
            </RadioGroup>
          )}
          {type === "multi_opcion" && (
            <CheckContainer onChange={handleChangeCheck}>
              {question.opciones.map((opt) => {
                return (
                  <FakeLabelCheck
                    control={
                      <FakeCheckbox
                        name={opt.opcion_id}
                        value={opt.opcion_id}
                        color={"primary"}
                      />
                    }
                    label={opt.opcion}
                  />
                );
              })}
            </CheckContainer>
          )}
        </Answers>
      </Body>
    </Container>
  );
};

export default QuestionGeneric;

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  margin: 1rem 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  padding: 0 15px;
  height: 50px;
  background-color: #f1f1f1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Title = styled.div`
  font-size: 1.2rem;
  color: #6b6b6b;
  font-weight: 700;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Value = styled.div`
  font-size: 1.2rem;
  color: #6b6b6b;
  font-weight: 700;
  padding: 0 0.5rem;
`;

const Question = styled.div`
  /* color: black; */
  /* font-size: 0.9rem; */
`;

const Answers = styled.div`
  width: 100%;
`;

const Body = styled.div`
  margin: 0 0 1rem 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 2rem;
  padding-bottom: 0;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
`;

const FakeRadio = styled(Radio)`
  transform: scale(0.7);
`;

const FakeLabelRadio = styled(FormControlLabel)`
  span {
    font-size: 0.8rem;
    padding-bottom: 1rem;
  }
`;

const FakeLabelCheck = styled(FormControlLabel)`
  span {
    font-size: 0.8rem;
  }
`;

const FakeCheckbox = styled(Checkbox)`
  transform: scale(0.7);
`;

const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

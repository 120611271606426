import React from "react";
import styled from "styled-components";

// Components
import FormTicketSubject from "./FormTicketSubject";

// Material UI
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalSubjectTicket = (props) => {
  const { open, handleClose } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxFake /*sx={style} */>
        <Header>
          <h3>Reportar una incidencia</h3>
          <CloseIcon onClick={() => handleClose()} />
        </Header>
        <FormTicketSubject handleClose={handleClose} />
      </BoxFake>
    </Modal>
  );
};

export default ModalSubjectTicket;

const BoxFake = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #e5e5e5;
  width: 50vw;
  height: 80vh;
`;

const Header = styled.div`
  background-color: #fff;
  display: flex;
  height: calc(70px - 2rem);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  padding: 1rem;
  width: calc(100% - 2rem);
  justify-content: space-between;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);

  h3 {
    color: #222222;
    font-size: 1.3rem;
  }

  svg {
    color: #a8a8a8;
    cursor: pointer;
    font-size: 1.8rem;
  }
`;

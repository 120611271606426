import { useEffect, useState } from "react";
import styled from "styled-components";
import Section from './components/Section'
import useCategoryThemes from "../../../../hooks/useCategoryThemes";
import RecommendedCoursesCard from "../../../../components/ui/molecules/recommended-courses/RecommendedCoursesCard";
import useRecommendedCourses from "../../../../hooks/useRecommendedCourses";

const RecommendedCourses = () => {

    const breakPoints = [
        { width: 500, itemsToShow: 1},
        { width: 1200, itemsToShow: 3 },
        { width: 1400, itemsToShow: 3 },
        { width: 1500, itemsToShow: 3.5 },
        { width: 1600, itemsToShow: 4 },
        { width: 1700, itemsToShow: 4.2 },
        { width: 1800, itemsToShow: 4.2 },
        { width: 2000, itemsToShow: 4.5 }
    ]

    const [activeTab, setActiveTab] = useState(0)

    const [activeArray, setActiveArray ] = useState(null)    

    const { thematicAxes } = useCategoryThemes();

    const { getRecommendedCoursesByCategoryName } = useRecommendedCourses();

    const [tabsArray, setTabsArray] = useState([])

    useEffect(() => {
        thematicAxes && setTabsArray(thematicAxes?.sort(() => Math.random() - 0.5).slice(0,5));
    }, [thematicAxes])

    useEffect(() => {
        setActiveArray(null)
        if (tabsArray[activeTab] ) {
            getCourses(tabsArray[activeTab]) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, tabsArray])

    const getCourses = async (name) => {
        try {
            const res = await getRecommendedCoursesByCategoryName(name)
            setActiveArray(res)
        } catch (e) {
            setActiveArray(false)
        }
    }

    return (
        <Container>
            <Section
                title={"Cursos recomendados para ti"}
                tabsArray={tabsArray}
                arrayLength={activeArray?.length ?? 0}
                array={activeArray}
                Card={RecommendedCoursesCard}
                cardWidth={320}
                breakPoints={breakPoints}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                to={"/mi-empresa/6?tab=2"}
            >
            </Section>
        </Container>
    )

}

export default RecommendedCourses;

const Container = styled.div`
    margin-bottom: 48px;
`


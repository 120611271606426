import { Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const CardArticles = (props) => {
    const { value, isSwiping } = props;

    const history = useHistory();

    // FUNCTIONS
    const handleClick = () => {
        if (!isSwiping) {
            if (value?.attributes?.link) {
                window.open(value?.attributes?.link);
            } else {
                history.push(`/blog/${value?.id}/${value?.attributes?.slug}`);
            }
        }
        
    };

    return (
        <ArticleCard onClick={handleClick} image={value?.attributes?.image || ""}>
            <Header
                img={
                    value?.attributes?.cover_link ||
                    `${process.env.REACT_APP_CMS_ADEN}${value?.attributes?.cover?.data?.attributes?.url}` ||
                    ""
                }
            />
            <Body>
                <TagsWrapper>
                    <>
                        {(value?.attributes?.tags?.length > 0 &&
                            value?.attributes?.tags
                                .slice(0, 2)
                                .map((tag, index) => (
                                    <Chip key={index}>{tag}</Chip>
                                ))) ||
                            ""}
                    </>
                </TagsWrapper>
                <Title>{value?.attributes?.title || ""}</Title>
                <p>{value?.attributes?.preview || ""}</p>
                <Span>
                    {new Date(value?.attributes?.publishedAt ?? value?.attributes?.createdAt).toLocaleString()}
                </Span>
            </Body>
        </ArticleCard>
    );
};

export default CardArticles;

const ArticleCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    width: 311px;
    height: 264px;
    border-radius: 20px;
    border-bottom: 8px solid #dcdcdc;

    img {
        scale: 1;
        transition: scale .5s ease-in-out;
    }
    :hover img {
        scale: 1.3;
    }

    :hover {
        border-bottom: 8px solid var(--primary-one); 
    }

    :hover section {
        background: linear-gradient(180deg, #FFFFFF 0%, var(--secondary-two) 167.5%);
    }
`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px 20px 0 0;
    width: calc(100% - 3rem);
    background-image: ${(p) => `url('${p.img}')`};
    background-size: cover;
    background-position: center center;
`;

const TagsWrapper = styled.div`
    position: absolute;
    top: 10px;
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
`;

const Chip = styled.div`
    width: calc(min-content - 0.6rem);
    height: calc(auto - 0.6rem);
    padding: 4px 8px;
    background-color: var(--primary-one);
    color: #fff;
    font-size: 12px;
    border-radius: 100px;
`;

const Body = styled.section`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    height: 200px;

    p {
        height: 60px;
        overflow: auto;
        scrollbar-color: transparent transparent;
        color: var(--primary-two);
        font-size: 0.93rem;

        &::-webkit-scrollbar {
            width: 1px;
        }
    }
`;

const Title = styled.h2`
    font-size: 16px;
    font-weight: 700;
    text-align: start;
    color: var(--primary-three);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`

const Span = styled.span`
    height: 14px;
    color: var(--primary-two);
    font-size: 0.9rem;

    span {
        font-weight: bold;
    }
`;

import { useState } from "react";

export const useVideo = () => {
  const [nextVideo, setNextVideo] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);
  const [playing, setPlaying] = useState(true);

  const handleStop = (playerRef) => {
    setNextVideo(false);
    setStopVideo(true);
    setPlaying(false);
    playerRef.current?.pause(true);
  };

  const handleStart = () => {
    setStopVideo(false);
    setPlaying(true);
  };

  return {
    nextVideo,
    setNextVideo,
    stopVideo,
    setStopVideo,
    playing,
    setPlaying,
    handleStop,
    handleStart,
  };
};

import { useState } from "react";
import OutsideClickHandler from "./OutsideClickHandler"
import { Icon } from '@iconify/react';
import Checkbox from "../ui/atoms/Checkbox";
import styled from "styled-components";
import SimpleLoading from "./SimpleLoading";


const Filter = (props) => {

  const {
    openFilter,
    setOpenFilter,
    menuFilter,
    handleOnChangeChecks,
    checkedState,
    handleApplyFilters,
    selectedOption,
    setSelectedOption,
    checkedStateAll,
    handleClearFilters,
    filterByStateActivated = true,
    filterByCategoryActivated = true
  } = props;

  const [statesOpen, setStatesOpen] = useState(false)
  const [themesOpen, setThemesOpen] = useState(false)

  // Filters with checks

  const handleOutsideClick = () => {
    openFilter && setOpenFilter(false)
  };

  const statesArray = [
    {
      label: "Todos",
      value: "todos"
    },
    {
      label: "En curso",
      value: "en curso"
    },
    {
      label: "Por iniciar",
      value: "por iniciar"
    },
    {
      label: "Finalizado",
      value: "finalizado"
    }
  ]

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <FilterContainer openFilter={openFilter}>
        <FilterTitle>
          Filtrar
        </FilterTitle>
        {
          filterByStateActivated &&
          <>
            <FilterSelect onClick={() => setStatesOpen(!statesOpen)}>
              Estado de cursado
              {
                statesOpen ? <Icon icon="ep:arrow-up-bold" /> : <Icon icon="ep:arrow-down-bold" />
              }
            </FilterSelect>
            <StatesContainer statesOpen={statesOpen}>
              {
                statesArray.map((item) => (
                  <StateLabel statesOpen={statesOpen}>
                    {item.label}
                    <StateRadio
                      type="radio"
                      value={item.value}
                      checked={selectedOption === item.value}
                      onChange={handleOptionChange}
                    />
                  </StateLabel>
                ))
              }
            </StatesContainer>
          </>
        }
        {
          filterByCategoryActivated &&
          <>
            <FilterSelect onClick={() => setThemesOpen(!themesOpen)}>
              Eje temático
              {
                statesOpen ? <Icon icon="ep:arrow-up-bold" /> : <Icon icon="ep:arrow-down-bold" />
              }
            </FilterSelect>
            <MainThemesContainer themesOpen={themesOpen}>
              <Checkbox
                value={"Todos"}
                checked={checkedStateAll}
                name={"Todos"}
                id={0}
                label={"Todos"}
                disabled={false}
              />
              {
                !menuFilter ? <SimpleLoading /> :
                menuFilter.map((item, index) => {
                  return (
                    <Checkbox
                      value={item.name}
                      checked={checkedState[index]}
                      onChange={() => handleOnChangeChecks(index)}
                      name={item.name}
                      id={item.id}
                      label={item.name}
                      disabled={false}
                    />

                  )
                })
              }
            </MainThemesContainer>
          </>
        }
        <FiltersButton onClick={() => {
          handleApplyFilters()
        }} color="var(--secondary-one)" backgroundColor="var(--primary-one)">
          Aplicar filtros
        </FiltersButton>
        <FiltersButton onClick={handleClearFilters} color="var(--primary-one)" backgroundColor="var(--secondary-one)">
          Limpiar filtros
        </FiltersButton>
      </FilterContainer>
    </OutsideClickHandler>
  )
}

export default Filter;

const FilterContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => props.openFilter ? "0" : "-450px"};
  border-radius: 20px;
  width: 350px;
  height: fit-content;
  background-color: var(--secondary-one);
  z-index: 1;
  box-shadow: 0px 18px 88px -4px #18274B24, 0px 8px 28px -6px #18274B1F;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 24px;
  padding: 32px;
  transition: right 1s ease-in-out;
`

const FilterTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
`

const StatesContainer = styled.div`
  width: 100%;
  height:${(p) => p.statesOpen ? "130px" : "0px"} ;
  display: flex;
  flex-direction: column;
  margin: -20px 0;
  transition: all .3s ease;
`

const FilterSelect = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid var(--secondary-two);
  cursor: pointer;
  svg {
    color: #a8a8a8;
  }
`

const MainThemesContainer = styled.div`
  width: 100%;
  height:${(p) => p.themesOpen ? "180px" : "0px"} ;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: -10px 0;
  transition: all .3s ease;
  overflow-y: scroll;
`

const StateLabel = styled.label`
  height: ${(p) => p.statesOpen ? "32px" : "0px"};
  width: 100%;
  display: ${(p) => p.statesOpen ? "flex" : "none"};
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  transition: all 1.6s ease;
`

const StateRadio = styled.input`
  width: 20px;
  height: 20px;
  /* Estilos para el radio button */
  appearance: none;
  width: 20px;
  height: 20px;
  border: 3px solid #a8a8a8; 
  border-radius: 50%; 
  outline: none;
  cursor: pointer;

  
  &:checked {
    background-color: transparent;
    border-color: var(--primary-one);
  }

  &:after {
    width: 0px;
    height: 0px;
    content: '';
  }

  &:checked:after {
    width: 10px;
    height: 10px;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    background-color: var(--primary-one);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
`

const FiltersButton = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${(p) => p.backgroundColor};
  color: ${(p) => p.color};
  cursor: pointer;
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import TabsComponent from "../../components/common/TabsComponent";
import TrainingRoutes from "./components/TrainingRoutes";
import CoursesBusiness from "./components/CoursesBusiness";
import CoursesAden from "./components/CoursesAden";
import CoursesAddiotional from "./components/CoursesAddiotional";
import SimpleLoading from "../../components/common/SimpleLoading";
import RegistrationModal from "../../components/templates/business/components/RegistrationModal";

// Hooks
import useBusiness from "../../hooks/business/useBusiness";
import useLayout from "../../hooks/useLayout";
import { useQueryParams } from "../../hooks/useQueryParams"

// Helpers
import { titleHTML } from "../../helpers/title.helper";

const Business = () => {
  titleHTML("Mi empresa");

  const businessData = useBusiness("mi-empresa");

  const {
    allThematic,
    tabs,
    /* title, */
    thematic,
    ondemandAcademic,
    openModal,
    setOpenModal,
    courseData,
    repo_id,
    ondemand_id,
    registrateUser,
    handleOpenModal,
    enabledTabs,
    loadingtabs,
  } = businessData;

  const { setMenu } = useLayout()
  const params = useQueryParams();
  const tabParam = params.get("tab");

  const [tabSelected, setTabSelected] = useState(6)

  useEffect(() => {
    setMenu("Mi empresa")
    if (tabParam) {
      setTabSelected(parseInt(tabParam))
    } else if (enabledTabs?.habilita_rutas_formativas) {
      setTabSelected(6)
    } else if(enabledTabs?.habilita_cursos_empresa) {
      setTabSelected(0)
    } else {
      setTabSelected(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledTabs])
  
  const handleSelectedTab = (e, value) => {
    setTabSelected(value);
  };

  if (!tabs) {
    return <SimpleLoading />;
  }

  /* setMenu(title) - */

  return (
    <BusinessContainer>
      <HeaderWrapper>
        {loadingtabs ? null : (
          <TabsComponent
            tab={tabSelected}
            handleTab={handleSelectedTab}
            tabs={tabs}
          />
        )}
      </HeaderWrapper>
      <BodyWrapper>
        {
          <RegistrationModal
            handleClick={registrateUser}
            data={courseData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            repoId={repo_id}
            ondemandId={ondemand_id}
          />
        }
        <ToggleMain
          allThematic={allThematic}
          thematic={thematic}
          tab={tabSelected}
          handleTab={handleSelectedTab}
          ondemandAcademic={ondemandAcademic}
          handleOpenModal={handleOpenModal}
        />
      </BodyWrapper>
    </BusinessContainer>
  );
};

export default Business;

function ToggleMain(props) {
  const {
    tab,
    thematic,
    allThematic,
    handleTab,
    ondemandAcademic,
    handleOpenModal,
  } = props;

  const cases = {
    0: (
      <CoursesBusiness
        handleTab={handleTab}
        thematic={thematic}
        handleOpenModal={handleOpenModal}
      />
    ),
    1: (
      <CoursesAden thematic={thematic} handleOpenModal={handleOpenModal} />
    ),
    2: (
      <CoursesAddiotional
        thematic={allThematic}
        ondemandAcademic={ondemandAcademic}
        handleOpenModal={handleOpenModal}
      />
    ),
    6: (
      <TrainingRoutes thematic={thematic} />
    ),
  };

  return cases[String(tab)] || <CoursesAden thematic={thematic} />;
}

const BusinessContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 20px;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  width: calc(100% - 6rem - 70px);
  position: fixed;
  left: 70px;
  top: 70px;
  z-index: 10;
  border-top: 1px solid #f7f7f7;
  padding: 1rem 3rem 0 3rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    left: 0;
    width: calc(100% - 6rem);
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-radius: 20px;
  margin-top: 2rem;
`;
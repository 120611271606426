import * as types from "../types";

const actions = {};

actions.getWorkshopsRequest = () => {
  return {
    type: types.GET_WORKSHOPS_REQUEST,
  };
};

actions.getWorkshopsSuccess = (workshops) => {
  return {
    type: types.GET_WORKSHOPS_SUCCESS,
    payload: workshops,
  };
};

actions.getWorkshopsFail = (error) => {
  return {
    type: types.GET_WORKSHOPS_FAIL,
    payload: error,
  };
};

// GET WORKSHOP BY ID
actions.getWorkshopByIdRequest = (id) => {
  return {
    type: types.GET_WORKSHOPBYID_REQUEST,
    payload: id,
  };
};

actions.getWorkshopByIdSuccess = (workshop) => {
  return {
    type: types.GET_WORKSHOPBYID_SUCCESS,
    payload: workshop,
  };
};

actions.getWorkshopByIdFail = (error) => {
  return {
    type: types.GET_WORKSHOPS_FAIL,
    payload: error,
  };
};

// Likes
actions.setLikes = (payload) => {
  return {
    type: types.SET_LIKE_WS,
    payload: payload,
  };
};

actions.setNoLikes = (payload) => {
  return {
    type: types.SET_NOLIKE_WS,
    payload: payload,
  };
};

export { actions };

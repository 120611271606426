export const randomArray = () => {
  // eslint-disable-next-line no-extend-native
  Array.prototype.random = function () {
    var currentIndex = this.length,
      temporaryValue,
      randomIndex;

    // Mientras queden elementos a mezclar...
    while (0 !== currentIndex) {
      // Seleccionar un elemento sin mezclar...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // E intercambiarlo con el elemento actual
      temporaryValue = this[currentIndex];
      this[currentIndex] = this[randomIndex];
      this[randomIndex] = temporaryValue;
    }

    return this;
  };
};

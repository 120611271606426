import React from "react";
import { SearchRounded } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import styled from "styled-components";

const DefaultSearch = (props) => {
    const { search, handleChange, label = "Buscar", disabled = false } = props;

    return (
        <TextFieldFake
            value={search}
            onChange={handleChange}
            size="small"
            label=""
            variant="outlined"
            placeholder={label}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default DefaultSearch;

const TextFieldFake = styled(TextField)`
    width: fit-content;
    background-color: #f9f9f9;
    border-radius: 5px;
    .MuiInputBase-root{
        color: #A8A8A8;
        font-weight: 500;
    }
    fieldset{
        border: 1px solid #f9f9f9 !important;
    }
    svg{
        fill: #c7c7c7;
        font-size: 22px;
    }
`;

import React from "react";
import styled from "styled-components";

import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";

const DefaultPhone = (props) => {
  const {
    name,
    label,
    value,
    setFieldValue,
    styled = { width: "100%", borderColor: "inherit" },
  } = props;

  // RETURN
  return (
    <Field
      as={PhoneInput}
      name={name}
      value={value}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
      }}
      helperText={<ErrorMessage name={name} component={CustomError} />}
      country={"ar"}
      countryCodeEditable={false}
      autoFormat={true}
      specialLabel={label}
      inputStyle={styled}
      {...props}
    />
  );
};

export default DefaultPhone;

const CustomError = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: #591AB6;
`;

import React from "react";
import styled from "styled-components";

// Components
import ModalSubjectTicket from "./subjectDescriptions/ModalSubjectTicket";

// Material UI
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CastForEducation from "@mui/icons-material/CastForEducation";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";
import SignalIcon from "@mui/icons-material/SignalCellularAlt";
import AccessTime from "@mui/icons-material/AccessTime";

const SubjectDescriptions = (props) => {
  const { descr, subject } = props;

  //STATES
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const about = {
    total_enrolled: subject?.total_enrolled,
    duration: subject.duration ? subject.duration : "3 semanas",
    modality: subject.modality ? subject.modality : "100% Online",
    certificate: "Obtén tu certificado al finalizar",
    networking: "Conéctate con la red de alumnos ADEN",
    level: subject.level ? subject.level : "Inicial",
    asyn_ws: subject?.courses?.length,
  };

  return (
    <WrapperInstructors>
      <Wrapper>
        <CardGeneric type={true}>
          <h2>¡Te damos la bienvenida al curso!</h2>
          <p>{descr.description}</p>
        </CardGeneric>
        <MoreInfo>
          <CardAbout>
            <div>
              <VerifiedUserIcon /> Certificado
            </div>
            <h3>{about.certificate}</h3>
          </CardAbout>
          <CardAbout>
            <div>
              <RecordVoiceOverIcon /> Networking
            </div>
            <h3>{about.networking}</h3>
          </CardAbout>
          <CardAbout>
            <div>
              <LanguageIcon /> Modalidad
            </div>
            <h3>{about.modality}</h3>
          </CardAbout>
        </MoreInfo>
      </Wrapper>
      <Aside>
        <Aside>
          <CardAbout>
            <div>
              <SignalIcon /> Nivel
            </div>
            <h3>{about.level}</h3>
          </CardAbout>
          <CardAbout>
            <div>
              <AccessTime /> Duración
            </div>
            <h3>{about.duration}</h3>
          </CardAbout>
          <CardAbout>
            <div>
              <CastForEducation /> Talleres sincrónicos
            </div>
            <h3>{about.asyn_ws}</h3>
          </CardAbout>
          <CardAbout>
            <div>
              <PersonIcon /> Alumnos activos
            </div>
            <h3>{about.total_enrolled}</h3>
          </CardAbout>
        </Aside>
        <CardGeneric>
          <h2>¿Tienes problemas durante el cursado?</h2>
          <p>
            En caso de que encuentres un problema durante el cursado, no cargue
            algún video, no puedas ver tus notas o tengas cualquier otro
            inconveniente, te dejamos este espacio para que puedas brindarnos
            mayor información y así poder darte una solución.
          </p>
          <ButtonFake onClick={handleOpen} color="primary" variant="contained">
            Reportar una incidencia
          </ButtonFake>
        </CardGeneric>
      </Aside>
      <ModalSubjectTicket open={open} handleClose={handleClose} />
    </WrapperInstructors>
  );
};

export default SubjectDescriptions;

const WrapperInstructors = styled.div`
  width: calc(100% - 4rem);
  padding: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2.5fr 1fr;

  @media (max-width: 1200px) {
    gap: 1rem;
    grid-template-columns: 2fr 1.5fr;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: calc(100% - 4rem);
    justify-content: center;
    align-items: center;
  }
`;
const CardGeneric = styled.div`
  width: calc(100% - 4rem);
  height: fit-content;
  border-radius: 20px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  padding: 2rem;

  h2 {
    color: #222222;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
  p {
    color: #222222;
    font-size: 1rem;
    line-height: 150%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonFake = styled(Button)`
  margin-top: 1rem;
`;

const Aside = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const CardAbout = styled.div`
  width: calc(100% - 4rem);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;

  div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #a8a8a8;
    font-size: 1.1rem;
    svg {
      color: #a8a8a8;
      font-size: 1.2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: #222222;
    font-weight: 400;
  }
`;

const MoreInfo = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
`;

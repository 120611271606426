import React, { useState } from "react";
import styled from "styled-components";

const SurveyFeedback = (props) => {
  const { surveyLink } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container open={open}>
      <ButtonText onClick={handleOpen}>
        Feedback
        <ExpandIcon open={open} className="material-icons-round">
          expand_less
        </ExpandIcon>
      </ButtonText>
      <EmbedContainer open={open}>
        <Iframe
          src="https://www.questionpro.com/a/TakeSurvey?tt=7rqe3rhpH0E%3D&segmentCode=PS&responseCheck=false&isCXIntercept=true&isMobile=true&custom201=https%3A%2F%2Fwww.questionpro.com%2Fqp_userimages%2Fsub-3%2F4154528%2Fintercept%2F2921.html&rand=0.906114263044052"
          frameBorder="0"
          loading="lazy"
        ></Iframe>
      </EmbedContainer>
    </Container>
  );
};

export default SurveyFeedback;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  row-gap: 0px;
  width: max-content;

  position: fixed;
  margin-top: 10%;
  right: ${(p) => (p.open ? "0px" : "-400px")};
  transition: all 400ms ease-in;

  z-index: 999999;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    left: 1rem;
    right: auto;
    magin-top: 0px;
    bottom: ${(p) => (p.open ? "0px" : "-70vh")};
  }
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonText = styled.div`
  position: relative;
  top: 50px;
  right: -50px;
  color: #ffffff;
  background-color: #591AB6;
  transform: rotate(-90deg);
  justify-self: flex-end;
  padding: 0.5rem 1rem;

  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin: 0px;
  /* Rotate from top left corner (not default) */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: min-content;
  height: min-content;
  border-radius: 5px 5px 0 0;
  transition: all ease-in 400ms;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    position: unset;
    transform: none;
    left: 0;
    right: auto;
  }
`;

const ExpandIcon = styled.span`
  color: #ffffff;
  font-size: 18px;
  transform: ${(p) => (p.open ? `rotate(-180deg)` : `none`)};
  transition: all 400ms ease-in;
`;

const EmbedContainer = styled.div`
  height: 600px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(p) => (p.open ? `0px 5px 10px rgba(0, 0, 0, 0.1)` : `none`)};
  margin: 0px;
  padding: 0px;
  transition: all 400ms ease-in;
  scrollbar-width: thin !important;

  @media screen and (max-width: 768px) {
    height: 70vh;
    width: 90vw;
  }
`;

const Iframe = styled.iframe`
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 5px;
  scrollbar-width: thin !important;

  @media screen and (max-width: 768px) {
    border-radius: 0 5px 0 0;
  }
`;

import * as types from "../types/my_course_routes";

const actions = {};

actions.getCoursesTabsRequest = (payload) => {
  return {
    type: types.GET_MY_COURSE_ROUTES_REQUEST,
    payload,
  };
};

actions.getCoursesTabsSuccess = (tabs) => {
  return {
    type: types.GET_MY_COURSE_ROUTES_SUCCESS,
    payload: tabs,
  };
};

actions.getCoursesTabsFail = (error) => {
  return {
    type: types.GET_MY_COURSE_ROUTES_FAIL,
    payload: error,
  };
};

export { actions };

import * as types from "../types/alerts_types";

const initialState = {
  isOpen: false,
  variant: null,
  message: null,
  log: null,
};

export const alerts = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACK:
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        variant: action.payload.variant,
        log: new Date(),
      };
    case types.CLOSE_SNACK:
      return {
        ...state,
        isOpen: false,
        message: "",
        variant: action.payload,
        log: null,
      };
    default:
      return state;
  }
};

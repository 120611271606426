import React, { useState } from "react";
import styled from "styled-components";

// Components
import Button from "../../../../../components/ui/atoms/Button";
import QuestionGeneric from "./questions/QuestionGeneric";
import WarningMessage from "./questions/WarningMessage";
import ModalDescription from "./ModalDescription";

// Material UI
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const PageExam = (props) => {
  const { evaluation, handleDataQuestion, handleSendExam } = props;

  // STATES
  const [cicleSelected, setCicleSelected] = useState([]);
  const [open, setOpen] = React.useState(false);

  // FUNCTIONS
  const handleValueQuestion = (value) => {
    let state = true;
    if (cicleSelected.length > 0) {
      cicleSelected.forEach((val) => {
        if (val === value) {
          state = false;
        }
      });
      if (state === true) {
        setCicleSelected((state) => [...state, value]);
        return null;
      }
    }
    if (state === true) {
      setCicleSelected((state) => [...state, value]);
    }
  };

  const handleFocus = (index) => {
    const number = index + 1;
    const element = document.getElementById(`${number}element`);

    element.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  return (
    <WrapperExam>
      <NavExam>
        <QuestionsCount>
          {evaluation.preguntas.map((question, index) => {
            return (
              <CircleQuestion
                onClick={() => handleFocus(index)}
                status={
                  cicleSelected.includes(index + 1) === true ? true : false
                }
              >
                {index + 1}
              </CircleQuestion>
            );
          })}
        </QuestionsCount>
        <BtnDescription>
          <ModalDescription
            setOpen={setOpen}
            open={open}
            description={evaluation.descripcion}
          />
        </BtnDescription>
        <Time>
          <IconClock />
          <SpanExam>
            {evaluation.tiempo === false
              ? "SIN LÍMITE"
              : `${evaluation.tiempo} min`}
          </SpanExam>
        </Time>
      </NavExam>
      <WrapperQuestions>
        {evaluation.preguntas.map((q, index) => {
          return (
            <QuestionGeneric
              handleDataQuestion={handleDataQuestion}
              type={q.tipo}
              handleValueQuestion={handleValueQuestion}
              number={index + 1}
              question={q}
            />
          );
        })}
        <Center>
          <WarningMessage />
        </Center>
        <ContainerButton>
          <Button
            onClick={handleSendExam}
            variant="contained"
            color="primary"
            disabled={
              evaluation.preguntas.length - cicleSelected.length === 0
                ? false
                : true
            }
          >
            Finalizar intento
          </Button>
        </ContainerButton>
      </WrapperQuestions>
    </WrapperExam>
  );
};

export default PageExam;

const WrapperExam = styled.div`
  width: 100%;
  height: auto;
`;

const NavExam = styled.nav`
  padding: 0 18px;
  width: calc(100% - 36px);
  height: 80px;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionsCount = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: center;
`;

const CircleQuestion = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === true ? "#b21f17" : "#c9c9c9"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.status === true ? "white" : "#3d3d3d")};
  margin-right: 0.4rem;
  font-weight: 700;
  cursor: pointer;
`;

const BtnDescription = styled.div``;

const Time = styled.div`
  width: 10%;
  height: 100%;
  padding: 0 1rem;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const IconClock = styled(AccessAlarmIcon)`
  color: #616161;
  font-size: 24px;
`;

const SpanExam = styled.span`
  font-size: 0.7rem;
  color: #616161;
  font-weight: 600;
  font-size: 16px;
`;

const WrapperQuestions = styled.div`
  width: calc(100% - 30px);
  min-height: 450px;
  max-height: 600px;
  overflow-y: auto;
  padding: 15px;
`;

const ContainerButton = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

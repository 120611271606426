import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import SimpleLoading from "../../../common/SimpleLoading";
import {
  interestsActions,
  motivationsActions,
} from "../../../../redux/actions";
import { Chip } from "@mui/material";

const InterestsMotivationsPublic = (props) => {
  const { motivations: userMotivations, interests: userInterests, rol } = props;

  // REDUX
  const dispatch = useDispatch();
  const motivations = useSelector((state) => state.motivations.motivations);
  const listLoadingMotivations = useSelector(
    (state) => state.motivations.listLoading
  );
  const interests = useSelector((state) => state.interests.interests);
  const listLoadingInterests = useSelector(
    (state) => state.interests.listLoading
  );

  // STATE
  const [motivationsShow, setMotivationsShow] = useState(null);
  const [interestsShow, setInterestsShow] = useState(null);

  // EFFECTS
  useEffect(() => {
    if (motivations === null) {
      dispatch(motivationsActions.getMotivationsRequest());
    }
    if (interests === null) {
      dispatch(interestsActions.getInterestsRequest());
    }
  }, []);

  useEffect(() => {
    if (motivations !== null) {
      setMotivationsShow(
        motivations.filter((x1) => userMotivations.some((x2) => x1.id === x2))
      );
    }
  }, [userMotivations, motivations]);

  useEffect(() => {
    if (interests !== null) {
      setInterestsShow(
        interests.filter((x1) => userInterests.some((x2) => x1.id === x2))
      );
    }
  }, [userInterests, interests]);

  // RETURN
  if (
    motivations === null ||
    listLoadingMotivations ||
    motivationsShow === null ||
    interests === null ||
    listLoadingInterests ||
    interestsShow === null
  ) {
    return <SimpleLoading padding="4rem" />;
  }
  return (
    <InterestsMotivationsPublicContainer>
      <Title>Motivaciones</Title>
      <MotivationsWrapper>
        {motivationsShow.map((motivationShow) => (
          <FakeChip
            label={motivationShow.name}
            key={motivationShow.id}
            color="primary"
            size="small"
            rol={rol}
          />
        ))}
      </MotivationsWrapper>
      <Title>Intereses</Title>
      <InterestsGrid>
        {interestsShow.map((interestShow) => (
          <InterestWrapper key={interestShow.id} rol={rol}>
            <InterestImage
              rol={rol}
              src={`data:image/svg+xml;base64, ${interestShow.icon}`}
            />
            <InterestName>{interestShow.name}</InterestName>
          </InterestWrapper>
        ))}
      </InterestsGrid>
    </InterestsMotivationsPublicContainer>
  );
};

export default InterestsMotivationsPublic;

const InterestsMotivationsPublicContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: #222222;
  padding: 0.5rem;
`;

const MotivationsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  flex-wrap: wrap;
`;

const FakeChip = styled(Chip)`
  padding: 1.5rem;
  background-color: ${(p) => (p.rol === "teacher" ? "#FFF5F5" : "#591AB6")};
  color: ${(p) => (p.rol === "teacher" ? "#591AB6" : "#ffffff")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  border: ${(p) => (p.rol === "teacher" ? "1px solid #F1F1F1" : "none")};
  border-radius: 20px;
`;

const InterestsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.5rem;
`;

const InterestWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 0;
  color: #616161;
  background-color: ${(p) => (p.rol === "teacher" ? "#ffffff" : "#ffd7d7")};
  border: ${(p) => (p.rol === "teacher" ? "1px solid #F1F1F1" : "none")};
  border-radius: ${(p) => (p.rol === "teacher" ? "10px" : "0")};
  /* @media (max-width: 768px) {
        flex-direction: column;
    } */
`;

const InterestImage = styled.img`
  filter: ${(p) =>
    p.rol === "teacher"
      ? "invert(12%) sepia(63%) saturate(887%) hue-rotate(349deg) brightness(73%) contrast(165%)"
      : "none"};
`;

const InterestName = styled.span`
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
`;

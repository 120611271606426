import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { chatActions } from "../actions";
import { getPartnerData } from "../api/chat";

function* getPartnerRequest(action) {
  try {
    const res = yield call(getPartnerData, action.payload);
    yield put(chatActions.getPartnerDataSuccess(res.data.response_data));
  } catch (error) {
    yield put(chatActions.getPartnerDataFail(error));
  }
}

function* chatWatcher() {
  yield takeEvery(types.GET_PARTNER_CHAT_REQUEST, getPartnerRequest);
}

export { chatWatcher };

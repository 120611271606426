import * as React from "react";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
// import Stack from '@mui/material/Stack';

const GenericChips = (props) => {
  const {
    title = "Chip genérico",
    color = "#fff",
    bgColor = "#fff",
    padding = ".5rem",
    fontSize = "1rem",
    radius = "5px",
    textColor = "#000",
    fontWeight = false,
    textTransform = "lowercase",
    width = "min-content"
  } = props;

  return (
    <ChipFake
      textTransform={textTransform}
      fontWeight={fontWeight}
      radius={radius}
      fontSize={fontSize}
      bg={color === "#fff" ? bgColor : color}
      padding={padding}
      label={title}
      textColor={textColor}
      width={width}
    />
  );
};

export default GenericChips;

const ChipFake = styled(Chip)`
  background-color: ${(p) => p.bg};
  height: 20px;
  border-radius: ${(p) => p.radius};
  text-transform: lowercase;
  text-transform: ${(p) => p.textTransform};
  width: ${(p) => p.width};
  span {
    font-size: ${(p) => p.fontSize};
    padding: ${(p) => p.padding};
    color: ${(p) => p.textColor};
    font-weight: ${(p) => p.fontWeight};
  }
`;

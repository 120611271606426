import React from "react";

import RatingOption from "../../ui/organisms/dracma/RatingOption";
// import BonusOption from "../../ui/organisms/dracma/BonusOption";
import RatingBusinessOption from "../../ui/organisms/dracma/RatingBusinessOption";

const OptionWrapper = (props) => {
  const { currentTab } = props;
  // RETURN
  if (currentTab === 0) {
    return <RatingOption />;
  }

  if (currentTab === 1) {
    return <RatingBusinessOption />;
  }

  return null;
};

export default OptionWrapper;

import { Button, Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import cert from "../../../../assets/media/aden/certificado-scorm.svg";
import { Img } from "../../../common/Image";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import useMyCertificate from "../../../../hooks/useMyCertificate";

const DialogCertificate = (props) => {
    const { course, setOpenTest, type } = props;
    const firstCourse = course;
    const { publication_name, id } = firstCourse;

    const [open, setOpen] = useState(true);
    const { repo_id } = useSelector((state) => state.auth.user);

    const { generateBadge } = useMyCertificate(
        firstCourse.nombre_publicacion,
        firstCourse.generar_insignia,
        firstCourse.id
    );

    const onClose = () => setOpen(false);
    const handleOpenCertificate = () => {
        generateBadge();

        window.open(
            `https://constancias.aden.org/?idcod=OA-${id}-${repo_id}-ADEN2022/`,
            "_blank"
        );
    };

    const handleModal = () => {
        setOpenTest(true);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <Content>
                <CloseButton onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
                <Img w="280px" src={cert} />
                <Text fontSize="24px" fontWeight="bold">
                    ¡Felicitaciones!
                </Text>
                <Text textAlign="center" color="#616161" fontSize="14px">
                    Completaste el curso{" "}
                    {publication_name
                        ? publication_name
                        : firstCourse.nombre_publicacion}{" "}
                    y ya está listo tu certificado. ¡Recuerda compartirlo con tu
                    comunidad!
                </Text>
                <Button
                    onClick={handleOpenCertificate}
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "var(--primary-one)"}}
                >
                    Ver certificado
                </Button>
                {type === "demo" ? (
                    <Button fullWidth onClick={handleModal} color="primary">
                        ¡Avanzar en mi ruta formativa!
                    </Button>
                ) : repo_id.ondemand_id !== null &&
                  type === "workshop" ? null : (
                    <Button fullWidth onClick={handleModal} color="primary">
                        ¡Avanzar en mi ruta formativa!
                    </Button>
                )}
            </Content>
        </Dialog>
    );
};

export default DialogCertificate;

const Content = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    position: relative;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 15px;
    right: 15px;
`;

import React from "react";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InterestsChips = (props) => {
  const { id, name, checked, value, values, setFieldValue } = props;

  const handleCheck = (id) => {
    if (checked) {
      let newvalues = values.intereses.filter((x) => x !== id);
      setFieldValue("intereses", newvalues);
    } else {
      setFieldValue("intereses", [...values.intereses, id]);
    }
  };

  return (
    <Wrapper checked={checked} onClick={() => handleCheck(id)}>
      <Text>
        {name} {value}
        <SpanFake checked={checked}>
          <CheckCircleIconFake />
        </SpanFake>
      </Text>
    </Wrapper>
  );
};

export default InterestsChips;

const Wrapper = styled.div`
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  width: auto;
  height: 40px;
  overflow: hidden;
  padding: 0 1.8rem 0 0.2rem;
  padding: ${(p) => (p.checked ? "0 1.6rem 0 0.4rem" : "0 1rem")};
  display: flex;
  justify-content: center;
  background-color: ${(p) => (p.checked ? "#F7FDFB" : "#ffffff")};
  align-items: center;
  color: ${(p) => (p.checked ? "#222222" : "#616161")};
  box-shadow: ${(p) =>
    p.checked
      ? "0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)"
      : "none"};
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    margin-top: -0.3rem;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
      0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    color: #222222;
  }
`;

const Text = styled.p`
  position: relative;
`;

const SpanFake = styled.span`
  position: absolute;
  display: ${(p) => (p.checked ? "block" : "none")};
  top: 1.6px;
  right: -20px;
  right: ${(p) => (p.checked ? "-20px" : "100px")};
`;

const CheckCircleIconFake = styled(CheckCircleIcon)`
  color: #3bd39c;
  font-size: 1.2rem;
`;

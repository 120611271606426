import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {
    ReportProblemRounded,
    ShareOutlined,
    ThumbDownRounded,
    ThumbUpRounded,
} from "@mui/icons-material";
import SharedResource from "../../common/SharedResource";
import ModalProblem from "../../common/ModalProblem";
import { viewResource } from "../../../redux/api/courses";
import { useDispatch, useSelector } from "react-redux";
import {
    getCountLikes,
    isMyLike,
    postLikes,
} from "../../../redux/api/microlearnings";
import { postFav, postTakeOutFav } from "../../../redux/api/utils";
import { microlearningsActions } from "../../../redux/actions";
import { Favorite } from "@mui/icons-material";

const Video = (props) => {
    const { currentMicrolearning } = props;

    // STATE
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isViewVideo, setIsViewVideo] = useState(false);
    const [durationVideo, setDurationVideo] = useState(0);
    const [likes, setLikes] = useState(false);
    const [myLikes, setMyLikes] = useState(false);
    const [fav, setFav] = useState(false);
    const [currentVideo, setCurrentVideo] = useState({});

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isViewVideo === true && currentMicrolearning.viewed === false) {
            submitViewResource();
        }
    }, [isViewVideo]);

    useEffect(() => {
        countLikes(currentMicrolearning.repo_id);
        getMyLike(currentMicrolearning.repo_id);
        setCurrentVideo(currentMicrolearning.video_url);
    }, [currentMicrolearning, currentMicrolearning.video_url]);

    useEffect(() => {
        setFav(currentMicrolearning.favorito);
    }, [currentMicrolearning.favorito]);

    // FUNCTIONS
    function handleOpenModal() {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: "microcontent",
            type: "recurso",
            id: currentMicrolearning.repo_id,
            nombre_publicacion: currentMicrolearning.nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    function handleOpenProblem() {
        setIsOpen(true);
    }

    function handleCloseProblem() {
        setIsOpen(false);
    }

    const handleFinishVideo = (e) => {
        const { playedSeconds } = e;
        let progress = 0;
        if (durationVideo > 0) {
            progress = Math.round((playedSeconds * 100) / durationVideo);
            if (progress > 50) {
                setIsViewVideo(true);
            }
        }
    };

    const countLikes = async (id) => {
        setLikes(false);
        const request = await getCountLikes(id);
        setLikes(request);
    };

    const handleLikes = async (type) => {
        await postLikes(
            currentMicrolearning.repo_id,
            type ? "like" : "dislike"
        );
        countLikes(currentMicrolearning.repo_id);
        getMyLike(currentMicrolearning.repo_id);
    };

    const getMyLike = async (id) => {
        setMyLikes(false);
        const request = await isMyLike(id);
        setMyLikes(parseInt(request?.calificacion, 10) || false);
    };

    const submitViewResource = async () => {
        const request = await viewResource({
            user: user.repo_id,
            resource: currentMicrolearning.repo_id,
        });
        if (request.error) return;
    };

    const handleFav = () => (!fav ? setFavorite() : setTakeOut());

    const setFavorite = async () => {
        const req = await postFav({
            resource: currentMicrolearning.repo_id,
            repo_id: user.repo_id,
            type: "recurso",
        });
        if (!req.error) {
            setFav(true);
            handleStateRedux(currentMicrolearning.repo_id, "like");
        }
    };

    const setTakeOut = async () => {
        const req = await postTakeOutFav({
            resource: currentMicrolearning.repo_id,
            repo_id: user.repo_id,
            type: "recurso",
        });
        if (!req.error) {
            setFav(false);
            handleStateRedux(currentMicrolearning.repo_id, "no-like");
        }
    };

    const handleStateRedux = (id, type) => {
        dispatch(
            type === "like"
                ? microlearningsActions.setLikes(id)
                : microlearningsActions.setNoLikes(id)
        );
    };

    // RETURN
    return (
        <VideoContainer>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            <ModalProblem
                isOpen={isOpen}
                handleCloseModal={handleCloseProblem}
                idCurrentMedia={currentMicrolearning.repo_id}
            />
            <VideoWrapper>
                {currentMicrolearning.categoria === 45 ? (
                    <IframeFake src={currentMicrolearning.video_url} />
                ) : (
                    <ReactPlayerFake
                        url={currentVideo}
                        controls={true}
                        width="100%"
                        height="100%"
                        playsinline
                        onDuration={(e) => setDurationVideo(e)}
                        config={{
                            file: {
                                attributes: {
                                    poster: `${currentMicrolearning.image_url}`,
                                },
                            },
                        }}
                        onProgress={handleFinishVideo}
                    />
                )}
            </VideoWrapper>
            <Options>
                <RatingWrapper>
                    {!!likes && (
                        <>
                            <FakeButton
                                selected={myLikes === 10 ? true : false}
                            >
                                <ThumbUpRounded
                                    onClick={() => handleLikes(true)}
                                    color="secondary"
                                    fontSize="small"
                                />
                                <span>{likes.likes}</span>
                            </FakeButton>
                            <FakeButton selected={myLikes === 1 ? true : false}>
                                <ThumbDownRounded
                                    onClick={() => handleLikes(false)}
                                    color="secondary"
                                    fontSize="small"
                                />
                                <span>{likes.dislikes}</span>
                            </FakeButton>
                        </>
                    )}

                    <FakeButton
                        selected={fav}
                        title="Marcar como favorito"
                        onClick={handleFav}
                    >
                        <Favorite color="primary" />
                        <span>Favorito</span>
                    </FakeButton>
                    <FakeButton onClick={handleOpenModal}>
                        <ShareOutlined color="secondary" fontSize="small" />
                        <span>Compartir</span>
                    </FakeButton>
                </RatingWrapper>
                <FakeButton onClick={handleOpenProblem}>
                    <ReportProblemRounded color="secondary" fontSize="small" />
                    <span>Reportar</span>
                </FakeButton>
            </Options>
        </VideoContainer>
    );
};

export default Video;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
`;

const ReactPlayerFake = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const Options = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
`;

const RatingWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const FakeButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.1rem;
    cursor: pointer;

    span {
        font-size: 0.75rem;
    }

    svg,
    span {
        color: ${(props) => (props.selected ? "#591AB6" : "#bfbfbf")};
    }

    :hover {
        svg {
            color: #591ab6;
        }
        span {
            color: #591ab6;
        }
    }
`;

const IframeFake = styled.iframe`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Route, Link } from "react-router-dom";
import styled from "styled-components";

const CustomBreadcrumbs = (props) => {
  const { title } = props;

  function transformPath(string) {
    let stringUppercase = string.charAt(0).toUpperCase() + string.slice(1);
    return stringUppercase.replace("-", " ");
  }

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split("/").filter((x) => x);
        return (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <CustomLink color="inherit" to="/">
              Home
            </CustomLink>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <Text color="primary" key={to}>
                  {title != null ? title : transformPath(value)}
                </Text>
              ) : (
                <CustomLink color="inherit" to={to} key={to}>
                  {transformPath(value)}
                </CustomLink>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
};

export default CustomBreadcrumbs;

const Text = styled(Typography)`
  font-size: 1rem;
`;

const CustomLink = styled(Link)`
  font-size: 1rem;
  color: #747484;
`;

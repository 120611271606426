import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPopupByCompany } from "../api/popup";

const usePopup = () => {
    const { activeRol } = useSelector((state) => state.auth);
    const ondemand_id = useSelector((state) => state?.auth?.user?.ondemand_id);
    const company = activeRol === "ondemand" && ondemand_id;

    // STATES
    const [popupSlides, setPopupSlides] = useState(null);

    // EFFECTS
    useEffect(() => {
        popupSlides === null && getPopup(company);
    }, []);

    // FUNCTIONS
    const getPopup = async (company) => {
        try {
            const req = await getPopupByCompany(company);

            setPopupSlides(
                req.data.filter(
                    (value) =>
                        value.attributes.published === true &&
                        new Date(value.attributes.publication_date).getTime() >
                            new Date().getTime()
                )
            );
        } catch (error) {
            setPopupSlides(false);
        }
    };

    return {
        popupSlides,
    };
};

export default usePopup;

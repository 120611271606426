import React, { useState } from "react";
import styled from "styled-components";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { IconButton, Modal, Tooltip } from "@mui/material";
import { userAction } from "../../../../helpers/userActions.helper";
import DialogEmail from "../../../common/DialogEmail";

const SharedEvents = (props) => {
  const { sharedItem, links } = props;

  const [link, setLink] = useState(links);

  return (
    <SocialsGrid>
      <FacebookShareButton
        onClick={() =>
          userAction({
            object_id: sharedItem.crm_id + "",
            object: sharedItem.publication_name,
            name: sharedItem.publication_name,
            activity: "Compartir recurso",
            medio: "facebook",
          })
        }
        url={link}
      >
        <FacebookIcon round size={48} />
      </FacebookShareButton>
      <TwitterShareButton
        onClick={() =>
          userAction({
            object_id: sharedItem.crm_id + "",
            object: sharedItem.publication_name,
            name: sharedItem.publication_name,
            activity: "Compartir recurso",
            medio: "twitter",
          })
        }
        url={link}
      >
        <TwitterIcon round size={48} />
      </TwitterShareButton>
      <LinkedinShareButton
        onClick={() =>
          userAction({
            object_id: sharedItem.crm_id + "",
            object: sharedItem.publication_name,
            name: sharedItem.publication_name,
            activity: "Compartir recurso",
            medio: "linkedin",
          })
        }
        url={link}
      >
        <LinkedinIcon round size={48} />
      </LinkedinShareButton>
      <WhatsappShareButton
        onClick={() =>
          userAction({
            object_id: sharedItem.crm_id + "",
            object: sharedItem.publication_name,
            name: sharedItem.publication_name,
            activity: "Compartir recurso",
            medio: "whatsapp",
          })
        }
        url={link}
      >
        <WhatsappIcon round size={48} />
      </WhatsappShareButton>
      {/* <DialogEmail button={IconButton} sharedItem={sharedItem} /> */}
      {/* <EmailShareButton
                        subject={ sharedItem.publication_name? sharedItem.publication_name : sharedItem.nombre} 
                        body=""
                        openShareDialogOnClick={true}
                        onClick={() =>
                            userAction({
                                object_id: sharedItem.crm_id + '',
                                object: sharedItem.publication_name,
                                name:  sharedItem.publication_name? sharedItem.publication_name : sharedItem.nombre,
                                activity: 'Compartir recurso',
                                medio: 'email'
                            })
                        }
                        url={link}>
                            <EmailIcon round size={48}/>
                        </EmailShareButton> */}
    </SocialsGrid>
  );
};

export default SharedEvents;

const SocialsGrid = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
`;

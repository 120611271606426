import * as types from "../types/subject_types";
import { call, put, takeEvery } from "redux-saga/effects";
import { subjectsActions } from "../actions";
import {
  getSubjectDirectories,
  getSubjectPaginate,
  getSubjects,
} from "../api/subjects";

function* subjectsRequest(action) {
  try {
    const res = yield call(getSubjects, action.payload);
    yield put(subjectsActions.getSubjectsSuccess(res.data.response_data));
  } catch (error) {
    yield put(subjectsActions.getSubjectsFail(error));
  }
}

function* paginationSubjectRequest(action) {
  try {
    const res = yield call(getSubjectPaginate, action.payload);
    yield put(
      subjectsActions.getSubjectPaginateSuccess(res.data.response_data)
    );
  } catch (error) {
    yield put(subjectsActions.getSubjectPaginateFail(error));
  }
}

function* directoriesSubjectRequest(action) {
  try {
    const res = yield call(getSubjectDirectories, action.payload);
    yield put(
      subjectsActions.getSubjectDirectoriesSuccess(res.data.response_data)
    );
  } catch (error) {
    yield put(subjectsActions.getSubjectDirectoriesFail(error));
  }
}

function* subjectsWatcher() {
  yield takeEvery(types.GET_SUBJECTS_REQUEST, subjectsRequest);
  yield takeEvery(
    types.GET_SUBJECT_PAGINATION_REQUEST,
    paginationSubjectRequest
  );
  yield takeEvery(
    types.GET_SUBJECT_DIRECTORIES_REQUEST,
    directoriesSubjectRequest
  );
}

export { subjectsWatcher };

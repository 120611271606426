export const removeDuplicateObjects = (array, valueToCompare) => {
  let newArray = [];
  let uniqueObject = {};

  for (const i in array) {
    const value = array[i][valueToCompare];
    if (value !== undefined) {
      uniqueObject[value] = array[i];
    }
  }

  for (const i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }

  return newArray;
};

export const fakeArrayItems = (amount) => {
  return Array.from(Array(amount).keys());
};

import React, { useState } from "react";
import styled from "styled-components";

// Components
import DefaultCardIcon from "../../../../../components/common/DefaultCardIcon";

// Material UI
import AudioFileIcon from "@mui/icons-material/AudioFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { Button, Dialog } from "@mui/material";

const Attachments = (props) => {

    const { values } = props;

    const handleDonwload = () => window.open(values.file_correction.file);

    const [openModalVideo, setOpenModalVideo] = useState(false)

    return (
        <AttachmentsWrapper>
            {values?.file_correction?.file && (
                <Click onClick={handleDonwload}>
                    <DefaultCardIcon
                        icon={<AttachFileIcon />}
                        color={"#5E80DB"}
                        subtitle={"Ver"}
                        title={"Archivo"}
                    />
                </Click>
            )}
            {values?.audio_correction?.file && (
                <DefaultCardIcon
                    icon={<AudioFileIcon />}
                    type="audio"
                    color={"#3bd39c"}
                    subtitle={'none'}
                    title={
                        <audio
                            style={{ position: "relative", top: "-8px" }}
                            controls
                        >
                            <source
                                src={values?.audio_correction?.file}
                            ></source>
                        </audio>
                    }
                />
            )}
            {values?.video_correction?.file && (
                <DefaultCardIcon
                    icon={<VideoFileIcon />}
                    type="video"
                    color={"#b31d15"}
                    subtitle={'none'}
                    title={
                        <WatchVideo onClick={() => setOpenModalVideo(true)}>
                            Ver video
                        </WatchVideo>
                    }
                />
            )}
            {
                <FakeDialog open={openModalVideo}>
                <ContentVideo>
                    <video
                        width="720"
                        className="video-player"
                        controls
                        autoplay
                        src={values?.video_correction?.file}
                    ></video>
                    <Button variant="outlined" onClick={() => setOpenModalVideo(false)}>
                        Salir
                    </Button>
                </ContentVideo>
            </FakeDialog>
            }
        </AttachmentsWrapper>
    );
};

export default Attachments;

const Click = styled.div`
    cursor: pointer;
    max-width: 230px;
    transition: all 0.3s ease-in-out;
    :hover {
        filter: brightness(0.9);
    }
`;

const AttachmentsWrapper = styled.div`
    width: fit-content;
    display: flex;
    gap: 18px;

    @media (width < 1550px) {
        flex-direction: column;
    }

    @media (width < 550px) {
        max-width: 50vw;

        audio {
            width: 120px;
        }
    }
`;

const WatchVideo = styled.button`
    cursor: pointer;
    height: 40px;
    width: 120px;
    background-color: #fff;
    color: #000;
    border: 1px solid #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
`

const FakeDialog = styled(Dialog)`
    & > div {
        max-width: 100vw;
    }
    & > div:nth-child(3) {
        width: 100vw;
        max-height: 100%;

        & > div {
            max-width: fit-content;
        }
    }

    .camera {
        width: calc(100% - 4rem);
    }
`

const ContentVideo = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`;
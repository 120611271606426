import React from "react";
import styled from "styled-components";

import Text from "../../../common/Text";

const TicketResolution = (props) => {
  const { title, text } = props;

  // RETURN
  return (
    <Container>
      <Text
        fontSize="0.9rem"
        fontWeight="700"
        textColor="#1e8065"
        component="h2"
      >
        {title}
      </Text>
      <Text fontSize="1rem" component="p">
        {text}
      </Text>
    </Container>
  );
};

export default TicketResolution;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: #f4fffc;
`;

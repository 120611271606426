import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { diffDate } from "../../../helpers/getDate.helper";
import { Text } from "../../common/Texts";
import { Button, Modal } from "@mui/material";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";

const ExpiredDate = () => {
	const { expired_date } = useSelector((state) => state.ondemand.ondemand);;
	const user = useSelector((state) => state.auth.user);

	const { push } = useHistory();
	const [open, setOpen] = useState(false);

	const expiredDateReplace = expired_date?.replace(/-/g, "/");
	const expiredDate = new Date(expiredDateReplace).getTime();
	const { diffDay } = diffDate(new Date(), expiredDate);

	const userBlocked = user?.od_participant_id?.estado === "bloqueado";
	const userExpired = user?.od_participant_id?.estado === "vencido";

	useEffect(() => {
		if (diffDay < 0 || userBlocked || userExpired) {
			setOpen(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		setTimeout(() => push("./logout"), 50);
		setOpen(false);
	};

	if (diffDay > 0 && !userBlocked && !userExpired) {
		return null;
	}
	return (
		<>
			<ModalFake open={open} disableEnforceFocus onClose={handleClose}>
				<Wrapper>
					<BlockRoundedIcon color="primary" fontSize="large" />
					<Text
						color="#616161"
						textAlign="center"
						fontSize="1.5rem"
						fontWeight="700"
					>
						¡Ups!
						{
							userBlocked ? ' Cuenta bloqueada' : ' Expiró la cuenta'
						}
					</Text>
					<Text
						color="#616161"
						textAlign="center"
						fontSize="1rem"
						fontWeight="400"
					>
						Ponte en contacto con el administrador del dominio para obtener
						ayuda.
					</Text>
					<Button onClick={handleClose} color="primary" variant="outlined">
						Aceptar
					</Button>
				</Wrapper>
			</ModalFake>
		</>
	);
};

export default ExpiredDate;

const ModalFake = styled(Modal)`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
`;

const Wrapper = styled.div`
	width: 40vw;
	height: 30vh;
	padding: 2rem;
	margin: auto;
	background-color: #efefef;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	border: none;
	@media (max-width: 768px) {
		width: 80vw;
		margin: 0 15px;
	}
	:focus-visible {
		outline: none;
	}
`;

import styled from "styled-components";
import { Button, Modal } from "@mui/material";
import Text from "../../../common/Text";
import { useState } from "react";

const RegistrationModal = (props) => {

  const { handleClick, openModal, setOpenModal, data, repoId, ondemandId, type } = props;

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(!openModal)}>
        <Container>
          <TextWrapper>
            <Text fontSize="1.5rem" fontWeight="600" color="var(--primary-one)">
              Estás a punto de matricularte en{" "}
              {data?.nombre_publicacion || data?.name || "un nuevo curso"}
            </Text>
            <TextFake fontSize="1.2rem" fontWeight="400">
              ¡Confirma tu elección!
            </TextFake>
          </TextWrapper>
          <WrapperButtons>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setOpenModal(!openModal)}
            >
              Cancelar
            </Button>
            <Button
              style={{ backgroundColor: "var(--primary-one)" }}
              variant="contained"
              onClick={() => {
                if (type === "route") {
                  handleClick(ondemandId, repoId, data?.id, "subject")
                } else {
                  handleClick(ondemandId, repoId, data?.id)
                }
                setLoading(true)
              }}
            >
              {
                loading ? "Cargando..." : "Confirmar"
              }
            </Button>
          </WrapperButtons>
        </Container>
      </Modal>
    </>
  );
};

export default RegistrationModal;

const Container = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 2rem;
  border-radius: 20px;
  width: 500px;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0rem;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 1.5rem;
  width: 70%;
`;
const TextFake = styled(Text)`
  color: #616161;
  margin-top: 1rem;
  span {
    font-weight: bold;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 468px) {
    flex-direction: column-reverse;
    gap: 2rem;
  }
`;

import * as types from "../types";

const initialState = {
  finances: null,
  listLoading: false,
  errorList: null,
};

export const finances = (state = initialState, action) => {
  switch (action.type) {
    // GET FINANCES
    case types.GET_FINANCES_REQUEST:
      return {
        ...state,
        listLoading: true,
        errorList: null,
      };
    case types.GET_FINANCES_SUCCESS:
      return {
        ...state,
        listLoading: false,
        finances: action.payload,
        errorList: null,
      };
    case types.GET_FINANCES_FAIL:
      return {
        ...state,
        listLoading: false,
        errorList: action.payload.response.data.error,
      };
    default:
      return state;
  }
};

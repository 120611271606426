import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { coursesActions } from "../../../../redux/actions";
import SimpleLoading from "../../../common/SimpleLoading";
import TryAgain from "../../../common/TryAgain";
import UserCard from "../../molecules/my-progress/UserCard";
import { Divider } from "@mui/material";
import NoMoreResults from "../../../common/NoMoreResults";
import LoadMore from "../../../common/LoadMore";

const GridCardsModal = (props) => {
  const { id, title, isOpen } = props;
  // REDUX
  const dispatch = useDispatch();
  const students = useSelector((state) => state.courses.students);
  const studentsLoading = useSelector((state) => state.courses.studentsLoading);
  const errorStudents = useSelector((state) => state.courses.errorStudents);
  const teachers = useSelector((state) => state.courses.teachers);
  const teachersLoading = useSelector((state) => state.courses.teachersLoading);
  const errorTeachers = useSelector((state) => state.courses.errorTeachers);

  // STATE
  const [sizeStudents, setSizeStudents] = useState(10);
  const [sizeTeachers, setSizeTeachers] = useState(10);

  // EFFECTS
  useEffect(() => {
    if (title === "Profesores") {
      dispatch(
        coursesActions.getTeachersRequest({
          id: id,
        })
      );
    } else if (title === "Alumnos") {
      dispatch(
        coursesActions.getStudentsRequest({
          id: id,
        })
      );
    }
  }, [title, isOpen]);

  // FUNCTIONS
  function handleTryAgainStudents() {
    dispatch(
      coursesActions.getStudentsRequest({
        id: id,
      })
    );
  }

  function handleTryAgainTeachers() {
    dispatch(
      coursesActions.getTeachersRequest({
        id: id,
      })
    );
  }

  function handleSizeStudents() {
    setSizeStudents(sizeStudents + 10);
  }

  function handleSizeTeachers() {
    setSizeTeachers(sizeTeachers + 10);
  }

  // RETURN
  if (title === "Alumnos") {
    if (errorStudents !== null) {
      return (
        <TryAgain padding="6rem" handleTryAgain={handleTryAgainStudents} />
      );
    }

    if (students === null || studentsLoading) {
      return <SimpleLoading padding="6rem" />;
    }

    return (
      <Overflow>
        {students.slice(0, sizeStudents).map((student) => (
          <>
            <UserCard user={student} />
            <Divider />
          </>
        ))}
        {sizeStudents >= students.length ? (
          <NoMoreResults />
        ) : (
          <LoadMore handleLoadMore={handleSizeStudents} />
        )}
      </Overflow>
    );
  }

  if (title === "Profesores") {
    if (errorTeachers !== null) {
      return (
        <TryAgain padding="6rem" handleTryAgain={handleTryAgainTeachers} />
      );
    }

    if (teachers === null || teachersLoading) {
      return <SimpleLoading padding="6rem" />;
    }

    return (
      <Overflow>
        {teachers.slice(0, sizeTeachers).map((teacher) => (
          <>
            <UserCard user={teacher} />
            <Divider />
          </>
        ))}
        {sizeTeachers >= teachers.length ? (
          <NoMoreResults />
        ) : (
          <LoadMore handleLoadMore={handleSizeTeachers} />
        )}
      </Overflow>
    );
  }
};

export default GridCardsModal;

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilterStudyPlan from "../ui/molecules/studyPlan/FilterStudyPlan";
import SubjectsCard from "../ui/molecules/studyPlan/SubjectsCard";
import { SearchRounded } from "@mui/icons-material";
import NoResultsFound from "./NoResultsFound";
import SimpleLoading from "./SimpleLoading";
import Certification from "../ui/molecules/my-progress/Certification";

const GridStudyPlan = (props) => {
  const {
    id,
    course,
    setDataAssigment,
    dataAssigment,
    setIsChange,
    isChange,
    onInscription,
    dataProgram,
    isDiplomat,
    setIsFlag,
    isFlag,
  } = props;

  // STATES
  const [values, setValues] = useState(course);
  const [currentState, setCurrentState] = useState("Todos");
  const [search, setSearch] = useState("");

  // //EFFECT
  useEffect(() => {
    setValues(course);
  }, [course]);

  useEffect(() => {
    setValues(
      course.filter((subjects) => {
        return subjects.name.toLowerCase().includes(search.toLowerCase());
      })
    );
    setCurrentState("Todos");
  }, [search]);

  useEffect(() => {
    if (currentState === "Todos") {
      setValues(course);
    }
    if (currentState === "En Curso") {
      setValues(course.filter((value) => value.state === "confirmado"));
    }
    if (currentState === "Egresado") {
      setValues(course.filter((value) => value.state === "egresado"));
    }
    if (currentState === "Pendiente") {
      setValues(course.filter((value) => value.state === "pendiente"));
    }
  }, [currentState]);

  useEffect(() => {
    setTimeout(() => {
      setIsChange(false);
    }, 300);
  }, [dataAssigment]);

  // FUNCTION
  const isFinish = (array) => {
    const result = array.filter(
      (assigment) => assigment.progress.progress < 100
    );
    return result.length === 0 ? true : false;
  };

  function handleCurrentState(newValue) {
    setCurrentState(newValue);
  }
  function handleChange(e) {
    setSearch(e.target.value);
  }

  return (
    <Container>
      <Header>
        <FilterWrapper>
          <TextFieldFake
            value={search}
            onChange={handleChange}
            size="small"
            label=""
            variant="outlined"
            placeholder="Buscar asignatura..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
          />
          <FilterStudyPlan
            currentState={currentState}
            handleCurrentState={handleCurrentState}
          />
        </FilterWrapper>
        {dataProgram?.has_certificate && (
          <Certification
            id={id}
            avaliable={isFinish(dataAssigment)}
            type="PROGRAM"
          />
        )}
      </Header>
      <SubjectsWrapper isChange={isChange} value={values.length}>
        {values.length === 0 ? (
          <>
            <NoResultWrapper>
              <NoResultsFound />
            </NoResultWrapper>
          </>
        ) : (
          <>
            {isChange ? (
              <>
                <SimpleLoading />
              </>
            ) : (
              <>
                {values.map((courses, index) => (
                  <SubjectsCard
                    key={index}
                    data={courses}
                    dataProgramName={dataProgram?.name}
                    onInscription={onInscription}
                    setIsFlag={setIsFlag}
                    isFlag={isFlag}
                    setDataAssigment={setDataAssigment}
                    dataAssigment={dataAssigment}
                    setIsChange={setIsChange}
                    isDiplomat={isDiplomat}
                  />
                ))}
              </>
            )}
          </>
        )}
      </SubjectsWrapper>
    </Container>
  );
};

export default GridStudyPlan;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${(p) => (p.isChange ? "100%" : p.value > 0 ? "400px" : "100%")},
      1fr
    )
  );
  grid-gap: 2rem;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextFieldFake = styled(TextField)`
  width: fit-content;
`;

const NoResultWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

const FilterWrapper = styled.div`
  display: grid;
  gap: 12px;
`;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import styled from "styled-components";

function LinearProgressWithLabel(props) {
  const { stars } = props;
  const [arrayStar, setArrayStar] = useState([]);
  const [arrayOutlineStar, setArrayOutlineStar] = useState([]);
  const outlineStar = 5 - stars;

  useEffect(() => {
    selectStar(stars);
    selectOutlineStar(outlineStar);
  }, []);

  const selectStar = (stars) => {
    const star = {
      1: [""],
      2: ["", ""],
      3: ["", "", ""],
      4: ["", "", "", ""],
      5: ["", "", "", "", ""],
    };
    setArrayStar(star[stars]);
  };

  const selectOutlineStar = (outlinesStar) => {
    const outlineStar = {
      0: [],
      1: [""],
      2: ["", ""],
      3: ["", "", ""],
      4: ["", "", "", ""],
      5: ["", "", "", "", ""],
    };
    setArrayOutlineStar(outlineStar[outlinesStar]);
  };

  return (
    <BodyContent display="flex" alignItems="center">
      <NumberMobile>{arrayStar.length}</NumberMobile>
      <BoxProgress width="100%" mr={1}>
        <ProgressFake color="primary" variant="determinate" {...props} />
      </BoxProgress>
      <BoxStars>
        <Typography variant="body2" color="primary">
          <LabelContent>
            <div>
              {arrayStar.map(() => {
                return <StarFake />;
              })}
              {arrayOutlineStar.map(() => {
                return <StarBorderFake />;
              })}
            </div>
            <span>{`${Math.round(props.value)}%`}</span>
          </LabelContent>
        </Typography>
      </BoxStars>
    </BodyContent>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function LinearProgressBar(props) {
  const { value = 0, stars } = props;
  const classes = useStyles();
  const [progress, setProgress] = React.useState(value);
  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} stars={stars} />
    </div>
  );
}
const BodyContent = styled(Box)`
  display: flex;
  justify-content: space-around;
`;
const BoxProgress = styled(Box)`
  flex-basis: calc(60% - 1rem);

  @media (max-width: 768px) {
    flex-basis: calc(80% - 0.5rem);
  }
`;
const BoxStars = styled(Box)`
  flex-basis: calc(40% - 1rem);

  @media (max-width: 768px) {
    flex-basis: calc(20% - 0.5rem);
  }
`;
const NumberMobile = styled.p`
  margin: 0 0.5rem;
  display: none;
  color: #591AB6;
  @media (max-width: 768px) {
    display: block;
  }
`;
const LabelContent = styled.div`
  display: flex;
  align-items: center;

  div {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const StarFake = styled(Star)`
  color: #f7bb56;
  font-size: 1.2rem;
  text-align: justify;
  margin-right: 0.2rem;
`;
const StarBorderFake = styled(StarBorder)`
  color: #f7bb56;
  font-size: 1.2rem;
  text-align: justify;
  margin-right: 0.2rem;
`;
const ProgressFake = styled(LinearProgress)`
  height: 8px;
  border-radius: 8px;
  background-color: #e5e5e5e5;
  div {
    border-radius: 8px;
  }
`;

export const options = {
  style: {
    backgroundColor: "#333",
    fontSize: "16px",
    textAlign: "center",
  },
  closeStyle: {
    color: "#eee",
    fontSize: "16px",
  },
};

import { Button, Card, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardNotifications from "../../components/ui/molecules/notifications/CardNotifications";
import FilterNotification from "../../components/ui/molecules/notifications/FilterNotification";
import {
  getCategoryNotification,
  getNotificationFake,
  postNotification,
} from "../../redux/api/notifications";
import NoResultsFound from "../../components/common/NoResultsFound";
import { SearchRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Notification = () => {
  const { activeRol } = useSelector((state) => state.auth);

  // STATES
  const [notificationState, setNotification] = useState([]);
  const [values, setValues] = useState(notificationState);
  const [currentState, setCurrentState] = useState("Todos");
  const [search, setSearch] = useState("");
  const [allRead, setAllRead] = useState(false);
  const [categories, setCategories] = useState([]);
  const history = useHistory();

  // EFFECTS
  useEffect(() => {
    if (notificationState.length === 0) {
      getNotifications(activeRol);
      getCategory();
    }
  }, []);

  useEffect(() => {
    if (notificationState) {
      sortChannel(notificationState);

      setValues(notificationState);
    }
  }, [notificationState]);

  useEffect(() => {
    categories.forEach((category) => {
      if (currentState === category.name) {
        setValues(
          notificationState.filter(
            (notification) => notification.message_category_id === category.id
          )
        );
      }
    });

    if (currentState === "Todos") {
      setValues(notificationState);
    }
  }, [currentState]);

  useEffect(() => {
    setValues(
      notificationState.filter((notificacion) => {
        return notificacion.title.toLowerCase().includes(search.toLowerCase());
      })
    );
    setCurrentState("Todos");
  }, [search]);

  // FUNCTIONS
  function handleCurrentState(newValue) {
    setCurrentState(newValue);
  }

  const sortChannel = (notification) => {
    let result = notification.sort((a, b) => a.is_open - b.is_open);
    setNotification(result);
  };

  const getNotifications = async (is_teacher) => {
    const response = await getNotificationFake(is_teacher);

    if (!response.error) {
      let result = response.sort((a, b) => b.id - a.id);
      setNotification(result);
    } else {
      console.error(response.error);
    }
  };

  const getCategory = async () => {
    const response = await getCategoryNotification();

    if (!response.error) {
      setCategories(response);
    } else {
      console.error(response.error);
    }
  };

  function handleChange(e) {
    setSearch(e.target.value);
  }

  // PARA MARCAR TODAS COMO LEIDAS
  const handleGoTo = async (launch_url, id) => {
    const req = await postNotification(id);

    if (req.error) {
      console.error(req.error);
    }

    if (launch_url) {
      history.push(launch_url);
      // <Redirect to="./mi-progreso" />;
    }
  };

  const handleAllRead = () => {
    values.forEach((notification) => {
      handleGoTo(false, notification.id);
      setAllRead(true);

      if (activeRol === "alumno" || activeRol === "academico") {
        getNotifications(false);
      } else if (activeRol === "docente") {
        getNotifications(true);
      }
    });
  };

  return (
    <>
      <Wrapper>
        <CardFake>
          {/* Input de busqueda */}
          <SearchWrapper>
            <TextFieldFake
              value={search}
              onChange={handleChange}
              size="small"
              label=""
              variant="outlined"
              placeholder="Buscar..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
            <Button onClick={handleAllRead} color="primary">
              Marcar todas como leídas
            </Button>
          </SearchWrapper>
          {/* Filtro por etiqueta */}
          {categories.length > 0 && (
            <FilterNotification
              currentState={currentState}
              handleCurrentState={handleCurrentState}
              categories={categories}
              setCategories={setCategories}
            />
          )}

          {/* cards de notificaciones */}
          <CardWrapper>
            {values.length === 0 && (
              <NoResultWrapper>
                <NoResultsFound />
              </NoResultWrapper>
            )}
            {values.map((notifications) => {
              return (
                <CardNotifications
                  title={notifications.title}
                  date={notifications.date}
                  descriptions={notifications.message}
                  read={notifications.is_open}
                  launch_url={notifications.launch_url}
                  allRead={allRead}
                  id={notifications.id}
                />
              );
            })}
          </CardWrapper>
        </CardFake>
      </Wrapper>
    </>
  );
};

export default Notification;

const Wrapper = styled.div`

`;

const CardFake = styled(Card)`
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const NoResultWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;

const TextFieldFake = styled(TextField)`
  width: fit-content;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

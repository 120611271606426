import styled from "styled-components";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";
import { Button } from "@mui/material";
import useStatus from "../../../../hooks/useStatus";
import { useState } from "react";
import useBusiness from "../../../../hooks/business/useBusiness";
import RegistrationModal from "../../../templates/business/components/RegistrationModal";

const RecommendedCoursesCard = (props) => {

    const { value } = props;

    const { setLightStateColor, setStrongStateColor } = useStatus();

    const [openModal, setOpenModal] = useState(false)

    const { registrateUser, repo_id, ondemand_id } = useBusiness("card");

    return (
        <BusinessCardContainer>
            <RegistrationModal
                handleClick={registrateUser}
                data={value}
                openModal={openModal}
                setOpenModal={setOpenModal}
                repoId={repo_id}
                ondemandId={ondemand_id}
            />
            <ImageWrapper>
                <Img
                    width="100%"
                    height="100%"
                    borderRadius="20px 20px 0 0"
                    src={value.image}
                />
                <State
                    ligthColor={setLightStateColor("sin_entrega")}
                    strongColor={setStrongStateColor("sin_entrega")}
                >
                    Iniciar curso
                </State>
            </ImageWrapper>
            <Body>
                <PublicationName>
                    {value.name}
                </PublicationName>
            </Body>
            <Footer>
                <FakeButton
                    onClick={() => setOpenModal(true)}
                >
                    Iniciar curso
                </FakeButton>
            </Footer>
        </BusinessCardContainer>
    )
}

export default RecommendedCoursesCard;

const BusinessCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    width: 320px;
    min-width: 300px;
    min-height: 325px;
    border-radius: 20px;
    background-color: #fff;
    transition: all .5s ease-in-out;
    img {
        scale: 1;
        transition: scale .5s ease-in-out;
    }
    :hover img {
        scale: 1.3;
        
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 136px;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
`;

const Img = styled(LazyImg)`
    object-fit: cover;
    object-position: center;
`

const State = styled.span` 
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-1rem, 1rem);
    border-radius: 8px;
    color: ${(p) => p.strongColor};
    background-color: ${(p) => p.ligthColor};
    font-size: 12px;
    font-weight: 600;
    padding: 4px 12px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 117px;
    max-height: 117px;
    padding: 0 1rem;
`;

const PublicationName = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    color: #222222;
`

const Footer = styled.div`
    position: relative;
    height: calc(72px - 16px);
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    padding: 16px;
    background-color: var(--secondary-two);
    border-radius: 0 0 20px 20px;
`;

const FakeButton = styled(Button)`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    border-radius: 48px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--primary-one);
    color: #ffffff;

    :hover {
        background-color: #616161;
        color: #ffffff;
    }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import TabsComponent from "../../common/TabsComponent";
import OptionWrapper from "./OptionWrapper";

/*const NAVEGATION_TABS = [
	{ id: 0, label: "Clasificación" },
	{ id: 1, label: "Beneficios" },
];*/

const MainDracma = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [menu, setMenu] = useState([{ id: 0, label: "Ranking Aden" }]);

  const { ondemand_id } = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //     if (!!ondemand_id)
  //         setMenu([...menu, { id: 1, label: 'Ranking Empresa' }]);
  // }, []);

  useEffect(() => {
    if (!!ondemand_id) {
      setMenu([{ id: 1, label: "Ranking Empresa" }]);
      setCurrentTab(1);
    } else {
      setMenu([{ id: 0, label: "Ranking Aden" }]);
      setCurrentTab(0);
    }
  }, []);

  // FUNCTIONS
  function changeCurrentTab(e, value) {
    setCurrentTab(value);
  }

  // RETURN
  return (
    <Container>
      <TabsComponent
        tab={currentTab}
        handleTab={changeCurrentTab}
        tabs={menu}
      />
      <PaddingWrapper>
        <OptionWrapper currentTab={currentTab} />
      </PaddingWrapper>
    </Container>
  );
};

export default MainDracma;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const PaddingWrapper = styled.div`
  padding: 2rem;
`;

import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import axios from "axios";
import * as encode from "nodejs-base64-encode";
import { Icon } from "@iconify/react";

// Components
import DefaultCardIcon from "../../../../components/common/DefaultCardIcon";
import GenericProgress from "../../../../components/common/GenericProgress";
import { Text } from "../../../../components/common/Texts";
import TextQuillInput from "./presentation/TextQuillInput";
import InputFile from "./presentation/InputFile";
import PageFinish from "./presentation/PageFinish";
import Pending from "./presentation/Pending";
import Correction from "./presentation/Correction";
import BlockSubmission from "./presentation/BlockSubmission";

// Material UI
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ReplayIcon from "@mui/icons-material/Replay";
import HistoryIcon from "@mui/icons-material/History";
import AccessTime from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Redux
import { useSelector } from "react-redux";

// Context
import CourseContext from "../../context/CourseContext"
import { useQueryParams } from "../../../../modules/acropolisCommon/hooks/useQueryParams";

const Presentation = (props) => {
	const {
		presentation,
		handleNewTry,
		submitViewResource,
	} = props;

	const {
		currentMedia: {
			id_recurso: resourceId,
		},
		objId,
		setChangeStatus,
		idCourse,
		viewDelivery
	} = useContext(CourseContext)

	// REDUX
	const user = useSelector((state) => state.auth.user);

	// 1206 -> lo pidio el seba agregar esa por defecto
	const hashId = !!idCourse ? encode.decode(idCourse, "base64") : 1206;

	const params = useQueryParams();

	// STATE
	const [page, setPage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);

	const qualificationRef = useRef(null);

	// USEEFFECT
	useEffect(() => {
		presentation.tipo_entrega !== "con_entrega" && submitViewResource();

		if (viewDelivery !== null) {
			handleViewDelivery();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (viewDelivery !== null) handleViewDelivery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewDelivery]);

	// FUNCTIONS
	const handleViewDelivery = () => {
		if (presentation.tipo_entrega === "con_entrega") {
			if (presentation.submissions.length > 0) {
				if (!viewDelivery) {
					submitViewResource();
				}
			}
		}
	};

	useEffect(() => {
		const scroll = params.get("scroll");

		if (scroll !== null) {
			handleOnClickScroll();
			let url = new URL(window.location.href);
			url.searchParams.delete('scroll');
			window.history.replaceState(null, '', url.toString());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sanitizerHtml = (html) => {
		const string = 'src="/';
		const regex = new RegExp(string, "g");
		return html.replace(regex, 'src="');
	};

	const handleDonwload = () => {
		window.open(
			presentation.archivo,
			presentation.name,
			"toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=800,height=480"
		);
	};

	const sendPresentationFormData = async (presentation, options) => {
		const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test_presentacion2`;

		try {
			const response = await axios.post(URL, presentation, options);
			return response.data;
		} catch (error) {
			return { error };
		}
	};

	const sendPresentationService = async (presentation) => {
		const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test_presentacion`;

		try {
			const response = await axios.post(URL, presentation);
			return response.data;
		} catch (error) {
			return { error };
		}
	};

	const handleSubmitText = (html) => {
		const data = {
			alumno: user.repo_id,
			evaluacion: presentation.id,
			recurso: parseInt(resourceId, 10),
			objeto_aprendizaje: parseInt(objId, 10),
			tipo: presentation.tipo,
			entrega_alumno: html,
			sub_tipo: "texto",
			asignatura: hashId,
			comentario_alumno: "Comentario sobre la entrega", // Agregar acá el comentario del alumno
			student_comment: "Comentario sobre la entrega"
		};
		sendPresentation(data);
	};

	const handleSubmitFile = async (file) => {
		//@TODO Limitar tamaño del archivo

		var data = new FormData();
		data.append("file", file);
		data.append("evaluacion", `${presentation.id}`);
		data.append("alumno", `${user.repo_id}`);
		data.append("recurso", `${parseInt(resourceId, 10)}`);
		data.append("objeto_aprendizaje", `${parseInt(objId, 10)}`);
		data.append("tipo", `${presentation.tipo}`);
		data.append("entrega_alumno", "");
		data.append("sub_tipo", "carga_archivo");
		data.append("asignatura", `${hashId}`);

		const options = {
			onUploadProgress: (progress) => {
				const { loaded, total } = progress;
				let percent = Math.floor((loaded * 100) / total);
				setProgress(percent);
			},
		};
		sendPresentation(data, options);
	};

	const sendPresentation = async (data, options) => {
		setLoading(true);
		let response = "";
		if (data?.sub_tipo === "texto") {
			response = await sendPresentationService(data);
		} else {
			response = await sendPresentationFormData(data, options);
		}
		if (response.error) {
			alert("Error en la peticion");
		} else {
			submitViewResource();
			setPage(1);
			setChangeStatus(true);
		}
		setLoading(false);
	};

	const validateInput = () => {
		if (presentation?.submissions.length > 0) {
			if (
				!presentation?.submissions[0]?.redo &&
				!presentation?.submissions[0]?.grade
			) {
				return true;
			}
			if (presentation?.submissions[0]?.redo) {
				return false;
			}
			if (presentation?.submissions[0].grade) {
				return true;
			}
		}
		return false;
	};

	const handleOnClickScroll = () => {
		qualificationRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
	};

	if (page === 1) {
		return (
			<PageFinish handleNewTry={handleNewTry} presentation={presentation} />
		);
	}

	return (
		<>
			<PresentationWrapper>

				<Activity>
					{
						presentation?.submissions?.length > 0 &&
						<WarningContainer>
							<section>
								<p>Esta actividad ha sido <b>corregida por tu profesor tutor.</b></p>
								<p>Puedes ver la <b>nota y el feedback</b> del profesor haciendo click en el siguiente botón:</p>
							</section>
							<button onClick={handleOnClickScroll}>
								<Icon icon="codicon:feedback" width="24px" height="24px" />
								Ver calificación y comentarios
							</button>
						</WarningContainer>
					}
					<Header>
						<LeftContent>
							{!!presentation?.submissions.sort((a, b) => b.grade - a.grade)[0]
								?.redo ? (
								<DefaultCardIcon
									icon={<HistoryIcon />}
									color={"#F8423F"}
									title={"Rehacer"}
									subtitle="Estado entrega"
								/>
							) : (
								<DefaultCardIcon
									icon={
										presentation.submissions.length === 0 ? (
											<AccessTime />
										) : (
											<CheckCircleOutlineIcon />
										)
									}
									color={
										presentation.submissions.length === 0 ? "#CEB269" : "#3BD39C"
									}
									title={
										presentation.submissions.length === 0
											? "Pendiente"
											: "Entregado"
									}
									subtitle="Estado entrega"
								/>
							)}
						</LeftContent>
						<RightContent>
							<DefaultCardIcon
								icon={<CalendarTodayIcon />}
								color={"#b21d15"}
								subtitle={"Fecha limite"}
								title={
									presentation.fecha_max_entrega !== false
										? new Date(
											presentation.fecha_max_entrega
										).toLocaleDateString()
										: "-"
								}
							/>
							<DefaultCardIcon
								icon={<ReplayIcon />}
								color={"#b21d15"}
								subtitle={"Intentos"}
								title={
									presentation.cantidad_intentos === 100
										? "Ilimitados"
										: presentation.cantidad_intentos
								}
							/>
							<DefaultCardIcon
								icon={<AccessTime />}
								color={"#b21d15"}
								subtitle={"Tiempo"}
								title={"-"}
							/>
						</RightContent>
					</Header>
					<Content>
						<DescriptionContainer>
							<div>{parse(sanitizerHtml(presentation.descripcion || ""))}</div>
						</DescriptionContainer>
						{!!presentation.archivo && (
							<DescriptionContainer>
								<Text color="#B31D15" fontSize="18px" fontWeight="700">
									Material complementario
								</Text>
								<Click onClick={handleDonwload}>
									<DefaultCardIcon
										icon={<CloudDownloadIcon />}
										color={"#616161"}
										subtitle={"Libro de ayuda"}
										title={"Descargar"}
									/>
								</Click>
							</DescriptionContainer>
						)}
						<DescriptionContainer>
							<Text color="#b31d15" fontSize="18px" fontWeight="700">
								Actividad
							</Text>
							<ParseHtml>{parse(sanitizerHtml(presentation.consigna))}</ParseHtml>
						</DescriptionContainer>
					</Content>
				</Activity>
				<Activity>
					{loading && (
						<LoadingPresentation>
							{/* <SimpleLoading /> */}
							<GenericProgress progress={progress} scale={125} variant="linear" />
							<Description>ENTREGANDO ACTIVIDAD</Description>
						</LoadingPresentation>
					)}
					{presentation.tipo_entrega === "con_entrega" && !loading && (
						<Footer>
							<Text color="#b31d15" fontSize="18px" fontWeight="700">
								Entrega
							</Text>
							<br />
							{!!presentation?.submissions.sort(
								(a, b) => b.submission_number - a.submission_number
							)[0]?.redo || presentation.submissions.length === 0 ? (
								<>
									{presentation.opciones_entrega === "carga_archivo" && (
										<InputFile
											delivery={validateInput()}
											handleSubmitFile={handleSubmitFile}
										/>
									)}
									{presentation.opciones_entrega === "texto" && (
										<TextQuillInput
											delivery={validateInput()}
											handleSubmitText={handleSubmitText}
										/>
									)}
								</>
							) : (
								<BlockSubmission
									submission={
										presentation?.submissions[
										presentation?.submissions.length - 1
										]
									}
								/>
							)}
						</Footer>
					)}
				</Activity>
				<Activity>
					<Qualification>
						<Text ref={qualificationRef} color="#b31d15" fontSize="18px" fontWeight="700">
							Calificaciones y comentarios
						</Text>
						{presentation.submissions.length === 0 ? (
							<Pending />
						) : (
							<>
								{
									presentation.submissions.sort(
										(a, b) => b.submission_number - a.submission_number
									).map((submission) => (
										<Correction
											values={submission}
										/>
									))
								}
							</>
						)}
					</Qualification>
				</Activity>
			</PresentationWrapper>
			<InfoContainer>
				<Icon icon="octicon:info-24" width="24px" height="24px" />
				<label>
					Ten en cuenta que para que la plataforma registre esta actividad como completa, <b>deberás realizar la entrega de la misma.</b>
				</label>
			</InfoContainer>
		</>
	);
};

export default Presentation;

const PresentationWrapper = styled.div`
	width: 100%;
	display: grid;
	gap: 15px;
`;

const WarningContainer = styled.div`
	width: 100%;
	height: 100px;
	border-bottom: 1px solid #c4c4c4; 
	display: flex;
	justify-content: space-around;
	align-items: center;

	button {
		cursor: pointer;
		background-color: #F9F5EC;
		color: #616161;
		border: 1px solid #C29F43;
		padding: 12px 24px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		font-size: 16px;
		transition: all 0.3s ease-in-out;

		svg {
		color: #C29F43;
		}

		:hover {
		background-color: #ECE2C5;
		}
	}
`

	const Activity = styled.div`
	background: #ffffff;
	box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 8px 24px -4px rgba(24, 39, 75, 0.08);
	border-radius: 20px;
	padding: 5px;
`;

const Content = styled.div`
	width: 100%;
	min-height: 80px;
	/* border: 1px solid #cccccc; */
`;

const ParseHtml = styled.div`
	width: 100%;
	max-width: auto;

	.btn {
		padding: .5rem 1rem;
		border-radius: 0.375rem;
		color: #fff;
	}
	.btn-danger {
		background-color: #dc3545;
		:hover {
		background-color: #c82333;
		}
	}
	.btn-warning {
		background-color: #ffc107;
		color: #000;
		:hover {
		background-color: #e0a800;
		}
	}
	.btn-info {
		background-color: #117a8b;
		:hover {
		background-color: #138496;
		}
	}
	.btn-success {
		background-color: #28a745;
		:hover {
		background-color: #218838;
		}
	}
	.btn-secondary {
		background-color: #f8f9fa;
		color: #000;
		:hover {
		background-color: #e2e6ea;
		}
	}
	.btn-primary {
		background-color: #007bff;
		:hover {
		background-color: #0069d9;
		}
	}
	.btn-epsilon {
		background-color: #E46F78;
		:hover {
		background-color: #de4f5a;
		}
	}
	.btn-delta {
		background-color: #5B899E;
		:hover {
		background-color: #4d7486;
		}
	}
	.btn-gamma {
		background-color: #5C5B80;
		:hover {
		background-color: #4c4b6a;
		}
	}
	.btn-beta {
		background-color: #875A7B;
		:hover {
		background-color: #704b66;
		}
	}
	.btn-alpha {
		background-color: #00A09D;
		:hover {
		background-color: #007a77;
		}
	}
`;

const DescriptionContainer = styled.div`
	width: auto;
	height: auto;
	padding: 1rem;
	position: relative;
	display: grid;
	gap: 15px;
`;

const Description = styled.div`
	color: var(--primary-one);
	font-weight: 600;
`;

const Footer = styled.div`
	width: calc(100% - 2rem);
	height: auto;
	padding: 1rem;
`;

const Click = styled.div`
	cursor: pointer;
	max-width: 230px;
	transition: all 0.3s ease-in-out;
	:hover {
		filter: brightness(0.9);
	}
`;

const LoadingPresentation = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const LeftContent = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	gap: 1rem;
`;

const RightContent = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	gap: 1rem;
`;

const Header = styled.div`
	height: auto;
	display: grid;
	width: calc(100% - 2rem);
	padding: 1rem;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
`;

const Qualification = styled.div`
	width: calc(100% - 2rem);
	height: auto;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 25px;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;

    svg {
        color: #C29F43;
    }
`
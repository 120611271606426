import { useState } from "react";
import { postNotesCourse } from "../redux/api/notesCourseApi";

const useNotesCourse = () => {
    // STATES
    const [LoadingPostNote, setLoadingPostNote] = useState(false);
    const [errorPost, setErrorPost] = useState(null);
    const [upDateNotes, setUpdateNotes] = useState(false);

    // FUNCTIONS
    // RESETEAR ESTADO DEL ERROR A NULL
    const resetError = () => {
        setTimeout(() => {
            setErrorPost(null);
        }, 2000);
    };

    const resetUpdateNotes = () => {
        setUpdateNotes(false);
    };

    // CREAR UNA CONTRIBUCIÓN
    const createNote = async (body) => {
        setLoadingPostNote(true);
        try {
            await postNotesCourse(body);
            setErrorPost(false);
            setLoadingPostNote(false);
            resetError();
            setUpdateNotes(true);
        } catch (error) {
            setLoadingPostNote(false);
            setErrorPost(true);
            resetError();
        }
    };

    return {
        LoadingPostNote,
        errorPost,
        upDateNotes,
        createNote,
        resetUpdateNotes,
    };
};

export default useNotesCourse;

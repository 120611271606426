import React from "react";
import styled from "styled-components";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useTheme from "../hooks/useTheme";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const TabsComponent = (props) => {
    const { tab, handleTab, tabs, variant } = props;

    const tabVariant = variant !== undefined ? variant : "scrollable";
    const { primary } = useTheme();

    // HARDCODE DIPLOMADO PRIMERO EN EL ARRAY (MUTA EL ARRAY ORIGINAL)
    // QUITA 'CURSOS DE ALAB' Y 'WORKSHOPS'
    if (tabs[0].label !== 'Diplomados') {
        const itemsToRearrange = tabs.slice(2, 4).flat()
        const diplomate = itemsToRearrange[0]
        const specialization = itemsToRearrange[1]
        
        tabs.splice(0, 0, specialization, diplomate)
        tabs.splice(4, 3) // BORRA CURSOS DE ALAB
        tabs.splice(4, 3) // BORRA WORKSHOPS
    }

    // RETURN
    return (
        <FakeAppBar position="static" color="secondary">
            <TabsFake
                value={tab}
                onChange={handleTab}
                indicatorColor="primary"
                textColor="primary"
                variant={tabVariant}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                color={primary}
            >
                {tabs.map((item) => (
                    <FakeTab
                        label={item.label}
                        key={item.id}
                        {...a11yProps(item.id)}
                        value={item.id}
                        disabled={item.disabled}
                    />
                ))}
            </TabsFake>
        </FakeAppBar>
    );
};

export default TabsComponent;

const FakeAppBar = styled(AppBar)`
    background-color: #ffffff;
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;
`;

const FakeTab = styled(Tab)`
    /* font-weight: 700; */
    text-transform: none;
`;

const TabsFake = styled(Tabs)``;
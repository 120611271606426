import React from "react";
import styled from "styled-components";

// Components
import GridBusiness from "./GridBusiness";
import GridBusinessSkeleton from "../../../components/skeletons/GridBusinessSkeleton";
import BusinessCard from "../../../components/templates/business/components/BusinessCard";

// Hooks
import useTheme from "../../../hooks/useTheme";
import useAssignamentByAlab from "../../../hooks/useAssignamentByAlab";

const TrainingRoutes = (props) => {
    const { thematic } = props;
    const { primary } = useTheme();

    const { courses } = useAssignamentByAlab(false, false, true);

    const CARDS_ON_SKELETON = window.innerWidth < 1750 ? (window.innerWidth < 1600 ? 3 : 4) : 5;

    if (courses === null) {
        return (
            <Wrapper>
                <GridBusinessSkeleton
                    cardWidth="310px"
                    width={310}
                    height={325}
                    cards={CARDS_ON_SKELETON}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {courses?.length === 0 || courses?.error || !courses ? (
                <MessageEmpty color={primary}>
                    No se asignó una ruta formativa
                </MessageEmpty>
            ) : (
                <GridBusiness
                    menuFilter={thematic}
                    allCourses={courses}
                    Card={BusinessCard}
                    enableFilter={true}
                    type="route"
                    filterByCategoryActivated={false}
                />
            )}
        </Wrapper>
    );
};

export default TrainingRoutes;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MessageEmpty = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.color};
    font-size: 1.2rem;
    font-weight: 600;
    width: calc(100% - 4rem);
    padding: 2rem;
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
`;

import * as types from "../types";
import * as authTypes from "../types/auth_types";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { deleteAllCookies } from "../../helpers/cookie.helper";

const initialState = {
  user: null,
  loading: false,
  authToken: null,
  error: null,
  challenge: {
    date: null,
    state: null,
  },
  snack: {
    snackMessage: "",
    snackShow: false,
    snackType: "",
  },
  canvas_login: null,
  fa_error: null,
  // REGISTER
  loadingRegister: false,
  errorRegister: null,
  // GET USER
  loadingUser: false,
  errorUser: null,
  // ALTA COMERCIAL
  signUp: null,
  signUpError: null,
  loadingSignUp: false,
  roles: null,
  token: null,
  loadingLogin: false,
  errorRoles: false,
  activeRol: null,
  loadingGetUser: false,
};

export const auth = persistReducer(
  {
    storage,
    key: "campus-alab-auth",
    whitelist: ["user", "challenge", "authToken", "canvas_login", "activeRol"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      // LOGIN USER
      case types.LOGIN_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case types.LOGIN_USER_SUCCESS:
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("sis_id", action.payload.profile_data.sis_id);
        sessionStorage.clear();
        return {
          ...state,
          loading: false,
          authToken: action.payload.token,
          user: {
            ...action.payload.profile_data,
            password: action.payload.data_login.password,
          },
        };
      case types.LOGIN_USER_FAIL:
        localStorage.removeItem("token");
        localStorage.removeItem("sis_id");
        sessionStorage.clear();
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case types.CLEAR_ERROR_LOGIN:
        return {
          ...state,
          error: null,
        };
      // REGISTER USER
      case types.REGISTER_USER_REQUEST:
        return {
          ...state,
          loadingRegister: true,
          errorRegister: null,
        };
      case types.REGISTER_USER_SUCCESS:
        return {
          ...state,
          loadingRegister: false,
          authToken: action.payload,
        };
      case types.REGISTER_USER_FAIL:
        return {
          ...state,
          loadingRegister: false,
          errorRegister: action.payload,
        };
      case types.CLEAR_ERROR_REGISTER: {
        return {
          ...state,
          errorRegister: null,
          authToken: null,
        };
      }
      // REGISTER USER
      case types.REGISTER_USER_ONDEMAND_REQUEST:
        return {
          ...state,
          loadingRegister: true,
          errorRegister: null,
        };
      case types.REGISTER_USER_ONDEMAND_SUCCESS:
        return {
          ...state,
          loadingRegister: false,
          authToken: action.payload,
        };
      case types.REGISTER_USER_ONDEMAND_FAIL:
        return {
          ...state,
          loadingRegister: false,
          errorRegister: action.payload,
        };
      case types.CLEAR_ERROR_ONDEMAND_REGISTER: {
        return {
          ...state,
          errorRegister: null,
        };
      }
      // LOGOUT
      case types.LOGOUT_USER:
        window.location.reload();
        const lastSeenPopup = localStorage.getItem('lastSeenPopup');
        localStorage.clear();
        localStorage.setItem('lastSeenPopup', lastSeenPopup);
        deleteAllCookies();
        return initialState;
      // EDIT USER
      case types.UPDATE_USER_REQUEST:
        //console.log("Ejecuto");
        return {
          ...state,
          loading: true,
          error: null,
        };
      case types.UPDATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: { ...state.user, ...action.payload },
          snack: {
            snackShow: false,
            snackMessage: "¡Datos actualizados con éxito!",
            snackType: "success",
          },
        };
      case types.UPDATE_USER_FAIL:
        return {
          ...state,
          loading: false,
          snack: {
            snackShow: false,
            snackMessage:
              "Ocurrió un problema al actualizar la foto, por favor, intenta nuevamente",
            snackType: "warning",
          },
          error: action.payload,
        };
      case types.SET_UPDATE_USER:
        return {
          ...state,
          loading: false,
          user: { ...state.user, ...action.payload },
          snack: {
            snackShow: true,
            snackMessage: "¡Datos actualizados con éxito!",
            snackType: "success",
          },
        };
      case types.CLOSE_SNACKBAR:
        return {
          ...state,
          snack: {
            snackShow: false,
            snackMessage: "",
            snackType: "",
          },
        };
      // UPDATE PHOTO
      case types.UPDATE_PHOTO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case types.UPDATE_PHOTO_SUCCESS:
        return {
          ...state,
          loading: false,
          user: { ...state.user, ...action.payload },
          snack: {
            snackShow: true,
            snackMessage: "¡Datos actualizados con éxito!",
            snackType: "success",
          },
        };
      case types.UPDATE_PHOTO_FAIL:
        return {
          ...state,
          loading: false,
          snack: {
            snackShow: true,
            snackMessage:
              "Ocurrió un problema al guardar tus datos, por favor, intenta nuevamente",
            snackType: "warning",
          },
          error: action.payload,
        };
      // INSTRUCTURE FIRST CLICK
      case types.INSTRUCTURE_FIRST_CLICK_REQUEST:
        return {
          ...state,
        };
      case types.INSTRUCTURE_FIRST_CLICK_SUCCESS:
        return {
          ...state,
          // canvas_login: true,
        };
      case types.LOGIN_CANVAS:
        // console.log("EJECUTO REDUCER");
        return {
          ...state,
          canvas_login: true,
        };
      case types.INSTRUCTURE_FIRST_CLICK_FAIL:
        return {
          ...state,
          fa_error: action.payload,
        };
      // GET USER REQUEST
      case types.GET_USER_REQUEST:
        return {
          ...state,
          loadingUser: true,
          errorUser: null,
        };
      case types.GET_USER_SUCCESS:
        return {
          ...state,
          loadingUser: false,
          user: action.payload,
        };
      case types.GET_USER_FAIL:
        return {
          ...state,
          loadingUser: false,
          errorUser: action.payload,
        };
      // REGISTER STUDENT
      case types.REGISTER_STUDENT_REQUEST:
        return {
          ...state,
          loadingSignUp: true,
        };
      case types.REGISTER_STUDENT_SUCCESS:
        return {
          ...state,
          signUp: action.payload,
          loadingSignUp: false,
        };
      case types.REGISTER_STUDENT_FAIL:
        return {
          ...state,
          signUpError: action.payload,
          loadingSignUp: false,
        };
      // REFRESH TOKEN
      case types.SET_REFRESH_TOKEN:
        return {
          ...state,
          authToken: action.payload,
        };
      case authTypes.LOGIN_TOKEN_REQUEST:
        return {
          ...state,
          loadingLogin: true,
        };
      case authTypes.LOGIN_TOKEN_SUCCESS:
        return {
          ...state,
          token: action.payload.token,
          roles: action.payload.roles,
          loadingLogin: false,
          errorRoles: false,
        };
      case authTypes.LOGIN_TOKEN_FAIL:
        return {
          ...state,
          errorRoles: action.payload,
          loadingLogin: false,
        };
      case authTypes.GET_DATA_REQUEST:
        return {
          ...state,
          loadingLogin: true,
          loadingGetUser: true,
        };
      case authTypes.GET_DATA_SUCCESS:
        return {
          ...state,
          authToken: state.token || state.authToken,
          user: action.payload.data,
          activeRol: action.payload.rol,
          challenge: {
            ...state.challenge,
            date: new Date().toLocaleDateString(),
          },
          loadingLogin: false,
          loadingGetUser: false,
        };
      case authTypes.GET_DATA_FAIL:
        return {
          ...state,
          error: action.payload,
          loadingLogin: false,
          loadingGetUser: false,
        };
      // CHALLENGE DRACMA
      case authTypes.SET_CHALLENGE_DRACMA:
        return {
          ...state,
          challenge: {
            ...state.challenge,
            ...action.payload,
          },
        };
      // SETEAR TOKEN AUTH
      case authTypes.SET_TOKEN_AUTH:
        return {
          ...state,
          authToken: action.payload,
        };
      default:
        return state;
    }
  }
);

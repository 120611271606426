import React from "react";
import styled from "styled-components";

// Material UI
import Skeleton from "@mui/material/Skeleton";

const CourseSkeleton = (props) => {
  return (
    <CourseContainer>
      <CourseCardContainer>
        <Body>
          <FakeResource
            variant="rounded"
            animation="wave"
            width="100%"
            height="100%"
          />
        </Body>
        <Aside>
          <FakeHeader>
            <p>Módulos</p>
            <p>Comentarios</p>
            <p>Apuntes</p>
          </FakeHeader>
          <FakeBody>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="94%"
              height="60px"
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              width="94%"
              height="60px"
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              width="94%"
              height="60px"
            />
          </FakeBody>
        </Aside>
      </CourseCardContainer>
    </CourseContainer>
  );
};

export default CourseSkeleton;

const CourseContainer = styled.div`
  width: calc(100% - 4rem);
  padding: 0rem 2rem;
`

const CourseCardContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(84vh);
  margin-top: 2rem;
`;

const Body = styled.div`
  flex-basis: 60%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
  padding-left: 8px;
`;

const FakeResource = styled(Skeleton)`
  background-color: #f1f1f1;
  border-radius: 20px;
`;

const Aside = styled.div`
  flex-basis: 40%;
`;

const FakeHeader = styled.div`
  height: 60px;
  padding: 1rem 2rem 0 7rem;
  display: flex;
  justify-content: space-between;
  align-content: end;

  p {
    background-color: #cfcfcf;
    color: #cfcfcf;
    height: fit-content;
    font-weight: 800;
    border-radius: 20px;
  }
`;

const FakeBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 25px;

  & > span {
    border-radius: 20px;
    margin-right: -1rem;
  }
`;
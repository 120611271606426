import React from "react";

import NewTicket from "../../ui/organisms/tickets/NewTicket";
import TicketState from "../../ui/organisms/tickets/TicketState";

const OptionWrapper = (props) => {
  const { currentTab } = props;

  // RETURN
  if (currentTab === 0) {
    return <NewTicket />;
  }

  if (currentTab === 1) {
    return <TicketState />;
  }

  return null;
};

export default OptionWrapper;

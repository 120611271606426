import * as types from '../types';

const initialState = {
    careers: null,
    loadingList: false,
    errorList: false
};

export const careers = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CAREERS_REQUEST:
            return {
                ...state,
                loadingList: true,
                errorList: null,
            };
        case types.GET_CAREERS_SUCCESS:
            return {
                ...state,
                careers: action.payload,
                loadingList: false,
                errorList: null
            };
        case types.GET_CAREERS_FAIL:
            return {
                ...state,
                loadingList: false,
                errorList: action.payload,
            };
        default:
            return state;
    }
};
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import { Icon } from "@iconify/react";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";

const CardSubjectPostTest = (props) => {

    const { module, openCard, last, color, releaseSequence } = props;

    return (
        <Wrapper onStudy={module.onStudy} title={module.description}>
            <Header>
                <Icon icon="solar:danger-circle-bold" width="24px" height="24px" style={{ color: color, marginRight: "-8px" }} />
                <Text color="#535353" fontSize="14px" fontWeight="500">
                    Recuerda completar el test para obtener tu certificado.
                </Text>
            </Header>
            <Body>
                <ImgWrapper>
                    <LazyImg
                        src="/assets/aden/post-test-img.png"
                        width="250px"
                        height="190px"
                    />
                </ImgWrapper>
                <TextWrapper>
                    <Text fontSize="22px" fontWeight="700" color="var(--primary-three)">
                        En este test, evaluaremos los contenidos aprendidos durante tu cursado.
                    </Text>
                    <Text fontSize="16px" fontWeight="500" color="#535353">
                        <i>¡Muchos éxitos!</i>
                    </Text>
                </TextWrapper>
                <ButtonWrapper>
                    <Button disabled={releaseSequence ? false : !module.onStudy} color={color}>
                        Iniciar el test
                    </Button>
                </ButtonWrapper>
                <Check check={module.progress >= 100} survey={true} color={color}>
                    <Icon icon="heroicons-outline:check" width="32px" height="32px" style={{ color: "var(--secondary-one)" }} />
                    <Line openCard={openCard} last={last} survey={true} color={color}/>
                </Check>
            </Body>
        </Wrapper>
    )
}

export default CardSubjectPostTest;

const Wrapper = styled.div`
  width: calc(100% - 4rem);
  height: 248px;
  border-radius: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
  background-color: var(--secondary-two);
  background-image: url('/assets/aden/background-card-subject.png');
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center center;
  transition: height .5s ease;
`

const Header = styled.div`
  position: absolute;
  right: 0;
  top: -40px;
  height: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: calc(100% - 4rem);
  padding: 1rem 2rem;
`;

const ImgWrapper = styled.div`
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextWrapper = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;
`

const ButtonWrapper = styled.div`
  width: 33%;
  height: 150px;
  display: flex;
  align-items: end;
  padding-left: 20px;
`

const Button = styled.button`
  cursor: pointer;
  width: 200px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 48px;
  padding: 0 8px;
  font-size: 14px;
  color: var(--secondary-one);
  :disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
    :hover {
      box-shadow: none;
    }
  }
  :hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`

const Check = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left:${(props) => props.survey ? "-58px" : "-90px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.color};
  border-radius: 40px;
  background-color: ${(props) => props.check ? props.color : "var(--secondary-one)"};
`

const Line = styled.div`
  width: 2px;
  height: ${(props) => props.last ? "0px" : props.openCard ? "250px" : props.nextSurvey ? "200px" : props.survey ? "200px" : "130px"};
  position: absolute;
  bottom: ${(props) => props.openCard ? "-260px" : props.nextSurvey ? "-210px" : props.survey ? "-210px" : "-140px"};
  background-color: ${(props) => props.color};
  transition: all .5s ease;
`
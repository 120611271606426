import React from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../../components/common/Texts";

// Material UI
import WarningIcon from "@mui/icons-material/Warning";

const WarningMessage = () => {
  return (
    <WarningMessageWrapper>
      <WarningIcon />
      <Text color="#616161" fontSize="18px" fontWeight="700">
        ¡Atención!
      </Text>
      <Text color="#A8A8A8" fontSize="14px" fontWeight="400" textAlign="center">
        ¡Asegúrate de haber respondido todas las preguntas! Una vez finalizado
        el intento no podrás modificarlo
      </Text>
    </WarningMessageWrapper>
  );
};

export default WarningMessage;

const WarningMessageWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 1rem 0 0 0;
  svg {
    color: #a8a8a8;
    font-size: 2.5rem;
  }
`;

import axios from "axios";

export function getSubjects(id) {
  return axios.get(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/${id}`
  );
}

export async function getSubjectPaginate(payload) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/paginate/${payload.subject}`;
  try {
    const pagination = await axios.post(URL, payload.query);
    return pagination;
  } catch (error) {
    return { error };
  }
}

export async function getSubjectDirectories(payload) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/directories/${payload.subject}?offset=${payload.query.page_offset}&limit=${payload.query.page_size}`;
  try {
    const directories = await axios.post(URL, payload.query);
    return directories;
  } catch (error) {
    return { error };
  }
}

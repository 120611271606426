import React, { useEffect, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { Text } from "../../common/Texts";
import useTheme from "../../../hooks/useTheme";

const AccordionNavBar = (props) => {
  const {
    closeMenu,
    title = "Menu",
    icon = <WidgetsIcon />,
    listMenu = [],
    data_tut,
  } = props;

  // Si estan todos desactivados no renderizo
  const isAvaliable = listMenu.find((menu) => menu.active);

  const { primary } = useTheme();
  const [active, setActive] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleClick = (state) => {
    setToggle(state);
  };

  useEffect(() => {
    checkPathnameRoute();
  }, []);

  const checkPathnameRoute = () => {
    const isActive = listMenu.find(
      (menu) => menu.to === window.location.pathname + window.location.search
    );
    setActive(!!isActive);
  };

  const handleLeave = () => {
    handleClick(false);
  };

  if (!isAvaliable) {
    return false;
  }

  return (
    <MenuAccordion onMouseLeave={handleLeave} open={toggle}>
      <WrapperNav
        color={primary}
        active={active}
        open={toggle}
        onClick={() => handleClick(!toggle)}
        data-tut={data_tut}
      >
        <InfoNav id="menu-accordion">
          <IconFake>
            {/* {isBeta && <NewSpan>BETA</NewSpan>} */}
            {icon}
          </IconFake>
          <Text id="title" fontWeight="500" color="#222">{title}</Text>
        </InfoNav>
        {toggle ? (
          <ExpandLess className="IconsHearder" />
        ) : (
          <ExpandMore className="IconsHearder" />
        )}
      </WrapperNav>

      <Body h={listMenu.length} open={toggle}>
        {listMenu
          .filter((menu) => menu.active)
          .map((menu) => (
            <MenuItem
              title={menu?.title}
              to={menu?.to}
              icon={menu?.icon}
              closeMenu={closeMenu}
              shadow={true}
            />
          ))}
      </Body>
    </MenuAccordion>
  );
};

AccordionNavBar.propTypes = {
  toggle: PropTypes.object,
  handleClick: PropTypes.func,
  closeMenu: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.element,
  listMenu: PropTypes.array,
  data_tut: PropTypes.string,
};

export default AccordionNavBar;

const WrapperNav = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 17px;
  /* padding: 0 ; */
  margin: 4px 7px;
  color: ${(p) => (p.open ? p.color : "#A8A8A8")};
  cursor: pointer;
  svg {
    color: ${(p) => (p.open ? p.color : "#A8A8A8")};
    font-size: 22px;
  }


  :hover {
    background: #f9f9f9;
    }
    ${(p) =>
    !!p.active &&
    `
    color: ${p.color};
    background-color: #F1F1F1;
    svg {
      color: ${p.color};
    }
    `};
    border-radius: 25px;
`;

const Body = styled.div`
  /* border: 1px solid red; */
  background-color: #FAFAFA;
  margin: 0 15px;
  margin-top: 10px;
  border-radius: 25px;
  padding: 10px;
`;

const InfoNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  #title {
    display: none;
  }
  @media (max-width: 768px) {
    #title {
      display: flex;
      margin-left: 12px;
      font-size: 0.9rem;
    }
  }
`;

const MenuAccordion = styled.div`
  transition: ease-in-out all 0.7s;
  height: ${(p) => (p.open ? "auto" : "62px")};
  max-height: ${(p) => (p.open ? "100%" : "62px")};
`;

const IconFake = styled.div`
  max-width: 45px;
`;

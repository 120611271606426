import { useState } from "react";
import styled from "styled-components";

const CardComment = (props) => {

    const { comment } = props;

    const [seeMore, setSeeMore ] = useState(false)
    
    return (
        <CommentContainer seeMore={seeMore}>
            <UserContainer>
                <UserImg src={comment.partner_id.image} />
                <UserInfo>
                    <UserName>
                        {comment.partner_id.name}
                    </UserName>
                    <PublicationDate>
                        {comment.create_date}
                    </PublicationDate>
                </UserInfo>
            </UserContainer>
            <Comment seeMore={seeMore}>
                {comment.contribution.replace(/<\/?p>/g, '')}
            </Comment>
            <SeeMoreButton onClick={() => setSeeMore(!seeMore)}>
                {
                    !seeMore ? "Ver más" : "Ver menos"
                }
            </SeeMoreButton>
        </CommentContainer>
    )
}

export default CardComment;

const CommentContainer = styled.div`
    width: 152px;
    height: ${(props) => props.seeMore ? "fit-content" : "100px"};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--secondary-one);
    border-left: 10px solid var(--secondary-three); 
    border-radius: 10px;
    padding: 8px 0 8px 8px;

    :hover {
        border-left: 10px solid var(--primary-one);
        box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
        button {
            color: var(--primary-one);
        }
    }
`

const UserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

const UserImg = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const UserName = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: #222;
    
`

const PublicationDate = styled.div`
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-four);
`

const Comment = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-four);
    overflow: hidden;
    white-space: ${(props) => !props.seeMore && "nowrap"};
    text-overflow: ellipsis;
    margin: ${(props) => props.seeMore && "8px 0"};
`

const SeeMoreButton = styled.button`
    cursor: pointer;
    width: 100%;
    text-align: start;
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-four);
`

import React from "react";
import { useParams } from 'react-router-dom'
import styled from "styled-components";
import AsideBlog from "../../components/blog/AsideBlog";
import CardArticleMain from "../../components/blog/CardArticleMain";
import DefaultNavegation from "../../components/shared/navegation/DefaultNavegation";
import useBlogArticle from '../../hooks/blog/useBlogArticle'

const Article = () => {

  const params = useParams()
  
  const { article } = useBlogArticle(Number(params.id))

  console.log('params', params)
  
  return (
    <>
      <DefaultNavegation title={article?.attributes?.title} />
      <Container>
        <Wrapper>
          <CardArticleMain value={article} />
        </Wrapper>
        <AsideBlog />
      </Container>
    </>
  );
};

export default Article;

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  gap: 1rem;
  margin-top: 4rem;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

import React from "react";
import styled from "styled-components";
import LoginFormLab from "../../molecules/auth/LoginFormLab";

const AuthComponent = (props) => {
  const { isLab, primaryColor } = props;

  return (
    <AuthComponentWrapper>
      <LoginFormLab primaryColor={primaryColor} />
    </AuthComponentWrapper>
  );
};

export default AuthComponent;

const AuthComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

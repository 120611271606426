import { useDispatch, useSelector } from 'react-redux'
import { layoutActions } from '../redux/actions'

const useLayout = () => {

    const dispatch = useDispatch()
    const menu = useSelector(state => state.layout.currentMenu)    

    const handleMenu = (title) => {
        dispatch(layoutActions.setMenu(title))
    }

    const clearMenu = () => {
        dispatch(layoutActions.clearMenu())
    }

    return {
        menu,
        setMenu: handleMenu,
        clearMenu: clearMenu
    }
}

export default useLayout
import React from "react";
import styled from "styled-components";
import { Img } from "../../../common/Image";
import logo from "../../../../assets/media/aden/logo-aden-simple-red.png";
import { Button } from "@mui/material";

const Navbar = () => {
  return (
    <NavbarWrapper>
      <Img w="auto" h="45px" src={logo} />
      <ButtonFake
        variant="contained"
        color="primary"
        href="https://www.aden.org"
        target="_blank"
      >
        ¡Conoce sobre ADEN!
      </ButtonFake>
    </NavbarWrapper>
  );
};

export default Navbar;

const NavbarWrapper = styled.div`
  width: calc(100% - 10rem);
  padding: 0 5rem;
  height: 80px;
  box-shadow: 0px 0px 7px 1px rgba(117, 117, 117, 0.75);
  -webkit-box-shadow: 0px 0px 7px 1px rgba(117, 117, 117, 0.75);
  -moz-box-shadow: 0px 0px 7px 1px rgba(117, 117, 117, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonFake = styled(Button)`
  height: 60%;
  margin: 0.5rem;
  padding: 0.5rem;
  @media (max-width: 770px) {
    width: min-content;
    height: 70%;
  }

  span {
    font-size: 14px;
    @media (max-width: 770px) {
      font-size: 10px;
      font-weight: bold;
    }
  }
`;

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import styled from "styled-components";
import InitialChallengeDracma from "../../ui/organisms/challengeDracma/InitialChallengeDracma";
import QuestionChallengeDracma from "../../ui/organisms/challengeDracma/QuestionChallengeDracma";
import { getDracmaChallenge } from "../../../redux/api/dracma.api";

const ChallengeQuestions = () => {
  const { challenge, user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [questionState, setQuestionState] = useState(null);

  const nowDate = new Date();
  const sessionDate = challenge?.date;

  useEffect(() => {
    if (
      (challenge?.state === null ||
        nowDate.toLocaleDateString() !== sessionDate) &&
      (user?.intereses.length > 0 || !!user?.motivaciones.length > 0)
    ) {
      getQuestion();
    }
  }, [challenge]);

  const getQuestion = async () => {
    const body = {
      motivations: user.motivaciones || [],
      interests: user.intereses || [],
    };
    const request = await getDracmaChallenge(
      body,
      user.partner_id,
      user.repo_id
    );
    if (!request.error) {
      if (!!request) {
        setOpen(true);
        setQuestionState(request);
      }
    }
  };

  const handleClose = () => setOpen(false);

  const handleNext = (page) => {
    setPage(page);
  };

  return (
    <div>
      <ModalFake
        open={open}
        disableEnforceFocus
        // onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Wrapper>
          {page === 0 && (
            <InitialChallengeDracma
              values={questionState}
              handleClose={handleClose}
              handleNext={handleNext}
            />
          )}
          {page === 1 && (
            <QuestionChallengeDracma
              values={questionState}
              handleClose={handleClose}
              handleNext={handleNext}
            />
          )}
        </Wrapper>
      </ModalFake>
    </div>
  );
};

export default ChallengeQuestions;

const Wrapper = styled.div`
  width: 514px;
  height: auto;
  margin: auto;
  background-color: white;
  border-radius: 20px;
  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
  :focus-visible {
    outline: none;
  }
`;

const ModalFake = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

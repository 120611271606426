import { useState, useEffect, useRef } from "react";
import Vimeo from "@vimeo/player";

const useVimeo = (videoLink) => {
    const options = {
        autoplay: true,
        loop: false,
        responsive: false,
    };

    const vimeoVideoRef = useRef(null);
    const vimeoPlayerRef = useRef(null);
    const [video, setVideo] = useState(videoLink);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        !vimeoPlayerRef.current && renderPLayer();
        video !== videoLink && videoController();
    }, [videoLink, video]);

    const renderPLayer = () => {
        const videoId = vimeoVideoRef.current.getAttribute("id");
        vimeoPlayerRef.current = new Vimeo(videoId, options);
    };

    const destroyPlayer = () => {
        vimeoPlayerRef.current.destroy();
        vimeoPlayerRef.current = null;
    };

    const videoController = async () => {
        if (!!vimeoPlayerRef.current) {
            setVideo(videoLink);
            destroyPlayer();
            const player = vimeoPlayerRef.current;

            try {
                await player.unload();
                await player.loadVideo(videoLink);
            } catch (error) {
                console.log("Controller", error);
                setFailed(true);
                destroyPlayer();
                renderPLayer();
            } finally {
                setFailed(false);
            }
        }
    };

    const handleStopVideo = async () => {
        try {
            await vimeoPlayerRef.current.pause();
        } catch (error) {
            console.log("Stop Video", error);
        }
    };

    const handlePlayVideo = async () => {
        try {
            await vimeoPlayerRef.current.play();
        } catch (error) {
            console.log("Play Video", error);
        }
    };

    return {
        player: vimeoPlayerRef.current,
        vimeoVideoRef,
        video,
        handleStopVideo,
        handlePlayVideo,
        failed,
    };
};

export default useVimeo;

import React, { useState } from "react";
import styled from "styled-components";

import Text from "../../../common/Text";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import { authActions } from "../../../../redux/actions";
import { AddAPhotoRounded } from "@mui/icons-material";

const OptionPhoto = () => {
  // REDUX
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // STATE
  const [photo, setPhoto] = useState(user.foto);
  const [form, setForm] = useState(null);

  // FUNCTIONS
  function changePhoto(e) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhoto(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    const form = new FormData();
    form.append("img", e.target.files[0]);
    setForm(form);
  }

  function handleSubmit() {
    dispatch(authActions.updatePhotoRequest({ form: form, photo: photo }));
    setForm(null);
  }

  // RETURN
  return (
    <Container>
      <PhotoRelativeWrapper>
        <Photo src={photo} alt="user" />
        <input
          accept="image/*"
          name="photo"
          type="file"
          id="photo"
          hidden
          onChange={changePhoto}
        />
        <FakeLabel htmlFor="photo">
          <CustomIconButton color="primary" component="span">
            <AddAPhotoRounded />
          </CustomIconButton>
        </FakeLabel>
      </PhotoRelativeWrapper>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={form === null ? true : false}
      >
        Subir avatar
      </Button>
      <Text fontSize="0.75rem" color="secondary" component="span">
        Acepta fotos tipo: png, jpg, jpeg.
      </Text>
    </Container>
  );
};

export default OptionPhoto;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  padding: 0 4rem;
`;

const PhotoRelativeWrapper = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
`;

const Photo = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
  }
`;

const FakeLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CustomIconButton = styled(IconButton)`
  background-color: rgba(256, 256, 256, 0.8);

  &:hover {
    background-color: rgb(256, 256, 256);
  }
`;

import * as types from "../types";
import { put, call, takeEvery } from "redux-saga/effects";
import { careersActions } from '../actions'
import { getCareers } from '../api/careers'

function* careersRerquest() {
    try {
        const res = yield call(getCareers);
        yield put(careersActions.getCareersSuccess(res.data.categories))
    } catch (error) {
        yield put(careersActions.getCareersFail(error))
    }
}

function* careersWatcher() {
    yield takeEvery(types.GET_CAREERS_REQUEST, careersRerquest);
}

export { careersWatcher };
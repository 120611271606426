import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ChipChallenges = (props) => {
    const { title, currentCategory, handleCategory } = props;

    const primaryColor = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    return (
        <Chip
            onClick={() => {
                handleCategory(title);
            }}
            title={title}
            selected={currentCategory}
            primaryColor={primaryColor}
        >
            {title}
        </Chip>
    );
};

export default ChipChallenges;

const Chip = styled.div`
    width: fit-content;
    padding: 0.35rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${(p) =>
        p.title === p.selected
            ? `0px 5px 10px 0px rgba(0, 0, 0, 0.06),
        0px 3px 5px 0px rgba(0, 0, 0, 0.15)`
            : "none"};
    background-color: ${(p) =>
        p.title === p.selected ? "#fff" : "transparent"};
    color: ${(p) => (p.selected === p.title ? p.primaryColor : " #949494")};
    border-radius: 5px;
    font-weight: ${(p) => (p.title === p.selected ? "bold" : "normal")};
`;

import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import React from "react";
import styled, { keyframes } from "styled-components";
import useGraduates from "../../../hooks/useGraduates";
import DefaultCard from "../../common/DefaultCard";
import GridCard from "../../common/GridCard";
import CardFreeGraduates from "../../ui/molecules/campus/CardFreeGraduates";

const SCORM_COURSE = true;

const CoursesFree = () => {
  const { product, productsFilter, handleProducts } =
    useGraduates(SCORM_COURSE);
  const skeleton = Array.from(Array(10).keys());

  if (!product) {
    return (
      <DefaultCard>
        <TextField
          disabled
          variant="outlined"
          size="small"
          label="Cargando cursos..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
        <GridCard cards={skeleton} component={Skeleton} />
      </DefaultCard>
    );
  }

  return (
    <DefaultCard>
      <TextField
        // value={filterField}
        onChange={handleProducts}
        variant="outlined"
        size="small"
        label="Buscar curso..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
      <GridCard
        cards={productsFilter || product}
        component={CardFreeGraduates}
      />
    </DefaultCard>
  );
};

const twinkle = keyframes`
    0%{
        background-color: #eeeeee;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #eeeeee;
    }
`;

const Skeleton = () => {
  return <SkeletonContainer />;
};

const SkeletonContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 10px;
  width: 100%;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 20px;
  height: 420px;
  animation: ${twinkle} 2s linear infinite;
`;

export default CoursesFree;

import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import IaCard from "../../components/ui/molecules/ia/IaCard";
import SliderCampus from "../../components/ui/organisms/campus/SliderCampus";
import { titleHTML } from "../../helpers/title.helper";

const IA = (props) => {
  titleHTML("Recomendación");

  const [microlearning, setMicrolearning] = useState(null);

  useEffect(() => {
    if (microlearning === null) {
      getMicrolearning();
    }
  }, [microlearning]);

  const getMicrolearning = async () => {
    const response = await getMicrolearningIaService();
    if (!response.error) {
      // console.log(response);
      setMicrolearning(response);
    } else {
      alert("Error en la peticion");
    }
  };

  const sanitizeName = (name) => {
    if (name == "GENERAL") {
      return "Interes general";
    }
    if (name == "FOR_YOU") {
      return "Recomendados para ti";
    }
    if (name == "FROM_VISITS") {
      return "Los mas vistos";
    }
    if (name == "FROM_INTERESTS") {
      return "Lo que te interesa";
    }
  };

  if (microlearning === null) {
    return <SimpleLoading />;
  }

  return (
    <Container>
      {microlearning.map((ia) => {
        {
          /* sanitizeName(ia.recommendation_name) */
        }
        return (
          <SliderCampus
            items={ia.resources}
            loading={false}
            title={sanitizeName(ia.recommendation_name)}
            label_button={"Ir a ver"}
            to="/"
            Card={IaCard}
            error={null}
            handleTryAgain={false}
          />
        );
        {
          /* return <h5>Hola</h5> */
        }
      })}
    </Container>
  );
};

const getMicrolearningIaService = async () => {
  const BASEURL = process.env.REACT_APP_REPO;
  const ENDPOINT = "/v1/repo_aden/recomendacion/?page_size=20&page_offset=0";
  const URL = BASEURL + ENDPOINT;

  try {
    const res = await axios.get(URL);
    return res.data.data;
  } catch (error) {
    return {
      error,
    };
  }
};

export default IA;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

import React from "react";
import styled from "styled-components";
import Text from "../../../common/Text";
import MotivationsCard from "../molecules/MotivationsCard";

const FirstForm = (props) => {
  const { motivations, setFieldValue, values } = props;

  return (
    <Wrapper>
      <TextFake fontSize="1.8rem" fontWeight="700" textColor="#222222">
        Selecciona tus motivaciones
      </TextFake>
      <TextFake fontSize="1.3rem" fontWeight="400" textColor="#616161">
        Elige todas aquellas opciones que sientas representan mejor tus
        motivaciones
      </TextFake>
      <WrapperCards>
        {motivations.map((motivation) => {
          return values.motivaciones.some((x) => x === motivation.id) ? (
            <MotivationsCard
              key={motivation.id}
              id={motivation.id}
              name={motivation.name}
              checked={true}
              setFieldValue={setFieldValue}
              values={values}
            />
          ) : (
            <MotivationsCard
              key={motivation.id}
              id={motivation.id}
              name={motivation.name}
              checked={false}
              setFieldValue={setFieldValue}
              values={values}
            />
          );
        })}
      </WrapperCards>
    </Wrapper>
  );
};

export default FirstForm;

const Wrapper = styled.div`
  text-align: center;
`;

const TextFake = styled(Text)`
  margin-top: 2rem;
`;

const WrapperCards = styled.div`
  display: grid;
  margin: 2rem auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
`;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subjectsActions } from "../../../redux/actions";
import ErrorResponse from "../../common/ErrorResponse";
import FilterSubjectSkeleton from "../../skeletons/FilterSubject";
import SubjectsSkeleton from "../../skeletons/Subjects";
import Filters from "../../ui/organisms/subject/Filters";
import GridCardSubjects from "../../ui/organisms/subject/GridCardSubjects";

const Registrations = (props) => {
  const { tabsFake } = props;
  const [values, setValues] = useState(null);

  // Redux
  const { user } = useSelector((state) => state.auth);
  const { enrollment, loading, error } = useSelector(
    (state) => state.enrollment
  );
  const dispatch = useDispatch();

  // Effects
  useEffect(() => {
    if (enrollment === null) {
      dispatch(subjectsActions.getSubjectsRequest(user.repo_id));
    }
  }, [enrollment]);

  const handleFilter = (result) => {
    setValues(result);
  };

  if (!!loading) {
    return (
      <div>
        <FilterSubjectSkeleton />
        <SubjectsSkeleton />;
      </div>
    );
  }
  if (enrollment === null) {
    return (
      <ErrorResponse
      //message={'No se pudieron cargar las asignaturas'}
      />
    );
  }
  if (!!error && enrollment === null) {
    return <h1>Error</h1>;
  }
  return (
    <>
      <Filters enrollment={enrollment} handleFilter={handleFilter} />
      <GridCardSubjects
        tabsFake={tabsFake}
        enrollment={enrollment}
        filterValues={values}
      />
    </>
  );
};

export default Registrations;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import ECLayout from "../components/shared/ecommerce-layout/ECLayout";
import { useHelpChat } from "../hooks/useHelpChat";
import { useQueryParams } from "../hooks/useQueryParams";

const PublicRoute = (props) => {
  const {
    layout = "common",
    rol,
    isAuthenticated,
    blockRedirect = false,
    component: Component,
    ...rest
  } = props;

  const queryTab = useQueryParams();

  // Hooks
  // const { hash } = useHelpChat(false, isAuthenticated, "public");

  const renderLayout = (layout, Component) => {
    const cases = {
      common: Component,
      certificate: Component,
      "e-commerce": <ECLayout>{Component}</ECLayout>,
    };

    return cases[layout] || cases["common"];
  };

  const handleRedirect = () => {
    let rolFun = rol?.toLowerCase();
    const redirect = queryTab.get("redirect");
    if (!!redirect) {
      return redirect;
    }
    // if (rolFun === "docente") {
    //   return "/profesor/dashboard";
    // }
    if (
      rolFun === "alumno" ||
      rolFun === "visita" ||
      rolFun === "ondemand" ||
      rolFun === "admin"
    ) {
      return "/campus";
    // }
    // if (rolFun === "academico") {
    //   return "/academico";
    } else {
      return "/campus";
    }
  };

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && !blockRedirect ? (
            <Redirect
              to={handleRedirect() === "not-found" ? "/" : handleRedirect()}
            />
          ) : (
            renderLayout(layout, <Component {...props} />)
          )
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.user,
  rol: state.auth.activeRol,
});

export default connect(mapStateToProps)(PublicRoute);

import React from "react";
import styled from "styled-components";

// Assets
import Imagen from "../../assets/media/aden/pared-imagen.png";

const BlockTabCourse = (props) => {
    const { msg } = props;

    return (
        <Container>
            <Image>
                <img
                    src={Imagen}
                    alt="imagen de bloqueo"
                />
            </Image>
            <h2>¡Ups!</h2>
            <p>{msg}</p>
        </Container>
    );
};

export default BlockTabCourse;

const Container = styled.div`
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2rem;
    padding: 0.5rem;
    h2 {
        color: #b31d15;
        font-size: 1.5rem;
    }

    p {
        text-align: center;
        color: #222222;
        font-size: 1.2rem;
    }
`;

const Image = styled.div`
    height: 200px;

    img {
        height: 100%;
    }
`;

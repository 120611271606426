import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

// Components
import SimpleLoading from "../../../../../components/common/SimpleLoading";

// Redux
import { useSelector } from "react-redux";

const CardNewComment = (props) => {
    
    const {
        expand = false,
        handleNewComment,
        createContribution,
        errorPostCont,
        LoadingPostCont,
        idResource,
        idContribution,
        type,
        createComment,
        upDateComments,
        resetUpdateComments,
        getContributions,
    } = props;

    // REDUX
    const { repo_id, foto } = useSelector((state) => state.auth.user);

    // STATES
    const [expandComment, setExpandComment] = useState(expand);
    const [valueMessage, setValueMessage] = useState("");

    // EFFECTS
    useEffect(() => {
        if (errorPostCont === false) {
            setValueMessage("");
            handleComment();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorPostCont]);

    useEffect(() => {
        if (upDateComments) {
            getContributions();
            resetUpdateComments();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upDateComments]);

    // FUNCTIONS
    const handleComment = () => {
        setExpandComment(!expandComment);

        if (expand) {
            handleNewComment();
        }
    };

    const handleValueMessage = (e) => {
        if (e.target.value.length <= 180) {
            setValueMessage(e.target.value);
        }
    };

    const handlePostContribution = () => {
        const body = {
            resource_id: idResource,
            contribution: valueMessage,
            type: "aporte",
            partner_id: repo_id,
        };
        createContribution(body);
    };

    const handlePostComment = () => {
        const body = {
            type: "aporte",
            partner_id: repo_id,
            comment: valueMessage,
            contribution_id: idContribution,
            parent_id: false,
        };
        createComment(body);
    };
    return (
        <Card expandComment={expandComment}>
            <HeaderContainer expandComment={expandComment}>
                <Image>
                    <img src={foto} alt="imagen de perfil" />
                </Image>
                <IconContainer
                    expandComment={expandComment}
                    onClick={handleComment}
                >
                    <IconCross
                        expandComment={expandComment}
                        icon="maki:cross"
                    />
                </IconContainer>
            </HeaderContainer>

            <BoxComment expandComment={expandComment}>
                <div className="headerBox"></div>
                <textarea
                    type="text"
                    value={valueMessage}
                    onChange={handleValueMessage}
                    placeholder="Escribe tu comentario o aporte."
                    onClick={() => {
                        !expandComment && handleComment();
                    }}
                />
                <div className="footerBox">{valueMessage.length}/180</div>
            </BoxComment>
            <ButtonContainer expandComment={expandComment}>
                {LoadingPostCont ? (
                    <SimpleLoading />
                ) : (
                    <Button
                        onClick={() => {
                            type === "contribution" && handlePostContribution();
                            type === "comment" && handlePostComment();
                        }}
                        disabled={valueMessage.length === 0 ? true : false}
                    >
                        <IconSend icon="lucide:send" />
                        <p>Publicar</p>
                    </Button>
                )}
            </ButtonContainer>
            {expandComment && errorPostCont && (
                <ErrorContainer>
                    <p>Tu comentario no pudo ser publicado.</p>
                </ErrorContainer>
            )}
        </Card>
    );
};

export default CardNewComment;

const Card = styled.div`
    margin-top: ${(p) => (p.expandComment ? "1rem" : "0")};
    height: ${(p) =>
        !p.expandComment ? "calc(80px - 2rem)" : "calc(100% - 3rem)"};
    width: ${(p) => (p.expandComment ? "calc(100% - 2rem)" : "100%")};
    display: flex;
    padding: ${(p) => (p.expandComment ? "1rem 1rem" : "1rem 0")};
    gap: 1rem;
    transition: all 0.3s ease-in-out;
    flex-direction: ${(p) => (p.expandComment ? "column" : "row")};
    background-color: ${(p) => (p.expandComment ? "#f1f1f1" : "transparent")};
    border: ${(p) => (p.expandComment ? "solid 1px #c4c4c4" : "none")};
    border-radius: ${(p) => (p.expandComment ? "10px" : "0")};
`;

const HeaderContainer = styled.div`
    display: ${(p) => (p.expandComment ? "flex" : "block")};
    transition: all 0.3s ease-in-out;
    width: ${(p) => (p.expandComment ? "100%" : "auto")};
    justify-content: space-between;
    align-items: center;
`;

const Image = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        /* border-radius: 50%; */

        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const IconContainer = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 0.3rem;
    border-radius: 0.3rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    display: ${(p) => (!p.expandComment ? "none" : "block")};

    :hover {
        background-color: #c4c4c4;
    }
`;

const IconCross = styled(Icon)`
    font-size: 1.6rem;
    color: #b31d15;
    display: ${(p) => (!p.expandComment ? "none" : "block")};
`;

const BoxComment = styled.div`
    width: 100%;
    height: ${(p) => (p.expandComment ? "328px" : "100%")};
    border-radius: 10px;
    border: ${(p) => (p.expandComment ? "solid 1px #c4c4c4" : "none")};

    .headerBox {
        display: ${(p) => (p.expandComment ? "block" : "none")};
        background-color: #f1f1f1;
        height: 50px;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: solid 1px #c4c4c4;
    }

    .footerBox {
        height: calc(17px - 12px);
        width: calc(100% - 20px);
        padding: 11px 10px;
        display: ${(p) => (p.expandComment ? "flex" : "none")};
        justify-content: flex-end;
        align-items: center;
        color: #a8a8a8;
        border-top: solid 1px #c4c4c4;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #fff;
    }

    textarea {
        font-size: 1rem;
        width: calc(100% - 1rem);
        padding: 0.5rem;
        height: ${(p) =>
            !p.expandComment
                ? "calc(100% - 1rem)"
                : "calc(100% - 1rem - 82px) "};
        outline: none;
        color: #222222;
        resize: none;
        border: ${(p) => (!p.expandComment ? "solid 1px #c4c4c4" : "none")};
        border-radius: ${(p) => (!p.expandComment ? "10px" : "0")};
        scrollbar-color: transparent transparent;

        ::placeholder {
            color: #616161;
        }
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: ${(p) => (p.expandComment ? "flex" : "none")};
`;

const Button = styled.button`
    width: fit-content;
    display: flex;
    height: auto;
    align-items: center;
    cursor: pointer;
    gap: 0.4rem;
    background-color: #b31d15;
    padding: 0.8rem 16px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
`;

const IconSend = styled(Icon)`
    font-size: 1.2rem;
    font-weight: bold;
`;

const ErrorContainer = styled.div`
    width: 100%;
    background-color: #f8423f;
    border-radius: 10px;
    text-align: center;
    padding: 0.1rem 0;

    p {
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
    }
`;
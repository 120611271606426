import React from "react";
import styled from "styled-components";
import Text from "../../../components/common/Text";
import { Icon } from "@iconify/react";
import ProgressBanner from "../../../components/ui/organisms/subject/ProgressBanner";
import { useSelector } from "react-redux";
import useTheme from "../../../hooks/useTheme";

export default function Banner(props) {

  const { subjectProgress, subject, color } = props;

  const user = useSelector((state) => state.auth.user);

  const { primary } = useTheme();

  return (
    <BannerWrapper backgroundColor={primary}>
      <Content>
        <ProgressBanner
          progress={subjectProgress}
          size={200}
          fontSize={"40px"}
          variant="determinate"
          variantColor={primary}
        />
        <InfoWrapper>
          <InfoText>
            <Text fontSize="16px" fontWeight="700">RUTA FORMATIVA</Text>
            <Line color={color}/>
          </InfoText>
          <Text fontSize="24px" fontWeight="700" textColor={primary}>
            {subject.name}
          </Text>
          <Description fontSize="16px">
            {subject.description}
          </Description>
          <SubjectInfo>
            <Info>
              <QuantityInfo>
                <Icon icon="ion:book" width="25px" height="25px" style={{ color: primary }} />
                <Text fontSize="16px" fontWeight="400"><b>{subject.courses.length || 0}</b> cursos</Text>
              </QuantityInfo>
              <TimeInfo>
                <Icon icon="mdi:clock" width="25px" height="25px" style={{ color: primary }} />
                <Text fontSize="16px" fontWeight="400"><b>{subject.duration_week || 0}</b> semanas de cursado</Text>
              </TimeInfo>
            </Info>
            {
              subject.plantilla_certificado_alab !== "" &&
              <CertificateButton 
              disabled={subjectProgress !== 100} 
              color={color}
              onClick={() => {
                window.open(
                  `https://constancias.aden.org/?idcod=ASSIGNMENT-${subject?.id}-${user?.repo_id}-ADEN2024/`,
                  "_blank"
                );
              }}
              >
                <Icon icon="la:award" width="20px" height="20px" style={{ color: "var(--secondary-two)" }} />
                <Text fontSize="16px" fontWeight="500" textColor="var(--secondary-two)">Ver certificado</Text>
              </CertificateButton>
            }
          </SubjectInfo>
        </InfoWrapper>
      </Content>
    </BannerWrapper>
  );
}

const BannerWrapper = styled.div`
  width: 100%;
  min-height: 280px;
  border-radius: 20px;
  background-image: ${(props) => `linear-gradient(180deg, ${props.backgroundColor} , #fff)`};
  background-size: 100% 280%;
  /* background: linear-gradient(180deg, var(--primary-two), var(--secondary-four));
  background: ${(props) => props.color === "var(--primary-two)" ? "var(--primary-two)" : "linear-gradient(180deg, var(--primary-two), var(--secondary-four))"}; */
  box-shadow: -1px 4px 16px -7px rgba(133, 133, 133, 0.75);
  -webkit-box-shadow: -1px 4px 16px -7px rgba(133, 133, 133, 0.75);
  -moz-box-shadow: -1px 4px 16px -7px rgba(133, 133, 133, 0.75);
  display: flex;
  position: absolute;
  top: 5rem;
  @media (max-width: 425px) {
    background-size: 100% 280%;
    height: 500px;
  }
`;

const Content = styled.div`
  width: calc(100% - 1rem);
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
  background-color: #f9f9f9;

  @media (width < 768px) {
    flex-direction: column;
    padding-top: 2rem;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (width < 768px) {
    width: 90%;
    align-items: start;
    gap: 1rem;
  }
`

const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  gap: 2rem;
  white-space: nowrap;

  @media (width < 768px) {
    width: 100%;
    align-items: center;
    gap: 1rem;
  }
`

const Description = styled(Text)`
  @media (width < 768px) {
    display: none;
  }
`

const Line = styled.div`
  height: 2px;
  width: 90%;
  background-color: ${(props) => props.color};
  margin-bottom: 4px;

  @media (width < 768px) {
    display: none;
  }
`

const SubjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (width < 768px) {
    flex-direction: column;
    align-items: start;
  }
`

const Info = styled.div`
  display: flex;
  @media (width < 768px) {
    flex-direction: column;
    align-items: start;
  }
`

const QuantityInfo = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 3rem;
  border-right: 2px solid ${(props) => props.color};

  @media (width < 768px) {
    border-right: 0;
  }
`

const TimeInfo = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 3rem;

  @media (width < 768px) {
    padding-left: 0;

  }
`

const CertificateButton = styled.button`
  cursor: pointer;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${(props) => props.color};
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  padding: 10px;
  :disabled {
    background-color: #C4C4C4;
    box-shadow: none;
    cursor: not-allowed;
  }
`
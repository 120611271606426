// TRAE LOS SLIDES DEL POP-UP
export async function getPopupByCompany(company) {
    const URL = `${process.env.REACT_APP_CMS_ADENAPI}/pop-up-slides?populate=*&filters[$or][0][company][repo_id][$eq]=${company}&filters[$or][1][company][repo_id][$eq]=0

    `;
    try {
        const request = await fetch(URL);
        return await request.json();
    } catch (error) {
        throw new Error(error);
    }
}

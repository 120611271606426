import React, { useState } from "react";
import styled from "styled-components";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import DialogEmail from "../common/DialogEmail";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { IconButton, Modal, Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { userAction } from "../../helpers/userActions.helper";
import { useEffect } from "react";

const SharedResource = (props) => {
  const { modalIsOpen, handleCloseModal, sharedItem, sharedLink } = props;

  // STATE
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);
  const [isCopy, setIsCopy] = useState(false);

  // EFFECT
  useEffect(() => {
    if (sharedItem !== null && sharedItem.type !== "evento") {
      setLink(`https://lp.aden.org/${sharedItem.lp_type}/${sharedItem.id}`);
    } else if (
      sharedItem !== null &&
      sharedItem.type === "evento" &&
      sharedLink !== null
    ) {
      setLink(sharedLink);
    }

    return () => {
      setLink(null);
    };
  }, [sharedItem]);

  // FUNCTIONS
  const handleTooltipOpen = () => {
    setIsCopy(true);
    userAction({
      object_id: sharedItem.id,
      object: sharedItem.type,
      name: sharedItem.nombre_publicacion,
      activity: "Compartir recurso",
      medio: "link",
    });

    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  if (!link || !sharedItem) {
    return null;
  }

  return (
    <ModalFake open={modalIsOpen} onClose={handleCloseModal}>
      <SharedResourceContainer>
        {/** Close */}
        <CloseButtonWrapper>
          <IconButton
            onClick={handleCloseModal}
            style={{
              borderRadius: "10px",
              margin: 0,
              backgroundColor: "#f3f6f9",
            }}
            size="small"
          >
            <CloseRounded />
          </IconButton>
        </CloseButtonWrapper>
        {/** Socials */}
        <SocialsWrapper>
          {sharedItem.lp_type === "evento-actual" ? (
            <SocialsText>
              ¿Te emociona participar de este evento?
              <br />
              <span>¡Compártelo!</span>
            </SocialsText>
          ) : sharedItem.lp_type === "evento-pasado" ? (
            <SocialsText>
              ¿Te emociona este evento?
              <br />
              <span>¡Compártelo!</span>
            </SocialsText>
          ) : sharedItem.lp_type === "workshop" ? (
            <SocialsText>
              ¿Te emociona este workshop?
              <br />
              <span>¡Compártelo!</span>
            </SocialsText>
          ) : (
            <SocialsText>
              ¿Te emociona este microlearning?
              <br />
              <span>¡Compártelo!</span>
            </SocialsText>
          )}
          <SocialsGrid>
            <FacebookShareButton
              onClick={() =>
                userAction({
                  object_id: sharedItem.id + "",
                  object: sharedItem.nombre_publicacion,
                  name: sharedItem.nombre_publicacion,
                  activity: "Compartir recurso",
                  medio: "facebook",
                })
              }
              url={link}
            >
              <FacebookIcon round size={48} />
            </FacebookShareButton>
            <TwitterShareButton
              onClick={() =>
                userAction({
                  object_id: sharedItem.id + "",
                  object: sharedItem.nombre_publicacion,
                  name: sharedItem.nombre_publicacion,
                  activity: "Compartir recurso",
                  medio: "twitter",
                })
              }
              url={link}
            >
              <TwitterIcon round size={48} />
            </TwitterShareButton>
            <LinkedinShareButton
              onClick={() =>
                userAction({
                  object_id: sharedItem.id + "",
                  object: sharedItem.nombre_publicacion,
                  name: sharedItem.nombre_publicacion,
                  activity: "Compartir recurso",
                  medio: "linkedin",
                })
              }
              url={link}
            >
              <LinkedinIcon round size={48} />
            </LinkedinShareButton>
            <WhatsappShareButton
              onClick={() =>
                userAction({
                  object_id: sharedItem.id + "",
                  object: sharedItem.nombre_publicacion,
                  name: sharedItem.nombre_publicacion,
                  activity: "Compartir recurso",
                  medio: "whatsapp",
                })
              }
              url={link}
            >
              <WhatsappIcon round size={48} />
            </WhatsappShareButton>
            <DialogEmail button={IconButton} sharedItem={sharedItem} />
            {/* <EmailShareButton
                        subject={ sharedItem.nombre_publicacion? sharedItem.nombre_publicacion : sharedItem.nombre} 
                        body=""
                        openShareDialogOnClick={true}
                        onClick={() =>
                            userAction({
                                object_id: sharedItem.id + '',
                                object: sharedItem.nombre_publicacion,
                                name:  sharedItem.nombre_publicacion? sharedItem.nombre_publicacion : sharedItem.nombre,
                                activity: 'Compartir recurso',
                                medio: 'email'
                            })
                        }
                        url={link}>
                            <EmailIcon round size={48}/>
                        </EmailShareButton> */}
          </SocialsGrid>
        </SocialsWrapper>
        {/** Link */}
        <LinkWrapper>
          <span>o copia el link</span>
          <LinkDiv>
            <input disabled value={link} />
            <CopyToClipboard text={link} onCopy={handleTooltipOpen}>
              <TooltipFake
                PopperProps={{
                  disablePortal: true,
                }}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado"
                placement="top"
              >
                <button disabled={isCopy}>
                  {isCopy ? "Copiado" : "Copiar"}
                </button>
              </TooltipFake>
            </CopyToClipboard>
          </LinkDiv>
        </LinkWrapper>
      </SharedResourceContainer>
      {/** Image */}
    </ModalFake>
  );
};

export default SharedResource;

const ModalFake = styled(Modal)`
  div:nth-child(0n + 3) {
    border-radius: 20px;
  }
`;

const SharedResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 4rem;
  width: max-content;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 5%;
  right: 5%;
`;

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: center;
`;

const SocialsText = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  color: #222222;

  span {
    font-weight: 400;
    font-size: 1.2rem;
    color: #616161;
  }
`;

const SocialsGrid = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
`;

const LinkDiv = styled.div`
  display: flex;
  background-color: #f3f6f9;
  padding: 0.5rem 1rem;
  border-radius: 8px;

  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: #9e9ea7;
    width: 100%;
  }

  button {
    background-color: transparent;
    color: #d1170d;
    width: max-content;
    padding-left: 1rem;
  }
`;

const TooltipFake = styled(Tooltip)`
  cursor: pointer;
`;

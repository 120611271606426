import React from "react";
import styled from "styled-components";

import { Skeleton } from "@mui/material";

const array = ["", "", ""];

const AdenEventsSkt = (props) => {

  const { firstSection } = props;
  return (
    <Container firstSection={firstSection}>
      {array.map(() => (
        <Card>
          <SkeletonFake
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
          />
        </Card>
      ))}
    </Container>
  );
};

export default AdenEventsSkt;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: ${(p) => p.firstSection ? "340px" : "250px"};
  border-radius: 5px;
  gap: 24px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;

const Card = styled.div`
  border-radius: 10px;
  min-height: 150px;
`;

const SkeletonFake = styled(Skeleton)`
  border-radius: 10px;
`;

import React from "react";
import styled from "styled-components";
import ChipChallenges from "./ChipChallenges";

const CategoriesChallenges = (props) => {
    const { categories, handleCategory, currentCategory } = props;

    return (
        <Wrapper>
            <ChipChallenges
                handleCategory={handleCategory}
                currentCategory={currentCategory}
                title="Todos"
            />
            {categories.map((category, index) => (
                <ChipChallenges
                    key={index}
                    handleCategory={handleCategory}
                    currentCategory={currentCategory}
                    title={category}
                />
            ))}
        </Wrapper>
    );
};

export default CategoriesChallenges;

const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

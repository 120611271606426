import React from "react";
import styled from "styled-components";

const estados = [
  { id: 0, name: "Todos" },
  { id: 1, name: "Confirmado" },
  { id: 2, name: "Preinscripto" },
  { id: 3, name: "Egresado" },
];

const CourseFilters = (props) => {
  const { currentState, handleCurrentState } = props;

  // RETURN
  return (
    <CourseFiltersContainer>
      {estados.map((estado) => (
        <SpanWrapper
          key={estado.id}
          currentState={currentState === estado.name}
          onClick={() => handleCurrentState(estado.name)}
        >
          <Span currentState={currentState === estado.name}>
            {estado.name == "Confirmado" ? "En curso" : estado.name}
          </Span>
        </SpanWrapper>
      ))}
    </CourseFiltersContainer>
  );
};

export default CourseFilters;

const CourseFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  flex-wrap: wrap;
`;

const SpanWrapper = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: ${(props) => (props.currentState ? "#591AB6" : "#bfbfbf")};
  background-color: ${(props) =>
    props.currentState ? "#ffffff" : "transparent"};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.currentState
      ? "0px 3px 5px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.06)"
      : "none"};

  :hover {
    color: #591AB6;
    background-color: ${(props) => (props.currentState ? "#fff" : "#FFF7F6")};
    font-weight: 700;
  }
`;

const Span = styled.span`
  font-size: 1rem;
  font-weight: ${(props) => (props.currentState ? "700" : "400")};
  text-transform: uppercase;
`;

import { useEffect, useState } from "react";
import { getSimulator360 } from "../api/simulator";

const useSimulator = () => {
    // STATES
    const [simulators, setSimulators] = useState(null);

    // EFFECTS
    useEffect(() => {
        simulators === null && getSimulators();
    }, []);

    // FUNCTIONS
    const getSimulators = async () => {
        try {
            const req = await getSimulator360();

            setSimulators(req);
        } catch (error) {
            setSimulators(false);
        }
    };

    return { simulators };
};

export default useSimulator;

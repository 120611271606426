import React from "react";
import styled from "styled-components";

import UsersRatingOndemand from "./UsersRatingOndemand";
import UserPosition from "./UserPosition";

const RatingBusinessOption = () => {
  return (
    <Container>
      {/** Tabla de clasificacion */}
      <UsersRatingOndemand minHeight={"max"} limit={100} withTitle={true} />
      {/** Posicion del usuario con recomendaciones para ganar */}
      <UserPosition />
    </Container>
  );
};

export default RatingBusinessOption;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

import React from "react";
import { Card } from "@mui/material";
import AccessTime from "@mui/icons-material/AccessTime";
import Language from "@mui/icons-material/Language";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import styled from "styled-components";

const CardAbout = (props) => {
  const { duration, modality, certificate, networking, gtc } = props;
  return (
    <CardWrapper gtc={gtc}>
      <CardContent>
        <IconWrapper>
          <AccessTime color="primary"></AccessTime>
        </IconWrapper>
        <div>
          <H3Card>Duración</H3Card>
          <p>{duration}</p>
        </div>
      </CardContent>
      <CardContent>
        <IconWrapper>
          <Language color="primary"></Language>
        </IconWrapper>
        <div>
          <H3Card>Modalidad</H3Card>
          <p>{modality}</p>
        </div>
      </CardContent>
      <CardContent>
        <IconWrapper>
          <VerifiedUserIcon color="primary"></VerifiedUserIcon>
        </IconWrapper>
        <div>
          <H3Card>Certificado</H3Card>
          <p>{certificate}</p>
        </div>
      </CardContent>
      <CardContent>
        <IconWrapper>
          <RecordVoiceOverIcon color="primary"></RecordVoiceOverIcon>
        </IconWrapper>
        <Info>
          <H3Card>Networking</H3Card>
          <p>{networking}</p>
        </Info>
      </CardContent>
    </CardWrapper>
  );
};

export default CardAbout;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 1370px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 100%);
  }
`;

const CardContent = styled.div`
  flex: 1;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  /* justify-content: space-around; */

  @media (max-width: 1370px) {
    height: auto;
  }
  @media (max-width: 1100px) {
    width: 90%;
    justify-content: flex-start;
  }
`;

const IconWrapper = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  svg {
    font-size: 1.5rem;

    @media (max-width: 1370px) {
      font-size: 1.2rem;
    }
  }
`;

const H3Card = styled.h3`
  color: #591AB6;
  font-size: 0.7rem;
  text-transform: uppercase;

  @media (max-width: 1370px) {
    font-size: 0.6rem;
  }
`;

const Info = styled.div`
  width: 80%;
`;

import React, { useContext } from "react";
import styled from "styled-components";

// Components
import CardNewNote from "./CardNewNote";
import CardNoteCourse from "./CardNoteCourse";
import SimpleLoading from "../../../../../components/common/SimpleLoading";

// Hooks
import useNotesCourse from "./hooks/useNotesCourse";

// Context
import CourseContext from "../../../context/CourseContext"

const MainNotes = () => {

    const {
        notesContent: {
            valuesNotes, 
            errorGetNotes, 
            LoadingGetNotes
        },
        showAside
    } = useContext(CourseContext); 

    const {
        LoadingPostNote,
        errorPost,
        upDateNotes,
        createNote,
        resetUpdateNotes,
    } = useNotesCourse();

    if (!showAside) {
        return (
            <>
                {LoadingGetNotes || errorGetNotes ? (
                    <SimpleLoading />
                ) : (
                    <>
                        {Array.isArray(valuesNotes) &&
                            valuesNotes.length > 0 ? (
                            <>
                                {valuesNotes.map((notes, index) => (
                                    <CardNoteCourse
                                        notes={notes}
                                        resetUpdateNotes={resetUpdateNotes}
                                        key={index}
                                    />
                                ))}
                            </>
                        ) : (
                            <NotCommentShow>
                                <p>No se encontraron apuntes.</p>
                            </NotCommentShow>
                        )}
                    </>
                )}
            </>
        )
    }

    return (
        <Container>
            <CardNewNote
                LoadingPostNote={LoadingPostNote}
                errorPost={errorPost}
                createNote={createNote}
                upDateNotes={upDateNotes}
                resetUpdateNotes={resetUpdateNotes}
            />
            <>
                {LoadingGetNotes || errorGetNotes ? (
                    <SimpleLoading />
                ) : (
                    <>
                        {Array.isArray(valuesNotes) &&
                            valuesNotes.length > 0 ? (
                            <>
                                {valuesNotes.map((notes, index) => (
                                    <CardNoteCourse
                                        notes={notes}
                                        resetUpdateNotes={resetUpdateNotes}
                                        key={index}
                                    />
                                ))}
                            </>
                        ) : (
                            <NotComment>
                                <p>No se encontraron apuntes.</p>
                                <p>¡Sé el primero en dejar uno!</p>
                            </NotComment>
                        )}
                    </>
                )}
            </>
        </Container>
    );
};

export default MainNotes;

const Container = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    height: calc(100% - 200px);
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;
`;

const NotComment = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 1.2rem;
    }
`;


const NotCommentShow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 14px;
        color: var(--secondary-three);
        text-align: center;
    }
`;
// import { Card } from '@mui/material';
import React, { useState } from "react";
import styled from "styled-components";
import useCareer from "./hooks/useCareer";
import GridCard from "./components/GridCard";
import Card from "./components/Card";
import DefaultSearch from "../acropolisCommon/components/DefaultSearch";
import SkeletonLoading from "./components/SkeletonLoading";
import TabsComponent from "../acropolisCommon/components/TabsComponent";

const MainEducativeOffer = () => {
  // HOOKS / CUSTOM HOOKS

  const [careerTab, setCareerTab] = useState(3);

  const {
    career,
    handleProduct,
    currentCareers,
    handleChange,
    filteredCareers,
    search,
  } = useCareer();

  const fakeArray = Array.from(Array(20).keys());

  const handleCareerTab = (e, value) => {
    setCareerTab(value);
    handleProduct(value);
  };

  if (!currentCareers) {
    return (
      <Container>
        <TabsWrapper>
          <SkeletonLoading
            propsSkl={{
              width: "100%",
              height: "50px",
              animation: "wave",
            }}
          />
        </TabsWrapper>
        <ContentWrapper>
          <GridContainer>
            <SearchWrapper>
              <DefaultSearch disabled={true} />
            </SearchWrapper>
            <GridCard
              cards={fakeArray}
              component={SkeletonLoading}
              propsSkl={{
                width: "323px",
                height: "271px",
                variant: "rounded",
                animation: "wave",
              }}
            />
          </GridContainer>
        </ContentWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <TabsWrapper>
        <TabsComponent
          tab={careerTab}
          handleTab={handleCareerTab}
          tabs={career}
        />
      </TabsWrapper>
      <ContentWrapper>
        <GridContainer>
          <SearchWrapper>
            <DefaultSearch handleChange={handleChange} search={search} />
          </SearchWrapper>
          <GridCard cards={filteredCareers || currentCareers} component={Card} />
        </GridContainer>
      </ContentWrapper>
    </Container>
  );
};

export default MainEducativeOffer;

const Container = styled.div`
  position: relative;
  margin-top: 30px;
`;
const TabsWrapper = styled.div`
  width: calc(100% - 6rem - 70px);
  position: fixed;
  left: 70px;
  top: 70px;
  z-index: 3;
  border-top: 1px solid #f7f7f7;
  padding: 0 3rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    left: 0;
    width: calc(100% - 6rem);
  }
`;

const GridContainer = styled.div`
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  background-color: #ffffff;
`;

const SearchWrapper = styled.div`
  padding: 1rem 20px 0px 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
`;
